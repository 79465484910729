import React, { Component } from 'react';
import axios from 'axios';
import { Form, FormControl, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import prod from '../../../prod';

const title = {
  pageTitle: 'Forgot Password Screen',
};
//TEMPORARY STYLES
const erroPara = {
  margin: '10px',
};

const divContainer = {
  width: '90%',
  display: 'flex',
  flexDirection: 'vertical',

  minHeight: '200px',
  margin: 'auto',
  padding: '20px',
  // border: "2px solid #000000",
  align: 'strech',
  justify: 'center',
};
const innerDiv = {
  padding: '15px',
  width: '100%',
};

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      showError: false,
      messageFromServer: '',
      showNullError: false,
    };
  }
  componentWillMount() {
    if (
      !this.props.location ||
      !this.props.location.firm ||
      !this.props.location.firm.name
    ) {
      this.props.history.push('/');
    }
  }
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  sendEmail = async (e) => {
    e.preventDefault();
    const { email } = this.state;
    const { firm } = this.props.location;
    const firmId = firm._id;
    if (email === '') {
      this.setState({
        showError: false,
        messageFromServer: '',
        showNullError: true,
      });
    } else {
      try {
        console.log('client fg pwd');
        const response = await axios.post(prod() + '/api/forgotPassword', {
          email,
          firmId,
        });
        console.log('Response: ', response);
        if (response.data === 'recovery email sent') {
          this.setState({
            showError: false,
            messageFromServer: 'recovery email sent',
            showNullError: false,
          });
        }
      } catch (error) {
        console.error('ERROR WHILE Forgot Password:', error.response.data);
        if (error.response.data === 'email not in db') {
          this.setState({
            showError: true,
            messageFromServer: '',
            showNullError: false,
          });
        }
      }
    }
  };

  render() {
    const { email, messageFromServer, showNullError, showError } = this.state;
    console.log("props: ", this.props)

    if (messageFromServer === 'recovery email sent') {
      return (
        <div style={{ marginTop: '110px', marginBottom: '125px' }}>
          <h3>Password Reset Email Successfully Sent!</h3>
        </div>
      );
    }

    return (
      <div style={divContainer} id='forgotPass-style' id='reset-pass'>
        <div style={innerDiv}>
          <h1>Forgot Password</h1>
          {this.props.location &&
            this.props.location.firm &&
            this.props.location.firm.name && (
              <h3>{this.props.location.firm.name}</h3>
            )}
          <Form onSubmit={this.sendEmail}>
            {showNullError && (
              <div
                className='alert alert-danger '
                style={{ top: '10px', padding: '10px' }}
              >
                <p style={{ margin: 0 }}>Enter valid email address.</p>
              </div>
            )}
            {showError && (
              <div className='alert alert-danger thanks-msg'>
                <p style={erroPara}>
                  That email address isn&apos;t recognized. Please try again or
                  register for a new account.
                </p>
              </div>
            )}
            <Form.Group controlId='formBasicEmail'>
              <FormControl
                type='email'
                placeholder='Email Address'
                value={email}
                onChange={this.handleChange('email')}
                name='email'
                autoFocus
                style={{ marginTop: '50px' }}
              />
            </Form.Group>

            <Button
              className='btn action action-s btn-dark'
              block
              type='submit'
              variant='primary'
              style={{ width: 'auto' }}
            >
              Send password reset link
            </Button>
          </Form>

          {showError && (
            <div>
              <Link to='/registration' style={{ textDecoration: 'none' }}>
                {' '}
                <Button
                  className='btn action action-s btn-dark'
                  block
                  style={{
                    width: 'auto',
                  }}
                >
                  Don't have an account with this firm?
                </Button>{' '}
              </Link>
            </div>
          )}
          {messageFromServer === 'recovery email sent' && (
            <div style={{ marginTop: '110px', marginBottom: '125px' }}>
              <h3>Password reset email successfully sent!</h3>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
