import React, { Component } from 'react';

import {
  Container,
  Col,
  Row,
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  Form,
  ProgressBar,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../../prod';
import history from '../../../../utils/history';
import TextareaAutosize from 'react-textarea-autosize';
import Pulse from 'react-reveal/Pulse'; // Importing Bounce effect
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import FocusModule from './FocusModal';
import Topic from './Topic';

import Documents from './Documents';
import { Promise } from 'bluebird';

export const logPageView = () => {
  window.analytics.page('Focus');
};
class Focus extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      focus: {
        name: {
          value: '',
          rows: 1,
        },
        relationship: {
          value: '',
          rows: 1,
        },
        goals: {
          value: '',
          rows: 1,
          important: false,
        },
        open: {
          value: '',
          rows: 1,
        },
        closing: {
          value: '',
          rows: 1,
        },
        bias: {
          value: '',
          rows: 1,
        },
        fact: {
          value: '',
          rows: 1,
        },
        tops: [],
        docs: [],
        keywords: {
          value: '',
          rows: 1,
        },
        strength: {
          value: '',
          rows: 1,
        },
        weaknesses: {
          value: '',
          rows: 1,
        },
        examination_tech: {
          value: '',
          rows: 1,
        },
        objections: {
          value: '',
          rows: 1,
        },
        opposition_adv: {
          value: '',
          rows: 1,
        },
      },
      show: false,
      hShow: false,
      witnessShow: false,
      witnessPhotoShow: false,
      relationShow: false,
      oppnent: false,
      bias: false,
      strength: false,
      weakness: false,
      fact: false,
      goals: false,
      keyword: false,
      objection: false,
      closeQue: false,
      openQue: false,
      crossExam: false,
      doc: false,
      topic: false,
      // witness: {},
      // document
      // delete card
      dCShow: false,
      // create link
      //upload photo
      witnessPhotoInfo: '',
      gcFileName: '',
      fileErr: '',
      uploadableFileTypes: [
        'image/jpeg',
        'image/gif',
        'image/png',
        'image/svg+xml',
        'image/bmp',
      ],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.saveItem = this.saveItem.bind(this);
    // settings
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    // help
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);
    // export

    // ref
    this.relationshipRef = React.createRef();
    this.opposition_advRef = React.createRef();
    this.biasRef = React.createRef();
    this.strengthRef = React.createRef();
    this.weaknessesRef = React.createRef();
    this.factRef = React.createRef();
    this.goalsRef = React.createRef();
    this.keywordsRef = React.createRef();
    this.objectionsRef = React.createRef();
    this.openRef = React.createRef();

    this.closingRef = React.createRef();
    this.examination_techRef = React.createRef();
    this.handleDCShow = this.handleDCShow.bind(this);

    // document
    this.handleClickLink = this.handleClickLink.bind(this);

    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }
  // documents
  // link

  handleWSSubmit() {
    const s = this.state.focus.name.value;
    if (s != '' && s != undefined) {
      axios
        .post(prod() + '/api/cases/update/witness', {
          name: s,
          witness: this.props.witness._id,
          case: this.props.case._id,
        })
        .then((user) => {
          for (let a of user.data.name.witnesses) {
            if (a._id == this.props.witness._id) {
              this.props.updateWitness(user.data.name, a);
            }
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.setState({
            errors: 'Could Not Update Witness',
          });
        });
    } else {
      console.log('err');
      this.setState({
        errors: 'Please Enter The Witness Name.',
      });
    }
  }

  handleClickLink = () => {
    console.log(
      this.state.linkErr,
      this.state.linkLocation,
      this.state.linkName
    );
  };

  handleProgress = () => {
    let p = 0;
    const n = this.state;
    if (n.focus.name && n.focus.name.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.relationship && n.focus.relationship.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.goals && n.focus.goals.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.open && n.focus.open.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.closing && n.focus.closing.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.bias && n.focus.bias.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.fact && n.focus.fact.value != '') {
      p += 6.66666666667;
    }
    // if (n.focus.tops && n.focus.tops.length > 0) {
    if (1 == 1) {
      p += 6.66666666667;
    }
    if (n.focus.docs && n.focus.docs.length > 0) {
      p += 6.66666666667;
    }
    if (n.focus.keywords && n.focus.keywords.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.strength && n.focus.strength.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.weaknesses && n.focus.weaknesses.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.examination_tech && n.focus.examination_tech.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.objections && n.focus.objections.value != '') {
      p += 6.66666666667;
    }
    if (n.focus.opposition_adv && n.focus.opposition_adv.value != '') {
      p += 6.66666666667;
    }
    if (p != this.state.progress) this.setState({ progress: p });
  };

  //link end
  //document ending

  handleDCShow = (event, item) =>
    this.setState({ dCShow: true, deletedCard: item });
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  handleHShow = () => {
    this.setState({ hShow: true });
  };
  handleHClose = () => {
    this.setState({ hShow: false });
  };
  handleWitness = () => {
    this.setState({ witnessShow: true });
  };
  handleWitnessClose = () => {
    this.setState({ witnessShow: false });
  };
  handleWitnessPhoto = () => {
    this.setState({ witnessPhotoShow: true });
  };
  handleWitnessPhotoClose = () => {
    this.setState({ witnessPhotoShow: false });
  };
  handleRelation = () => {
    this.setState({ relationShow: true });
  };

  handleRelationClose = () => {
    this.setState({ relationShow: false });
  };

  handleOppnent = () => {
    this.setState({ oppnent: true });
  };

  handleOppnentClose = () => {
    this.setState({ oppnent: false });
  };
  handleBias = () => {
    this.setState({ bias: true });
  };
  handleBiasClose = () => {
    this.setState({ bias: false });
  };

  handleStrength = () => {
    this.setState({ strength: true });
  };

  handleStrengthClose = () => {
    this.setState({ strength: false });
  };
  handleWeakness = () => {
    this.setState({
      weakness: !this.state.weakness,
    });
  };
  handleTopic = () => {
    this.setState({
      topic: !this.state.topic,
    });
  };

  handleFacts = () => {
    this.setState({
      fact: !this.state.fact,
    });
  };

  handleGoals = () => {
    this.setState({
      goals: !this.state.goals,
    });
  };
  handleKeyword = () => {
    this.setState({
      keyword: !this.state.keyword,
    });
  };
  handleObjection = () => {
    this.setState({
      objection: !this.state.objection,
    });
  };

  handleOpenQuestion = () => {
    this.setState({
      openQue: !this.state.openQue,
    });
  };
  handleCloseQuestion = () => {
    this.setState({
      closeQue: !this.state.closeQue,
    });
  };

  handleCrossExam = () => {
    this.setState({
      crossExam: !this.state.crossExam,
    });
  };

  handleDoc = () => {
    this.setState({
      doc: !this.state.doc,
    });
  };

  handleClick = (event) => {
    const name = event.target.getAttribute('name');
    const value = event.target.getAttribute('value');
    event.preventDefault();
    this.setState(
      ({ focus }) => ({
        focus: {
          ...focus,
          [name]: {
            ...focus[name],
            value: value,
          },
        },
      }),
      () => {
        if (name == 'relationship') {
          this.relationshipRef.current._ref.focus();
        } else if (name == 'opposition_advRef') {
          this.opposition_advRef.current._ref.focus();
        } else if (name == 'bias') {
          this.biasRef.current._ref.focus();
        } else if (name == 'strength') {
          this.strengthRef.current._ref.focus();
        } else if (name == 'weaknesses') {
          this.weaknessesRef.current._ref.focus();
        } else if (name == 'fact') {
          this.factRef.current._ref.focus();
        } else if (name == 'goals') {
          this.goalsRef.current._ref.focus();
        } else if (name == 'keywords') {
          this.keywordsRef.current._ref.focus();
        } else if (name == 'objections') {
          this.objectionsRef.current._ref.focus();
        } else if (name == 'open') {
          this.openRef.current._ref.focus();
        } else if (name == 'closing') {
          this.closingRef.current._ref.focus();
        } else if (name == 'examination_tech') {
          this.examination_techRef.current._ref.focus();
        }
      }
    );
  };

  handleChange = (event) => {
    //val
    const key = event.target.id;
    const name = event.target.name;
    const value = event.target.value;
    this.setState(({ focus }) => ({
      focus: {
        ...focus,
        [name]: {
          ...focus[name],
          value: value,
        },
      },
    }));
  };
  componentWillReceiveProps(newProps) {
    if (
      newProps.witness &&
      newProps.witness.focus &&
      newProps.witness.focus.name.value != this.props.witness.focus.name.value
    ) {
      axios
        .post(prod() + '/api/documents/getall', {
          case: newProps.case['_id'],
          witness: newProps.witness['_id'],
        })
        .then((a) => {
          let focus = newProps.witness.focus;
          focus.docs = a.data.documents;
          this.setState({
            case: newProps.case,
            witness: newProps.witness,
            focus: focus,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }
  componentDidUpdate(props) {
    if (this.state.focus != props.focus) this.handleProgress();
  }
  componentDidMount() {
    logPageView();
    window.scrollTo(0, 0);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.witness == undefined ||
      this.props.witness == null ||
      Object.keys(this.props.case).length === 0 ||
      Object.keys(this.props.witness).length === 0
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          topic: '',
          path: '/case/witness/focus',
          name: 'Focus',
          active: true,
        });
      }
      const items = [];

      axios
        .post(prod() + '/api/cases/get/witness/focus', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
        })
        .then((use) => {
          axios
            .post(prod() + '/api/documents/getall', {
              case: this.props.case['_id'],
              witness: this.props.witness['_id'],
            })
            .then((a) => {
              let focus = use.data.focus;
              focus.docs = a.data.documents;
              console.log('FOCUS: ', use.data.focus);
              this.setState({
                focus: focus,
                witness: this.props.witness,
                case: this.props.case,
              });
            })
            .catch((e) => {
              console.log('ERROR', e);
            });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  saveItem(q) {
    if (q == 1) {
      this.handleWSSubmit();
    } else {
      let foc = this.state.focus;
      foc.doc = undefined;
      axios
        .post(prod() + '/api/cases/update/witness/focus', {
          witness: this.state.witness['_id'],
          case: this.state.case['_id'],
          focus: foc,
        })
        .then((use) => {
          for (let a of use.data.case.witnesses) {
            if (a._id == this.state.witness._id) {
              this.props.updateWitness(use.data.case, a);
            }
          }
        })
        .catch((err) => {
          // alert('Did not save. Please refresh and try again.');
          this.setState({
            user: 'Focus Error',
          });
        });
    }
  }
  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/focus', {
          case: this.state.case._id,
          witness: this.state.witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          this.setState(
            {
              witness: user.data,
            },
            () => {
              this.props.updateWitness(this.state.case, user.data);
            }
          );
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };

  handleImportantRow = (row, index) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/focus/row', {
          case: this.props.case._id,
          witness: this.props.witness._id,
          focus: this.state.focus,
          user: s.data.id,
          row: row,
          index: index,
        })
        .then((user) => {
          let focus = user.data;
          this.setState({
            focus: {
              ...focus,
              docs: this.state.focus.docs,
            },
          });
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };
  handlecomments = (name) => {
    this.setState(
      ({ focus }) => ({
        focus: {
          ...focus,
          [name]: {
            ...focus[name],
            comment:
              this.state.focus[name].comments != undefined
                ? !this.state.focus[name].comment
                : true,
          },
        },
      }),
      () => {
        console.log(this.state);
      }
    );
  };

  handleDocuments = (item) => {
    this.setState(({ focus }) => ({
      focus: {
        ...focus,
        docs: item,
      },
    }));
  };

  deleteDocs = (topic) => {
    let focus = this.state.focus;
    for (let i = 0; i < this.state.focus.docs.length; i++) {
      let sub = 0;
      let count = 0;
      for (let j = 0; j < this.state.focus.docs[i].locations.length; j++) {
        if (
          focus.docs[i].locations[j].topicId.toString() == topic._id.toString()
        ) {
          focus.docs[i].locations.splice(j, 1);
          axios
            .post(prod() + '/api/documents/update/loc', {
              case: this.props.case['_id'],
              witness: this.props.witness['_id'],
              document: this.state.focus.docs[i]['_id'],
              loc: focus.docs[i].locations,
            })
            .then((use) => {
              sub++;
              if (sub == this.state.focus.docs.locations.length) {
                if (count == this.state.focus.docs.length) {
                  this.setState({
                    focus: focus,
                  });
                }
              }
            })
            .catch((e) => {
              console.log('Invalid Input, Please Try Again');
            });
        } else {
          sub++;
        }
      }
    }
  };

  uploadFile(file) {
    const url = prod() + '/api/cases/update/witness/uploadFile';
    const data = new FormData();
    data.append('fileInfo', file);

    if (!file) {
      this.setState({ fileErr: 'Please choose a file' });
    } else if (file && !this.state.uploadableFileTypes.includes(file.type)) {
      this.setState({
        fileErr:
          'File type is not supported. Supported file type: .jpeg, .png, .svg, .bmp',
      });
    } else if (file && this.state.uploadableFileTypes.includes(file.type)) {
      axios({
        method: 'post',
        url: url,
        data: data,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      }).then((res) => {
        console.log('witness photo uploaded: ', res.data);
        const data = {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          witnessPhoto: res.data.path,
          filename: res.data.filename,
        };

        axios
          .post(prod() + '/api/cases/witness/focus/uploadPhoto', data)
          .then(() => {
            console.log('photo uploaded to db');
            this.setState({
              focus: {
                ...this.state.focus,
                photo: { file: res.data.path, gc_filename: res.data.filename },
              },
              fileErr: '',
            });
          })
          .catch((e) => console.log(e));
      });
    }
  }

  deleteFile(filename) {
    this.setState({
      focus: { ...this.state.focus, photo: {} },
      witnessPhotoInfo: '',
    });

    Promise.all([
      axios.post(prod() + '/api/cases/gcs/deleteFile', { filename }),
      axios.post(prod() + '/api/cases/witness/focus/deletePhoto', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
      }),
    ])
      .then(() => {
        console.log('deleted photo');
      })
      .catch((s) => {
        console.log('err');
      });
  }

  render() {
    console.log('photo url: ', this.state.focus.photo);
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;

    // strategies
    let info = <div></div>;

    // if (this.state.focus.examination_tech.value.trim() == 'Impeachment') {
    //   info = (
    //     <Button
    //       onClick={() => history.push('/information/impeachment')}
    //       className='tech-info'
    //     >
    //       More Info
    //     </Button>
    //   );
    // } else if (
    //   this.state.focus.examination_tech.value.trim() == 'Framing Questions'
    // ) {
    //   info = (
    //     <Button
    //       onClick={() => history.push('/information/framing')}
    //       className='tech-info'
    //     >
    //       More Info
    //     </Button>
    //   );
    // } else if (
    //   this.state.focus.examination_tech.value.trim() ==
    //   'Exposing False Testimony'
    // ) {
    //   info = (
    //     <Button
    //       onClick={() => history.push('/information/exposing')}
    //       className='tech-info'
    //     >
    //       More Info
    //     </Button>
    //   );
    // } else if (
    //   this.state.focus.examination_tech.value.trim() == 'General Credibility'
    // ) {
    //   info = (
    //     <Button
    //       onClick={() => history.push('/information/credibility')}
    //       className='tech-info'
    //     >
    //       More Info
    //     </Button>
    //   );
    // }

    return (
      <>
        <div id='wit-dash'>
          <Container fluid className='scope'>
            <Row>
              <Col md={5}>
                <div className='case-dash-container'>
                  {/* {this.state.witness && this.state.witness.comments ? (
                    <img
                      onClick={(e) => this.handleImportant(e, false)}
                      className='logo-title-dash '
                      src={require('../../../../pics/comment_submitted.svg')}
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src={require('../../../../pics/comment.svg')}
                    ></img>
                  )} */}
                  {this.state.witness &&
                  this.state.witness.focus &&
                  this.state.witness.focus.important ? (
                    <img
                      className='logo-title-dash'
                      onClick={(e) =>
                        this.handleImportant(
                          e,

                          false
                        )
                      }
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                    ></img>
                  )}
                </div>
                <h1>Focus of Cross-examination</h1>

                <ProgressBar now={Math.round(this.state.progress)} />
              </Col>
              <Col md={7}>
                <FocusModule
                  darkMode={this.props.darkMode}
                  show={this.state.show}
                  handleClose={this.handleClose}
                  focus={this.state.focus}
                  handleHClose={this.handleHClose}
                  hShow={this.state.hShow}
                  witnessShow={this.state.witnessShow}
                  handleWitnessClose={this.handleWitnessClose}
                  relationShow={this.state.relationShow}
                  handleRelationClose={this.handleRelationClose}
                  oppnent={this.state.oppnent}
                  handleOppnentClose={this.handleOppnentClose}
                  bias={this.state.bias}
                  handleBiasClose={this.handleBiasClose}
                  strength={this.state.strength}
                  handleStrengthClose={this.handleStrengthClose}
                  weakness={this.state.weakness}
                  handleWeakness={this.handleWeakness}
                  fact={this.state.fact}
                  handleFacts={this.handleFacts}
                  goals={this.state.goals}
                  handleGoals={this.handleGoals}
                  keyword={this.state.keyword}
                  handleKeyword={this.handleKeyword}
                  objection={this.state.objection}
                  handleObjection={this.handleObjection}
                  openQue={this.state.openQue}
                  handleOpenQuestion={this.handleOpenQuestion}
                  closeQue={this.state.closeQue}
                  handleCloseQuestion={this.handleCloseQuestion}
                  crossExam={this.state.crossExam}
                  handleCrossExam={this.handleCrossExam}
                  doc={this.state.doc}
                  handleDoc={this.handleDoc}
                  topic={this.state.topic}
                  handleTopic={this.handleTopic}
                  topics={this.props.witness.topics}
                  witnessPhotoShow={this.state.witnessPhotoShow}
                  handleWitnessPhotoClose={this.handleWitnessPhotoClose}
                />

                {/* link end */}
                <div className='add-btn add-btn-p add-btn-p2 topic-btn'>
                  <Pulse>
                    <div>
                      <ButtonToolbar>
                        <OverlayTrigger
                          key='bottom'
                          placement='bottom'
                          overlay={
                            <Tooltip
                              id={
                                this.props.darkMode
                                  ? 'dark-tooltip-add'
                                  : 'tooltip-add'
                              }
                            >
                              Help
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={this.handleHShow}
                            className='add-case'
                          >
                            <img
                              className='logo-title log'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                            ></img>{' '}
                          </Button>
                        </OverlayTrigger>
                      </ButtonToolbar>
                    </div>
                  </Pulse>
                </div>
                <div className='add-btn add-btn-p add-btn-p2 topic-btn'>
                  <Pulse>
                    <div>
                      <ButtonToolbar>
                        <OverlayTrigger
                          key='bottom'
                          placement='bottom'
                          overlay={
                            <Tooltip
                              id={
                                this.props.darkMode
                                  ? 'dark-tooltip-add'
                                  : 'tooltip-add'
                              }
                            >
                              Export
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={this.handleShow}
                            className='add-case'
                          >
                            <img
                              className='logo-title log'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                            ></img>{' '}
                          </Button>
                        </OverlayTrigger>
                      </ButtonToolbar>
                    </div>
                  </Pulse>
                </div>
              </Col>
            </Row>

            <div id='focus-form' className='focus-f'>
              <Fade>
                <div className='d-block d-md-none'>
                  <Row className='saved-input underline-t'>
                    <h4>Witness Name</h4>

                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.name.value}
                          onChange={this.handleChange}
                          name='name'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Relationship To Case</h4>

                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.relationship.value}
                          onChange={this.handleChange}
                          name='relationship'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>How They Advance Opponent's Case</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.opposition_adv.value}
                          onChange={this.handleChange}
                          name='opposition_adv'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Bias / Motivation</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.bias.value}
                          onChange={this.handleChange}
                          name='bias'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Strengths</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.strength.value}
                          onChange={this.handleChange}
                          name='strength'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Weaknesses</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.weaknesses.value}
                          onChange={this.handleChange}
                          name='weaknesses'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Facts To Bring Out</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.fact.value}
                          onChange={this.handleChange}
                          name='fact'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Goals / Focus Of Examination</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.goals.value}
                          onChange={this.handleChange}
                          name='goals'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Key Words</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          rows={this.state.focus.keywords.rows}
                          value={this.state.focus.keywords.value}
                          onChange={this.handleChange}
                          name='keywords'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Objections</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          rows={this.state.focus.objections.rows}
                          value={this.state.focus.objections.value}
                          onChange={this.handleChange}
                          name='objections'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Opening Question</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.open.value}
                          onChange={this.handleChange}
                          name='open'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='saved-input underline-t'>
                    <h4>Closing Question</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.closing.value}
                          onChange={this.handleChange}
                          name='closing'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Cross-Examination Techniques</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.examination_tech.value}
                          onChange={this.handleChange}
                          name='examination_tech'
                          onBlur={this.saveItem}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Documents</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      {/* <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          // value={this.state.focus.documents.value}
                          onChange={this.handleChange}
                          name='documents'
                          onBlur={this.saveItem}
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>

                  <Row className='saved-input underline-t'>
                    <h4>Topics</h4>
                    <Col md={1}></Col>
                    <Col md={9}>
                      {/* <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.topics.value}
                          onChange={this.handleChange}
                          name='topics'
                          onBlur={this.saveItem}
                        />
                      </Form.Group> */}
                    </Col>
                  </Row>
                </div>

                {/* desktop */}

                <div className='d-none d-md-block '>
                  <Row className='saved-input  focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.name.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('name')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('name')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.name.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('name')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('name')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Witness Name</h4>

                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleWitness}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.name.value}
                          onChange={this.handleChange}
                          name='name'
                          onBlur={() => this.saveItem(1)}
                        />
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.name.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('name')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('name')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  {/* WITNESS PHOTO */}
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Witness Photo</h4>

                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleWitnessPhoto}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      {this.state.fileErr && (
                        <div className='invalid-feedback invalid-feedback-c'>
                          {this.state.fileErr}
                        </div>
                      )}
                      {this.state.focus.photo && this.state.focus.photo.file && (
                        <div className='photoperson'>
                          <img
                            src={this.state.focus.photo.file}
                            alt='witness-photo'
                            className='witness-img'
                          />
                          <img
                            className='logo-s highlight-delete'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg'
                            onClick={() =>
                              this.deleteFile(
                                this.state.focus.photo.gc_filename
                              )
                            }
                          />
                        </div>
                      )}
                      {this.state.focus.photo && !this.state.focus.photo.file && (
                        <Form.Group controlId='witness-photo'>
                          <input
                            type='file'
                            name='image'
                            onChange={(e) => this.uploadFile(e.target.files[0])}
                          />
                        </Form.Group>
                      )}
                    </Col>
                  </Row>
                  <Row className='saved-input  focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.relationship.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('relationship')
                          }
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('relationship')
                          }
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.relationship.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('relationship')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('relationship')
                              }
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Relationship To Case</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleRelation}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.relationship.value}
                          onChange={this.handleChange}
                          name='relationship'
                          onBlur={this.saveItem}
                          ref={this.relationshipRef}
                        />
                        {this.state.focus.relationship.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='relationship'
                                value='Complainant'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Complainant
                              </Button>
                              <Button
                                name='relationship'
                                value='Eye Witness'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Eye Witnesses
                              </Button>
                              <Button
                                name='relationship'
                                value='Police Officer'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Police Officer
                              </Button>
                              <Button
                                name='relationship'
                                value='Expert Witness'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Expert Witness
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.relationship.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('relationship')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('relationship')
                              }
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.opposition_adv.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('opposition_adv')
                          }
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('opposition_adv')
                          }
                        />
                      )} */}
                      {/* {this.props.splitState === true && (
                        <>
                          {this.state.focus.opposition_adv.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('opposition_adv')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('opposition_adv')
                              }
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>How They Advance Opponent's Case</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleOppnent}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.opposition_adv.value}
                          onChange={this.handleChange}
                          name='opposition_adv'
                          onBlur={this.saveItem}
                          ref={this.opposition_advRef}
                        />
                        {this.state.focus.opposition_adv.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='opposition_adv'
                                value='Eye Witness To Incident'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Eye Witness To Incident
                              </Button>
                              <Button
                                name='opposition_adv'
                                value='Provides Expert Opinion'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Provides Expert Opinion
                              </Button>
                              <Button
                                name='opposition_adv'
                                value='Investigated Allegation'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Investigated Allegation
                              </Button>
                              <Button
                                name='opposition_adv'
                                value='Heard Statement / Omission'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Heard Statement / Omission
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.opposition_adv.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('opposition_adv')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('opposition_adv')
                              }
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.bias.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('bias')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('bias')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.bias.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('bias')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('bias')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Bias / Motivation</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleBias}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.bias.value}
                          onChange={this.handleChange}
                          name='bias'
                          onBlur={this.saveItem}
                          ref={this.biasRef}
                        />
                        {this.state.focus.bias.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='bias'
                                value='Relative'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Relative
                              </Button>
                              <Button
                                name='bias'
                                value='Friend'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Friend
                              </Button>
                              <Button
                                name='bias'
                                value='Financial'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Financial
                              </Button>
                              <Button
                                name='bias'
                                value='Employment'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Employment
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.bias.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('bias')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('bias')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.strength.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('strength')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('strength')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.strength.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('strength')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('strength')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Strengths</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleStrength}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.strength.value}
                          onChange={this.handleChange}
                          name='strength'
                          onBlur={this.saveItem}
                          ref={this.strengthRef}
                        />
                        {this.state.focus.strength.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='strength'
                                value='Independent, No Bias'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Independent, No Bias
                              </Button>
                              <Button
                                name='strength'
                                value='Notes Or Records Of Event'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Notes Or Records Of Event
                              </Button>
                              <Button
                                name='strength'
                                value='Strong Credentials'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Strong Credentials
                              </Button>
                              <Button
                                name='strength'
                                value='Supported By Other Evidence'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Supported By Other Evidence
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.strength.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('strength')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('strength')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.weaknesses.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('weaknesses')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('weaknesses')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.weaknesses.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('weaknesses')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('weaknesses')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Weaknesses</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleWeakness}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.weaknesses.value}
                          onChange={this.handleChange}
                          name='weaknesses'
                          onBlur={this.saveItem}
                          ref={this.weaknessesRef}
                        />
                        {this.state.focus.weaknesses.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='weaknesses'
                                value='Bias'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Bias
                              </Button>
                              <Button
                                name='weaknesses'
                                value='Criminal Record'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Criminal Record
                              </Button>
                              <Button
                                name='weaknesses'
                                value='Poor Memory'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Poor Memory
                              </Button>
                              <Button
                                name='weaknesses'
                                value='Contradictions / Inconsistent Statements'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Contradictions / Inconsistent Statements
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.weaknesses.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('weaknesses')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('weaknesses')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.fact.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('fact')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('fact')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.fact.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('fact')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('fact')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Facts To Bring Out</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleFacts}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.fact.value}
                          onChange={this.handleChange}
                          name='fact'
                          onBlur={this.saveItem}
                          ref={this.factRef}
                        />
                        {this.state.focus.fact.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='fact'
                                value='Bias'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Bias
                              </Button>
                              <Button
                                name='fact'
                                value='Criminal Record'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Criminal Record
                              </Button>
                              <Button
                                name='fact'
                                value='Poor Memory'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Poor Memory
                              </Button>
                              <Button
                                name='fact'
                                value='Contradictions / Inconsistent Statements'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Contradictions / Inconsistent Statements
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.fact.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('fact')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('fact')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.goals.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('goals')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('goals')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.goals.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('goals')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('goals')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Goals / Focus Of Examination</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleGoals}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.goals.value}
                          onChange={this.handleChange}
                          name='goals'
                          onBlur={this.saveItem}
                          ref={this.goalsRef}
                        />
                        {this.state.focus.goals.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='goals'
                                value='Facts Supporting Your Case'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Facts Supporting Your Case
                              </Button>
                              <Button
                                name='goals'
                                value='Attack Credibility'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Attack Credibility
                              </Button>
                              <Button
                                name='goals'
                                value='Attack Reliability'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Attack Reliability
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.goals.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('goals')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('goals')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.keywords.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('keywords')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('keywords')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.keywords.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('keywords')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('keywords')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Key Words</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleKeyword}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.keywords.value}
                          onChange={this.handleChange}
                          name='keywords'
                          onBlur={this.saveItem}
                          ref={this.keywordsRef}
                        />
                        {this.state.focus.keywords.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='keywords'
                                value='Inconsistent'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Inconsistent
                              </Button>
                              <Button
                                name='keywords'
                                value='Poor Memory'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Poor Memory
                              </Button>
                              <Button
                                name='keywords'
                                value='Dishonest'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Dishonest
                              </Button>
                              <Button
                                name='keywords'
                                value='Uncertain'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Uncertain
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.keywords.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('keywords')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('keywords')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.objections.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('objections')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('objections')}
                        />
                      )}
                      {this.props.splitState === true && (
                        <>
                          {this.state.focus.objections.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('objections')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('objections')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Objections</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleObjection}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.objections.value}
                          onChange={this.handleChange}
                          name='objections'
                          onBlur={this.saveItem}
                          ref={this.objectionsRef}
                        />
                        {this.state.focus.objections.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='objections'
                                value='Hearsay'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Hearsay
                              </Button>
                              <Button
                                name='objections'
                                value='Relevance'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Relevance
                              </Button>
                              <Button
                                name='objections'
                                value='Requires An Expert'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Requires An Expert
                              </Button>
                              <Button
                                name='objections'
                                value='Character Evidence'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Character Evidence
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.objections.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('objections')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('objections')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.open.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('open')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('open')}
                        />
                      )} */}
                      {/* {this.props.splitState === true && (
                        <>
                          {this.state.focus.open.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('open')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('open')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Opening Question</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleOpenQuestion}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.open.value}
                          onChange={this.handleChange}
                          name='open'
                          onBlur={this.saveItem}
                          ref={this.openRef}
                        />
                        {this.state.focus.open.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='open'
                                value='We Can Agree That You’ve Got A Poor Memory'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                We Can Agree That You’ve Got A Poor Memory
                              </Button>
                              <Button
                                name='open'
                                value='We Can Agree That Your A Thief'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                We Can Agree That Your A Thief
                              </Button>
                              <Button
                                name='open'
                                value='You are a Friend'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                You are a Friend
                              </Button>
                              <Button
                                name='open'
                                value=' Relative Of The Complanent'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Relative Of The Complanent
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.open.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('open')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('open')}
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>
                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.closing.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) => this.handleImportantRow('closing')}
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) => this.handleImportantRow('closing')}
                        />
                      )} */}
                      {/* {this.props.splitState === true && (
                        <>
                          {this.state.focus.closing.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) => this.handleComment('closing')}
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) => this.handleComment('closing')}
                            />
                          )}
                        </>
                      )} */}
                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Closing Question</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleCloseQuestion}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.closing.value}
                          onChange={this.handleChange}
                          name='closing'
                          onBlur={this.saveItem}
                          ref={this.closingRef}
                        />
                        {this.state.focus.closing.value.trim() == '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='closing'
                                value='We Can Agree That You’ve Got A Poor Memory'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                We Can Agree That You’ve Got A Poor Memory
                              </Button>
                              <Button
                                name='closing'
                                value='We Can Agree That Your A Thief'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                We Can Agree That Your A Thief
                              </Button>
                              <Button
                                name='closing'
                                value='You Are A Friend'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                You Are A Friend
                              </Button>
                              <Button
                                name='closing'
                                value=' Relative Of The Complanent'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                {' '}
                                Relative Of The Complanent
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                        {/* {this.props.splitState === false && (
                          <>
                            {this.state.focus.closing.comments ? (
                              <img
                                className='comment'
                                src={require('../../../../pics/comment_submitted.svg')}
                                onClick={(e) => this.handleComment('closing')}
                              />
                            ) : (
                              <img
                                className='not_comment'
                                src={require('../../../../pics/comment.svg')}
                                onClick={(e) => this.handleComment('closing')}
                              />
                            )}
                          </>
                        )} */}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      {/* {this.state.focus.examination_tech.important ? (
                        <img
                          className='important'
                          src={require('../../../../pics/favourite_submitted.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('examination_tech')
                          }
                        />
                      ) : (
                        <img
                          className='not_important'
                          src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                          onClick={(e) =>
                            this.handleImportantRow('examination_tech')
                          }
                        />
                      )} */}
                      {/* {this.props.splitState === true && (
                        <>
                          {this.state.focus.examination_tech.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('examination_tech')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('examination_tech')
                              }
                            />
                          )}
                        </>
                      )} */}

                      <Form.Group controlId='s'>
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Cross-Examination Techniques</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleCrossExam}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Form.Group controlId='s'>
                        <TextareaAutosize
                          placeholder='Enter Notes Here'
                          value={this.state.focus.examination_tech.value}
                          onChange={this.handleChange}
                          name='examination_tech'
                          onBlur={this.saveItem}
                          ref={this.examination_techRef}
                        />
                        {info}
                        {this.state.focus.examination_tech.value.trim() ==
                          '' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                name='examination_tech'
                                value='Impeachment'
                                onClick={this.handleClick}
                                className='s-item'
                              >
                                Impeachment
                              </Button>
                              <Button
                                name='examination_tech'
                                value='Framing Questions'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Framing Questions
                              </Button>
                              <Button
                                name='examination_tech'
                                value='Exposing False Testimony'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                Exposing False Testimony
                              </Button>
                              <Button
                                name='examination_tech'
                                value='General Credibility'
                                className='s-item'
                                onClick={this.handleClick}
                              >
                                General Credibility
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      </Form.Group>
                      {/* {this.props.splitState === false && (
                        <>
                          {this.state.focus.examination_tech.comments ? (
                            <img
                              className='comment'
                              src={require('../../../../pics/comment_submitted.svg')}
                              onClick={(e) =>
                                this.handleComment('examination_tech')
                              }
                            />
                          ) : (
                            <img
                              className='not_comment'
                              src={require('../../../../pics/comment.svg')}
                              onClick={(e) =>
                                this.handleComment('examination_tech')
                              }
                            />
                          )}
                        </>
                      )} */}
                    </Col>
                  </Row>

                  {/* <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      <Form.Group controlId='s'>
               
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Documents</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleDoc}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Documents
                        witness={this.props.witness}
                        case={this.props.case}
                        firm={this.props.firm}
                        user={this.props.user}
                        darkMode={this.props.darkMode}
                        documents={this.state.focus.docs}
                        handleDocuments={this.handleDocuments}
                        handleImportantRow={this.handleImportantRow}
                        getDoc={this.props.getDoc}
                        getTab={this.props.getTab}
                        getFact={this.props.getFact}
                      />
                    </Col>
                  </Row> */}
                  {/* construction end */}

                  <Row className='saved-input focus'>
                    <Col md={3} className='ref'>
                      <Form.Group controlId='s'>
                        {/* {this.state.focus.topics &&
                        this.state.focus.topics.important ? (
                          <img
                            className='important'
                            src={require('../../../../pics/favourite_submitted.svg')}
                            onClick={(e) => this.handleImportantRow('topics')}
                          />
                        ) : (
                          <img
                            className='not_important'
                            src={require('../https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                            onClick={(e) => this.handleImportantRow('topics')}
                          />
                        )} */}
                        <Row>
                          <div className='hov-container-focus'>
                            <h4>Topics</h4>
                            <img
                              className='hoverHelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleTopic}
                            ></img>
                          </div>
                        </Row>
                      </Form.Group>
                    </Col>
                    <Col md={9} className='notes'>
                      <Topic
                        witness={this.props.witness}
                        case={this.props.case}
                        firm={this.props.firm}
                        user={this.props.user}
                        darkMode={this.props.darkMode}
                        topics={this.props.witness.topics}
                        handleTopics={this.props.handleTopics}
                        deleteDocs={this.deleteDocs}
                        getDoc={this.props.getDoc}
                      />
                    </Col>
                  </Row>
                </div>
              </Fade>
            </div>
          </Container>
        </div>
      </>
    );
  }
}
export default withRouter(Focus);
