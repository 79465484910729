import React, { Component } from 'react';

import { Button } from 'react-bootstrap';

import moment from 'moment';

// pdf
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

// Docx
import { saveAs } from 'file-saver';
import { Document as DocxDocument, Header, Packer, Paragraph } from 'docx';
import {
  getCrossExaminationChecklist,
  getFacts,
  getPrepChart,
  getTopics,
  getStatements,
  getCrossExamination,
  createHeading,
  createSubHeading,
  getTimelines,
} from '../../Shared/DocxExport';

const Pdf = (props) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;

  const output = <div></div>;

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 8,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: 'column',
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    subSection: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: '1 0 50%',
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    titleSM: {
      letterSpacing: 1,
      fontSize: 7,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: 'right',
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: 'right',
      textTransform: 'uppercase',
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColName: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColNameL: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
    },
    tableColValue: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableColValueL: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableCell: {
      margin: 'auto',
      marginTop: 0,
      fontSize: 11,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontWeight: 300,
      width: '100%',
      textOverflow: 'ellipsis',
      padding: 5,
    },
  });
  const styles2 = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 8,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: 'column',
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    subSection: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: '1 0 50%',
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: 'right',
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: 'right',
      textTransform: 'uppercase',
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColName: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColValue: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableCell: {
      margin: 'auto',
      marginTop: 0,
      fontSize: 11,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontWeight: 300,
      width: '100%',
      textOverflow: 'ellipsis',
      padding: 5,
    },
  });
  const styles3 = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 8,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: 'column',
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    subSection: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: '1 0 50%',
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: 'right',
    },
    titleSM: {
      letterSpacing: 1,
      fontSize: 7,
      textAlign: 'left',
      textTransform: 'uppercase',
    },

    image: {
      width: 50,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: 'right',
      textTransform: 'uppercase',
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 20,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColName: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColNameL: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
    },
    tableColValue: {
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableColValueL: {
      minHeight: 100,
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableCell: {
      margin: 'auto',
      marginTop: 0,
      fontSize: 11,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontWeight: 300,
      width: '100%',
      textOverflow: 'ellipsis',
      padding: 5,
    },
    card: {
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderLeftWidth: 0,
      borderTopWidth: 0,

      margin: 5,
      marginTop: 5,
      width: '93%',

      textOverflow: 'ellipsis',
      padding: 2,
    },
  });

  const factStyles = StyleSheet.create({
    page: {
      fontFamily: 'Helvetica',
      fontSize: 8,
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: 'column',
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      width: '100%',
    },
    subSection: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      flex: '1 0 50%',
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    titleSM: {
      letterSpacing: 1,
      fontSize: 7,
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: 'right',
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: 'right',
      textTransform: 'uppercase',
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: 'table',
      width: 'auto',
      borderStyle: 'solid',
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: 'auto',
      flexDirection: 'row',
    },
    tableColName: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColValue: {
      width: '30%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textOverflow: 'ellipsis',
    },

    tableColNameL: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
    },

    tableColValueL: {
      width: '70%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: 'ellipsis',
    },
    tableCell: {
      margin: 'auto',
      marginTop: 0,
      fontSize: 11,
      textAlign: 'left',
      fontFamily: 'Helvetica',
      fontWeight: 300,
      width: '100%',
      textOverflow: 'ellipsis',
      padding: 5,
    },
  });

  //timeline
  const timelineArr = [];
  for (let i = 0; i < props.events.length; i++) {
    const a = (
      <View style={styles.tableRow}>
        <View style={styles.tableColValue}>
          <Text style={styles.tableCell}>{moment(props.events[i].date, moment.ISO_8601).format('MMMM Do YYYY, h:mm a')}</Text>
        </View>

        <View style={styles.tableColValueL}>
          <Text style={styles.tableCell}>{props.events[i].name}</Text>
        </View>
      </View>
    );
    timelineArr.push(a);
  }

  const timeline = (
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={styles.subSection}>
          <Text style={styles.titleSM}>Timeline by Witness</Text>
          <Text style={styles.title}>{props.witness.name}</Text>
        </View>
        <View style={styles.subSection}>
          <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
          <Text style={styles.date}>{today}</Text>
        </View>
      </View>
      <View style={styles.section}>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColName}>
              <Text style={styles.tableCell}>Date</Text>
            </View>

            <View style={styles.tableColNameL}>
              <Text style={styles.tableCell}>Event</Text>
            </View>
          </View>
          {timelineArr}
        </View>
      </View>
    </Page>
  )

  

  // topic
  const arr = [];

  for (let item of props.topics) {
    const e = [];
    for (let i = 0; i < item.topic.length; i++) {
      const a = (
        <View style={styles.tableRow}>
          <View style={styles.tableColValue}>
            <Text style={styles.tableCell}>{item.topic[i].ref.value}</Text>
          </View>
          <View style={styles.tableColValueL}>
            <Text style={styles.tableCell}>{item.topic[i].disc.value}</Text>
          </View>
        </View>
      );

      e.push(a);
    }
    arr.push(
      <Page size='A4' style={styles.page}>
        <View style={styles.section}>
          <View style={styles.subSection}>
            <Text style={styles.titleSM}>
              Cross Examination Questions Topic
            </Text>
            <Text style={styles.title}>{item.name}</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
            <Text style={styles.date}>{today}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell}>References</Text>
              </View>
              <View style={styles.tableColNameL}>
                <Text style={styles.tableCell}>Questions</Text>
              </View>
            </View>
            {e}
          </View>
        </View>
      </Page>
    );
  }
  // facts
  const factsArr = [];
  for (let item of props.witness.facts) {
    const e = [];
    for (let i = 0; i < item.fact.length; i++) {
      const a = (
        <View style={factStyles.tableRow}>
          <View style={factStyles.tableColValue}>
            <Text style={factStyles.tableCell}>{item.fact[i].reference}</Text>
          </View>
          <View style={factStyles.tableColValueL}>
            <Text style={factStyles.tableCell}>{item.fact[i].evidence}</Text>
          </View>
        </View>
      );

      e.push(a);
    }
    factsArr.push(
      <Page size='A4' style={factStyles.page}>
        <View style={factStyles.section}>
          <View style={factStyles.subSection}>
            <Text style={factStyles.titleSM}>Facts By Topic</Text>
            <Text style={factStyles.title}>{item.name}</Text>
          </View>
          <View style={factStyles.subSection}>
            <Text style={factStyles.logo}>&copy; ALERI Inc. 2020</Text>
            <Text style={factStyles.date}>{today}</Text>
          </View>
        </View>
        <View style={factStyles.section}>
          <View style={factStyles.table}>
            <View style={factStyles.tableRow}>
              <View style={factStyles.tableColName}>
                <Text style={factStyles.tableCell}>References</Text>
              </View>
              <View style={factStyles.tableColNameL}>
                <Text style={factStyles.tableCell}>Evidence</Text>
              </View>
            </View>
            {e}
          </View>
        </View>
      </Page>
    );
  }

  // facts
  const summaryArr = [];
  for (let item of props.witness.statements) {
    const e = [];
    for (let i = 0; i < item.statement.length; i++) {
      const a = (
        <View style={factStyles.tableRow}>
          <View style={factStyles.tableColValue}>
            <Text style={factStyles.tableCell}>
              {item.statement[i].reference}
            </Text>
          </View>
          <View style={factStyles.tableColValueL}>
            <Text style={factStyles.tableCell}>
              {item.statement[i].evidence}
            </Text>
          </View>
        </View>
      );

      e.push(a);
    }
    factsArr.push(
      <Page size='A4' style={factStyles.page}>
        <View style={factStyles.section}>
          <View style={factStyles.subSection}>
            <Text style={factStyles.titleSM}>Statement Summaries</Text>
            <Text style={factStyles.title}>{item.name}</Text>
          </View>
          <View style={factStyles.subSection}>
            <Text style={factStyles.logo}>&copy; ALERI Inc. 2020</Text>
            <Text style={factStyles.date}>{today}</Text>
          </View>
        </View>
        <View style={factStyles.section}>
          <View style={factStyles.table}>
            <View style={factStyles.tableRow}>
              <View style={factStyles.tableColName}>
                <Text style={factStyles.tableCell}>References</Text>
              </View>
              <View style={factStyles.tableColNameL}>
                <Text style={factStyles.tableCell}>Evidence</Text>
              </View>
            </View>
            {e}
          </View>
        </View>
      </Page>
    );
  }

  // // prep

  const arr2 = [];
  for (let i = 0; i < props.witness.prepchart.length; i++) {
    const statements = [];
    const other_statements = [];
    for (let j = 0; j < props.witness.prepchart[i].statement.length - 1; j++) {
      statements.push(
        <View style={styles3.card}>
          <Text style={styles3.tableCell}>
            {props.witness.prepchart[i].statement[j].value}
          </Text>
        </View>
      );
    }
    for (
      let t = 0;
      t < props.witness.prepchart[i].other_statement.length - 1;
      t++
    ) {
      other_statements.push(
        <View style={styles3.card}>
          <Text style={styles3.tableCell}>
            {props.witness.prepchart[i].other_statement[t].value}
          </Text>
        </View>
      );
    }

    const a = (
      <View style={styles3.tableRow}>
        <View style={styles3.tableColValue}>
          <Text style={styles3.tableCell}>
            {props.witness.prepchart[i].fact.value}
          </Text>
        </View>
        <View style={styles3.tableColValue}>{statements}</View>
        <View style={styles3.tableColValue}>
          <Text style={styles3.tableCell}>
            {props.witness.prepchart[i].trial_evidence.value}
          </Text>
        </View>
        <View style={styles3.tableColValueL}>{other_statements}</View>
      </View>
    );

    arr2.push(a);
  }

  const pdf = (
    <PDFDownloadLink
      className='bob-link'
      target='_blank'
      document={
        <Document>
          <Page size='A4' style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.title}>Cross Examination</Text>
                <Text style={styles.titleS}>Case: {props.case.name}</Text>
                <Text style={styles.titleS}>Client: {props.case.client}</Text>
                <Text style={styles.titleS}>Witness: {props.witness.name}</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
          </Page>
          {/* <Page size='A4' style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.titleSM}>Cross Examination Questions</Text>
                <Text style={styles.title}>{props.witness.name}</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
              
          </Page> */}
          {timeline}
          {arr}

          <Page size='A4' style={styles3.page}>
            <View style={styles3.section}>
              <View style={styles3.subSection}>
                <Text style={styles3.titleSM}>
                  Cross Examination Preparation Chart
                </Text>
              </View>
              <View style={styles3.subSection}>
                <Text style={styles3.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles3.date}>{today}</Text>
              </View>
            </View>
            <View style={styles3.section}>
              <View style={styles3.table}>
                <View style={styles3.tableRow}>
                  <View style={styles3.tableColName}>
                    <Text style={styles3.tableCell}>Fact</Text>
                  </View>
                  <View style={styles3.tableColName}>
                    <Text style={styles3.tableCell}>Statements</Text>
                  </View>
                  <View style={styles3.tableColName}>
                    <Text style={styles3.tableCell}>Trial Evidence</Text>
                  </View>
                  <View style={styles3.tableColNameL}>
                    <Text style={styles3.tableCell}>Other Statements</Text>
                  </View>
                </View>
                {arr2}
              </View>
            </View>
          </Page>
          {factsArr}

          {summaryArr}
          <Page size='A4' style={styles2.page}>
            <View style={styles2.section}>
              <View style={styles2.subSection}>
                <Text style={styles2.title}>Cross Examination Checklist</Text>
              </View>
              <View style={styles2.subSection}>
                <Text style={styles2.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles2.date}>{today}</Text>
              </View>
            </View>
            <View style={styles2.section}>
              <View style={styles2.group}>
                <Text style={styles2.groupName}>Reliability Of Witness</Text>
                <Text style={styles2.groupStatus}>
                  {props.witness.checklist.reliability.status}
                </Text>
              </View>
              <View style={styles2.table}>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Bias and Motive - Financial Interest
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.reliability.bias.financial}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Bias and Motive - Friendship, Relative
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.reliability.bias.relationship}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Bias and Motive - Hostility
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.reliability.bias.hostility}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Impairment Alcohol Or Drugs
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .impairment_alcohol_or_drugs
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Pyhsical Deficiency (Eyesight, hearing etc)
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.reliability.physical_deficiency}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Focus Of
                      Attention Is Not On Event
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception.focus
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Witness
                      Preoccupied
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .witness_preoccupied
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Measures
                      Of Quantity, And Distance Are Often Unreliable
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .m_quantity_and_distance_not_reliable
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Desire
                      To Find Meaning
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .desire_to_find_meaning
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To See
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .establish_inability_to_see
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To Hear
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .establish_inability_to_hear
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Impact
                      Of Sudden Events
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .impact_of_sudden_events
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Insignificance Of Event
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .insignificance_of_event
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Ability
                      To Observe
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .ability_to_observe
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Expose
                      Belief From Habit
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .expose_belief_from_habit
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Reliability of Memory - Witnesses Forget Events Over Time,
                      And Subsequent Events Can Distort Memory
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .reliability_of_memory.forget_events
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Reliability of Memory - Memory Is Highly Flawed
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .reliability_of_memory.flawed
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Reliability of Memory - Experience, Social Values,
                      Prejudice And Emotions Can Affect The Way An Event Is
                      Remembered
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .reliability_of_memory.experience
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      {' '}
                      Reliability of Memory - Establish Poor Memory-Cross On
                      Collateral Details
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .reliability_of_memory.collateral
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Reliability of Memory - Influence Of Hearsay
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.reliability
                          .reliability_of_memory.influence
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles2.group}>
                <Text style={styles2.groupName}>
                  Plausibility and Consistency
                </Text>
                <Text style={styles2.groupStatus}>
                  {props.witness.checklist.plausibility_and_consistency.status}
                </Text>
              </View>
              <View style={styles2.table}>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Prior Inconsistent Statements - Accuse Of False Statement
                      At Trial
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.plausibility_and_consistency
                          .prior_inconsistent.accuse
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Prior Inconsistent Statements - Show The Circumstances Of
                      Prior Statement- Why More Reliable
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.plausibility_and_consistency
                          .prior_inconsistent.reliable
                      }
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Prior Inconsistent Statements - Confront With Prior
                      Statement
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.plausibility_and_consistency
                          .prior_inconsistent.confront
                      }
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Version Is Implausible Or Improbable
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.plausibility_and_consistency
                          .implausible
                      }
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Contradicted By Other Evidence Or Witnesses
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {
                        props.witness.checklist.plausibility_and_consistency
                          .contradicted
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles2.group}>
                <Text style={styles2.groupName}>Credibility Of Witness</Text>
                <Text style={styles2.groupStatus}>
                  {props.witness.checklist.credibility.status}
                </Text>
              </View>
              <View style={styles2.table}>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Prior Criminal Record</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.credibility.record}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      Evidence Of Misconduct Or Character
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.checklist.credibility.evidence}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
          <Page size='A4' style={styles2.page}>
            <View style={styles2.section}>
              <View style={styles2.subSection}>
                <Text style={styles2.title}>Focus Of Cross Examination</Text>
              </View>
              <View style={styles2.subSection}>
                <Text style={styles2.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles2.date}>{today}</Text>
              </View>
            </View>
            <View style={styles2.section}>
              <View style={styles2.table}>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Witness Name</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.name.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Relationship To Case</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.relationship.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      {`How They Advance \nOpponent's Case`}
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.opposition_adv.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Bias / Motivation</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.bias.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Strengths</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.strength.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Weaknesses</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.weaknesses.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Facts To Bring Out</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.fact.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      {`Goals / Focus Of \nExamination`}
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.goals.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Key Words</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.keywords.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Objections</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.objections.value}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Opening Question</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.open.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Closing Question</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.closing.value}
                    </Text>
                  </View>
                </View>
                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>
                      {`Cross-Examination \nTechniques`}
                    </Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.examination_tech.value}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Documents</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.documents.value}
                    </Text>
                  </View>
                </View>

                <View style={styles2.tableRow}>
                  <View style={styles2.tableColName}>
                    <Text style={styles2.tableCell}>Topics</Text>
                  </View>
                  <View style={styles2.tableColValue}>
                    <Text style={styles2.tableCell}>
                      {props.witness.focus.topics.value}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName='witness.pdf'
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button className='btn action  btn-b btn btn-primary btn-block'>
            Loading PDF To Export
          </Button>
        ) : (
          <Button className='btn action  btn-b btn btn-primary btn-block'>
            Export To PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return <>{pdf}</>;
};

const Word = (props) => {
  const generate = () => {
    // const doc = new DocxDocument();
    let docArr = [];

    const witness = props.witness;
    const events = props.events;

    const header = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Cross Examination'),
        createSubHeading(`Case: ${props.case.name}`),
        createSubHeading(`Client: ${props.case.client}`),
        createSubHeading(`Witness: ${witness.name} `),
      ],
    });

    docArr.push(header);

    //Timeline
    const timelines = getTimelines([{ name: witness.name, timeline: events }])
  
    // Topics
    const topics = getTopics(witness.topics);

    // Facts
    const facts = getFacts(witness.facts);

    // Statements
    const summaries = getStatements(witness.statements);

    // Prep Chart
    const prep = getPrepChart(witness.prepchart);

    // Cross Examination Checklist
    const checklist = getCrossExaminationChecklist(witness.checklist);

    // Focus Of Cross Examination
    const focus = getCrossExamination(witness.focus);

    docArr = docArr.concat(timelines, topics, facts, summaries, prep, checklist, focus);

    const doc = new DocxDocument({
      sections: docArr,
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${witness.name}.docx`);
    });
  };

  return (
    <Button
      className='btn action  btn-b btn btn-primary btn-block'
      onClick={generate}
    >
      Export To Word(docx)
    </Button>
  );
};

const WitExport = (props) => {
  let events = [];
  for (const timeline of props.timelineByWitness) {
    if(timeline[props.witness.name]) {
      events = timeline[props.witness.name];
    }
  }

  return (
    <>
      <Pdf {...props} events={events} />
      <Word {...props} events={events}/>
    </>
  );
};

export default WitExport;
