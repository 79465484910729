import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import TopicModal from './TopicModal';
export default class Topic extends Component {
  constructor() {
    super();
    this.state = {
      createLink: false,
      linkErr: '',

      showDelete: false,
      topic: {},
      showUpdate: false,
      updateErr: '',
      show: false,
    };
    this.linkRef = React.createRef();
  }
  handleCreateClose = () => {
    this.setState({
      createLink: false,
      linkErr: false,
    });
  };
  handleDeleteClose = () => {
    this.setState({
      showDelete: false,
      topic: {},
      updateErr: '',
    });
  };
  handleDeleteOpen = (doc, topics) => {
    this.setState({
      showDelete: true,
      topic: doc,
    });
  };
  handleUpdateOpen = (doc) => {
    this.setState({
      showUpdate: true,
      topic: doc,
    });
  };
  handleUpdateClose = () => {
    this.setState({
      showUpdate: false,
      topic: {},
    });
  };
  onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let listName = source.droppableId;
      const newList = this.reorder(
        this.props.topics,
        source.index,
        destination.index,
        source,
        destination
      );
    }
  };

  reorder = (list, startIndex, endIndex, source, destination) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);
    console.log(result);
    axios
      .post(prod() + '/api/cases/update/witness/topic-reorder', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        newTopics: result,
      })

      .then((response) => {
        console.log('Reorder response');
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.handleTopics(result);
  };

  handleDelete = () => {
    // console.log('HANDLE', this.state.topic);
    // if (this.state.topic.ref.document) {
    //   const locations = this.state.topic.ref.document.locations;
    //   console.log(locations);
    //   let update = false;
    //   if (locations != null) {
    //     for (let i = 0; i < locations.length; i++) {
    //       if (
    //         locations[i].path == '/case/witness/topic' &&
    //         locations[i].topic.toString() == this.props.topic['_id'].toString()
    //       ) {
    //         console.log('called');
    //         let count = 0;
    //         for (let a of this.state.allTopics) {
    //           if (
    //             a.ref.document &&
    //             a.ref.document._id.toString() ==
    //               this.props.topic['_id'].toString()
    //           ) {
    //             count++;
    //           }
    //           if (count == this.state.allTopics.length) {
    //             locations.splice(i, 1);
    //           }
    //         }
    //         break;
    //       }
    //     }
    //   } else {
    //     locations = [];
    //   }
    //   if (update == false) {
    //     axios
    //       .post(prod() + '/api/documents/update/loc', {
    //         case: this.props.case['_id'],
    //         witness: this.props.witness['_id'],
    //         document: this.state.topic.ref.document['_id'],
    //         loc: locations,
    //       })
    //       .then((use) => {
    //         console.log(use.data);
    //       })
    //       .catch((e) => {
    //         console.log('Invalid Input, Please Try Again');
    //       });
    //   }
    // }
    this.props.deleteDocs(this.state.topic);

    axios
      .post(prod() + '/api/cases/delete/witness/topic', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
        topic: this.state.topic['_id'],
      })
      .then((use) => {
        axios
          .post(prod() + '/api/cases/getall/witness/topic', {
            case: this.props.case['_id'],
            witnesses: this.props.witness['_id'],
          })
          .then((use) => {
            this.setState(
              {
                showDelete: false,
                topics: use.data.topics,
                topic: {},
              },
              () => {
                this.props.handleTopics(use.data.topics);
              }
            );
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
  };

  handleUpdate = (data) => {
    console.log(this.state.topic);
    console.log(data);
    axios
      .post(prod() + '/api/cases/update/witness/topic/name', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        topic: this.state.topic['_id'],
        name: data.name,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.props.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].topics.length; j++) {
              if (
                use.data.case.witnesses[i].topics[j]['_id'] ==
                this.state.topic['_id']
              ) {
                this.setState(
                  {
                    showUpdate: false,
                    topic: {},
                    topics: use.data.case.witnesses[i].topics,
                  },
                  () => {
                    this.props.handleTopics(use.data.case.witnesses[i].topics);
                  }
                );

                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          user: 'Topic already exist',
        });
      });
  };

  handleSubmit = (data) => {
    // create

    const topic = {
      name: data.name,
      witness: this.props.witness['_id'],
      case: this.props.case['_id'],
    };

    if (topic.name != '' && topic.witness != undefined && topic.case != '') {
      axios
        .post(prod() + '/api/cases/create/witness/topic', topic)
        .then((use) => {
          axios
            .post(prod() + '/api/cases/getall/witness/topic', {
              case: this.props.case['_id'],
              witnesses: this.props.witness['_id'],
            })
            .then((use) => {
              console.log(use.data.topics);
              this.setState(
                {
                  topics: use.data.topics,
                  createLink: false,
                  name: '',
                },
                () => {
                  this.props.handleTopics(use.data.topics);
                }
              );
            })
            .catch((err) => {
              console.log('MSG in err', err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            user: 'Topic already exist',
          });
        });
    } else {
      this.setState({
        user: 'Please fill out all topic fields',
      });
    }
  };
  render() {
    const topics = [];
    const tops = [];
    if (this.props.topics != undefined) {
      for (let i = 0; i < this.props.topics.length; i++) {
        console.log(this.props.topics[i]);
        tops.push(
          <Draggable
            isDragDisabled={this.props.topics.length === 0}
            key={'topics' + i}
            draggableId={'topics' + i}
            index={i}
            tabIndex={-1}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                tabIndex={-1}
                className='f9 unfocus'
                onMouseDown={(e) => e.currentTarget.focus()}
              >
                <div
                  className={
                    this.props.darkMode === true
                      ? 'border-bx dark-border-bx document-box'
                      : 'border-bx document-box'
                  }
                >
                  <div className='card-type'>
                    <span>Topic</span>
                  </div>
                  <div className='card-close'>
                    <img
                      onClick={(e) =>
                        this.handleUpdateOpen(this.props.topics[i])
                      }
                      className='logo-title document-u'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                    ></img>
                    <img
                      onClick={(e) =>
                        this.handleDeleteOpen(
                          this.props.topics[i],
                          this.props.topics
                        )
                      }
                      className='logo-title document-x'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                    ></img>
                  </div>
                  <Form.Group controlId={i}>
                    <a
                      target='_blank'
                      // href={this.props.topics[i].link}
                      onClick={() => {
                        console.log(this.props.topics[i]);
                        const data = [];
                        data.path = '/case/witness/topic';
                        data.caseId = this.props.case;
                        data.witnessId = this.props.witness;
                        data.topicId = this.props.witness.topics[i];
                        this.props.getDoc(data);
                      }}
                      className='link_doc'
                    >
                      <Form.Control
                        as='textarea'
                        rows={1}
                        value={this.props.topics[i].name}
                        readOnly
                        className='link_doc focus_topic'
                      />
                    </a>
                  </Form.Group>
                </div>
              </div>
            )}
          </Draggable>
        );
      }
    }
    console.log(tops);
    return (
      <>
        <Form.Group controlId='s'>
          {tops.length > 0 ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId={'topics'}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} id='focus-document-list'>
                    {tops}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <TextareaAutosize
              // as='textarea'
              placeholder='Create A Topic'
              // rows={tops.rows}
              readOnly
              name='topics'
              className='document-text'
            />
          )}
          <div className='suggestion'>
            <div></div>
            <div className='s-items'>
              {/* <Button
                name='upload'
                onClick={this.handleClickUpload}
                className='s-item-documents'
              >
                Upload
              </Button> */}
              <Button
                name='link'
                className='s-item-documents'
                onClick={() =>
                  this.setState({ createLink: true }, () => {
                    this.linkRef.current.focus();
                  })
                }
              >
                Create Topic
              </Button>
            </div>
            <div></div>
          </div>
        </Form.Group>
        <TopicModal
          createLink={this.state.createLink}
          linkRef={this.linkRef}
          handleSubmit={this.handleSubmit}
          handleCreateClose={this.handleCreateClose}
          linkErr={this.state.linkErr}
          darkMode={this.props.darkMode}
          showDelete={this.state.showDelete}
          handleDelete={this.handleDelete}
          handleDeleteClose={this.handleDeleteClose}
          showUpdate={this.state.showUpdate}
          handleUpdate={this.handleUpdate}
          handleUpdateClose={this.handleUpdateClose}
          updateErr={this.state.updateErr}
          name={this.state.topic.name}
        />
      </>
    );
  }
}
