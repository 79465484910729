import React, { Component } from 'react';

import { Container } from 'react-bootstrap';
export default class Framing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.second != true) {
      this.props.getTab({
        case: '',
        witness: '',
        topic: '',
        path: '/information/framing',
        name: 'Framing Questions',
        active: true,
      });
    }
  }
  render() {
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash' className='wit-dash-w'>
        <Container fluid>
          <div className='info_and_tech'>
            <h2>Coming Soon</h2>
            {/* <h2>Framing Questions</h2>
            <h1>Leading Questions/Statements</h1>
            <p>
              In order not maintain control over the witness, and the direction
              of the cross-examination, you will normally want to avoid
              open-ended questions that begin with words like why. You do not
              want to invite the witness to take control of the
              cross-examination. You will normally want to use leading questions
              that direct the witness in the way you want the examination to
              proceed. There is also a theory the you may want to not even ask
              questions but only use short statements that leave out the parts
              like “isn’t it true” or “isn’t that correct”. In MacCarthy on
              Cross-examination (at page 89-90) it is explained that this method
              of short statements accomplishes the goals of controlling the
              witness with the short statements, and it puts the focus of the
              cross-examination on the cross-examiner as the person who is
              telling a story as opposed to the witness. In these short
              statements you should avoid arguments or opinions, and the
              statements should be as short as possible.
            </p>
            <h1>Trilogies</h1>
            <h6>
              A trilogy is a technique that draws attention to an important fact
              or theme. Trilogies are often used in argument because an argument
              can appear more compelling and attractive it has three components.
              The same technique, that is used in arguments, can be use in
              cross-examination. It is done by first determining an important
              fact in the case. You would then either aske three leading
              questions that either:
            </h6>
            <ul>
              <li>establish the same fact in three different manners</li>
              <li>
                establish the fact when the three questions are combine
                together.{' '}
              </li>
            </ul>
            <h1>Loops</h1>
            <h6>
              Loops are a method of drawing attention to an important fact.
              Loops enable an element of repetition of the important fact
              without giving the opponent the ability to object to the questions
              for being repetitive. A loop is done by:
            </h6>
            <ul>
              <li>asking a leading question to establish a fact</li>
              <li>
                including the fact from the first question in the body of the
                next question (this highlights the fact by repeating it).
              </li>
            </ul>
            <h1>Juxtaposition </h1>
            <h6>
              {' '}
              This technique takes a past event and compares it to the present
              situation to suggest that people tend to act in the manner in
              which they have in the past. In Cross-Examination: Science and
              Techniques, Larry Pozner and Roger Dodd, 5th Ed., at page 578 it
              is explained as:
            </h6>
            <p className='quote'>
              “…first highlight those specific acts from one chapter that
              require and will produce a “yes” answer. We form the second
              juxtaposition chapter with as many factual parallels as possible
              to the first chapter. When drafting the tow chapters to be
              juxtaposed, we must focus on detailed facts. The more parallel the
              facts of the two chapters, the more logical and believable the
              inference we seek to draw that the conduct denied had occurred.”
            </p> */}
          </div>
        </Container>
      </div>
    );
  }
}
