import React, { Component, createRef } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  Form,
  RBGlyph,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';
import DropboxChooser from 'react-dropbox-chooser';
import { FaDropbox } from 'react-icons/fa';

import '../../Shared/UploadDocument.css';

// import FilePreviewModalE from './FilePreviewModal_existingrow';
// import FilePreviewModalN from './FilePreviewModal_newrow';

export default class UploadDocument extends Component {
  constructor() {
    super();
    this.state = {
      // linkName: '',
      // linkLocation: 'https://',

      docType: 'file',

      fileName: '', //file name displayed on app
      fileLocation: '',
      fileErr: '',

      fileInfo: '',
      fileModal: false,
      fileModalN: false,
      uploadableFileTypes: ['application/pdf', 'audio/mpeg'],

      gcFileName: '', //file name on google cloud

      showCreateNewDoc: false,
      showAddExistingDoc: false,

      //dropbox
      dropbox: false,
    };

    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.openFileViewModal = this.openFileViewModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.fileRef = createRef();
  }

  isURL = (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  };

  createLink = (data) => {
    console.log(data);
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios.post(prod() + '/api/documents/create', {
          name: data.linkName,
          link: data.linkLocation,
          case: this.props.case['_id'],
          user: s.data.id,
          firm: s.data.firm,
          witness: this.props.witness['_id'],
          type: data.type, // Link or file
          locations: [],
        });
      });
    }
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  componentDidMount() {
    axios
      .post(prod() + '/api/documents/getall', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
      })
      .then((a) => {
        this.setState({
          docs: a.data.documents,
        });
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }
  componentWillReceiveProps(newProps) {
    if (newProps.linkErr !== this.props.linkErr) {
      this.setState({
        linkName: '',
        linkLocation: '',
      });
    }
    if (
      newProps.linkName != this.props.linkName ||
      newProps.linkLocation != this.props.linkLocation
    ) {
      console.log('BEFORE DOCS');
      axios
        .post(prod() + '/api/documents/getall', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
        })
        .then((a) => {
          console.log(a.data.documents);
          this.setState({
            docs: a.data.documents,
            linkName: newProps.linkName,
            linkLocation: newProps.linkLocation,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  onFileChange(e) {
    // if the fileName already exists because the user entered the optional name, then don't change the filename to the actual file name
    let docType;
    if (e.target.files[0].type === 'audio/mpeg') docType = 'audio';
    console.log("file change: ", e.target.files[0])
    if (this.state.fileName) {
      this.setState({ fileInfo: e.target.files[0], docType });
    } else {
      this.setState({
        fileInfo: e.target.files[0],
        fileName: e.target.files[0].name,
        docType,
      });
    }
  }

  componentDidUpdate(prevState) {
    if (
      prevState.fileInfo === undefined &&
      Object.keys(this.state.fileInfo).length > 0
    ) {
      console.log('axios call here!');
    }
  }

  // when a user clicks on 'upload' button, it uploads to google doc and sends back the google cloud uploaded file info including the link
  uploadFile() {
    console.log('UPLOADING FILE (TO GOOGLE CLOUD)');
    // const url = prod() + '/api/cases/update/witness/uploadFile';
    const url = prod() + '/api/cases/update/witness/uploadFile';
    const file = this.state.fileInfo;
    const data = new FormData();
    data.append('fileInfo', file);

    if (!file) {
      this.setState({ fileErr: 'Please choose a file' });
    } else if (file && !this.state.uploadableFileTypes.includes(file.type)) {
      this.setState({
        fileErr: 'File type is not supported. Supported file type: pdf, mp3',
      });
    } else if (file && this.state.uploadableFileTypes.includes(file.type)) {
      if (this.props.refId) {
        this.setState({ fileModal: true });
      } else {
        this.setState({ fileModalN: true });
      }
      axios({
        method: 'post',
        url: url,
        data: data,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      }).then((res) => {
        console.log('uploaded file info from gc: ', res.data);
        this.setState({
          fileLocation: res.data.path,
          gcFileName: res.data.filename,
        });

        this.props.handleSubmit({
          linkName: this.state.fileName,
          linkLocation: this.state.fileLocation,
          type: this.state.docType,
        });
        this.setState(
          {
            fileName: '',
            fileLocation: '',
          },
          () => {
            this.props.openUploadDocModal(false);
          }
        );
      });
    }
  }

  openFileViewModal(open) {
    const file = this.state.fileInfo;
    if (this.props.refId) {
      if (file && this.state.uploadableFileTypes.includes(file.type)) {
        this.setState({ fileModal: open });
      }
    } else {
      this.setState({ fileModalN: open });
    }
  }

  closeModals() {
    this.setState({ showCreateNewDoc: false, showAddExistingDoc: false });
  }

  deleteFile() {
    const url = prod() + '/api/cases/update/witness/deleteFile';
    const filename = this.state.gcFileName;
    axios.post(url, { filename }).then(() => {
      this.setState({
        fileInfo: '',
        fileName: '',
        fileLocation: '',
        gcFileName: '',
      });
    });
  }

  handleDropboxSuccess = (files) => {
    //
    console.log("files: ", files)
    //"https://www.dropbox.com/s/ruixvjprdywhl8w/sha531_transcripts.pdf?dl=0"
    //change to http://dl.dropboxusercontent.com/s/{filename}
    const dropboxLink = files[0].link;
    const index = dropboxLink.indexOf("www.dropbox.com/s/") + 18;
    const filePath = dropboxLink.slice(index);
    // console.log(filePath)
    //link that is not blocked by CORS policy
    const updatedDropboxLink = "https://dl.dropboxusercontent.com/s/" + filePath;

    if (!files[0].name.includes('pdf')) {
      this.setState({ fileErr: 'You can only upload pdfs'})
    } else if (files.length > 1) {
      this.setState({ fileErr: 'You can only choose one file at a time', 
      fileName: files[0].name,
      fileLocation: updatedDropboxLink,
      dropbox: true, 
    });
    } else {
      this.setState({ 
        fileName: files[0].name,
        fileLocation: updatedDropboxLink,
        fileErr: '',
        dropbox: true,
      });
    }
  }

  render() {
    // console.log("ROW ID OF THE CLICKED ROW: ", this.props.refId)
    let listItems = [];
    if (this.state.docs && this.state.docs.length > 0) {
      for (let a of this.state.docs) {
        listItems.push(
          <div
            className='listItem'
            onClick={() => {
              if (this.props.refId) {
                this.props.addExistingDocOnRow(a, this.props.refId);
              } else {
                this.props.addExistingDoc(a);
              }
              this.setState({ showAddExistingDoc: false });
              this.props.openUploadDocModal(false);
            }}
          >
            <p>{a.name}</p>
            <p>{a.type === 'Link' ? a.link : 'Uploaded on Aleri'}</p>
          </div>
        );
      }
    } else {
      listItems = <p className='no_ref'>No Documents Referenced</p>;
    }
    let listItemsExisting = [];
    if (this.state.docs && this.state.docs.length > 0) {
      for (let a of this.state.docs) {
        listItemsExisting.push(
          <div
            className='listItem'
            onClick={() => this.props.addExistingLink(a)}
          >
            <p>{a.name}</p>
            <p>{a.link}</p>
          </div>
        );
      }
    } else {
      listItemsExisting = <p className='no_ref'>No Documents Referenced</p>;
    }
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={
            this.props.uploadDocModal
              ? this.props.uploadDocModal
              : this.props.uploadDocModalE
          }
          onHide={() => {
            this.setState({
              linkName: '',
              linkLocation: 'https://',
              fileName: '',
              fileInfo: {},
            });
            this.props.openUploadDocModal(false);
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach File</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.fileErr && this.state.docType === 'file' && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.fileErr}
                </div>
              )}
              <Form.Group controlId='fileName'>
                <Form.Label>Name(optional)</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The File'
                  value={this.state.fileName ? this.state.fileName : ''}
                  onChange={this.handleChange}
                  name='fileName'
                  ref={this.props.docRef}
                />
              </Form.Group>
              {/* )} */}
              <Form.Group controlId='linkLocation'>
                <Form.Label>Location</Form.Label>

                <div>
                  <input
                    type='file'
                    name='fileInfo'
                    className='choose-file'
                    onChange={this.onFileChange}
                  />
                  <div className="dropbox">
                    <DropboxChooser 
                      appKey={'ui01g9o2ch2wmw7'}
                      success={this.handleDropboxSuccess}
                      cancel={() => console.log("cancel")}
                      multiselect={true}
                      extensions={['.pdf']} 
                    >
                      <div>
                        <FaDropbox size={25} className="dropbox-icon"/>       
                        <span style={{ marginLeft: '10px'}}>Dropbox</span>
                      </div>
                    </DropboxChooser>
                  </div>
                </div>
                {/* )} */}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  if (this.state.dropbox) {
                    this.props.handleSubmit({
                      linkName: this.state.fileName,
                      linkLocation: this.state.fileLocation,
                      type: 'file',
                    })
                    this.setState(
                      {
                        fileName: '',
                        fileLocation: '',
                      },
                      () => this.props.openUploadDocModal(false));
                  } else {
                    this.uploadFile(
                      this.state.fileInfo.name,
                      this.state.fileInfo.type
                    );
                  }
                }}
                variant='primary'
              >
                Upload
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* add */}
        {/* ??? what is this modal for -JJ */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.addLinkState}
          onHide={() =>
            this.setState(
              {
                linkName: '',
                linkLocation: '',
              },
              () => {
                this.props.handleAddClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.linkErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.linkErr}
                </div>
              )}
              <h5>Create New Document</h5>
              <Form.Group controlId='linkName'>
                <Form.Label>Name(optional)</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The File'
                  value={this.state.linkName ? this.state.linkName : ''}
                  onChange={this.handleChange}
                  name='linkName'
                  ref={this.props.addRef}
                />
              </Form.Group>
              <Form.Group controlId='linkLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The URL Of The File'
                  value={this.state.linkLocation ? this.state.linkLocation : ''}
                  onChange={this.handleChange}
                  name='linkLocation'
                />
              </Form.Group>
              <h5>Add Existing Document</h5>
              <div className='documentList'>{listItemsExisting}</div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.addLink(
                    {
                      linkName:
                        this.state.linkName != '' &&
                        this.state.linkName != undefined &&
                        this.state.linkName != null
                          ? this.state.linkName
                          : this.state.linkLocation,
                      linkLocation: this.state.linkLocation,
                    },
                    () => {
                      this.setState({
                        linkName: '',
                        linkLocation: '',
                      });
                    }
                  );
                }}
                variant='primary'
              >
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showDelete}
          onHide={this.props.handleDeleteClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this document?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleDeleteClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
