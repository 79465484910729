import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import TopicExport from './TopicExport';
import { Link } from 'react-router-dom';
export default class TopicModal extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uName: '',
      topicName: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    this.props.handleWSubmit({ name: this.state.wName }, e);
    this.setState({ wName: '' });
  }
  componentDidMount() {
    this.setState({
      uName: this.props.uWName,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.uWName !== this.props.uWName) {
      this.setState({
        uName: this.props.uWName,
      });
    }
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wSShow}
          onHide={this.props.handleWSClose}
        >
          <Form
            onSubmit={(e) =>
              this.props.handleWSSubmit({ name: this.state.uName }, e)
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.errors && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors}
                </div>
              )}
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Topic Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.props.edit}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.props.handleWSClose}
              >
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wDShow}
          onHide={this.props.handleWDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this topic?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleWDClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteWitness}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this Question?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteTopic}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Question Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TopicExport topic={this.props.topic} q={this.props.q} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What Is A Topic Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The cross-examination should have one overall goal or theme.
                  Within the general theme or goal, you will break the
                  cross-examination into individual topics. Each topic is a
                  group of questions that designed to achieve a goal that
                  supports the overall theory of your case. The topics will vary
                  on the nature of the case and the witness. They could include
                  issues of motive or bias, impeachment of the witness, cutting
                  off escape routes for a later impeachment or any number of
                  relevant topics. You will assign names for each topic, create
                  a list of the topics you intend to use, and then create
                  questions within the individual topics. The questions for each
                  topic should be one page or under. If the topic is too long
                  then you would break it into multiple topics.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/settings/support'>
                <Button variant='outline-secondary'>Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Topic</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.user && (
              <div className='invalid-feedback invalid-feedback-c'>
                {this.props.user}
              </div>
            )}
            <Form.Group controlId='caseName'>
              <Form.Label>Topic Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Topic Name'
                value={this.state.topicName}
                onChange={this.onChange}
                name='topicName'
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleClose}>
              Close
            </Button>
            <Button
              variant='primary'
              type='submit'
              onClick={(e) => this.props.handleSubmit(e, this.state.topicName)}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this Question?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteTopic}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dTShow}
          onHide={this.props.handleDTClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this topic?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDTClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleDTSubmit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reference}
          onHide={this.props.hideReference}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReference();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>References</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Description of any document with page and line reference.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.question}
          onHide={this.props.hideQuestion}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideQuestion();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Set out questions.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.note}
          onHide={this.props.hideNote}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideNote();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Make any notes during trial of new versions of the evidence.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}
