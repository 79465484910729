import store from '../../store';
//auth
import jwt_decode from 'jwt-decode';
import setAuthToken from '../../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../../actions/authActions';

const sessionExpire = () => {
  //set auth token
  setAuthToken(localStorage.jwtToken);
  //decode token
  const decoded = jwt_decode(localStorage.jwtToken);
  // set isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //check for expired token
  const currentTime = Date.now() / 1000;
  //logout user

  console.log('called test');
  if (decoded.exp < currentTime) {
    console.log('called test 2');
    //clear all state
    store.dispatch(logoutUser);
    //redirect user to home

    window.location.href = '/';
    localStorage.clear();
  }
}

export { sessionExpire };