import React, { Component } from 'react';
import { Nav, Navbar, Button, Form, Modal } from 'react-bootstrap';
import FactExport from './FactExport';
export default class FactModal extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uName: '',
      factName: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    this.props.handleWSubmit({ name: this.state.wName }, e);
    this.setState({ wName: '' });
  }
  componentDidMount() {
    this.setState({
      uName: this.props.uWName,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.uWName !== this.props.uWName) {
      this.setState({
        uName: this.props.uWName,
      });
    }
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wSShow}
          onHide={this.props.handleWSClose}
        >
          <Form
            onSubmit={(e) =>
              this.props.handleWSSubmit({ name: this.state.uName }, e)
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.errors && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors}
                </div>
              )}
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Fact Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.props.edit}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.props.handleWSClose}
              >
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wDShow}
          onHide={this.props.handleWDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this fact?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleWDClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteWitness}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  */}

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this fact?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteFact}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Fact Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FactExport fact={this.props.fact} q={this.props.q} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Statement Facts By Topic</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  When you are preparing your questions, it is useful to have
                  the facts, from pre-trial statements, separated by topic. This
                  will allow you to prepare questions more easily as you will
                  have the pre-trial statement facts separate by topic when you
                  prepare questions on each topic. Each question topic can have
                  a corresponding document that lists the relevant facts for
                  that topic. The “Facts by Topic” document can then be put on a
                  split screen on the computer as you are drafting questions.
                  This gives a summary of the relevant portion. You can then go
                  directly to the pre-trial statement or testimony on split
                  screen, next to the questions, instead of the summary if you
                  want the exact wording to the pre-trial statement. These
                  statements can be exported and printed as PDF versions
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Fact</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.user && (
              <div className='invalid-feedback invalid-feedback-c'>
                {this.props.user}
              </div>
            )}
            <Form.Group controlId='caseName'>
              <Form.Label>Fact Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Fact Name'
                value={this.state.factName}
                onChange={this.onChange}
                name='factName'
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleClose}>
              Close
            </Button>
            <Button
              variant='primary'
              type='submit'
              onClick={(e) => this.props.handleSubmit(e, this.state.factName)}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this Fact?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteFact}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dTShow}
          onHide={this.props.handleFTClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this fact?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleFTClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleFTSubmit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.factRef}
          onHide={this.props.hideFactRef}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                factRef: !this.props.factRef,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Reference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Description of the statement with page and line reference</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.factEvi}
          onHide={this.props.hideFactEvi}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                factEvi: !this.props.factEvi,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Evidence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Summarized version of the evidence from a statement on one
                  topic.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}
