import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../../prod';
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ButtonGroup,
} from 'react-bootstrap';
import { Chrono } from 'react-chrono';
import TimelineExport from './TimelineExport';

import moment from 'moment';

import DatePicker from 'react-datepicker';
import { Player } from 'video-react';

import '../../../../../node_modules/video-react/dist/video-react.css';
import 'react-datepicker/dist/react-datepicker.css';

class Timeline extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
      error: '',
      loading: true,

      // add
      show: false,
      // help
      hShow: false,
      helpVideoShow: false,
      // export
      eShow: false,

      // event to be deleted
      deleteEvent: null,
      // event to be updated
      updateEvent: null,

      // Timeline
      events: [],
      mode: 'VERTICAL',

      // New Timeline Point
      // Name
      newEventName: '',
      // Date
      date: new Date(),
    };

    // help
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    // add to timeline
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.getTimelineItems = this.getTimelineItems.bind(this);

    this.fetchTimeline = this.fetchTimeline.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.tref = React.createRef();
  }

  fetchTimeline = () => {
    axios
      .get(prod() + '/api/cases/timeline/witness', {
        params: {
          caseId: this.props.case._id,
          witnessId: this.props.witness._id,
        },
      })
      .then((res) => {
        const response = res?.data?.events || [];
        const sortedEvents = this.sortEvents(response);
        this.setState({
          loading: false,
          events: sortedEvents,
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          events: [],
        });
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log('CALLEd');
    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.witness == undefined ||
      this.props.witness == null ||
      Object.keys(this.props.case).length === 0 ||
      Object.keys(this.props.witness).length === 0
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          topic: '',
          path: '/case/witness/timeline',
          name: 'Timeline',
          active: true,
        });
      }
    }
    this.fetchTimeline();
  }

  componentWillReceiveProps(newProps) {
    console.log('CALLEd');
    if (this.props.case != newProps.case) {
      console.log('RED');
      console.log('New', newProps.case);
      if (
        newProps.case == undefined ||
        newProps.case == null ||
        Object.keys(newProps.case).length === 0
      ) {
        this.setState({ redirect: true });
      } else {
        if (this.props.second != true) {
          this.props.getTab({
            case: this.props.case,
            witness: this.props.witness,
            topic: '',
            path: '/case/witness/timeline',
            name: 'Timeline',
            active: true,
          });
        }
      }
      this.fetchTimeline();
    }
  }

  // add to timeline
  handleClose = () => this.setState({ show: false, newEventName: '' });
  handleShow = () => {
    this.setState({ show: true }, () => {
      this.tref.current.focus();
    });
  };

  // onChange for New Event name
  onChange(e) {
    this.setState({ newEventName: e.target.value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const event = {
      name: this.state.newEventName,
      date: this.state.date,
      witnessId: this.props.witness._id,
      caseId: this.props.case._id,
    };

    if (
      event.name !== '' &&
      event.witnessId !== undefined &&
      event.caseId !== ''
    ) {
      axios
        .post(prod() + '/api/cases/create/witness/timeline', event)
        .then((res) => {
          this.setState({
            show: false,
            newEventName: '',
            date: new Date(),
            loading: true,
          });
          this.fetchTimeline();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            error: 'Error',
          });
        });
    } else {
      this.setState({
        error: 'Please fill out all event fields',
      });
    }
  };

  // export
  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      //axios call to get all the timeline data
    });

  handleEClose = () => this.setState({ eShow: false });

  // help
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  sortEvents = (events) => events.sort((a, b) => a.date.localeCompare(b.date));

  getTimelineItems = (items) => {
    console.log("Items in chrono: ", items);
    const events = items?.map((t) => {
      const dateFormatted = moment(t.date, moment.ISO_8601).format(
        'MMMM Do YYYY, h:mm a'
        );
        
        return {
          title: dateFormatted,
          cardTitle: t.name,
          cardSubtitle: t.witnessName,
        };
      })
      console.log("events: ", events)
    return events || [];  
  }

  handleDelete = (e) => {
    e.preventDefault();

    const event = {
      eventId: this.state.deleteEvent._id,
      witnessId: this.props.witness._id,
      caseId: this.props.case._id,
    };

    if (
      event.eventId !== '' &&
      event.witnessId !== undefined &&
      event.caseId !== ''
    ) {
      axios
        .post(prod() + '/api/cases/timeline/delete', event)
        .then((res) => {
          this.setState({
            deleteEvent: null,
            loading: true,
          });
          this.fetchTimeline();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            error: 'Error',
          });
        });
    } else {
      this.setState({
        error: 'Cannot delete the selected event',
      });
    }
  };

  handleUpdate = (e) => {
    e.preventDefault();

    const updatedEvent = {
      ...this.state.updateEvent,
      name: this.state.newEventName,
      date: this.state.date,
    };

    const event = {
      event: updatedEvent,
      witnessId: this.props.witness._id,
      caseId: this.props.case._id,
    };

    if (
      event.event._id !== '' &&
      event.witnessId !== undefined &&
      event.caseId !== ''
    ) {
      axios
        .post(prod() + '/api/cases/timeline/update', event)
        .then((res) => {
          this.setState({
            updateEvent: null,
            loading: true,
            newEventName: '',
            date: new Date(),
          });
          this.fetchTimeline();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            error: 'Error',
          });
        });
    } else {
      this.setState({
        error: 'Cannot update the selected event',
      });
    }
  };

  changeMode = (e) => {
    this.setState({ mode: e.target.name });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const firm = this.props.top ? 'add-btn-expanded' : 'add-btn';
    const firm2 = this.props.top ? 'wit-nav-expanded' : '';
    const mode = this.props.darkMode ? '#14111129' : 'white';
    const modeFont = this.props.darkMode ? 'white' : 'black';

    return (
      <div id="wit-dash">
        <Container fluid>
          <div>
            <Row id="dash-add">
              <Col md={5}>
                <div className="case-dash-container"></div>
                <h1 id={firm2}>Witness Timeline</h1>
              </Col>

              <Col md={7}>
                <div className={firm + " add-btn-p add-btn-i1"}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? "dark-tooltip-add"
                                : "tooltip-add"
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className="add-case">
                          <img
                            className="logo-title log"
                            src="https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg"
                          ></img>{" "}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                {/* Export button */}
                <div className={firm + " add-btn-p add-btn-i2"}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? "dark-tooltip-add"
                                : "tooltip-add"
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className="add-case">
                          <img
                            className="logo-title log"
                            src="https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg"
                          ></img>{" "}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={firm + " add-btn-p add-btn-i3"}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key="bottom"
                        placement="bottom"
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? "dark-tooltip-add"
                                : "tooltip-add"
                            }
                          >
                            Add to Timeline
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleShow} className="add-case plus-icon">
                          <img
                            className="logo-title plus-icon"
                            src="https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg"
                          ></img>{" "}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
            <Row style={{ marginTop: '10px', marginBottom: '10px'}}>View Mode: </Row>
            <Row>
              <ButtonGroup aria-label="Basic example" size="sm">
                <Button variant="secondary" active={this.state.mode === 'VERTICAL'} name="VERTICAL" onClick={this.changeMode}>Vertical</Button>
                <Button variant="secondary" active={this.state.mode === 'VERTICAL_ALTERNATING'} name="VERTICAL_ALTERNATING" onClick={this.changeMode}>Vertical-Alternating</Button>
                <Button variant="secondary" active={this.state.mode === 'HORIZONTAL'} name="HORIZONTAL" onClick={this.changeMode}>Horizontal</Button>
              </ButtonGroup>
            </Row>
          </div>

          {this.state.events.length === 0 && (
            <>
              <Row id='dash-desc' className='dash-desc-d'>
                <Col>
                  <p className='dash-d dash-d-d'>
                    Press The Plus Icon Below To Create A New Event
                  </p>
                </Col>
              </Row>
              <Row id='dash-add'>
                <Col style={{ textAlign: 'center'}}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                            }
                          >
                            Add to Timeline
                          </Tooltip>
                        }
                      >
                      <Button onClick={this.handleShow} className='add-case d plus-icon'>
                        <img
                          className='logo-title plus-icon'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                        ></img>{' '}
                      </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {/* {this.state.events.length === 0 && this.state.error && (
            <div style={{ textAlign: 'center' }}>
              Something went wrong, please try again later
            </div>
          )} */}

          {this.state.events.length > 0 && (
            <div
              className={this.props.darkMode ? "dark-modal" : ""}
              style={{
                width: "100%",
                height: "calc(90vh - 200px)",
                marginTop: "30px",
              }}
            >
              <Chrono
                key={`chrono-key${this.state.events.length}`}
                items={this.getTimelineItems(this.state.events)}
                mode={this.state.mode}
                slideShow
                slideItemDuration={2500}
                allowDynamicUpdate={true}
                cardHeight={100}
                theme={
                  {
                    // primary: 'rgb(148, 157, 176)',
                    // secondary: '#0f7ec0',
                    // cardBgColor: mode,
                    // cardForeColor: modeFont,
                  }
                }
              >
                {this.state.events.map((event) => {
                  console.log("this.state.events: ", this.state.events);
                  return (
                    <div>
                      <img
                        style={{ margin: "5px" }}
                        className="ref-logo-title"
                        src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg"
                        onClick={() => this.setState({ deleteEvent: event })}
                      />
                      <img
                        style={{
                          margin: "5px",
                          right: "35px",
                          position: "absolute",
                          top: "4px",
                          width: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          this.setState({
                            updateEvent: event,
                            newEventName: event.name,
                            date: new Date(event.date),
                          })
                        }
                        src="https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg"
                      />
                    </div>
                  );
                })}
              </Chrono>
            </div>
          )}
        </Container>

        {/* Add to Timeline Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? "dark-modal" : ""}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create New Timeline Point</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <div className="invalid-feedback invalid-feedback-c">
                  {this.state.error}
                </div>
              )}
              <Form.Group controlId="caseName">
                <Form.Label>Event Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Event"
                  value={this.state.newEventName ? this.state.newEventName : ""}
                  onChange={(e) => this.onChange(e)}
                  name="name"
                  ref={this.tref}
                />
                <div className="select-date">
                  <Form.Label>Date</Form.Label>
                  <div>
                    <DatePicker
                      className={
                        this.props.darkMode
                          ? "date-picker-dark"
                          : "date-picker-white"
                      }
                      selected={this.state.date}
                      onChange={(date) => this.setState({ date })}
                      showTimeSelect
                      dateFormat="MMMM d, yyyy h:mm aa"
                      value={this.state.date}
                      placeholderText="MM/DD/YYYY"
                    />
                  </div>
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Add Time Point
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Export Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? "dark-modal" : ""}
          show={this.state.eShow}
          onHide={this.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                eShow: false,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Timeline Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TimelineExport
                events={this.state.events}
                witness={this.props.witness}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Help Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? "dark-modal" : ""}
          show={this.state.hShow}
          onHide={this.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                hShow: false,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What is the Timeline??</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="help">
                <p>
                  The Timeline allows you to easily map out all of the facts
                  across in this specific witness in one simple document.
                </p>
                {/* <div>
                    <div onClick={() => this.setState({ helpVideoShow: !this.state.helpVideoShow })} className="help-video">
                      <span className="help-video_click" style={{ marginBottom: '1rem', color: '#2B7FC0' }}>Click to see Help Video</span>
                    </div>
                    {this.state.helpVideoShow && (
                      <Player>
                        <source 
                          src="https://storage.cloud.google.com/aleri-app-bucket/aleri-test-facttags-video.mp4?authuser=0"
                          width={200}
                        />
                      </Player>
                    )}
                  </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to="/settings/support">
                <Button variant="outline-secondary">Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant="secondary" onClick={this.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? "dark-modal" : ""}
          show={this.state.deleteEvent !== null}
          onHide={() => this.setState({ deleteEvent: null })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this timeline event?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type="submit"
              variant="secondary"
              onClick={() => this.setState({ deleteEvent: null })}
            >
              No
            </Button>
            <Button variant="primary" onClick={this.handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? "dark-modal" : ""}
          show={this.state.updateEvent !== null}
          onHide={() => this.setState({ updateEvent: null })}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Timeline</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.state.error && (
              <div className="invalid-feedback invalid-feedback-c">
                {this.state.error}
              </div>
            )}
            <Form.Group controlId="caseName">
              <Form.Label>Event Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Event"
                value={this.state.newEventName}
                onChange={(e) =>
                  this.setState({
                    newEventName: e.target.value,
                  })
                }
                name="name"
                ref={this.createRef}
              />
              <div className="select-date" style={{ marginTop: "10px" }}>
                <Form.Label>Date</Form.Label>
                <div>
                  <DatePicker
                    className={
                      this.props.darkMode
                        ? "date-picker-dark"
                        : "date-picker-white"
                    }
                    selected={this.state.date}
                    value={this.state.date}
                    onChange={(date) => this.setState({ date })}
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    placeholderText="MM/DD/YYYY"
                  />
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.setState({ updateEvent: null })}
            >
              Close
            </Button>
            <Button variant="primary" type="submit" onClick={this.handleUpdate}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default withRouter(Timeline);
