import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
  Modal,
  ButtonToolbar,
} from 'react-bootstrap';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import prod from '../../../prod';
import TagExport from './TagExport';
import TagViewAllExport from './TagViewAllExport';
import FileViewModal from './FileViewModal';
import TagModal from './TagModal';
import '../../../Tags.css';

class Tag extends Component {
  constructor() {
    super();

    this.state = {
      redirecting: false,
      case: null,
      facts: [],
      buckets: [],
      witnesses: [],

      // delete fact
      deleteT: {},
      selectedFactWitness: {},
      dTShow: false,

      // update
      uShow: false,
      uName: '',
      updatedFact: {},

      // buckets
      selectedBucket: undefined,
      q: [],
      defaultSort: true,
      referenceSort: false,
      witnessSort: false,

      //doc
      openFileViewModal: false,
      index: '',

      //export
      eShow: false,
      allFacts: [],
      showViewAllExport: false,

      //help
      hShow: false,

      //add new fact
      show: false,
      error: '',
    };
    // get fact
    this.getFS = this.getFS.bind(this);

    // delete fact
    this.handleDFShow = this.handleDFShow.bind(this);
    this.handleDFClose = this.handleDFClose.bind(this);
    this.handleDFSubmit = this.handleDFSubmit.bind(this);

    // update fact
    this.handleUShow = this.handleUShow.bind(this);
    this.handleUClose = this.handleUClose.bind(this);
    this.handleUSubmit = this.handleUSubmit.bind(this);
    this.editRef = React.createRef();
    this.onChange = this.onChange.bind(this);

    // bucket
    this.getSelectBucket = this.getSelectBucket.bind(this);
    this.closeSelectedBucket = this.closeSelectedBucket.bind(this);
    this.renderSelectBucket = this.renderSelectBucket.bind(this);
    this.openFileViewModal = this.openFileViewModal.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
    this.handleShowViewAllExport = this.handleShowViewAllExport.bind(this);

    // help
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);

    // add new fact
    this.ref = React.createRef();
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getWitnessMap = this.getWitnessMap.bind(this);
    this.getWitnessFactsObj = this.getWitnessFactsObj.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openFileViewModal(open, index) {
    this.setState({ fileModal: open, index });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      Object.keys(this.props.case).length === 0
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: { name: 'Fact Tags' },
          topic: '',
          path: '/case/tags',
          name: 'Fact Tags',
          active: true,
        });
      }
      axios
        .post(prod() + '/api/cases/get', { case: this.props.case._id })
        .then((use) => {
          // console.log(use);
          this.sortFacts(use.data);
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
      console.log(this.props.case);
    }
    // sorts facts on mount

    // console.log('mount state case', this.state.case)
  }
  componentWillReceiveProps(newProps) {
    if (this.props.case != newProps.case) {
      // console.log('104', newProps.case);
      if (
        newProps.case == undefined ||
        newProps.case == null ||
        Object.keys(newProps.case).length === 0
      ) {
        this.setState({ redirect: true });
      } else {
        if (this.props.second != true) {
          // console.log(newProps.case);
          this.props.getTab({
            case: newProps.case,
            witness: { name: 'Fact Tags' },
            topic: '',
            path: '/case/tags',
            name: 'Fact Tags',
            active: true,
          });
        }
        axios
          .post(prod() + '/api/cases/get', { case: newProps.case._id })
          .then((use) => {
            // console.log('called use', use.data);
            this.sortFacts(use.data);
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      }
      // sorts facts on mount

      // console.log('mount state case', this.state.case)
    }
  }

  // compares a fact id and matches it with the witness
  matchFactWithWitness(factId) {
    // console.log('props witness', this.props.case.witnesses);
    for (const witness of this.props.case.witnesses) {
      for (const fact of witness.facts) {
        if (fact._id === factId) {
          return witness.name;
        }
      }
    }
  }

  // help
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  // delete fact
  handleDFClose = () => this.setState({ dTShow: false });
  handleDFShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    console.log(e);
    // for (const witness of this.props.case.witnesses) {
    // if (witness.facts.find((fact) => fact._id === e._id)) {
    //   this.setState({ selectedFactWitness: witness });
    // }

    // if (witness._id == e.witness._id) {
    //   this.setState({ selectedFactWitness: witness });
    // }
    // }
    this.setState({
      dTShow: true,
      deleteT: e.fact,
      selectedFactWitness: e.witness,
    });
  };

  // delete submit function
  handleDFSubmit = () => {
    console.log(this.state.selectedFactWitness._id); //the problem
    axios
      .post(prod() + '/api/cases/delete/witness/fact', {
        case: this.props.case._id,
        witness: this.state.selectedFactWitness._id,
        fact: this.state.deleteT._id,
      })
      .then((use) => {
        this.props.updateCase(use.data.case);
        // this.sortFacts();
      });
  };

  // update fact
  handleUShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();

    for (const witness of this.props.case.witnesses) {
      if (witness.facts.find((fact) => fact._id === e._id)) {
        this.setState({ selectedFactWitness: witness });
      }
    }

    this.setState({ uShow: true, uName: e.name, updatedFact: e }, () =>
      this.editRef.current.focus()
    );
  };
  handleUClose = () => this.setState({ uShow: false, uName: '' });

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleUSubmit = (data) => {
    data.preventDefault();

    // console.log("update fact successful");
    // console.log("checking params:",
    //   "witness:", this.state.selectedFactWitness._id,
    //   "case:", this.props.case,
    //   "current fact:", this.state.updatedFact,
    //   "updated name:", this.state.uName,);

    axios
      .post(prod() + '/api/cases/update/witness/fact/name', {
        witness: this.state.selectedFactWitness._id,
        case: this.props.case._id,
        fact: this.state.updatedFact._id,
        name: this.state.uName,
      })
      .then((use) => {
        this.props.updateCase(use.data.case);
        // this.sortFacts();
      })
      .catch((err) => {
        this.setState({
          user: 'Fact already exist',
        });
      });
  };

  getFS(fact, witness) {
    // console.log('getFS', fact, witness);
    localStorage.removeItem('q');
    this.props.getFact(fact, witness);
  }

  // renders View All bucket into a chart
  SortableItemBucket = (value, i) => {
    let fact = {};
    // console.log('loop: ', i);
    // console.log('this.state.q[i]', this.state.q[i]);
    // console.log('this.state.q', this.state.q);
    // last row of table
    if (i == this.state.q.length - 1) {
      fact = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-block d-md-none all-tag'>
            <Row className='saved-input'>
              <h3>Reference: {i + 1}</h3>
              <Col md={2}>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    // placeholder='Enter Reference Here'
                    value={this.state.q[i].reference}
                    name='reference'
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <h3>Witness: {i + 1}</h3>
              <Col md={3}>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    placeholder='Enter Reference Here '
                    value={this.state.q[i].reference}
                    name='reference'
                    ref={this.textInput1}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <h3>Question: {i + 1} </h3>
              <Col md={7}>
                <Form.Group controlId={i}>
                  {value.evidence.includes(
                    'storage.googleapis.com/highlight-images'
                  ) ? (
                    <div className='img-container'>
                      <img
                        src={value.evidence.replace(
                          'aleri-app-bucket',
                          'aleri-prod-app-bucket'
                        )}
                        alt='screenshot'
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      as='textarea'
                      placeholder='Enter Evidence Here'
                      value={this.state.q[i].evidence}
                      name='evidence'
                      ref={this.textInput2}
                      readOnly={true}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>

          <div className='d-none d-md-block '>
            <Row key={i} className='saved-input'>
              <Col md={2} className='ref'>
                {this.state.q[i].ref.document ? (
                  <>
                    {this.state.q[i].ref.document.type === 'Link' && (
                      <a
                        className='centre_link'
                        href={value.ref.document.link}
                        target='_black'
                      >
                        {this.state.q[i].ref.document.name}
                      </a>
                    )}
                    {this.state.q[i].ref.document.type === 'file' && (
                      <p
                        className='centre_link'
                        onClick={() => this.openFileViewModal(true, i)}
                      >
                        {this.state.q[i].ref.document.name}
                      </p>
                    )}
                  </>
                ) : (
                  <Form.Group controlId={i}>
                    <TextareaAutosize
                      // placeholder='Enter Reference Here'
                      value={this.state.q[i].reference}
                      name='reference'
                      readOnly={true}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col md={3} className='notes'>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    // placeholder='Enter Reference Here'
                    value={this.state.q[i].witnessName}
                    name='reference'
                    ref={this.textInput1}
                    readOnly={true}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                  {value.evidence.includes(
                    'storage.googleapis.com/highlight-images'
                  ) ? (
                    <div className='img-container'>
                      <img
                        src={value.evidence.replace(
                          'aleri-app-bucket',
                          'aleri-prod-app-bucket'
                        )}
                        alt='screenshot'
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      // placeholder='Enter Evidence Here'
                      value={this.state.q[i].evidence}
                      name='evidence'
                      ref={this.textInput2}
                      readOnly={true}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      fact = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-block d-md-none'>
            <Row className='saved-input'>
              <h3>Reference: {i + 1}</h3>
              <Col md={2}>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    // placeholder='Enter Reference Here'
                    value={this.state.q[i].reference}
                    name='reference'
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <h3>Witness: {i + 1}</h3>
              <Col md={3}>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    // placeholder='Enter Reference Here '
                    value={this.state.q[i].witnessName}
                    name='reference'
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <h3>Question: {i + 1} </h3>
              <Col md={7}>
                <Form.Group controlId={i}>
                  {value.evidence.includes(
                    'storage.googleapis.com/highlight-images'
                  ) ? (
                    <div className='img-container'>
                      <img
                        src={value.evidence.replace(
                          'aleri-app-bucket',
                          'aleri-prod-app-bucket'
                        )}
                        alt='screenshot'
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      as='textarea'
                      // placeholder='Enter Evidence Here'
                      value={this.state.q[i].evidence}
                      name='evidence'
                      readOnly={true}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className='d-none d-md-block ' ref={this.divElement}>
            <Row key={i} className='saved-input'>
              <Col md={2} className='ref'>
                {this.state.q[i].ref.document ? (
                  <>
                    {this.state.q[i].ref.document.type === 'Link' && (
                      <a
                        className='centre_link'
                        href={value.ref.document.link}
                        target='_black'
                      >
                        {this.state.q[i].ref.document.name}
                      </a>
                    )}
                    {this.state.q[i].ref.document.type === 'file' && (
                      <p
                        className='centre_link'
                        onClick={() => this.openFileViewModal(true, i)}
                      >
                        {this.state.q[i].ref.document.name}
                      </p>
                    )}
                  </>
                ) : (
                  <Form.Group controlId={i}>
                    <TextareaAutosize
                      // placeholder='Enter Reference Here'
                      value={this.state.q[i].reference}
                      name='reference'
                      readOnly={true}
                    />
                  </Form.Group>
                )}
              </Col>
              <Col md={3} className='notes'>
                <Form.Group controlId={i}>
                  <TextareaAutosize
                    // placeholder='Enter Reference Here'
                    value={this.state.q[i].witnessName}
                    name='reference'
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                  {value.evidence.includes(
                    'storage.googleapis.com/highlight-images'
                  ) ? (
                    <div className='img-container'>
                      <img
                        src={value.evidence.replace(
                          'aleri-app-bucket',
                          'aleri-prod-app-bucket'
                        )}
                        alt='screenshot'
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      // placeholder='Enter Evidence Here'
                      value={this.state.q[i].evidence}
                      name='evidence'
                      readOnly={true}
                    />
                  )}
                </Form.Group>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return (
      <>
        {fact}
        {this.state.index !== '' && (
          <FileViewModal
            refId={this.state.q[this.state.index]._id}
            link={this.state.q[this.state.index].ref.document.link}
            value={this.state.q[this.state.index]}
            name={this.state.q[this.state.index].ref.document.name}
            fileId={this.state.q[this.state.index].ref.document._id}
            fileModal={this.state.fileModal}
            openFileViewModal={this.openFileViewModal}
            darkMode={this.props.darkMode}
          />
        )}
      </>
    );
  };

  // View All Button
  getSelectBucket(bucket) {
    this.setState({ selectedBucket: bucket });
    // console.log("selected bucket: ", bucket)

    let allFactRows = [];

    // add all references and evidence of a bucket into an array
    for (const fact of bucket.facts) {
      fact.fact.fact.forEach((row) => {
        if (row.ref.document) {
          const url = prod() + '/api/documents/get';
          // console.log("ROW: ", row)
          axios
            .post(url, { document: row.ref.document })
            .then((res) => {
              // console.log(res.data)
              row = {
                ...row,
                ref: { ...row.ref, document: res.data },
                witnessName: fact.witness.name,
              };
              // console.log("PUSHING ROW: ", row)
              allFactRows.push(row);
              this.setState({ q: allFactRows });
              // console.log("all fact rows: ", allFactRows )
            })
            .catch((e) => console.log(e));
          // row.witnessName = fact.witness.name;
          // allFactRows.push(row);
        } else {
          row.witnessName = fact.witness.name;
          allFactRows.push(row);
          this.setState({ q: allFactRows });
          // console.log("all fact rows: ", allFactRows )
        }
      });
    }
  }

  closeSelectedBucket() {
    this.setState({ selectedBucket: undefined });
  }

  // Sorts Bucket by reference alphabetically when viewing a bucket
  handleSortByRef = () => {
    let bucketList = this.state.q;
    bucketList.sort((a, b) => a.reference.localeCompare(b.reference));
    this.setState({
      q: bucketList,
      defaultSort: false,
      referenceSort: true,
      witnessSort: false,
    });
  };

  // Sorts bucket by witness name alphabetically when viewing a bucket
  handleSortByWitness = () => {
    let bucketList = this.state.q;
    bucketList.sort((a, b) => a.witnessName.localeCompare(b.witnessName));
    this.setState({
      q: bucketList,
      defaultSort: false,
      referenceSort: false,
      witnessSort: true,
    });
  };

  // renders a specific bucket
  renderSelectBucket() {
    return (
      <>
        <h1>{this.state.selectedBucket.name}</h1>

        <Button
          className='view-all-btn-1 s-item-documents'
          onClick={this.closeSelectedBucket}
        >
          Back
        </Button>
        <Button
          className='view-all-btn s-item-documents'
          onClick={this.handleSortByRef}
        >
          Sort By Reference
        </Button>
        <Button
          className='view-all-btn s-item-documents'
          onClick={this.handleSortByWitness}
        >
          Sort By Witness
        </Button>

        <div ref={this.divElement2} className='d-none d-md-block'>
          <Fade>
            <Row className='titles'>
              <Col md={2}>
                <Row>
                  <div className='hov-container'>
                    <h3>References</h3>
                  </div>
                </Row>
              </Col>
              <Col md={3}>
                <Row>
                  <div className='hov-container'>
                    <h3>Witness</h3>
                  </div>
                </Row>
              </Col>
              <Col md={7}>
                <Row>
                  <div className='hov-container'>
                    <h3>Evidence</h3>
                  </div>
                </Row>
              </Col>

              <Col md={1}></Col>
            </Row>
          </Fade>
        </div>
        {/* Default Sort */}
        {this.state.defaultSort &&
          this.state.q.map((fact, index) =>
            this.SortableItemBucket(fact, index)
          )}
        {/* Reference Sort */}
        {this.state.referenceSort &&
          this.state.q.map((fact, index) =>
            this.SortableItemBucket(fact, index)
          )}
        {/* Witness Sort */}
        {this.state.witnessSort &&
          this.state.q.map((fact, index) =>
            this.SortableItemBucket(fact, index)
          )}
      </>
    );
  }

  SortableItem = (fact, index) => {
    // console.log('REEEEEEEEEED', fact, index);
    return (
      <div key={index}>
        <div
          className={
            this.props.darkMode == true
              ? 'parent-side-nav parent-side-nav-card tag dark-parent-side-nav-card'
              : 'parent-side-nav parent-side-nav-card tag'
          }
        >
          <div
            className='cases-items-fact-tags cases-items-s'
            onClick={() => this.getFS(fact.fact, fact.witness)}
          >
            <div
              className={
                this.props.darkMode == true
                  ? 'inside-card inside-card-dark'
                  : 'inside-card'
              }
            >
              <ButtonToolbar className='side-nav-t witness-name fact-witness-name'>
                <p className='fact-witness-name'>{fact.witness.name}</p>
              </ButtonToolbar>
              <div className='inside-btn'>
                <ButtonToolbar className='side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleUShow(fact.fact, e)}
                      className='logo-settings'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
                <ButtonToolbar className=' side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleDFShow(fact, e)} //fact.fact
                      className='logo-s'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
              </div>
              <p className='fact-card-name'>{fact.fact.name}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // notes for buckets
  // renders sortable item through a list of objects
  // obj has 2 keys:
  // 1: bucket name
  // 2: array of facts that contain the bucket name
  // render facts (loop):
  // 1. print <h1>{obj.bucketName}</h1>
  // 2. pass list of facts into sortable item fn
  sortFacts = (data) => {
    let factsArr = [];
    // console.log('this.state.case', this.state.case);
    for (const witness of data.witnesses) {
      for (const fact of witness.facts) {
        factsArr.push({
          fact: fact,
          witness: witness,
        });
      }
    }

    // sorts facts arr alphabetically
    factsArr.sort((a, b) => a.fact.name.localeCompare(b.fact.name));

    this.setState(
      {
        facts: factsArr,
        dTShow: false,
        uShow: false,
      },
      () => {
        console.log(this.state.facts);
      }
    );

    this.createFactBuckets(factsArr);
    this.setState({ allFacts: factsArr });
  };

  createFactBuckets = (factsList) => {
    let buckets = [];
    // check if factsArr contains an obj w a key of the fact name
    // else create one

    // bucket:
    // {name: '', facts: []}
    let prevFacts = [];

    for (const fact of factsList) {
      // if fact.fact.name is already in use, add it to that bucket facts
      if (prevFacts.includes(fact.fact.name)) {
        const index = buckets.findIndex(
          (findFact) => findFact.name === fact.fact.name
        );
        buckets[index].facts.push(fact);
      } else {
        // when fact.fact.name is new, create a new bucket
        prevFacts.push(fact.fact.name);
        buckets.push({
          name: fact.fact.name,
          facts: [fact],
        });
      }
    }

    // sorts buckets alphabetically
    buckets.sort((a, b) => a.name.localeCompare(b.name));
    console.log('before', buckets);
    this.setState({ buckets: buckets });
    if (this.state.selectedBucket) {
      const selectedBucketId = this.state.selectedBucket.facts[0].fact._id;
      const selectedBucketName = this.state.selectedBucket.name;
      const updatedBucket = buckets.find((b) => {
        return (
          b.name === selectedBucketName &&
          b.facts[0].fact._id === selectedBucketId
        );
      });
      this.getSelectBucket(updatedBucket);
    }
  };

  renderBuckets(buckets) {
    // const buckets = this.state.buckets;
    // console.log(buckets);
    let data = [];

    for (const bucket of buckets) {
      data.push(this.createBucketElement(bucket));
    }

    return data;
  }

  createBucketElement = (bucket) => {
    const sc =
      this.props.splitState === true ? 'auto auto' : 'auto auto auto auto';

    return (
      <>
        <div className='bucket-labels'>
          <h1 className='bucket-labels-name'>{bucket.name}</h1>
        </div>
        <div className='bucket-labels'>
          <Button
            className='bucket-labels-btn s-item-documents'
            onClick={() =>
              this.getSelectBucket({ name: bucket.name, facts: bucket.facts })
            }
          >
            View All
          </Button>
        </div>
        <Row>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: sc,
              gap: '10px',
            }}
          >
            {bucket.facts.map((fact, index) => this.SortableItem(fact, index))}
          </div>
        </Row>
      </>
    );
  };

  handleShowViewAllExport = (open) =>
    this.setState({ showViewAllExport: open });
  handleEShow = () => this.setState({ eShow: true });
  handleEClose = () =>
    this.setState({ eShow: false, showViewAllExport: false });
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  getWitnessMap = () => {
    return (
      this.props?.case?.witnesses?.reduce(
        (prev, witness) => ({ ...prev, [witness._id]: witness.name }),
        {}
      ) || {}
    );
  };

  getWitnessFactsObj = (witnessId) => {
    let witnessFactObj = {};
    const witnesses =
      this.state.witnesses.length !== 0
        ? this.state.witnesses
        : this.props.case.witnesses;
    for (const witness of witnesses) {
      if (witnessId === witness._id) {
        for (const fact of witness.facts) {
          witnessFactObj = { ...witnessFactObj, [fact._id]: fact.name };
        }
      }
    }
    return witnessFactObj;
  };

  handleSubmit = (witnessId, factId, reference, evidence, selectFact, fact) => {
    // console.log(witnessId, factId, reference, evidence, fact);

    if (selectFact) {
      axios
        .post(prod() + '/api/cases/create/witness/fact/q', {
          case: this.props.case._id,
          witness: witnessId,
          fact: factId,
          reference,
          evidence,
          ref: { value: reference },
        })
        .then((res) => {
          this.sortFacts(res.data.case);
          this.setState({
            error: '',
            show: false,
            witnesses: res.data.case.witnesses,
          });
        })
        .catch((e) => console.log(e));
    } else {
      axios
        .post(prod() + '/api/cases/create/witness/fact', {
          case: this.props.case._id,
          witness: witnessId,
          name: fact,
        })
        .then((res) => {
          const witness = res.data.case.witnesses.find(
            (w) => w._id === witnessId
          );
          const newFactId = witness.facts[witness.facts.length - 1]._id;
          // console.log("witness and fact id: ", witness, newFactId);
          axios
            .post(prod() + '/api/cases/create/witness/fact/q', {
              case: this.props.case._id,
              witness: witnessId,
              fact: newFactId,
              reference,
              evidence,
              ref: { value: reference },
            })
            .then((res) => {
              console.log('updated rows: ', res.data.case.witnesses);
              this.sortFacts(res.data.case);
              this.setState({
                error: '',
                show: false,
                witnesses: res.data.case.witnesses,
              });
            })
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  };

  render() {
    // const witnesses = this.getWitnessMap();
    // console.log("facts: ", this.state.allFacts)

    // let num = 0;
    if (this.state.redirecting) {
      return <Redirect to='/' />;
    }

    const firm2 = this.props.top ? 'wit-nav-expanded' : '';
    return (
      <div id='wit-dash'>
        <Container fluid>
          <div>
            <Row id='dash-add'>
              <Col md={6}>
                <div className='case-dash-container'></div>
                <h1 id={firm2}>All Fact Tags</h1>
              </Col>
              {!this.state.selectedBucket ? (
                <Col md={6}>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Help
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleHShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Export
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleEShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Add to Facts
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleShow}
                              className='add-case plus-icon'
                            >
                              <img
                                className='logo-title plus-icon'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                </Col>
              ) : (
                <Col md={6}>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Help
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleHShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Export
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleShowViewAllExport}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Add to Facts
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleShow}
                              className='add-case plus-icon'
                            >
                              <img
                                className='logo-title plus-icon'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                </Col>
              )}
            </Row>
          </div>

          {/* Export All Fact Tags */}
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.eShow}
            onHide={this.handleEClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  eShow: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Export All Fact Sheets</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TagExport facts={this.state.allFacts} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleEClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          {/* Export View All */}
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.showViewAllExport}
            onHide={this.handleEClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  showViewAllExport: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Export Facts by Tag</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TagViewAllExport
                  facts={this.state.q}
                  tag={
                    this.state.selectedBucket
                      ? this.state.selectedBucket.name
                      : ''
                  }
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleEClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.dTShow}
            onHide={this.handleDFClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Are you sure you want to delete this fact?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.handleDFClose}
              >
                No
              </Button>
              <Button variant='primary' onClick={this.handleDFSubmit}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.uShow}
            onHide={this.handleUClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Fact</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Fact Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Fact Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.editRef}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleUClose}>
                Close
              </Button>
              <Button
                variant='primary'
                type='submit'
                onClick={this.handleUSubmit}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          <div className='cases cases-d'>
            {/* Checks if viewing all buckets or a selected bucket */}
            {this.state.selectedBucket
              ? this.renderSelectBucket()
              : this.state.buckets && this.renderBuckets(this.state.buckets)}
            {this.state.facts.length == 0 && (
              <>
                <Row id='dash-desc' className='dash-desc-d'>
                  <Col>
                    <p className='dash-d dash-d-d'>
                      Press The Plus Icon Below To Create A New Fact
                    </p>
                    {/* <p className='dash-d dash-d-d'>
                    Click a Witness in the Witness Tab and Create a New Fact
                  </p> */}
                    {/* <p className='dash-d dash-d-d dash-d-m'>
                    There Are No Facts To View In This Case test
                  </p> */}
                  </Col>
                </Row>
                <Row id='dash-add'>
                  <Col style={{ textAlign: 'center' }}>
                    <div>
                      <ButtonToolbar>
                        <OverlayTrigger
                          key='bottom'
                          placement='bottom'
                          overlay={
                            <Tooltip
                              id={
                                this.props.darkMode
                                  ? 'dark-tooltip-add'
                                  : 'tooltip-add'
                              }
                            >
                              Add to Facts
                            </Tooltip>
                          }
                        >
                          <Button
                            onClick={this.handleShow}
                            className='add-case d plus-icon'
                          >
                            <img
                              className='logo-title plus-icon'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                            ></img>{' '}
                          </Button>
                        </OverlayTrigger>
                      </ButtonToolbar>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </Container>
        <TagModal
          hShow={this.state.hShow}
          handleHClose={this.handleHClose}
          darkMode={this.props.darkMode}
          show={this.state.show}
          handleClose={this.handleClose}
          ref={this.ref}
          getWitnessMap={this.getWitnessMap}
          getWitnessFactsObj={this.getWitnessFactsObj}
          handleSubmit={this.handleSubmit}
          error={this.state.error}
        />
      </div>
    );
  }
}

export default withRouter(Tag);
