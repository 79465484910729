import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';

import { withRouter } from 'react-router-dom';

class Tabs extends Component {
  constructor() {
    super();
    this.state = {
      open: true,
      first: [],
      second: [],
      show: false,
      showt: false,
      notReady: false,
      secondContainer: false,
    };
  }
  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleSecondContainerClose = () => this.setState({ secondContainer: false });
  handleNotReadyClose = () => this.setState({ notReady: false });
  handleTClose = () => this.setState({ showt: false });

  remove = (item, index, ev) => {
    ev.stopPropagation();
    let c = index;
    if (this.state.second.length > 0 && this.state.second[0]._id == item._id) {
      this.setState(
        {
          second: [],
        },
        () => {
          this.props.split(false);
        }
      );
    } else {
      const result = Array.from(this.state.first);
      // console.log(result, index);

      if (result[index].active == true) {
        if (result[index - 1]) {
          result[index - 1].active = true;
          c = 1;
        } else if (result[index + 1]) {
          result[index + 1].active = true;
          c = 2;
        }
      }
      // console.log(result);
      result.splice(index, 1);
      // console.log('2', result);
      this.setState(
        {
          first: result,
        },
        () => {
          // console.log(c, result[index - 1], result);
          if (c == 1) {
            this.props.getDoc(result[index - 1]);
          } else if (c == 2) {
            this.props.getDoc(result[index]);
          } else {
            this.props.history.push('/');
          }
        }
      );
    }
  };

  removeAll = () => {
    this.setState(
      {
        first: [],
        second: [],
      },
      () => {
        this.props.history.push('/');
        this.props.split(false);
      }
    );
  };
  componentWillReceiveProps(prevProps) {
    console.log("tabs.js data: ", prevProps.data)
    // console.log("prevprops == this.props?", prevProps.data == this.props.data)
    if (this.props.data != prevProps.data) {
      let _id = '';
      if (prevProps.data.fact) {
        console.log('facts');
        prevProps.data.topic = prevProps.data.fact;
      } else if (prevProps.data.summary) {
        console.log('summaries');
        prevProps.data.topic = prevProps.data.summary;
      }
      if (prevProps.data.topic && prevProps.data.topic != '') {
        _id = prevProps.data.topic._id
          ? prevProps.data.topic._id
          : prevProps.data.topic;
      } else if (prevProps.data.fact && prevProps.data.fact._id != '') {
        _id = prevProps.data.fact._id;
      } else if (prevProps.data.statement && prevProps.data.statement != '') {
        _id = prevProps.data.statement._id;
      } else if (prevProps.data.name === 'Timeline') {
        _id = prevProps.data.case._id + prevProps.data.path;
      } else {
        _id =
          prevProps.data.case._id +
          prevProps.data.witness._id +
          prevProps.data.path;
      }

      // console.log("witnes name:", prevProps.data.witness.name)

      // console.log('newProps.data: ', prevProps.data);
      const topic =
        prevProps.data.file && prevProps.data.file !== ''
          ? prevProps.data.file
          : prevProps.data.topic != ''
          ? prevProps.data.topic.name
          : '';
      const data = {
        //placeholder

        _id: _id,
        case: prevProps.data.case.name,
        witness: prevProps.data.witness ? prevProps.data.witness.name : '',
        topic: topic,
        path: prevProps.data.path,
        name: prevProps.data.name,
        active: prevProps.data.active,
        caseId: prevProps.data.case,
        witnessId: prevProps.data.witness,
        topicId: prevProps.data.topic ? prevProps.data.topic : undefined,
        docId: prevProps.data.docId,
      };

      let status = data.path == '/case/witness/topic' && data.topic == '';
      if (status == false) {
        const result = Array.from(this.state.first);
        const result2 = Array.from(this.state.second);
        const result22 = result2[0];
        let flag = false;

        if (result22 != undefined && data['_id'] == result22._id) {
          // if already opened prompt saying already open in second sceen

          flag = true;

          this.setState(
            {
              first: result,
              secondContainer: true,
            },
            () => {
              for (let i = 0; i < result.length; i++) {
                if (result[i].active == true) {
                  this.props.getDoc(result[i]);
                }
              }
            }
          );
        } else {
          for (let i = 0; i < result.length; i++) {
            if (result[i]['_id'] == data['_id']) {
              result[i].active = true;
              flag = true;
            } else {
              result[i].active = false;
            }
          }
          if (flag == false) {
            result.splice(result.length, 0, data);
          }

          this.setState({
            first: result,
          });
        }
      }
      // }
      // console.log(data);
    }
  }
  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(this.state[source]);
    const destClone = Array.from(this.state[destination]);

    let c = null;
    if (
      destination == 'second' &&
      sourceClone.length <= 1 &&
      destClone.length == 0
    ) {
      this.handleShow();
    } else {
      if (destination == 'second') {
        if (sourceClone[droppableSource['index']].active == true) {
          if (sourceClone[droppableSource['index'] - 1]) {
            sourceClone[droppableSource['index'] - 1].active = true;

            c = 1;
          } else if (sourceClone[droppableSource['index'] + 1]) {
            sourceClone[droppableSource['index'] + 1].active = true;

            c = 2;
          } else {
            c = 2;
          }
        }
      }
      if (sourceClone[droppableSource['index']].active == true) {
        sourceClone[droppableSource['index']].active = false;
      }

      if (destination == 'second' && destClone.length > 0) {
        const [item] = destClone.splice(0, 1);

        sourceClone.splice(sourceClone.length, 0, item);
      }
      const [removed] = sourceClone.splice(droppableSource.index, 1);
      destClone.splice(droppableDestination.index, 0, removed);
      this.setState(
        {
          [source]: sourceClone,
          [destination]: destClone,
        },
        () => {
          // console.log('this.state.second[0]: ', this.state.second[0]);
          if (this.state.second.length > 0) {
            if (c == 1) {
              this.props.getDoc(sourceClone[droppableSource['index'] - 1]);
            } else if (c == 2) {
              this.props.getDoc(sourceClone[droppableSource['index']]);
            }
            this.props.getPath({
              path: this.state.second[0].path,
              case: this.state.second[0].caseId,
              witness: this.state.second[0].witnessId,
              topic: this.state.second[0].topicId
                ? this.state.second[0].topicId
                : this.state.second[0].topic,
              docId: this.state.second[0].docId,
            });

            this.props.split(true);
          } else {
            this.props.split(false);
          }
        }
      );
    }
  };

  grid = 0;

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '0 !important',
    margin: `0 !important`,

    // change background colour if dragging
    background: isDragging
      ? this.props.darkMode
        ? '#242527'
        : 'rgb(234, 239, 244)'
      : this.props.darkMode
      ? '#242527'
      : 'white',
    height: '40px',
    width: '199px',
    display: 'inline-block',
    borderRight: '1px solid #dfe3ed',
    borderRadius: '0',
    // marginLeft: 'auto',
    zIndex: '10000 !important',
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: isDraggingOver
      ? this.props.darkMode
        ? '#424242'
        : '#dfe3ed'
      : this.props.darkMode
      ? '#242527'
      : '#f9f9f9',
    padding: this.grid,
    minHeight: '0',
    width: '100%',
    display: 'flex',
    whiteSpace: 'nowrap',
  });

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (
      !destination ||
      (source.droppableId === 'second' &&
        source.droppableId === destination.droppableId)
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      let listName = source.droppableId;
      const newList = this.reorder(
        this.state[listName],
        source.index,
        destination.index
      );
      this.setState({ [listName]: newList });
    } else {
      let sourceList = source.droppableId;
      let destinationList = destination.droppableId;

      this.move(sourceList, destinationList, source, destination);
    }
  };

  getIndividualCase = (item, e) => {
    let i = item.topic == '' ? item.name : item.topic;
    let p = '';
    if (item.name == 'Topic') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/question_1_nvpf9m.svg';
    } else if (item.name == 'Checklist') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642363/Aleri/notepad_ouiolb.svg';
    } else if (item.name == 'Preparation Chart') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593878907/Aleri/ruler-and-pencil-cross_urquuu.svg';
    } else if (item.name == 'Focus') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642371/Aleri/target_lesomq.svg';
    } else if (item.name == 'Impeachment') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/img_uz8lfh.svg';
    } else if (item.name == 'Framing Questions') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/frame_ekhmss.svg';
    } else if (item.name == 'Exposing False Testimony') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/frau_vdmmen.svg';
    } else if (item.name == 'General Credibility') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/diamond_uxktw3.svg';
    } else if (item.name == 'Fact') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/footprint_hafmha.svg';
    } else if (item.name == 'Summary') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/evidence_lol8la.svg';
    } else if (item.name == 'Timeline') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1615996857/Aleri/app/timeline_iipgrq.svg';
    } else if (item.name == 'File') {
      p = //insert new icon
        'https://res.cloudinary.com/aleri/image/upload/v1616009746/Aleri/app/document_vv0wn2.svg';
    } else if (item.name == 'Fact Tags') {
      p =
        'https://res.cloudinary.com/aleri/image/upload/v1615678031/Aleri/app/evidence_1_mzewee.svg';
    }
    const card = item.active == true ? ' active-top-card top-card' : 'top-card';
    return (
      <div className={card} onClick={() => this.props.getDoc(item)}>
        <div className='side-bt side-bt-2'>
          <p className='top-case'>
            {item.case} - {item.witness}
          </p>
          <img className='logo-top' src={p}></img> <p>{i}</p>
          <img
            className='logo-top logo-top-s'
            src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
            onClick={(t) => this.remove(item, e, t)}
          ></img>
        </div>
      </div>
    );
  };

  render() {
    let first = [];
    let second = <div></div>;
    if (this.state.first.length == 0) {
      first = <p className='tab-text'>First Screen</p>;
    } else {
      first = this.state.first.map((item, index) => (
        <Draggable key={item._id} draggableId={item._id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
              className='unfocus'
              onMouseDown={(e) => e.currentTarget.focus()}
            >
              {this.getIndividualCase(item, index)}
            </div>
          )}
        </Draggable>
      ));
    }
    if (Object.keys(this.state.second).length == 0) {
      second = <p className='tab-text'>Second Screen</p>;
    } else {
      // second = this.state.second;
      let i =
        this.state.second.topic == ''
          ? this.state.second.name
          : this.state.second.topic;
      let p = '';
      if (this.state.second.name == 'Topic') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/question_1_nvpf9m.svg';
      } else if (this.state.second.name == 'Checklist') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642363/Aleri/notepad_ouiolb.svg';
      } else if (this.state.second.name == 'Preparation Chart') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593878907/Aleri/ruler-and-pencil-cross_urquuu.svg';
      } else {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642371/Aleri/target_lesomq.svg';
      }
      if (this.state.second.name == 'Topic') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/question_1_nvpf9m.svg';
      } else if (this.state.second.name == 'Checklist') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642363/Aleri/notepad_ouiolb.svg';
      } else if (this.state.second.name == 'Preparation Chart') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593878907/Aleri/ruler-and-pencil-cross_urquuu.svg';
      } else if (this.state.second.name == 'Focus') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642371/Aleri/target_lesomq.svg';
      } else if (this.state.second.name == 'Impeachment') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/img_uz8lfh.svg';
      } else if (this.state.second.name == 'Framing Questions') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/frame_ekhmss.svg';
      } else if (this.state.second.name == 'Exposing False Testimony') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/frau_vdmmen.svg';
      } else if (this.state.second.name == 'General Credibility') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/diamond_uxktw3.svg';
      } else if (this.state.second.name == 'Fact') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/footprint_hafmha.svg';
      } else if (this.state.second.name == 'Summary') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/evidence_lol8la.svg';
      } else if (this.state.second.name == 'Timeline') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1615996857/Aleri/app/timeline_iipgrq.svg';
      } else if (this.state.second.name == 'File') {
        p = //insert new icon
          'https://res.cloudinary.com/aleri/image/upload/v1616009746/Aleri/app/document_vv0wn2.svg';
      } else if (this.state.second.name == 'Fact Tags') {
        p =
          'https://res.cloudinary.com/aleri/image/upload/v1615678031/Aleri/app/evidence_1_mzewee.svg';
      }
      second = this.state.second.map((item, index) => (
        <Draggable key={item._id} draggableId={item._id} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={this.getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style
              )}
              className='unfocus'
              onMouseDown={(e) => e.currentTarget.focus()}
            >
              {this.getIndividualCase(item, index)}
            </div>
          )}
        </Draggable>
      ));
    }
    const lay = this.props.open != true ? 'top-nav' : 'top-nav-expanded';
    const lay2 = this.props.open != true ? 'czhaTb-top' : 'czhaTb-top-expanded';
    const hidden = this.props.top != true ? 'tabs-up' : 'tabs-down';
    const hidden2 =
      this.props.top != true ? 'tabs-up-button' : 'tabs-down-button';

    return (
      <>
        {' '}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.secondContainer}
          onHide={this.handleSecondContainerClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Split Screen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            This Document Is Already Open On Second Screen.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={this.handleSecondContainerClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.show}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Split Screen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You must have at least two files open to use split screen.
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.showt}
          onHide={this.handleTClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Split Screen</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You must have a file placed in the "second screen" section on the
            top navigation.
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleTClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.notReady}
          onHide={this.handleNotReadyClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notepad</Modal.Title>
          </Modal.Header>
          <Modal.Body>Coming soon.</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleNotReadyClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <div id={lay} className={hidden}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div id='top-first'>
              <Droppable direction='horizontal' droppableId='first'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={this.getListStyle(snapshot.isDraggingOver)}
                  >
                    {first}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div id='top-second'>
              <Droppable direction='horizontal' droppableId='second'>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={this.getListStyle(snapshot.isDraggingOver)}
                  >
                    {second}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
          <div id='top-func'>
            <ButtonToolbar>
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={
                  <Tooltip
                    id={
                      this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                    }
                  >
                    Split Screen
                  </Tooltip>
                }
              >
                <img
                  className='logo top-l'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/split_svg_v70wvk.svg'
                  onClick={() =>
                    this.state.second.length > 0
                      ? this.props.toggleSplit()
                      : this.setState({ showt: true })
                  }
                ></img>
              </OverlayTrigger>
            </ButtonToolbar>

            <ButtonToolbar>
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={
                  <Tooltip
                    id={
                      this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                    }
                  >
                    Close All Tabs
                  </Tooltip>
                }
              >
                <img
                  className='logo top-l'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/center_oyyjhl.svg'
                  onClick={this.removeAll}
                ></img>
              </OverlayTrigger>
            </ButtonToolbar>

            {/* <ButtonToolbar>
              <OverlayTrigger
                key='bottom'
                placement='bottom'
                overlay={
                  <Tooltip
                    id={
                      this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                    }
                  >
                    Notes (Coming Soon)
                  </Tooltip>
                }
              > */}
            {/* <img
                  onClick={() => this.setState({ notReady: true })}
                  className='logo top-l'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/ex_mopdoe.svg'
                ></img> */}
            {/* <img
                  className='logo top-l'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/ex_mopdoe.svg'
                ></img> */}
            {/* </OverlayTrigger>
            </ButtonToolbar> */}
          </div>
        </div>
        <div className='comp-auth-nav-toggle-top'>
          <div
            onClick={this.props.changeTop}
            className={`${lay2} ${hidden2}`}
          ></div>
        </div>
      </>
    );
  }
}

export default withRouter(Tabs);
