import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

export class UserDeleteModal extends Component {
  render() {
    return (
      <>
        {/* Delete Account Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showUserDeleteModal}
          onHide={() => this.props.openUserDeleteModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Account</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this account?</p>
            <p>If you are the last user in this firm, this firm will be deleted as well.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.openUserDeleteModal(false)}>
              No
            </Button>
            <Button variant='danger' onClick={this.props.deleteUser}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

      {/* Delete Firm Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showDeleteFirmModal}
          onHide={() => this.props.openDeleteFirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Firm</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Do you want to delete this firm as well?</p>
            <p>If you delete this firm, then all the data related to this firm including cases and the users in this firm will be removed.</p>
            <p>If you only delete your account and your card is on file for this firm, you will still keep getting charged.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.handleDeleteFirmNo()}>
              No, Just Delete My Account
            </Button>
            <Button variant='danger' onClick={() => this.props.openConfirmDeleteFirmModal(true)}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Confirm Delete Firm Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showConfirmDeleteFirmModal}
          onHide={() => this.props.openConfirmDeleteFirmModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete Firm</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete the firm?</p>
            <p>If you delete this firm, then all the data related to this firm including cases and the users in this firm will be removed and can not be retrieved.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.openConfirmDeleteFirmModal(false)}>
              No
            </Button>
            <Button variant='danger' onClick={this.props.handleRemoveFirm}>
              Delete Firm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Make Admin Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showMakeAdminModal}
          onHide={() => this.props.openMakeAdminModal(false)}
        >
          <Modal.Header>
            <Modal.Title>Try Again</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Please make a different user the Admin of this firm and try again.</p>
            <p>Go to Collaborators tab and choose a user you would like to assign as Admin and click on Make Admin button.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.openMakeAdminModal(false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default UserDeleteModal;
