import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import moment from 'moment';

// pdf
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Docx
import { saveAs } from "file-saver";
import { Document as DocxDocument, Packer } from "docx";

import { getAllTimelines } from "../../../Shared/DocxExport";

const Pdf = (props) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const output = <div></div>;

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: "column",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
    },
    subSection: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1 0 50%",
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: "left",
      textTransform: "uppercase",
    },
    titleSM: {
      letterSpacing: 1,
      fontSize: 7,
      textAlign: "left",
      textTransform: "uppercase",
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: "right",
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: "auto",
      marginRight: "auto",
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: "right",
      textTransform: "uppercase",
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColName: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColValue: {
      width: "25%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textOverflow: "ellipsis",
    },

    tableColNameL: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
    },

    tableColValueL: {
      width: "50%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: "ellipsis",
    },
    tableCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: 300,
      width: "100%",
      textOverflow: "ellipsis",
      padding: 5,
    },
  });

  const arr = [];
  for (let i = 0; i < props.events.length; i++) {
    const a = (
      <View style={styles.tableRow}>
        <View style={styles.tableColValue}>
          <Text style={styles.tableCell}>{moment(props.events[i].date, moment.ISO_8601).format('MMMM Do YYYY, h:mm a')}</Text>
        </View>

        <View style={styles.tableColValue}>
          <Text style={styles.tableCell}>{props.events[i].witnessName}</Text>
        </View>

        <View style={styles.tableColValueL}>
          <Text style={styles.tableCell}>{props.events[i].name}</Text>
        </View>
      </View>
    );

    arr.push(a);
  }
  const pdf = (
    <PDFDownloadLink
      className="bob-link"
      target="_blank"
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.titleSM}>Timelines for All Witnesses</Text>
                <Text style={styles.title}>Timeline</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Date</Text>
                  </View>

                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Witness</Text>
                  </View>

                  <View style={styles.tableColNameL}>
                    <Text style={styles.tableCell}>Event</Text>
                  </View>
                </View>
                {arr}
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName="timeline.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Loading PDF To Export
          </Button>
        ) : (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Export To PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return <>{pdf}</>;
};

const Word = (props) => {
  const generate = () => {
    const docArr = getAllTimelines([{ timeline: props.events }]);

    const doc = new DocxDocument({
      sections: docArr,
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `timelines.docx`);
    });
  };

  return (
    <Button
      className="btn action  btn-b btn btn-primary btn-block"
      onClick={generate}
    >
      Export To Word(docx)
    </Button>
  );
};

const TimelinesExport = (props) => {
  return (
    <>
      <Pdf {...props} />
      <Word {...props} />
    </>
  )
}

export default TimelinesExport;
