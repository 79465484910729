import React, { Component } from 'react';
import axios from 'axios';
import { Form, FormControl, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import prod from '../../../prod';

const loading = {
  margin: '1em',
};

const divContainer = {
  width: '90%',
  display: 'flex',
  flexDirection: 'vertical',
  margin: '30px auto',

  justify: 'center',
};
const innerDiv = {
  padding: '15px',
  width: '100%',
};

export default class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      first_name: '',
      last_name: '',
      user_id: '',
      password: '',
      confirm_password: '',
      updated: false,
      isLoading: true,
      error: false,
      matchError: false,
      emptyErr: false,
    };
  }

  async componentDidMount() {
    console.log('PROPS:', this.props);
    console.log('CDM RESETPWD');
    const {
      match: {
        params: { token_id },
      },
    } = this.props;

    try {
      const response = await axios.get(prod() + '/api/forgotPassword/reset', {
        params: {
          resetPasswordToken: token_id,
        },
      });
      console.log('RESPONSE:', response);
      if (response.data.message === 'password reset link a-ok') {
        console.log('USER name:', response.data.user_id);
        this.setState({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          user_id: response.data.user_id,
          updated: false,
          isLoading: false,
          error: false,
        });
      }
    } catch (error) {
      console.log(error.response.data);
      this.setState({
        updated: false,
        isLoading: false,
        error: true,
      });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  updatePassword = async (e) => {
    e.preventDefault();
    const {
      first_name,
      last_name,
      password,
      user_id,
      confirm_password,
    } = this.state;
    const {
      match: {
        params: { token_id },
      },
    } = this.props;
    if (password.trim().length != 0) {
      if (password === confirm_password) {
        try {
          const response = await axios.put(
            prod() + '/api/forgotPassword/updatePasswordViaEmail',
            {
              user_id,
              first_name,
              last_name,
              password,
              resetPasswordToken: token_id,
            }
          );
          console.log('Data Recieved from Server: ', response.data);
          if (response.data.message === 'password updated') {
            this.setState({
              updated: true,
              error: false,
            });
          } else {
            this.setState({
              updated: false,
              error: true,
            });
          }
        } catch (error) {
          console.log(error.response.data);
        }
      } else {
        this.setState({
          matchError: true,
        });
      }
    } else {
      this.setState({
        emptyErr: true,
      });
    }
  };

  render() {
    const {
      password,
      error,
      isLoading,
      updated,
      confirm_password,
      matchError,
    } = this.state;

    if (error) {
      return (
        <div>
          <div style={{ marginTop: '110px', marginBottom: '120px' }}>
            <h4 className='alert alert-danger thanks-msg'>
              Problem resetting password. Send new password reset link.
            </h4>
            <Link to='/' className=' action-b btn-dark btn'>
              Click Here
            </Link>
          </div>
        </div>
      );
    }
    if (isLoading) {
      return (
        <div>
          <div style={loading}>Loading User Data...</div>
        </div>
      );
    }

    return (
      <div style={divContainer} id='reset-pass'>
        <div style={innerDiv}>
          <h1>Reset Password</h1>
          <Form className='password-form' onSubmit={this.updatePassword}>
            {this.state.emptyErr && (
              <div>
                <p className='alert alert-danger thanks-msg'>
                  Please enter password.
                </p>
              </div>
            )}
            {matchError && (
              <div>
                <p className='alert alert-danger thanks-msg'>
                  Passwords do not match. Please try again.
                </p>
              </div>
            )}
            {updated && (
              <p className='alert alert-success thanks-msg'>
                Your password has been successfully reset, please try logging in
                again.
              </p>
            )}
            {!updated && (
              <div style={{ width: '40%', paddingTop: '50px', margin: 'auto' }}>
                <Form.Group>
                  <FormControl
                    placeholder='Password'
                    id='password'
                    label='password'
                    onChange={this.handleChange('password')}
                    value={password}
                    type='password'
                    minLength='8'
                  />
                  <FormControl
                    placeholder='Confirm Password'
                    id='confirm_password'
                    label='password'
                    onChange={this.handleChange('confirm_password')}
                    value={confirm_password}
                    type='password'
                    minLength='8'
                  />
                </Form.Group>

                <Button
                  className='btn action action-s'
                  block
                  type='submit'
                  variant='primary'
                  style={{ width: '50%' }}
                >
                  Update Password
                </Button>
              </div>
            )}
          </Form>
          {updated && (
            <Link
              to='/login'
              className='btn action action-s btn btn-primary btn-block'
            >
              Go Home
            </Link>
          )}
        </div>
      </div>
    );
  }
}
