import React from 'react';
import CardSection from '../../Unauthenticated/Auth//Payment/CardSection';
import { injectStripe } from 'react-stripe-elements';
import axios from 'axios';
import prod from '../../../prod';
import { Link } from 'react-router-dom';
import {
  FormControl,
  Button,
  Form,
  Modal,
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import PaymentLoading from './paymentLoading';
class CheckoutForm extends React.Component {
  constructor() {
    super();
    this.state = {
      tog: false,
      plan: null,
      createPaymentShow: false,
      errorUpgrade: false,
      confirmLeaving: false,
      plan: '',
      coupon: '',
      show: false,
      showComponent: false,
      err: false,
      errMSG: '',
    };
    this.updateCard = this.updateCard.bind(this);
    this.handleSubmitSubscription = this.handleSubmitSubscription.bind(this);
    this.togglePlan = this.togglePlan.bind(this);
    this.onSubmitPlan = this.onSubmitPlan.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onCouponChange = this.onCouponChange.bind(this);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleShow = () => this.setState({ show: true });
  handleClose = () =>
    this.setState({ show: false, createPaymentShow: false }, () =>
      this.props.handleBClose()
    );
  handleErrClose = () => this.setState({ show: false, err: false });
  handleSubmit = (data) => {
    data.preventDefault();
    window.location.reload(true);

    this.setState({ show: false });
  };

  onCouponChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitPlan(a) {
    console.log('CALLLED', this.props.fullFirm);
    console.log(a);
    if (
      (a == 1 && this.props.fullFirm.cases.length > 15) ||
      (a == 1 && this.props.fullFirm.members.length > 1) ||
      (a == 4 && this.props.fullFirm.cases.length > 15) ||
      (a == 4 && this.props.fullFirm.members.length > 1)
    ) {
      console.log('over 1');
      this.setState(
        {
          errorUpgrade: true,
          createPaymentShow: false,
        },
        () => this.props.handleBClose()
      );
    } else if (
      (a == 2 && this.props.fullFirm.cases.length > 100) ||
      (a == 2 && this.props.fullFirm.members.length > 3) ||
      (a == 5 && this.props.fullFirm.cases.length > 100) ||
      (a == 5 && this.props.fullFirm.members.length > 3)
    ) {
      console.log('over 2');
      this.setState(
        {
          errorUpgrade: true,
          createPaymentShow: false,
        },
        () => this.props.handleBClose()
      );
    } else {
      console.log('good');
      this.setState(
        {
          createPaymentShow: true,
          plan: a,
        },
        () => this.props.handleBClose()
      );
    }
  }
  togglePlan() {
    let tog = this.state.tog == false ? true : false;
    this.setState({ tog: tog });
  }
  // update
  updateCard = (props, ev) => {
    ev.preventDefault();

    props.stripe.createToken({}).then(({ token }) => {
      axios
        .post(prod() + '/api/stripe/customer/update', {
          token: token.id,
          email: props.user,
          customerId: props.firm,
        })
        .then((use) => {
          props.handleCClose();
        })
        .catch((e) => console.log(e));
    });
  };

  // create

  handleSubmitSubscription(ev) {
    ev.preventDefault();
    if (
      this.state.coupon.trim() != '' &&
      this.state.coupon.trim() != undefined
    ) {
      // check coupon
      axios
        .post(prod() + '/api/stripe/coupon', {
          coupon: this.state.coupon.trim(),
        })
        .then((use) => {
          this.props.stripe
            .createToken({})
            .then(({ token }) => {
              console.log('Received Stripe Token', token);
              if (token) {
                this.setState(
                  {
                    showComponent: true,
                    createPaymentShow: false,
                  },
                  () => {
                    // create stripe customer

                    axios
                      .post(prod() + '/api/stripe/customer/create', {
                        token: token.id,
                        email: this.props.user,
                      })
                      .then((use) => {
                        axios
                          .post(prod() + '/api/stripe/customer/subscribe', {
                            plan: this.state.plan,
                            coupon: this.state.coupon.trim(),
                            customerId: use.data.customerId,
                            quantity: this.props.members.length,
                          })
                          .then((used) => {
                            axios
                              .post(prod() + '/api/firms/create/subscription', {
                                _id: this.props.fullFirm._id,
                                customerId: use.data.customerId,
                                subscriptionId: used.data.subscriptionId,
                                plan: this.state.plan,
                              })
                              .then((response) => {
                                console.log('SAVED', response);
                                this.setState(
                                  { show: true, showComponent: false },
                                  () => {}
                                );
                              })
                              .catch((e) => {
                                console.log('retreiving error' + e);
                                // this.setState({ error: true, loading: false });
                                this.setState({
                                  err: true,
                                  showComponent: false,
                                });
                              });
                          })
                          .catch((e) => {
                            console.log('retreiving error' + e);
                            // this.setState({ error: true, loading: false });
                            this.setState({
                              err: true,
                              showComponent: false,
                            });
                          });

                        // window.location.href = '/login/';
                      })
                      .catch((error) => {
                        console.log('retreiving error' + error);
                        // this.setState({ error: true, loading: false });
                        this.setState({ err: true, showComponent: false });
                      });
                  }
                );
              } else {
                this.setState({
                  errMSG: 'Error: Credit Card Not Valid',
                });
              }
            })
            .catch((e) => this.setState({ err: true, showComponent: false }));
        })
        .catch((e) => {
          this.setState({
            errMSG: 'Error: Coupon Invalid Or Expired',
          });
        });
    } else {
      this.props.stripe
        .createToken({})
        .then(({ token }) => {
          console.log('Received Stripe Token', token);
          if (token) {
            this.setState(
              {
                showComponent: true,
                createPaymentShow: false,
              },
              () => {
                // create stripe customer

                axios
                  .post(prod() + '/api/stripe/customer/create', {
                    token: token.id,
                    email: this.props.user,
                  })
                  .then((use) => {
                    axios
                      .post(prod() + '/api/stripe/customer/subscribe', {
                        plan: this.state.plan,
                        coupon: this.state.coupon.trim(),
                        customerId: use.data.customerId,
                        quantity: this.props.members.length,
                      })
                      .then((used) => {
                        axios
                          .post(prod() + '/api/firms/create/subscription', {
                            _id: this.props.fullFirm._id,
                            customerId: use.data.customerId,
                            subscriptionId: used.data.subscriptionId,
                            plan: this.state.plan,
                          })
                          .then((response) => {
                            console.log('SAVED', response);
                            this.setState(
                              { show: true, showComponent: false },
                              () => {}
                            );
                          })
                          .catch((e) => {
                            console.log('retreiving error' + e);
                            // this.setState({ error: true, loading: false });
                            this.setState({
                              err: true,
                              showComponent: false,
                            });
                          });
                      })
                      .catch((e) => {
                        console.log('retreiving error' + e);
                        // this.setState({ error: true, loading: false });
                        this.setState({
                          err: true,
                          showComponent: false,
                        });
                      });

                    // window.location.href = '/login/';
                  })
                  .catch((error) => {
                    console.log('retreiving error' + error);
                    // this.setState({ error: true, loading: false });
                    this.setState({ err: true, showComponent: false });
                  });
              }
            );
          } else {
            this.setState({
              errMSG: 'Error: Credit Card Not Valid',
            });
          }
        })
        .catch((e) => this.setState({ err: true, showComponent: false }));
    }
  }

  render() {
    return (
      <div>
        <Modal
          show={this.props.cShow}
          onHide={this.props.handleCClose}
          dialogClassName={this.props.darkMode ? 'dark-modal ' : ''}
        >
          <Form
            onSubmit={(e) => this.updateCard(this.props, e)}
            id='UpdateCard'
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Card</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CardSection />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleCClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* create */}
        <Modal
          show={this.props.bShow}
          onHide={this.handleClose}
          bsClass='payment-modal'
          style={{ left: '32px', margin: '0 auto' }}
          dialogClassName={
            this.props.darkMode ? 'dark-modal payment-modal' : 'payment-modal'
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Select A Plan</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <div
                  id='pricing'
                  style={{ marginLeft: '-10px', background: 'none' }}
                >
                  <Container className='pricing-container'>
                    <Row>
                      <Col xs={12}>
                        <h2 className='text-center'>
                          Simple, Affordable Pricing
                        </h2>

                        {this.state.errd && (
                          <h5 className='errd text-center'>
                            {this.state.errd}
                          </h5>
                        )}
                        <p className='middle-p'>Most Frequently Purchased</p>
                      </Col>

                      <Col>
                        <div className='modal-box-a box text-center'>
                          <h4 className='box-title'>Monthly</h4>
                          {/* <h6 className='box-title'>
                            <strike>{this.state.tog == false ? '' : ''}</strike>
                          </h6> */}
                          <h2 className='box-title-price'>
                            <span className='box-title-d'>
                              <sup>$US</sup>
                            </span>
                            <span className='box-title-m'>25</span>
                            <span className='box-title-t'>/mo</span>
                          </h2>
                          <h5 className='box-time'>Per User</h5>
                          <div className='box-items-t'>
                            <p className='box-item'>Unlimited Users</p>
                            <p className='box-item'>Unlimited Cases</p>
                          </div>

                          <Button
                            onClick={() => this.onSubmitPlan(7)}
                            className='action action-b'
                          >
                            Select
                          </Button>
                          <br />

                          <small>
                            <small>Billed Monthly</small>
                          </small>
                        </div>
                      </Col>
                      <Col>
                        {/* <p class='middle-pd'>Most Frequently Purchased</p> */}
                        <div className='modal-box-b box-b  text-center'>
                          <h4 className='box-title'>Yearly</h4>
                          {/* <h6 className='box-title'>
                            <strike>{this.state.tog == false ? '' : ''}</strike>
                          </h6> */}
                          <h2 className='box-title-price'>
                            <span className='box-title-d'>
                              <sup>$US</sup>
                            </span>
                            <span className='box-title-m'>20</span>
                            <span className='box-title-t'>/mo</span>
                          </h2>
                          <h5 className='box-time'>Per User</h5>
                          <div className='box-items-t'>
                            <p className='box-item'>Unlimited Users</p>
                            <p className='box-item'>Unlimited Cases</p>
                            <p className='box-item'>Priority Support</p>
                          </div>

                          <Button
                            onClick={() => this.onSubmitPlan(8)}
                            className='action action-b'
                          >
                            Select
                          </Button>
                          <br />
                          <small>
                            <small>Billed Yearly</small>
                          </small>
                        </div>
                      </Col>
                      <Col>
                        <div className='  modal-box-c box-b-2 text-center'>
                          <h4 className='box-title'>
                            Law Library / Law School
                          </h4>
                          <p>Contact us at support@aleri.ca</p>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Row>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          show={this.state.show}
          onHide={(e) => this.handleSubmit(e)}
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
        >
          <Form onSubmit={(e) => this.handleSubmit(e)}>
            <Modal.Header closeButton>
              <Modal.Title>Congrats</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>You have successfully created an account.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='primary' type='submit'>
                Continue
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={this.state.err} onHide={this.handleErrClose}>
          <Form onSubmit={(e) => window.location.reload(true)}>
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                An Error occurred when trying to create firm. Please try again
                or contact us.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='primary' type='submit'>
                Please Try Again
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Enter Payment Information Modal */}
        <Modal
          show={this.state.createPaymentShow}
          onHide={(e) =>
            this.setState({ confirmLeaving: true, createPaymentShow: false })
          }
          dialogClassName={this.props.darkMode ? 'dark-modal ' : ''}
        >
          <Form onSubmit={this.handleSubmitSubscription} id='UpdateCard2'>
            <Modal.Header closeButton>
              <Modal.Title>Enter Payment Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <div className='registrationForm' id='paymentForm'> */}
              {this.state.errMSG && (
                <div className='alert-danger alert invalid-feedback'>
                  {this.state.errMSG}
                </div>
              )}

              <CardSection autoFocus />
              <br />
              <h5>Coupon</h5>
              <Form.Group controlId='formBasicEmail'>
                <FormControl
                  type='text'
                  placeholder='Coupon'
                  value={this.state.coupon}
                  onChange={this.onCouponChange}
                  name='coupon'
                  id='coupon'
                />
              </Form.Group>
              <p>
                By clicking the "Start Membership" button below, you agree to
                our Terms & Conditions, Privacy Statement, and that Aleri will
                automatically continue your membership and charge the membership
                fee plus tax for the plan selected to your payment method until
                you cancel.
              </p>

              {/* </div> */}
            </Modal.Body>
            <Modal.Footer>
              <Button className='action action-b' type='submit'>
                Start Membership
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.confirmLeaving}
          onHide={(e) => this.setState({ confirmLeaving: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>You are about to cancel.</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to cancel setting up a plan?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='primary'
              onClick={() =>
                this.setState({
                  confirmLeaving: false,
                  createPaymentShow: false,
                })
              }
            >
              Yes
            </Button>
            <Button
              variant='primary'
              onClick={() =>
                this.setState({
                  confirmLeaving: false,
                  createPaymentShow: true,
                })
              }
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showComponent}>
          <PaymentLoading />
        </Modal>
        <Modal
          show={this.state.errorUpgrade}
          onHide={() => {
            // this.props.handleBOpen()
            this.setState({ errorUpgrade: false });
          }}
          dialogClassName={
            this.props.darkMode ? 'dark-modal payment-modal' : 'payment-modal'
          }
        >
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            You have more cases or members in your firm then this plan can hold.
            Please remove some cases / members or choose a larger plan.
            <Button
              onClick={() => this.setState({ errorUpgrade: false })}
              className='action action-b btn-dark new top-g-f switch-btn-c'
            >
              Okay
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export default injectStripe(CheckoutForm);
