import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../actions/authActions';

import Guest from './Unauthenticated/Shared/Guest';
import Dashboard from './Authenticated/Dashboard/DashboardNav';
import NotExpanded from './Authenticated/Navigation/NotExpanded';
import CaseDashboard from './Authenticated/Case/CaseDashboard';
import WitnessDashboard from './Authenticated/Witness/WitnessDashboard';
import TopicDashboard from './Authenticated/Documents/Questions/TopicDashboard';
import FactDashboard from './Authenticated/Documents/Facts/FactDashboard';
import Settings from './Authenticated/Settings/SettingsNav';
import SummaryDashboard from './Authenticated/Documents/Summary/SummaryDashboard';
import FileTab from './Authenticated/Shared/FileTab';
import AudioTranscript from './Authenticated/Shared/AudioTranscript';
import { trialEnd } from './Shared/trialEnd';

import Tag from './Authenticated/Tags/Tag';

import axios from 'axios';
import prod from '../prod';
import Information from './Authenticated/Information/InformationNav';

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      navExpanded: true,
      bUpdate: {},
      show: false,
      cases: [],
      name: '',
      client: '',
      uName: '',
      uClient: '',
      firm: '',
      use: '',
      user: '',
      plan: '',
      dShow: false,
      sShow: false,
      updating: '',
      info: {},
      deleted: '',
      witnesses: [],
      witnessList: [],
      case: {},
      wShow: false,
      wDShow: false,
      caseN: null,
      wName: '',
      wOld: '',
      wSelected: '',
      wSShow: '',
      uWSName: '',
    };
    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    //
    this.goHome = this.goHome.bind(this);
    this.goCase = this.goCase.bind(this);
    this.goTopics = this.goTopics.bind(this);
    this.goFacts = this.goFacts.bind(this);
    this.goSummaries = this.goSummaries.bind(this);
    this.goTags = this.goTags.bind(this);
    this.goTimeline = this.goTimeline.bind(this);

    // go Info
    this.goInfo = this.goInfo.bind(this);
    this.goSettings = this.goSettings.bind(this);
    this.goActivity = this.goActivity.bind(this);

    // withness
    this.changeLocation = this.changeLocation.bind(this);
    this.changeLocationW = this.changeLocationW.bind(this);
    this.updateCase = this.updateCase.bind(this);
  }
  updateCase() {
    if (this.props.case && this.props.case._id && this.props.witness) {
      axios
        .post(prod() + '/api/cases/get', {
          case: this.props.case._id,
        })
        .then((user) => {
          for (let item of user.data.witnesses) {
            if (item && this.props.witness) {
              if (item._id === this.props.witness._id) {
                this.props.updateFromFocus(user.data, item);
                break;
              }
            }
          }
        });
    } else if (this.props.case && this.props.case._id) {
      axios
        .post(prod() + '/api/cases/get', {
          case: this.props.case._id,
        })
        .then((user) => {
          // console.log(user.data);
          this.props.updateFromFocus(user.data, undefined);
        });
    }
  }
  changeLocation(a) {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        // console.log(a);
        this.props.history.push(a.loc);
        this.setState(
          {
            cases: user.data.cases,
            case: a.case,
            caseN: a.caseN,
          },
          () => {
            this.props.changeLocation(a.loc, a.case, a.witness);
          }
        );
      });
  }
  changeLocationW(a) {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        // console.log(a);
        this.setState(
          {
            cases: user.data.cases,
            witness: a.witness,
            witnessN: a.witnessN,
            case: user.data.cases[this.state.caseN], //might take out
          },
          this.props.changeLocation(a.loc, a.case, a.witness)
        );
      });
  }

  refreshCaseWitnessList = (newList, caseId) => {
    let allCases = this.state.cases;
    allCases.find((singleCase) => {
      if (singleCase._id === caseId) {
        singleCase.witnesses = newList;
      }
    });
    this.setState({ cases: allCases });
  };

  goTopics = () => {
    this.props.changeLocation('/case/witness/topics');
  };
  componentWillReceiveProps(newProps) {
    trialEnd(this.state.firm);

    if (this.props.clicked != newProps.clicked) {
      axios
        .post(prod() + '/api/cases/getall', {
          firm: this.state.firm,
        })
        .then((user) => {

          this.setState(
            {
              cases: user.data.cases,
            },
            // () => console.log(this.state)
          );
        });
    }
    if (this.props.loc != newProps.loc) {
      this.props.history.push(newProps.loc);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.clicked !== this.props.clicked) {
      axios
        .post(prod() + '/api/cases/getall', {
          firm: this.state.firm,
        })
        .then((user) => {
          if (this.state.caseN || this.state.caseN === 0) {
            this.setState(
              (prevState) => (
                {
                  cases: user.data.cases,
                },
                () => this.props.changeLocation(this.props.location.pathname)
              )
            );
          } else {
            this.setState(
              (prevState) => (
                {
                  cases: user.data.cases,
                },
                () => this.props.changeLocation(this.props.location.pathname)
              )
            );
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }
  componentDidMount() {
    console.log("Navigation component did mount")
    if (this.props.authentication == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/firms/get', { firm: s.data.firm })
          .then((use) => {
            axios
              .post(prod() + '/api/cases/getall', {
                firm: s.data.firm,
              })
              .then((user) => {
                // console.log(s.data);
                this.setState(
                  {
                    cases: user.data.cases,
                    firm: s.data.firm,
                    use: s.data.id,
                    client: '',
                    name: '',
                    plan: use.data.plan,
                  },
                  () => this.props.changeLocation(this.props.location.pathname)
                );
              })

              .catch((err) => {
                console.log('MSG in err', err);
              });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
    }
  }
  setNavExpanded() {
    if (this.props.open === true) {
      if (this.props.loc === '/') {
        axios
          .post(prod() + '/api/cases/getall', {
            firm: this.state.firm,
          })
          .then((user) => {
            this.props.toggleSideMenu();
            if (this.state.caseN || this.state.caseN === 0) {
              this.setState(
                (prevState) => (
                  {
                    cases: user.data.cases,
                    navExpanded: !this.state.navExpanded,
                  },
                  () => {
                    this.props.changeLocation(this.props.location.pathname);
                  }
                )
              );
            } else {
              this.setState(
                (prevState) => (
                  {
                    cases: user.data.cases,
                    navExpanded: !this.state.navExpanded,
                  },
                  () => {
                    this.props.changeLocation(this.props.location.pathname);
                  }
                )
              );
            }
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      } else {
        this.props.toggleSideMenu();
        this.updateCase();
      }
    } else {
      this.props.toggleSideMenu();
      this.setState((prevState) => ({
        navExpanded: !this.state.navExpanded,
      }));
    }
  }
  goCase = () => {
    axios
      .post(prod() + '/api/cases/get', {
        case: this.props.case._id,
      })
      .then((user) => {
        this.props.history.push('/case');
        this.setState({ witness: undefined }, () =>
          this.props.changeLocation('/case', user.data, undefined)
        );
      });
  };
  goFacts = () => {
    this.props.history.push('/case/witness/facts');
    this.setState({ topic: undefined }, () => {
      this.props.changeLocation(
        '/case/witness/facts',
        this.props.case,
        this.props.witness,
        undefined
      );
    });
  };
  goSummaries = () => {
    this.props.history.push('/case/witness/summaries');
    this.setState({ topic: undefined }, () => {
      this.props.changeLocation(
        '/case/witness/summaries',
        this.props.case,
        this.props.witness,
        undefined
      );
    });
  };
  // Tags url
  goTags = () => {
    this.props.history.push('/case/tags');
    this.setState({ topic: undefined }, () => {
      this.props.changeLocation(
        '/case/tags',
        this.props.case,
        this.props.witness,
        undefined
      );
    });
  };
  goTimeline = () => {
    this.props.history.push('/case/timeline');
    this.setState({ topic: undefined }, () => {
      this.props.changeLocation(
        '/case/timeline',
        this.props.case,
        this.props.witness,
        undefined
      );
    });
  };
  goTopics = () => {
    this.props.history.push('/case/witness/topics');
    this.setState({ topic: undefined }, () =>
      this.props.changeLocation(
        '/case/witness/topics',
        this.props.case,
        this.props.witness,
        undefined
      )
    );
  };
  goAudioTranscription = () => {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        this.props.history.push('/');
        this.setState(
          { cases: user.data.cases, case: undefined, witness: undefined },
          () => this.props.changeLocation('/transcription/audio', undefined, undefined)
        );
      });
  }
  goHome = () => {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        this.props.history.push('/');
        this.setState(
          { cases: user.data.cases, case: undefined, witness: undefined },
          () => this.props.changeLocation('/', undefined, undefined)
        );
      });
  };
  goInfo = () => {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        this.props.history.push('/');
        this.setState(
          { cases: user.data.cases, case: undefined, witness: undefined },
          () => this.props.changeLocation('/information/impeachment')
        );
      });
  };
  goSettings = () => {
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        this.props.history.push('/');
        this.setState(
          { cases: user.data.cases, case: undefined, witness: undefined },
          () => this.props.changeLocation('/settings')
        );
      });
  };
  goActivity = () => {
    this.props.changeLocation('/activity');
  };

  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    let info = window.location.pathname;
    info = info.split('/');
    let loc = '/';
    if (info[1] && info[1] === 'information') {
      loc = '/information';
    } else if (info[1] && info[1] === 'settings') {
      loc = '/settings';
    } else if (
      info[1] &&
      info[1] === 'case' &&
      info[2] &&
      info[2] === 'witness' &&
      info[3] &&
      info[3] === 'topic'
    ) {
      loc = '/topic';
    } else if (
      info[1] &&
      info[1] === 'case' &&
      info[2] &&
      info[2] === 'witness' &&
      info[3] &&
      info[3] === 'fact'
    ) {
      loc = '/fact';
    } else if (
      info[1] &&
      info[1] === 'case' &&
      info[2] &&
      info[2] === 'witness' &&
      info[3] &&
      info[3] === 'summary'
    ) {
      loc = '/summary';
    } else if (
      info[1] &&
      info[1] === 'case' &&
      info[2] &&
      info[2] === 'witness'
    ) {
      loc = '/witness';
    } else if (info[1] && info[1] === 'case') {
      loc = '/case';
    }

    const authLinks = this.props.open ? (
      loc === '/' ? (
        <Dashboard
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          updateCase={this.updateCase}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          goAudioTranscription={this.goAudioTranscription}
          clicked2={this.props.clicked2}
          plan={this.state.plan}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          currentUser={this.state.use}
          getDoc={this.props.getDoc}
          clicked={this.props.clickedB}
        />
      ) : loc === '/case' ? (
        <CaseDashboard
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          updateCase={this.updateCase}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          changeLocationW={this.changeLocationW}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          case={this.props.case}
          goHome={this.goHome}
          goCase={this.goCase}
          getTopic={this.props.getTopic}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          goAudioTranscription={this.goAudioTranscription}
          loadOut={this.props.loadOut}
          refreshCaseWitnessList={this.refreshCaseWitnessList}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          clicked={this.props.clickedB}
        />
      ) : loc === '/witness' ? (
        <WitnessDashboard
          getWitness={this.props.getWitness}
          changeLocation={this.changeLocation}
          cases={this.state.cases}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goCase={this.goCase}
          case={this.props.case}
          witness={this.props.witness}
          changeLocationW={this.changeLocationW}
          updateCase={this.updateCase}
          getT={this.props.getT}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          setNavExpanded={this.setNavExpanded}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          updateWitness={this.props.updateWitness}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/topic' ? (
        <TopicDashboard
          getWitness={this.props.getWitness}
          changeLocation={this.changeLocation}
          cases={this.state.cases}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goCase={this.goCase}
          case={this.props.case}
          witness={this.props.witness}
          topic={this.props.topic}
          changeLocationW={this.changeLocationW}
          updateCase={this.updateCase}
          getT={this.props.getT}
          goInfo={this.goInfo}
          goTopics={this.goTopics}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          getQ={this.props.getSingleTopic}
          clicked2={this.props.clicked2}
          topic={this.props.topic}
          goTopics={this.goTopics}
          setNavExpanded={this.setNavExpanded}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          updateFromFocus={this.props.updateFromFocus}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/fact' ? (
        <FactDashboard
          getWitness={this.props.getWitness}
          changeLocation={this.changeLocation}
          cases={this.state.cases}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goCase={this.goCase}
          case={this.props.case}
          witness={this.props.witness}
          fact={this.props.fact}
          changeLocationW={this.changeLocationW}
          updateCase={this.updateCase}
          getT={this.props.getT}
          goInfo={this.goInfo}
          goFacts={this.goFacts}
          goTopics={this.goTopics}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          getFact={this.props.getSingleFact}
          clicked2={this.props.clicked2}
          setNavExpanded={this.setNavExpanded}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          updateFromFocus={this.props.updateFromFocus}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/summary' ? (
        <SummaryDashboard
          getWitness={this.props.getWitness}
          changeLocation={this.changeLocation}
          cases={this.state.cases}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goCase={this.goCase}
          case={this.props.case}
          witness={this.props.witness}
          statement={this.props.statement}
          changeLocationW={this.changeLocationW}
          updateCase={this.updateCase}
          getT={this.props.getT}
          goInfo={this.goInfo}
          goSummaries={this.goSummaries}
          goTopics={this.goTopics}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          getSummary={this.props.getSingleSummary}
          clicked2={this.props.clicked2}
          setNavExpanded={this.setNavExpanded}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          updateFromFocus={this.props.updateFromFocus}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/information' ? (
        <Information
          getWitness={this.props.getWitness}
          cases={this.state.cases}
          history={this.props.history}
          changeLocation={this.changeLocation}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          setNavExpanded={this.setNavExpanded}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/settings' ? (
        <Settings
          cases={this.state.cases}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          setNavExpanded={this.setNavExpanded}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          loadOut={this.props.loadOut}
          goActivity={this.goActivity}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/activity' ? (
        <Dashboard
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/case/tags' ? (
        <Tag
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          getFact={this.props.getSingleFact}
          goAudioTranscription={this.goAudioTranscription}
        />
      ) : loc === '/transcription/audio' ? (
        <AudioTranscript
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          getFact={this.props.getSingleFact}
          goAudioTranscription={this.goAudioTranscription}
        />
        ) : (
        <Dashboard
          cases={this.state.cases}
          updateCases={this.state.updateCases}
          getWitness={this.props.getWitness}
          history={this.props.history}
          changeLocation={this.changeLocation}
          use={this.state.use}
          setNavExpanded={this.setNavExpanded}
          firm={this.state.firm}
          goHome={this.goHome}
          goInfo={this.goInfo}
          goSettings={this.goSettings}
          goActivity={this.goActivity}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.goAudioTranscription}
        />
      )
    ) : (
      <NotExpanded
        changeLocation={this.changeLocation}
        cases={this.state.cases}
        getWitness={this.props.getWitness}
        goHome={this.goHome}
        setNavExpanded={this.setNavExpanded}
        goCase={this.goCase}
        goTopics={this.goTopics}
        goInfo={this.goInfo}
        goSettings={this.goSettings}
        goActivity={this.goActivity}
        loadOut={this.props.loadOut}
        darkMode={this.props.darkMode}
        getDoc={this.props.getDoc}
        goAudioTranscription={this.goAudioTranscription}
      />
    );
    return (
      <div>
        {isAuthenticated &&
          window.location.pathname != '/docs' &&
          window.location.pathname != '/season' &&
          authLinks}
      </div>
    );
  }
}
Navigation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Navigation));
