import React, { Component } from 'react';
import Dashboard from './Dashboard/Dashboard';

import Case from './Case/Case';

import { Route } from 'react-router-dom';
import Settings from './Settings/Settings';
import User from './Settings/User';
import Members from './Settings/Members';
import Payments from './Settings/Payment';
import Support from './Settings/Support';
import Topic from './Documents/Questions/Topic';
import Witness from './Witness/Witness';
import Focus from './Documents/Focus/Focus';
import Checklist from './Documents/Checklist/Checklist';
import PreparationChart from './Documents/PrepChart/PreparationChart';
import Timeline from './Documents/Timeline/Timeline';
import Timelines from './Documents/Timeline/Timelines';
import Activity from './Activity';

// info
import Exposing from './Information/Exposing';
import Framing from './Information/Framing';
import Impeachment from './Information/Impeachment';
import Credibility from './Information/Credibility';

import Tabs from './Navigation/Tabs';

// new charts
import Facts from './Documents/Facts/Facts';
import Fact from './Documents/Facts/Fact';
import Summaries from './Documents/Summary/Summaries';
import Summary from './Documents/Summary/Summary';
import FileTab from './Shared/FileTab';
import AudioTab from './Shared/AudioTab';

import './Shared/Sidebar.css';

import Tag from './Tags/Tag';
import AudioTranscript from './Shared/AudioTranscript';

export default class Layout extends Component {
  constructor() {
    super();
    this.state = {
      q: {}, // topic id for individual topic
      focus: {},
      checklist: {},
      prep: {},
      top: true,
      data: {},
      path: '',
      secondCase: {},
      secondWitness: {},
      secondTopic: {},
      secondPath: {},
      secondDocId: {},

      //doc
      refId: '',
      link: '',

      reload: false,
    };
    this.onClickOpen = this.onClickOpen.bind(this);
    this.getQ = this.getQ.bind(this);
    this.getDash = this.getDash.bind(this);
    this.toggleTop = this.toggleTop.bind(this);
    this.getTab = this.getTab.bind(this);
    this.getPath = this.getPath.bind(this);
    this.getFact = this.getFact.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.saveDocInfo = this.saveDocInfo.bind(this);
    this.reload = this.reload.bind(this);
  }

  getPath(path) {
    this.setState(
      {
        secondPath: path.path,
        secondCase: path.case,
        secondWitness: path.witness,
        secondTopic: path.topic,
        secondDocId: path.docId,
      },
      () => console.log('check')
    );
  }
  toggleTop() {
    if (this.state.top == true) {
      this.setState({ top: false });
    } else {
      this.setState({ top: true });
    }
  }
  onClickOpen() {
    if (this.state.open) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  getQ(a) {
    if (a !== undefined || a !== {} || a !== null) {
      this.props.getSingleTopic(a);
    }
  }
  getFact(a) {
    if (a !== undefined || a !== {} || a !== null) {
      // console.log(this.props);
      this.props.getSingleFact(a);
    }
  }
  getSummary(a) {
    if (a !== undefined || a !== {} || a !== null) {
      this.props.getSingleSummary(a);
    }
  }
  getDash(a) {
    if (a !== undefined || a != {} || a != null) {
      // this.setState({ case: a });
      console.log(this.state.case);
    } else {
      console.log('no');
    }
  }
  getTab(data) {
    this.setState({ data: data });
  }

  saveDocInfo(data) {
    this.setState({ refId: data.refId, link: data.link });
  }

  reload(bool) {
    console.log('reload');
    this.setState({ reload: bool });
  }

  render() {
    let load = <div />;
    let page = <div />;
    const lay = this.props.open !== true ? 'case-menu' : 'case-menu-expanded';

    const lay2 =
      this.state.top !== true
        ? this.props.open !== true
          ? 'c4-u'
          : 'c3-u'
        : this.props.open !== true
        ? 'c4'
        : 'c3';
    let split = lay2;
    let splitRight = '';
    // let filename = this.state.topic.topic.find(row => {
    //   if (row._id === this.state.secondTopic) {
    //     return row.ref.value;
    //   }
    // })
    let right = <div></div>;
    if (this.props.splitState == true) {
      split =
        this.state.top !== true
          ? this.props.open !== true
            ? 'c4-u-left'
            : 'c3-u-left'
          : this.props.open !== true
          ? 'c4-left'
          : 'c3-left';
      splitRight =
        this.state.top !== true
          ? this.props.open !== true
            ? 'c4-u-right'
            : 'c3-u-right'
          : this.props.open !== true
          ? 'c4-right'
          : 'c3-right';
      if (this.state.secondPath === '/case/witness/topic') {
        right = (
          <div className={splitRight}>
            <Topic
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              topic={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getQ={this.getQ}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              getTab={this.getTab}
              second={true}
              darkMode={this.props.darkMode}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/prep') {
        right = (
          <div className={splitRight}>
            <PreparationChart
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              prep={this.state.prep}
              open={this.props.open}
              getTab={this.getTab}
              second={true}
              darkMode={this.props.darkMode}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/checklist') {
        right = (
          <div className={splitRight}>
            <Checklist
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              open={this.props.open}
              getTab={this.getTab}
              second={true}
              darkMode={this.props.darkMode}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/focus') {
        right = (
          <div className={splitRight}>
            <Focus
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              second={true}
              open={this.props.open}
              getTab={this.getTab}
              darkMode={this.props.darkMode}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              handleTopics={this.props.handleTopics}
              getDoc={this.props.getDoc}
              getFact={this.getFact}
            />
          </div>
        );
      } else if (this.state.secondPath === '/information/impeachment') {
        right = (
          <div className={splitRight}>
            <Impeachment
              open={this.props.open}
              getTab={this.getTab}
              second={true}
            />
          </div>
        );
      } else if (this.state.secondPath === '/information/framing') {
        right = (
          <div className={splitRight}>
            <Framing
              open={this.props.open}
              getTab={this.getTab}
              second={true}
            />
          </div>
        );
      } else if (this.state.secondPath === '/information/exposing') {
        right = (
          <div className={splitRight}>
            <Exposing
              open={this.props.open}
              getTab={this.getTab}
              second={true}
            />
          </div>
        );
      } else if (this.state.secondPath === '/information/credibility') {
        right = (
          <div className={splitRight}>
            <Credibility
              open={this.props.open}
              getTab={this.getTab}
              second={true}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/fact') {
        right = (
          <div className={splitRight}>
            <Fact
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              fact={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getFact={this.getFact}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/summary') {
        right = (
          <div className={splitRight}>
            <Summary
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              statement={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              //
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/witness/timeline') {
        right = (
          <div className={splitRight}>
            <Timeline
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              statement={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              saveDocInfo={this.saveDocInfo}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              getDoc={this.props.getDoc}
              getFact={this.props.getSingleFact}
              updateCase={this.props.updateCase}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/timeline') {
        right = (
          <div className={splitRight}>
            <Timelines
              case={this.state.secondCase}
              // witness={this.state.secondWitness}
              statement={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              saveDocInfo={this.saveDocInfo}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              getDoc={this.props.getDoc}
              getFact={this.props.getSingleFact}
              updateCase={this.props.updateCase}
            />
          </div>
        );
      } else if (
        this.state.secondPath.includes(
          `/case/witness/file/${this.state.secondTopic}/${this.state.secondDocId}`
        )
      ) {
        right = (
          <div className={splitRight}>
            <FileTab
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              refId={this.state.secondTopic}
              filename={this.state.secondPath.slice(69)}
              path={this.state.secondPath}
              getTab={this.getTab}
              darkMode={this.props.darkMode}
              second={true}
              statement={this.props.statement}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              saveDocInfo={this.saveDocInfo}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              getFact={this.props.getSingleFact}
              updateCase={this.props.updateCase}
              docId={this.state.secondDocId}
            />
          </div>
        );
      } else if (this.state.secondPath === '/case/tags') {
        right = (
          <div className={splitRight}>
            <Tag
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              statement={this.state.secondTopic}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              saveDocInfo={this.saveDocInfo}
              getTab={this.getTab}
              topic={this.state.secondTopic}
              darkMode={this.props.darkMode}
              second={true}
              updateWitness={this.props.updateWitness}
              splitState={this.props.splitState}
              getDoc={this.props.getDoc}
              getFact={this.props.getSingleFact}
              updateCase={this.props.updateCase}
            />
          </div>
        );
      } else if (
        this.state.secondPath.includes(
          `/case/witness/file/audio/${this.state.secondTopic}/${this.state.secondDocId}`
        )
      ) {
        right = (
          <div className={splitRight}>
            <AudioTab
              case={this.state.secondCase}
              witness={this.state.secondWitness}
              refId={this.state.secondTopic}
              filename={this.state.secondPath.slice(75)}
              path={this.state.secondPath}
              getTab={this.getTab}
              darkMode={this.props.darkMode}
              statement={this.props.statement}
              open={this.props.open}
              changeSideMenu={this.props.changeSideMenu}
              getT={this.props.getTopic}
              getSummary={this.getSummary}
              clicked2={this.props.clicked2}
              clicked={this.props.clicked}
              updateWitness={this.props.updateWitness}
              saveDocInfo={this.saveDocInfo}
              topic={this.state.secondTopic}
              second={true}
              splitState={this.props.splitState}
              getFact={this.props.getSingleFact}
              updateCase={this.props.updateCase}
              docId={this.state.secondDocId}
            />
          </div>
        );
      }
    }

    page = (
      <>
        <div id={lay}>
          <Tabs
            top={this.state.top}
            open={this.props.open}
            changeTop={this.toggleTop}
            data={this.state.data}
            getDoc={this.props.getDoc}
            getPath={this.getPath}
            split={this.props.split}
            toggleSplit={this.props.toggleSplit}
            darkMode={this.props.darkMode}
          />
          <div className={split}>
            <Route
              exact
              path='/'
              render={() => (
                <Dashboard
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  open={this.props.open}
                  getWitness={this.props.getWitness}
                  unload={this.props.unload}
                  toggleSideMenu={this.props.toggleSideMenu}
                  sideMenuOpen={this.props.open}
                  top={this.state.top}
                  darkMode={this.props.darkMode}
                />
              )}
            />
            <Route
              exact
              path='/case'
              render={() => (
                <Case
                  open={this.props.open}
                  toggleSideMenu={this.props.toggleSideMenu}
                  case={this.props.case}
                  getT={this.props.getTopic}
                  changeSideMenu={this.props.changeSideMenu}
                  clicked={this.props.clicked}
                  top={this.state.top}
                  darkMode={this.props.darkMode}
                  case={this.props.case}
                />
              )}
            />
            <Route
              exact
              path='/settings/firm'
              render={() => <Settings open={this.props.open} />}
              path='/settings/firm'
              render={() => (
                <Settings
                  open={this.props.open}
                  darkMode={this.props.darkMode}
                />
              )}
            />
            {/* user */}
            <Route
              exact
              path='/settings'
              render={() => (
                <User
                  loadOut={this.props.loadOut}
                  open={this.props.open}
                  setDarkMode={this.props.setDarkMode}
                  darkMode={this.props.darkMode}
                />
              )}
            />
            {/* members */}
            <Route
              exact
              path='/settings/members'
              render={() => (
                <Members
                  open={this.props.open}
                  darkMode={this.props.darkMode}
                />
              )}
            />
            {/* payment */}
            <Route
              exact
              path='/settings/payment'
              render={() => (
                <Payments
                  open={this.props.open}
                  darkMode={this.props.darkMode}
                />
              )}
            ></Route>
            {/* support */}
            <Route
              exact
              path='/settings/support'
              render={() => <Support open={this.props.open} />}
            ></Route>
            <Route
              exact
              path='/case/witness'
              render={() => (
                <Witness
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  splitState={this.props.splitState}
                  updateWitness={this.props.updateWitness}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/topics'
              render={() => (
                <Witness
                  case={this.props.case}
                  witness={this.props.witness}
                  topic={this.props.topic}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getTopics={this.props.getTopic}
                  getQ={this.getQ}
                  getT={this.props.getTopic}
                  clicked={this.props.clicked}
                  top={this.state.top}
                  darkMode={this.props.darkMode}
                  splitState={this.props.splitState}
                  updateWitness={this.props.updateWitness}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/topic'
              render={() => (
                <Topic
                  case={this.props.case}
                  witness={this.props.witness}
                  topic={this.props.topic}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getQ={this.getQ}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/focus'
              render={() => (
                <Focus
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateFromFocus={this.props.updateFromFocus}
                  splitState={this.props.splitState}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  updateWitness={this.props.updateWitness}
                  handleTopics={this.props.handleTopics}
                  getDoc={this.props.getDoc}
                  getFact={this.getFact}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/checklist'
              render={() => (
                <Checklist
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/prep'
              render={() => (
                <PreparationChart
                  case={this.props.case}
                  witness={this.props.witness}
                  prep={this.state.prep}
                  open={this.props.open}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                />
              )}
            />
            <Route
              exact
              path='/activity'
              render={() => <Activity open={this.props.open} />}
            />
            <Route
              exact
              path='/information/impeachment'
              render={() => (
                <Impeachment open={this.props.open} getTab={this.getTab} />
              )}
            />
            <Route
              exact
              path='/information/framing'
              render={() => (
                <Framing open={this.props.open} getTab={this.getTab} />
              )}
            />
            <Route
              exact
              path='/information/exposing'
              render={() => (
                <Exposing open={this.props.open} getTab={this.getTab} />
              )}
            />
            <Route
              exact
              path='/information/credibility'
              render={() => (
                <Credibility open={this.props.open} getTab={this.getTab} />
              )}
            />
            {/* new */}
            <Route
              exact
              path='/case/witness/facts'
              render={() => (
                <Facts
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  darkMode={this.props.darkMode}
                  splitState={this.props.splitState}
                  getFact={this.getFact}
                  updateWitness={this.props.updateWitness}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/fact'
              render={() => (
                <Fact
                  case={this.props.case}
                  witness={this.props.witness}
                  fact={this.props.fact}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getFact={this.getFact}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/summaries'
              render={() => (
                <Summaries
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  darkMode={this.props.darkMode}
                  splitState={this.props.splitState}
                  getSummary={this.getSummary}
                  updateWitness={this.props.updateWitness}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/summary'
              render={() => (
                <Summary
                  case={this.props.case}
                  witness={this.props.witness}
                  statement={this.props.statement}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getSummary={this.getSummary}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/timeline'
              render={() => (
                <Timeline
                  case={this.props.case}
                  witness={this.props.witness}
                  statement={this.props.statement}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getSummary={this.getSummary}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                  getDoc={this.props.getDoc}
                  saveDocInfo={this.saveDocInfo}
                  getFact={this.props.getSingleFact}
                  updateCase={this.props.updateCase}
                />
              )}
            />
            <Route
              exact
              path='/case/timeline'
              render={() => (
                <Timelines
                  case={this.props.case}
                  // witness={this.props.witness}
                  statement={this.props.statement}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getSummary={this.getSummary}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                  getDoc={this.props.getDoc}
                  saveDocInfo={this.saveDocInfo}
                  getFact={this.props.getSingleFact}
                  updateCase={this.props.updateCase}
                  reloaded={this.state.reload}
                  reload={this.reload}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/file/:refId/:docId/:filename'
              render={() => (
                <FileTab
                  case={this.props.case}
                  witness={this.props.witness}
                  statement={this.props.statement}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getSummary={this.getSummary}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                  getDoc={this.props.getDoc}
                  saveDocInfo={this.saveDocInfo}
                  getFact={this.props.getSingleFact}
                  updateCase={this.props.updateCase}
                />
              )}
            />
            <Route
              exact
              path='/case/tags'
              render={() => (
                <Tag
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                  getFact={this.props.getSingleFact}
                  updateCase={this.props.updateCase}
                />
              )}
            />
            <Route
              exact
              path='/case/witness/file/audio/:refId/:docId/:filename'
              render={() => (
                <AudioTab
                  case={this.props.case}
                  witness={this.props.witness}
                  statement={this.props.statement}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  getT={this.props.getTopic}
                  getSummary={this.getSummary}
                  clicked2={this.props.clicked2}
                  clicked={this.props.clicked}
                  getTab={this.getTab}
                  darkMode={this.props.darkMode}
                  updateWitness={this.props.updateWitness}
                  splitState={this.props.splitState}
                  getDoc={this.props.getDoc}
                  saveDocInfo={this.saveDocInfo}
                  getFact={this.props.getSingleFact}
                  updateCase={this.props.updateCase}
                />
              )}
            />
            <Route
              exact
              path='/transcription/audio'
              render={() => (
                <AudioTranscript
                  case={this.props.case}
                  witness={this.props.witness}
                  open={this.props.open}
                  changeSideMenu={this.props.changeSideMenu}
                  darkMode={this.props.darkMode}
                  splitState={this.props.splitState}
                />
              )}
            />
          </div>
          {right}
          <div className='hui'></div>
        </div>
      </>
    );
    return <>{page}</>;
  }
}
