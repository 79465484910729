import React, { Component } from 'react';
import { Modal, Button, Tooltip, OverlayTrigger, ButtonToolbar } from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';

import {
  PdfLoader,
  PdfHighlighter,
  Highlight,
  Popup,
  AreaHighlight,
  setPdfWorker,
} from 'react-pdf-highlighter';

import Tip from './Tip';
import Spinner from './Spinner';
import Sidebar from './Sidebar';

import './UploadDocument.css';

const parseIdFromHash = () =>
  document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  document.location.hash = '';
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className='Highlight__popup'>
      {comment.emoji} {comment.text}
    </div>
  ) : null;

class FilePreviewModal extends Component {
  constructor() {
    super();
    this.state = {
      url: '',
      highlights: [],
      preview: true,
    };

    this.deleteHighlight = this.deleteHighlight.bind(this);
    this.pinHighlight = this.pinHighlight.bind(this);
    this.resetHighlights = this.resetHighlights.bind(this);
    this.getNextId = this.getNextId.bind(this);
  }
  resetHighlights = () => {
    this.setState({ highlights: [] });
  };

  scrollViewerTo = (highlight) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  getNextId = () => String(Math.random()).slice(2);

  componentDidMount() {
    window.addEventListener(
      'hashchange',
      this.scrollToHighlightFromHash,
      false
    );
  }

  getHighlightById(id) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight._id === id);
  }

  addHighlight(highlight) {
    const highlights = [...this.state.highlights];
    highlights.push({ ...highlight, _id: this.getNextId(), pinned: false });

    this.setState({ highlights });
  }

  // ONLY for updating image highlight, not texts
  updateHighlight(highlightId, position, content) {
    // console.log('Updating highlight', highlightId, position, content);

    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          _id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return _id === highlightId
          ? {
              _id,
              position: { ...originalPosition, ...position },
              content: { ...originalContent, ...content },
              ...rest,
            }
          : h;
      }),
    });
  }

  deleteHighlight(highlightId) {
    const updatedHighlights = this.state.highlights.filter(
      (highlight) => highlight._id !== highlightId
    ); // remove the highlight from the state

    this.setState({ highlights: updatedHighlights });
  }

  // pin or unpin highlight, pinned is either true or false
  pinHighlight(highlightId, pinned) {
    //make a shallow copy of the highlights
    const highlights = [...this.state.highlights];
    //find the index of the highlight you're updating, and pin it
    const highlightIndex = this.state.highlights.findIndex(
      (h) => h._id === highlightId
    );
    const updatedHighlight = { ...highlights[highlightIndex], pinned };

    //find the index of the previously pinned highlight to unpin it
    const pinnedHighlightIndex = this.state.highlights.findIndex(
      (h) => h.pinned === true
    );
    const unpinnedHighlight = {
      ...highlights[pinnedHighlightIndex],
      pinned: false,
    };

    //insert updated highlight to the highlights copy
    highlights[highlightIndex] = updatedHighlight;
    highlights[pinnedHighlightIndex] = unpinnedHighlight;

    this.setState({ highlights });
  }

  render() {
    console.log("highlights: ", this.state.highlights)
    const { url, highlights } = this.state;
    return (
      <Modal
        show={this.props.fileModal}
        id='file-modal'
        dialogClassName={
          this.props.darkMode ? 'dark-modal file-preview' : 'file-preview'
        }
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header>
          <Modal.Title id='example-custom-modal-styling-title'>
            {this.props.name}
          </Modal.Title>
          <div className="buttons">
            <Button
              onClick={() => {
                this.props.deleteFile();
                this.props.openFileViewModal(false);
              }}
              variant='secondary'
            >
              Back
            </Button>
            {this.props.summary && (
              <ButtonToolbar>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode
                          ? 'dark-tooltip-add'
                          : 'tooltip-add'
                      }
                    >
                      Click this button to make each highlight a row with the document in the reference column and highlighted text in the evidence column
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={() => {
                      this.props.openFileViewModal(false);
                      this.props.openUploadDocModal(false);
                      this.setState({ highlights: [] });
                      this.props.closeModals();
                      if (this.props.refId) {
                        this.props.createHighlightsToRows(highlights, this.props.refId);
                      } else {
                        this.props.createHighlightsToRows(highlights, null);
                      }
                    }}
                    variant='secondary'
                  >
                    Summarize & Upload
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            )}
            <Button
              onClick={() => {
                this.props.uploadDoc();
                this.props.openFileViewModal(false);
                this.props.openUploadDocModal(false);
                if (this.props.refId) {
                  this.props.saveHighlights(highlights, this.props.link, this.props.refId);
                } else {
                  this.props.saveHighlights(highlights, this.props.link, null);
                }
                this.setState({ highlights: [] });
                this.props.closeModals();
              }}
              variant='secondary'
            >
              Upload
            </Button>
          </div>
        </Modal.Header>

        <Modal.Body className='webviewer' ref={this.props.fileRef}>
          <div className='pdf-container'>
            <Sidebar
              highlights={highlights}
              resetHighlights={this.resetHighlights}
              toggleDocument={this.toggleDocument}
              deleteHighlight={this.deleteHighlight}
              pinHighlight={this.pinHighlight}
              preview={true}
              darkMode={this.props.darkMode}
              summary={this.props.summary}
            />

            <div className='file-container'>
              <PdfLoader
                url={url ? url : this.props.link}
                beforeLoad={<Spinner />}
              >
                {(pdfDocument) => (
                  <PdfHighlighter
                    pdfDocument={pdfDocument}
                    enableAreaSelection={(event) => event.altKey}
                    onScrollChange={resetHash}
                    scrollRef={(scrollTo) => {
                      this.scrollViewerTo = scrollTo;

                      this.scrollToHighlightFromHash();
                    }}
                    onSelectionFinished={(
                      position,
                      content,
                      hideTipAndSelection,
                      transformSelection
                    ) => (
                      <Tip
                        onOpen={transformSelection}
                        darkMode={this.props.darkMode}
                        onConfirm={(comment) => {
                          this.addHighlight({ content, position, comment });

                          hideTipAndSelection();
                        }}
                      />
                    )}
                    highlightTransform={(
                      highlight,
                      index,
                      setTip,
                      hideTip,
                      viewportToScaled,
                      screenshot,
                      isScrolledTo
                    ) => {
                      const isTextHighlight = !Boolean(
                        highlight.content && highlight.content.image
                      );

                      const component = isTextHighlight ? (
                        <Highlight
                          isScrolledTo={isScrolledTo}
                          position={highlight.position}
                          comment={highlight.comment}
                        />
                      ) : (
                        <AreaHighlight
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            this.updateHighlight(
                              highlight._id,
                              { boundingRect: viewportToScaled(boundingRect) },
                              { image: screenshot(boundingRect) }
                            );
                          }}
                        />
                      );

                      return (
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={(popupContent) =>
                            setTip(highlight, (highlight) => popupContent)
                          }
                          onMouseOut={hideTip}
                          key={index}
                          children={component}
                        />
                      );
                    }}
                    highlights={highlights}
                  />
                )}
              </PdfLoader>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}

export default FilePreviewModal;
