import React, { Component } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

import prod from "../../../../prod";

import axios from "axios";
import "../../../../Tags.css";

export default class SummaryHighlightModal extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      cases: [],
      show: false,
      dShow: false,
      firm: "",
      use: "",
      client: "",
      name: "",
      user: "",
      uName: "",
      summaryName: "",
      selectFact: "",
      facts: "",
      witness: "",
      case: "",
      newFactName: "",
      activeSelectedFact: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({
      newFactName: value,
    });
    // this.handleFactSubmit();
  };

  handleSubmit(e) {
    console.log("handle submit e:", e);
    this.props.handleWSubmit({ name: this.state.wName }, e);
    this.setState({ wName: "" });
  }
  componentDidMount() {
    this.setState({
      uName: this.props.uWName,
    });
    axios
      .get(prod() + "/api/cases/facts/all", {
        params: { id: this.props.case._id },
      })
      .then((res) => {
        this.setState({
          loading: false,
          facts: res?.data?.facts || [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          facts: [],
        });
      })
      .finally(() => {});
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.uWName !== this.props.uWName) {
      this.setState({
        uName: this.props.uWName,
      });
    }

    //when new fact was submitted
    if (prevState.newFactName !== this.state.newFactName && !this.state.newFactName) {
      console.log("called after submitting new fact")
      axios
      .get(prod() + "/api/cases/facts/all", {
        params: { id: this.props.case._id },
      })
      .then((res) => {
        this.setState({
          loading: false,
          facts: res?.data?.facts || [],
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: true,
          facts: [],
        });
      })
    }

  }

  getUserFacts = () => {
    axios
      .post(prod() + "/api/cases/getall/witness/fact", {
        case: this.props.case["_id"],
        witnesses: this.props.witness["_id"],
      })
      .then((use) => {
        this.setState({
          facts: use.data.facts,
          witness: this.props.witness,
          case: this.props.case,
        });
      })
      .catch((err) => {
        console.log("MSG in err", err);
      });
  };

  getReferenceValue = () => {
    const { highlightedTextRef } = this.props;

    if (!highlightedTextRef) return "";

    if (typeof highlightedTextRef === "string") return highlightedTextRef;

    return highlightedTextRef.reference;
  };

  // used for multiple highlights
  getReferenceValueForHighlight = (highlightRef) => {

    if (!highlightRef) return "";

    if (typeof highlightRef === "string") return highlightRef;

    return highlightRef.reference;
  };

  createFactList = () => {
    if (this.state.facts.length === 0) return;

    // Always show current witness facts + non duplicate from other witness
    const otherWitFacts = this.state.facts.filter(
      (f) => f.witnessId != this.props.witness._id
    );
    const thisWitFacts = this.state.facts
      .filter((f) => f.witnessId == this.props.witness._id)
      .map((f) => ({ ...f, witnessName: "Tag from this witness" }));

    const validFacts = [...thisWitFacts];
    otherWitFacts.forEach((f) => {
      const duplicates = thisWitFacts.filter((a) => a.name == f.name);

      if (!duplicates.length)
        validFacts.push({ ...f, witnessName: "Tag from other witness" });
    });
    const factsList = validFacts.map((fact) => (
      <>
        <div
          className={"factItem"}
          id={
            fact.witnessId === this.props.witness._id ? "current-witness" : ""
          }
          onClick={() =>
            this.props.witness._id == fact.witnessId
              ? this.handleSelectFact(fact)
              : this.createNewFactFromExisting(fact)
          }
        >
          <div>
            <p className="factItem-text">{fact.name}</p>
            <p className="factItem-witness">{fact.witnessName}</p>
          </div>
        </div>
      </>
    ));

    return factsList;
  };

  createNewFactFromExisting = (data) => {
    const { highlightedText, highlightedTextRef, multipleHighlights } = this.props;
    let fact = {
      name: data.name,
      witness: this.props.witness._id,
      case: this.props.case._id,
      facts: [
        {
          reference: this.getReferenceValue(),
          evidence: highlightedText,
          ref:
            typeof highlightedTextRef === "string"
              ? {}
              : highlightedTextRef.ref,
        },
      ],
    };
    let rowIds = [];
    let docIds = [];

    //highlighting multiple rows
    if (multipleHighlights.length && !highlightedText && !highlightedTextRef) {
      let highlightedFacts = [];

      multipleHighlights.forEach(highlight => {
        rowIds.push(highlight.highlightedTextRef._id);
        highlightedFacts.push(
          {
            reference: this.getReferenceValueForHighlight(highlight.highlightedTextRef),
            evidence: highlight.highlightedText,
            ref:
              typeof highlight.highlightedTextRef === "string"
                ? {}
                : highlight.highlightedTextRef.ref,
          }
        );
      });

      fact.facts = highlightedFacts;

      axios
        .post(prod() + "/api/cases/create/witness/fact/tag", fact)
        .then((use) => {
          // console.log(use);
          //copy highlights over and update document locations
          const witness = use.data.case.witnesses.find(w => w._id === this.props.witness._id);
          const newFact = witness.facts[witness.facts.length - 1];
          const newRowIds = [];
          for (let i = 0; i < newFact.fact.length; i++) {
            if (newFact.fact[i].ref?.document) {
              docIds.push(newFact.fact[i].ref.document)
              newRowIds.push(newFact.fact[i]._id);
            }
          }
          this.copyHighlights(rowIds, newRowIds)
          this.updateDocLocations(docIds, newFact.name, newFact._id);
          //reset and close modal
          this.setState({ newFactName: "" });
          this.props.handleHighlightClose();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            user: "Fact already exist",
          });
        });
    //highlight one row
    } else if (fact.name != "" && fact.witness != undefined && fact.case != "" && !multipleHighlights.length) {
      rowIds.push(highlightedTextRef._id);
      axios
        .post(prod() + "/api/cases/create/witness/fact/tag", fact)
        .then((use) => {
          console.log(use);
          //copy highlights to the docs copied and update doc locations
          if (highlightedTextRef.ref?.document) {
            const witness = use.data.case.witnesses.find( witness => witness._id === this.props.witness._id);
            const newFact = witness.facts[witness.facts.length - 1];
            const newRowId = newFact.fact[0]._id;
            docIds = [newFact.fact[0].ref.document];
            this.copyHighlights(rowIds, [newRowId]);
            this.updateDocLocations(docIds, newFact.name, newFact._id);
          }
          //reset and close modal
          this.setState({ newFactName: "" });
          this.props.handleHighlightClose();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            user: "Fact already exist",
          });
        });
    } 
  };

  handleSelectFact(fact) {
    console.log(fact);
    console.log(this.props.highlightedTextRef)
    let rowIds = [];
    let docIds = [];

    const { highlightedText, highlightedTextRef, multipleHighlights } = this.props;
    this.setState({
      selectFact: fact,
      newFactName: "",
    });
    console.log("Text ref: ", highlightedTextRef)

    // check if there is multiple highlights
    // else make a post request for 1 highlight
    if (multipleHighlights.length && !highlightedText && !highlightedTextRef) {

      let requests = [];

      multipleHighlights.forEach(highlight => {
        // if (highlight.highlightedText !== 'Screenshot') {
          rowIds.push(highlight.highlightedTextRef._id);
  
          const data = {
            case: this.props.case._id,
            witness: fact.witnessId,
            fact: fact._id,
            reference: this.getReferenceValueForHighlight(highlight.highlightedTextRef),
            evidence: highlight.highlightedText,
            ref: typeof highlight.highlightedTextRef === "string" ? {} : highlight.highlightedTextRef.ref,
          };
          let request = axios.post(prod() + "/api/cases/create/witness/fact/tag/add", data);
          requests.push(request);
        // }
      });

      axios
        .all(requests)
        .then((use) => {
          console.log("use: ", use)
          //copy highlights over and update doc location
          const newRowIds = [];
          use.forEach(u => {
            const witness = u.data.case.witnesses.find(w => w._id === fact.witnessId);
            const selectedFact = witness.facts.find(f => f._id === fact._id);
            if (selectedFact.fact[selectedFact.fact.length - 1].ref?.document) {
              newRowIds.push(selectedFact.fact[selectedFact.fact.length - 1]._id);
              docIds.push(selectedFact.fact[selectedFact.fact.length - 1].ref.document);
            }
          })
          this.copyHighlights(rowIds, newRowIds);
          this.updateDocLocations(docIds, fact.name, fact._id);
          //close modal
          this.props.handleHighlightClose();
        })
        .catch((err) => console.log(err));
    } else {
      rowIds.push(highlightedTextRef._id);

      const data = {
        case: this.props.case._id,
        witness: fact.witnessId,
        fact: fact._id,
        reference: this.getReferenceValue(),
        evidence: highlightedText,
        ref: typeof highlightedTextRef === "string" ? {} : highlightedTextRef.ref,
      };
  
      axios
        .post(prod() + "/api/cases/create/witness/fact/tag/add", data)
        .then((use) => {
          console.log("use: ", use.data.case)
          //copy highlights over if the ref has documents
          if (highlightedTextRef.ref?.document) {
            const witness = use.data.case.witnesses.find(witness => witness._id === fact.witnessId);
            const selectedFact = witness.facts.find(f => f._id === fact._id);
            const newRowId = selectedFact.fact[selectedFact.fact.length - 1]._id;
            this.copyHighlights(rowIds, [newRowId]);
            this.updateDocLocations(docIds, fact.name, fact._id);
          }
          //close modal
          this.props.handleHighlightClose();
        })
        .catch((err) => console.log(err));
    }

  }

  // creating a new fact
  handleNewFactSubmit = (data) => {
    data.preventDefault();
    let rowIds = [];
    let docIds = [];
    const { highlightedText, highlightedTextRef, multipleHighlights } = this.props;
    let fact = {
      name: this.state.newFactName,
      witness: this.props.witness._id,
      case: this.props.case._id,
      facts: [
        {
          reference: this.getReferenceValue(),
          evidence: highlightedText,
          ref:
            typeof highlightedTextRef === "string"
              ? {}
              : highlightedTextRef.ref,
        },
      ],
    };

    if (multipleHighlights.length && !highlightedText && !highlightedTextRef && fact.name != "" && fact.witness != undefined && fact.case != "") {
      let highlightedFacts = [];
      

      multipleHighlights.forEach(highlight => {
        // if (highlight.highlightedText !== 'Screenshot') {
          highlightedFacts.push(
            {
              reference: this.getReferenceValueForHighlight(highlight.highlightedTextRef),
              evidence: highlight.highlightedText,
              ref:
                typeof highlight.highlightedTextRef === "string"
                  ? {}
                  : highlight.highlightedTextRef.ref,
            }
          )
          rowIds.push(highlight.highlightedTextRef._id);
        // }
      });

      fact.facts = highlightedFacts;

      axios
        .post(prod() + "/api/cases/create/witness/fact/tag", fact)
        .then((use) => {
          console.log(use);
          //copy highlights over
          const witness = use.data.case.witnesses.find(w => w._id === this.props.witness._id);
          const newFact = witness.facts[witness.facts.length - 1];
          const newRowIds = [];
          for (let i = 0; i < newFact.fact.length; i++) {
            if (newFact.fact[i].ref?.document){
              docIds.push(newFact.fact[i].ref.document)
              newRowIds.push(newFact.fact[i]._id);
            }
          }
          this.copyHighlights(rowIds, newRowIds)
          this.updateDocLocations(docIds, newFact.name, newFact._id);
          //reset and close modal
          this.setState({ newFactName: "" });
          this.props.handleHighlightClose();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            user: "Fact already exist",
          });
        });
    } else if (fact.name != "" && fact.witness != undefined && fact.case != "" && !multipleHighlights.length) {
      rowIds.push(highlightedTextRef._id);

      axios
        .post(prod() + "/api/cases/create/witness/fact/tag", fact)
        .then((use) => {
          console.log(use);
          //copy highlights and update doc locations
          if (highlightedTextRef.ref?.document) {
            const witness = use.data.case.witnesses.find( witness => witness._id === this.props.witness._id);
            const newFact = witness.facts[witness.facts.length - 1];
            const newRowId = newFact.fact[0]._id;
            docIds = [newFact.fact[0].ref.document];
            this.copyHighlights(rowIds, [newRowId]);
            this.updateDocLocations(docIds, newFact.name, newFact._id);
          }
          //reset and close modal
          this.setState({ newFactName: "" });
          this.props.handleHighlightClose();
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            user: "Fact already exist",
          });
        });
    } else {
      this.setState({
        user: "Please fill out all fact fields",
      });
    }
  };
  renderMultipleHighlightTitles = () => {
    const { multipleHighlights } = this.props;
    let filteredHighlights = multipleHighlights.filter((highlight, index) => {
      return multipleHighlights.indexOf(highlight) === index;
    });
    const titles = filteredHighlights.map((highlight, index) => {
      // if (highlight.highlightedText !== 'Screenshot') {
        return (
          <>
            <div>
              <h5>
                <div className="highlighted--text">
                  Highlight {index + 1}:
                </div>
                {highlight.highlightedText.includes('storage.googleapis.com/highlight-images') ? (
                      <>
                        <img 
                          src={highlight.highlightedText.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                          alt="screenshot"
                        /> 
                      </>
                    ): (
                      <>{highlight.highlightedText}</>
                    )}
              </h5>
            </div>
            <hr></hr>
          </>
        )
      // }
    });
    return titles;
  };

  copyHighlights = (rowIds, newRowIds) => {
    console.log("rowIds:", rowIds)
    console.log("newrowIds:", newRowIds)
    //take the original row ids and then copy the highlights to the new row ids
    axios
      .post(prod() + '/api/file/highlights/copy',
        { rowIds: rowIds, newRowIds: newRowIds }
      )
      .then((res) => {
        console.log("res", res.data);
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }

  updateDocLocations = (docIds, name, factId) => {
    let docIdsObj = {};
    docIds.forEach(docId => {
      if (!docIdsObj[docId]) docIdsObj[docId] = true;
    })
    console.log("doc ids: ", Object.keys(docIdsObj));
    const location = {
      case: this.props.case,
      witness: this.props.witness,
      name: name,
      path: '/case/witness/fact',
      fact: factId,
    }
    axios
      .post(prod() + '/api/documents/add/location', { location, docIds: Object.keys(docIdsObj) })
      .then(res => {
        console.log(res)
      })
      .catch(e => console.log(e));
  }

  render() {
    // console.log("this.props modal: ", this.props)
    return (
      <>
        <Modal
          dialogClassName={
            this.props.darkMode ? "dark-modal highlight" : "highlight"
          }
          show={this.props.highlightShow}
          onHide={() => {
            this.setState({ newFactName: "" });
            this.props.handleHighlightClose();
          }}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClos();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Or Select Fact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.multipleHighlights.length ? 
                this.renderMultipleHighlightTitles() 
                : (
                  <>
                    {this.props.highlightedText.includes('storage.googleapis.com/highlight-images') ? (
                      <h5>
                        <div className="highlighted--text">Image:</div>
                        <img 
                          src={this.props.highlightedText.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                          alt="screenshot"
                        /> 
                      </h5>
                    ): (
                      <h5><div className="highlighted--text">Highlighted Text:</div> {this.props.highlightedText}</h5>
                    )}
                  </>
                )
              }
              {this.state.user && (
                <div className="invalid-feedback invalid-feedback-c">
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId="linkName">
                <Form.Control
                  type="text"
                  placeholder="Enter The Name Of The New Fact"
                  value={this.state.newFactName ? this.state.newFactName : ""}
                  onChange={this.handleChange}
                  name="tagName"
                  ref={this.props.linkRef}
                />
              </Form.Group>
              <Button
                className="select-tag s-item-documents"
                onClick={(e) => {
                  this.handleNewFactSubmit(e);
                }}
              >
                Create Fact Tag
              </Button>
              <div className="help">
                <h5>Select a Tag</h5>
                <div className="all-case-tags">
                  {this.state.loading && <Spinner animation="border" />}
                  {!this.state.loading && <form>{this.createFactList()}</form>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div style={{ flex: 1 }}></div>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
