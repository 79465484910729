import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PDFViewer from '../Shared/PDFViewer';

class FileViewModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Modal
        show={this.props.fileModal}
        onHide={() => this.props.openFileViewModal(false, '')}
        id='file-modal'
        dialogClassName={
          this.props.darkMode ? 'dark-modal file-preview' : 'file-preview'
        }
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header className='file-modal_header' closeButton>
          <Modal.Title id='example-custom-modal-styling-title'>
            {this.props.value.ref.document.name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <PDFViewer refId={this.props.refId} link={this.props.link} />
        </Modal.Body>
      </Modal>
    );
  }
}

export default FileViewModal;
