import React, { Component } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { Player } from 'video-react';
import '../../../../node_modules/video-react/dist/video-react.css';

import '../../../styles/tag.css'

export class TagModal extends Component {
  constructor() {
    super();
    this.state = {
      //help
      helpVideoShow: false,
      
      //add new fact
      reference: '',
      evidence: '',
      selectedWitnessId: '',
      selectedFactId: '',
      selectFact: true,
      fact: '',
      error: '',
    };
    this.showCreateNewFact = this.showCreateNewFact.bind(this);
    this.showSelectFact = this.showSelectFact.bind(this);
    this.submit = this.submit.bind(this);
  }
  
  handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({ [name]: value });
  }

  submit(e) {
    e.preventDefault();

    if (this.state.selectFact) { //selecting from existing facts
      if (this.state.selectedWitnessId === "" || this.state.selectedWitnessId === 'Select' || this.state.selectedFactId === "" || this.state.selectedFactId === "Select" || this.state.reference === "" || this.state.evidence === "") {
        this.setState({ error: "Error: Fields should not be empty"})
      } else {
        this.props.handleSubmit(this.state.selectedWitnessId, this.state.selectedFactId, this.state.reference, this.state.evidence, this.state.selectFact, this.state.fact);
        this.setState({ reference: '', evidence: '', selectedWitnessId: '', selectedFactId: '', fact: '', selectFact: true, error: '' });
      }
    } else { //creating a new fact
      if (this.state.selectedWitnessId === "" || this.state.selectedWitnessId === 'Select' || this.state.fact === "" || this.state.reference === "" || this.state.evidence === "") {
        this.setState({ error: "Error: Fields should not be empty"})
      } else {
        this.props.handleSubmit(this.state.selectedWitnessId, this.state.selectedFactId, this.state.reference, this.state.evidence, this.state.selectFact, this.state.fact);
        this.setState({ reference: '', evidence: '', selectedWitnessId: '', selectedFactId: '', fact: '', selectFact: true, error: '' });
      }
    }
  }

  showSelectFact() {
    this.setState({ selectFact: true, fact: '' });
  }
  showCreateNewFact() {
    this.setState({ selectFact: false, selectedFactId: '' });
  }

  render() {
    // console.log("state: ", this.state)
    return (
      <>
        {/* help */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What Are Statement Facts By Topics</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  When you are preparing your questions, it is useful to have
                  the facts, from pre-trial statements, separated by topic. This
                  will allow you to prepare questions more easily as you will
                  have the pre-trial statement facts separate by topic when you
                  prepare questions on each topic. Each question topic can have
                  a corresponding document that lists the relevant facts for
                  that topic. The “Facts by Topic” document can then be put on a
                  split screen on the computer as you are drafting questions.
                  This gives a summary of the relevant portion. You can then go
                  directly to the pre-trial statement or testimony on split
                  screen, next to the questions, instead of the summary if you
                  want the exact wording to the pre-trial statement. These
                  statements can be exported and printed as PDF versions
                </p>
                {/* <div>
                    <div onClick={() => this.setState({ helpVideoShow: !this.state.helpVideoShow })} className="help-video">
                      <span className="help-video_click" style={{ marginBottom: '1rem', color: '#2B7FC0' }}>Click to see Help Video</span>
                    </div>
                    {this.state.helpVideoShow && (
                      <Player>
                        <source 
                          src="https://storage.cloud.google.com/aleri-app-bucket/aleri-test-facttags-video.mp4?authuser=0"
                          width={200}
                        />
                      </Player>
                    )}
                  </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/settings/support'>
                <Button variant='outline-secondary'>Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Add New Fact */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Form onSubmit={(e) => this.submit(e)}>
            <Modal.Header closeButton>
              <Modal.Title>Add New Fact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.error && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.error}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Reference</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Reference'
                  value={this.state.reference}
                  onChange={(e) => this.handleChange(e)}
                  name='reference'
                  ref={this.props.ref}
                />
                <Form.Label>Evidence</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Evidence'
                  value={this.state.evidence}
                  onChange={(e) => this.handleChange(e)}
                  name='evidence'
                />
                <Form.Label style={{ marginTop: '10px' }}>
                  Select Witness
                </Form.Label>
                <Form.Control
                  as='select'
                  custom
                  onChange={(e) => this.handleChange(e)}
                  name='selectedWitnessId'
                >
                  <option>Select</option>
                  {Object.entries(this.props.getWitnessMap()).map(
                    ([key, value]) => (
                      <option value={key} key={key}>
                        {value}
                      </option>
                    )
                  )}
                </Form.Control>
                <Form.Label style={{ marginTop: '10px' }}>
                  <span onClick={this.showSelectFact} className={this.state.selectFact ? "add-fact select" : "add-fact"}>Select Fact</span>
                  <span> OR </span>
                  <span onClick={this.showCreateNewFact} className={this.state.selectFact ? "add-fact" : "add-fact create"}>Create A New Fact</span>
                </Form.Label>
                {this.state.selectFact ? (
                  <Form.Control
                    as='select'
                    custom
                    onChange={(e) => this.handleChange(e)}
                    name='selectedFactId'
                  >
                    <option>Select</option>
                    {Object.entries(this.props.getWitnessFactsObj(this.state.selectedWitnessId)).map(
                      ([key, value]) => (
                        <option value={key} key={key}>
                          {value}
                        </option>
                      )
                    )}
                  </Form.Control>
                ): (
                  <Form.Control
                    type='text'
                    placeholder='Enter New Fact'
                    value={this.state.fact}
                    onChange={(e) => this.handleChange(e)}
                    name='fact'
                  />
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Add Fact
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default withRouter(TagModal);
