import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Form,
  Button,
  Col,
  Row,
  Container,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  ProgressBar,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';

import Pulse from 'react-reveal/Pulse';

import Fade from 'react-reveal/Fade';
import FactModal from './FactModal';
import NewRow from '../../Shared/NewRow';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import LinkDocument from '../../Shared/LinkDocument';
import FactRow from './FactRow';
import SummaryTimelineModal from '../Summary/SummaryTimelineModal';

// import ReactGA from 'react-ga';

export const logPageView = () => {
  window.analytics.page('Fact');
};
class Fact extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      fact: {},
      q: [],

      reference: '',
      evidence: '',

      deleted: '',
      dShow: false,
      // delete fact
      deletedT: '',
      dtShow: '',
      // update
      show: false,
      err: '',
      name: '',

      // help
      hShow: false,
      // export
      eShow: false,
      dragging: false,
      rowHeight: 0,
      factRef: false,
      factEvi: false,
      note: false,
      createLink: false,
      linkErr: '',
      addLinkState: false,
      row: 0,
      documentDeleteShow: false,

      //upload doc
      ref: {
        value: '',
        rows: 1,
      },
      highlights: [],
      link: '',
      uploadDocModal: false,
      docs: [],
      rowId: '',
      addExistingDoc: false,

      // timeline
      timelineShow: false,
      toggleTimeline: false,
      timelineButtonText: 'Timeline Tagging',
      timelineText: '',
      timelineTextRef: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteFact = this.deleteFact.bind(this);
    this.updateRows = this.updateRows.bind(this);
    // delete fact
    this.handleFTShow = this.handleFTShow.bind(this);
    this.handleFTClose = this.handleFTClose.bind(this);
    this.handleFTSubmit = this.handleFTSubmit.bind(this);
    // update fact
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // help
    this.handleHClose = this.handleHClose.bind(this);
    this.handleHShow = this.handleHShow.bind(this);

    // change
    this.onChange = this.onChange.bind(this);
    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
    // references
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.divElement = React.createRef();
    this.divElement2 = React.createRef();

    this.goToRef = this.goToRef.bind(this);
    this.linkRef = React.createRef();
    this.addRef = React.createRef();

    //doc
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
    this.saveHighlights = this.saveHighlights.bind(this);
    this.createPDF = this.createPDF.bind(this);
    this.saveLinkToCreatePDF = this.saveLinkToCreatePDF.bind(this);

    // Timeline
    this.handleTimelinetClose = this.handleTimelineClose.bind(this);
    this.handleTimelineShow = this.handleTimelineShow.bind(this);
    // this.createHighlight = this.createHighlight.bind(this);
    this.selectionTimelineHandler = this.selectionTimelineHandler.bind(this);
  }

  isURL = (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  };
  addLink = (data) => {
    console.log('data: ', data);
    console.log('q:', this.state.q);
    const rowIndex = this.state.q.findIndex((row) => {
      console.log('refId', data.refId);
      return row._id == data.refId;
    });
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                fact: this.props.fact,
                path: '/case/witness/fact',
                name: this.props.fact.name,
              },
            ],
          })
          .then((use) => {
            console.log(use);
            this.setState({ docs: use.data.documents });
            let t = this.state.q;
            t[rowIndex].ref.document =
              use.data.documents[use.data.documents.length - 1];
            t[rowIndex].ref.value =
              use.data.documents[use.data.documents.length - 1].name;
            t[rowIndex].reference =
              use.data.documents[use.data.documents.length - 1].name;
            console.log(t);
            axios
              .post(prod() + '/api/cases/update/witness/fact', {
                witness: this.state.witness['_id'],
                case: this.state.case['_id'],
                fact: this.state.fact['_id'],
                q: t,
              })
              .then((use) => {
                // console.log(use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];
                axios
                  .post(prod() + '/api/cases/get/witness/fact', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    fact: this.props.fact['_id'],
                  })
                  .then((use) => {
                    // console.log("ROw DATA: ", use)
                    for (let topi of use.data.facts.fact) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,
                        ref: topi.ref,
                      });
                    }

                    this.setState({
                      q: items,
                      row: 0,
                      addLinkState: false,
                    }, () => this.handleProgress());
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  addExistingDoc = (data) => {
    console.log("data: ", data)
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/fact' &&
          a.fact.toString() == this.props.fact['_id'].toString()
        ) {
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        fact: this.props.fact,
        path: '/case/witness/fact',
        name: this.props.fact.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }
    axios
      .post(prod() + '/api/cases/create/witness/fact/q', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        fact: this.props.fact['_id'],
        reference: data.name,
        referenceLink: data,
        evidence: '',
      })
      .then((use) => {
        console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];

        axios
          .post(prod() + '/api/cases/get/witness/fact', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            fact: this.props.fact['_id'],
          })
          .then((use) => {
            console.log('GET', use);
            for (let topi of use.data.facts.fact) {
              items.push({
                reference: topi.reference,
                evidence: topi.evidence,
                _id: topi._id,
                ref: topi.ref,
              });
            }

            this.setState({
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              fact: use.data.facts,
              reference: '',
              evidence: '',
              delete: '',
              uploadDocModal: false,
              linkErr: '',
              ref: {
                value: '',
                rows: 1,
              },
            }, () => this.handleProgress());
            this.goToRef(data);
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  //adds an existing document on an existing row
  addExistingDocOnRow = (data, rowId) => {
    console.log('1');
    const rowIndex = this.state.q.findIndex((row) => {
      return row._id == rowId;
    });
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/fact' &&
          a.fact.toString() == this.props.fact['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      console.log(data);
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        fact: this.props.fact,
        path: '/case/witness/fact',
        name: this.props.fact.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }

    let t = this.state.q;
    t[rowIndex].ref.document = {
      case: data.case,
      link: data.link,
      name: data.name,
      type: data.type,
      witness: data.witness,
      _id: data._id,
    };
    t[rowIndex].ref.value = data.name;
    t[rowIndex].reference = data.name;

    axios
      .post(prod() + '/api/cases/update/witness/fact', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        fact: this.state.fact['_id'],
        q: t,
      })
      .then((use) => {
        console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];
        axios
          .post(prod() + '/api/cases/get/witness/fact', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            fact: this.props.fact['_id'],
          })
          .then((use) => {
            for (let topi of use.data.facts.fact) {
              items.push({
                reference: topi.reference,
                ref: topi.ref,
                evidence: topi.evidence,
                _id: topi._id,
              });
            }

            this.setState({
              q: items,
              row: 0,
              addLinkState: false,
            }, () => this.handleProgress());
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  // adds the document on a new row
  createLink = (data) => {
    console.log('1');
    console.log(data);
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                fact: this.props.fact,
                path: '/case/witness/fact',
                name: this.props.fact.name,
              },
            ],
          })

          .then((use) => {
            console.log("docs: ", use.data)
            console.log(
              'use',
              use.data.documents[use.data.documents.length - 1]._id
            );
            this.setState({ docs: use.data.documents });
            // add document to topic

            axios
              .post(prod() + '/api/cases/create/witness/fact/q', {
                witness: this.props.witness['_id'],
                case: this.props.case['_id'],
                fact: this.props.fact['_id'],
                reference: data.linkName,
                referenceLink:
                  use.data.documents[use.data.documents.length - 1],

                evidence: '',
              })
              .then((use) => {
                console.log(use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];

                axios
                  .post(prod() + '/api/cases/get/witness/fact', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    fact: this.props.fact['_id'],
                  })
                  .then((use) => {
                    console.log('GET', use);
                    for (let topi of use.data.facts.fact) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,

                        ref: topi.ref,
                      });
                    }

                    this.setState({
                      q: items,
                      witness: this.props.witness,
                      case: this.props.case,
                      fact: use.data.facts,
                      reference: '',
                      evidence: '',
                      delete: '',
                      linkErr: '',

                      ref: {
                        value: '',
                        rows: 1,
                      },
                      uploadDocModal: false,
                    }, () => {
                      this.handleProgress()
                    });
                    this.goToRef(data);
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              linkErr: 'Invalid Input. Please Try Again',
            });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  closeLink = () => {
    this.setState({
      createLink: false,
      linkErr: false,
    });
  };

  //

  handleProgress = () => {
    let p = 0;
    const n = this.state;
    if (n.q && n.q.length > 0) {
      p += 100;
    }
    // console.log(n);
    if (p != this.state.progress) this.setState({ progress: p });
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    console.log('1');
    this.setState({
      dragging: true,
    });
  };
  factRef = () => {
    console.log('1');
    this.setState({
      factRef: !this.state.factRef,
    });
  };
  factEvi = () => {
    console.log('1');
    this.setState({
      factEvi: !this.state.factEvi,
    });
  };

  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      axios
        .post(prod() + '/api/cases/get/witness/fact', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          fact: this.props.fact['_id'],
        })
        .then((use) => {
          console.log('1');
          const items = [];
          for (let topi of use.data.facts.fact) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,
              ref: topi.ref,
            });
          }
          this.setState({
            q: items,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    });
  handleEClose = () => this.setState({ eShow: false });
  onChange(e) {
    this.setState({ [e.target.name]: e.target }, () => {
      // console.log('onchange');
    });
  }
  // help
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  // update fact
  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleAddClose = () => this.setState({ addLinkState: false });
  componentWillReceiveProps(newProps) {
    // console.log("FACT.JS component will receive props")
    if (newProps.fact !== this.props.fact && newProps.fact != '') {
      const items = [];
      axios
        .post(prod() + '/api/cases/get/witness/fact', {
          case: newProps.case['_id'],
          witness: newProps.witness['_id'],
          fact: newProps.fact['_id'],
        })
        .then((use) => {
          for (let topi of use.data.facts.fact) {
            items.push({
              ref: topi.ref,
              evidence: topi.evidence,
              reference: topi.reference,
              _id: topi._id,
            });
          }
          const p = this.handleProgress();
          this.setState(
            {
              q: items,
              witness: newProps.witness,
              case: newProps.case,
              fact: newProps.fact,
              name: newProps.fact.name,
              progress: p,
              ref: {
                value: '',
                rows: 1,
              },
              evidence: '',
              reference: '',
            },
            () => {
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  fact: newProps.fact,
                  path: '/case/witness/fact',
                  name: 'Fact',
                  active: true,
                });
              }
              localStorage.setItem('q', JSON.stringify(this.state));
              this.goToRef(2);
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }
  goToRef(a) {
    let reference = this.textInput3.current._ref;

    if (a == 1) {
      reference = this.textInput2.current._ref;
    } else if (a == 2) {
      reference = this.textInput3.current._ref;
    } else if (a == 3) {
      reference = this.textInput1.current._ref;
    }

    reference.focus();
  }
  handleSubmit = (data) => {
    data.preventDefault();
    axios
      .post(prod() + '/api/cases/update/witness/fact/name', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        fact: this.state.fact['_id'],
        name: this.state.name,
        old: this.state.fact.name,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].facts.length; j++) {
              if (
                use.data.case.witnesses[i].facts[j]['_id'] ==
                this.state.fact['_id']
              ) {
                this.props.getT(use.data.case.witnesses[i]);
                this.props.getFact(use.data.case.witnesses[i].facts[j]);

                this.setState({
                  show: false,
                  name: this.props.fact.name,
                  fact: this.props.fact,
                });
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: 'Fact already exist',
        });
      });
    // }
  };
  // delete fact

  handleFTClose = () => this.setState({ dTShow: false });
  handleFTShow = (e) => this.setState({ dTShow: true, deletedT: e });
  handleFTSubmit = () => {
    axios
      .post(prod() + '/api/cases/delete/witness/fact', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        fact: this.state.fact['_id'],
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            this.props.getT(use.data.case.witnesses[i]);
            this.props.history.push('/case/witness/facts');
          }
        }
      });
  };

  //
  // update fact
  //
  updateRows() {}
  handleChange = (i, event) => {
    console.log('CALLLLLLED HANDLE CHANGE');
    const key = i;
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'reference') {
      this.setState((prev) => ({
        q: prev.q.map((el, index) => {
          return index == key
            ? { ...el, reference: value, ref: { ...el.ref, value } }
            : el;
        }),
      }));
    } else {
      this.setState((prev) => ({
        q: prev.q.map((el, index) =>
          index == key
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      }));
    }
  };

  componentDidUpdate() {
    // console.log('THIS.STATE.Q: ', this.state.q);
    localStorage.setItem('q', JSON.stringify(this.state));
  }

  componentDidUpdate(props) {
    // console.log('THIS.STATE.Q: ', this.state.q);
    if (this.state.fact != props.fact) this.handleProgress();
  }
  handleUpdate = (event) => {
    if (event.target.name === 'reference') {
      this.setState({
        reference: event.target.value,
        ref: { value: event.target.value },
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  componentDidMount() {
    logPageView();

    window.addEventListener('resize', this.updateRows);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.fact == undefined ||
      this.props.fact == null ||
      this.props.witness == undefined ||
      this.props.witness._id == undefined
    ) {
      this.setState({ redirect: true });
    } else {
      const items = [];
      if (this.props.second != true) {
        // console.log("FACT.js component did mount called")
        // console.log("This.props.fact: ", this.props.fact)
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          fact: this.props.fact,
          path: '/case/witness/fact',
          name: 'Fact',
          active: true,
        });
      }
      axios
        .post(prod() + '/api/cases/get/witness/fact', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          fact: this.props.fact['_id'],
        })
        .then((use) => {
          for (let topi of use.data.facts.fact) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,
              ref: topi.ref,
            });
          }
          this.setState(
            {
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              fact: this.props.fact,
              name: this.props.fact.name,
            },
            () => {
              this.handleProgress();
              this.goToRef(2);
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
    console.log('Row HEight', this.state.rowHeight);
  }

  deleteFact = () => {
    if (this.state.deleted.ref && this.state.deleted.ref.document) {
      this.deleteDocs(this.state.q, this.state.fact, this.state.deleted);
    }

    axios
      .post(prod() + '/api/cases/delete/witness/fact/q', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        fact: this.state.fact['_id'],
        d: this.state.deleted['_id'],
      })
      .then((use) => {
        const items = [];
        console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/get/witness/fact', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            fact: this.props.fact['_id'],
          })
          .then((use) => {
            for (let topi of use.data.facts.fact) {
              items.push({
                reference: topi.reference,
                evidence: topi.evidence,
                _id: topi._id,
                ref: topi.ref,
              });
            }
            this.setState({
              q: items,
              witness: this.state.witness,
              case: this.state.case,
              fact: use.data.facts,
              deleted: '',
              dShow: false,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      });
  };

  addItem(item) {
    if (
      (this.state.reference != undefined && this.state.reference != '') ||
      (this.state.evidence != undefined && this.state.evidence != '')
    ) {
      axios
        .post(prod() + '/api/cases/create/witness/fact/q', {
          witness: this.props.witness['_id'],
          case: this.props.case['_id'],
          fact: this.props.fact['_id'],
          reference: this.state.reference,
          evidence: this.state.evidence,
          ref: this.state.ref,
        })
        .then((use) => {
          for (let a of use.data.case.witnesses) {
            if (a._id == this.props.witness['_id']) {
              this.props.updateWitness(use.data.case, a);
            }
          }
          const items = [];

          axios
            .post(prod() + '/api/cases/get/witness/fact', {
              case: this.props.case['_id'],
              witness: this.props.witness['_id'],
              fact: this.props.fact['_id'],
            })
            .then((use) => {
              for (let topi of use.data.facts.fact) {
                items.push({
                  reference: topi.reference,
                  evidence: topi.evidence,
                  _id: topi._id,
                  ref: topi.ref,
                });
              }

              this.setState({
                q: items,
                witness: this.props.witness,
                case: this.props.case,
                fact: use.data.facts,
                reference: '',
                evidence: '',
                delete: '',
                ref: {
                  value: '',
                  rows: 1,
                },
              });
              this.goToRef(item);
            })
            .catch((e) => {
              // alert('Did not save. Please go back or refresh and try again.')
              console.log('ERROR', e);
            });
        });
    }
  }
  saveItem(value) {
    // LOOP FOR REMOVING EMPTY ELEMENT
    console.log('SAVEITEM ', value);
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex((row) => row._id === value._id);
    if (value.ref.value === '' && value.evidence === '') {
      console.log('setState');
      qDupe.splice(index, 1);
      this.setState({ q: qDupe }, () => this.handleProgress());
    } else {
      qDupe[index] = value;
      this.setState({ q: qDupe }, () => this.handleProgress());
      console.log('QDUOE: ', qDupe);
    }

    axios
      .post(prod() + '/api/cases/update/witness/fact', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        fact: this.state.fact['_id'],
        q: qDupe,
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
      })
      .catch((err) => {
        // alert('Did not save. Please go back or refresh and try again.')
      });
  }

  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, deleted: e });

  handleDeleteClose = () =>
    this.setState({ documentDeleteShow: false, deleted: null });

  handleDocumentDeleteShow = (doc) => {
    this.setState({ documentDeleteShow: true, deleted: doc });
  };

  handleDelete = () => {
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex(
      (row) => row._id === this.state.deleted._id
    );
    let row = qDupe.find((row) => {
      return row._id === this.state.deleted._id;
    });
    row = {
      _id: row._id,
      evidence: row.evidence,
      ref: { value: '' },
      reference: '',
    };

    qDupe[index] = row;

    this.deleteDocs(this.state.q, this.state.fact, this.state.deleted);

    this.setState({ q: qDupe, documentDeleteShow: false }, () => {
      this.saveItem(row);
    });
  };

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }

  deleteDocs = (rows, fact, deleted) => {
    let update = false;
    let rowId;
    let count = 0;

    for (let row of rows) {
      if (
        row.ref &&
        row.ref.document &&
        deleted.ref.document &&
        row._id === deleted._id &&
        row.ref.document._id == deleted.ref.document._id
      ) {
        count++;
        update = true;
        rowId = row._id;
      }
    }

    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: [rowId],
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })
        axios.post(prod() + '/api/file/highlights/documents/deleteAll', {
          rowIds: [rowId],
        })
        .then((res) => console.log(res.data))
        .catch(e => console.log(e));
    
        if (count === 1 && update == true) {
          axios
            .post(prod() + '/api/documents/get', {
              document: deleted.ref.document._id,
            })
            .then((a) => {
              const doc = a.data;
    
              if (doc.locations.length === 0) {
                axios //delete highlights for that document
                  .post(prod() + '/api/file/highlights/documents/deleteAll', {
                    rowIds: [rowId],
                  })
                  .then((res) => console.log(res.data))
                  .catch(e => console.log(e));
              } else {
                for (let i = 0; i < doc.locations.length; i++) {
                  let sub = 0;
                  let count = 0;
                  if (
                    doc.locations[i].fact &&
                    doc.locations[i].fact.toString() == fact._id.toString()
                  ) {
                    doc.locations.splice(i, 1);
    
                    axios
                      .post(prod() + '/api/documents/update/loc', {
                        case: this.props.case['_id'],
                        witness: this.props.witness['_id'],
                        document: doc._id,
                        loc: doc.locations,
                      })
                      .then(() => {})
                      .catch((e) => {
                        console.log('Invalid Input, Please Try Again', e);
                      });
                  } else {
                    sub++;
                  }
                }
              }
            })
            .catch((e) => {
              console.log('ERROR', e);
            });
        }
      })
      .catch(e => console.log(e));

  };

  // Syncs the backend with the order change

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ q }) => ({
      q: arrayMove(q, oldIndex, newIndex),
      dragging: false,
    }));

    axios
      .post(prod() + '/api/cases/update/witness/fact', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        fact: this.state.fact['_id'],
        q: this.state.q,
      })
      .then((use) => {})
      .catch((err) => {
        console.err('Fact sort backend sync failed');
        console.log(err);
      });
  };

  DragHandle = sortableHandle(() => <span className='drag'></span>);

  SortableItem = sortableElement(({ value, i }) => {
    let fact = {};

    fact = (
      <FactRow
        value={value}
        index={i}
        saveItem={this.saveItem}
        darkMode={this.props.darkMode}
        q={this.state.q.length}
        handleDocumentDeleteShow={this.handleDocumentDeleteShow}
        fact={this.props.fact}
        handleDShow={this.handleDShow}
        addRef={this.addRef}
        textInput1={this.textInput1}
        textInput2={this.textInput2}
        fact={this.state.fact._id}
        uploadDocModal={this.state.uploadDocModal}
        linkRef={this.linkRef}
        addRef={this.addRef}
        createLink={this.createLink}
        handleCreateClose={this.openUploadDocModal}
        linkErr={this.state.linkErr}
        darkMode={this.props.darkMode}
        witness={this.props.witness}
        case={this.props.case}
        addExistingDoc={this.addExistingDoc}
        addExistingLink={this.addExistingLink}
        addLink={this.addLink}
        addLinkState={this.state.addLinkState}
        handleAddClose={this.handleAddClose}
        showDelete={this.state.documentDeleteShow}
        handleDelete={this.handleDelete}
        handleDeleteClose={this.handleDeleteClose}
        addExistingDocOnRow={this.addExistingDocOnRow}
        docs={this.state.docs}
        toggleTimeline={this.state.toggleTimeline}
        selectionTimelineHandler={this.selectionTimelineHandler}
        createPDF={this.createPDF}
      />
    );

    return fact;
  });

  SortableContainer = sortableContainer(({ children }) => {
    return children;
  });

  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open });
  }

  // add highlights after the row is saved
  //   after refId, and document Id are present, add the highlights to the db
  componentDidUpdate(prevProps, prevState) {
    const url = prod() + '/api/file/highlights/add-highlights';
    const { highlights, link } = this.state;

    if (prevState.q !== this.state.q) {
      if (this.state.rowId || this.state.link || this.state.highlights) {
        if (this.state.rowId) {
          axios
            .post(url, { refId: this.state.rowId, highlights, link })
            .then((res) => {
              this.setState({ highlights: [], link: '', rowId: '' });
            });
        } else if (this.state.addExistingDoc) {
          const refId = this.state.q[this.state.q.length - 1]._id;
          this.createPDF(refId, link);
        } else if (this.state.q.length > 0 && this.state.link !== '') {
          const refId = this.state.q[this.state.q.length - 1]._id;
          axios.post(url, { refId, highlights, link }).then((res) => {
            this.setState({ highlights: [], link: '' });
          });
        }
      }
    }
  }
  saveHighlights(highlights, link) {
    this.setState({ highlights, link });
  }

  // timeline tagging
  toggleTimelineHighlighting = (e) => {
    // console.log('e', e.target.value);
    // e.target.value = false;
    this.setState({
      toggleTimeline: !this.state.toggleTimeline,
      timelineButtonText: this.state.toggleTimeline
        ? 'Timeline Tagging' //disabled
        : 'Timeline Tagging', //enabled
    });
  };

  handleTimelineShow = () =>
    this.setState({
      timelineShow: true,
      // highlightShow: false,
      highlightedText: '',
      highlightedTextRef: '',
    });
  handleTimelineClose = () => {
    this.setState({
      timelineShow: false,
      highlightedText: '',
      highlightedTextRef: '',
    });
  };

  selectionTimelineHandler(selection, row) {
    //do something with selection
    // console.log('selection handler');
    // console.log(selection);
    // console.log('selection reference', row);
    // console.log('selection timeline handler working');
    // console.log("timeline show modal: ", this.state.timelineShow)
    this.setState({
      timelineShow: true,
      highlightedText: selection.text,
      highlightedTextRef: row,
    });
  }

  //create pdf table with PDF schema when a document is added to a row
  createPDF(rowId, link) {
    axios
      .post(prod() + '/api/file/highlights/create', { refId: rowId, link })
      .then((res) => {
        this.setState({ rowId: '', link: '' });
      })
      .catch(e => console.log(e))
  }

  saveLinkToCreatePDF(link) {
    this.setState({ link, addExistingDoc: true });
  }

  render() {
    console.log("ROWS: ", this.state.q)
    console.log("docs: ", this.state.docs)
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash'>
        {' '}
        <Container fluid className='scope'>
          <Row id='dash-add'>
            <Col md={6}>
              <h1 className='topic-title w-name'>
                Fact: <span>{this.state.fact.name} </span>
              </h1>
              <ProgressBar now={Math.round(this.state.progress)} />
            </Col>
            <Col md={6}>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <div className='add-btn add-btn-p-top topic-btn'>
                <ButtonToolbar>
                  <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                      <Tooltip
                        id={
                          this.props.darkMode
                            ? 'dark-tooltip-add'
                            : 'tooltip-add'
                        }
                      >
                        Highlight text in evidence column to create timeline
                        events of the case.
                      </Tooltip>
                    }
                  >
                    <Form className='highlight-switch'>
                      <Form.Check
                        type='switch'
                        id='timeline-switch'
                        label={this.state.timelineButtonText}
                        onChange={(e) => this.toggleTimelineHighlighting(e)}
                        value={this.state.toggleTimeline}
                        defaultChecked={this.state.toggleTimeline}
                        defaultValue={this.state.toggleTimeline}
                        ref={this.state.toggleTimeline}
                      />
                    </Form>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>

          <div className='cases'>
            <Form id='fact-form'>
              <div ref={this.divElement2} className='d-none d-md-block'>
                <Fade>
                  <Row className='titles'>
                    <Col md={4}>
                      <Row>
                        <div className='hov-container'>
                          <h3>References</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.factRef}
                          ></img>
                        </div>
                      </Row>
                    </Col>
                    <Col md={7}>
                      <Row>
                        <div className='hov-container'>
                          <h3>Evidence</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.factEvi}
                          ></img>
                        </div>
                      </Row>
                    </Col>

                    <Col md={1}></Col>
                  </Row>
                </Fade>
              </div>
              <FactModal
                factEvi={this.state.factEvi}
                hideFactEvi={this.factEvi}
                factRef={this.state.factRef}
                hideFactRef={this.factRef}
                handleFTSubmit={this.handleFTSubmit}
                handleFTClose={this.handleFTClose}
                dTShow={this.state.dTShow}
                deleteFact={this.deleteFact}
                handleDClose={this.handleDClose}
                dShow={this.state.dShow}
                darkMode={this.props.darkMode}
                handleSubmit={this.handleSubmit}
                handleClose={this.handleClose}
                user={this.state.user}
                show={this.state.show}
                handleHClose={this.handleHClose}
                hShow={this.state.hShow}
                handleEClose={this.handleEClose}
                fact={this.state.fact}
                q={this.state.q}
                eShow={this.state.eShow}
              />
              <LinkDocument
                uploadDocModal={this.state.uploadDocModal}
                linkRef={this.linkRef}
                addRef={this.addRef}
                createLink={this.createLink}
                openUploadDocModal={this.openUploadDocModal}
                linkErr={this.state.linkErr}
                darkMode={this.props.darkMode}
                witness={this.props.witness}
                case={this.props.case}
                addExistingDoc={this.addExistingDoc}
                addLinkState={this.state.addLinkState}
                handleAddClose={this.handleAddClose}
                showDelete={this.state.documentDeleteShow}
                handleDelete={this.handleDelete}
                handleDeleteClose={this.handleDeleteClose}
                saveHighlights={this.saveHighlights}
                docs={this.state.docs}
                saveLinkToCreatePDF={this.saveLinkToCreatePDF}
              />
              {this.state.timelineShow && (
                <SummaryTimelineModal
                  darkMode={this.props.darkMode}
                  user={this.state.user}
                  show={this.state.timelineShow}
                  handleTimelineClose={this.handleTimelineClose}
                  case={this.props.case}
                  witness={this.props.witness}
                  highlightedText={this.state.highlightedText}
                  highlightedTextRef={this.state.highlightedTextRef}
                />
              )}

              <Fade>
                <div
                  className={this.state.dragging ? 'cases-dragging' : ''}
                ></div>
                <div>
                  <this.SortableContainer
                    onSortEnd={this.onSortEnd}
                    onSortStart={this.onDragStart}
                    useDragHandle
                  >
                    <div>
                      {this.state.q.map((value, index) => (
                        <this.SortableItem
                          key={`fact-dnd--${index}`}
                          index={index}
                          i={index}
                          value={value}
                        />
                      ))}
                    </div>
                  </this.SortableContainer>
                  <div
                    className={
                      this.props.darkMode == true
                        ? ' d-block d-md-none underline-t dark'
                        : ' d-block d-md-none underline-t dark'
                    }
                  >
                    <Row className='unsaved-input'>
                      <h2>Create New</h2>
                      <h3 className='mobile-title'>Reference</h3>
                      <Col md={4} className='ref-mobile'>
                        <Form.Group controlId='formBasicEmail'>
                          {/* <Form.Control */}
                          <TextareaAutosize
                            // as='textarea'
                            placeholder='Enter Reference'
                            onBlur={() => this.addItem(1)}
                            // rows={this.state.reference.rows}
                            value={this.state.reference}
                            onChange={this.handleUpdate}
                            name='reference'
                          />
                        </Form.Group>
                      </Col>
                      <h3 className='mobile-title'>Evidence</h3>
                      <Col md={7} className='dic-mobile'>
                        <Form.Group controlId='formBasicEmail'>
                          {/* <Form.Control */}
                          <TextareaAutosize
                            // as='textarea'
                            placeholder='Enter Evidence Here'
                            onBlur={() => this.addItem(2)}
                            rows={this.state.evidence.rows}
                            // value={this.state.evidence}

                            onChange={this.handleUpdate}
                            name='evidence'
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  {/* Laptop screen text area remove */}
                  <NewRow
                    handleUpdate={this.handleUpdate}
                    textInput3={this.textInput3}
                    col1Value={this.state.reference}
                    col1Name='reference'
                    addItem={this.addItem}
                    openUploadDocModal={this.openUploadDocModal}
                    col2Placeholder={'Enter Evidence Here'}
                    col2Value={this.state.evidence}
                    col2Name='evidence'
                  />
                </div>
              </Fade>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Fact);
