import React, { Component } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import DocumentModal from './DocumentModal';
// import UploadDocumentModal from '../Shared/UploadDocumentModal';
// import UploadDocumentButton from '../Shared/UploadDocumentButton';
import LinkDocument from '../../Shared/LinkDocument';
import UploadDocument from './UploadDocument';

export default class Documents extends Component {
  constructor() {
    super();
    this.state = {
      createLink: false,
      linkErr: '',

      showDelete: false,
      document: {},
      showUpdate: false,
      updateErr: '',

      createDocument: false,
      documentErr: '',

      uploadDocModal: false,
    };
    this.linkRef = React.createRef();
    this.docRef = React.createRef();

    //doc
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
  }

  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open }, () => {
      this.docRef.current.focus();
    });
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let listName = source.droppableId;
      const newList = this.reorder(
        this.props.documents,
        source.index,
        destination.index,
        source,
        destination
      );
    }
  };

  reorder = (list, startIndex, endIndex, source, destination) => {
    const result = Array.from(list);

    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    axios
      .post(prod() + '/api/documents/update/index', {
        caseId: this.props.case['_id'],
        witnessId: this.props.witness['_id'],
        oldIndex: this.props.documents.length - startIndex - 1,
        newIndex: this.props.documents.length - endIndex - 1,
        id: removed._id,
      })
      .then((response) => {
        console.log('Reorder response');
        console.log(response.data.documents);
      })
      .catch((error) => {
        console.log(error);
      });
    for (let i = 0; i < result.length; i++) {
      if (
        result[i].orderIndex != endIndex &&
        result[i].orderIndex >= this.props.documents.length - startIndex - 1
      ) {
        result[i].orderIndex--;
      } else if (
        result[i].orderIndex != endIndex &&
        result[i].orderIndex >= this.props.documents.length - endIndex - 1
      ) {
        result[i].orderIndex++;
      }
    }
    this.props.handleDocuments(result);
  };

  // construction above
  isURL = (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  };
  handleSubmit = (data) => {
    // create
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
          })
          .then((use) => {
            console.log('use', use);
            console.log("documents: ", use.data)
            this.setState(
              {
                createLink: false,

                linkErr: '',
              },
              this.props.handleDocuments(use.data.documents)
            );
          })
          .catch((e) => {
            console.log(e);
            this.setState({
              linkErr: 'Invalid Input. Please Try Again',
            });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  handleCreateClose = () => {
    this.setState({
      createLink: false,
      linkErr: false,

      createDocument: false,
      documentErr: false,
    });
  };
  handleDeleteClose = () => {
    this.setState({
      showDelete: false,
      document: {},
      updateErr: '',
    });
  };
  handleDeleteOpen = (doc) => {
    console.log("document: ", doc)
    this.setState({
      showDelete: true,
      document: doc,
    });
  };

  handleDelete = () => {
    console.log("documents: ", this.props.documents)
    //GC storage bucket names are different for prod and development
    const link = this.state.document.link;
    const indexOfTerm = link.indexOf('storage.googleapis.com');
    const slicedLink = link.slice(indexOfTerm);
    const gcFileName = slicedLink.slice(23)
    console.log("gc filename: ", gcFileName);

    axios
      .post(prod() + '/api/documents/delete', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
        document: this.state.document['_id'],
        index: this.state.document['orderIndex'],
      })
      .then((use) => {
        console.log(use);
        if (this.state.document.type === 'file') {
          axios.post(prod() +'/api/cases/update/witness/deleteFile', { filename: gcFileName })
        }

        this.setState(
          {
            showDelete: false,

            document: {},
            updateErr: '',
          });
        this.props.handleDocuments(use.data.documents);

      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };
  handleUpdateOpen = (doc) => {
    this.setState({
      showUpdate: true,
      document: doc,
    });
  };
  handleUpdateClose = () => {
    this.setState({
      showUpdate: false,
      document: {},
    });
  };
  handleUpdate = (data) => {
    if (this.isURL(data.linkLocation) == true) {
      axios
        .post(prod() + '/api/documents/update', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: this.state.document['_id'],
          name: data.linkName,
          link: data.linkLocation,
        })
        .then((use) => {
          this.setState(
            {
              showUpdate: false,
              document: {},
            },
            () => {
              this.props.handleDocuments(use.data.documents);
            }
          );
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };

  render() {
    console.log("DOCUMENTS: ", this.props.documents);
    const documents = [];
    const docs = this.props.documents ? this.props.documents : [];

    for (let i = 0; i < docs.length; i++) {
      const locations = [];

      if (docs[i].locations && docs[i].locations.length > 0) {
        for (let j = 0; j < docs[i].locations.length; j++) {
          let color = '';
          // console.log("LOCATION: ", docs[i].locations[j]);
          if (docs[i].locations[j].path == '/case/witness/topic') {
            color = 'focus_topic';
          } else if (docs[i].locations[j].path == '/case/witness/summary') {
            color = 'focus_summary';
          } else if (docs[i].locations[j].path == '/case/witness/fact') {
            color = 'focus_fact';
          }
          docs[i].locations[j].witnessId = docs[i].locations[j].witness;
          docs[i].locations[j].caseId = docs[i].locations[j].case;
          if (docs[i].locations[j].topic) {
            docs[i].locations[j].topicId = docs[i].locations[j].topic;
          } else if (docs[i].locations[j].fact) {
            docs[i].locations[j].topicId = docs[i].locations[j].fact;
          } else if (docs[i].locations[j].statement) {
            docs[i].locations[j].topicId = docs[i].locations[j].statement;
          }

          // if (j == docs[i].locations.length - 1) {
          locations.push(
            <span
            onClick={(e) => {
              e.stopPropagation();
              let data = {};
              console.log("DOC INFO:", docs[i])
              data.path = docs[i].locations[j].path;
              data.witnessId = this.props.witness;
              data.caseId = this.props.case;
              data.name = docs[i].locations[j].name;
              const topic = { _id: docs[i].locations[j].topicId };
              // console.log("DATA: ", data)
              // console.log("TOPIC: ", topic)
              if (data.path == '/case/witness/topic') {
                axios
                  .post(prod() + '/api/cases/get/witness/topic', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    topic: topic['_id'],
                  })
                  .then((use) => {
                    data.topicId = use.data.topics;
                    this.props.getDoc(data);
                  })
                  .catch((err) => {
                    console.log('MSG in err', err);
                  });
              } else if (data.path == '/case/witness/summary') {
                axios
                  .post(prod() + '/api/cases/get/witness/statement', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    statement: topic['_id'],
                  })
                  .then((use) => {
                    // console.log("response", use.data)
                    data.topicId = use.data.statements;
                    // localStorage.removeItem('q')
                    this.props.getDoc(data);
                  })
                  .catch((err) => {
                    console.log('MSG in err', err);
                  });
              } else {
                console.log("Documents axios call")
                // data.topicId = docs[i].locations[j]
                axios
                  .post(prod() + '/api/cases/get/witness/fact', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    fact: topic['_id'],
                  })
                  .then((use) => {
                    // console.log("response", use.data)
                    data.topicId = use.data.facts;
                    this.props.getDoc(data);
                  })
                  .catch((err) => {
                    console.log('MSG in err', err);
                  });
              }
            }}
            className={color}
            >
              {docs[i].locations[j].name}
              {j !== docs[i].locations.length - 1 && <span>, </span>}
            </span>
          );
          // } else {
          //   locations.push(
          //     <>
          //       <a
          //         onClick={(e) => {
          //           e.stopPropagation();
          //           console.log(docs[i].locations[j]);
          //           // const data = [];
          //           // data.path = '/case/witness/topic';
          //           // data.caseId = this.props.case;
          //           // data.witnessId = this.props.witness;
          //           // data.topicId = this.props.witness.topics[i];

          //           // this.props.getDoc(docs[i].locations[j])
          //         }}
          //         className={color}
          //       >
          //         {docs[i].locations[j].name}
          //       </a>
          //       <span>, </span>
          //     </>
          //   );
          // }
        }
      }

      documents.push(
        <Draggable
          isDragDisabled={docs.length === 0}
          key={'documents' + i}
          draggableId={'documents' + i}
          index={i}
          tabIndex={-1}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              tabIndex={-1}
              className='f9 unfocus'
              onMouseDown={(e) => e.currentTarget.focus()}
            >
              <div
                className={
                  this.props.darkMode === true
                    ? 'border-bx dark-border-bx document-box'
                    : 'border-bx document-box'
                }
              >
                <div className='card-type'>
                  <span>{docs[i].type}</span>
                </div>
                {locations.length == 0 && (
                  <div className='card-close'>
                    {/* {docs[i] && docs[i].important ? (
                    <img
                      className='logo-title document-u'
                      src={require('https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg')}
                      onClick={(e) =>
                        this.props.handleImportantRowCard('docs', i)
                      }
                    />
                  ) : (
                    <img
                      className='logo-title document-u'
                      src={require('https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg')}
                      onClick={(e) =>
                        this.props.handleImportantRowCard('docs', i)
                      }
                    />
                  )} */}
                    {docs[i].type !== 'file' && (
                      <img
                        onClick={(e) => this.handleUpdateOpen(docs[i])}
                        className='logo-title document-u'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                      ></img>
                    )}
                    <img
                      onClick={(e) => this.handleDeleteOpen(docs[i])}
                      className='logo-title document-x'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                    ></img>
                  </div>
                )}

                <Form.Group controlId={i} className='focus_doc'>
                  <a target='_blank' href={docs[i].link} className='link_doc'>
                    <Form.Control
                      as='textarea'
                      rows={1}
                      value={docs[i].name}
                      readOnly
                      className='link_doc'
                    />
                  </a>
                </Form.Group>
                <div className='card-placement'>{locations}</div>
              </div>
            </div>
          )}
        </Draggable>
      );
    }
    return (
      <>
        <Form.Group controlId='s'>
          {docs.length > 0 ? (
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId={'documents'}>
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} id='focus-document-list'>
                    {documents}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            <TextareaAutosize
              // as='textarea'
              placeholder='Upload Or Link A Document'
              // rows={docs.rows}
              readOnly
              name='documents'
              className='document-text'
            />
          )}
          <div className='suggestion'>
            <div></div>
            <div className='s-items'>
              <Button
                name='link'
                className='s-item-documents'
                onClick={() =>
                  this.setState({ createLink: true }, () => {
                    this.linkRef.current.focus();
                  })
                }
              >
                Create Link
              </Button>
              <Button
                onClick={() => this.openUploadDocModal(true)}
                className='s-item-documents'
              >
                Upload Document
              </Button>
            </div>
            <div></div>
          </div>
        </Form.Group>
        <DocumentModal
          createLink={this.state.createLink}
          linkRef={this.linkRef}
          handleSubmit={this.handleSubmit}
          handleCreateClose={this.handleCreateClose}
          linkErr={this.state.linkErr}
          darkMode={this.props.darkMode}
          showDelete={this.state.showDelete}
          handleDelete={this.handleDelete}
          handleDeleteClose={this.handleDeleteClose}
          showUpdate={this.state.showUpdate}
          handleUpdate={this.handleUpdate}
          handleUpdateClose={this.handleUpdateClose}
          updateErr={this.state.updateErr}
          linkName={this.state.document.name}
          linkLocation={this.state.document.link}
        />
        <UploadDocument
          uploadDocModal={this.state.uploadDocModal}
          linkRef={this.linkRef}
          docRef={this.docRef}
          addRef={this.addRef}
          openUploadDocModal={this.openUploadDocModal}
          linkErr={this.state.linkErr}
          darkMode={this.props.darkMode}
          witness={this.props.witness}
          case={this.props.case}
          addExistingDoc={this.addExistingDoc}
          addExistingLink={this.addExistingLink}
          addLinkState={this.state.addLinkState}
          handleAddClose={this.handleAddClose}
          showDelete={this.state.documentDeleteShow}
          handleDelete={this.handleDelete}
          handleDeleteClose={this.handleDeleteClose}
          handleSubmit={this.handleSubmit}
        />
        {/* <UploadDocumentModal createDocument={this.state.createDocument} /> */}
      </>
    );
  }
}
