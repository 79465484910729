import React, { Component } from 'react';
import {
  Button,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ModalBody,
} from 'react-bootstrap';
import { logoutUser } from '../../../actions/authActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Search from '../Search';
// info
import { Link, withRouter } from 'react-router-dom';
class Icons extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      showSearch: false,
      goToAudio: false,
    };
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSearchClose = this.handleSearchClose.bind(this);
  }
  handleClose = () => this.setState({ show: false });
  handleSearchClose = (e) => this.setState({ showSearch: e });
  handleShow = () => this.setState({ show: true });
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.props.loadOut();
  }
  componentDidMount() {
    console.log('Navigation/Dashboard Dark Mode:', this.props.darkMode);
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.show}
          onHide={this.handleClose}
          id='module-search'
        >
          <ModalBody>
            <Search
              handleSearchClose={(e) => {
                this.handleClose(e);
              }}
              user={this.props.user}
              changeLocation={this.props.changeLocation}
              cases={this.props.cases}
              getWitness={this.props.getWitness}
              darkMode={this.props.darkMode}
              getDoc={this.props.getDoc}
            />
          </ModalBody>
        </Modal>

        <div id='comp-auth-nav-expanded' className='comp-auth-nav'>
          <div id='com-app-icon-group-expanded' className='com-app-icon-group '>
            <div className=' com-app-icon-proof' onClick={this.props.goHome}>
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Home
                    </Tooltip>
                  }
                >
                  <Button className='side-bt side-bt-t'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642374/Aleri/tod_lzzdaf.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
            <div
              className='com-app-icon'
              id='com-app-icon-first'
              onClick={this.handleShow}
            >
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Search
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/folder_1_ss37ea.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
            {/* <div
              className='com-app-icon'
              id='com-app-icon-first'
              onClick={this.props.goAudioTranscription}
            >
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Audio Transcription
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src={'/images/audio-waves.png'}
                    ></img>{' '}
                    {/* <GiSoundWaves className="logo"/> */}
                  {/* </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div> */}
            {/* <div className='com-app-icon'>

              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Information & Strategies (Coming Soon)
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    {' '}
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plan_c0wem4.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>

            <div className='com-app-icon'>
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Activity (Comming Soon)
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bell_dm5hld.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div> */}
            <div
              className='com-app-icon second-last-icon'
              onClick={this.props.goSettings}
            >
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Settings
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/repairing-service_nvrcnx.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
            <ButtonToolbar className='com-app-icon  last-icon'>
              <OverlayTrigger
                key='right'
                placement='right'
                overlay={
                  <Tooltip
                    id={
                      this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                    }
                  >
                    Logout
                  </Tooltip>
                }
              >
                <Button
                  className='side-bt'
                  onClick={this.onLogoutClick.bind(this)}
                >
                  <img
                    className='logo'
                    src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/logout_1_zdzmqm.svg'
                  ></img>{' '}
                </Button>
              </OverlayTrigger>
            </ButtonToolbar>
          </div>
        </div>

        <div className='mobile-auth-nav'>
          <div className='app-icon-group'>
            <div
              id='com-app-icon-first'
              className='com-app-icon com-p'
              onClick={this.props.goHome}
            >
              {' '}
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Files
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/case_gnkgtd.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>

            {/* <div className='com-app-icon' onClick={this.props.goInfo}>
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Information & Strategies
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    {' '}
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plan_c0wem4.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div> */}

            {/* <div className='com-app-icon com-p' onClick={this.props.goActivity}>
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Activity
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bell_dm5hld.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div> */}

            <div className='com-app-icon com-p' onClick={this.props.goSettings}>
              {' '}
              <ButtonToolbar>
                <OverlayTrigger
                  key='right'
                  placement='right'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Settings
                    </Tooltip>
                  }
                >
                  <Button className='side-bt'>
                    <img
                      className='logo'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/repairing-service_nvrcnx.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          </div>
        </div>
      </>
    );
  }
}

Icons.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Icons);
