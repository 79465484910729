import React from "react";

import { Button } from "react-bootstrap";

// pdf
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Docx
import { saveAs } from "file-saver";
import { Document as DocxDocument, Packer, Paragraph } from "docx";

import { getFacts } from "../../../Shared/DocxExport";

const Pdf = (props) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: "column",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
    },
    subSection: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1 0 50%",
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: "left",
      textTransform: "uppercase",
    },
    titleSM: {
      letterSpacing: 1,
      fontSize: 7,
      textAlign: "left",
      textTransform: "uppercase",
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: "right",
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: "auto",
      marginRight: "auto",
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: "right",
      textTransform: "uppercase",
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColName: {
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColNameL: {
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
    },
    tableColValue: {
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      textOverflow: "ellipsis",
    },
    tableColValueL: {
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: "ellipsis",
    },
    tableCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: 300,
      width: "100%",
      textOverflow: "ellipsis",
      padding: 5,
    },
  });
  const arr = [];

  for (let item of props.facts) {
    const e = [];
    for (let i = 0; i < item.fact.length; i++) {
      const a = (
        <View style={styles.tableRow}>
          <View style={styles.tableColValue}>
            <Text style={styles.tableCell}>{item.fact[i].reference}</Text>
          </View>

          <View style={styles.tableColValueL}>
            <Text style={styles.tableCell}>{item.fact[i].evidence}</Text>
          </View>
        </View>
      );

      e.push(a);
    }
    arr.push(
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.subSection}>
            <Text style={styles.titleSM}>Statement Facts By Topic</Text>
            <Text style={styles.title}>{item.name}</Text>
          </View>
          <View style={styles.subSection}>
            <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
            <Text style={styles.date}>{today}</Text>
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableColName}>
                <Text style={styles.tableCell}>References</Text>
              </View>

              <View style={styles.tableColNameL}>
                <Text style={styles.tableCell}>Evidence</Text>
              </View>
            </View>
            {e}
          </View>
        </View>
      </Page>
    );
  }
  const pdf = (
    <PDFDownloadLink
      className="bob-link"
      target="_blank"
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.titleSM}>Cross Examination Questions</Text>
                <Text style={styles.title}>{props.witness.name}</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
          </Page>
          {arr}
        </Document>
      }
      fileName="facts.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Loading PDF To Export
          </Button>
        ) : (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Export To PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return <>{pdf}</>;
};

const Word = (props) => {
  const generate = () => {
    const docArr = getFacts(props.facts);

    const doc = new DocxDocument({
      sections: docArr,
    })

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${props.witness.name}-facts.docx`);
    });
  };

  return (
    <Button
      className="btn action  btn-b btn btn-primary btn-block"
      onClick={generate}
    >
      Export To Word(docx)
    </Button>
  );
};

const FactsExport = (props) => {
  return (
    <>
      <Pdf {...props} />
      <Word {...props} />
    </>
  );
};
export default FactsExport;
