import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from 'react-bootstrap';
import WitExport from './WitExport';
export default class WitModules extends Component {
  constructor() {
    super();
    this.state = {
      dShow: false,
      show: false,
      err: '',
    };
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = () => this.setState({ dShow: true });
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleEClose = () => this.setState({ eShow: false });
  handleEShow = () => this.setState({ eShow: true });
  handleHShow = () => {
    this.setState({ hShow: true });
  };
  handleHClose = () => {
    this.setState({ hShow: false });
  };
  render() {
    return (
      <>
        <Modal show={this.props.eShow} onHide={this.props.handleEClose}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Focus Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* <WitExport witness={this.props.witness} case={this.props.case} /> */}
              {this.props.witness}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal show={this.props.hShow} onHide={this.props.handleHClose}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                hShow: false,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>ALERI CROSS-EXAMINATION SYSTEM</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The Aleri cross-examination system is simple system of four
                  steps that allows you to prepare for your cross-examination of
                  a witness. The four steps are :
                </p>
                <ol>
                  <li>
                    <h4>FOCUS SHEET</h4>
                    <p>
                      The focus sheet is a general road map of how the witness
                      fits into your theory of the case, and how you are going
                      to prepare for the case.
                    </p>
                  </li>
                  <li>
                    <h4>CROSS-EXAMINATION CHART</h4>
                    <p>
                      The cross-examination chart allows you to immediate
                      compare all of witness statements, to each other and to
                      the statements of other witnesses. The inconsistencies
                      that are noted can then assist in the preparation of
                      questions, particularly for the impeachment based on
                      inconsistency.
                    </p>
                  </li>
                  <li>
                    <h4>CROSS-EXAMINAION CHECKLIST</h4>
                    <p>
                      The cross-examination checklist allows you canvas the
                      significant areas available to challenge a witness
                      including mental and sensory deficiency, lack of
                      knowledge, bias, prior statements, contradictions,
                      plausibility, and criminal records or other misconduct. It
                      also provided a series of cross-examination techniques
                      that you can consider applying as you are preparing your
                      questions.
                    </p>
                  </li>
                  <li>
                    <h4>CROSS-EXAMINATION QUESTIONS</h4>
                    <p>
                      The final step is to break the cross-examination into a
                      series of individual topics that consist of a page or less
                      of leading questions. The questions will be a final step
                      that follows directly from the work you have done in
                      relation to the first three steps. The Topics will likely
                      be inspired in part by the information revealed in the
                      chart and the checklist, while the detailed drafting of
                      the questions will be assisted by the use of the
                      cross-examination techniques.
                    </p>
                  </li>
                </ol>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.question}
          onHide={this.props.setQuestion}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                question: !this.props.question,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Questions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The Questions Folder holds multiple chapters which are
                  separated by topic. Each chapter should have about 30
                  questions / be 1 page long.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.preparation}
          onHide={this.props.setPreparation}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                preparation: !this.props.preparation,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Preparation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  A chart that sets out the internal and external
                  inconsistencies relating to the witness.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.fact}
          onHide={this.props.setFact}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                fact: !this.props.fact,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Facts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  A document that takes all the references in different
                  statement summaries, for one topic of evidence, and lists them
                  so you can see all the evidence on one topic.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.checklist}
          onHide={this.props.setChecklist}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                checklist: !this.props.checklist,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The checklist is a general preparation document that give you
                  an idea of the possible weaknesses in the evidence of the
                  witness.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.summary}
          onHide={this.props.setSummary}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                summary: !this.props.summary,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Summaries</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  A document in which you summarize a witness statement to
                  provide a shorter version with only the key points that you
                  need.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.focus}
          onHide={this.props.setFocus}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                focus: !this.props.focus,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Focus of Cross Examination</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  A summary relating to the witness that allows you to establish
                  the focus of your cross-examination and how generally you will
                  deal with the witness.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.timeline}
          onHide={this.props.setTimeline}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.setState({
                timeline: !this.props.timeline,
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Timeline of Cross Examination</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  A timeline relating to the witness that allows you to map
                  the details and time of events.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}
