import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Route } from 'react-router-dom';
import { loginUser } from '../../../actions/authActions';
import {
  Form,
  FormControl,
  Button,
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../prod';
// import ReactGA from 'react-ga';
// export const initGA = () => {
// ReactGA.initialize('UA-160714583-1');
// };
export const logPageView = () => {
  window.analytics.page('Login');
};
export class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      password: '',
      firm: '',
      firmSubmitted: false,
      firmError: '',
      user: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitFirm = this.onSubmitFirm.bind(this);
    this.resetFirm = this.resetFirm.bind(this);
    this.editRef = React.createRef();
    this.edit2Ref = React.createRef();
  }
  resetFirm = () => {
    this.setState({ firmSubmitted: false, firm: '', firmError: '' }, () =>
      this.edit2Ref.current.focus()
    );
  };
  componentDidMount() {
    // initGA();
    logPageView();
    window.scrollTo(0, 0);

    if (
      this.props.auth.isAuthenticated &&
      window.location.pathname == '/community/login'
    ) {
      console.log('called inside mount');
      this.props.bundle(this.props.currentUser);
    } else if (this.props.auth.isAuthenticated) {
      this.props.history
        ? this.props.history.push('/')
        : (window.location.href = '/');
    }
  }
  async componentDidUpdate() {
    if (
      this.props.auth.isAuthenticated &&
      window.location.pathname == '/community/login'
    ) {
      if (
        localStorage.jwtToken !== 'undefined' &&
        localStorage.jwtToken !== undefined &&
        localStorage.jwtToken !== ''
      ) {
        try {
          const res = await axios.get(prod() + '/api/users/current');

          this.props.bundle({
            email: res.data.email,
            id: res.data._id,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
          });
        } catch (error) {
          console.log('Error while Dark Mode', error);
        }

        // check if dark and set if true
      }
    } else if (this.props.auth.isAuthenticated) {
      this.props.history
        ? this.props.history.push('/')
        : (window.location.href = '/');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.load();
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmitFirm(e) {
    e.preventDefault();

    if (this.state.firm == '') {
      this.setState({ firmError: 'This is not a valid firm name, try again.' });
    } else {
      const name = this.state.firm.trim().toLowerCase();
      axios
        .post(prod() + '/api/firms/check', {
          name: name,
        })
        .then((r) => {
          console.log('r', r);
          if (r.data.err == false) {
            this.setState({
              firmError: 'Firm does not exist!',
            });
          } else {
            this.setState(
              {
                firmSubmitted: true,
                firm: this.state.firm,
                fullFirm: { _id: r.data.firmId, name: r.data.firmName },
              },
              () => this.editRef.current.focus()
            );
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const userData = {
      email: this.state.email.trim(),
      password: this.state.password.trim(),
      firm: this.state.firm.trim(),
    };
    console.log('Login.js Props: ', this.props);
    if (
      userData.email !== '' &&
      userData.password !== ''
      // &&  userData.firm !== ""
    ) {
      let a = this.props.loginUser(userData);

      // if(userData)

      if (this.props.errors.length !== 0) {
        setTimeout(() => {
          this.setState(
            {
              user: 'We could not find a user with this information.',
            },
            () => {
              console.log('Called alert State', this.state.user);
            }
          );
        }, 1000);
      }
    } else {
      this.setState({
        user: 'Missing credentials.',
      });
    }
  }

  render() {
    const { errors, firm, firmSubmitted, user } = this.state;
    console.log(window.location.href);

    const firmCode = (
      <div className='Login-Container'>
        <Row>
          <Col md={5}>
            <div className='registrationForm'>
              <h1>
                <em onClick={this.resetFirm} className='firm-name'>
                  {firm}
                </em>
              </h1>
              <h5>Welcome back to Aleri</h5>
              <p>Log in to your account and start creating</p>
              <Form onSubmit={this.onSubmit} id='login-user'>
                {user && (
                  <div className='alert alert-danger invalid-feedback invalid-feedback-c'>
                    {user}
                  </div>
                )}
                <Form.Group controlId='formLoginEmail'>
                  <FormControl
                    type='email'
                    placeholder='Email'
                    value={this.state.email}
                    onChange={this.onChange}
                    name='email'
                    ref={this.editRef}
                  />
                </Form.Group>
                <Form.Group controlId='password'>
                  <FormControl
                    type='password'
                    placeholder='Password'
                    value={this.state.password}
                    onChange={this.onChange}
                    name='password'
                  />
                </Form.Group>
                <Link
                  to={{
                    pathname: '/forgotPassword',
                    firm: this.state.fullFirm,
                  }}
                >
                  Forgot Username or Password?
                </Link>{' '}
                <br />
                <Link to='/registration'>
                  Don't have an Account with this Firm?
                </Link>
                <Button
                  className='btn action btn-dark'
                  block
                  type='submit'
                  variant='primary'
                >
                  Sign in
                </Button>
              </Form>
            </div>
          </Col>
          <Col md={7} className='Login-IMG d-none d-md-block'></Col>
        </Row>
      </div>
    );
    const noFirm = (
      <div className='Login-Container'>
        <Row>
          <Col md={5}>
            <div className='registrationForm'>
              <h1>Aleri</h1>
              <h5>Welcome back to Aleri</h5>
              <p>Log in to your account and start creating</p>
              <Form onSubmit={this.onSubmitFirm} id='login-firm'>
                {this.state.firmError && (
                  <div className='alert alert-danger invalid-feedback invalid-feedback-c '>
                    {this.state.firmError}
                    <br />
                  </div>
                )}
                <Form.Group controlId='formRegisterFirm'>
                  <FormControl
                    type='text'
                    placeholder='Firm name'
                    value={this.state.firm}
                    onChange={this.onChange}
                    name='firm'
                    autoFocus
                    ref={this.edit2Ref}
                  />
                </Form.Group>
                <Button
                  className='btn action btn-dark'
                  block
                  type='submit'
                  variant='primary'
                >
                  Continue
                </Button>
                <a href='https://www.aleri.ca/academy/kb-tickets/new'>
                  Forgot firm name?
                </a>{' '}
                <br />
                <Link to='/registration'>
                  <span>Don't have an account?</span>{' '}
                  <span className='signup-signin'>Sign up</span>
                </Link>
              </Form>
            </div>
          </Col>
          <Col md={7} className='Login-IMG d-none d-md-block'></Col>
        </Row>
      </div>
    );

    return <div>{firmSubmitted ? firmCode : noFirm}</div>;
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    errors: state.errors,
  };
};

export default connect(mapStateToProps, { loginUser })(Login);
