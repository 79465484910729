import React, { Component, Fragment } from "react";

import { Button, Tooltip, OverlayTrigger, ButtonToolbar } from 'react-bootstrap';
import './Sidebar.css';

// import type { T_Highlight } from "react-pdf-highlighter/src/types";
// type T_ManuscriptHighlight = T_Highlight;

// type Props = {
//   highlights: Array<T_ManuscriptHighlight>,
//   resetHighlights: () => void,
//   toggleDocument: () => void
// };

const updateHash = highlight => {
  document.location.hash = `highlight-${highlight._id}`;
};

class Sidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,

    }
  }

  render () {
    // console.log("HIGHLIGHTS IN THE SIDEBAR: ", this.props.highlights);
    
    const { showInfo } = this.state;

    return (
      <div className="sidebar" >
        <div className="description" >
          <h3 style={{ marginBottom: "1rem" }}>Highlights</h3>
          <p 
            className="showInfo" 
            onClick={() => {
              showInfo ?
              this.setState({ showInfo: false }) :
              this.setState({ showInfo: true })
            }}
          >How to use Highlights</p>
          { showInfo && (
            <Fragment>
              <p>
                <small>
                  To create text highlight, click and drag.
                </small>
              </p>
              <p>
                <small>
                  To create area highlight hold ⌥ Option key (Alt), then click and
                  drag.
                </small>
              </p>
              <p>
                <small>
                  Click on the highlight to navigate to the highlighted part.
                </small>
              </p>
              <p>
                <small>
                  Pin your document so the next time you open it, you are where you pinned your document.
                </small>
              </p>
            </Fragment>
          )}
        </div>
  
        <ul className="sidebar__highlights">
          {this.props.highlights.map((highlight, index) => (
            <li
              key={index}
              className="sidebar__highlight"
            >
              <div 
                className="sidebar_highlight-text"
                onClick={() => updateHash(highlight)}
              >
                <strong>{highlight.comment.text}</strong>
                {highlight.content.text ? (
                  <blockquote style={{ marginTop: "0.5rem" }}>
                    {`${highlight.content.text?.slice(0, 70).trim()}…`}
                  </blockquote>
                ) : null}
                {highlight.content.image?.file ? (
                  <div
                    className="highlight__image"
                    style={{ marginTop: "0.5rem" }}
                  >
                    <img src={highlight.content.image.file.replace('aleri-app-bucket', 'aleri-prod-app-bucket')} alt={"Screenshot"} />
                  </div>
                ) : (
                  <>
                  {highlight.content.image ? (
                    <div
                      className="highlight__image"
                      style={{ marginTop: "0.5rem" }}
                    >
                      <img src={highlight.content.image} alt={"Screenshot"} />
                    </div>
                  ) : null }
                  </>
                )}
                <div className="highlight__location">
                  Page {highlight.position.pageNumber}
                </div>
              </div>
              <div className="sidebar_icons">
                <img 
                  className="logo-title-dash highlight-delete" 
                  src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg" 
                  onClick={() => this.props.deleteHighlight(highlight._id, highlight.content)}
                />
                {this.props.summary && !this.props.preview && !highlight.added && (
                  <ButtonToolbar>
                  <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                      <Tooltip
                        id={
                          this.props.darkMode
                            ? 'dark-tooltip-add'
                            : 'tooltip-add'
                        }
                      >
                        Click this icon to make this highlight a row with the document in the reference column and highlighted text in the evidence column
                      </Tooltip>
                    }
                  >
                    <img 
                    className="logo-title-dash highlight-add" 
                    src="https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg" 
                    onClick={() => {
                      this.props.addHighlightToRow(highlight)
                      this.props.changeIcon(highlight._id)
                    }}/>
                  </OverlayTrigger>
                </ButtonToolbar> 
                )}
                {highlight.added && (
                  <img class="logo-title-dash" src="https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg"></img>
                )}
                {highlight.pinned && (
                  <img className="logo-title-dash" src="https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg" onClick={() => this.props.pinHighlight(highlight._id, false)}></img>
                )}
                {!highlight.pinned && (
                  <img className="logo-title-dash card_important_comment" src="https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg" onClick={() => this.props.pinHighlight(highlight._id, true)}></img>
                )}
              </div>
            </li>
          ))}
        </ul>

        {this.props.highlights.length > 0 ? (
          <div className="buttons">
            <Button variant='secondary btn-reset' onClick={() => this.props.resetHighlights()}>Reset highlights</Button>
            {this.props.summary && !this.props.preview && (
               <ButtonToolbar>
               <OverlayTrigger
                 key='top'
                 placement='top'
                 overlay={
                   <Tooltip
                     id={
                       this.props.darkMode
                         ? 'dark-tooltip-add'
                         : 'tooltip-add'
                     }
                   >
                     Click this button to make each highlight a row with the document in the reference column and highlighted text in the evidence column. Images will not be added.
                   </Tooltip>
                 }
               >
                 <Button variant='secondary btn-summarize' onClick={this.props.addHighlightsToRows}>
                   {this.props.highlightsAddedToRows ? (
                    <>Added</>
                   ): (
                    <>Add All</>
                   )}
                 </Button>
               </OverlayTrigger>
             </ButtonToolbar>   
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Sidebar;
