import React from 'react';
import Pulse from 'react-reveal/Pulse';

const LoggingOut = () => {
  return (
    <div id='load'>
      <Pulse>
        <h1>Logging Out</h1>

        <div className='lds-ring'>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <img
            src='https://res.cloudinary.com/aleri/image/upload/v1593642359/Aleri/b-logo_bexcob.svg'
            alt='image2'
          />
        </div>
      </Pulse>
    </div>
  );
};
export default LoggingOut;
