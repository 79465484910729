import React, { Component } from "react";

import { Button } from "react-bootstrap";

// pdf
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

// Docx
import { saveAs } from "file-saver";
import { Document as DocxDocument, Packer } from "docx";

import { getCrossExamination } from "../../../Shared/DocxExport";

const Pdf = (props) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const output = <div></div>;

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: "column",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
    },
    subSection: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1 0 50%",
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: "left",
      textTransform: "uppercase",
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: "right",
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: "auto",
      marginRight: "auto",
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: "right",
      textTransform: "uppercase",
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColName: {
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColValue: {
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: "ellipsis",
    },
    tableCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: 300,
      width: "100%",
      textOverflow: "ellipsis",
      padding: 5,
    },
    image: {
      maxHeight: 120,
      maxWidth: 200,
      borderRadius: 4,
      resizeMode: 'contain',
    }
  });
  const docs = [];
  const topics = [];
  for (let a of props.focus.docs) {
    console.log("docs: ", a.locations);
    const locations = [];
    for (let i = 0; i < a.locations.length; i++) {
      if (i !== a.locations.length -1) {
        locations.push(<Text style={styles.tableCell}>{`${a.locations[i].name}, `}</Text>)
      } else {
        locations.push(<Text style={styles.tableCell}>{`${a.locations[i].name}`}</Text>)
      }
    }
    docs.push(
      <>
        <Text style={styles.tableCell}>{`${a.name} - `}{locations}</Text>
        
      </>
    );
  }
  for (let t of props.topics) {
    topics.push(<Text style={styles.tableCell}>{t.name}</Text>);
  }
  const pdf = (
    <PDFDownloadLink
      className="bob-link"
      target="_blank"
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.title}>Focus Of Cross Examination</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Witness Name</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.name.value}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Witness Photo</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Image style={styles.image} source={{ uri: props.focus.photo.file }}/>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Relationship To Case</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.relationship.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      {`How They Advance \nOpponent's Case`}
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.opposition_adv.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Bias / Motivation</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.bias.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Strengths</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.strength.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Weaknesses</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.weaknesses.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Facts To Bring Out</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.fact.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      {`Goals / Focus Of \nExamination`}
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.goals.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Key Words</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.keywords.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Objections</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.objections.value}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Opening Question</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.open.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Closing Question</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.closing.value}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      {`Cross-Examination \nTechniques`}
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.focus.examination_tech.value}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Documents</Text>
                  </View>
                  <View style={styles.tableColValue}>{docs}</View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Topics</Text>
                  </View>
                  <View style={styles.tableColValue}>{topics}</View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName="focus.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Loading PDF To Export
          </Button>
        ) : (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Export To PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return <>{pdf}</>;
};

const Word = (props) => {
  let docs = "";
  let topics = "";
  for (let a of props.focus.docs) {
    docs = `${docs}, ${a.name}`;
  }
  for (let t of props.topics) {
    topics = `${topics}, ${t.name}`;
  }

  const generate = () => {
    const page = getCrossExamination({
      ...props.focus,
      topics: { value: topics },
      documents: { value: docs },
    });

    const doc = new DocxDocument({
      sections: [page],
    })
    

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `focus.docx`);
    });
  };

  return (
    <Button
      className="btn action  btn-b btn btn-primary btn-block"
      onClick={generate}
    >
      Export To Word(docx)
    </Button>
  );
};

const FocusExport = (props) => {
  return (
    <>
      <Pdf {...props} />
      <Word {...props} />
    </>
  );
};

export default FocusExport;
