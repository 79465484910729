import React, { Component } from 'react';
import Icons from './Icons';
export default class NotExpanded extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <>
        <Icons
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          darkMode={this.props.darkMode}
          changeLocation={this.props.changeLocation}
          getWitness={this.props.getWitness}
          cases={this.props.cases}
          setNavExpanded={this.props.setNavExpanded}
          goInfo={this.props.goInfo}
          goHome={this.props.goHome}
          goSettings={this.props.goSettings}
          loadOut={this.props.loadOut}
          goActivity={this.props.goActivity}
          goAudioTranscription={this.props.goAudioTranscription}
        />

        <div className='comp-auth-nav-toggle'>
          <div
            onClick={this.props.setNavExpanded}
            className='czhaTb-notExpanded'
          ></div>
        </div>
        <div className='mobile-auth-nav-toggle'>
          <div onClick={this.props.setNavExpanded} className='czhaTb'>
            <div className='mobile-upper-nav'>
              <img
                className='land-logo'
                src='https://res.cloudinary.com/aleri/image/upload/v1593642374/Aleri/tod_lzzdaf.svg'
              ></img>
              <div className='title-mobile'>
                <h2>ALERI</h2>
              </div>
            </div>
            <div className='ham'>
              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/menu_arjwr3.svg'></img>
            </div>
          </div>
        </div>
      </>
    );
  }
}
