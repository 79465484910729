import React, { Component } from 'react';
import { Nav, Navbar, Button, NavDropdown } from 'react-bootstrap';

import { Link } from 'react-router-dom';

export default class Guest extends Component {
  constructor() {
    super();
    this.state = {
      navExpanded: false,
    };
    this.setNavExpanded = this.setNavExpanded.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }
  setNavExpanded(e) {
    this.props.toggleSideMenu();
    this.setState({ navExpanded: e });
  }
  closeNav() {
    this.setState({ navExpanded: false });
  }
  render() {
    return (
      <>
        {/* <div className='sales'>
          <Link to='/support/'>
            We are for innovators. Those who see the future.
          </Link>
        </div>
        */}
        <Navbar
          bg='light'
          expand='lg'
          id='Navigation'
          onToggle={this.setNavExpanded}
          expanded={this.state.navExpanded}
        >
          <Navbar.Brand>
            <a href='https://aleri.ca' onClick={this.closeNav}>
              <div className='box-t'>
                <img
                  className='land-logo'
                  src='https://res.cloudinary.com/aleri/image/upload/v1610911993/Aleri/Auth/Copy_of_Aleri_3_ekndzg.png'
                ></img>
                {/* {'  '}
                <h4 className='brands'>ALERI</h4> */}
              </div>
            </a>
          </Navbar.Brand>
          {/* <Navbar.Brand className='temp'></Navbar.Brand> */}
          {/*  */}
          {/* 
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='mr-auto'> */}
          {/* <NavDropdown
                title='Why ALERI?'
                id='basic-nav-dropdown'
                className='pad pad2'
              >
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/features'>
                    <Nav className='head-nav'>
                      Features
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/questions'>
                    <Nav className='sub-nav'>Questions</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/preparation-chart'>
                    <Nav className='sub-nav'>Preparation Chart</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/facts'>
                    <Nav className='sub-nav'>Facts</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/summaries'>
                    <Nav className='sub-nav'>Summaries</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/checklist'>
                    <Nav className='sub-nav'>Checklist</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/focus'>
                    <Nav className='sub-nav'>Focus</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/customer-security'>
                    <Nav className='head-nav'>
                      Security <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/collaboration'>
                    <Nav className='head-nav'>
                      Collaboration{' '}
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
              </NavDropdown>
              <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/docs/'>Documentation</Link>
              </Nav> */}
          {/* <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/pricing'>Pricing</Link>
              </Nav> */}
          {/* <NavDropdown
                title='Pricing'
                id='basic-nav-dropdown'
                className='pad pad2'
              >
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/promotion'>
                    <Nav className='head-nav'>
                      Promotion
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/pricing'>
                    <Nav className='head-nav'>
                      Plans
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/starter'>
                    <Nav className='sub-nav'>Starter</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/pro'>
                    <Nav className='sub-nav'>Pro</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/business'>
                    <Nav className='sub-nav'>Business</Nav>
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/enterprise'>
                    <Nav className='head-nav'>
                      Enterprise
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/student'>
                    <Nav className='head-nav'>
                      Student
                      <img src="https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg" />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
              </NavDropdown> */}
          {/* <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/blog'>Blog</Link>
              </Nav>
              <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/podcast'>Podcast</Link>
              </Nav> */}
          {/* <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/news'>News</Link>
              </Nav> */}
          {/* <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/spotlight'>Community Stories</Link>
              </Nav> */}
          {/* <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/about'>Who Are We?</Link>
              </Nav>
              <Nav className='pad pad2' onClick={this.closeNav}>
                <Link to='/support'>Contact Us</Link>
              </Nav> */}
          {/* 
              <NavDropdown
                title='Explore'
                id='basic-nav-dropdown'
                className='pad pad2'
              >
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/about'>
                    <Nav className='head-nav'>
                      Company{' '}
                      <img src='https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg' />
                    </Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/blog'>
                    <Nav className='sub-nav'>Blog</Nav>
                  </Link>
                </NavDropdown.Item>
                <NavDropdown.Item onClick={this.closeNav}>
                  <Link to='/support'>
                    <Nav className='sub-nav'>Contact Us </Nav>
                  </Link>
                </NavDropdown.Item>

                <NavDropdown.Divider />
                <NavDropdown.Item
                  href='https://www.linkedin.com/groups/13847290/'
                  target='_blank'
                  onClick={this.closeNav}
                >
                  <Nav className='head-nav'>
                    Community{' '}
                    <img src='https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/arrow_ynh0ee.svg' />
                  </Nav>
                </NavDropdown.Item>
              </NavDropdown> */}
          {/* </Nav>
            <Nav className='background-blue'>
              <Nav className='pad' onClick={this.closeNav}>
                <Link to='/login/'>Login</Link>
              </Nav>
              <Nav onClick={this.closeNav}>

                <Nav onClick={this.closeNav}> */}
          {/* <Link to='/registration/'>
                    <Button className='action action-b btn-dark'>
                      Registration
                    </Button>
                  </Link> */}
          {/* <Link to='/registration/'>
                    <Button className='action action-b btn-dark'>
                  Create Account
                    </Button>
                  </Link>
                </Nav>
              </Nav>
            </Nav>
          </Navbar.Collapse>
  */}
        </Navbar>
      </>
    );
  }
}
