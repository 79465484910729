import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../prod';
import DashboardModal from './DashboardModal';
import DashboardCaseList from './DashboardCaseList';
import Icons from '../Navigation/Icons';
import ToggleNav from '../Navigation/ToggleNav';

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uClient: '',
      uName: '',
      bUpdate: {
        uClient: '',
        uName: '',
      },
      case: {},
      eShow: false,
      timelineByWitness: [],
      timelines: [],
    };
    this.handleSShow = this.handleSShow.bind(this);
    this.handleSClose = this.handleSClose.bind(this);
    this.getW = this.getW.bind(this);
    this.handleSSubmit = this.handleSSubmit.bind(this);

    this.handleDClose = this.handleDClose.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.headingRef = React.createRef();

    this.editRef = React.createRef();
    this.createRef = React.createRef();
    //
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    this.cloneCase = this.cloneCase.bind(this);
  }
  handleEShow = () => {
    this.setState({ eShow: true, sShow: false }, () => {});
  };
  handleEClose = () => this.setState({ eShow: false });
  handleProgress = (witness) => {
    let p = 0;
    let num = 0;
    let v = 0;
    let amount = 0;

    if (witness && witness.length > 0) {
      witness.forEach((n) => {
        if (n.topics && n.topics.length > 0) {
          for (let i of n.topics) {
            if (i.topic.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.statements && n.statements.length > 0) {
          for (let i of n.statements) {
            if (i.statement.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.facts && n.facts.length > 0) {
          for (let i of n.facts) {
            if (i.fact.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.financial != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.relationship != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.hostility != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.impairment_alcohol_or_drugs != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.physical_deficiency != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .focus != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .witness_preoccupied != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .m_quantity_and_distance_not_reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .desire_to_find_meaning != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_see != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_hear != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .impact_of_sudden_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .insignificance_of_event != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .ability_to_observe != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .expose_belief_from_habit != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.forget_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.flawed != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.experience != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.collateral != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.influence != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent.accuse !=
            ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .confront != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.implausible
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.contradicted
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.record &&
          n.checklist.credibility.record != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.evidence &&
          n.checklist.credibility.evidence != ''
        ) {
          v += 1;
        }
        //
        if (n.focus.name && n.focus.name.value != '') {
          v += 1;
        }
        if (n.focus.relationship && n.focus.relationship.value != '') {
          v += 1;
        }
        if (n.focus.goals && n.focus.goals.value != '') {
          v += 1;
        }
        if (n.focus.open && n.focus.open.value != '') {
          v += 1;
        }
        if (n.focus.closing && n.focus.closing.value != '') {
          v += 1;
        }
        if (n.focus.bias && n.focus.bias.value != '') {
          v += 1;
        }
        if (n.focus.fact && n.focus.fact.value != '') {
          v += 1;
        }
        // if (n.focus.tops && n.focus.tops.length > 0) {
        if (1 == 1) {
          v += 1;
        }
        if (n.focus.docs && n.focus.docs.length > 0) {
          v += 1;
        }
        if (n.focus.keywords && n.focus.keywords.value != '') {
          v += 1;
        }
        if (n.focus.strength && n.focus.strength.value != '') {
          v += 1;
        }
        if (n.focus.weaknesses && n.focus.weaknesses.value != '') {
          v += 1;
        }
        if (n.focus.examination_tech && n.focus.examination_tech.value != '') {
          v += 1;
        }
        if (n.focus.objections && n.focus.objections.value != '') {
          v += 1;
        }
        if (n.focus.opposition_adv && n.focus.opposition_adv.value != '') {
          v += 1;
        }
        if (n.prepchart && n.prepchart.length > 0) {
          v += 1;
        }
      });
    } else {
      num = 1;
    }

    num += 38 * (witness ? witness.length : 0);

    amount = 100 / num;

    p = amount * v;

    return p;
  };
  handleSubmit = (s, data) => {
    data.preventDefault();
    const cases = {
      name: s.name,
      client: s.client,
      firm: this.state.firm,
      user: this.props.use,
      currentUser: this.props.currentUser,
    };
    if (
      cases.name !== '' &&
      cases.client !== '' &&
      cases.name !== undefined &&
      cases.client !== undefined &&
      cases.firm !== ''
    ) {
      if (
        (this.state.plan == '1' && this.state.cases.length >= 15) ||
        (this.state.plan == '4' && this.state.cases.length >= 15)
      ) {
        this.setState({
          err: 'You are allowed to add 15 case. Upgrade to higher plan to add more cases.',
        });
      } else if (
        (this.state.plan == '2' && this.state.cases.length >= 100) ||
        (this.state.plan == '5' && this.state.cases.length >= 100)
      ) {
        this.setState({
          err: 'You are allowed to add 100 case. Upgrade to higher plan to add more cases.',
        });
      } else {
        console.log('creating cases');
        axios.post(prod() + '/api/cases/create', cases).then((use) => {
          this.props.clicked();

          this.setState(
            {
              show: false,
              client: '',
              name: '',
              user: '',
              err: '',
            },
            () => {
              this.props.clicked2();
            }
          );
        });
      }
    } else {
      this.setState({
        user: 'Please fill out both file number and client name fields',
      });
    }
  };

  handleShow = () => {
    this.setState({ show: true }, () => this.createRef.current.focus());
  };
  handleClose = () =>
    this.setState({
      show: false,
      err: '',
      user: '',
      name: '',
      client: '',
    });
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e, deletedOrderIndex, deletedStatus, ev) => {
    ev.stopPropagation();
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((use) => {
        this.setState({
          dShow: true,
          deleted: e,
          deletedOrderIndex: deletedOrderIndex,
          deletedStatus: deletedStatus,
          cases: use.data.cases,
        });
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  };
  componentDidMount() {
    this.setState(
      {
        cases: this.props.cases,
        firm: this.props.firm,
        plan: this.props.plan,
      },
      () => {
        for (let cased of this.state.cases) {
          cased['progress'] = this.handleProgress(cased.witnesses);
        }
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cases !== this.props.cases) {
      this.setState(
        {
          cases: this.props.cases,
          firm: this.props.firm,
        },
        () => {
          for (let cased of this.state.cases) {
            cased['progress'] = this.handleProgress(cased.witnesses);
          }
        }
      );
    }
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.firm !== this.props.firm ||
      this.props.case != prevProps.cases
    ) {
      for (let cased of prevProps.cases) {
        cased['progress'] = this.handleProgress(cased.witnesses);
      }
      this.setState({
        firm: prevProps.firm,
        cases: prevProps.cases,
      });
    }
  }
  getW(a, l) {
    localStorage.removeItem('case');
    this.props.getWitness(a, {
      loc: '/case',
      case: a,
      caseN: l,
    });
  }
  handleSClose = () =>
    this.setState({
      sShow: false,
      userU: '',
      uClient: '',
      uName: '',
    });

  transformEvents = (events, caseItem) => {
    const witnessMap = this.getWitnessMap(caseItem);
    return events.reduce((prev, event) => {
      const individualEvents = event.events.map((e) => ({
        ...e,
        witnessId: event.witnessId,
        witnessName: witnessMap[event.witnessId],
      }));
      return [...prev, ...individualEvents];
    }, []);
  };

  getWitnessMap = (caseItem) => {
    return (
      caseItem.witnesses.reduce(
        (prev, witness) => ({ ...prev, [witness._id]: witness.name }),
        {}
      ) || {}
    );
  };

  sortEvents = (events) => events.sort((a, b) => a.date.localeCompare(b.date));

  handleSShow = (e, ev) => {
    ev.stopPropagation();
    //getting timelines to pass it to case export
    axios
      .post(prod() + '/api/cases/getall', {
        firm: this.state.firm,
      })
      .then((user) => {
        const selectedCase = user.data.cases.find((c) => c._id === e);

        axios
          .get(prod() + '/api/cases/timeline/all', {
            params: {
              id: selectedCase._id,
            },
          })
          .then((res) => {
            const response = this.transformEvents(
              res?.data?.events || [],
              selectedCase
            );
            const sortedEvents = this.sortEvents(response);
            this.setState({
              timelines: sortedEvents,
            });
          })
          .catch((e) => console.log(e));

        axios
          .post(prod() + '/api/cases/get', { case: e })
          .then((use) => {
            const eventsByWitness = [];
            for (const witness of selectedCase.witnesses) {
              axios
                .get(prod() + '/api/cases/timeline/witness', {
                  params: {
                    caseId: selectedCase._id,
                    witnessId: witness._id,
                  },
                })
                .then((res) => {
                  const response = res?.data?.events || [];
                  const sortedEvents = this.sortEvents(response);
                  eventsByWitness.push({
                    [witness.name]: sortedEvents,
                  });
                });
            }

            this.setState(
              {
                sShow: true,
                updating: e,
                uClient: use.data.client,
                uName: use.data.name,
                bUpdate: {
                  client: use.data.client,
                  name: use.data.name,
                },
                cases: user.data.cases,
                case: selectedCase,
                timelineByWitness: eventsByWitness,
              },
              () => this.editRef.current.focus()
            );
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  };
  handleSSubmit = (s, data) => {
    data.preventDefault();

    const cases = {
      name: s.name,
      client: s.client,
      firm: this.state.firm,
      user: this.state.use,
      updating: this.state.updating,
    };
    if (
      this.state.bUpdate.client != cases.client ||
      this.state.bUpdate.name != cases.name
    ) {
      if (
        cases.name !== '' &&
        cases.client !== '' &&
        cases.name !== undefined &&
        cases.client !== undefined &&
        cases.firm !== ''
      ) {
        axios
          .post(prod() + '/api/cases/update', {
            name: cases.name,
            client: cases.client,
            updating: this.state.updating,
          })
          .then((use) => {
            this.props.clicked();
            this.setState({
              dShow: false,
              sShow: false,
              updating: '',
            });
          })
          .catch((e) =>
            this.setState({
              user: 'Could not find case',
            })
          );
      } else {
        this.setState({
          userU: 'Please fill out both file number and client name fields',
        });
      }
    } else {
      this.setState({
        dShow: false,
        sShow: false,
        updating: '',
      });
    }
  };

  deleteTimelines = (deletedWitnessId, caseId) => {
    axios
      .post(prod() + '/api/cases/timeline/case/delete', {
        caseId: caseId,
        witnessId: deletedWitnessId,
      })
      .then((res) => {
        console.log(res.data);
      })
      .catch((e) => console.log(e));
  };

  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then((res) => console.log('deleted file'))
      .catch((e) => console.log(e));
  };

  deleteDocs = (deletedWitnessId, caseId) => {
    axios
      .post(prod() + '/api/documents/getall', {
        case: caseId,
        witness: deletedWitnessId,
      })
      .then((a) => {
        // console.log("documents: ", a)
        const documents = a.data.documents;
        for (const doc of documents) {
          if (doc.type === 'file') {
            //GC storage bucket names are different for prod and development
            const link = doc.link;
            const indexOfTerm = link.indexOf('storage.googleapis.com');
            const slicedLink = link.slice(indexOfTerm);
            const gcFileName = slicedLink.slice(23);

            this.deleteFile(gcFileName);
          }
        }
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };

  deleteHighlights(rowIds) {
    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: rowIds,
      })
      .then((res) => {
        console.log('images: ', res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach((image) => {
          this.deleteFile(image);
        });
        axios
          .post(prod() + '/api/file/highlights/documents/deleteAll', { rowIds })
          .then((res) => console.log(res.data))
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }

  deleteWitness(witnessInfo, caseId) {
    this.deleteDocs(witnessInfo._id, caseId);
    this.deleteTimelines(witnessInfo._id, caseId);
    //delete highlights and remove image highlights from gcs
    let rowIds = [];
    witnessInfo.statements.forEach((s) => {
      s.statement.forEach((row) => {
        if (row.ref.document) rowIds.push(row._id);
      });
    });
    witnessInfo.facts.forEach((f) => {
      f.fact.forEach((row) => {
        if (row.ref.document) rowIds.push(row._id);
      });
    });
    witnessInfo.topics.forEach((t) => {
      t.topic.forEach((row) => {
        if (row.ref.document) rowIds.push(row._id);
      });
    });
    console.log('rowIds: ', rowIds);
    this.deleteHighlights(rowIds);
  }
  //deletes documents, highglihts, pdfs in gcs, image higlights in gcs
  deleteCase = () => {
    // this.deleteWitness()
    console.log('case: ', this.state.deleted);
    const caseInfo = this.state.cases.find((c) => c._id === this.state.deleted);
    console.log('case :', caseInfo);

    caseInfo.witnesses.forEach((w) => {
      console.log('witness: ', w);
      this.deleteWitness(w, this.state.deleted);
    });

    axios.post(prod() + '/api/documents/delete/case', {
      case: this.state.deleted,
    });
    axios
      .post(prod() + '/api/cases/delete', {
        id: this.state.deleted,
        orderIndex: this.state.deletedOrderIndex,
        status: this.state.deletedStatus,
        firm: this.state.firm,
      })
      .then((use) => {
        this.props.clicked();
        this.setState(
          {
            dShow: false,
            client: '',
            name: '',
            deleted: '',
            status: '',
            orderIndex: '',
            loc: '/',
          },
          () => {
            if (window.location.pathname !== '/') this.props.history.push('/');
            this.props.clicked2();
          }
        );
      })

      .catch((e) => console.log(e));
  };

  cloneCase(e, caseInfo, newName, newClientName) {
    console.log('chosen case: ', caseInfo);
    e.preventDefault();

    console.log('new name: ', newName);
    const newCase = {
      name: newName,
      client: newClientName,
      firm: this.state.firm,
      user: this.props.use,
      currentUser: this.props.currentUser,
      witnesses: caseInfo.witnesses,
      tagging: caseInfo.tagging,
      users: caseInfo.users,
      status: caseInfo.status,
    };
    if (
      newCase.name !== '' &&
      newCase.client !== '' &&
      newCase.name !== undefined &&
      newCase.client !== undefined &&
      newCase.firm !== ''
    ) {
      if (
        this.state.plan == '1' ||
        (this.state.plan == '4' && this.state.cases.length >= 15)
      ) {
        this.setState({
          err: 'You are allowed to add 15 case. Upgrade to higher plan to add more cases.',
        });
      } else if (
        this.state.plan == '2' ||
        (this.state.plan == '5' && this.state.cases.length >= 100)
      ) {
        this.setState({
          err: 'You are allowed to add 100 case. Upgrade to higher plan to add more cases.',
        });
      } else {
        axios.post(prod() + '/api/cases/clone', newCase).then((use) => {
          console.log('new case: ', use.data.case);
          this.props.clicked();

          this.setState(
            {
              sShow: false,
            },
            () => {
              this.props.clicked2();
            }
          );
        });
      }
    } else {
      this.setState({
        user: 'Please fill out both file number and client name fields',
      });
    }
  }

  render() {
    // if (this.state.cases[0]) console.log(this.state.cases[0].progress);

    return (
      <div id='main-dash'>
        <Icons
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          cases={this.props.cases}
          user={this.state.user}
          getWitness={this.props.getWitness}
          changeLocation={this.props.changeLocation}
          setNavExpanded={this.props.setNavExpanded}
          goInfo={this.props.goInfo}
          goHome={this.props.goHome}
          goSettings={this.props.goSettings}
          goActivity={this.props.goActivity}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.props.goAudioTranscription}
        />

        <div className='expanded'>
          <div id='side-nav-open'>
            <div className='side-nav-open-title' ref={this.headingRef}>
              <h5 id='case'>
                {this.state.cases && this.state.cases.length}{' '}
                {this.state.cases && this.state.cases.length === 1
                  ? 'Case'
                  : 'Cases'}
              </h5>
            </div>
            <Button
              onClick={this.handleShow}
              className='add-case-btn dash-desc-d-d'
              block
            >
              Add Case
            </Button>

            <DashboardCaseList
              cases={this.props.cases}
              handleSShow={this.handleSShow}
              handleDShow={this.handleDShow}
              getW={this.getW}
              refreshCases={this.refreshCases}
              darkMode={this.props.darkMode}
              updateCase={this.props.updateCase}
              firm={this.state.firm}
              darkMode={this.props.darkMode}
              handleProgress={this.handleProgress}
            />

            <DashboardModal
              show={this.state.show}
              handleClose={this.handleClose}
              handleSubmit={this.handleSubmit}
              dShow={this.state.dShow}
              handleDClose={this.handleDClose}
              handleDSubmit={this.handleDSubmit}
              sShow={this.state.sShow}
              handleSClose={this.handleSClose}
              handleSSubmit={this.handleSSubmit}
              user={this.state.user}
              err={this.state.err}
              uName={this.state.uName}
              uClient={this.state.uClient}
              deleteCase={this.deleteCase}
              create={this.createRef}
              edit={this.editRef}
              darkMode={this.props.darkMode}
              handleEShow={this.handleEShow}
              handleEClose={this.handleEClose}
              eShow={this.state.eShow}
              case={this.state.case}
              cases={this.state.cases}
              timelineByWitness={this.state.timelineByWitness}
              timelines={this.state.timelines}
              cloneCase={this.cloneCase}
            />
          </div>
        </div>
        <ToggleNav setNavExpanded={this.props.setNavExpanded} />
      </div>
    );
  }
}
