import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Form,
  Button,
  Col,
  Row,
  Container,
  Modal,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  ProgressBar,
} from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import axios from 'axios';
import prod from '../../../../prod';
import Pulse from 'react-reveal/Pulse';
import Fade from 'react-reveal/Fade';

import '../../../../Tags.css';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

// import ReactGA from 'react-ga';
import SummaryModal from './SummaryModal';
import LinkDocument from '../../Shared/LinkDocument';
import SummaryRow from './SummaryRow';
import NewRow from '../../Shared/NewRow';

import SummaryHighlightModal from './SummaryHighlightModal';
import SummaryTimelineModal from './SummaryTimelineModal';

export const logPageView = () => {
  window.analytics.page('Summary');
};

class Summary extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      statement: {},
      q: [],

      reference: '',
      evidence: '',

      deleted: '',
      dShow: false,
      // delete statement
      deletedT: '',
      dtShow: '',
      // update
      show: false,
      err: '',
      name: '',

      // help
      hShow: false,
      // export
      eShow: false,
      dragging: false,
      rowHeight: 0,
      summaryEvidence: false,
      summaryReference: false,
      note: false,
      createLink: false,
      linkErr: '',
      addLinkState: false,
      row: 0,
      documentDeleteShow: false,

      //upload doc
      ref: {
        value: '',
        rows: 1,
      },
      highlights: [],
      link: '',
      uploadDocModal: false,
      uploadDocModalE: false, //upload document modal for saved rows
      docs: [],
      rowId: '',
      addExistingDoc: false,

      highlightShow: false,
      toggleHighlight: false,
      highlightButtonText: 'Fact Tagging',
      highlightedText: '',
      highlightedTextRef: '',
      highlightedImg: '',
      multipleHighlights: [],
      addRowModal: false,

      timelineShow: false,
      toggleTimeline: false,
      timelineButtonText: 'Timeline Tagging',
      timelineText: '',
      timelineTextRef: '',

      addItem: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteStatement = this.deleteStatement.bind(this);
    this.updateRows = this.updateRows.bind(this);
    // delete statement
    this.handleFTShow = this.handleFTShow.bind(this);
    this.handleFTClose = this.handleFTClose.bind(this);
    this.handleFTSubmit = this.handleFTSubmit.bind(this);
    // update statement
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // help
    this.handleHClose = this.handleHClose.bind(this);
    this.handleHShow = this.handleHShow.bind(this);

    // change
    this.onChange = this.onChange.bind(this);
    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
    // Highlight
    this.handleHighlightClose = this.handleHighlightClose.bind(this);
    this.handleHighlightShow = this.handleHighlightShow.bind(this);
    this.createHighlight = this.createHighlight.bind(this);
    this.selectionHandler = this.selectionHandler.bind(this);
    this.showAddRowModal = this.showAddRowModal.bind(this);

    // Timeline
    this.handleTimelineClose = this.handleTimelineClose.bind(this);
    this.handleTimelineShow = this.handleTimelineShow.bind(this);
    this.createHighlight = this.createHighlight.bind(this);
    this.selectionTimelineHandler = this.selectionTimelineHandler.bind(this);

    // references
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.divElement = React.createRef();
    this.divElement2 = React.createRef();

    this.goToRef = this.goToRef.bind(this);
    this.linkRef = React.createRef();
    this.addRef = React.createRef();

    //doc
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
    this.openUploadDocModalE = this.openUploadDocModalE.bind(this);
    this.saveHighlights = this.saveHighlights.bind(this);
    this.createHighlightsToRows = this.createHighlightsToRows.bind(this);
    this.addHighlightsToRows = this.addHighlightsToRows.bind(this);
    this.addHighlightToRow = this.addHighlightToRow.bind(this);
    this.createPDF = this.createPDF.bind(this);
    this.saveLinkToCreatePDF = this.saveLinkToCreatePDF.bind(this);
    this.saveHighlightsToPDF = this.saveHighlightsToPDF.bind(this);
  }
  isURL = (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  };

  //

  addExistingLink = (data) => {
    // console.log(data);

    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/summary' &&
          a.summary.toString() == this.props.statement['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      // console.log(data);
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        summary: this.props.statement,
        path: '/case/witness/summary',
        name: this.props.statement.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          // console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }

    let t = this.state.q;
    t[this.state.row].ref.document = data;
    t[this.state.row].ref.value = data.name;
    t[this.state.row].reference = data.name;

    axios
      .post(prod() + '/api/cases/update/witness/statement', {
        witness: this.state.witness['_id'],
        case: this.state.case._id,
        statement: this.state.statement._id,
        q: t,
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];
        axios
          .post(prod() + '/api/cases/get/witness/statement', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            statement: this.props.statement._id,
          })
          .then((use) => {
            for (let topi of use.data.statements.statement) {
              items.push({
                reference: topi.reference,
                evidence: topi.evidence,
                _id: topi._id,
              });
            }

            const p = this.handleProgress(items);
            this.setState({
              q: items,
              row: 0,
              addLinkState: false,
            });
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  //adding link/file to an existing row
  addLink = (data) => {
    const rowIndex = this.state.q.findIndex((row) => {
      return row._id == data.refId;
    });
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                statement: this.props.statement,
                path: '/case/witness/summary',
                name: this.props.statement.name,
              },
            ],
          })
          .then((use) => {
            this.setState({ docs: use.data.documents });
            let t = this.state.q;
            t[rowIndex].ref.document =
              use.data.documents[use.data.documents.length - 1];
            t[rowIndex].ref.value =
              use.data.documents[use.data.documents.length - 1].name;
            t[rowIndex].reference =
              use.data.documents[use.data.documents.length - 1].name;
            axios
              .post(prod() + '/api/cases/update/witness/statement', {
                witness: this.state.witness['_id'],
                case: this.state.case._id,
                statement: this.state.statement._id,
                q: t,
              })
              .then((use) => {
                // console.log(use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];
                axios
                  .post(prod() + '/api/cases/get/witness/statement', {
                    case: this.props.case._id,
                    witness: this.props.witness['_id'],
                    statement: this.props.statement._id,
                  })
                  .then((use) => {
                    // console.log("ROw DATA: ", use)
                    for (let topi of use.data.statements.statement) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,
                        ref: topi.ref,
                      });
                    }

                    const p = this.handleProgress(items);
                    this.setState({
                      q: items,
                      row: 0,
                      addLinkState: false,
                    });
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  addExistingDoc = (data) => {
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/summary' &&
          a.statement.toString() == this.props.statement['_id'].toString()
        ) {
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        statement: this.props.statement,
        path: '/case/witness/summary',
        name: this.props.statement.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          // console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }
    axios
      .post(prod() + '/api/cases/create/witness/statement/q', {
        witness: this.props.witness._id,
        case: this.props.case._id,
        statement: this.props.statement._id,
        reference: data.name,
        referenceLink: data,
        evidence: '',
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];

        axios
          .post(prod() + '/api/cases/get/witness/statement', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            statement: this.props.statement._id,
          })
          .then((use) => {
            // console.log('GET', use);
            for (let topi of use.data.statements.statement) {
              items.push({
                reference: topi.reference,
                evidence: topi.evidence,
                _id: topi._id,
                ref: topi.ref,
              });
            }
            const p = this.handleProgress(items);
            this.setState({
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              statement: use.data.statements,
              reference: '',
              evidence: '',
              delete: '',
              progress: p,
              uploadDocModal: false,
              linkErr: '',
              ref: {
                value: '',
                rows: 1,
              },
            });
            this.goToRef(data);
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };

  //adds an existing document on an existing row
  addExistingDocOnRow = (data, rowId) => {
    const rowIndex = this.state.q.findIndex((row) => {
      // console.log("rowId", rowId)
      return row._id == rowId;
    });
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/summary' &&
          a.statement.toString() == this.props.statement['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      // console.log(data);
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        statement: this.props.statement,
        path: '/case/witness/summary',
        name: this.props.statement.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          // console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }

    let t = this.state.q;
    t[rowIndex].ref.document = {
      case: data.case,
      link: data.link,
      name: data.name,
      type: data.type,
      witness: data.witness,
      _id: data._id,
    };
    t[rowIndex].ref.value = data.name;
    t[rowIndex].reference = data.name;

    axios
      .post(prod() + '/api/cases/update/witness/statement', {
        witness: this.state.witness['_id'],
        case: this.state.case._id,
        statement: this.state.statement._id,
        q: t,
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];
        axios
          .post(prod() + '/api/cases/get/witness/statement', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            statement: this.props.statement._id,
          })
          .then((use) => {
            for (let topi of use.data.statements.statement) {
              items.push({
                reference: topi.reference,
                ref: topi.ref,
                evidence: topi.evidence,
                _id: topi._id,
              });
            }

            const p = this.handleProgress(items);
            this.setState({
              q: items,
              row: 0,
              addLinkState: false,
            });
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };

  // adds the document on a new row
  createLink = (data) => {
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                statement: this.props.statement,
                path: '/case/witness/summary',
                name: this.props.statement.name,
              },
            ],
          })

          .then((use) => {
            this.setState({ docs: use.data.documents });
            // add document to topic

            axios
              .post(prod() + '/api/cases/create/witness/statement/q', {
                witness: this.props.witness._id,
                case: this.props.case._id,
                statement: this.props.statement._id,
                reference: data.linkName,
                referenceLink:
                  use.data.documents[use.data.documents.length - 1],

                evidence: '',
              })
              .then((use) => {
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];

                axios
                  .post(prod() + '/api/cases/get/witness/statement', {
                    case: this.props.case._id,
                    witness: this.props.witness['_id'],
                    statement: this.props.statement._id,
                  })
                  .then((use) => {
                    for (let topi of use.data.statements.statement) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,

                        ref: topi.ref,
                      });
                    }
                    const p = this.handleProgress(items);
                    this.setState({
                      q: items,
                      witness: this.props.witness,
                      case: this.props.case,
                      statement: use.data.statements,
                      reference: '',
                      evidence: '',
                      delete: '',
                      progress: p,
                      linkErr: '',

                      ref: {
                        value: '',
                        rows: 1,
                      },
                      uploadDocModal: false,
                    });
                    this.goToRef(data);
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          })
          .catch((e) => {
            this.setState({
              linkErr: 'Invalid Input. Please Try Again',
            });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  closeLink = () => {
    this.setState({
      createLink: false,
      linkErr: false,
    });
  };

  //
  handleProgress = () => {
    let p = 0;
    const n = this.state;
    if (n.q && n.q.length > 0) {
      p += 100;
    }
    // console.log(this.state);
    if (p != this.state.progress) this.setState({ progress: p });
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    this.setState({
      dragging: true,
    });
  };

  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      axios
        .post(prod() + '/api/cases/get/witness/statement', {
          case: this.props.case._id,
          witness: this.props.witness['_id'],
          statement: this.props.statement._id,
        })
        .then((use) => {
          const items = [];
          for (let topi of use.data.statements.statement) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,
              ref: topi.ref,
            });
          }
          this.setState({
            q: items,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    });
  handleEClose = () => this.setState({ eShow: false });
  onChange(e) {
    this.setState({ [e.target.name]: e.target });
  }
  // help
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  // update statement
  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleAddClose = () => this.setState({ addLinkState: false });
  componentDidUpdate(props) {
    if (this.state.statement != props.statement) this.handleProgress();
  }

  showAddRowModal(show) {
    this.setState({ addRowModal: show })
  }

  // highlight
  toggleHighlighting = (e) => {
    console.log('e', e.target.value);
    if (this.state.q.length <= 0) {
      this.setState({ addRowModal: true, toggleHighlight: false })
    } else {
      e.target.value = false;
      this.setState({
        toggleHighlight: !this.state.toggleHighlight,
        highlightButtonText: this.state.toggleHighlight
          ? 'Fact Tagging' //disabled
          : 'Fact Tagging', //enabled
        // toggleHighlight: false,
      });
    }
    // console.log(e.target.checked);
  };

  handleHighlightShow = () => {
    this.setState({
      highlightShow: true,
      timelineShow: false,
      highlightedText: '',
      highlightedTextRef: '',
    });
  };
  handleHighlightClose = () => {
    this.setState({
      highlightShow: false,
      highlightedText: '',
      highlightedTextRef: '',
      multipleHighlights: [],
      highlightedImg: '',
      // toggleHighlight: false,
    });
  };

  createHighlight = (value) => {
    let highlightText = '';
    let highlightReference = '';
    if (this.state.toggleHighlight) {
      this.state.searchText = value.evidence;
    }
  };

  handleHighlightModal = () => {
    this.setState({ highlightShow: true });
  };

  selectionHandler(selection, row, evidence) {
    console.log("row selected: ", row)
    console.log("selection: ", selection)
    console.log("evidence: ", evidence)
    const rows = this.state.q;
    console.log("state.q: ", this.state.q)
    const selectedEvidence = evidence ? (evidence.includes('storage.googleapis.com/highlight-images') ? evidence : selection.text) : selection.text;
    console.log("selected evidence: ", selectedEvidence)

    const multipleSelection = selection.text
      .split('\n')
      .filter((element) => element != '' && element != 'Upload Document');
    // console.log("texts array: ", textsArray)
    // const multipleSelection = textsArray.map((el, i) => {
    //   if (el === 'Screenshot') {
    //     console.log("rows evidence: ", rows[i].evidence)
    //     return el = rows[i].evidence;
    //   } else {
    //     return el;
    //   }
    // })

    // console.log("adjusted: ", multipleSelection)
    let selectionArr = [];
    const existingSelection = [];

    // checks each row and matches with highlighted selection
    for (const row of rows) {
      for (const selectText of multipleSelection) {
        // if the row evidence includes the highlighted text and has not been used, add it to the final array
        if (
          row.evidence.includes(selectText.includes('aleri-prod-app-bucket') ? selectText.replace('aleri-prod-app-bucket','aleri-app-bucket') : selectText) &&
          !existingSelection.includes(selectText)
        ) {
            selectionArr.push({
              highlightedText: selectText,
              highlightedTextRef: row,
            });
            existingSelection.push(selectText);
          // break prevents row reference bugs to prevent existing rows to be called again once there is a match
          break;
        }
      }
    }
    // console.log("selection arry: ", selectionArr)
    // if selection arr has multiple text highlighted send arr
    if (selectionArr.length > 1) {
      this.setState({
        highlightShow: true,
        highlightedText: '',
        highlightedTextRef: '',
        multipleHighlights: selectionArr,
      });
    } else {
      // else use original setState with string and row
      this.setState({
        highlightShow: true,
        highlightedText: selectedEvidence,
        highlightedTextRef: row,
        multipleHighlights: [],
      });
    }
  }
  // timeline tagging
  toggleTimelineHighlighting = (e) => {
    // e.target.value = false;
    this.setState({
      toggleTimeline: !this.state.toggleTimeline,
      timelineButtonText: this.state.toggleTimeline
        ? 'Timeline Tagging' //disabled
        : 'Timeline Tagging', //enabled
    });
  };

  handleTimelineShow = () =>
    this.setState({
      timelineShow: true,
      highlightShow: false,
      highlightedText: '',
      highlightedTextRef: '',
    });
  handleTimelineClose = () => {
    this.setState({
      timelineShow: false,
      highlightedText: '',
      highlightedTextRef: '',
    });
  };

  selectionTimelineHandler(selection, row) {
    //do something with selection
    let selectionArr = [];
    this.setState({
      timelineShow: true,
      highlightedText: selection.text,
      highlightedTextRef: row,
    });
    // if selection arr has multiple text highlighted send arr
    if (selectionArr.length > 1) {
      this.setState({
        highlightShow: true,
        highlightedText: '',
        highlightedTextRef: '',
        multipleHighlights: selectionArr,
      });
    } else {
      // else use original setState with string and row
      this.setState({
        highlightShow: true,
        highlightedText: selection.text,
        highlightedTextRef: row,
        multipleHighlights: [],
      });
    }
  }

  componentWillReceiveProps(newProps) {
    //updates the row right away when the file and the summary sheet are both open on split tabs
    if (newProps.witness.statements !== this.props.witness.statements && this.state.addItem === false) {
      const items = [];

      axios
        .post(prod() + '/api/cases/get/witness/statement', {
          case: newProps.case._id,
          witness: newProps.witness['_id'],
          statement: newProps.statement._id,
        })
        .then((use) => {
          for (let topi of use.data.statements.statement) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,
              ref: topi.ref,
            });
          }
          const p = this.handleProgress(items);

          this.setState(
            {
              q: items,
              witness: newProps.witness,
              case: newProps.case,
              statement: newProps.statement,
              name: newProps.statement.name,
              progress: p,
              ref: {
                value: '',
                rows: 1,
              },
              evidence: '',
              reference: '',
            },
            () => {
              localStorage.setItem('q', JSON.stringify(this.state));
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }

    if (
      newProps.statement !== this.props.statement &&
      newProps.statement != ''
    ) {
      const items = [];

      axios
        .post(prod() + '/api/cases/get/witness/statement', {
          case: newProps.case._id,
          witness: newProps.witness['_id'],
          statement: newProps.statement._id,
        })
        .then((use) => {
          for (let topi of use.data.statements.statement) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,
              ref: topi.ref,
            });
          }
          const p = this.handleProgress();
          this.setState(
            {
              q: items,
              witness: newProps.witness,
              case: newProps.case,
              statement: newProps.statement,
              name: newProps.statement.name,
              ref: {
                value: '',
                rows: 1,
              },
              evidence: '',
              reference: '',
            },
            () => {
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  topic: newProps.statement,
                  path: '/case/witness/summary',
                  name: 'Summary',
                  active: true,
                });
              }
              localStorage.setItem('q', JSON.stringify(this.state));

              this.goToRef(2);
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }
  goToRef(a) {
    let reference = this.textInput3.current._ref;

    if (a == 1) {
      reference = this.textInput2.current._ref;
    } else if (a == 2) {
      reference = this.textInput3.current._ref;
    } else if (a == 3) {
      reference = this.textInput1.current._ref;
    }

    reference.focus();
  }
  handleSubmit = (data) => {
    data.preventDefault();
    axios
      .post(prod() + '/api/cases/update/witness/statement/name', {
        witness: this.state.witness['_id'],
        case: this.state.case._id,
        statement: this.state.statement._id,
        name: this.state.name,
        old: this.state.statement.name,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            for (
              let j = 0;
              j < use.data.case.witnesses[i].statements.length;
              j++
            ) {
              if (
                use.data.case.witnesses[i].statements[j]['_id'] ==
                this.state.statement['_id']
              ) {
                this.props.getT(use.data.case.witnesses[i]);
                this.props.getStatement(
                  use.data.case.witnesses[i].statements[j]
                );
                // this.props.history.push('/case/witness/statements');
                this.setState({
                  show: false,
                  name: this.props.statement.name,
                  statement: this.props.statement,
                });
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: 'Summary already exist',
        });
      });
    // }
  };
  // delete statement

  summaryEvidence = () => {
    this.setState({
      summaryEvidence: !this.state.summaryEvidence,
    });
  };

  summaryReference = () => {
    this.setState({
      summaryReference: !this.state.summaryReference,
    });
  };

  handleFTClose = () => this.setState({ dTShow: false });
  handleFTShow = (e) => this.setState({ dTShow: true, deletedT: e });
  handleFTSubmit = () => {
    axios
      .post(prod() + '/api/cases/delete/witness/statement', {
        case: this.state.case._id,
        witness: this.state.witness['_id'],
        statement: this.state.statement._id,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            this.props.getT(use.data.case.witnesses[i]);
            this.props.history.push('/case/witness/summaries');
          }
        }
      });
  };

  //
  // update statement
  //
  updateRows() {}
  handleChange = (i, event) => {
    const key = i;
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'reference') {
      this.setState((prev) => ({
        q: prev.q.map((el, index) => {
          return index == key
            ? { ...el, reference: value, ref: { ...el.ref, value } }
            : el;
        }),
      }));
    } else {
      this.setState((prev) => ({
        q: prev.q.map((el, index) =>
          index == key
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      }));
    }

    // el === key ? { ...el, status: 'done' } : el
  };
  handleUpdate = (event) => {
    // }
    if (event.target.name === 'reference') {
      this.setState({
        reference: event.target.value,
        ref: { value: event.target.value },
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  };

  componentDidMount() {
    console.log("Summary component did mount")
    logPageView();

    window.addEventListener('resize', this.updateRows);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.statement == undefined ||
      this.props.statement == null ||
      this.props.witness == undefined ||
      this.props.witness._id == undefined
    ) {
      this.setState({ redirect: true });
      // console.log(this.props);
    } else {
      const items = [];
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          summary: this.props.statement,
          path: '/case/witness/summary',
          name: 'Summary',
          active: true,
        });
      }
      axios
        .post(prod() + '/api/cases/get/witness/statement', {
          case: this.props.case._id,
          witness: this.props.witness['_id'],
          statement: this.props.statement._id,
        })
        .then((use) => {
          for (let topi of use.data.statements.statement) {
            items.push({
              reference: topi.reference,
              evidence: topi.evidence,
              _id: topi._id,

              ref: topi.ref,
            });
          }
          this.setState(
            {
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              statement: this.props.statement,
              name: this.props.statement.name,
            },
            () => {
              this.handleProgress();
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  deleteStatement = () => {
    if (this.state.deleted.ref && this.state.deleted.ref.document) {
      this.deleteDocs(this.state.q, this.state.statement, this.state.deleted);
    }

    axios
      .post(prod() + '/api/cases/delete/witness/statement/q', {
        case: this.state.case._id,
        witness: this.state.witness['_id'],
        statement: this.state.statement._id,
        d: this.state.deleted._id,
      })
      .then((use) => {
        const items = [];
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/get/witness/statement', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            statement: this.props.statement._id,
          })
          .then((use) => {
            for (let topi of use.data.statements.statement) {
              items.push({
                reference: topi.reference,
                evidence: topi.evidence,
                _id: topi._id,
                ref: topi.ref,
              });
            }
            this.setState({
              q: items,
              witness: this.state.witness,
              case: this.state.case,
              statement: use.data.statements,
              deleted: '',
              dShow: false,
            });
          })
          .catch((e) => {
            console.log(e);
          });
      });
  };

  addItem(item) {
    if (
      (this.state.reference != undefined && this.state.reference != '') ||
      (this.state.evidence != undefined && this.state.evidence != '')
    ) {
      this.setState({ addItem: true })

      axios
        .post(prod() + '/api/cases/create/witness/statement/q', {
          witness: this.props.witness._id,
          case: this.props.case._id,
          statement: this.props.statement._id,
          reference: this.state.reference,
          evidence: this.state.evidence,
          ref: this.state.ref,
        })
        .then((use) => {
          for (let a of use.data.case.witnesses) {
            if (a._id == this.props.witness['_id']) {
              this.props.updateWitness(use.data.case, a);
            }
          }
          const items = [];

          axios
            .post(prod() + '/api/cases/get/witness/statement', {
              case: this.props.case._id,
              witness: this.props.witness['_id'],
              statement: this.props.statement._id,
            })
            .then((use) => {
              for (let topi of use.data.statements.statement) {
                items.push({
                  reference: topi.reference,
                  evidence: topi.evidence,
                  _id: topi._id,

                  ref: topi.ref,
                });
              }

              this.setState({
                q: items,
                witness: this.props.witness,
                case: this.props.case,
                statement: use.data.statements,
                reference: '',
                evidence: '',
                delete: '',

                ref: {
                  value: '',
                  rows: 1,
                },
              }, () => {
                this.handleProgress();
              });
              this.goToRef(item);
            })
            .catch((e) => {
              console.log('ERROR', e);
              // alert('Did not save. Please go back or refresh and try again.');
            });
        });
    }
  }
  //saving texts on existing rows
  saveItem(value) {
    // console.log("save item")
    // LOOP FOR REMOVING EMPTY ELEMENT
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex((row) => row._id === value._id);
    if (value.ref.value === '' && value.evidence === '') {
      qDupe.splice(index, 1);
      this.setState({ q: qDupe }, () => this.handleProgress());
    } else {
      qDupe[index] = value;
      this.setState({ q: qDupe }, () => this.handleProgress());
    }

    axios
      .post(prod() + '/api/cases/update/witness/statement', {
        witness: this.state.witness['_id'],
        case: this.state.case._id,
        statement: this.state.statement._id,
        q: qDupe,
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
      })
      .catch((err) => {
        // alert('Did not save. Please go back or refresh and try again.');
      });
  }

  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, deleted: e });

  handleDeleteClose = () =>
    this.setState({ documentDeleteShow: false, deleted: null });

  handleDocumentDeleteShow = (doc) => {
    this.setState({ documentDeleteShow: true, deleted: doc });
  };

  handleDelete = () => {
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex(
      (row) => row._id === this.state.deleted._id
    );
    let row = qDupe.find((row) => {
      return row._id === this.state.deleted._id;
    });
    row = {
      _id: row._id,
      evidence: row.evidence,
      ref: { value: '' },
      reference: '',
    };

    qDupe[index] = row;

    this.deleteDocs(this.state.q, this.state.statement, this.state.deleted);

    this.setState({ q: qDupe, documentDeleteShow: false }, () => {
      this.saveItem(row);
    });
  };

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }

  deleteDocs = (rows, statement, deleted) => {
    let update = false;
    let rowId;
    let count = 0;

    for (let row of rows) {
      if (
        row.ref &&
        row.ref.document &&
        deleted.ref.document &&
        row._id === deleted._id &&
        row.ref.document._id == deleted.ref.document._id
      ) {
        count++;
        rowId = row._id;
        update = true;
      }
    }

    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: [rowId],
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })

        axios //delete highlights for that document
          .post(prod() + '/api/file/highlights/documents/deleteAll', {
            rowIds: [rowId],
          })
          .then((res) => console.log(res.data))
          .catch(e => console.log(e));
    
        if (count === 1 && update == true) {
          axios
            .post(prod() + '/api/documents/get', {
              document: deleted.ref.document._id,
            })
            .then((a) => {
              const doc = a.data;
    
              for (let i = 0; i < doc.locations.length; i++) {
                let sub = 0;
                if (
                  doc.locations[i].statement &&
                  doc.locations[i].statement.toString() == statement._id.toString()
                ) {
                  doc.locations.splice(i, 1);
                  axios
                    .post(prod() + '/api/documents/update/loc', {
                      case: this.props.case['_id'],
                      witness: this.props.witness['_id'],
                      document: doc._id,
                      loc: doc.locations,
                    })
                    .then((res) => {
                      // console.log('updated location: ', res.data);
                    })
                    .catch((e) => {
                      console.log('Invalid Input, Please Try Again', e);
                    });
                } else {
                  sub++;
                }
              }
            })
            .catch((e) => {
              console.log('ERROR', e);
            });
        }
      })
      .catch(e => console.log(e));
  };

  // Syncs the backend with the order change

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ q }) => ({
      q: arrayMove(q, oldIndex, newIndex),
      dragging: false,
    }));

    axios
      .post(prod() + '/api/cases/update/witness/statement', {
        witness: this.state.witness['_id'],
        case: this.state.case._id,
        statement: this.state.statement._id,
        q: this.state.q,
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
      })
      .catch((err) => {
        console.err('Summary sort backend sync failed');
        console.log(err);
      });
  };

  DragHandle = sortableHandle(() => <span className='drag'></span>);

  SortableItem = sortableElement(({ value, i }) => {
    // console.log("VALUE: ", value)
    // console.log("INDEX: ", i)
    let statement = {};

    statement = (
      <SummaryRow
        value={value}
        index={i}
        saveItem={this.saveItem}
        darkMode={this.props.darkMode}
        q={this.state.q.length}
        handleDocumentDeleteShow={this.handleDocumentDeleteShow}
        statement={this.props.statement}
        handleDShow={this.handleDShow}
        addRef={this.addRef}
        textInput1={this.textInput1}
        textInput2={this.textInput2}
        statement={this.state.statement._id}
        uploadDocModal={this.state.uploadDocModal}
        divElement={this.divElement}
        linkRef={this.linkRef}
        addRef={this.addRef}
        createLink={this.createLink}
        handleCreateClose={this.openUploadDocModal}
        linkErr={this.state.linkErr}
        darkMode={this.props.darkMode}
        witness={this.props.witness}
        case={this.props.case}
        addExistingDoc={this.addExistingDoc}
        addExistingLink={this.addExistingLink}
        addLink={this.addLink}
        addLinkState={this.state.addLinkState}
        handleAddClose={this.handleAddClose}
        showDelete={this.state.documentDeleteShow}
        handleDelete={this.handleDelete}
        handleDeleteClose={this.handleDeleteClose}
        addExistingDocOnRow={this.addExistingDocOnRow}
        toggleHighlight={this.state.toggleHighlight}
        selectionHandler={this.selectionHandler}
        docs={this.state.docs}
        createHighlightsToRows={this.createHighlightsToRows}
        addHighlightToRow={this.addHighlightToRow}
        addHighlightsToRows={this.addHighlightsToRows}
        toggleTimeline={this.state.toggleTimeline}
        selectionTimelineHandler={this.selectionTimelineHandler}
        summary={true}
        saveHighlights={this.saveHighlights}
        createPDF={this.createPDF}
        handleImageHighlightShow={this.handleImageHighlightShow}
      />
    );

    return statement;
  });

  SortableContainer = sortableContainer(({ children }) => {
    return children;
  });

  handleImageHighlightShow = (imgLink, row) => {
    console.log("ref: ", row)
    this.setState({
      highlightShow: true,
      timelineShow: false,
      highlightedText: imgLink,
      highlightedTextRef: row,
    });
  }

  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open });
  }

  openUploadDocModalE(open) {
    this.setState({ uploadDocModalE: open });
  }
  // add highlights after the row is saved
  //   after refId, and document Id are present, add the highlights to the db
  componentDidUpdate(prevProps, prevState) {
    const url = prod() + '/api/file/highlights/add-highlights';
    const { highlights, link } = this.state;

    if (prevState.q !== this.state.q) {
      if (this.state.rowId || this.state.link || this.state.highlights) {
        if (this.state.rowId) { // previewed document gets saved from existing row
          axios
            .post(url, { refId: this.state.rowId, highlights, link })
            .then((res) => {
              this.setState({ highlights: [], link: '', rowId: '' });
            })
            .catch(e => console.log(e));
        } else if (this.state.addExistingDoc) {
          const refId = this.state.q[this.state.q.length - 1]._id;
          this.createPDF(refId, link);
        } else if (this.state.q.length > 0 && this.state.link !== '') {
          // previewed document gets saved from new row
          const refId = this.state.q[this.state.q.length - 1]._id;
          this.saveHighlightsToPDF(highlights, refId, link)
          // axios.post(url, { refId, highlights, link }).then((res) => {
          //   this.setState({ highlights: [], link: '' });
          // })
          // .catch(e => console.log(e));
        }
      }
    }
  }
  saveHighlights(highlights, link, rowId) {
    if (rowId) {
      this.setState({ highlights, link, rowId });
    } else {
      this.setState({ highlights, link });
    }
  }

  async saveHighlightsToPDF(highlights, rowId, link) {
    const updatedHighlights = await this.uploadImagesToGCS(highlights);
    console.log("new highlights!!: ", updatedHighlights)

    axios.post(prod() + '/api/file/highlights/add-highlights', {
      refId: rowId,
      highlights: updatedHighlights,
      link,
    }).then(res => {
      console.log(res.data)
      this.setState({ highlights: [], link: '' });
    }).catch(e => console.log(e))
  }

  //uploads image highlight to gcs
  uploadFile = async (image) => {
    const base64Text = image.toString().substring(22, image.length);
    const url = prod() + '/api/cases/upload/highlightImage';

    return await axios
      .post(url, { imageData: base64Text })
      .then((res) => {
        console.log("highlight image uploaded: ", res.data.gcImageData)
        // const gcImageData = res.data.gcImageData;
        return res.data.gcImageData;
      })
      .catch(e => console.log(e));
  }

  //with the array of highlights, upload image highlights and replace image base64 string with gcs link
  uploadImagesToGCS = async (highlights) => {
    const newHighlights = [];
    await Promise.all(highlights.map(async (h) => {
      try {
        if (h.content.image) {
          const gcImageData = await this.uploadFile(h.content.image);
          h.content.image = gcImageData;
          newHighlights.push(h); 
        } else {
          newHighlights.push(h);
        }
      } catch(error) { 
        console.log(error)
      }
    }))
    // highlights.forEach(async(h) => {
    //   if (h.content.image) {
    //     const gcImageData = await this.uploadFile(h.content.image);
    //     h.content.image = gcImageData;
    //     newHighlights.push(h);
    //   } else {
    //     newHighlights.push(h);
    //   }
    // })
    return newHighlights;
  };


  // only used in LinkDocument.js
  // initiated by Summarize & Upload button in FilePreviewModal .js
  async createHighlightsToRows(highlights, data) {
    const rows = [];
    //upload image highlights to gcs and return a new Highlights array
    const updatedHighlights = await this.uploadImagesToGCS(highlights);
    console.log("new highlights!!: ", updatedHighlights)
    // let index = '';
    // if (data.rowId) {
    //   index = this.state.q.findIndex((row) => row._id === data.rowId); 
    // };

    // make rows with reference and evidence values
    for (const highlight of updatedHighlights) {
      rows.push({
        reference: `${data.linkName} - page ${highlight.position.pageNumber}`,
        evidence: highlight.content.text ? highlight.content.text : highlight.content.image.file,
      });
    }
    const rowsLength = rows.length;
    console.log("Rows made: ", rows);

    axios.get(prod() + '/api/users/current').then((s) => {
      axios //update document location
        .post(prod() + '/api/documents/create', {
          name: data.linkName,
          link: data.linkLocation,
          case: this.props.case['_id'],
          user: s.data.id,
          firm: s.data.firm,
          witness: this.props.witness['_id'],
          type: data.type,
          locations: [
            {
              case: this.props.case,
              witness: this.props.witness,
              statement: this.props.statement,
              path: '/case/witness/summary',
              name: this.props.statement.name,
            },
          ],
        })

        .then((use) => {
          this.setState({ docs: use.data.documents });

          axios //route that adds multiple rows at the same time
            .post(prod() + '/api/cases/create/witness/statement/rows', {
              witness: this.props.witness._id,
              case: this.props.case._id,
              statement: this.props.statement._id,
              referenceLink: use.data.documents[use.data.documents.length - 1],
              rows: rows, //array of ref & evidence objects
            })
            .then((use) => {
              for (let a of use.data.case.witnesses) {
                if (a._id == this.props.witness['_id']) {
                  this.props.updateWitness(use.data.case, a);
                }
              }
              const items = [];

              axios
                .post(prod() + '/api/cases/get/witness/statement', {
                  case: this.props.case._id,
                  witness: this.props.witness['_id'],
                  statement: this.props.statement._id,
                })
                .then((use) => {

                  const statement = use.data.statements.statement;
                  //get the rowIds of the newly created rows
                  const rowIds = [];
                  for (
                    let i = statement.length - 1;
                    i >= statement.length - rowsLength;
                    i--
                  ) {
                    rowIds.push(statement[i]._id);
                  }
                  axios
                    .post(
                      prod() + '/api/file/highlights/documents/addHighlights',
                      { rowIds: rowIds.reverse(), highlights: updatedHighlights, link: data.linkLocation }
                    )
                    .then((res) => {
                      //update q
                      for (let topi of use.data.statements.statement) {
                        items.push({
                          reference: topi.reference,
                          evidence: topi.evidence,
                          _id: topi._id,
                          ref: topi.ref,
                        });
                      }
                      const p = this.handleProgress(items);
                      this.setState({
                        q: items,
                        witness: this.props.witness,
                        case: this.props.case,
                        statement: use.data.statements,
                        reference: '',
                        evidence: '',
                        delete: '',
                        progress: p,
                        linkErr: '',
                        ref: {
                          value: '',
                          rows: 1,
                        },
                        uploadDocModal: false,
                        highlights: [],
                      });
                    });
                })
                .catch((e) => {
                  console.log('ERROR', e);
                });
            });
        })
        .catch((e) => {
          console.log(e);
          this.setState({ linkErr: 'Invalid Input. Please Try Again' });
        });
    });
  }

  addHighlightsToRows(highlights, data) {
    const index = this.state.q.findIndex((row) => row._id === data.rowId);
    const row = this.state.q.find((row) => row._id === data.rowId);
    const rows = [];
    for (const highlight of highlights) {
      rows.push({
        reference: `${data.linkName} - page ${highlight.position.pageNumber}`,
        evidence: highlight.content.text ? highlight.content.text : highlight.content.image.file,
      });
    }
    const rowsLength = rows.length;

    axios
      .post(prod() + '/api/documents/get', { document: row.ref.document._id })
      .then((res) => {
        const docInfo = res.data;
        // console.log("doc info: ", res.data)

        axios //route that adds multiple rows at the same time
          .post(prod() + '/api/cases/add/witness/statement/rows', {
            witness: this.props.witness._id,
            case: this.props.case._id,
            statement: this.props.statement._id,
            referenceLink: docInfo,
            rows: rows,
            rowIndex: index + 1,
            rowsLength: rowsLength,
          })
          .then((use) => {
            console.log("Case after updating rows: ", use);
            for (let a of use.data.case.witnesses) {
              if (a._id == this.props.witness['_id']) {
                this.props.updateWitness(use.data.case, a);
              }
            }
            const items = [];

            axios
              .post(prod() + '/api/cases/get/witness/statement', {
                case: this.props.case._id,
                witness: this.props.witness['_id'],
                statement: this.props.statement._id,
              })
              .then((use) => {
                console.log("updatedn rows: ", use.data.statements.statement);
                const statement = use.data.statements.statement;
                const rowIds = [];
                for (let i = index + 1; i <= index + rowsLength; i++) {
                  rowIds.push(statement[i]._id);
                }
                //get the rowId of the newly created row
                axios
                  .post(
                    prod() + '/api/file/highlights/documents/addHighlights',
                    {
                      rowIds: rowIds,
                      highlights,
                      link: data.linkLocation,
                    }
                  )
                  .then((res) => {
                    // console.log(res.data);
                    //update q
                    for (let topi of use.data.statements.statement) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,
                        ref: topi.ref,
                      });
                    }
                    const p = this.handleProgress(items);
                    this.setState({
                      q: items,
                      witness: this.props.witness,
                      case: this.props.case,
                      statement: use.data.statements,
                      reference: '',
                      evidence: '',
                      delete: '',
                      progress: p,
                      linkErr: '',
                      ref: {
                        value: '',
                        rows: 1,
                      },
                      uploadDocModal: false,
                      highlights: [],
                    });
                  });
              })
              .catch((e) => {
                console.log('ERROR', e);
              });
          });
      });
  }

  //adding one row with the highlighted text in evidence, and document in reference. The document in the newly created row will have one highlight that is referenced and it'll have been pinned
  addHighlightToRow(highlight, data) {
    const index = this.state.q.findIndex((row) => row._id === data.rowId);
    const row = this.state.q.find((row) => row._id === data.rowId);

    // make row with reference and evidence values
    const newRow = {
      reference: `${data.linkName} - page ${highlight.position.pageNumber}`,
      evidence: highlight.content.text ? highlight.content.text : highlight.content.image.file,
    };
    // console.log("new row: ", newRow)

    axios
      .post(prod() + '/api/documents/get', { document: row.ref.document._id })
      .then((res) => {
        const docInfo = res.data;

        axios //route that add one row below the row that has the document
          .post(prod() + '/api/cases/add/witness/statement/row', {
            witness: this.props.witness._id,
            case: this.props.case._id,
            statement: this.props.statement._id,
            referenceLink: docInfo,
            row: newRow,
            rowIndex: index + 1,
          })
          .then((use) => {
            // console.log('Case after updating rows: ', use.data.case.witnesses[0].statements[0].statement);
            for (let a of use.data.case.witnesses) {
              if (a._id == this.props.witness['_id']) {
                this.props.updateWitness(use.data.case, a);
              }
            }
            const items = [];

            axios
              .post(prod() + '/api/cases/get/witness/statement', {
                case: this.props.case._id,
                witness: this.props.witness['_id'],
                statement: this.props.statement._id,
              })
              .then((use) => {
                // console.log('UPDATED ROWS', use.data.statements);
                const statement = use.data.statements.statement;
                const rowId = statement[index + 1]._id;
                //get the rowId of the newly created row
                axios // save the highlight with the newly created row's id and pin
                  .post(
                    prod() + '/api/file/highlights/documents/addHighlights',
                    {
                      rowIds: [rowId],
                      highlights: [highlight],
                      link: data.linkLocation,
                    }
                  )
                  .then((res) => {
                    // console.log(res.data);
                    //update q
                    for (let topi of use.data.statements.statement) {
                      items.push({
                        reference: topi.reference,
                        evidence: topi.evidence,
                        _id: topi._id,
                        ref: topi.ref,
                      });
                    }
                    const p = this.handleProgress(items);
                    this.setState({
                      q: items,
                      witness: this.props.witness,
                      case: this.props.case,
                      statement: use.data.statements,
                      reference: '',
                      evidence: '',
                      delete: '',
                      progress: p,
                      linkErr: '',
                      ref: {
                        value: '',
                        rows: 1,
                      },
                      uploadDocModal: false,
                      highlights: [],
                    });
                  });
              })
              .catch((e) => {
                console.log('ERROR', e);
              });
          });
      });
  }

  //create pdf table with PDF schema when a document is added to a row
  createPDF(rowId, link) {
    axios
      .post(prod() + '/api/file/highlights/create', { refId: rowId, link })
      .then((res) => {
        this.setState({ rowId: '', link: '', addExistingDoc: false });
      })
      .catch(e => console.log(e))
  }

  saveLinkToCreatePDF(link) {
    this.setState({ link, addExistingDoc: true });
  }

  render() {
    console.log("rows: ", this.state.q)

    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash'>
        {' '}
        <Container fluid className='scope'>
          <Row id='dash-add'>
            <Col md={6}>
              <h1 className='topic-title w-name'>
                Summary: <span>{this.state.statement.name} </span>
              </h1>
              <ProgressBar now={Math.round(this.state.progress)} />
            </Col>
            <Col md={6}>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>

              <div className='add-btn add-btn-p-top topic-btn'>
                <ButtonToolbar>
                  <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                      <Tooltip
                        id={
                          this.props.darkMode
                            ? 'dark-tooltip-add'
                            : 'tooltip-add'
                        }
                      >
                        Highlight text in evidence column to create tags and
                        send to the facts by topic sheets
                      </Tooltip>
                    }
                  >
                    <Form className='highlight-switch'>
                      <Form.Check
                        type='switch'
                        id='highlight-switch'
                        label={this.state.highlightButtonText}
                        onChange={(e) => this.toggleHighlighting(e)}
                        // defaultValue={this.state.toggleHighlight}
                        checked={this.state.toggleHighlight}
                        value={this.state.toggleHighlight}
                        defaultChecked={this.state.toggleHighlight}
                        defaultValue={this.state.toggleHighlight}
                        ref={this.state.toggleHighlight}
                      />
                    </Form>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </Col>
          </Row>

          <div className='cases'>
            <Form id='statement-form'>
              <div ref={this.divElement2} className='d-none d-md-block'>
                <Fade>
                  <Row className='titles'>
                    <Col md={4}>
                      <Row>
                        <div className='hov-container'>
                          <h3>References</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.summaryReference}
                          ></img>
                        </div>
                      </Row>
                    </Col>
                    <Col md={7}>
                      <Row>
                        <div className='hov-container'>
                          <h3>Evidence</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.summaryEvidence}
                          ></img>
                        </div>
                      </Row>
                    </Col>
                    <Col md={1}></Col>
                  </Row>
                </Fade>
              </div>
              <SummaryModal
                summaryEvi={this.state.summaryEvidence}
                hideSummaryEvi={this.summaryEvidence}
                summaryRef={this.state.summaryReference}
                hideSummaryRef={this.summaryReference}
                handleFTSubmit={this.handleFTSubmit}
                handleFTClose={this.handleFTClose}
                dTShow={this.state.dTShow}
                deleteStatement={this.deleteStatement}
                handleDClose={this.handleDClose}
                dShow={this.state.dShow}
                darkMode={this.props.darkMode}
                handleSubmit={this.handleSubmit}
                handleClose={this.handleClose}
                user={this.state.user}
                show={this.state.show}
                handleHClose={this.handleHClose}
                hShow={this.state.hShow}
                handleEClose={this.handleEClose}
                statement={this.state.statement}
                q={this.state.q}
                eShow={this.state.eShow}
                showAddRowModal={this.showAddRowModal}
                addRowModal={this.state.addRowModal}
              />
              <LinkDocument
                uploadDocModal={this.state.uploadDocModal}
                linkRef={this.linkRef}
                addRef={this.addRef}
                createLink={this.createLink}
                openUploadDocModal={this.openUploadDocModal}
                linkErr={this.state.linkErr}
                darkMode={this.props.darkMode}
                witness={this.props.witness}
                case={this.props.case}
                addExistingDoc={this.addExistingDoc}
                // addExistingLink={this.addExistingLink}
                addLinkState={this.state.addLinkState}
                handleAddClose={this.handleAddClose}
                showDelete={this.state.documentDeleteShow}
                handleDelete={this.handleDelete}
                handleDeleteClose={this.handleDeleteClose}
                saveHighlights={this.saveHighlights}
                docs={this.state.docs}
                createHighlightsToRows={this.createHighlightsToRows}
                summary={true}
                saveLinkToCreatePDF={this.saveLinkToCreatePDF}
              />

              <SummaryHighlightModal
                highlightShow = {this.state.highlightShow}
                summaryEvi={this.state.summaryEvidence}
                hideSummaryEvi={this.summaryEvidence}
                summaryRef={this.state.summaryReference}
                hideSummaryRef={this.summaryReference}
                handleFTSubmit={this.handleFTSubmit}
                handleFTClose={this.handleFTClose}
                dTShow={this.state.dTShow}
                deleteStatement={this.deleteStatement}
                handleDClose={this.handleDClose}
                dShow={this.state.dShow}
                darkMode={this.props.darkMode}
                handleSubmit={this.handleSubmit}
                handleClose={this.handleClose}
                user={this.state.user}
                show={this.state.show}
                handleEClose={this.handleEClose}
                statement={this.state.statement}
                q={this.state.q}
                eShow={this.state.eShow}
                highlightShow={this.state.highlightShow}
                handleHighlightClose={this.handleHighlightClose}
                case={this.props.case}
                witness={this.props.witness}
                highlightedText={this.state.highlightedText}
                highlightedTextRef={this.state.highlightedTextRef}
                multipleHighlights={this.state.multipleHighlights}
                highlightedImg={this.state.highlightedImg}
              />

              {this.state.timelineShow && (
                <SummaryTimelineModal
                  darkMode={this.props.darkMode}
                  // handleSubmit={this.handleSubmit}
                  // handleClose={this.handleClose}
                  user={this.state.user}
                  show={this.state.show}
                  handleEClose={this.handleEClose}
                  statement={this.state.statement}
                  q={this.state.q}
                  eShow={this.state.eShow}
                  timelineShow={this.state.timelineShow}
                  handleTimelineClose={this.handleTimelineClose}
                  case={this.props.case}
                  witness={this.props.witness}
                  highlightedText={this.state.highlightedText}
                  highlightedTextRef={this.state.highlightedTextRef}
                />
              )}

              <Fade>
                <div
                  className={this.state.dragging ? 'cases-dragging' : ''}
                ></div>
                <div>
                  <this.SortableContainer
                    onSortEnd={this.onSortEnd}
                    onSortStart={this.onDragStart}
                    useDragHandle
                  >
                    <div>
                      {this.state.q.map((value, index) => (
                        <this.SortableItem
                          key={`statement-dnd--${index}`}
                          index={index}
                          i={index}
                          value={value}
                        />
                      ))}
                    </div>
                  </this.SortableContainer>
                  <div
                    className={
                      this.props.darkMode == true
                        ? ' d-block d-md-none underline-t dark'
                        : ' d-block d-md-none underline-t dark'
                    }
                  >
                    <Row className='unsaved-input'>
                      <h2>Create New</h2>
                      <h3 className='mobile-title'>Reference</h3>
                      <Col md={4} className='ref-mobile'>
                        <Form.Group controlId='formBasicEmail'>
                          {/* <Form.Control */}
                          <TextareaAutosize
                            // as='textarea'
                            placeholder='Enter Reference'
                            onBlur={() => this.addItem(1)}
                            // rows={this.state.reference.rows}
                            value={this.state.reference}
                            onChange={this.handleUpdate}
                            name='reference'
                          />
                        </Form.Group>
                      </Col>
                      <h3 className='mobile-title'>Evidence</h3>
                      <Col md={7} className='dic-mobile'>
                        <Form.Group controlId='formBasicEmail'>
                          {/* <Form.Control */}
                          <TextareaAutosize
                            // as='textarea'
                            placeholder='Enter Evidence Here'
                            onBlur={() => this.addItem(2)}
                            rows={this.state.evidence.rows}
                            // value={this.state.evidence}

                            onChange={this.handleUpdate}
                            name='evidence'
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>

                  <NewRow
                    handleUpdate={this.handleUpdate}
                    textInput3={this.textInput3}
                    col1Value={this.state.reference}
                    col1Name='reference'
                    addItem={this.addItem}
                    openUploadDocModal={this.openUploadDocModal}
                    col2Placeholder={'Enter Evidence Here'}
                    col2Value={this.state.evidence}
                    col2Name='evidence'
                  />
                </div>
              </Fade>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Summary);
