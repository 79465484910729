import React, { Component } from 'react';
import {
  Button,
  Tab,
  Row,
  Col,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  ProgressBar,
} from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../../prod';

import FactModal from './FactModal';
import Fade from 'react-reveal/Fade';

import Icons from '../../Navigation/Icons';
import ToggleNav from '../../Navigation/ToggleNav';
class FactDashboard extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      case: {},
      witness: {},
      facts: [],
      //  create case
      wShow: false,
      // delete witness
      wDShow: false,
      // update witness

      wSShow: false,
      uWName: '',
      hShow: false,
    };
    this.handleWShow = this.handleWShow.bind(this);
    this.handleWClose = this.handleWClose.bind(this);
    this.handleWDShow = this.handleWDShow.bind(this);
    this.handleWDClose = this.handleWDClose.bind(this);
    this.handleWSubmit = this.handleWSubmit.bind(this);
    this.deleteFact = this.deleteFact.bind(this);

    this.handleWSShow = this.handleWSShow.bind(this);
    this.handleWSClose = this.handleWSClose.bind(this);
    this.handleWSSubmit = this.handleWSSubmit.bind(this);
    this.getFact = this.getFact.bind(this);
    this.getFactS = this.getFactS.bind(this);

    this.editRef = React.createRef();
  }
  handleProgress = () => {
    let p = 0;
    const n = this.state;
    let num = 0;
    let v = 0;
    let amount = 0;
    for (let i of n.facts) {
      if (i.fact.length > 0) v += 1;
      num += 1;
    }
    num += 1;
    amount = 100 / num;
    if (n.facts && n.facts.length > 0) {
      p += amount;
    }
    p += amount * v;
    if (p != this.state.progress) this.setState({ progress: p });
  };
  getFactS(a) {
    this.props.getFact(a);
  }
  getFact() {
    //  this.props.getFact(a);
    this.props.setNavExpanded();
  }
  componentDidMount() {
    axios
      .post(prod() + '/api/cases/getall/witness/fact', {
        case: this.props.case['_id'],
        witnesses: this.props.witness['_id'],
      })
      .then((use) => {
        this.setState(
          {
            fact: this.props.fact,
            facts: use.data.facts,
            witness: this.props.witness,
            case: this.props.case,
            name: this.props.fact.name,
          },
          () => {
            if (this.state.facts) {
              this.handleProgress();
            }
          }
        );
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  }
  componentWillReceiveProps(newProps) {
    if (this.props.fact != newProps) {
      axios
        .post(prod() + '/api/cases/getall/witness/fact', {
          case: newProps.case['_id'],
          witnesses: newProps.witness['_id'],
        })
        .then((use) => {
          this.setState(
            {
              fact: newProps.fact,
              facts: use.data.facts,
              witness: newProps.witness,
              case: newProps.case,
              name: newProps.fact.name,
            },
            () => {
              this.handleProgress();
            }
          );
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.case !== this.props.case) {
      this.setState(
        {
          case: this.props.case,
          witness: this.props.witness,
        },
        () => {
          this.handleProgress();
        }
      );
    }
  }
  handleWSSubmit(data, e) {
    e.preventDefault();

    axios
      .post(prod() + '/api/cases/update/witness/fact/name', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        fact: this.state.fact['_id'],
        name: data.name,
        old: this.state.fact.name,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].facts.length; j++) {
              if (
                use.data.case.witnesses[i].facts[j]['_id'] ==
                this.state.fact['_id']
              ) {
                this.setState(
                  {
                    fact: this.props.fact,
                    facts: use.data.case.witnesses[i].facts,
                    witness: this.props.witness,
                    case: this.props.case,
                    name: this.props.fact.name,
                    wShow: false,
                    // delete witness
                    wDShow: false,
                    // update witness

                    wSShow: false,
                  },
                  () => {
                    this.props.getFact(use.data.case.witnesses[i].facts[j]);
                  }
                );

                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: 'Fact already exist',
        });
      });
    // }
  }

  handleWSubmit(i, e) {
    e.preventDefault();
    const witnesses = {
      name: i.name,
      user: this.state.use,
      case: this.state.case._id,
    };
    if (
      witnesses.name != '' &&
      witnesses.name != undefined &&
      witnesses.case != {}
    ) {
      axios
        .post(prod() + '/api/cases/create/witness', witnesses)
        .then((use) => {
          this.setState({
            wShow: false,
          });
          this.props.updateCase();
        })
        .catch((err) => {
          this.setState({
            user: 'Case already exist',
          });
        });
    } else {
      this.setState({
        user: 'Please fill out witness name field',
      });
    }
  }
  handleWSClose = () => this.setState({ wSShow: false });
  handleWSShow = () => {
    this.setState(
      {
        wSShow: true,
        uWName: this.state.fact.name,
        updating: this.state.witness['_id'],
        old: this.state.witness['name'],
      },
      () => this.editRef.current.focus()
    );
  };

  handleWClose = () => this.setState({ wShow: false });
  handleWShow = () => this.setState({ wShow: true });
  deleteFact(a) {
    axios
      .post(prod() + '/api/cases/delete/witness/fact', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        fact: this.state.fact['_id'],
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            this.setState(
              {
                wShow: false,
                // delete witness
                wDShow: false,
                // update witness

                wSShow: false,
              },
              () => {
                this.props.updateFromFocus(
                  use.data.case,
                  use.data.case.witnesses[i]
                );
                this.props.goFacts();
              }
            );

            break;
          }
        }
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleWDShow = (e, ev) => {
    ev.stopPropagation();

    this.setState({ wDShow: true, deleted: e });
  };
  handleWDClose = () => {
    this.setState({ wDShow: false });
  };
  render() {
    const list = [];
    if (this.state.facts && this.state.facts.length > 0) {
      for (let i = 0; i < this.state.facts.length; i++) {
        list.push(
          <Col md={12}>
            <Fade>
              <div onClick={() => this.getFactS(this.state.facts[i])}>
                <ListGroup.Item className='parent-side-nav untouch'>
                  <div className='icon-img'>
                    <img src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/footprint_hafmha.svg'></img>
                    <p className='wit-title wit-title-z'>
                      {this.state.facts[i].name}
                    </p>
                    <img src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'></img>
                  </div>
                </ListGroup.Item>
              </div>
            </Fade>
          </Col>
        );
      }
    }

    return (
      <>
        <Icons
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          changeLocation={this.props.changeLocation}
          getWitness={this.props.getWitness}
          cases={this.props.cases}
          setNavExpanded={this.props.setNavExpanded}
          goInfo={this.props.goInfo}
          goHome={this.props.goHome}
          goSettings={this.props.goSettings}
          loadOut={this.props.loadOut}
          goActivity={this.props.goActivity}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.props.goAudioTranscription}
        />
        <div className='expanded'>
          <div id='side-nav-open' className='side-nav-open-w'>
            <div className='side-nav-open-title side-nav-open-title-w'>
              <Row>
                <Col md={12}>
                  <Fade>
                    <h5 id='case'>Facts</h5>
                  </Fade>
                </Col>
              </Row>

              <br />
              <div>
                <Fade>
                  <div className='w-buttons'>
                    <Row>
                      <Col md={4}>
                        {' '}
                        <div className='add-btn-plus add-btn-plus1'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Witness Dashboard
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={this.props.goFacts}
                                  className='add-case'
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/left-arrow_w86mdw.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus2'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Settings
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className='add-case'
                                  onClick={(e) =>
                                    this.handleWSShow(this.state.case['_id'], e)
                                  }
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus3'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={(e) =>
                                    this.handleWDShow(this.state.case['_id'], e)
                                  }
                                  className='add-case'
                                >
                                  <img
                                    className='logo-title logo-title-l'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Fade>
                <Col sm={{ size: 10 }}>
                  <ProgressBar now={this.state.progress} />
                </Col>
                <Fade>
                  <h6 className='w-name'>
                    <span>Case: </span>
                    {this.state.case.name}
                  </h6>
                  <h6 className='w-name'>
                    <span>Client:</span> {this.state.case.client}
                  </h6>
                  <h6 className='w-name'>
                    <span>Witness:</span> {this.state.witness.name}
                  </h6>
                </Fade>
              </div>
              <Fade>
                <div>{list}</div>
              </Fade>
            </div>
          </div>

          <div id='side-nav-open-mobile'>
            <div className='side-nav-open-title side-nav-open-title-w'>
              <Fade>
                <h3>Facts</h3>
              </Fade>
              <Fade>
                <p className='numberOfItems'></p>
                <div className='add-btn-plus'></div>
              </Fade>

              <br />
              <div>
                <Fade>
                  <div className='w-buttons'>
                    <div className='add-btn-plus add-btn-plus3'>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Delete
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={(e) =>
                                this.handleWDShow(this.state.case['_id'], e)
                              }
                              className='add-case'
                            >
                              <img
                                className='logo-title logo-title-l'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </div>

                    <div className='add-btn-plus add-btn-plus2'>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Settings
                              </Tooltip>
                            }
                          >
                            <Button
                              className='add-case'
                              onClick={(e) =>
                                this.handleWSShow(this.state.case['_id'], e)
                              }
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </div>
                    <div className='add-btn-plus add-btn-plus1'>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Case Dashboard
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.props.goFacts}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/left-arrow_w86mdw.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </div>
                </Fade>

                <Fade>
                  <h6 className='w-name'>
                    <span>Case: </span>
                    {this.state.case.name}
                  </h6>
                  <h6 className='w-name'>
                    <span>Client:</span> {this.state.case.client}
                  </h6>
                  <h6 className='w-name'>
                    <span>Witness:</span> {this.state.witness.name}
                  </h6>
                </Fade>
              </div>
            </div>
            <Fade>
              <div>{list}</div>
            </Fade>
          </div>
        </div>
        <FactModal
          wShow={this.state.wShow}
          wSShow={this.state.wSShow}
          handleWClose={this.handleWClose}
          handleWSubmit={this.handleWSubmit}
          wDShow={this.state.wDShow}
          handleWDClose={this.handleWDClose}
          handleWDSubmit={this.handleWDSubmit}
          sWShow={this.state.sWShow}
          handleWSClose={this.handleWSClose}
          handleWSSubmit={this.handleWSSubmit}
          user={this.state.user}
          err={this.state.err}
          uName={this.state.uName}
          uClient={this.state.uClient}
          deleteWitness={this.deleteFact}
          //  update wit
          uWName={this.state.uWName}
          edit={this.editRef}
          darkMode={this.props.darkMode}
        />
        <ToggleNav setNavExpanded={this.props.setNavExpanded} />
      </>
    );
  }
}
export default withRouter(FactDashboard);
