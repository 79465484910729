import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SummaryExport from './SummaryExport';
export default class SummaryModal extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uName: '',
      summaryName: '',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    this.props.handleWSubmit({ name: this.state.wName }, e);
    this.setState({ wName: '' });
  }
  componentDidMount() {
    this.setState({
      uName: this.props.uWName,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.uWName !== this.props.uWName) {
      this.setState({
        uName: this.props.uWName,
      });
    }
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wSShow}
          onHide={this.props.handleWSClose}
        >
          <Form
            onSubmit={(e) =>
              this.props.handleWSSubmit({ name: this.state.uName }, e)
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.errors && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors}
                </div>
              )}
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Summary Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.props.edit}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.props.handleWSClose}
              >
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wDShow}
          onHide={this.props.handleWDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this summary?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleWDClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteWitness}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/*  */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this Row?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteStatement}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Summary Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SummaryExport
                statement={this.props.statement}
                q={this.props.q}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClos();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What are Statement Summaries</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  In cases where there are lengthy pre-trial statements or
                  transcripts of evidence, it will be important to be able to
                  find the relevant portions of these statements quickly. The
                  statement summary forms allow you to summarize pre-trial
                  statements in a way you can easily find the relevant portions.
                  These summaries can them be used in preparing the questions;
                  they can also be used during the trial to compare the
                  witnesses trial evidence with the prior statements. These
                  statements can be exported and printed as PDF files.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/settings/support'>
                <Button variant='outline-secondary'>Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Update Summary</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {this.props.user && (
              <div className='invalid-feedback invalid-feedback-c'>
                {this.props.user}
              </div>
            )}
            <Form.Group controlId='caseName'>
              <Form.Label>Summary Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Summary Name'
                value={this.state.summaryName}
                onChange={this.onChange}
                name='summaryName'
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleClose}>
              Close
            </Button>
            <Button
              variant='primary'
              type='submit'
              onClick={(e) =>
                this.props.handleSubmit(e, this.state.summaryName)
              }
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this summary?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteStatement}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dTShow}
          onHide={this.props.handleFTClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this summary?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleFTClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleFTSubmit}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.summaryRef}
          onHide={this.props.hideSummaryRef}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideSummaryRef();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>References</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Description of the statement with page and line reference.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.summaryEvi}
          onHide={this.props.hideSummaryEvi}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.HideSummaryEv();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Evidence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Summarized version of the key parts of the statement of the
                  witness.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.addRowModal}
          onHide={() => this.props.showAddRowModal(false)}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>Please add at least one row to highlight.</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.showAddRowModal(false)}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
