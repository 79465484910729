import React, { Component } from 'react';
import {
  Form,
  FormControl,
  Button,
  Col,
  Row,
  Container,
} from 'react-bootstrap';
import prod from '../../../../prod';
import axios from 'axios';
import { Link } from 'react-router-dom';
export default class RegisterFirm extends Component {
  constructor() {
    super();
    this.state = {
      firmError: '',
      firm: '',
      verified: '',
      verification: '',
      // pass: '',
    };
    this.onSubmitFirm = this.onSubmitFirm.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  onSubmitFirm(e) {
    e.preventDefault();
    // if (this.state.pass !== '7777') {
    //   this.setState({ firmError: 'Please book a demo to create an account' });
    // } else {
    if (this.state.firm == '' || this.state.firm == undefined) {
      this.setState({ firmError: 'Please enter a firm name.' });
    } else {
      // check if firm already exist
      axios
        .post(prod() + '/api/firms/check', {
          name: this.state.firm.trim().toLowerCase(),
        })
        .then((r) => {
          console.log(r);
          if (r.data.err) {
            this.setState({
              firmError: 'Firm already exist!',
            });
          } else {
            console.log('CALLED');
            this.props.changeStep({ firm: this.state.firm });
            this.props.jumpToStep(1);
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
      // }
    }
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  render() {
    return (
      <div className='Login-Container'>
        <Row>
          <Col md={5}>
            <div className='registrationForm '>
              <h1>Aleri</h1>
              <h5>Create your account</h5>
              <p>Build a stronger case.</p>
              <Form onSubmit={this.onSubmitFirm} id='reg-firm'>
                {this.state.firmError && (
                  <div className='invalid-feedback invalid-feedback-c alert alert-danger'>
                    {this.state.firmError}
                  </div>
                )}
                <Form.Group controlId='formBasicFirmName'>
                  <FormControl
                    type='text'
                    placeholder='Firm name'
                    value={this.state.firm}
                    onChange={this.onChange}
                    name='firm'
                    autoFocus
                  />
                  {/* <FormControl
                type='password'
                placeholder='Admin Key'
                value={this.state.pass}
                onChange={this.onChange}
                name='pass'
              /> */}
                </Form.Group>

                <Button
                  className='btn action'
                  block
                  type='submit'
                  variant='primary'
                >
                  Create Firm
                </Button>
              </Form>
              <a href='https://www.aleri.ca/academy/kb-tickets/new'>
                Join An Existing Firm
              </a>
              <br />
              <Link to='/login'>
                Already have an account?{' '}
                <span className='signup-signin'>Sign in</span>
              </Link>
            </div>
          </Col>
          <Col md={7} className='Login-IMG d-none d-md-block'></Col>
        </Row>
      </div>
    );
  }
}
