import React, { Component } from 'react';

import { Redirect, withRouter } from 'react-router-dom';
import {
  Form,
  Button,
  Col,
  Row,
  Container,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  ProgressBar,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';
import Pulse from 'react-reveal/Pulse';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import PreparationModal from './PreparationModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import arrayMove from 'array-move';

export const logPageView = () => {
  window.analytics.page('Preparation Chart');
};
class PreparationChart extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      prep: {},
      q: [],

      fact: {
        value: '',
        rows: 3,
      },
      statement: {
        value: '',
        rows: 3,
      },
      trial_evidence: {
        value: '',
        rows: 3,
      },
      other_statement: {
        value: '',
        rows: 3,
      },
      deleted: '',
      deletedCard: '',
      deletedCardJ: {},
      dShow: false,
      dCShow: false,
      hShow: false,
      show: false,
      prepList: [],
      dragging: false,
      facts: false,
      statements: false,
      trails: false,
      others: false,
      witness: {},
      case: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deletePrep = this.deletePrep.bind(this);
    this.myDivToFocus = React.createRef();
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleDCShow = this.handleDCShow.bind(this);
    this.handleDCClose = this.handleDCClose.bind(this);
    this.deleteCard = this.deleteCard.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    // refs
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.textInput4 = React.createRef();

    this.goToRef = this.goToRef.bind(this);
  }

  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/prepchart', {
          case: this.state.case._id,
          witness: this.state.witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          this.setState(
            {
              witness: user.data,
            },
            () => {
              this.props.updateWitness(this.state.case, user.data);
            }
          );
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };

  handleProgress = () => {
    let p = 0;
    const n = this.state;
    if (n.q && n.q.length > 0) {
      p += 100;
    }
    if (p != this.state.progress) this.setState({ progress: p });
  };
  handleFact = () => {
    this.setState({
      facts: !this.state.facts,
    });
  };

  handleTrail = () => {
    this.setState({
      trails: !this.state.trails,
    });
  };
  handleStatement = () => {
    this.setState({
      statements: !this.state.statements,
    });
  };

  handleOther = () => {
    this.setState({
      others: !this.state.others,
    });
  };

  handleEShow = () => this.setState({ eShow: true });
  handleEClose = () => this.setState({ eShow: false });
  handleDCShow = (event, e, e2) => {
    console.log(e);
    this.setState({ dCShow: true, deletedCard: e, deletedCardJ: e2 });
  };

  handleDCClose = () => {
    this.setState({ dCShow: false, deletedCard: '', deletedCardJ: {} });
  };
  deleteCard = () => {
    let newQ = this.state.q;
    console.log(newQ);

    // find deleted row
    for (let [i, row] of newQ.entries()) {
      if (i == this.state.deletedCard) {
        //find col
        for (let [j, col] of row[this.state.deletedCardJ.col].entries()) {
          if (j == this.state.deletedCardJ.num) {
            newQ[i][this.state.deletedCardJ.col].splice(j, 1);
            axios
              .post(prod() + '/api/cases/update/witness/prep', {
                witness: this.state.witness['_id'],
                case: this.state.case['_id'],
                q: newQ,
              })
              .then((use) => {
                console.log(use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.state.witness._id) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                this.setState({
                  q: newQ,
                  dCShow: false,
                  deletedCard: '',
                  deletedCardJ: {},
                });
              })
              .catch((e) => console.log(e));
            break;
          }
        }
      }
    }
  };

  //
  handleHShow = () => {
    this.setState({ hShow: true });
  };
  handleHClose = () => {
    this.setState({ hShow: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };
  //ref
  handleBottomClick = (event) => {
    if (this.myDivToFocus.current) {
      this.myDivToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    setTimeout(() => {
      this.myDivToFocus.current.focus();
    }, 1000);
  };

  //

  goToRef(a) {
    let ref = this.textInput1.current._ref;

    if (a == 1) {
      ref = this.textInput2.current;
    } else if (a == 2) {
      ref = this.textInput3.current;
    } else if (a == 3) {
      ref = this.textInput4.current;
    } else if (a == 4) {
      ref = this.textInput1.current;
    }
    ref.focus();
  }

  //

  handleChange = (event) => {
    //val
    const key = event.target.id;
    const name = event.target.name;
    const value = event.target.value;
    const j = event.currentTarget.getAttribute('data-j');
    const textareaLineHeight = 30;
    const { minRows, maxRows } = { minRows: 0, maxRows: 100 };

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState(
      (prev) => ({
        q: prev.q.map((el, index) =>
          index == key
            ? j != undefined && j != null
              ? {
                  ...el,
                  [name]: el[name].map((e, i) =>
                    j == i
                      ? {
                          ...e,
                          value: value,
                          rows: currentRows < maxRows ? currentRows : maxRows,
                        }
                      : e
                  ),
                }
              : {
                  ...el,
                  [name]: {
                    value: value,
                    rows: currentRows < maxRows ? currentRows : maxRows,
                  },
                }
            : el
        ),
      }),
      () => {
        if (name == 'statement') {
          this.setState((prev) => ({
            q: prev.q.map((el, index) =>
              index == key
                ? el['statement'][el['statement'].length - 1].value != ''
                  ? {
                      ...el,
                      ['statement']: el['statement'].concat({
                        value: '',
                        rows: 3,
                      }),
                    }
                  : el
                : el
            ),
          }));
        } else if (name == 'other_statement') {
          this.setState((prev) => ({
            q: prev.q.map((el, index) => {
              return index == key
                ? el['other_statement'][el['other_statement'].length - 1]
                    .value != ''
                  ? {
                      ...el,
                      ['other_statement']: el['other_statement'].concat({
                        value: '',
                        row: 3,
                      }),
                    }
                  : el
                : el;
            }),
          }));
        }
      }
    );
  };
  handleUpdate = (event) => {
    console.log('Update', event.target.name);
    const textareaLineHeight = 30;
    const { minRows, maxRows } = { minRows: 0, maxRows: 100 };

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }
    console.log('EVENT', event.target.name);
    this.setState({
      [event.target.name]: {
        value: event.target.value,
        rows: currentRows < maxRows ? currentRows : maxRows,
      },
    });
  };

  componentDidMount() {
    logPageView();
    window.scrollTo(0, 0);
    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          topic: '',
          path: '/case/witness/prep',
          name: 'Preparation Chart',
          active: true,
        });
      }
      const items = [];

      axios
        .post(prod() + '/api/cases/get/witness/prep', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
        })
        .then((use) => {
          this.setState(
            {
              q: use.data.preps,
              witness: this.props.witness,
              case: this.props.case,
              prep: this.props.prep,
            },
            () => {
              this.handleProgress();
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }
  componentDidUpdate() {
    localStorage.setItem('q', JSON.stringify(this.state));
    this.handleProgress();
  }
  deletePrep = () => {
    axios
      .post(prod() + '/api/cases/delete/witness/prep', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        prep: this.props.prep,
        d: this.state.deleted['_id'],
      })
      .then((user) => {
        console.log(user);
        for (let a of user.data.case.witnesses) {
          if (a._id == this.state.witness._id) {
            this.props.updateWitness(user.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/get/witness/prep', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
          })
          .then((use) => {
            const items = [];

            for (let topi of use.data.preps) {
              items.push({
                fact: topi.fact,
                statement: topi.statement,
                trial_evidence: topi.trial_evidence,
                other_statement: topi.other_statement,
                _id: topi._id,
              });
            }
            this.setState({
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              prep: this.props.prep,
              fact: {
                value: '',
                rows: 3,
              },
              statement: {
                value: '',
                rows: 3,
              },
              trial_evidence: {
                value: '',
                rows: 3,
              },
              other_statement: {
                value: '',
                rows: 3,
              },
              delete: '',
              dShow: false,
            });
          });
      });
  };

  addItem(item) {
    if (
      (this.state.fact.value !== undefined && this.state.fact.value !== '') ||
      (this.state.statement.value !== undefined &&
        this.state.statement.value !== '') ||
      (this.state.trial_evidence.value !== undefined &&
        this.state.trial_evidence.value !== '') ||
      (this.state.other_statement.value !== undefined &&
        this.state.other_statement.value !== '')
    ) {
      axios
        .post(prod() + '/api/cases/create/witness/prep', {
          witness: this.props.witness['_id'],
          cases: this.props.case['_id'],
          fact: this.state.fact,
          statement: this.state.statement,
          trial_evidence: this.state.trial_evidence,
          other_statement: this.state.other_statement,
        })
        .then((user) => {
          axios
            .post(prod() + '/api/cases/get/witness/prep', {
              case: this.props.case['_id'],
              witness: this.props.witness['_id'],
            })
            .then((use) => {
              const items = [];
              for (let topi of use.data.preps) {
                items.push({
                  fact: topi.fact,
                  statement: topi.statement,
                  trial_evidence: topi.trial_evidence,
                  other_statement: topi.other_statement,
                  _id: topi._id,
                });
              }
              console.log(user);
              for (let a of user.data.case.witnesses) {
                if (a._id == this.state.witness._id) {
                  console.log(user.data.case);
                  this.props.updateWitness(user.data.case, a);
                }
              }
              this.setState({
                q: items,
                witness: this.props.witness,
                case: this.props.case,
                prep: this.props.prep,
                fact: {
                  value: '',
                  rows: 3,
                },
                statement: {
                  value: '',
                  rows: 3,
                },
                trial_evidence: {
                  value: '',
                  rows: 3,
                },
                other_statement: {
                  value: '',
                  rows: 3,
                },
                delete: '',
              });
              this.goToRef(item);
            });
        })
        .catch((err) => {
          console.log('err: ', err);
          // alert('Did not save. Please refresh and try again.');
        });
    } else {
      // console.log("Else Block : error");
    }
  }
  saveItem() {
    console.log('test');
    let emp;

    let q = [];
    q = q.concat(this.state.q);
    for (let i = 0; i < this.state.q.length; i++) {
      if (this.state.q[i].statement.length > 1) {
        // loop over statements
        for (let j = 0; j < this.state.q[i].statement.length; j++) {
          if (
            j !== this.state.q[i].statement.length - 1 &&
            this.state.q[i].statement[j].value === ''
          ) {
            q[i].statement.splice(j, 1);
            emp = true;
          }
        }
      }
      if (this.state.q[i].other_statement.length > 1) {
        // loop over statements
        for (let j = 0; j < this.state.q[i].other_statement.length; j++) {
          if (
            j !== this.state.q[i].other_statement.length - 1 &&
            this.state.q[i].other_statement[j].value == ''
          ) {
            q[i].other_statement.splice(j, 1);
            emp = true;
          }
        }
      }

      if (
        this.state.q[i].fact.value === '' &&
        q[i].statement.length === 1 &&
        this.state.q[i].trial_evidence.value === '' &&
        q[i].other_statement.length === 1
      ) {
        q.splice(i, 1);
        break;
      } else {
        // console.log("ORIGINAL");
      }
    }
    axios
      .post(prod() + '/api/cases/update/witness/prep', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        q: q,
      })
      .then((use) => {
        // if (this.state.q.length != q.length || emp == true) {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.state.witness._id) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        this.setState({
          q: q,
          case: this.props.case,
          witness: this.props.witness,
        });
        // }
      })
      .catch((err) => {
        console.log('err', err);
        alert('Did not save. Please refresh and try again.');
      });
  }

  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => {
    console.log(e);
    this.setState({ dShow: true, deleted: e });
  };

  DragHandle = SortableHandle(() => <span className='drag'></span>);

  SortableItem = SortableElement(({ value, i }) => {
    let prepObject = {};
    const statements = [];
    const other_statements = [];
    for (let j = 0; j < this.state.q[i].statement.length; j++) {
      if (j == this.state.q[i].statement.length - 1) {
        statements.push(
          <Draggable
            // isDragDisabled={this.state.q[i].statement[j].value.length === 0}
            key={'statements' + i + 'dd' + j}
            draggableId={'statement' + j + i}
            index={j}
            tabIndex={-1}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                tabIndex={-1}
                className='unfocus'
                onMouseDown={(e) => e.currentTarget.focus()}
              >
                <div
                  className={
                    this.props.darkMode === true
                      ? 'border-bx dark-border-bx'
                      : 'border-bx'
                  }
                >
                  <Form.Group controlId={i}>
                    {this.state.q[i].statement[j] !=
                      this.state.q[i].statement[
                        this.state.q[i].statement.length - 1
                      ] && (
                      <div className='card-close'>
                        <img
                          onClick={(e) =>
                            this.handleDCShow(e, i, {
                              col: 'statement',
                              num: j,
                            })
                          }
                          className='logo-title'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                        ></img>
                      </div>
                    )}

                    <Form.Control
                      as='textarea'
                      placeholder='Enter This Witnesses Statement'
                      rows={this.state.q[i].statement[j].rows}
                      value={this.state.q[i].statement[j].value}
                      onChange={this.handleChange}
                      name='statement'
                      onBlur={this.saveItem}
                      ref={this.textInput2}
                      data-j={j}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
          </Draggable>
        );
      } else {
        statements.push(
          <Draggable
            isDragDisabled={this.state.q[i].statement[j].value.length === 0}
            key={'statements' + i + 'dd' + j}
            draggableId={'statement' + j + i}
            index={j}
            tabIndex={-1}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                tabIndex={-1}
                className='unfocus'
                onMouseDown={(e) => e.currentTarget.focus()}
              >
                <div
                  className={
                    this.props.darkMode == true
                      ? 'border-bx dark-border-bx  '
                      : 'border-bx'
                  }
                >
                  <Form.Group controlId={i}>
                    {this.state.q[i].statement[j] !==
                      this.state.q[i].statement[
                        this.state.q[i].statement.length - 1
                      ] && (
                      <div className='card-close'>
                        <img
                          onClick={(e) =>
                            this.handleDCShow(e, i, {
                              col: 'statement',
                              num: j,
                            })
                          }
                          className='logo-title'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                        ></img>
                      </div>
                    )}

                    <Form.Control
                      as='textarea'
                      placeholder='Enter This Witnesses Statement'
                      rows={this.state.q[i].statement[j].rows}
                      value={this.state.q[i].statement[j].value}
                      onChange={this.handleChange}
                      name='statement'
                      onBlur={this.saveItem}
                      data-j={j}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
          </Draggable>
        );
      }
    }

    for (let j = 0; j < this.state.q[i].other_statement.length; j++) {
      if (j == this.state.q[i].other_statement.length - 1) {
        other_statements.push(
          <Draggable
            key={'other-statements' + i + 'dd' + j}
            draggableId={'other-statement' + j + i}
            index={j}
            tabIndex={-1}
            isDragDisabled={
              this.state.q[i].other_statement[j].value.length === 0
            }
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                tabIndex={-1}
                className='unfocus'
                onMouseDown={(e) => e.currentTarget.focus()}
              >
                <div
                  className={
                    this.props.darkMode == true
                      ? 'border-bx dark-border-bx'
                      : 'border-bx'
                  }
                >
                  {this.state.q[i].other_statement[j] !=
                    this.state.q[i].other_statement[
                      this.state.q[i].other_statement.length - 1
                    ] && (
                    <div className='card-close'>
                      <img
                        onClick={(e) =>
                          this.handleDCShow(e, i, {
                            col: 'other_statement',
                            num: j,
                          })
                        }
                        className='logo-title'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                      ></img>
                    </div>
                  )}
                  {/* new box */}
                  <Form.Group controlId={i}>
                    <Form.Control
                      as='textarea'
                      placeholder='Enter Other Witnesses Statement'
                      rows={this.state.q[i].other_statement[j].rows}
                      value={this.state.q[i].other_statement[j].value}
                      onChange={this.handleChange}
                      name='other_statement'
                      onBlur={this.saveItem}
                      ref={this.textInput4}
                      data-j={j}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
          </Draggable>
        );
      } else {
        other_statements.push(
          <Draggable
            key={'other-statements' + i + 'dd' + j}
            draggableId={'other-statement' + j + i}
            index={j}
            tabIndex={-1}
            isDragDisabled={
              this.state.q[i].other_statement[j].value.length === 0
            }
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                tabIndex={-1}
                className='unfocus'
                onMouseDown={(e) => e.currentTarget.focus()}
              >
                <div
                  className={
                    this.props.darkMode == true
                      ? 'border-bx dark-border-bx'
                      : 'border-bx'
                  }
                >
                  {this.state.q[i].other_statement[j] !=
                    this.state.q[i].other_statement[
                      this.state.q[i].other_statement.length - 1
                    ] && (
                    <div className='card-close'>
                      <img
                        onClick={(e) =>
                          this.handleDCShow(e, i, {
                            col: 'other_statement',
                            num: j,
                          })
                        }
                        className='logo-title'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                      ></img>
                    </div>
                  )}
                  {/* saved box */}
                  <Form.Group controlId={i}>
                    <Form.Control
                      as='textarea'
                      placeholder='Enter Other Witnesses Statement'
                      rows={this.state.q[i].other_statement[j].rows}
                      value={this.state.q[i].other_statement[j].value}
                      onChange={this.handleChange}
                      name='other_statement'
                      onBlur={this.saveItem}
                      data-j={j}
                    />
                  </Form.Group>
                </div>
              </div>
            )}
          </Draggable>
        );
      }
    }
    if (i == this.state.q.length - 1) {
      prepObject = (
        <div key={i} className='underline-t'>
          <div className='d-block d-md-none'>
            <Row className='saved-input'>
              <h3>Facts</h3>
              <Col md={12} className='remove-s'>
                <img
                  onClick={() => this.handleDShow(this.state.q[i])}
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                ></img>{' '}
              </Col>
              <Col md={3}>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Fact '
                    rows={this.state.q[i].fact.rows}
                    value={this.state.q[i].fact.value}
                    onChange={this.handleChange}
                    name='fact'
                    onBlur={this.saveItem}
                    ref={this.textInput1}
                  />
                </Form.Group>
              </Col>
              <h3>This Witness's Statements</h3>
              <Col md={3} className='f9'>
                <Droppable droppableId={'statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
              <h3>Trial Evidence</h3>
              <Col md={3} className='notes'>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Trial Evidence'
                    rows={this.state.q[i].trial_evidence.rows}
                    value={this.state.q[i].trial_evidence.value}
                    onChange={this.handleChange}
                    name='trial_evidence'
                    onBlur={this.saveItem}
                    ref={this.textInput3}
                  />
                </Form.Group>
              </Col>

              <h3>Other Witness's Statements</h3>
              <Col md={3} className='notes f9'>
                <Droppable droppableId={'other-statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {other_statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </Row>
          </div>
          <div className='d-none d-md-block'>
            <Row key={i} className='saved-input'>
              <Col md={12} className='s'>
                {this.DragHandle}
                {/* <this.DragHandle /> */}
                <img
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                  onClick={() => this.handleDShow(this.state.q[i])}
                ></img>{' '}
              </Col>
              <Col md={3} className='ref ref-end ref-line'>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Fact'
                    rows={this.state.q[i].fact.rows}
                    value={this.state.q[i].fact.value}
                    onChange={this.handleChange}
                    name='fact'
                    onBlur={this.saveItem}
                    ref={this.textInput1}
                  />
                </Form.Group>
                <Row></Row>
              </Col>
              <Col md={3} className='disc f9 disc2'>
                <Droppable droppableId={'statement-' + i}>
                  {(provided, snapshot) => (
                    <div tabIndex={-1} ref={provided.innerRef}>
                      {statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Row></Row>
              </Col>
              <Col md={3} className='ref'>
                {/* Last saved row */}
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Trial Evidence'
                    rows={this.state.q[i].trial_evidence.rows}
                    value={this.state.q[i].trial_evidence.value}
                    onChange={this.handleChange}
                    name='trial_evidence'
                    onBlur={this.saveItem}
                    ref={this.textInput3}
                  />
                </Form.Group>
                <Row></Row>
              </Col>
              <Col md={3} className='notes f9'>
                <Droppable droppableId={'other-statement-' + i}>
                  {(provided, snapshot) => (
                    <div tabIndex={-1} ref={provided.innerRef}>
                      {other_statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Row></Row>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      prepObject = (
        <div key={i} className='underline-t'>
          <div className='d-block d-md-none'>
            <Row className='saved-input'>
              <h3>Facts</h3>
              <Col md={12} className='remove-s'>
                <img
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                  onClick={() => this.handleDShow(this.state.q[i])}
                ></img>{' '}
              </Col>
              <Col md={3}>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Fact '
                    rows={this.state.q[i].fact.rows}
                    value={this.state.q[i].fact.value}
                    onChange={this.handleChange}
                    name='fact'
                    onBlur={this.saveItem}
                  />
                </Form.Group>
              </Col>
              <h3>This Witness's Statements</h3>
              <Col md={3} className='f9'>
                <Droppable droppableId={'statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
              <h3>Trial Evidence</h3>
              <Col md={3} className='notes'>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Trial Evidence'
                    rows={this.state.q[i].trial_evidence.rows}
                    value={this.state.q[i].trial_evidence.value}
                    onChange={this.handleChange}
                    name='trial_evidence'
                    onBlur={this.saveItem}
                  />
                </Form.Group>
              </Col>

              <h3>Other Witness's Statements</h3>
              <Col md={3} className='notes f9'>
                <Droppable droppableId={'other-statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {other_statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Col>
            </Row>
          </div>
          <div className='d-none d-md-block'>
            <Row key={i} className='saved-input'>
              <Col md={12} className='s'>
                {/* {this.DragHandle} */}
                {/* <span className='drag'></span> */}
                {/* <this.DragHandle /> */}
                <img
                  onClick={() => this.handleDShow(this.state.q[i])}
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                ></img>{' '}
              </Col>
              <Col md={3} className='ref ref-end ref-line'>
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Fact'
                    rows={this.state.q[i].fact.rows}
                    value={this.state.q[i].fact.value}
                    onChange={this.handleChange}
                    name='fact'
                    onBlur={this.saveItem}
                  />
                </Form.Group>
                <Row></Row>
              </Col>
              <Col md={3} className='disc f9 disc2'>
                <Droppable droppableId={'statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Row></Row>
              </Col>
              <Col md={3} className='ref'>
                {/* first to second last saved row */}
                <Form.Group controlId={i}>
                  <Form.Control
                    as='textarea'
                    placeholder='Enter Trial Evidence'
                    rows={this.state.q[i].trial_evidence.rows}
                    value={this.state.q[i].trial_evidence.value}
                    onChange={this.handleChange}
                    name='trial_evidence'
                    onBlur={this.saveItem}
                  />
                </Form.Group>
                <Row></Row>
              </Col>
              <Col md={3} className='notes f9'>
                <Droppable droppableId={'other-statement-' + i}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {other_statements}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
                <Row></Row>
              </Col>
            </Row>
          </div>
        </div>
      );
    }
    return prepObject;
  });

  SortableContainer = SortableContainer(({ children }) => {
    return children;
  });

  onStatementsDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // Same dropable
    if (source.droppableId === destination.droppableId) {
      let id = source.droppableId;
      let prepIndex = id[id.length - 1];
      // statements
      if (id[0] == 's') {
        let newStatements = arrayMove(
          this.state.q[prepIndex].statement,
          source.index,
          destination.index
        );
        let prepArray = this.state.q;
        prepArray[prepIndex].statement = newStatements;
        this.setState({ q: prepArray });
        axios
          .post(prod() + '/api/cases/update/witness/prep', {
            witness: this.state.witness['_id'],
            case: this.state.case['_id'],
            q: prepArray,
          })
          .then((use) => {})
          .catch((e) => console.log(e));
      } else {
        //other statements
        let newOtherStatements = arrayMove(
          this.state.q[prepIndex].other_statement,
          source.index,
          destination.index
        );
        let prepArray = this.state.q;
        prepArray[prepIndex].other_statement = newOtherStatements;
        this.setState({ q: prepArray });
        axios
          .post(prod() + '/api/cases/update/witness/prep', {
            witness: this.state.witness['_id'],
            case: this.state.case['_id'],
            q: prepArray,
          })
          .then((use) => {})
          .catch((e) => console.log(e));
      }
    } else {
      // Different Droppable
      let sourcePrepIndex = source.droppableId[source.droppableId.length - 1];
      let destinationPrepIndex =
        destination.droppableId[destination.droppableId.length - 1];
      let prepArray = this.state.q;
      let elementBeingMoved = {};

      if (source.droppableId[0] == 's') {
        elementBeingMoved = prepArray[sourcePrepIndex].statement[source.index];
        prepArray[sourcePrepIndex].statement.splice(source.index, 1);
      } else {
        elementBeingMoved =
          prepArray[sourcePrepIndex].other_statement[source.index];
        prepArray[sourcePrepIndex].other_statement.splice(source.index, 1);
      }

      if (destination.droppableId[0] == 's') {
        prepArray[destinationPrepIndex].statement.splice(
          destination.index,
          0,
          elementBeingMoved
        );
      } else {
        prepArray[destinationPrepIndex].other_statement.splice(
          destination.index,
          0,
          elementBeingMoved
        );
      }

      this.setState({ q: prepArray });
      axios
        .post(prod() + '/api/cases/update/witness/prep', {
          witness: this.state.witness['_id'],
          case: this.state.case['_id'],
          q: prepArray,
        })
        .then((use) => {})
        .catch((e) => console.log(e));
    }
  };

  onDragEnd = ({ oldIndex, newIndex }) => {
    let newPrepList = arrayMove(this.state.q, oldIndex, newIndex);

    this.setState({ q: newPrepList, dragging: false });

    axios
      .post(prod() + '/api/cases/update/witness/prep', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        q: newPrepList,
      })
      .then((use) => {})
      .catch((e) => console.log(e));
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    this.setState({
      dragging: true,
    });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    return (
      <div id='wit-dash'>
        <Container fluid className='scope'>
          <div className={this.state.dragging ? 'cases-dragging' : ''}></div>
          <DragDropContext onDragEnd={this.onStatementsDragEnd}>
            <div className='cases'>
              <Row>
                <Col md={5}>
                  <div className='case-dash-container'>
                    {this.state.witness.prepImportant ? (
                      <img
                        className='logo-title-dash'
                        onClick={(e) =>
                          this.handleImportant(
                            e,

                            false
                          )
                        }
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                      ></img>
                    ) : (
                      <img
                        onClick={(e) => this.handleImportant(e, true)}
                        className='logo-title-dash card_important_comment'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                      ></img>
                    )}
                  </div>
                  <h1>Cross-examination Preparation Chart</h1>
                  <ProgressBar now={Math.round(this.state.progress)} />
                </Col>
                <Col md={7}>
                  <PreparationModal
                    dShow={this.state.dShow}
                    handleDClose={this.handleDClose}
                    deletePrep={this.deletePrep}
                    eShow={this.state.eShow}
                    handleEClose={this.handleEClose}
                    q={this.state.q}
                    dCShow={this.state.dCShow}
                    handleDCClose={this.handleDCClose}
                    deleteCard={this.deleteCard}
                    hShow={this.state.hShow}
                    handleHClose={this.handleHClose}
                    facts={this.state.facts}
                    handleFact={this.handleFact}
                    statements={this.state.statements}
                    handleStatement={this.handleStatement}
                    trails={this.state.trails}
                    handleTrail={this.handleTrail}
                    others={this.state.others}
                    handleOther={this.handleOther}
                    darkMode={this.props.darkMode}
                    handleClose={this.handleClose}
                  />

                  <div className='add-btn add-btn-p topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Help
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleHShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title log'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p add-btn-p3 topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Export
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleEShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title log'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                </Col>
              </Row>

              <Form id='focus-form'>
                <div className='d-none d-md-block'>
                  <Fade>
                    <Row className='titles'>
                      <Col md={3}>
                        <Row>
                          <div className='hov-container'>
                            <h3 className='h-facts'>Facts</h3>
                            <img
                              className='prephelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleFact}
                            ></img>
                          </div>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <div className='hov-container'>
                            <h3 className='h-stat'>
                              This Witness's Statements
                            </h3>
                            <img
                              className='prephelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleStatement}
                            ></img>
                          </div>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <div className='hov-container'>
                            <h3 className='h-trail'>Trial Evidence</h3>
                            <img
                              className='prephelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleTrail}
                            ></img>
                          </div>
                        </Row>
                      </Col>
                      <Col md={3}>
                        <Row>
                          <div className='hov-container'>
                            <h3 className='h-other'>
                              Other Witness's Statements
                            </h3>
                            <img
                              className='prephelp'
                              alt='image2'
                              src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                              onClick={this.handleOther}
                              tabIndex={-1}
                            ></img>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </Fade>
                </div>

                <Fade>
                  <this.SortableContainer
                    onSortEnd={this.onDragEnd}
                    onSortStart={this.onDragStart}
                    useDragHandle
                  >
                    <div>
                      {this.state.q.map((value, index) => (
                        <this.SortableItem
                          key={`prepe-${index}`}
                          i={index}
                          index={index}
                          value={value}
                        />
                      ))}
                    </div>
                  </this.SortableContainer>
                  <div className='d-block d-md-none underline-t'>
                    <Row className='unsaved-input'>
                      <h2>Create New</h2>
                      <h3 className='mobile-title'>Fact</h3>
                      <Col md={2} className='ref-mobile'>
                        <Row>
                          <Form.Group controlId='formBasicEmail'>
                            <Form.Control
                              ref={this.myDivToFocus}
                              as='textarea'
                              placeholder='Enter Fact'
                              onBlur={() => this.addItem(1)}
                              rows={this.state.fact.rows}
                              value={this.state.fact.value}
                              onChange={this.handleUpdate}
                              name='fact'
                            />
                          </Form.Group>
                        </Row>
                        <Row></Row>
                      </Col>
                      <h3 className='mobile-title'>
                        This Witness's Statements
                      </h3>
                      <Col md={3} className='disc-mobile'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter This Witnesses Statement'
                            onBlur={() => this.addItem(2)}
                            rows={this.state.statement.rows}
                            value={this.state.statement.value}
                            onChange={this.handleUpdate}
                            name='statement'
                          />
                        </Form.Group>
                      </Col>
                      <h3 className='mobile-title'>Trial Evidence</h3>
                      <Col md={3} className='notes'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter Trial Evidence'
                            onBlur={() => this.addItem(3)}
                            rows={this.state.trial_evidence.rows}
                            value={this.state.trial_evidence.value}
                            onChange={this.handleUpdate}
                            name='trial_evidence'
                          />
                        </Form.Group>
                      </Col>
                      <h3 className='mobile-title'>
                        Other Witness's Statements
                      </h3>
                      <Col md={3} className='notes'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter Other Witnesses Statement'
                            onBlur={() => this.addItem(4)}
                            rows={this.state.other_statement.rows}
                            value={this.state.other_statement.value}
                            onChange={this.handleUpdate}
                            name=''
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  {/* ROW REMOVAL */}
                  <div className='d-none d-md-block'>
                    <Row className='unsaved-input'>
                      <Col md={3} className='ref ref-end'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            ref={this.myDivToFocus}
                            as='textarea'
                            placeholder='Enter Fact '
                            onBlur={() => this.addItem(1)}
                            rows={this.state.fact.rows}
                            value={this.state.fact.value}
                            onChange={this.handleUpdate}
                            name='fact'
                          />
                        </Form.Group>
                        <Row></Row>
                      </Col>
                      <Col md={3} className='disc disc3'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter This Witnesses Statement'
                            onBlur={() => this.addItem(2)}
                            rows={this.state.statement.rows}
                            value={this.state.statement.value}
                            onChange={this.handleUpdate}
                            name='statement'
                          />
                        </Form.Group>
                        <Row></Row>
                      </Col>

                      {/* new row */}
                      <Col md={3} className='ref'>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter Trial Evidence'
                            onBlur={() => this.addItem(3)}
                            rows={this.state.trial_evidence.rows}
                            value={this.state.trial_evidence.value}
                            onChange={this.handleUpdate}
                            name='trial_evidence'
                          />
                        </Form.Group>
                        <Row></Row>
                      </Col>
                      <Col md={3} className=' '>
                        <Form.Group controlId='formBasicEmail'>
                          <Form.Control
                            as='textarea'
                            placeholder='Enter Other Witnesses Statement'
                            onBlur={() => this.addItem(4)}
                            rows={this.state.other_statement.rows}
                            value={this.state.other_statement.value}
                            onChange={this.handleUpdate}
                            name='other_statement'
                          />
                        </Form.Group>
                        <Row></Row>
                      </Col>
                    </Row>
                  </div>
                </Fade>
              </Form>
            </div>
          </DragDropContext>
        </Container>
      </div>
    );
  }
}
export default withRouter(PreparationChart);
