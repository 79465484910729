import React, { Component } from 'react';
import { Container, Col, Row, Button, Modal, Form } from 'react-bootstrap';
export default class DocumentModal extends Component {
  constructor() {
    super();
    this.state = {
      linkName: '',
      linkLocation: '',
    };
  }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  componentWillReceiveProps(newProps) {
    if (newProps.linkErr !== this.props.linkErr) {
      this.setState({
        linkName: '',
        linkLocation: '',
      });
    }
    if (
      newProps.linkName != this.props.linkName ||
      newProps.linkLocation != this.props.linkLocation
    ) {
      this.setState({
        linkName: newProps.linkName,
        linkLocation: newProps.linkLocation,
      });
    }
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.createLink}
          onHide={() =>
            this.setState(
              {
                linkName: '',
                linkLocation: '',
              },
              () => {
                this.props.handleCreateClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.linkErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.linkErr}
                </div>
              )}
              <Form.Group controlId='linkName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The File'
                  value={this.state.linkName ? this.state.linkName : ''}
                  onChange={this.handleChange}
                  name='linkName'
                  ref={this.props.linkRef}
                />
              </Form.Group>
              <Form.Group controlId='linkLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The URL Of The File'
                  value={this.state.linkLocation ? this.state.linkLocation : ''}
                  onChange={this.handleChange}
                  name='linkLocation'
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.handleSubmit({
                    linkName: this.state.linkName,
                    linkLocation: this.state.linkLocation,
                    type: 'Link',
                  });
                  this.setState({
                    linkName: '',
                    linkLocation: '',
                  });
                }}
                variant='primary'
              >
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showDelete}
          onHide={this.props.handleDeleteClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this document?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleDeleteClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showUpdate}
          onHide={() =>
            this.setState(
              {
                linkName: '',
                linkLocation: '',
              },
              () => {
                this.props.handleUpdateClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Update Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.updateErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.updateErr}
                </div>
              )}
              <Form.Group controlId='linkName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The File'
                  value={this.state.linkName ? this.state.linkName : ''}
                  onChange={this.handleChange}
                  name='linkName'
                  ref={this.props.linkRef}
                />
              </Form.Group>
              <Form.Group controlId='linkLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The URL Of The File'
                  value={this.state.linkLocation ? this.state.linkLocation : ''}
                  onChange={this.handleChange}
                  name='linkLocation'
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.handleUpdate({
                    linkName: this.state.linkName,
                    linkLocation: this.state.linkLocation,
                  });
                  this.setState({
                    linkLocation: '',
                    linkName: '',
                  });
                }}
                variant='primary'
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
