import React, { Component } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import PrepExport from './PrepExport';
import { Link } from 'react-router-dom';
export default class PreparationModal extends Component {
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this Fact?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deletePrep}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Preparation Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrepExport prep={this.props.q} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dCShow}
          onHide={this.props.handleDCClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDCClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteCard}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.dShow}
          onHide={this.props.handleDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              type='submit'
              variant='secondary'
              onClick={this.props.handleDClose}
            >
              No
            </Button>
            <Button variant='primary' onClick={this.props.deletePrep}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What Is A Preparation Chart</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The cross-examination chart is designed to organize the
                  evidence and allow you to easily compare different statements
                  that the witness has made and compare the witnesses evidence
                  with that of other witnesses. The chart list the relevant fact
                  or areas of interest on the left going down, and creates a
                  column for each statement of the witness, a column for the
                  evidence of other witnesses and a final column for additional
                  evidence at the time of trial. The chart allows you to easily
                  locate any internal or external inconsistencies that can be
                  used to impeach the witness. The chart is particularly
                  valuable when dealing with a witness who has given multiple
                  statements, and where there are multiple witnesses.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/settings/support'>
                <Button variant='outline-secondary'>Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.facts}
          onHide={this.props.handleFact}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleFact();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Facts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  props each relevant Fact that the witness has given statements
                  on.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.statements}
          onHide={this.props.handleStatement}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleStatement();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Statements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  List all the different statements of the witness on the one
                  fact
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.trails}
          onHide={this.props.handleTrail}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleTrail();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Trial Evidence</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Add trial evidence relating to each fact.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.others}
          onHide={this.props.handleOther}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleOther();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Other Statement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Add the statements of other witnesses to check for external
                  inconsistencies.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
      </>
    );
  }
}
