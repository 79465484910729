import React, { Component } from 'react';
import ChecklistExport from './ChecklistExport';
import { Modal, Button, Form } from 'react-bootstrap';
export default class ChecklistModal extends Component {
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ChecklistExport checklist={this.props.checklist} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What Is A Checklist</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The cross-examination checklist is a tool that allows you to
                  assess the witness for any of the most common areas that a
                  witness can be challenged o. These areas include:
                </p>
                <ul>
                  <li>lack of knowledge</li>

                  <li>bias</li>
                  <li>prior statements</li>
                  <li>contradictions</li>
                  <li>plausibility</li>
                  <li>and criminal records or other misconduct</li>
                </ul>
                <p>
                  The checklist also provides a list of numerous
                  cross-examination techniques that can be considered when
                  drafting the questions for the witness.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <a target='_blank' href='/docs/checklist'>
                <Button variant='outline-secondary'>Help</Button>
              </a>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.bias}
          onHide={this.props.handleBias}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleBias();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bias and Motive - Financial Interest</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Does the witness have a financial interest in the outcome of the
                case?
              </p>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.biasFriend}
          onHide={this.props.handleBiasFriend}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleBiasFriend();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bias and Motive - Friendship, Relative</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Is the witness a friend or relative of the opponent or
                complainant?
              </p>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hostility}
          onHide={this.props.handleHostility}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHostility();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bias and Motive – Hostility</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Does the witness have a motive to be hostile to your client?
              </p>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.impairment}
          onHide={this.props.handleImpairment}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleImpairment();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Impairment Alcohol Or Drugs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Was the witness using alcohol or drugs?</p>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.pyhsical}
          onHide={this.props.handlePyhsical}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handlePyhsical();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Did the witness have and problem with sight or hearing?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Did the witness have and problem with sight or hearing?</p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.focus}
          onHide={this.props.handleFocus}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleFocus();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Focus Of Attention Is Not On Event Was the witness originally
                focused on something else?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.witness}
          onHide={this.props.handleWitness}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleWitness();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Witness
                Preoccupied
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Was the witness preoccupied with another activity?</p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.desire}
          onHide={this.props.handleDesire}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleDesire();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Desire To Find
                Meaning
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Did the witness alter the facts to support a conclusion that
                they were biased towards?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.see}
          onHide={this.props.hideSee}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideSee();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Establish
                Inability To See
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Were there difficulties observing due to obstructions, distance,
                or movement of people?{' '}
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hear}
          onHide={this.props.hideHear}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideHear();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge oEvents / Faulty Perception - Establish
                Inability To Hear
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Were there difficulties hearing due to other noises or distance?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.impact}
          onHide={this.props.hideImpact}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideImpact();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Impact Of
                Sudden Events
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Was the witnesses’ memory affected by the sudden nature of the
                events?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.insignificance}
          onHide={this.props.hideInsignificance}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideInsignificance();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Insignificance
                Of Event
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Was it a routine event that the witness would not likely
                remember?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.expose}
          onHide={this.props.hideExpose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideExpose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Lack of Knowledge of Events / Faulty Perception - Expose Belief
                From Habit
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Was the witness basing their evidence on habit and not actual
                memory?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reliability}
          onHide={this.props.hideReliability}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReliability();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Reliability of Memory - Witnesses Forget Events Over Time, And
                Subsequent Events Can Distort Memory
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Was the witness impacted by subsequent events or discussing
                events with others?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reliabilityMem}
          onHide={this.props.hideReliabilityMem}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReliabilityMem();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Reliability of Memory - Memory Is Highly Flawed
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Is the witness mistaken or do they have a bad memory?</p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reliabilityExp}
          onHide={this.props.hideReliabilityExp}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReliabilityExp();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Reliability of Memory - Experience, Social Values, Prejudice And
                Emotions Can Affect The Way An Event Is Remembered
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Is the witness altering their evidence as a result of their
                social values?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reliabilityEst}
          onHide={this.props.hideReliabilityEst}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReliabilityEst();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Reliability of Memory - Establish Poor Memory-Cross On
                Collateral Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Does the witness have a poor memory relating to details?</p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.reliabilityInflu}
          onHide={this.props.hideReliabilityInflu}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideReliabilityInflu();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Reliability of Memory - Influence Of Hearsay
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Has the witness been influenced by what they heard from others?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.prior}
          onHide={this.props.hidePrior}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hidePrior();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Prior Inconsistent Statements</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Has the witness made inconsistent written or verbal statements
                on important facts?
              </p>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.version}
          onHide={this.props.hideVersion}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideVersion();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Version Is Implausible or Improbable</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Is the version of the witness contrary to common sense?</p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.contradicted}
          onHide={this.props.hideContradicted}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideContradicted();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Contradicted By Other Evidence or Witnesses
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Is the witness contradicted by other witnesses or by the
                physical evidence?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.evidence}
          onHide={this.props.hideEvidence}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hideEvidence();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Evidence of Misconduct or Character</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Is there other misconduct that is relevant to their testimony?
              </p>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.priorCriminal}
          onHide={this.props.hidePriorCriminal}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.hidePriorCriminal();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Prior Criminal Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Does the witness have a criminal record?</p>
            </Modal.Body>
          </Form>
        </Modal>

      </>
    );
  }
}
