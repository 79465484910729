import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { Redirect, withRouter } from 'react-router-dom';
import {
  Form,
  Button,
  Col,
  Row,
  Container,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  ProgressBar,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../../prod';
import Pulse from 'react-reveal/Pulse';
import LinkDocument from '../../Shared/LinkDocument';
import Fade from 'react-reveal/Fade';
import TopicModal from './TopicModal';
import TopicRow from './TopicRow';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import NewRow from '../../Shared/NewRow';

export const logPageView = () => {
  window.analytics.page('Topic');
};
class Topic extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      topic: {},
      q: [],

      ref: {
        value: '',
        rows: 1,
      },
      disc: {
        value: '',
        rows: 1,
      },

      deleted: '',
      dShow: false,
      // delete topic
      deletedT: '',
      dtShow: '',
      // update
      show: false,
      err: '',
      name: '',

      // help
      hShow: false,
      // export
      eShow: false,
      dragging: false,
      rowHeight: 0,

      reference: false,
      question: false,
      note: false,
      uploadDocModal: false,
      linkErr: '',
      addLinkState: false,
      row: 0,
      documentDeleteShow: false,

      //doc
      highlights: [],
      link: '',
      docs: [],
      rowId: '',
      addExistingDoc: false,
    };

    this.saveItem = this.saveItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteTopic = this.deleteTopic.bind(this);
    this.updateRows = this.updateRows.bind(this);

    // delete topic
    this.handleDTShow = this.handleDTShow.bind(this);
    this.handleDTClose = this.handleDTClose.bind(this);
    this.handleDTSubmit = this.handleDTSubmit.bind(this);
    // update topic
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    // help
    this.handleHClose = this.handleHClose.bind(this);
    this.handleHShow = this.handleHShow.bind(this);

    // change
    this.onChange = this.onChange.bind(this);
    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    //doc
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
    this.saveHighlights = this.saveHighlights.bind(this);
    this.createPDF = this.createPDF.bind(this);
    this.saveLinkToCreatePDF = this.saveLinkToCreatePDF.bind(this);

    // refs
    this.textInput1 = React.createRef();
    this.textInput2 = React.createRef();
    this.textInput3 = React.createRef();
    this.divElement = React.createRef();
    this.divElement2 = React.createRef();

    this.goToRef = this.goToRef.bind(this);
    this.linkRef = React.createRef();
    this.addRef = React.createRef();
  }
  isURL = (str) => {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(str);
  };
  addExistingLink = (data) => {
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/topic' &&
          a.topic.toString() == this.props.topic['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        topic: this.props.topic,
        path: '/case/witness/topic',
        name: this.props.topic.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }

    let t = this.state.q;
    t[this.state.row].ref.document = data;
    t[this.state.row].ref.value = data.name;
    axios
      .post(prod() + '/api/cases/update/witness/topic', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        topic: this.state.topic['_id'],
        q: t,
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];
        axios
          .post(prod() + '/api/cases/get/witness/topic', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            topic: this.props.topic['_id'],
          })
          .then((use) => {
            for (let topi of use.data.topics.topic) {
              items.push({
                ref: topi.ref,
                disc: topi.disc,

                _id: topi._id,
              });
            }

            this.setState({
              q: items,
              row: 0,
              addLinkState: false,
            }, () => this.handleProgress());
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  addLink = (data) => {
    const rowIndex = this.state.q.findIndex((row) => {
      return row._id == data.refId;
    });
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type,
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                topic: this.props.topic,
                path: '/case/witness/topic',
                name: this.props.topic.name,
              },
            ],
          })
          .then((use) => {
            this.setState({ docs: use.data.documents });

            let t = this.state.q;
            t[rowIndex].ref.document =
              use.data.documents[use.data.documents.length - 1];
            t[rowIndex].ref.value =
              use.data.documents[use.data.documents.length - 1].name;

            axios
              .post(prod() + '/api/cases/update/witness/topic', {
                witness: this.state.witness['_id'],
                case: this.state.case['_id'],
                topic: this.state.topic['_id'],
                q: t,
              })
              .then((use) => {
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];
                axios
                  .post(prod() + '/api/cases/get/witness/topic', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    topic: this.props.topic['_id'],
                  })
                  .then((use) => {
                    for (let topi of use.data.topics.topic) {
                      items.push({
                        ref: topi.ref,
                        disc: topi.disc,

                        _id: topi._id,
                      });
                    }

                    this.setState({
                      q: items,
                      row: 0,
                      addLinkState: false,
                    }, () => this.handleProgress());
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  addExistingDocOnRow = (data, refId) => {
    const rowIndex = this.state.q.findIndex((row) => {
      return row._id == refId;
    });
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/topic' &&
          a.topic.toString() == this.props.topic['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        topic: this.props.topic,
        path: '/case/witness/topic',
        name: this.props.topic.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          // console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }

    let t = this.state.q;
    t[rowIndex].ref.document = {
      case: data.case,
      link: data.link,
      name: data.name,
      type: data.type,
      witness: data.witness,
      _id: data._id,
    };
    t[rowIndex].ref.value = data.name;

    axios
      .post(prod() + '/api/cases/update/witness/topic', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        topic: this.state.topic['_id'],
        q: t,
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];
        axios
          .post(prod() + '/api/cases/get/witness/topic', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            topic: this.props.topic['_id'],
          })
          .then((use) => {
            for (let topi of use.data.topics.topic) {
              items.push({
                ref: topi.ref,
                disc: topi.disc,

                _id: topi._id,
              });
            }

            this.setState({
              q: items,
              row: 0,
              addLinkState: false,
            }, () => this.handleProgress());
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };

  addExistingDoc = (data) => {
    // console.log(data);
    const locations = data.locations;

    let update = false;
    if (locations != null) {
      for (let a of locations) {
        if (
          a.path == '/case/witness/topic' &&
          a.topic.toString() == this.props.topic['_id'].toString()
        ) {
          console.log('YES');
          update = true;
        }
      }
    } else {
      locations = [];
    }

    if (update == false) {
      console.log(data);
      locations.push({
        case: this.props.case,
        witness: this.props.witness,
        topic: this.props.topic,
        path: '/case/witness/topic',
        name: this.props.topic.name,
      });
      axios
        .post(prod() + '/api/documents/update/loc', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          document: data['_id'],
          loc: locations,
        })
        .then((use) => {
          // console.log(use.data);
        })
        .catch((e) => {
          console.log('Invalid Input, Please Try Again');
        });
    }
    axios
      .post(prod() + '/api/cases/create/witness/topic/q', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        topic: this.props.topic['_id'],
        reference: data.name,
        referenceLink: data,
        disclosure: '',
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        const items = [];

        axios
          .post(prod() + '/api/cases/get/witness/topic', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            topic: this.props.topic['_id'],
          })
          .then((use) => {
            // console.log('GET', use);
            for (let topi of use.data.topics.topic) {
              items.push({
                ref: topi.ref,
                disc: topi.disc,

                _id: topi._id,
              });
            }

            this.setState({
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              topic: use.data.topics,
              ref: {
                value: '',
                rows: 1,
              },
              disc: {
                value: '',
                rows: 1,
              },

              delete: '',
              uploadDocModal: false,
              linkErr: '',
            }, () => this.handleProgress());
            this.goToRef(data);
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  // adds the document on a new row
  createLink = (data) => {
    console.log("data passsed: ", data);
    if (this.isURL(data.linkLocation) == true) {
      axios.get(prod() + '/api/users/current').then((s) => {
        axios
          .post(prod() + '/api/documents/create', {
            name: data.linkName,
            link: data.linkLocation,
            case: this.props.case['_id'],
            user: s.data.id,
            firm: s.data.firm,
            witness: this.props.witness['_id'],
            type: data.type, // Link or file
            locations: [
              {
                case: this.props.case,
                witness: this.props.witness,
                topic: this.props.topic,
                path: '/case/witness/topic',
                name: this.props.topic.name,
              },
            ],
          })

          .then((use) => {
            // console.log(
            //   'use',
            //   use.data.documents[use.data.documents.length - 1]._id
            // );
            this.setState({ docs: use.data.documents });
            // add document to topic

            axios
              .post(prod() + '/api/cases/create/witness/topic/q', {
                witness: this.props.witness['_id'],
                case: this.props.case['_id'],
                topic: this.props.topic['_id'],
                reference: data.linkName,
                referenceLink:
                  use.data.documents[use.data.documents.length - 1],
                disclosure: '',
              })
              .then((use) => {
                // console.log(use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];

                axios
                  .post(prod() + '/api/cases/get/witness/topic', {
                    case: this.props.case['_id'],
                    witness: this.props.witness['_id'],
                    topic: this.props.topic['_id'],
                  })
                  .then((use) => {
                    // console.log('GET', use);
                    for (let topi of use.data.topics.topic) {
                      items.push({
                        ref: topi.ref,
                        disc: topi.disc,

                        _id: topi._id,
                      });
                    }

                    this.setState({
                      q: items,
                      witness: this.props.witness,
                      case: this.props.case,
                      topic: use.data.topics,
                      ref: {
                        value: '',
                        rows: 1,
                      },
                      disc: {
                        value: '',
                        rows: 1,
                      },

                      delete: '',
                      uploadDocModal: false,
                      linkErr: '',
                    }, () => this.handleProgress());
                    this.goToRef(data);
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
          })
          .catch((e) => {
            // console.log(e);
            this.setState({
              linkErr: 'Invalid Input. Please Try Again',
            });
          });
      });
    } else {
      this.setState({
        linkErr:
          'Invalid URL. Please format like this: https://www.google.com/',
      });
    }
  };
  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open });
  }
  handleProgress = () => {
    let p = 0;
    const n = this.state;
    if (n.q && n.q.length > 0) {
      p += 100;
    }
    if (p != this.state.progress) this.setState({ progress: p });
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    this.setState({
      dragging: true,
    });
  };

  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      axios
        .post(prod() + '/api/cases/get/witness/topic', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          topic: this.props.topic['_id'],
        })
        .then((use) => {
          const items = [];
          for (let topi of use.data.topics.topic) {
            items.push({
              ref: topi.ref,
              disc: topi.disc,

              _id: topi._id,
            });
          }
          this.setState({
            q: items,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    });
  handleEClose = () => this.setState({ eShow: false });
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  // help
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  // update topic
  handleShow = () => this.setState({ show: true });
  handleClose = () => this.setState({ show: false });
  handleAddClose = () => this.setState({ addLinkState: false });
  componentWillReceiveProps(newProps) {
    window.onpopstate = e => {
      console.log("saveitem")
    }
    console.log('c will receive props');
    if (newProps.topic !== this.props.topic && newProps.topic != '') {
      console.log("called3")
      const items = [];

      axios
        .post(prod() + '/api/cases/get/witness/topic', {
          case: newProps.case['_id'],
          witness: newProps.witness['_id'],
          topic: newProps.topic['_id'],
        })
        .then((use) => {
          for (let topi of use.data.topics.topic) {
            items.push({
              ref: topi.ref,
              disc: topi.disc,

              _id: topi._id,
            });
          }
          const p = this.handleProgress(items);
          this.setState(
            {
              q: items,
              witness: newProps.witness,
              case: newProps.case,
              topic: newProps.topic,
              name: newProps.topic.name,
              progress: p,
              ref: {
                value: '',
                rows: 1,
              },
              disc: {
                value: '',
                rows: 1,
              },
            },
            () => {
              this.handleProgress();
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  topic: newProps.topic,
                  path: '/case/witness/topic',
                  name: 'Topic',
                  active: true,
                });
              }
              localStorage.setItem('q', JSON.stringify(this.state));
              this.goToRef(2);
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
          // alert('Did not save. Please go back or refresh and try again.');
        });
    }
  }
  goToRef(a) {
    let ref = this.textInput3.current._ref;

    if (a == 1) {
      ref = this.textInput2.current._ref;
    } else if (a == 2) {
      ref = this.textInput3.current._ref;
    } else if (a == 3) {
      ref = this.textInput1.current._ref;
    }

    ref.focus();
  }
  handleSubmit = (data, name) => {
    data.preventDefault();
    axios
      .post(prod() + '/api/cases/update/witness/topic/name', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        topic: this.state.topic['_id'],
        name: name,
        old: this.state.topic.name,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].topics.length; j++) {
              if (
                use.data.case.witnesses[i].topics[j]['_id'] ==
                this.state.topic['_id']
              ) {
                this.props.getT(use.data.case.witnesses[i]);
                this.props.getQ(use.data.case.witnesses[i].topics[j]);
                // this.props.history.push('/case/witness/topics');

                this.setState({
                  show: false,
                  name: this.props.topic.name,
                  topic: this.props.topic,
                });
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: 'Topic already exist',
        });
      });
    // }
  };
  // delete topic

  handleDTClose = () => this.setState({ dTShow: false });
  handleDTShow = (e) => this.setState({ dTShow: true, deletedT: e });
  handleDTSubmit = () => {
    axios
      .post(prod() + '/api/cases/delete/witness/topic', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        topic: this.state.topic['_id'],
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.state.witness['_id']) {
            this.props.getT(use.data.case.witnesses[i]);
            this.props.history.push('/case/witness/topics');
          }
        }
      });
  };

  //
  // update topic
  //
  updateRows() {}

  handleUpdate = (event) => {
    // }
    this.setState({
      [event.target.name]: {
        value: event.target.value,
      },
    });
  };

  componentDidMount() {
    // initGA();
    logPageView();

    window.addEventListener('resize', this.updateRows);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.case.client == undefined ||
      this.props.case.client == null ||
      this.props.topic == undefined ||
      this.props.topic == null ||
      this.props.witness == undefined ||
      this.props.witness._id == undefined
    ) {
      this.setState({ redirect: true });
    } else {
      const items = [];
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          topic: this.props.topic,
          path: '/case/witness/topic',
          name: 'Topic',
          active: true,
        });
      }
      axios
        .post(prod() + '/api/cases/get/witness/topic', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
          topic: this.props.topic['_id'],
        })
        .then((use) => {
          console.log("topic rows: ", use);
          for (let topi of use.data.topics.topic) {
            items.push({
              ref: topi.ref,
              disc: topi.disc,

              _id: topi._id,
            });
          }

          this.setState(
            {
              q: items,
              witness: this.props.witness,
              case: this.props.case,
              topic: this.props.topic,
              name: this.props.topic.name,
            },
            () => {
              this.handleProgress();
              this.goToRef(2);
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  reference = () => {
    this.setState({
      reference: !this.state.reference,
    });
  };

  question = () => {
    this.setState({
      question: !this.state.question,
    });
  };

  note = () => {
    this.setState({
      note: !this.state.note,
    });
  };

  componentDidUpdate() {
    localStorage.setItem('q', JSON.stringify(this.state));
    window.onpopstate = e => {
      console.log("saveitem")
    }
  }
  handleDelete = () => {
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex(
      (row) => row._id === this.state.deleted._id
    );
    let row = qDupe.find((row) => {
      return row._id === this.state.deleted._id;
    });
    row = { _id: row._id, disc: { ...row.disc }, ref: { value: '' } };

    qDupe[index] = row;

    this.deleteDocs(this.state.q, this.state.topic, this.state.deleted);

    this.setState({ q: qDupe, documentDeleteShow: false }, () => {
      this.saveItem(row);
    });
  };
  
  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }

  deleteDocs = (rows, topic, deleted) => {
    // console.log("rows: ", rows)
    let update = false;
    let rowId;
    let count = 0;

    for (let row of rows) {
      if (
        row.ref &&
        row.ref.document &&
        deleted.ref.document &&
        row._id === deleted._id &&
        row.ref.document._id == deleted.ref.document._id
      ) {
        count++;
        update = true;
        rowId = row._id;
      }
    }
    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: [rowId],
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })
          axios.post(prod() + '/api/file/highlights/documents/deleteAll', {
            rowIds: [rowId],
          })
          .then((res) => console.log(res.data))
          .catch(e => console.log(e));
      
          if (count === 1 && update == true) {
            axios
              .post(prod() + '/api/documents/get', {
                document: deleted.ref.document._id,
              })
              .then((a) => {
                const doc = a.data;
      
                if (doc.locations.length === 0) {
                  axios //delete highlights for that document
                    .post(prod() + '/api/file/highlights/documents/deleteAll', {
                      rowIds: [rowId],
                    })
                    .then((res) => console.log(res.data))
                    .catch(e => console.log(e));
                } else {
                  for (let i = 0; i < doc.locations.length; i++) {
                    let sub = 0;
                    if (
                      doc.locations[i].topic &&
                      doc.locations[i].topic.toString() == topic._id.toString()
                    ) {
                      doc.locations.splice(i, 1);
                      axios
                        .post(prod() + '/api/documents/update/loc', {
                          case: this.props.case['_id'],
                          witness: this.props.witness['_id'],
                          document: doc._id,
                          loc: doc.locations,
                        })
                        .then(() => {})
                        .catch((e) => {
                          console.log('Invalid Input, Please Try Again', e);
                        });
                    } else {
                      sub++;
                    }
                  }
                }
              })
              .catch((e) => {
                console.log('ERROR', e);
              });
          }
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };
  deleteTopic = () => {
    if (this.state.deleted.ref && this.state.deleted.ref.document) {
      this.deleteDocs(this.state.q, this.state.topic, this.state.deleted);
    }
    axios
      .post(prod() + '/api/cases/delete/witness/topic/q', {
        case: this.state.case['_id'],
        witness: this.state.witness['_id'],
        topic: this.state.topic['_id'],
        d: this.state.deleted['_id'],
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        // console.log(use.data.case.witnesses);
        const items = [];

        axios
          .post(prod() + '/api/cases/get/witness/topic', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
            topic: this.props.topic['_id'],
          })
          .then((use) => {
            // console.log(use.data.topics.topic);
            for (let topi of use.data.topics.topic) {
              if ((topi.ref != '', topi.disc != '')) {
                items.push({
                  ref: topi.ref,
                  disc: topi.disc,

                  _id: topi._id,
                });
              }
            }

            // console.log(items);
            this.setState(
              {
                q: items,
                witness: this.state.witness,
                case: this.state.case,
                topic: use.data.topics,
                deleted: '',
                dShow: false,
              }, () => this.handleProgress());
          })
          .catch((e) => {
            console.log(e);
          });
      });
  };

  addItem = (item) => {
    if (
      (this.state.ref.value != undefined && this.state.ref.value != '') ||
      (this.state.disc.value != undefined && this.state.disc.value != '')
    ) {
      axios
        .post(prod() + '/api/cases/create/witness/topic/q', {
          witness: this.props.witness['_id'],
          case: this.props.case['_id'],
          topic: this.props.topic['_id'],
          reference: this.state.ref,
          disclosure: this.state.disc,
          // id: id.data._id,
        })
        .then((use) => {
          console.log('created row');
          for (let a of use.data.case.witnesses) {
            if (a._id == this.props.witness['_id']) {
              this.props.updateWitness(use.data.case, a);
            }
          }
          const items = [];
          axios
            .post(prod() + '/api/cases/get/witness/topic', {
              case: this.props.case['_id'],
              witness: this.props.witness['_id'],
              topic: this.props.topic['_id'],
            })
            .then((use) => {
              for (let topi of use.data.topics.topic) {
                items.push({
                  _id: topi._id,
                  ref: topi.ref,
                  disc: topi.disc,
                });
              }

              this.setState({
                q: items,
                witness: this.props.witness,
                case: this.props.case,
                topic: use.data.topics,
                disc: {
                  value: '',
                  rows: 1,
                },
                delete: '',
                ref: {
                  value: '',
                  rows: 1,
                },
              }, () => this.handleProgress());
              this.goToRef(item);
            })
            .catch((e) => {
              // alert('Did not save. Please refresh and try again.');
              console.log('ERROR', e);
            });
        });
      // });
    }
  };
  saveItem(value) {
    console.log("save item")
    // LOOP FOR REMOVING EMPTY ELEMENT
    const qDupe = [...this.state.q];
    const index = this.state.q.findIndex((row) => row._id === value._id);
    if (value.ref.value === '' && value.disc.value === '') {
      qDupe.splice(index, 1);
      this.setState({ q: qDupe }, () => this.handleProgress());
    } else {
      qDupe[index] = value;
      this.setState({ q: qDupe }, () => this.handleProgress());
    }

    axios
      .post(prod() + '/api/cases/update/witness/topic', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        topic: this.state.topic['_id'],
        q: qDupe,
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        // alert('Did not save. Please go back or refresh and try again.');
      });
  }
  handleDeleteClose = () =>
    this.setState({ documentDeleteShow: false, deleted: null });

  handleDocumentDeleteShow = (topic, doc) => {
    this.setState({ documentDeleteShow: true, deleted: doc });
  };
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, deleted: e });

  // Syncs the backend with the order change

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(({ q }) => ({
      q: arrayMove(q, oldIndex, newIndex),
      dragging: false,
    }));

    axios
      .post(prod() + '/api/cases/update/witness/topic', {
        witness: this.state.witness['_id'],
        case: this.state.case['_id'],
        topic: this.state.topic['_id'],
        q: this.state.q,
      })
      .then((use) => {})
      .catch((err) => {
        console.err('Topic sort backend sync failed');
        console.log(err);
      });
  };

  SortableItem = sortableElement(({ value, i }) => {
    let topic = {};
    // console.log(this.state.q[0]);
    // console.log(value);
    topic = (
      <TopicRow
        value={value}
        index={i}
        saveItem={this.saveItem}
        darkMode={this.props.darkMode}
        q={this.state.q.length}
        handleDocumentDeleteShow={this.handleDocumentDeleteShow}
        topic={this.props.topic}
        handleDShow={this.handleDShow}
        addRef={this.addRef}
        textInput1={this.textInput1}
        textInput2={this.textInput2}
        topic={this.state.topic._id}
        uploadDocModal={this.state.uploadDocModal}
        linkRef={this.linkRef}
        addRef={this.addRef}
        createLink={this.createLink}
        handleCreateClose={this.openUploadDocModal}
        linkErr={this.state.linkErr}
        darkMode={this.props.darkMode}
        witness={this.props.witness}
        case={this.props.case}
        addExistingDoc={this.addExistingDoc}
        addExistingLink={this.addExistingLink}
        addLink={this.addLink}
        addLinkState={this.state.addLinkState}
        handleAddClose={this.handleAddClose}
        showDelete={this.state.documentDeleteShow}
        handleDelete={this.handleDelete}
        handleDeleteClose={this.handleDeleteClose}
        addExistingDocOnRow={this.addExistingDocOnRow}
        docs={this.state.docs}
        createPDF={this.createPDF}
      />
    );

    return topic;
  });

  SortableContainer = sortableContainer(({ children }) => {
    return children;
  });

  // add highlights after the row is saved
  //   after refId, and document Id are present, add the highlights to the db
  componentDidUpdate(prevProps, prevState) {
    const url = prod() + '/api/file/highlights/add-highlights';
    const { highlights, link } = this.state;

    if (prevState.q !== this.state.q) {
      if (this.state.rowId || this.state.link || this.state.highlights) {
        if (this.state.rowId) {
          axios
            .post(url, { refId: this.state.rowId, highlights, link })
            .then((res) => {
              this.setState({ highlights: [], link: '', rowId: '' });
            });
        } else if (this.state.addExistingDoc) { //after adding an existing doc from new row
          const refId = this.state.q[this.state.q.length - 1]._id;
          this.createPDF(refId, link);
        } else if (this.state.q.length > 0 && this.state.link !== '') {
          const refId = this.state.q[this.state.q.length - 1]._id;
          // console.log('HIGHLIGHTS: ', highlights);
          // console.log('REFID: ', refId);
          axios.post(url, { refId, highlights, link }).then((res) => {
            // console.log('after saving the highlights: ', res.data);
            this.setState({ highlights: [], link: '' });
          });
        }
      }
    }
  }
  saveHighlights(highlights, link) {
    this.setState({ highlights, link });
    // console.log('saved highlights to state!');
  }

  //create pdf table with PDF schema when a document is added to a row
  createPDF(rowId, link) {
    axios
      .post(prod() + '/api/file/highlights/create', { refId: rowId, link })
      .then((res) => {
        this.setState({ rowId: '', link: '', addExistingDoc: false });
      })
      .catch(e => console.log(e));
  }

  // triggered when adding an existing document from a new row
  saveLinkToCreatePDF(link) {
    this.setState({ link, addExistingDoc: true });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash'>
        {' '}
        <Container fluid className='scope'>
          <Row id='dash-add'>
            <Col md={5}>
              <h1 className='topic-title w-name'>
                Topic: <span>{this.state.topic.name} </span>
              </h1>
              <ProgressBar now={Math.round(this.state.progress)} />
            </Col>
            <Col md={7}>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <div className='add-btn add-btn-p-top topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className='add-case'>
                          <img
                            className='logo-title'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
            </Col>
          </Row>

          <div className='cases'>
            <Form id='topic-form'>
              <div ref={this.divElement2} className='d-none d-md-block'>
                <Fade>
                  <Row className='titles'>
                    <Col md={4}>
                      <Row>
                        <div className='hov-container'>
                          <h3>References</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.reference}
                          ></img>
                        </div>
                      </Row>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <div className='hov-container'>
                          <h3>Questions</h3>
                          <img
                            className='hoverHelp'
                            alt='image2'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                            onClick={this.question}
                          ></img>
                        </div>
                      </Row>
                    </Col>

                    <Col md={1}></Col>
                  </Row>
                </Fade>
              </div>

              <TopicModal
                note={this.state.note}
                hideNote={this.note}
                question={this.state.question}
                hideQuestion={this.question}
                reference={this.state.reference}
                hideReference={this.reference}
                handleDTSubmit={this.handleDTSubmit}
                handleDTClose={this.handleDTClose}
                dTShow={this.state.dTShow}
                deleteTopic={this.deleteTopic}
                handleDClose={this.handleDClose}
                dShow={this.state.dShow}
                darkMode={this.props.darkMode}
                user={this.state.user}
                handleSubmit={this.handleSubmit}
                handleClose={this.handleClose}
                show={this.state.show}
                handleHClose={this.handleHClose}
                hShow={this.state.hShow}
                handleEClose={this.handleEClose}
                q={this.state.q}
                topic={this.state.topic}
                eShow={this.state.eShow}
              />
              <LinkDocument
                uploadDocModal={this.state.uploadDocModal}
                linkRef={this.linkRef}
                addRef={this.addRef}
                createLink={this.createLink}
                openUploadDocModal={this.openUploadDocModal}
                linkErr={this.state.linkErr}
                darkMode={this.props.darkMode}
                witness={this.props.witness}
                case={this.props.case}
                addExistingDoc={this.addExistingDoc}
                addExistingLink={this.addExistingLink}
                addLinkState={this.state.addLinkState}
                handleAddClose={this.handleAddClose}
                showDelete={this.state.documentDeleteShow}
                handleDelete={this.handleDelete}
                handleDeleteClose={this.handleDeleteClose}
                saveHighlights={this.saveHighlights}
                docs={this.state.docs}
                saveLinkToCreatePDF={this.saveLinkToCreatePDF}
              />
              <Fade>
                <div>
                  <this.SortableContainer
                    onSortEnd={this.onSortEnd}
                    onSortStart={this.onDragStart}
                    useDragHandle
                  >
                    <div>
                      {this.state.q.map((value, index) => (
                        <this.SortableItem
                          key={`topic-dnd--${index}`}
                          index={index}
                          i={index}
                          value={value}
                        />
                      ))}
                    </div>
                  </this.SortableContainer>

                  {/* Laptop screen text area remove */}
                  <NewRow
                    handleUpdate={this.handleUpdate}
                    textInput3={this.textInput3}
                    col1Value={this.state.ref.value}
                    col1Name='ref'
                    addItem={this.addItem}
                    openUploadDocModal={this.openUploadDocModal}
                    col2Placeholder={'Enter Question'}
                    col2Value={this.state.disc.value}
                    col2Name='disc'
                  />
                </div>
              </Fade>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Topic);
