import React, { Component } from 'react';
import './ErrorScreen.css';

class ErrorScreen extends Component {
  constructor() {
    super();
    this.state = {}
  }

  render() {
    return (
      <div className="errorScreen">
        <div className="errorMessage">
          {this.props.message}
        </div>
      </div>
    )
  }
}

export default ErrorScreen;