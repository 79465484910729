import React, { Component } from 'react';
import { Form, Button, Container } from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';

export const logPageView = () => {
  window.analytics.page('Support Settings');
};
export default class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      bodyText: '',
      loading: null,
      showError: false,
      showSuccess: false,
    };
  }

  componentDidMount() {
    logPageView();
    axios.get(prod() + '/api/users/current').then((user) => {
      this.setState({
        email: user.data.email,
      });
    });
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  sendToBackEnd = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    const { bodyText } = this.state;
    const email = this.state.email;

    try {
      const res = await axios.post(prod() + '/api/sendEmail', {
        email,
        bodyText,
      });
      console.log(res);

      if (res.data == 1) {
        this.setState({
          loading: false,
          showSuccess: true,
          email: '',
          bodyText: '',
        });
      } else {
        this.setState({
          showError: true,
        });
      }
    } catch (sendMessageError) {
      console.log('Error in Sending Email', sendMessageError);
    }
  };

  render() {
    const { loading, showError, showSuccess, email, bodyText } = this.state;
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;

    if (showError) {
      return (
        <div className='alert alert-danger thanks-msg'>
          <h3>
            Sorry, there was some error while sending the email. Please try
            again later
          </h3>
        </div>
      );
    }
    if (showSuccess) {
      setTimeout(() => {
        this.setState({
          showSuccess: false,
        });
      }, 4000);
    }
    const isEnabled = this.state.bodyText && this.state.bodyText.trim() != '';
    return (
      <div>
        <Container>
          <div id='user-settings'>
            <Form onSubmit={this.sendToBackEnd}>
              <h2>Support</h2>
              {loading && (
                <div>
                  <p className='alert alert-primary thanks-msg'>
                    Sending email...{'  '}
                    <span
                      className='spinner-border spinner-border-sm'
                      role='status'
                    ></span>
                  </p>
                </div>
              )}

              {showSuccess && !loading && (
                <div>
                  <p className='alert alert-success thanks-msg'>
                    We will get back to you as soon as possible
                  </p>
                </div>
              )}
              <h3 id='contact'>Contact Us By Email</h3>
              <Form name='contact' method='POST' data-netlify='true'>
                <Form.Group controlId='formBasicEmail'>
                  <Form.Control type='text' value={this.state.name} hidden />
                </Form.Group>

                <Form.Group controlId='exampleForm.ControlTextarea1'>
                  <Form.Control
                    as='textarea'
                    rows='5'
                    name='bodyText'
                    placeholder='Enter Message'
                    value={bodyText}
                    onChange={this.handleChange('bodyText')}
                  />
                </Form.Group>
                <Button
                  className='btn btn-dark action action-s'
                  block
                  type='submit'
                  variant='primary'
                  disabled={!isEnabled}
                >
                  Send Message
                </Button>
              </Form>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
