import React, { Component } from 'react';
// import classnames from 'classnames';
import { connect } from 'react-redux';
import { registerUser, registerFirm } from '../../../actions/authActions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import RegisterFirm from './FirmRegistrationSteps/RegisterFirm';
// import RegisterPlan from './FirmRegistrationSteps/RegisterPlan';
import RegisterUser from './FirmRegistrationSteps/RegisterUser';
// import RegisterPayment from './FirmRegistrationSteps/RegisterPayment';
import { loginUser } from '../../../actions/authActions';

export const logPageView = () => {
  window.analytics.page('Register');
};

class Registration extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount() {
    logPageView();
    window.scrollTo(0, 0);
    if (this.props.auth.isAuthenticated) {
      window.location.href = '/';
    }
  }
  render() {
    return <RegisterUser loginUser={this.props.loginUser} />;
  }
}
Registration.propTypes = {
  registerUser: PropTypes.func.isRequired,
  registerFirm: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  registerUser,
  registerFirm,
  loginUser,
})(withRouter(Registration));
