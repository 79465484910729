import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Icons from './../Navigation/Icons';
import ToggleNav from '../Navigation/ToggleNav';

const Settings = (props) => {
  return (
    <>
      <Icons
        dialogClassName={props.darkMode ? 'dark-modal' : ''}
        darkMode={props.darkMode}
        changeLocation={props.changeLocation}
        getWitness={props.getWitness}
        cases={props.cases}
        setNavExpanded={props.setNavExpanded}
        goInfo={props.goInfo}
        goHome={props.goHome}
        loadOut={props.loadOut}
        goSettings={props.goSettings}
        goActivity={props.goActivity}
        darkMode={props.darkMode}
        getDoc={props.getDoc}
        goAudioTranscription={props.goAudioTranscription}
      />

      <div className='expanded'>
        <div id='side-nav-open' className='side-nav-open-w'>
          <div className='side-nav-open-title side-nav-open-title-w'>
            <Row>
              <Col md={12}>
                <div>
                  <h5>Settings</h5>
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>

            <br />

            <div>
              <div>
                {' '}
                <Col md={12}>
                  <div>
                    <div>
                      <Link to='/settings' className='cases-items'>
                        <ListGroup.Item className='parent-side-nav untouch'>
                          <div className='icon-img'>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/gen_hzoarh.svg'></img>
                            <p className='wit-title wit-title-z'>User</p>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'></img>
                          </div>
                        </ListGroup.Item>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <div>
                      <Link to='/settings/firm' className='cases-items'>
                        <ListGroup.Item className='parent-side-nav untouch'>
                          <div className='icon-img'>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/weight-balance_dz0uzi.svg'></img>
                            <p className='wit-title wit-title-z'>Firm</p>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'></img>
                          </div>
                        </ListGroup.Item>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <div>
                      <Link to='/settings/members' className='cases-items'>
                        <ListGroup.Item className='parent-side-nav untouch'>
                          <div className='icon-img'>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/pea_zzehyw.svg'></img>
                            <p className='wit-title wit-title-z'>Team</p>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'></img>
                          </div>
                        </ListGroup.Item>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <div>
                      <Link to='/settings/payment' className='cases-items'>
                        <ListGroup.Item className='parent-side-nav untouch'>
                          <div className='icon-img'>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/c_nw7qom.svg'></img>
                            <p className='wit-title wit-title-z'>Payment</p>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'></img>
                          </div>
                        </ListGroup.Item>
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div>
                    <div>
                      <Link to='/settings/support' className='cases-items'>
                        <ListGroup.Item className='parent-side-nav untouch'>
                          <div className='icon-img'>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/sup_dpemcx.svg'></img>
                            <p className='wit-title wit-title-z'>Support</p>
                            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'></img>
                          </div>
                        </ListGroup.Item>
                      </Link>
                    </div>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </div>

        <div id='side-nav-open-mobile'>
          <div className='side-nav-open-title side-nav-open-title-w'>
            <div>
              <h5>Sections </h5>
            </div>
            <div>
              <p className='numberOfItems'></p>
            </div>
          </div>
          <div>
            <div>
              {' '}
              <Col md={12}>
                <div>
                  <Link to='/settings' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch'>
                      <div className='icon-img'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/gen_hzoarh.svg'
                          alt='image12'
                        ></img>
                        <p className='wit-title wit-title-z'>User</p>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                          alt='image22'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link
                    to='/settings/firm'
                    // to='#'
                    className='cases-items'
                  >
                    <ListGroup.Item className='parent-side-nav untouch'>
                      <div className='icon-img'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/weight-balance_dz0uzi.svg'
                          alt='image31'
                        ></img>
                        <p className='wit-title wit-title-z'>Firm</p>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                          alt='image4'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link to='/settings/members' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch'>
                      <div className='icon-img'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/pea_zzehyw.svg'
                          alt='image1'
                        ></img>
                        <p className='wit-title wit-title-z'>Collaborators</p>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                          alt='image6'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link to='/settings/payment' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch'>
                      <div className='icon-img'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/c_nw7qom.svg'
                          alt='image7'
                        ></img>
                        <p className='wit-title wit-title-z'>Payment</p>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                          alt='image8'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link to='/settings/support' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch'>
                      <div className='icon-img'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/sup_dpemcx.svg'
                          alt='image8'
                        ></img>
                        <p className='wit-title wit-title-z'>Support</p>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                          alt='image9'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
            </div>
          </div>
        </div>

        <div id='side-nav-open-mobile'>
          <div className='side-nav-open-title side-nav-open-title-w'>
            <h5>Sections </h5>

            <p className='numberOfItems'></p>
          </div>

          <div>
            {' '}
            <Col md={12}>
              <div>
                <Link
                  to='/settings'
                  className='cases-items'
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/gen_hzoarh.svg'
                        alt='image21'
                      ></img>
                      <p className='wit-title wit-title-z'>User</p>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        alt='image31'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/settings/firm'
                  // to='#'
                  className='cases-items'
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/weight-balance_dz0uzi.svg'
                        alt='image14'
                      ></img>
                      <p className='wit-title wit-title-z'>Firm</p>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        alt='image51'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/settings/members'
                  className='cases-items'
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/pea_zzehyw.svg'
                        alt='image19'
                      ></img>
                      <p className='wit-title wit-title-z'>Collaborators</p>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        alt='image01'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/settings/payment'
                  className='cases-items'
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/c_nw7qom.svg'
                        alt='image1'
                      ></img>
                      <p className='wit-title wit-title-z'>Payment</p>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        alt='image1'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/settings/support'
                  className='cases-items'
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/sup_dpemcx.svg'
                        alt='image1'
                      ></img>
                      <p className='wit-title wit-title-z'>Support</p>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        alt='image1'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
          </div>
        </div>
      </div>
      <ToggleNav setNavExpanded={props.setNavExpanded} />
    </>
  );
};

export default Settings;
