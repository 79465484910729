import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, ButtonToolbar, ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../prod';
import DashboardComment from './DashboardComment';
class DashboardCaseList extends Component {
  constructor(props) {
    super(props);
    let preTrial = [],
      trial = [],
      archived = [];
    this.props.cases.map((caseItem) => {
      switch (caseItem.status) {
        case 'PRE_TRIAL':
          preTrial.push(caseItem);
          break;
        case 'TRIAL':
          trial.push(caseItem);
          break;
        case 'ARCHIVED':
          archived.push(caseItem);
          break;
      }
      let commentModal = false;
      let cases = {};
    });

    preTrial = preTrial.sort((a, b) => a.orderIndex - b.orderIndex);
    trial = trial.sort((a, b) => a.orderIndex - b.orderIndex);
    archived = archived.sort((a, b) => a.orderIndex - b.orderIndex);
    this.state = {
      preTrial,
      trial,
      archived,
    };
  }

  componentWillReceiveProps(newProps) {
    if (newProps.cases != this.props.cases) {
      let preTrial = [],
        trial = [],
        archived = [];
      newProps.cases.map((caseItem) => {
        switch (caseItem.status) {
          case 'PRE_TRIAL':
            preTrial.push(caseItem);
            break;
          case 'TRIAL':
            trial.push(caseItem);
            break;
          case 'ARCHIVED':
            archived.push(caseItem);
            break;
        }
      });

      preTrial = preTrial.sort((a, b) => a.orderIndex - b.orderIndex);
      trial = trial.sort((a, b) => a.orderIndex - b.orderIndex);
      archived = archived.sort((a, b) => a.orderIndex - b.orderIndex);

      this.setState({ preTrial, trial, archived });
    } else {
      console.log('Nope');
    }
  }

  handleImportant = (ev, cases, important, index) => {
    ev.stopPropagation();
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/case', {
          cases: cases,
          important: important,
          user: s,
        })
        .then((user) => {
          if (user.data.status == 'PRE_TRIAL') {
            const data = [...this.state.preTrial];
            data[index].important = user.data.important;
            this.setState({ data });
          } else if (user.data.status == 'TRIAL') {
            const data = [...this.state.trial];
            data[index].important = user.data.important;
            this.setState({ data });
          } else if (user.data.status == 'ARCHIVED') {
            const data = [...this.state.archived];
            data[index].important = user.data.important;
            this.setState({ data });
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };
  handleCommentModal = (ev, cases, index) => {
    ev.stopPropagation();
    this.setState({
      commentModal: true,
      cases: { cases, index },
    });
  };
  handleCommentModalClose = () => {
    this.setState({
      commentModal: false,
      cases: {},
    });
  };
  handleComment = (cases, comment, index) => {
    axios
      .post(prod() + '/api/comments/case', {
        case: cases,
        comment: comment,
      })
      .then((user) => {
        if (cases.status == 'PRE_TRIAL') {
          const data = [...this.state.preTrial];
          data[index].comments.push(comment);
          this.setState({ data });
        } else if (cases.status == 'TRIAL') {
          const data = [...this.state.trial];
          data[index].comments.push(comment);
          this.setState({ data });
        } else {
          const data = [...this.state.archived];
          data[index].comments.push(comment);
          this.setState({ data });
        }
      })

      .catch((err) => {
        console.log('MSG in err', err);
      });
  };

  onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    let sourceList = source.droppableId;
    let destinationList = destination.droppableId;

    if (source.droppableId === destination.droppableId) {
      let listName = source.droppableId;
      const newList = this.reorder(
        this.state[listName],
        source.index,
        destination.index,
        source,
        destination
      );
      this.setState({ [listName]: newList });
    } else {
      let status = '';
      switch (destination.droppableId) {
        case 'preTrial':
          status = 'PRE_TRIAL';
          break;
        case 'trial':
          status = 'TRIAL';
          break;
        default:
          status = 'ARCHIVED';
      }
      // console.log("status changed to: ", status)
      axios
        .post(prod() + '/api/cases/updateStatus', {
          id: this.state[sourceList][source.index]._id,
          status: status,
        })
        .then((res) => {
          // console.log("case with the status changesd: ", res.data);
          const status = res.data.status;
          const caseId = res.data._id;
          if (status == 'PRE_TRIAL') {
            const index = this.state.preTrial.findIndex(c => c._id === caseId);
            const data = [...this.state.preTrial];
            data.splice(index, 1, res.data)
            this.setState({ preTrial: data });
          } else if (status == 'TRIAL') {
            const index = this.state.preTrial.findIndex(c => c._id === caseId);
            const data = [...this.state.trial];
            data.splice(index, 1, res.data)
            this.setState({ trial: data });
          } else if (status == 'ARCHIVED') {
            const index = this.state.preTrial.findIndex(c => c._id === caseId);
            const data = [...this.state.archived];
            data.splice(index, 1, res.data)
            this.setState({ archived: data });
          } 
        })
        .catch((error) => {
          console.log('Error while changing status');
          console.log(error);
          return;
        });
      this.move(sourceList, destinationList, source, destination);

      // Update the status of the case in the backend
    }
  };

  reorder = (list, startIndex, endIndex, source, destination) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    // Update the index in the backend
    axios

      .post(prod() + '/api/cases/updateOrderIndex', {
        id: result[endIndex]._id,
        newIndex: endIndex,
        status: result[endIndex].status,
        client: result[endIndex].client,
        newStatus: destination.droppableId,
        oldStatus: source.droppableId,
        firm: this.props.firm,
        oldIndex: endIndex,
        newIndex: startIndex,
      })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(this.state[source]);
    const destClone = Array.from(this.state[destination]);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    this.setState({ [source]: sourceClone, [destination]: destClone });
    // Update the index in the backend
    axios
      .post(prod() + '/api/cases/updateOrderIndex', {
        id: removed._id,
        newIndex: droppableDestination.index,
        status: removed.status,
        client: removed.client,
        newStatus: droppableSource.droppableId,
        oldStatus: droppableDestination.droppableId,
        firm: this.props.firm,
        oldIndex: droppableDestination.index,
        newIndex: droppableSource.index,
      })
      .then((response) => {
        // console.log('Reorder response!');
        // console.log(response);
        // this.props.updateCase();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  grid = 8;

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: 0,
    margin: `0 0 ${this.grid}px 0`,

    background: this.props.darkMode
      ? isDragging
        ? '#202224'
        : '#424242'
      : isDragging
      ? 'rgb(234, 239, 244)'
      : 'white',

    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: this.props.darkMode
      ? isDraggingOver
        ? '#202224 !important'
        : '#202224 !important'
      : isDraggingOver
      ? '#f9f9f9 !important'
      : '#f9f9f9 !important',

    padding: this.grid,
    minHeight: '90px',
  });

  getIndividualCase = (item, i) => {
    const progress = this.props.handleProgress(item.witnesses);
    const caseItem = {...item, progress };

    return (
      <ButtonToolbar className='side-nav-t-t side-nav-t-t-m'>
        <div className='case-btn-side'>
          <div className='w-home-2'>
            <ButtonToolbar className=' side-nav-t-overide'>
              <Button
                className=' add-case'
                onClick={(e) =>
                  this.props.handleDShow(
                    caseItem['_id'],
                    caseItem['orderIndex'],
                    caseItem['status'],
                    e
                  )
                }
              >
                <img
                  className='logo-title logo-title-2'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                ></img>
              </Button>
            </ButtonToolbar>
          </div>
          <div className='  w-home-2'>
            <ButtonToolbar className=' side-nav-t-overide'>
              <Button
                className=' add-case'
                onClick={(e) => this.props.handleSShow(caseItem['_id'], e)}
              >
                <img
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                ></img>
              </Button>
            </ButtonToolbar>
          </div>

          {/* {caseItem.comments.length > 0 ? (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <Button
                  className='add-case'
                  onClick={(e) => this.handleCommentModal(e, caseItem, i)}
                >
                  <img
                    className='logo-title '
                    src={require('../../../pics/app/filled_comment_grey.svg')}
                  ></img>
                </Button>
              </ButtonToolbar>
            </div>
          ) : (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <Button
                  className=' add-case'
                  onClick={(e) => this.handleCommentModal(e, caseItem, i)}
                >
                  <img
                    className='logo-title card_important_comment'
                    src={require('../../../pics/app/comment_grey.svg')}
                  ></img>
                </Button>
              </ButtonToolbar>
            </div>
          )} */}
          {caseItem.important ? (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode
                          ? 'dark-tooltip-add'
                          : 'tooltip-add'
                      }
                    >
                      Unmark as Important
                    </Tooltip>
                  }
                >
                  <Button
                    className=' add-case'
                    onClick={(e) => this.handleImportant(e, caseItem, false, i)}
                  >
                    <img
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642356/Aleri/app/filled_star_grey_fzinfp.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          ) : (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode
                          ? 'dark-tooltip-add'
                          : 'tooltip-add'
                      }
                    >
                      Mark as Important
                    </Tooltip>
                  }
                >
                  <Button
                    className=' add-case'
                    onClick={(e) => this.handleImportant(e, caseItem, true, i)}
                  >
                    <img
                      className='logo-title card_important_comment'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642356/Aleri/app/star_grey_ixp4e2.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          )}

          <ProgressBar now={Math.round(caseItem.progress)} />
          {/* <div className='case-type'>
            <p>Criminal</p>
          </div> */}

          <br />
          <div className='case-btn-side-t'>
            <p className='parent-p'>{caseItem.name}</p>
            <p className='parent-p-d'>{caseItem.client}</p>
          </div>
        </div>
      </ButtonToolbar>
    );
  };

  render() {

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className='case-drag-single-list'>
          <h4>Pre Trial Cases</h4>
          <Droppable droppableId='preTrial'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.preTrial.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='parent-side-nav unfocus'
                        onMouseDown={(e) => e.currentTarget.focus()}
                        onClick={() =>
                          this.props.getW(
                            item,
                            (() => {
                              for (
                                let i = 0;
                                i < this.props.cases.length;
                                i++
                              ) {
                                if (item._id == this.props.cases[i]['_id']) {
                                  return i;
                                }
                              }
                            })()
                          )
                        }
                      >
                        {this.getIndividualCase(item, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className='case-drag-single-list'>
          <h4>Trial Cases</h4>
          <Droppable droppableId='trial'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.trial.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='parent-side-nav unfocus'
                        onMouseDown={(e) => e.currentTarget.focus()}
                        onClick={() =>
                          this.props.getW(
                            item,
                            (() => {
                              // console.log('TRIAL', item, index);
                              for (
                                let i = 0;
                                i < this.props.cases.length;
                                i++
                              ) {
                                if (item._id == this.props.cases[i]['_id']) {
                                  return i;
                                }
                              }
                            })()
                          )
                        }
                      >
                        {this.getIndividualCase(item, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className='case-drag-single-list'>
          <h4>Archived Cases</h4>
          <Droppable droppableId='archived'>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={this.getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.archived.map((item, index) => (
                  <Draggable
                    key={item._id}
                    draggableId={item._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                        className='parent-side-nav unfocus'
                        onMouseDown={(e) => e.currentTarget.focus()}
                        onClick={() => {
                          this.props.getW(
                            item,
                            (() => {
                              // console.log('ARCHIVED', item, index);
                              for (
                                let i = 0;
                                i < this.props.cases.length;
                                i++
                              ) {
                                if (item._id == this.props.cases[i]['_id']) {
                                  return i;
                                }
                              }
                            })()
                          );
                        }}
                      >
                        {this.getIndividualCase(item, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <DashboardComment
            commentModal={this.state.commentModal}
            case={this.state.cases}
            handleCommentModalClose={this.handleCommentModalClose}
            darkMode={this.props.darkMode}
          />
        </div>
      </DragDropContext>
    );
  }
}

export default DashboardCaseList;
