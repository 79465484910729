import React, { Component } from 'react';
import {
  Button,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import WitExport from './../Witness/WitExport';
import CaseExport from './CaseExport';
export default class CaseModal extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uName: '',

      //add more
      addMoreClicked: 1,
      wNames : {},
      errors: "",
      removeLocations: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    //
    this.removeOne = this.removeOne.bind(this);
    this.createRef = React.createRef();
  }
  onChange(e) {
    if (e.target.name === 'uName') {
      this.setState({ [e.target.name]: e.target.value });
    } else {
      this.setState({
        wNames: {
          ...this.state.wNames,
          [e.target.name]: e.target.value
        }
      });
    }
  }

  handleSubmit(e, noOfNames) {
    let witnesses = [];
    let enteredWitnesses = {};

    for (const witness of this.props.case.witnesses) {
      witnesses.push(witness.name.toLowerCase());
    }

    let errors = {};
    
    for (let i = 1; i <= noOfNames; i++) {
      const name = `name${i}`;
      const names = this.state.wNames;
      if (names[name]) {
        //checking to see if the user input duplicate names
        if (enteredWitnesses[names[name]]) {
          errors[`error${i}`] = 'Duplicate Witness, Please Remove One'
        } else {
          enteredWitnesses[names[name]] = true;
        }

        //checking to see if it already exists
        if (witnesses.includes(names[name].toLowerCase())) {
          errors[`error${i}`] = 'Witness Already Exists'
        }
      }
    };

    let errorExists = Object.keys(errors).length > 0;

    if (errorExists) {
      this.setState({ errors });
    } else {
      let enteredWitnessesArr = Object.keys(enteredWitnesses);

      if (enteredWitnessesArr.length !== 0 && this.props.case && this.props.case.witnesses) {
        this.props.handleWSubmit(enteredWitnessesArr, e);
        this.setState({ 
          wNames: {},
          errors: {},
          addMoreClicked: 1,
          removeLocations: [],
          user: '',
        });
      } else {
        this.setState({
          user: 'Please fill out witness name field',
        });
      }
    }
  }
  componentDidMount() {
    this.setState({
      uName: this.props.uWName,
    });

  }
  componentDidUpdate(prevProps) {
    if (prevProps.case !== this.props.case){
    }
    if (prevProps.uWName !== this.props.uWName) {
      this.setState({
        uName: this.props.uWName,
      });
    }
  }

  handleWClose = () => {
    this.setState({ user: "", name: "", uName: "", wNames: {}, errors: {}, removeLocations: [], addMoreClicked: 1, });
    this.props.handleWClose();
  }

  addMore = (clicked, remove) => {
    let forms = [];

    for (let i = 1; i <= clicked; i++) {
      if (!remove.includes(i)) {
        const form = (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
              {this.state.errors[`error${i}`] && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors[`error${i}`]}
                </div>
              )}
              <Form.Control
                type='text'
                placeholder='Enter Witness Name'
                value={this.state.wNames[`name${i}`] ? this.state.wNames[`name${i}`] : ''}
                onChange={this.onChange}
                name={`name${i}`}
                ref={clicked === 1 ? this.props.create : this.createRef}
              />
            </div>
            <img
              style={{ width: '10%'}}
              onClick={() => this.removeOne(i)}
              className="logo-title logo-title-l" 
              src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg"
            ></img>
          </div>
        )
        forms.push(form);
      }
    }

    if (forms.length === 0) {
      this.setState({ 
        user: 'Error: At Lease One Field Must Exist',
        addMoreClicked: this.state.addMoreClicked + 1,
      })
    }

    return forms;
  }

  removeOne = (nameLocation) => { 
    let names = {...this.state.wNames};
    names[`name${nameLocation}`] = '';

    let removeLocations = [...this.state.removeLocations];
    removeLocations.push(nameLocation);
    this.setState({ removeLocations, wNames: names });
  };

  render() {
    return (
      <div>
        {/* Witness modal from sidebar */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wSShow}
          onHide={this.props.handleWSClose}
        >
          <Form
            onSubmit={(e) =>
              this.props.handleWSSubmit({ name: this.state.uName }, e)
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Settings</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               {this.props.err && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.err}
                </div>
              )}
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Witness Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.props.edit}
                />
              </Form.Group>
              <Form.Label>Other Settings</Form.Label>
              <div>
                <ButtonToolbar>
                  <OverlayTrigger
                    key='bottom'
                    placement='bottom'
                    overlay={
                      <Tooltip
                        id={
                          this.props.darkMode
                            ? 'dark-tooltip-add'
                            : 'tooltip-add'
                        }
                      >
                        Export
                      </Tooltip>
                    }
                  >
                    <Button
                      onClick={this.props.handleEShow}
                      className='add-case'
                      className={
                        this.props.darkMode ? 'dark-add add-case' : 'add-case'
                      }
                    >
                      <img
                        className='logo-title log'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                      ></img>{' '}
                    </Button>
                  </OverlayTrigger>
                </ButtonToolbar>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.props.handleWSClose}
              >
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wShow}
          onHide={this.handleWClose}
        >
          <Form onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(e, this.state.addMoreClicked);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Create Witness</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Witness Name</Form.Label>
                {this.addMore(this.state.addMoreClicked, this.state.removeLocations)}
                </Form.Group>
                <Button 
                  variant='primary' 
                  style={{ marginRight: '10px' }} 
                  onClick={() => this.setState({ addMoreClicked: this.state.addMoreClicked + 1 }, () => this.createRef?.current?.focus())}
                >
                  Add More
                </Button>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleWClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Add Witness
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.wDShow}
          onHide={this.props.handleWDClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this witness?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleWDClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.deleteWitness}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* help */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Case Dashboard</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The Case Dashboard is where you can see an overview of your
                  case. In the left menu you will find the witnesses. Witnesses
                  can be placed in one of three different categories (Todo,
                  Doing, Done). You can export the entire case by clicking the
                  export button at the top right of the Case Dashboard.
                </p>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eCaseShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Entire Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Modal.Body>
                <CaseExport 
                  case={this.props.case}
                  timelineByWitness={this.props.timelineByWitness}
                  timelines={this.props.timelines}
                />
              </Modal.Body>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Entire Witness</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.witness && this.props.witness.topics ? (
                <WitExport
                  case={this.props.case}
                  witness={this.props.witness}
                  topics={this.props.witness.topics}
                  timelineByWitness={this.props.timelineByWitness}
                />
              ) : (
                <></>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Modal that opens what the user clicks on Fact Tags or Timeline widgets when there is no witness created */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showNoWitnessModalF}
          onHide={() => this.props.openNoWitnessModalF(false)}
        >
          <Modal.Header>
            <Modal.Title>No Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>There are no items in this widget, please create a witness and add facts in the fact sheet to see Fact Tag items.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.openNoWitnessModalF(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showNoWitnessModalT}
          onHide={() => this.props.openNoWitnessModalT(false)}
        >
          <Modal.Header>
            <Modal.Title>No Items</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>There are no items in this widget, please create a witness first.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.props.openNoWitnessModalT(false)}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
