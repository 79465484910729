import React from 'react';
import { Button } from 'react-bootstrap';
const SupportedBrowsers = () => {
  return (
    <>
      <div className='container main-unsupport'>
        <div className='element-div'>
          <h3>Welcome to Aleri!</h3>
          <p className='unsupport-msg'>
            The best way to prepare for your case is using Aleri on your
            computer. Head over there to start building your case.
          </p>
        </div>
        <div className='element-div'>
          <h6 className='not-comp'>Not at your computer?</h6>
          <Button
            className='btn action'
            block
            type='submit'
            variant='primary'
            onClick={() =>
              (window.location.href =
                'https://help.aleri.ca/en/collections/2747781-getting-started-with-aleri')
            }
          >
            Watch Tutorials
          </Button>
        </div>
      </div>
    </>
  );
};

export default SupportedBrowsers;
