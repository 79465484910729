import React, { Component } from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icons from './../Navigation/Icons';
import ToggleNav from '../Navigation/ToggleNav';

const InformationNav = (props) => {
  return (
    <>
      <Icons
        dialogClassName={props.darkMode ? 'dark-modal' : ''}
        darkMode={props.darkMode}
        changeLocation={props.changeLocation}
        getWitness={props.getWitness}
        cases={props.cases}
        setNavExpanded={props.setNavExpanded}
        goInfo={props.goInfo}
        goHome={props.goHome}
        goSettings={props.goSettings}
        goActivity={props.goActivity}
        loadOut={props.loadOut}
        darkMode={props.darkMode}
        getDoc={props.getDoc}
        goAudioTranscription={this.props.goAudioTranscription}
      />

      <div className='expanded'>
        <div id='side-nav-open' className='side-nav-open-w'>
          <div className='side-nav-open-title side-nav-open-title-w'>
            <Row>
              <Col md={12}>
                <div>
                  <h5>Sections </h5>
                </div>
              </Col>
            </Row>

            <br />

            <div>
              {' '}
              <Col md={12}>
                <div>
                  <Link to='/information/impeachment' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information info-strategy-main'>
                      <div className='icon-img info-strategy'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/img_uz8lfh.svg'
                          alt='image1'
                        ></img>
                        <p className='wit-title wit-title-z info-strategy-p'>
                          Impeachment
                        </p>
                        <img
                          alt='image2'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link
                    to='/information/framing'
                    // to='#'
                    className='cases-items'
                  >
                    <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information info-strategy-main'>
                      <div className='icon-img info-strategy'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/frame_ekhmss.svg'
                          alt='image3'
                        ></img>
                        <p className='wit-title wit-title-z info-strategy-p'>
                          Framing Questions
                        </p>
                        <img
                          alt='image4'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link to='/information/exposing' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information info-strategy-main'>
                      <div className='icon-img info-strategy'>
                        <img
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/frau_vdmmen.svg'
                          alt='image5'
                        ></img>
                        <p className='wit-title wit-title-z info-strategy-p'>
                          Exposing False Testimony
                        </p>
                        <img
                          alt='image6'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
              <Col md={12}>
                <div>
                  <Link to='/information/credibility' className='cases-items'>
                    <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information info-strategy-main'>
                      <div className='icon-img info-strategy '>
                        <img
                          alt='image7'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/diamond_uxktw3.svg'
                        ></img>
                        <p className='wit-title wit-title-z info-strategy-p'>
                          General Credibility
                        </p>
                        <img
                          alt='image19'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                        ></img>
                      </div>
                    </ListGroup.Item>
                  </Link>
                </div>
              </Col>
            </div>
          </div>
        </div>

        <div id='side-nav-open-mobile'>
          <div className='side-nav-open-title side-nav-open-title-w'>
            <h5>Sections </h5>
          </div>

          <div>
            {' '}
            <Col md={12}>
              <div>
                <Link
                  to='/information/impeachment'
                  className='cases-items'
                  // onClick={() => getT()}
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/img_uz8lfh.svg'
                        alt='image9'
                      ></img>
                      <p className='wit-title wit-title-z'>Impeachment</p>
                      <img
                        alt='image10'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/information/framing'
                  // to='#'
                  className='cases-items'
                  // onClick={() => getT()}
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/frame_ekhmss.svg'
                        alt='image11'
                      ></img>
                      <p className='wit-title wit-title-z'>Framing Questions</p>
                      <img
                        alt='image12'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/information/exposing'
                  className='cases-items'
                  // onClick={() => getT(
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information'>
                    <div className='icon-img'>
                      <img
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/frau_vdmmen.svg'
                        alt='image14'
                      ></img>
                      <p className='wit-title wit-title-z'>
                        Exposing False Testimony
                      </p>
                      <img
                        alt='image15'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
            <Col md={12}>
              <div>
                <Link
                  to='/information/credibility'
                  className='cases-items'
                  // onClick={() => getT(
                  onClick={props.setNavExpanded}
                >
                  <ListGroup.Item className='parent-side-nav untouch untouch-y untouch-information'>
                    <div className='icon-img'>
                      <img
                        alt='image16'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/diamond_uxktw3.svg'
                      ></img>
                      <p className='wit-title wit-title-z'>
                        General Credibility
                      </p>
                      <img
                        alt='image17'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/next-page_rwv8sl.svg'
                      ></img>
                    </div>
                  </ListGroup.Item>
                </Link>
              </div>
            </Col>
          </div>
        </div>
      </div>
      <ToggleNav setNavExpanded={props.setNavExpanded} />
    </>
  );
};

export default InformationNav;
