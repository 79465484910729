import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FocusExport from './FocusExport';
export default class FocusModal extends Component {
  render() {
    console.log("focus: ", this.props.focus)
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.show}
          onHide={this.props.handleClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Focus Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FocusExport
                focus={this.props.focus}
                topics={this.props.topics}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.hShow}
          onHide={this.props.handleHClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleHClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>What Is A Focus Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  The focus sheet is to be used at the very beginning of the
                  preparation of the cross-examination questions. You should
                  prepare the focus sheet first, then prepare the charts of the
                  evidence, and then at the end prepare the actual
                  cross-examination questions. The focus sheet is a brief
                  summary of the role of the witness in the case. You should
                  have first developed a general theory of your case and
                  everything you do in the case will revolve around that theory.
                  The focus sheet will incorporate your theory of the case and
                  is designed to keep you focused on your theory of the case,
                  and be a general road map for you subsequent preparation of
                  charts of evidence and the actual questions. The focus sheet
                  will include the following:
                </p>
                <ul>
                  <li>how the witness fits into your opponent’s case</li>

                  <li>
                    strengths and weaknesses of the witness, including any bias
                    or motive to fabricate
                  </li>
                  <li>
                    key fact that you want to bring out that support your theory
                  </li>
                  <li>
                    the overall goal of your cross-examination (that is
                    consistent with your theory of the case)
                  </li>
                  <li>
                    key words that you want to use in your questions (that
                    support your theory of the case)
                  </li>
                  <li>legal objections that are anticipate</li>
                  <li>
                    opening and closing questions that allow you to start and
                    finish strong (primacy/recency principle)
                  </li>
                  <li>
                    cross-examination techniques that you may wish to rely upon
                    (see list of techniques
                  </li>
                  <li>
                    documents you need to rely upon (such as prior inconsistent
                    statements)
                  </li>
                  <li>
                    topics of cross-examination that you anticipate (you will
                    want to break down the cross-examination into topics, of not
                    more than a page of questions each, and then prepare the
                    questions for each topic)
                  </li>
                </ul>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Link to='/settings/support'>
                <Button variant='outline-secondary'>Help</Button>
              </Link>
              <div style={{ flex: 1 }}></div>
              <Button variant='secondary' onClick={this.props.handleHClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.witnessShow}
          onHide={this.props.handleWitnessClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleWitnessClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Witness Name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Name and title of witness.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.witnessPhotoShow}
          onHide={this.props.handleWitnessPhotoClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleWitnessPhotoClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Witness Photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Photo of witness.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.relationShow}
          onHide={this.props.handleRelationClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleRelationClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Relationship to Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  What type of Witness are they? (complainant, eyewitness,
                  police officer, expert).
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.oppnent}
          onHide={this.props.handleOppnentClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleOppnentClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>How They Advance Opponent's Case?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Why is the opponent calling this witness?</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.bias}
          onHide={this.props.handleBiasClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleBiasClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Bias/Motivation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Do they have a bias, or interest in the outcome of the case?
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.strength}
          onHide={this.props.handleStrengthClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleStrengthClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Strenghts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>What factors might make them a strong witness?</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.weakness}
          onHide={this.props.handleWeakness}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleWeakness();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Weaknesses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>What factors weaken their evidence?</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.fact}
          onHide={this.props.handleFacts}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleFacts();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Facts to Bring Out</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  What key facts, relating to this witness, support your theory
                  of the case?
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.goals}
          onHide={this.props.handleGoals}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleGoals();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Goals/Focus of Examination</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  What is your goal in cross-examining the witness? How do they
                  fit into your theory of the case?
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.keyword}
          onHide={this.props.handleKeyword}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleKeyword();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Key Words</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Think of words that you can use that will make an impact while
                  you are questioning.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.objection}
          onHide={this.props.handleObjection}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleObjection();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Objections</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Anticipate some of the evidence issues that may arise with the
                  witness.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.openQue}
          onHide={this.props.handleOpenQuestion}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleOpenQuestion();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Opening Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Find a strong point to commence the cross-examination.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.closeQue}
          onHide={this.props.handleCloseQuestion}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleCloseQuestion();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Closing Question</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>Find a strong point to close the cross-examination.</p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.crossExam}
          onHide={this.props.handleCrossExam}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleCrossExam();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Cross-Examination Techniques</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  List some cross-examination techniques - See “Information and
                  Strategies” section.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.doc}
          onHide={this.props.handleDoc}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleDoc();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Documents</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  What documents are needed to question the witness such as
                  statements and transcripts.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.topic}
          onHide={this.props.handleTopic}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleTopic();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Topics</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='help'>
                <p>
                  Start listing possible topics of cross-examinations which are
                  areas where you will have up to a page of questions.
                </p>
              </div>
            </Modal.Body>
          </Form>
        </Modal>

        {/* link */}
      </>
    );
  }
}
