import React from 'react';
import Pulse from 'react-reveal/Pulse';

const PaymentLoading = () => {
  return (
    <div id='load'>
      <Pulse>
        <h1>Updating Account</h1>
        <div className="div-cent">
          <div class='lds-ring'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <img src='https://res.cloudinary.com/aleri/image/upload/v1593642359/Aleri/b-logo_bexcob.svg' />
          </div>
        </div>

      </Pulse>
    </div>
  );
};
export default PaymentLoading;
