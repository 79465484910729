import React, { Component } from 'react';
import { Col, Form, Button, Container, Row, Modal } from 'react-bootstrap';

import { StripeProvider } from 'react-stripe-elements';
import axios from 'axios';
import prod from '../../../prod';
import { Link } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';

import { Elements } from 'react-stripe-elements';

import moment from 'moment';

export const logPageView = () => {
  window.analytics.page('Payment Settings');
};
class Payments extends Component {
  constructor() {
    super();
    this.state = {
      firm: '',
      number: '',
      first_name: '',
      last_name: '',
      errors: '',
      success: '',
      user: {},
      old: {},
      plan: '',
      payment: {},
      members: [],
      show: false,
      dShow: false,
      admin: false,
      new_first_name: '',
      new_last_name: '',
      new_email: '',
      new_password: '',
      user_err: '',
      users: 0,
      to_delete: null,
      user_d__err: '',
      user_t__err: '',
      tShow: false,
      to_toggle: null,
      show: false,
      cShow: false,
      tog: false,
      errd: '',
      addCreditCart: false,
      bShow: false,
      fullFirm: {},
      errorUpgrade: false,
      trialEnd: false,
    };
    this.onChange = this.onChange.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.handleCShow = this.handleCShow.bind(this);
    this.handleCClose = this.handleCClose.bind(this);
    this.handleBShow = this.handleBShow.bind(this);
    this.handleBClose = this.handleBClose.bind(this);
    this.onSubmitPlan = this.onSubmitPlan.bind(this);
    this.togglePlan = this.togglePlan.bind(this);
    this.addCreditCartClose = this.addCreditCartClose.bind(this);
  }
  addCreditCartClose() {
    this.setState({
      addCreditCart: false,
    });
  }
  onSubmitPlan(a) {
    if (this.state.subscriptionId == 0) {
      this.setState({
        addCreditCart: true,
        show: false,
      });
    } else {
      if (
        (this.state.fullFirm.plan == 1 &&
          this.state.fullFirm.cases.length > 15) ||
        (this.state.fullFirm.plan == 1 &&
          this.state.fullFirm.cases.length > 15) ||
        (this.state.fullFirm.plan == 4 &&
          this.state.fullFirm.members.length > 1) ||
        (this.state.fullFirm.plan == 4 &&
          this.state.fullFirm.members.length > 1)
      ) {
        this.setState({
          errorUpgrade: true,
          show: false,
        });
      } else if (
        (this.state.fullFirm.plan == 2 &&
          this.state.fullFirm.cases.length > 100) ||
        (this.state.fullFirm.plan == 2 &&
          this.state.fullFirm.cases.length > 100) ||
        (this.state.fullFirm.plan == 5 &&
          this.state.fullFirm.members.length > 3) ||
        (this.state.fullFirm.plan == 5 &&
          this.state.fullFirm.members.length > 3)
      ) {
        this.setState({
          errorUpgrade: true,
          show: false,
        });
      } else {
        axios
          .post(prod() + '/api/stripe/subscription/update', {
            plan: a,
            customerId: this.state.firm,
            subscriptionId: this.state.subscriptionId,
          })
          .then((use) => {
            console.log(this.state.fullFirm);
            axios
              .post(prod() + '/api/firms/create/subscription', {
                _id: this.state.fullFirm._id,
                customerId: this.state.firm,
                subscriptionId: this.state.subscriptionId,
                plan: a,
              })
              .then((response) => {
                window.location.reload();
              })
              .catch((e) => {
                console.log('retreiving error' + e);
              });
          })
          .catch((e) =>
            this.setState({
              errd: 'Error: Please contact support to change plan',
            })
          );
      }
    }
  }
  togglePlan() {
    let tog = this.state.tog == false ? true : false;
    this.setState({ tog: tog });
  }
  handleCClose = () => this.setState({ cShow: false });
  handleCShow = () => this.setState({ cShow: true, addCreditCart: false });
  handleBClose = () => this.setState({ bShow: false });
  handleBShow = () => this.setState({ bShow: true, addCreditCart: false });
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  handleSubmit = (plan) => {};
  componentDidMount() {
    // console.log("component mount", this.props.firm)
    logPageView();
    if (this.props.firm) {
      axios
        .post(prod() + '/api/firms/get', {
          firm: this.props.firm,
        })
        .then((user) => {
          console.log('firm info: ', user.data);
          if (user.data.subscriptionId == 0) {
            const today = moment();
            const differenceInDays = today.diff(user.data.created_at, 'days');

            if (differenceInDays >= 7) {
              this.setState({ trialEnd: true });
            }

            this.setState({
              plan: 'Free Trial',
              subscriptionId: 0,
              firm: user.data.customerId,
              user: '',
              admin: 'true',
              fullFirm: user.data,
            });
          } else {
            axios
              .post(prod() + '/api/stripe/subscription/get', {
                subscriptionId: user.data.subscriptionId,
              })
              .then((a) => {
                console.log(a.data.subscription.plan);
                this.setState({
                  start: a.data.subscription.current_period_start,
                  finish: a.data.subscription.current_period_end,
                  plan: a.data.subscription.plan.name,
                  subscriptionId: a.data.subscription.id,
                  firm: user.data.customerId,
                  user: '',
                  admin: 'true',
                  fullFirm: user.data,
                });
              })
              .catch((err) => {
                console.log('MMEMEMME', err);
              });
          }
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    } else {
      axios
        .get(prod() + '/api/users/current')

        .then((use) => {
          axios
            .post(prod() + '/api/firms/get', {
              firm: use.data.firm,
            })
            .then((user) => {
              console.log('firm data: ', user.data);
              if (user.data.subscriptionId == 0) {
                const today = moment();
                const differenceInDays = today.diff(
                  user.data.created_at,
                  'days'
                );

                if (differenceInDays >= 7) {
                  this.setState({ trialEnd: true });
                }
                this.setState({
                  // start: a.data.subscription.current_period_start,
                  // finish: a.data.subscription.current_period_end,
                  plan: 'Free Trial',
                  subscriptionId: 0,
                  firm: user.data.customerId,
                  user: use.data.email,
                  admin: use.data.admin,
                  fullFirm: user.data,
                  members: user.data.members,
                });
              } else {
                axios
                  .post(prod() + '/api/stripe/subscription/get', {
                    subscriptionId: user.data.subscriptionId,
                  })
                  .then((a) => {
                    console.log(a.data.subscription.plan);
                    this.setState({
                      start: a.data.subscription.current_period_start,
                      finish: a.data.subscription.current_period_end,
                      plan: a.data.subscription.plan.name,
                      subscriptionId: a.data.subscription.id,
                      firm: user.data.customerId,
                      user: use.data.email,
                      admin: use.data.admin,
                      fullFirm: user.data,
                    });
                  })
                  .catch((err) => {
                    console.log('MMEMEMME', err);
                  });
              }
            })
            .catch((err) => {
              console.log('MSG in err', err);
            });
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  f;
  render() {
    console.log('state: ', this.state);
    var month = new Array();
    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';

    let plan;
    let statePlan = this.state.plan.toString();
    console.log(statePlan);
    if (this.state.plan == 'Free Trial') {
      plan = this.state.plan;
    } else if (
      statePlan == '1' ||
      statePlan == 'Aleri Basic' ||
      statePlan == 'Aleri Basic Month'
    ) {
      plan = 'Aleri Basic Month';
    } else if (
      statePlan == '2' ||
      statePlan == 'Aleri Pro' ||
      statePlan == 'Aleri Pro Month'
    ) {
      plan = 'Aleri Pro Month';
    } else if (
      statePlan == '3' ||
      statePlan == 'Aleri Business' ||
      statePlan == 'Aleri Business Month'
    ) {
      plan = 'Aleri Business Month';
    } else if (statePlan == '4' || statePlan == 'Aleri Basic Year') {
      plan = 'Aleri Basic Year';
    } else if (statePlan == '5' || statePlan == 'Aleri Pro Year') {
      plan = 'Aleri Pro Year';
    } else if (statePlan == '6' || statePlan == 'Aleri Business Year') {
      plan = 'Aleri Business Year';
    } else if (statePlan == '7' || statePlan == 'Aleri Month') {
      //2022
      plan = 'Aleri Month';
    } else if (statePlan == '8' || statePlan == 'Aleri Year') {
      //2022
      plan = 'Aleri Year';
    } else {
      plan = 'Aleri Enterprise';
    }
    var date;
    var date2;
    const disabled = this.state.admin == 'true' ? false : true;

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    const stripe =
      window.location.hostname == 'app.aleri.ca'
        ? 'pk_live_jLfhSQ0fgZFRo5XIGJnYJcJu'
        : 'pk_test_QgJecj1grCnouWQFZXjXRIOo';

    if (this.state.start && this.state.finish) {
      date = new Date(this.state.start * 1000);
      date2 = new Date(this.state.finish * 1000);

      date = month[date.getMonth()] + ' ' + date.getDate();

      date2 = month[date2.getMonth()] + ' ' + date2.getDate();
    } else {
      date2 = 'At the End of Your Free Trial';
    }

    const buttons =
      this.state.subscriptionId == 0 ? (
        <div>
          <h5>
            <Button
              onClick={this.handleBShow}
              className='action action-b btn-dark new top-g-f switch-btn-c'
              disabled={disabled}
            >
              Buy Now{' '}
            </Button>
          </h5>
        </div>
      ) : (
        <div>
          <h5>
            <a className='phone' href='#contact'>
              <Button
                onClick={this.handleShow}
                className='action action-b btn-dark new top-g-f switch-btn-c'
                disabled={disabled}
              >
                Update Plan{' '}
              </Button>
            </a>
          </h5>
          <h3>Payment</h3>
          <h5>
            Next payment is due: <span>{date2}</span>
          </h5>
          <h5>
            <Button
              onClick={this.handleCShow}
              className='action action-b btn-dark new top-g-f switch-btn-c'
              disabled={disabled}
            >
              Update Payment{' '}
            </Button>
          </h5>
        </div>
      );
    return (
      <div>
        <Container>
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            bsClass='payment-modal'
            style={{ left: '32px', margin: '0 auto' }}
            dialogClassName={
              this.props.darkMode ? 'dark-modal payment-modal' : 'payment-modal'
            }
          >
            <Form>
              <Modal.Header closeButton>
                <Modal.Title>Update Plan</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <div
                    id='pricing'
                    style={{ marginLeft: '-10px', background: 'none' }}
                  >
                    <Container className='pricing-container'>
                      <Row>
                        <Col xs={12}>
                          <h2 className='text-center'>
                            Simple, Affordable Pricing
                          </h2>
                          <h5 className='text-center'>
                            Billed{' '}
                            {this.state.tog == true ? 'Monthly' : 'Yearly'}
                          </h5>

                          <a
                            className='blue-blood text-center text-center-adv'
                            href='#'
                            onClick={this.togglePlan}
                          >
                            <p>
                              View{' '}
                              {this.state.tog == true ? 'Yearly' : 'Monthly'}{' '}
                              Plans
                            </p>
                          </a>
                          {this.state.errd && (
                            <h5 className='errd text-center'>
                              {this.state.errd}
                            </h5>
                          )}
                          <p className='middle-p'>Most Frequently Purchased</p>
                        </Col>

                        <Col>
                          <div className='modal-box-a box text-center'>
                            <h4 className='box-title'>Basic</h4>
                            {/* <h6 className='box-title'>
                              <strike>
                                {this.state.tog == false ? '95' : '119'}
                              </strike>
                            </h6> */}
                            <h2 className='box-title-price'>
                              <span className='box-title-d'>
                                <sup>$US</sup>
                              </span>
                              <span className='box-title-m'>
                                {this.state.tog == false ? '95' : '119'}
                              </span>
                              <span className='box-title-t'>/mo</span>
                            </h2>
                            <h5 className='box-time'>1 User</h5>
                            <div className='box-items-t'>
                              <p className='box-item'>15 Cases</p>
                              <p className='box-item'>Priority Support</p>
                            </div>

                            <Button
                              onClick={() =>
                                this.state.tog == false
                                  ? this.onSubmitPlan(4)
                                  : this.onSubmitPlan(1)
                              }
                              className='action action-b'
                            >
                              Select
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          {/* <p class='middle-pd'>Most Frequently Purchased</p> */}
                          <div className='modal-box-b box-b  text-center'>
                            <h4 className='box-title'>Pro</h4>
                            {/* <h6 className='box-title'>
                              <strike>
                                {this.state.tog == false ? '119' : '149'}
                              </strike>
                            </h6> */}
                            <h2 className='box-title-price'>
                              <span className='box-title-d'>
                                <sup>$US</sup>
                              </span>
                              <span className='box-title-m'>
                                {this.state.tog == false ? '119' : '149'}
                              </span>
                              <span className='box-title-t'>/mo</span>
                            </h2>
                            <h5 className='box-time'>Per User</h5>
                            <div className='box-items-t'>
                              <p className='box-item'>100 Cases</p>
                              <p className='box-item'>Up to 3 Users</p>
                              <p className='box-item'>Priority Support</p>
                            </div>

                            <Button
                              onClick={() =>
                                this.state.tog == false
                                  ? this.onSubmitPlan(5)
                                  : this.onSubmitPlan(2)
                              }
                              className='action action-b'
                            >
                              Select
                            </Button>
                          </div>
                        </Col>
                        <Col>
                          <div className='  modal-box-c box-b-2 text-center'>
                            <h4 className='box-title'>Business</h4>
                            {/* <h6 className='box-title'>
                              <strike>
                                {this.state.tog == false ? '143' : '179'}
                              </strike>
                            </h6> */}
                            <h2 className='box-title-price'>
                              <span className='box-title-d'>
                                <sup>$US</sup>
                              </span>
                              <span className='box-title-m'>
                                {this.state.tog == false ? '143' : '179'}
                              </span>
                              <span className='box-title-t'>/mo</span>
                            </h2>
                            <h5 className='box-time'>Per User</h5>
                            <div className='box-items-t'>
                              <p className='box-item'>Everything in Pro</p>
                              <p className='box-item'>Unlimited Cases</p>
                              <p className='box-item'>Up To 10 Users</p>
                              <p className='box-item'>Dedicated Support</p>
                              <p className='box-item'>Business Integration</p>
                            </div>

                            <Button
                              onClick={() =>
                                this.state.tog == false
                                  ? this.onSubmitPlan(6)
                                  : this.onSubmitPlan(3)
                              }
                              className='action action-b'
                            >
                              Select
                            </Button>
                          </div>
                        </Col>
                        <Col md={12} id='box-b'>
                          <div className='box-bottom'>
                            <p>
                              <span>Work in a large firm?</span> Learn more
                              about our <b>Enterprise Plan</b>.
                            </p>
                            <Link to='/support'>
                              {' '}
                              <Button className='action action-b btn-dark'>
                                Start a Chat
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Row>
              </Modal.Body>
            </Form>
          </Modal>
          {/* <Modal
               show={this.state.addCreditCart}
            onHide={this.addCreditCartClose}
            dialogClassName={
              this.props.darkMode ? 'dark-modal payment-modal' : 'payment-modal'
            }>  
              <Modal.Header closeButton>
                <Modal.Title>Add a Credit Card</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              You Must add a credit card to start a plan.
                       <Button
                  onClick={this.handleCShow}
                  className='action action-b btn-dark new top-g-f switch-btn-c'
                  disabled={disabled}
                >
                  Add Credit Card{' '}
                </Button>
               </Modal.Body>
          </Modal> */}
          <Modal
            show={this.state.errorUpgrade}
            onHide={() => this.setState({ errorUpgrade: false, show: true })}
            dialogClassName={
              this.props.darkMode ? 'dark-modal payment-modal' : 'payment-modal'
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              You have more cases or members in your firm then this plan can
              hold. Please remove some cases / members or choose a larger plan.
              <Button
                onClick={() =>
                  this.setState({ errorUpgrade: false, show: true })
                }
                className='action action-b btn-dark new top-g-f switch-btn-c'
              >
                Okay
              </Button>
            </Modal.Body>
          </Modal>
          <StripeProvider apiKey={stripe}>
            <Elements>
              <CheckoutForm
                handleCClose={this.handleCClose}
                cShow={this.state.cShow}
                firm={this.state.firm}
                user={this.state.user}
                darkMode={this.props.darkMode}
                subscriptionId={this.state.subscriptionId}
                members={this.state.members}
              />
            </Elements>
            {/* buy */}
          </StripeProvider>
          <StripeProvider apiKey={stripe}>
            <Elements>
              <CheckoutForm
                handleBClose={this.handleBClose}
                bShow={this.state.bShow}
                firm={this.state.firm}
                user={this.state.user}
                darkMode={this.props.darkMode}
                subscriptionId={this.state.subscriptionId}
                fullFirm={this.state.fullFirm}
                handleBOpen={this.handleBShow}
                members={this.state.members}
              />
            </Elements>
          </StripeProvider>

          <div id='user-settings'>
            <Form onSubmit={this.onSubmit}>
              {this.props.msg && (
                <h3 style={{ color: 'red' }}>{this.props.msg}</h3>
              )}
              <h2>Membership</h2>
              {this.state.trialEnd && (
                <p>
                  **Free Trial has ended. Please choose another plan to continue
                  using Aleri
                </p>
              )}
              <h3>Plan</h3>
              <h5>
                Current Plan: <span>{plan}</span>
              </h5>
              {buttons}
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
export default Payments;
