import React, { Component } from 'react';

import { Container } from 'react-bootstrap';
export default class Exposing extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.second != true) {
      this.props.getTab({
        case: '',
        witness: '',
        topic: '',
        path: '/information/exposing',
        name: 'Exposing False Testimony',
        active: true,
      });
    }
  }
  render() {
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash' className='wit-dash-w'>
        <Container fluid>
          <div className='info_and_tech'>
            <h2>Coming Soon</h2>
            {/* <h2>Exposing False Testimony</h2>
            <h1>Asking Highly Detailed Questions to Expose False Evidence</h1>
            <p>
              If you suspect that a witness or witness is giving false evidence,
              then you may want to cross-examine at length on some of the
              peripheral details relating to the case. This strategy is
              particularly useful where there are multiple witnesses who are
              suspected of fabricating a version of the events. The theory being
              that it is easy to fabricate a general version of the events, but
              it is not as common or as easy to create all the minute peripheral
              details of a false version of the events.
            </p>
            <h1>
              Lay Foundations for Questions & Cut Off Escape Routes for the
              Witness
            </h1>
            <p>
              If you are intending to confront the witness at some point on an
              issue, then you will want to try to anticipate the responses of
              the witness to the challenge to his credibility. If there are
              possible explanations or escape routes that the witness may rely
              upon, then it is preferable to try to deal with them prior to
              challenging to the witness. You will want to cut off the escape
              routes for the witness <em>before</em> they are alerted to the
              credibility challenge that you are mounting. It is particularly
              important to cut off escape routes for a witness that you believe
              are dishonest, and will grasp at any opportunity to explain a
              contradiction or implausible conduct. The solution is to first ask
              questions that limit the ability to explain the contradiction or
              implausible conduct, and then later confront the witness with the
              contradiction or implausible conduct.
            </p>
            <h1>Tell it Again</h1>
            <p>
              If you suspect that the witness has memorized a false version of
              the events, then you could consider asking them to tell a portion
              of their evidence again. If it is a false version, then the
              details may come out differently when the witness repeats the
              portion of the evidence. You would not want to rely upon this
              technique if you believe the witness is an honest witness who may
              be mistaken about some of the details; you would want to use this
              technique on a witness that is believed to be fabricating their
              evidence. The technique also has a disadvantage of an obvious loss
              of control over the witness by asking such a general question.
            </p>
            <h1>You Don’t Mean to Imply </h1>
            <p>
              When a witness deliberately implies something that is an
              inaccurate, the one strategy is to confront them aggressively on
              the misleading statement.To put into practice, you would bring out
              the factors making the statement to appear misleading, confront
              them with the statement that they had made, and then suggest “You
              did not mean to imply (whatever the inaccurate evidence is being
              impli9ed)”. This approach can force the witness to back down from
              inaccurate implications that they are trying to put into their
              evidence.
            </p>
            <h1>Dealing with a Witness who Claims that they Lack memory </h1>
            <p>
              In some circumstances a witness will legitimately fail to remember
              something. However, you must be alert to the fact that some
              witness will pretend not to remember events when they think that
              an answer may hurt them. If you suspect that the witness is
              pretending not to remember, then you should bring out
              circumstances that would suggest that a normal person would have
              remembered in the circumstance. You may want to show that the
              event was significant and not likely be forgotten, that the duties
              and functions of the witness would suggest that the information
              was important and not likely to be forgotten, or you may want to
              show that the witness is being selective and only forgetting
              information that is harmful to their case. You may want to
              contrast the areas they remember and do not to illustrate the
              issue of selective memory. The lack of memory can also be used to
              the cross-examiner’s advantage. If there are a series of areas
              where it is expected that the witness will clam a lack of memory
              (you may know this from pre-trial statements), then you can ask a
              series of questions in a row bringing out responses of a lack of
              memory. The series of these responses would assist in later
              arguing that the witness is not a reliable witness due to the
              memory deficiency.
            </p> */}
          </div>
        </Container>
      </div>
    );
  }
}
