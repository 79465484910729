import React, { Component } from 'react';
import axios from 'axios';
import prod from '../../prod';

import { RiFilter3Fill } from 'react-icons/ri';
import { IoIosArrowDown } from 'react-icons/io'


import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
const CaseIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642359/Aleri/bag_qhym3u.svg';
const ClientIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642364/Aleri/pea_zzehyw.svg';

const QuestionIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/question_uhcg2h.svg';
const FootstepIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/footprint_hafmha.svg';

const FocusIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642371/Aleri/target_lesomq.svg';
const ChecklistIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593642363/Aleri/notepad_ouiolb.svg';
const PrepIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593878907/Aleri/ruler-and-pencil-cross_urquuu.svg';
const EvidenceIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/evidence_lol8la.svg';
const WitnessIcon =
  'https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/witness_sspsnj.svg';
class Search extends Component {
  constructor() {
    super();
    this.state = {
      res: '',
      buttonIcon: '',
      resultList: [],
      currentUser: '',
      firm: '',

      //filter
      filterModal: false,
      caseOptions: false,
      witnessOptions: false,
      filters: [],
      added: [],
      appliedFilters: [],
      search: '',
      opened: {},
    };
    this.onListClickHandler = this.onListClickHandler.bind(this);
    this.createRef = React.createRef();
  }
  componentDidMount() {
    axios
      .get(prod() + '/api/users/current')
      .then((user) => {
        this.setState(
          {
            currentUser: user.data.id,
            firm: user.data.firm,
          },
          () => {
            this.createRef.current.focus();
          }
        );
      })
      .catch((e) => {
        console.log('ERROR while getting the user:', e);
      });
  }

  onSearchHandeler = async (term) => {
    console.log("filters: ", this.state.filters)
    let searchQuery = [];
    let case_array = [];
    let searchTerm = term;
    let currentUser = this.state.currentUser;
    let firm = this.state.firm;
    let caseIds = [];
    let witnessIds = [];

    this.state.appliedFilters.forEach(f => {
      if (f.type === 'witness') {
        witnessIds.push(f.id);
      }
      if (f.type === 'case') {
        caseIds.push(f.id);
      }
    })

    if (searchTerm.length >= 1) {
      try {
        let searchTerm = term;
        let caseID = '';
        let caseName = '';

        const res = await axios.post(prod() + '/api/search', {
          searchTerm,
          currentUser,
          firm,
          caseIds,
          witnessIds,
        });
        if (res) {
          console.log("results: ", res.data)
          res.data.map((item) => {
            console.log(item);
            caseID = item.case;
            caseName = item.name;

            let value = '';
            item.texts.map((text) => {
              value = value.concat(text.value);
            });

            searchQuery.push({
              pathName: item.pathName,
              path: item.path,
              text: value,
              case_ID: caseID,
              case_Name: item.name, //Documentname
              witness_Name: item.witness.name,
              case: item.case,
              topic: item.topic,
              witness: item.witness,
              type: item.type,
            });
          });
          this.setState({
            resultList: searchQuery,
          });
        }
      } catch (error) {
        console.log('ERROR while searching Front End', error);
      }
    } else {
      searchTerm = [];
      this.setState({
        resultList: [],
      });
    }
  };

  onListClickHandler(e, query) {
    const _id =
      query.topic != ''
        ? query.topic._id
        : query.case._id + query.witness._id + query.path;
    const data = {
      _id: _id,
      case: query.case.name,
      witness: query.witness.name,
      topic: query.topic && query.topic != undefined ? query.topic.name : '',
      path: query.path,
      name: query.type, //Documentname
      active: true, //true
      caseId: query.case,
      witnessId: query.witness,
      topicId: query.topic,
    };

    this.props.getDoc(data);

    this.props.handleSearchClose();
  }

  addCaseFilter = (e, caseInfo) => {
    console.log("change case: ", e.target.checked)
    const {filters , added} = this.state;
    const index = filters.findIndex(f => f.id === caseInfo._id);
    const index2 = added.indexOf(caseInfo._id);

    if (e.target.checked) { // if it hasn't been alrea
      filters.push({
        name: caseInfo.name,
        type: e.target.name,
        id: caseInfo._id,
      })
      added.push(caseInfo._id);

      caseInfo.witnesses.forEach(w => {
        if (!this.state.added.includes(w._id)) {
          filters.push({
            name: w.name,
            type: 'witness',
            id: w._id,
          })
          added.push(w._id);
        }
      })
    } else {
      filters.splice(index, 1);
      added.splice(index2, 1);

      caseInfo.witnesses.forEach(w => {
        const index = filters.findIndex(f => f.id === w._id);
        const index2 = added.indexOf(w._id);
        filters.splice(index, 1);
        added.splice(index2, 1);
      })
    }

    this.setState({ filters, added });
  }

  addWitnessFilter = (e, witnessInfo, caseInfo) => {
    console.log("change: ", e.target.checked)
    const {filters , added} = this.state;

    if (e.target.checked) {
      filters.push({
        name: witnessInfo.name,
        type: e.target.name,
        id: witnessInfo._id,
      })
      added.push(witnessInfo._id);
    } else {
      const caseIndex = filters.findIndex(f => f.id === caseInfo._id);
      const caseIndex2 = added.indexOf(caseInfo._id);
      if (index !== -1 && index2 !== -1) {
        filters.splice(caseIndex, 1);
        added.splice(caseIndex2, 1);
      }
      const index = filters.findIndex(f => f.id === witnessInfo._id);
      const index2 = added.indexOf(witnessInfo._id);
      filters.splice(index, 1);
      added.splice(index2, 1);
    }
    this.setState({ filters, added });
  }

  
  handleFClose = () => {
    this.setState({ 
      filterModal: false,
      caseOptions: false,
      witnessOptions: false,
    })
  }
  
  applyFilters = (e) => {
    e.preventDefault();
    const filters = this.state.filters;
    this.setState({ appliedFilters: filters }, () => this.onSearchHandeler(this.state.search));
    this.handleFClose();
  };

  openCloseOptions = (open, name) => {
    if (open) {
      this.setState({ [name]: false });
    } else {
      this.setState({ [name]: true });
    }
  };

  removeFilter = (filterId) => {
    const {appliedFilters, added, filters} = this.state;
    const index = appliedFilters.findIndex(f => f.id === filterId);
    const index2 = added.indexOf(filterId);
    appliedFilters.splice(index, 1);
    filters.splice(index, 1);
    added.splice(index2, 1);
    this.setState({ appliedFilters: filters, filters, added }, () => {
      this.onSearchHandeler(this.state.search)
    });
  }; 


  render() {
    console.log("this.state ", this.state)
    const { resultList, buttonIcon } = this.state;

    let result = '';

    return (
      <>
        <div></div>
        <div className='searchModal'>
          <input
            className={
              this.props.darkMode ? 'search-input-dark' : 'search-input-light'
            }
            id='search-input'
            type='text'
            value={this.state.search}
            placeholder='Search & Go To Document'
            onChange={(e) => {
              this.setState({ search: e.target.value })
              this.onSearchHandeler(e.target.value)
            }}
            ref={this.createRef}
            autoComplete='off'
          ></input>{' '}
        </div>
        <div>
          <Row>
            <Button
              onClick={() => this.setState({ filterModal: true })}
              variant="secondary"
              style={{ margin: '10px 0px'}}
            >
              <span style={{ marginRight: '5px'}}>Filter</span>
              <RiFilter3Fill size={20}/>
            </Button>
            {this.state.filters.length !== 0 && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ margin: '0px 10px', fontWeight: '600' }}>Filters:</span>
                {this.state.appliedFilters.map(f => {
                  return (
                    <Button
                    key={f.id} 
                      variant="secondary"
                      active
                      style={{ paddingBottom: '0', paddingTop: '0', paddingRight: '5px', marginRight: '8px' }}
                    >
                      <span
                        style={{ paddingRight: '3px'}}
                      >
                        {f.name}
                      </span>
                      <img className="logo-title" src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg" style={{ height: '12px'}} onClick={() => this.removeFilter(f.id)}></img>
                    </Button>
                  )
                })}
              </div>
            )}
          </Row>
        </div>
        <hr></hr>
        {this.state.resultList.length ?
            <div className='search-list-tab'>
            <Row>
              <Col md={1}></Col>
              <Col md={2}>Case</Col>
              <Col md={2}>Witness</Col>
              <Col md={2}>Go To</Col>
              <Col md={5}>Text Found</Col>
              {/* <p>To see results type something in the search bar.</p> */}
            </Row>
          </div> :
          <div className='search-list-tab'>
            <Row>
              {/* <Col md={1}></Col>
              <Col md={2}>Case</Col>
              <Col md={2}>Witness</Col>
              <Col md={2}>Go To</Col>
              <Col md={5}>Text Found</Col> */}
              <p>To see results type something in the search bar.</p>
            </Row>
          </div>
        }

        {resultList.map((query) => {
          let filePath = '';
          let buttonIcon = '';

          if (query.pathName.includes('checklist')) {
            query.pathName = 'checklist';
          } else if (query.pathName.includes('focus')) {
            query.pathName = 'focus';
          }
          switch (query.pathName) {
            case 'name':
              filePath = 'Case';
              buttonIcon = CaseIcon;
              break;
            case 'client':
              filePath = 'Case';
              buttonIcon = ClientIcon;
              break;
            case 'witnesses.name':
              filePath = 'Witness';
              buttonIcon = WitnessIcon;
              break;
            case 'witnesses.topics.name':
              filePath = 'Topic';
              buttonIcon = QuestionIcon;
              break;
            case 'witnesses.topics.topic.name':
              filePath = 'Topic';
              buttonIcon = QuestionIcon;
              break;
            case 'witnesses.topics.topic.ref.value':
              filePath = 'Topic';
              buttonIcon = QuestionIcon;
              break;
            case 'witnesses.topics.topic.disc.value':
              filePath = 'Topic';
              buttonIcon = QuestionIcon;
              break;

            case 'witnesses.facts.name':
              filePath = 'Fact';
              buttonIcon = FootstepIcon;
              break;
            case 'witnesses.facts.fact.reference':
              filePath = 'Fact';
              buttonIcon = FootstepIcon;
              break;
            case 'witnesses.facts.fact.evidence':
              filePath = 'Fact';
              buttonIcon = FootstepIcon;
              break;

            case 'witnesses.statements.name':
              filePath = 'Summary';
              buttonIcon = EvidenceIcon;
              break;
            case 'witnesses.statements.statement.reference':
              filePath = 'Summary';
              buttonIcon = EvidenceIcon;
              break;
            case 'witnesses.statements.statement.evidence':
              filePath = 'Summary';
              buttonIcon = EvidenceIcon;
              break;

            case 'witnesses.topics.topic.notes.value':
              filePath = 'Topic';

              buttonIcon = QuestionIcon;
              break;

            case 'witnesses.prepchart.statement.value':
              filePath = 'Preparation Chart';
              buttonIcon = PrepIcon;
              break;

            case 'witnesses.prepchart.other_statement.value':
              filePath = 'Preparation Chart';
              buttonIcon = PrepIcon;
              break;
            case 'witnesses.prepchart.fact.value':
              filePath = 'Preparation Chart';
              buttonIcon = PrepIcon;
              break;
            case 'witnesses.prepchart.trial_evidence.value':
              filePath = 'Preparation Chart';
              buttonIcon = PrepIcon;
              break;
            case 'checklist':
              filePath = 'Checklist';
              buttonIcon = ChecklistIcon;
              break;
            case 'focus':
              filePath = 'Focus';
              buttonIcon = FocusIcon;
              break;
            default:
              filePath = 'Not found';
              break;
          }
          return (result = (
            <div
              onClick={() => {
                this.onListClickHandler(query.case_ID, query);
              }}
            >
              <Row className='search-list-dark'>
                <Col className='search-list-tab-elements' md={1}>
                  <Button className='side-bt side-bt-t'>
                    <img className='logo' src={buttonIcon}></img>
                  </Button>
                </Col>
                <Col className='search-list-tab-elements' md={2}>
                  {query.case_Name}
                </Col>
                <Col className='search-list-tab-elements' md={2}>
                  {query.witness_Name}
                </Col>
                <Col className={'search-list-tab-elements'} md={2}>
                  {filePath}
                </Col>

                <Col className='search-list-tab-elements' md={4}>
                  {query.text}
                </Col>
              </Row>
            </div>
          ));
        })}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.filterModal}
          onHide={() => this.setState({ filterModal: false})}
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter</Modal.Title>
          </Modal.Header>
          <Form onSubmit={(e) => this.applyFilters(e)}>
          <Modal.Body>
            <div>
              {/* <div 
                style={{ display: 'flex', justifyContent: 'space-between' }}
                onClick={() => this.openCloseOptions(this.state.caseOptions, 'caseOptions')}
              >
                <span style={{ fontWeight: '600' }}>Cases</span>
                <IoIosArrowDown />
              </div> */}
              
                {this.props.cases.map(c => {
                  return (
                    <div>
                      <div 
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}   
                      >
                        <Form.Check
                          label={`Case Number: ${c.name}, ${c.client}`}
                          name="case"
                          type="checkbox"
                          checked={this.state.added.includes(c._id) ? true : false}
                          id={`Case Number: ${c.name}, ${c.client}`}
                          key={c._id} 
                          onChange={(e)=> {
                            this.addCaseFilter(e, c)
                          }}
                          style={{ margin: '5px 0px 5px 15px', fontWeight: '600'}}
                        />
                        <IoIosArrowDown 
                          onClick={() => {
                            const opened = this.state.opened;
                            console.log("opened:", opened[c._id])
                            !opened[c._id] ? opened[c._id] = true : opened[c._id] = false;
                            this.setState({ opened })
                          }}
                          className='expand-icon'
                        />
                      </div>
                      {this.state.opened[c._id] && (
                        <div style={{ marginLeft: '20px' }}>
                          {c.witnesses.map(w => {
                            return (
                              <div key={w._id}>
                                <Form.Check
                                  inline
                                  checked={this.state.added.includes(w._id) ? true : false}
                                  label={w.name}
                                  id={w.name}
                                  name="witness"
                                  style={{ margin: '5px 0px 5px 15px'}}
                                  onChange={(e)=> {
                                    this.addWitnessFilter(e, w, c)
                                  }}
                                />
                              </div>
                            )
                          })}
                        </div>
                      )}
                    </div>
                  )
                })}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleFClose}>
              Close
            </Button>
            <Button as="input" type="submit" value="Apply" />
          </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
export default withRouter(Search);
