import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const NotFound = () => {
  window.scrollTo(0, 0);
  return (
    <div id='ac' className='f10'>
      <Container>
        <Row>
          <Col>
            <div id='about'>
              <h1>Page Not Found</h1>

              <p>
                Unfortunately we could not find the page you are looking for.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default NotFound;
