import React, { Component } from 'react';
import { Form, Button, Container, Modal } from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';
import { logoutUser } from '../../../actions/authActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserDeleteModal from './UserDeleteModal';

export const logPageView = () => {
  window.analytics.page('User Settings');
};
class User extends Component {
  constructor() {
    super();
    this.state = {
      email: '',
      errors: '',
      first_name: '',
      last_name: '',
      number: '',
      old: {},
      photo: '',
      success: '',
      dShow: false,
      password: '',
      passwordV: '',
      errorsp: '',
      showAlert: false,
      eShow: false,
      showNameModal: true,
      showUserDeleteModal: false,
      showMakeAdminModal: false,
      showDeleteFirmModal: false,
      showConfirmDeleteFirmModal: false,
      noOfAdmins: 0,
      members: [],
       //upload photo
       gcFileName: '',
       fileErr: '',
       uploadableFileTypes: [
         'image/jpeg',
         'image/gif',
         'image/png',
         'image/svg+xml',
         'image/bmp',
       ],
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
    this.onSubmitPassword = this.onSubmitPassword.bind(this);
    this.openUserDeleteModal = this.openUserDeleteModal.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.openMakeAdminModal = this.openMakeAdminModal.bind(this);
    this.openDeleteFirmModal = this.openDeleteFirmModal.bind(this);
    this.openConfirmDeleteFirmModal = this.openConfirmDeleteFirmModal.bind(this);
    this.handleDeleteFirmNo = this.handleDeleteFirmNo.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.removeFirm = this.removeFirm.bind(this);
    this.handleRemoveFirm = this.handleRemoveFirm.bind(this);
    this.passRef = React.createRef();

    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
  }
  handleDClose = () =>
    this.setState({ dShow: false, errorsp: '', password: '', passwordV: '' });
  handleDShow = (e) => {
    this.setState({ dShow: true, password: '', passwordV: '' }, () =>
      this.passRef.current.focus()
    );
  };
  handleEClose = () => this.setState({ eShow: false });
  onLogoutClick(e) {
    e.preventDefault();
    this.props.logoutUser();
    this.props.loadOut();
  }
  componentDidMount() {
    logPageView();
    axios
      .get(prod() + '/api/users/current')

      .then((use) => {
        axios
          .post(prod() + '/api/users/get', { user: use.data.id })
          .then((user) => {
            this.setState({
              id: use.data.id,
              email: user.data['email'],
              first_name: user.data['first_name'],
              last_name: user.data['last_name'],
              old: user.data,
              number: user.data['number'],
              photo: user.data['photo'],
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      })

      .catch((err) => {
        console.log('MSG in err', err);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmitPassword(e) {
    e.preventDefault();
    if (
      this.state.password.trim() !== '' &&
      this.state.passwordV.trim() !== ''
    ) {
      if (this.state.password.trim() === this.state.passwordV.trim()) {
        axios
          .post(prod() + '/api/users/update/password', {
            _id: this.state.id,
            password: this.state.password.trim(),
          })
          .then((user) => {
            this.setState({
              password: '',
              passwordV: '',
              errorsp: undefined,
              dShow: false,
            });
          })
          .catch((err) => {
            console.log('INNNNNER', err);
          });
      } else {
        this.setState({ errorsp: 'Passwords Do Not Match.' });
      }
    } else {
      this.setState({ errorsp: 'Fields can not be blank.' });
    }
  }
  onSubmit(e) {
    if (
      this.state.first_name.trim() !== '' &&
      this.state.last_name.trim() !== '' &&
      this.state.email.trim() !== ''
    ) {
      if (
        this.state.first_name.trim() !== this.state.old.first_name.trim() ||
        this.state.last_name.trim() !== this.state.old.last_name.trim() ||
        this.state.email.trim() !== this.state.old.email.trim() ||
        this.state.number.trim() !== this.state.old.number.trim()
      ) {
        axios
          .post(prod() + '/api/users/update', {
            first_name: this.state.first_name.trim(),
            last_name: this.state.last_name.trim(),
            email: this.state.email.trim(),
            oldEmail: this.state.old['email'].trim(),
            number: this.state.number.trim(),
            _id: this.state.id,
            darkMode: this.props.darkMode,
          })
          .then((user) => {
            this.setState(
              {
                success: 'Profile Successfully Updated!',
                old: user.data.user,
              },
              () => {
                setTimeout(() => {
                  this.setState({
                    success: '',
                  });
                }, 2000);
              }
            );
          })

          .catch((err) => {
            console.log('INNNNNER', err);
          });
      }
    } else {
      this.setState({ errors: 'Fields can not be blank.', success: undefined });
    }
  }

  openUserDeleteModal(open) {
    this.setState({ showUserDeleteModal: open })
  }
  openMakeAdminModal(open) {
    this.setState({ showMakeAdminModal: open, showDeleteFirmModal: false })
  }

  openDeleteFirmModal(open) {
    this.setState({ showDeleteFirmModal: open })
  }

  openConfirmDeleteFirmModal(open) {
    this.setState({ showConfirmDeleteFirmModal: open, showDeleteFirmModal: false })
  }

  removeUser(userId, firmId) {
    //removes the user from User collection and Firm.members
    axios
      .post(prod()+ '/api/users/delete', { user: userId, firm: firmId })
      .then((res) => console.log(res.data))
      .catch(e => console.log(e))
  }
  removeFirm(firmId) {
    axios
      .post(prod() + '/api/firms/deleteFirm', { firm: firmId })
      .then(() => {
        this.setState({ showConfirmDeleteFirmModal: false })
      })
      .catch(e => console.log(e))

    axios.post(prod() + '/api/firms/get', { firm: firmId }).then(res => {
      const subscriptionId = res.data.subscriptionId;
      if (subscriptionId !== 0) {
        axios
          .post(prod() + '/api/stripe/subscription/get', {
            subscriptionId,
          })
          .then((a) => {
            // console.log(a.data.subscription);
            const stripeSubscriptionId = a.data.subscription.id;

            axios
              .post(prod()+ '/api/stripe/subscription/cancel', {
                subscriptionId: stripeSubscriptionId,
              })
              .then(res => {
                console.log(res.data);
              })
          })
          .catch((err) => {
            console.log('MMEMEMME', err);
          });
        }
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  }

  deleteUser() {
    const firmId = this.props.auth.user.firm;
    const userId = this.props.auth.user.id;
    axios
      .post(prod() + '/api/users/getall', { firm: firmId })
      .then((res) => {
        console.log("members: ", res.data.members)
        let members = [];
        for (const member of res.data.members) {
          members.push(member._id);
        }

        console.log("member ids:", members);

        if (res.data.members.length <= 1) {
          // if this user is the only user in this firm
          this.removeUser(userId, firmId)
          this.removeFirm(firmId);
          this.props.logoutUser();
          this.props.loadOut();
        } else { 
          //if there are more users in this firm
          //check to see if the user is the only admin
          let admin = 0;
          let isThisUserAdmin = false;
          for (const member of res.data.members) {
            if (member.admin === 'true') {
              admin++;
            }
            if (member._id === userId) {
              isThisUserAdmin = true;
            }
          }
          console.log(admin, isThisUserAdmin)

          if (!isThisUserAdmin) {
            this.removeUser(userId, firmId)
            this.props.logoutUser();
            this.props.loadOut();
          }

          if (isThisUserAdmin) {
            //if this user is the admin
            this.setState({ showDeleteFirmModal: true , showUserDeleteModal: false, noOfAdmins: admin, members });
          }
        }
      })
      .catch(e => console.log(e))
  }

  handleRemoveFirm() {
    const firmId = this.props.auth.user.firm;
    for (const member of this.state.members) {
      this.removeUser(member, firmId);
    }
    this.removeFirm(firmId);
    this.props.logoutUser();
    this.props.loadOut();
  }

  handleDeleteFirmNo () {
    const firmId = this.props.auth.user.firm;
    const userId = this.props.auth.user.id;

    if (this.state.noOfAdmins > 1) {
      this.removeUser(userId, firmId)
      this.props.logoutUser();
      this.props.loadOut();
    }
    if (this.state.noOfAdmins === 1) {
      this.setState({ showMakeAdminModal: true, showDeleteFirmModal: false });
    }
  }

  uploadFile(file) {
    console.log("file: ", file)
    const url = prod() + '/api/cases/update/witness/uploadFile';
    const data = new FormData();
    data.append('fileInfo', file);

    if (!file) {
      this.setState({ fileErr: 'Please choose a file' });
    } else if (file && !this.state.uploadableFileTypes.includes(file.type)) {
      this.setState({
        fileErr:
          'File type is not supported. Supported file type: .jpeg, .png, .svg, .bmp',
      });
    } else if (file && this.state.uploadableFileTypes.includes(file.type)) {
      axios({
        method: 'post',
        url: url,
        data: data,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      }).then((res) => {
        console.log("witness photo uploaded: ", res.data)
        const data = {
          userId: this.state.id,
          witnessPhoto: res.data.path,
          filename: res.data.filename,
        };
        console.log("user data: ", data)

        axios
          .post(prod() + '/api/users/update/photo', data)
          .then(() => {
            console.log('photo uploaded to db');
            this.setState({
              photo: {
                file: res.data.path, 
                gc_filename: res.data.filename,
              },
              fileErr: '',
            });
          })
          .catch(e => console.log(e))
      });
    }
  }

  deleteFile(filename) {
    this.setState({
      photo: {},
    });

    Promise.all([
      axios.post(prod() + '/api/cases/gcs/deleteFile', { filename }),
      axios.post(prod() + '/api/users/update/photo', {
        userId: this.state.id,
        filename: '',
        witnessPhoto: '',
      }),
    ])
      .then(() => {
        console.log('deleted photo');
      })
      .catch((s) => {
        console.log('err');
      });
  }

  render() {
    console.log("firm: ", this.props)
    const isEnabled =
      (this.state.first_name &&
        this.state.first_name.trim() != this.state.old.first_name.trim()) ||
      (this.state.last_name &&
        this.state.last_name.trim() != this.state.old.last_name.trim()) ||
      (this.state.number &&
        this.state.number.trim() != this.state.old.number.trim()) ||
      (this.state.email &&
        this.state.email.trim() != this.state.old.email.trim());

    return (
      <div>
        <Container>
          {' '}
          <div id='user-settings'>
            <Modal
              show={this.state.dShow}
              onHide={this.handleDClose}
              dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            >
              <Modal.Header closeButton>
                <Modal.Title>New Password</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                {this.state.errorsp && (
                  <div className='invalid-feedback invalid-feedback-c'>
                    {this.state.errorsp}
                  </div>
                )}
                <Form onSubmit={this.onSubmitPassword}>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Enter New Password'
                      value={this.state.password}
                      onChange={this.onChange}
                      name='password'
                      ref={this.passRef}
                    />
                  </Form.Group>
                  <Form.Group controlId='formBasicEmail'>
                    <Form.Label>Password Verification</Form.Label>
                    <Form.Control
                      type='password'
                      placeholder='Renter New Password'
                      value={this.state.passwordV}
                      onChange={this.onChange}
                      name='passwordV'
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={this.handleDClose}
                >
                  No
                </Button>
                <Button variant='primary' onClick={this.onSubmitPassword}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
              show={this.state.eShow}
              onHide={this.handleEClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are your sure you want to make these changes?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={this.handleEClose}
                >
                  No
                </Button>
                <Button variant='primary' onClick={this.onYes}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <h2>User Settings</h2>
            <Button
              onClick={this.onSubmit}
              className='action action-b new btn-dark'
              disabled={!isEnabled}
            >
              Save
            </Button>
            <Form className='user-form'>
              {this.state.errors && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors}
                </div>
              )}
              {this.state.success && (
                <div className='valid-feedback valid-feedback-c'>
                  {this.state.success}
                </div>
              )}

              <Form.Label>Dark Mode</Form.Label>

              <Form.Check custom type='switch' id='custom-switch'>
                <Form.Check.Input
                  checked={this.props.darkMode}
                  onClick={() =>
                    this.props.setDarkMode(!this.props.darkMode, {
                      first_name: this.state.first_name.trim(),
                      last_name: this.state.last_name.trim(),
                      email: this.state.email.trim(),
                      oldEmail: this.state.old['email'].trim(),
                      number: this.state.number.trim(),
                      _id: this.state.id,
                    })
                  }
                />
                <Form.Check.Label></Form.Check.Label>
              </Form.Check>
              <Form.Group controlId='formBasicEmails'>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter First Name'
                  value={this.state.first_name}
                  onChange={this.onChange}
                  name='first_name'
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmails'>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Last Name'
                  value={this.state.last_name}
                  onChange={this.onChange}
                  name='last_name'
                />
              </Form.Group>
              <Form.Group controlId='formBasicPhoto'>
                <Form.Label>Profile Photo</Form.Label>
                {this.state.photo?.file ? (
                  <div className="photo_person">
                    <img
                      src={this.state.photo.file}
                      alt='user-photo'
                      className='witness-img'
                    />
                    <img
                      className='trash '
                      style={{ marginLeft: '10px'}}
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/trash_umvko6.svg'
                      onClick={() => this.deleteFile(this.state.photo.gc_filename)}
                    />
                  </div>
                ): (
                  <Form.Control
                    type='file'
                    onChange={(e) => this.uploadFile(e.target.files[0])}
                    name='image'
                  />
                )}
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={this.state.email}
                  onChange={this.onChange}
                  name='email'
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Phone Number'
                  value={this.state.number}
                  onChange={this.onChange}
                  name='number'
                />
              </Form.Group>
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <div style={{ display: 'flex', height: '90px'  }}>
                  <Button
                    onClick={this.handleDShow}
                    className='action action-b new btn-dark'
                  >
                    Reset Password
                  </Button>

                  <Button
                    className='action action-b new bottom-btn btn-dark'
                    onClick={this.onLogoutClick.bind(this)}
                  >
                    Logout
                  </Button>
                </div>
                <p
                  className='deactivate-account'
                  style={{ marginLeft: '30px',
                    color: '#0f7ec0',
                    cursor: 'pointer' }}
                  onClick={() => this.openUserDeleteModal(true)}
                >
                  Deactivate Account
                </p>
              </div>
            </Form>
          </div>
        </Container>
        <UserDeleteModal
          darkMode={this.props.darkMode}
          deleteUser={this.deleteUser}
          handleDeleteFirmNo={this.handleDeleteFirmNo}
          handleRemoveFirm={this.handleRemoveFirm}
          showUserDeleteModal={this.state.showUserDeleteModal}
          openUserDeleteModal={this.openUserDeleteModal}
          showMakeAdminModal={this.state.showMakeAdminModal}
          openMakeAdminModal={this.openMakeAdminModal}
          showDeleteFirmModal={this.state.showDeleteFirmModal}
          openDeleteFirmModal={this.openDeleteFirmModal}
          showConfirmDeleteFirmModal={this.state.showConfirmDeleteFirmModal}
          openConfirmDeleteFirmModal={this.openConfirmDeleteFirmModal}
        />
      </div>
    );
  }
}

User.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(User);
