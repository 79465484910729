import React, { Component } from 'react';
import {
  Button,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import CaseExport from '../Case/CaseExport';
import axios from 'axios';
import prod from '../../../prod';
export default class DashboardModal extends Component {
  constructor() {
    super();
    this.state = {
      cases: [],
      show: false,
      dShow: false,
      firm: '',
      use: '',
      client: '',
      name: '',
      user: '',
      uClient: '',
      uName: '',
      user: '',

      //export
      timelineByWitness: [],
      timelines: [],

      //clone case
      cloneConfirm: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit(e) {
    const { cases } = this.props;
    let existingCaseName = false;

    // c = case
    cases.forEach(c => {
      if (c.name.toLowerCase() === this.state.name.toLowerCase()) existingCaseName = true;
    });

    if (existingCaseName) {
      this.setState({ user: "Case Name Already Exists" });
    } else {
      this.props.handleSubmit(
        {
          name: this.state.name,
          client: this.state.client,
        },
        e
      );
      this.setState({ client: '', name: '', user: '', });
    }
  }

  handleClose = () => {
    this.setState({
      user: '',
      name: '',
      client: '',
    });
    this.props.handleClose();
  }

  cloneCase(e, caseInfo) {
    //case names shouldn't be the same. When cloning a case, add 'copy' at the end of the case name. Add another 'copy' if the name exists
    const { cases } = this.props;
    let copy = ' copy';
    for (let i = 0; i < cases.length; i++) {
      if ((caseInfo.name + copy).toLowerCase() ===  cases[i].name.toLowerCase()) {
        copy += ' copy';
      }
    }

    const newName = caseInfo.name + copy;
    const newClientName = caseInfo.client + copy;

    this.props.cloneCase(e, caseInfo, newName, newClientName);
  }

  componentDidMount() {
    this.setState({
      uClient: this.props.uClient,
      uName: this.props.uName,
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.uClient !== this.props.uClient) {
      this.setState({
        uClient: this.props.uClient,
        uName: this.props.uName,
      });
    }
  }
  render() {
    return (
      <div>
        <Modal
          show={this.props.show}
          onHide={this.handleClose}
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
        >
          <Form onSubmit={(e) => {
            e.preventDefault();
            this.handleSubmit(e);
            }}>
            <Modal.Header closeButton>
              <Modal.Title>Create Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.user}
                </div>
              )}
              {this.props.err && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.err}
                </div>
              )}
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>File Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter File Number'
                  value={this.state.name}
                  onChange={this.onChange}
                  name='name'
                  ref={this.props.create}
                />
              </Form.Group>
              <Form.Group controlId='clientName'>
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Client Name'
                  value={this.state.client}
                  onChange={this.onChange}
                  name='client'
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Add Case
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
        <Modal
          show={this.props.dShow}
          onHide={this.props.handleDClose}
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this case?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleDClose}>
              No
            </Button>
            <Button variant='primary' onClick={() => this.props.deleteCase()}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Update Case Modal */}
        <Modal
          show={this.props.sShow}
          onHide={this.props.handleSClose}
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
        >
          <Form
            onSubmit={(e) =>
              this.props.handleSSubmit(
                { name: this.state.uName, client: this.state.uClient },
                e
              )
            }
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Case</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.userU && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.userU}
                </div>
              )}
              {this.state.err && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.err}
                </div>
              )}
              <Form.Group controlId='uName'>
                <Form.Label>Case Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Case Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.props.edit}
                />
              </Form.Group>
              <Form.Group controlId='uClient'>
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Client Name'
                  value={this.state.uClient}
                  onChange={this.onChange}
                  name='uClient'
                />
              </Form.Group>
              <Form.Label>Other Settings</Form.Label>
              <div className="other-settings">
                <div>
                  <ButtonToolbar>
                    <OverlayTrigger
                      key='bottom'
                      placement='bottom'
                      overlay={
                        <Tooltip
                          id={
                            this.props.darkMode
                              ? 'dark-tooltip-add'
                              : 'tooltip-add'
                          }
                        >
                          Export
                        </Tooltip>
                      }
                    >
                      <Button
                        onClick={this.props.handleEShow}
                        className='add-case'
                        className={
                          this.props.darkMode ? 'dark-add add-case' : 'add-case'
                        }
                      >
                        <img
                          className='logo-title log'
                          src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                        ></img>{' '}
                      </Button>
                    </OverlayTrigger>
                  </ButtonToolbar>
                </div>
                {/* <div>
                  <Button
                    onClick={() => this.setState({ cloneConfirm: true })}
                    variant="secondary"
                  >
                    Clone Case
                  </Button>
                </div> */}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleSClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Update Case
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        {/* Confirm Case Clone Modal */}
        <Modal show={this.state.cloneConfirm} onHide={() => this.setState({ cloneConfirm: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Clone Case</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to clone this case?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ cloneConfirm: false })}>
              Close
            </Button>
            <Button variant="primary" onClick={(e) => {
              this.setState({ cloneConfirm: false});
              this.props.handleSClose();
              this.cloneCase(e, this.props.case);
            }}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Case Export Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.eShow}
          onHide={this.props.handleEClose}
        >
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.handleEClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Export Entire Witness</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CaseExport 
                case={this.props.case}
                timelineByWitness={this.props.timelineByWitness}
                timelines={this.props.timelines} 
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.props.handleEClose}>
                Done
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
