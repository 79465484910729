import moment from 'moment';
import axios from 'axios';
import prod from '../../prod';

const trialEnd = async (firmId) => {
  if (window.location.pathname !== '/settings/payment') {
    const res = await axios.post(prod()+ '/api/firms/get', { firm: firmId})

    const today = moment();
    const differenceInDays = today.diff(res.data.created_at, 'days');

    if (res.data.subscriptionId === "0" && differenceInDays >= 7) {
      // if the user is on free trial and has expired
      // window.location.href = '/settings/payment';
      return true;
    } else {
      return false;
    }

  } else {
    return false;
  }
}

export { trialEnd };