import React, { Component } from 'react';
import { Form, FormControl, Button, Modal, Row, Col } from 'react-bootstrap';
import * as EmailValidator from 'email-validator';
import axios from 'axios';
import prod from '../../../../prod';

import { Link, Route } from 'react-router-dom';

import { withRouter } from 'react-router-dom';

import PaymentLoading from '../../../Shared/paymentLoading';
import ReactPixel from 'react-facebook-pixel';

ReactPixel.init('513762306385898');
class RegisterUser extends Component {
  constructor() {
    super();
    this.state = {
      nameErr: '',
      first_name: '',
      last_name: '',
      email: '',
      number: '',
      password: '',
      password2: '',
      verified: false,
      showComponent: false,
      err: false,
      show: false,
      disabled: false,
      firmError: '',
      firm: '',
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  handleShow = () => this.setState({ show: true });
  handleClose = () =>
    this.setState({ show: false }, () => console.log(this.state));
  handleErrClose = () => this.setState({ show: false, err: false });
  handleSubmit = (data) => {
    data.preventDefault();
    this.props.history.push('/login');
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  onSubmit(e) {
    console.log('CALLLED FIRST');
    e.preventDefault();
    if (this.state.firm == '' || this.state.firm == undefined) {
      this.setState({
        emailErr: 'Please Enter A Firm Name.',
        disabled: false,
        showComponent: false,
      });
    } else {
      // check if firm already exist
      axios
        .post(prod() + '/api/firms/check', {
          name: this.state.firm.trim().toLowerCase(),
        })
        .then((r) => {
          console.log(r);
          if (r.data.err) {
            this.setState({
              emailErr: 'Firm already exist!',
              disabled: false,
              showComponent: false,
            });
          } else {
            this.setState(
              {
                emailErr: '',
                nameErr: '',
                passwordErr: '',
                numberErr: '',
                verified: '',
                disabled: true,
                showComponent: true,
              },
              () => {
                if (this.state.first_name == '') {
                  this.setState({
                    emailErr: 'Please First Name',
                    disabled: false,
                    showComponent: false,
                  });
                } else if (this.state.last_name == '') {
                  this.setState({
                    emailErr: 'Please Last Name',
                    disabled: false,
                    showComponent: false,
                  });
                } else if (this.state.email == '') {
                  this.setState({
                    emailErr: 'Please Enter Email',
                    disabled: false,
                    showComponent: false,
                  });
                } else if (EmailValidator.validate(this.state.email) == false) {
                  this.setState({
                    emailErr: 'Email Not Valid',
                    disabled: false,
                    showComponent: false,
                  });
                }
                // } else if (this.state.number == '') {
                //   this.setState({
                //     emailErr: 'Please Enter Mobile Number',
                //     disabled: false,
                //     showComponent: false,
                //   });
                else if (this.state.password == '') {
                  this.setState({
                    emailErr: 'Please Enter Password',
                    disabled: false,
                    showComponent: false,
                  });
                } else {
                  const newUser = {
                    first_name: this.state.first_name.trim(),
                    last_name: this.state.last_name.trim(),
                    email: this.state.email.trim(),
                    number: this.state.number.trim(),
                    password: this.state.password.trim(),
                    admin: true,
                  };
                  console.log('creating firm');
                  axios
                    .post(prod() + '/api/firms/create', {
                      first_name: newUser.first_name,
                      last_name: newUser.last_name,
                      email: newUser.email,
                      number: newUser.number,
                      password: newUser.password,
                      firm: this.state.firm.trim(),
                      plan: 3,
                      customerId: 0,
                      subscriptionId: 0,
                    })
                    .then((response) => {
                      console.log(response.data);
                      const userData = {
                        email: newUser.email.trim(),
                        password: newUser.password.trim(),
                        firm: this.state.firm.trim(),
                      };
                      console.log(response.data.traits);

                      window.analytics.identify(
                        response.data.traits.userId,
                        response.data.traits.traits
                      );
                      this.props.loginUser(userData);
                      this.props.history.push('/');

                      // ReactPixel.track('StartTrial', {
                      //   value: 119,
                      //  currency: 'USD',
                      // });
                    })
                    .catch((e) => {
                      console.log('retreiving error' + e);
                      this.setState({
                        err: true,
                        showComponent: false,
                      });
                    });
                }
              }
            );
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
      // }
    }
  }

  render() {
    return (
      <div className='Login-Container'>
        <Row>
          <Col md={5}>
            <div className='registrationForm reg-extender' id='registerUser'>
              <h1>Aleri</h1>
              <p id='register-msg'>Create Account</p>
              {/* {this.props.errors && (
                <div className='invalid-feedback alert-danger alert'>
                  {this.props.errors}
                </div>
              )} */}
              {this.state.firmError && (
                <div className='invalid-feedback invalid-feedback-c alert alert-danger'>
                  {this.state.firmError}
                </div>
              )}
              <Modal
                show={this.state.show}
                onHide={(e) => this.props.history.push('/login')}
              >
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                  <Modal.Header closeButton>
                    <Modal.Title>Welcome</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>You have successfully created an account.</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='primary' type='submit'>
                      Go To Login Page
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
              <Modal show={this.state.err} onHide={this.handleErrClose}>
                <Form onSubmit={(e) => window.reload()}>
                  <Modal.Header closeButton>
                    <Modal.Title>Error</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>
                      An Error occurred when trying to create firm. Please try
                      again or contact us.
                    </p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant='primary' type='submit'>
                      Please Try Again
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
              <Form onSubmit={this.onSubmit} id='reg-user'>
                {this.state.emailErr && (
                  <div className='invalid-feedback alert-danger alert'>
                    {this.state.emailErr}
                  </div>
                )}
                <Row>
                  <Col md={6} className='name-space'>
                    <Form.Group controlId='formFirstName'>
                      <FormControl
                        type='text'
                        placeholder='First name'
                        value={this.state.first_name}
                        onChange={this.onChange}
                        name='first_name'
                        autoFocus
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='formLastName'>
                      <FormControl
                        type='text66ttt'
                        placeholder='Last name'
                        value={this.state.last_name}
                        onChange={this.onChange}
                        name='last_name'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId='formEmail'>
                  <FormControl
                    type='email'
                    placeholder='Email'
                    value={this.state.email}
                    onChange={this.onChange}
                    name='email'
                  />
                </Form.Group>

                <Form.Group controlId='formBasicFirmName'>
                  <FormControl
                    type='text'
                    placeholder='Firm name'
                    value={this.state.firm}
                    onChange={this.onChange}
                    name='firm'
                  />
                </Form.Group>

                <Form.Group controlId='password'>
                  <FormControl
                    type='password'
                    placeholder='Password'
                    value={this.state.password}
                    onChange={this.onChange}
                    name='password'
                  />
                </Form.Group>

                <Button
                  className='btn action'
                  block
                  type='submit'
                  variant='primary'
                  disabled={this.state.disabled ? true : false}
                >
                  Get Started
                </Button>
              </Form>
              <a href='https://www.aleri.ca/academy/kb-tickets/new'>
                Join An Existing Firm
              </a>
              <br />
              <Link to='/login'>
                Already have an account?{' '}
                <span className='signup-signin'>Sign in</span>
              </Link>
              {this.state.showComponent ? <PaymentLoading /> : null}
            </div>
          </Col>{' '}
          <Col md={7} className='Login-IMG d-none d-md-block'></Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(RegisterUser);
