import React, { Component } from 'react';
import { Col, Container, Row, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import prod from '../../prod';
import axios from 'axios';

const divContainer = {
  justifyContent: 'space-between',
  padding: '30px 0',
  margin: '0 auto',
  textAlign: 'left',
};

export default class Activity extends Component {
  constructor() {
    super();
    this.state = {
      activity: [
        {
          user: 'Bill Jones',
          case: '1221312',
          page: 'Dashboard',
          date: '10 minutes ago',
          firm: 'a',
          withnessName: 'Mark',
          editor: 'Collab1',
        },
        {
          user: 'Francine St-Louis',
          case: '5123444',
          page: 'Witness Dashboard',
          date: '1 day ago',
          firm: 'aa',
          withnessName: 'John',
          editor: 'Collab1',
        },
      ],
    };
  }

  componentDidMount() {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/firms/get', { firm: s.data.firm })
        .then((use) => {
          axios
            .post(prod() + '/api/cases/getall', { firm: s.data.firm })

            .then((user) => {
              this.setState({
                cases: user.data.cases,
                firm: s.data.firm,
                use: use.data.id,
                dShow: false,
                client: '',
                name: '',
                deleted: '',
                plan: use.data.plan,
              });
            })
            .catch((err) => {
              console.log('MSG in err', err);
            });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  }

  render() {
    let activityList = [];
    let { activity } = this.state;

    for (let i = 0; i < activity.length; i++) {
      activityList.push(
        <div key={i}>
          <Link
            to='#'
            className='cases-items'
            onClick={() => this.getW(this.state.cases[i])}
          >
            <ListGroup.Item className='list-underline'>
              <div style={divContainer}>
                <Row>
                  <Col sm>
                    <p>
                      {activity[i].case} - {activity[i].withnessName}
                    </p>
                  </Col>

                  <Col sm>
                    <p>{activity[i].user}</p>
                  </Col>
                  <Col sm>
                    <p>{activity[i].page}</p>
                  </Col>

                  <Col sm>
                    <p>{activity[i].editor}</p>
                  </Col>
                </Row>
                <p style={{ float: 'right' }}>{activity[i].date}</p>
              </div>
            </ListGroup.Item>
          </Link>
        </div>
      );
    }
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div>
        <Container>
          <Row>
            <Col md={sideMenu}></Col>
            <Col md={display}>
              <div className='cases'>
                <h1>Activity Comming Soon </h1>
                <div className='cases-list'>
                  <ListGroup>{activityList}</ListGroup>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
