import React, { Component } from 'react';

import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from 'react-bootstrap';

import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../prod';

import Fade from 'react-reveal/Fade';

import TopicsExport from '../Documents/Questions/TopicsExport';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

export const logPageView = () => {
  window.analytics.page('Topic List');
};
class Witness extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      topics: [],
      redirecting: false,
      dShow: false,
      show: false,
      deleted: '',
      case: '',
      witness: '',
      witnessName: '',
      name: '',
      uName: '',
      use: '',
      notes: '',
      disclosure: '',
      reference: '',
      // delete Topic
      deleteT: {},
      dTShow: false,

      // update
      uShow: false,
      // help
      hShow: false,
      // export
      eShow: false,
      dragging: false,

      //add more
      addMoreClicked: 1,
      names : {},
      errors: {},
      removeLocations: [],

      //clone
      cloneConfirm: false,
    };
    this.getTS = this.getTS.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteTopic = this.deleteTopic.bind(this);
    this.onChange = this.onChange.bind(this);
    // delete topic
    this.handleDTShow = this.handleDTShow.bind(this);
    this.handleDTClose = this.handleDTClose.bind(this);
    this.handleDTSubmit = this.handleDTSubmit.bind(this);

    // update topic
    this.handleUShow = this.handleUShow.bind(this);
    this.handleUClose = this.handleUClose.bind(this);
    this.handleUSubmit = this.handleUSubmit.bind(this);

    // help
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    //
    this.removeOne = this.removeOne.bind(this);

    //clone
    this.clone = this.clone.bind(this);

    this.createRef = React.createRef();
    this.editRef = React.createRef();
  }
  deleteDocs = (topic) => {
    axios
      .post(prod() + '/api/documents/getall', {
        case: this.props.case._id,
        witness: this.props.witness['_id'],
      })
      .then((a) => {
        let docs = a.data.documents;
        for (let i = 0; i < docs.length; i++) {
          let sub = 0;
          let count = 0;
          for (let j = 0; j < docs[i].locations.length; j++) {
            if (
              docs[i].locations[j].topic &&
              docs[i].locations[j].topic.toString() == topic._id.toString()
            ) {
              docs[i].locations.splice(j, 1);

              axios
                .post(prod() + '/api/documents/update/loc', {
                  case: this.props.case['_id'],
                  witness: this.props.witness['_id'],
                  document: docs[i]._id,
                  loc: docs[i].locations,
                })
                .then((use) => {})
                .catch((e) => {
                  console.log('Invalid Input, Please Try Again', e);
                });
            } else {
              sub++;
            }
          }
        }
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };
  handleImportantCard = (e, cases, topic, important, index) => {
    e.stopPropagation();
    axios
      .get(prod() + '/api/users/current')
      .then((s) => {
        axios
          .post(prod() + '/api/important/question/cards', {
            topic: topic,
            important: important,
            user: s.data,
            topics: this.state.topics,
            index: index,
          })
          .then((user) => {
            this.setState(
              {
                topics: user.data,
              }
              // () => {
              //   this.props.updateWitness(this.state.case, user.data);
              // }
            );
          });
      })

      .catch((err) => {
        console.log('MSG in err', err);
      });
  };
  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/questions', {
          case: this.state.case._id,
          witness: this.state.witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          this.setState(
            {
              witness: user.data,
            },
            () => {
              this.props.updateWitness(this.state.case, user.data);
            }
          );
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };

  handleProgress = (a) => {
    let p = 0;
    const arr = a;
    let num = 0;
    let v = 0;
    let amount = 0;
    for (let i of a) {
      if (i.topic.length > 0) {
        v += 1;
        i.progress = 100;
      } else {
        i.progress = 0;
      }
      num += 1;
    }
    num += 1;
    amount = 100 / num;
    if (a && a.length > 0) {
      p += amount;
    }
    p += amount * v;
    return [p, a];
  };
  SortableItem = sortableElement(({ topic, index }) => {
    return (
      <div key={index}>
        <div
          className={
            this.props.darkMode == true
              ? 'parent-side-nav parent-side-nav-card dark-parent-side-nav-card'
              : 'parent-side-nav parent-side-nav-card '
          }
          helperClass='dragging'
        >
          <div
            className='cases-items cases-items-s'
            onClick={() => this.getTS(topic)}
          >
            <div
              className={
                this.props.darkMode == true
                  ? 'inside-card inside-card-dark'
                  : 'inside-card'
              }
            >
              <ProgressBar now={Math.round(topic.progress)} />
              <div className='inside-btn'>
                {/* INSIDE BTN */}
                {/* {topic.important ? (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportantCard(
                          e,
                          this.state.case,
                          topic,
                          false,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s'
                        src={require('../../../pics/app/filled_star_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportantCard(
                          e,
                          this.state.case,
                          topic,
                          true,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s card_important_comment'
                        src={require('../../../pics/app/star_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                )} */}
                {/* {topic.comment ? (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportantCard(
                          e,
                          this.state.case,
                          topic,
                          false,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s '
                        src={require('../../../pics/app/filled_comment_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportantCard(
                          e,
                          this.state.case,
                          topic,
                          true,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s card_important_comment'
                        src={require('../../../pics/app/comment_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                )} */}
                <ButtonToolbar className='side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleUShow(topic, e)}
                      className='logo-settings'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
                <ButtonToolbar className=' side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleDTShow(topic, e)}
                      className='logo-s'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
              </div>
              <p>{topic.name}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  SortableContainer = sortableContainer(({ children }) => {
    const sc =
      this.props.splitState === true ? 'auto auto' : 'auto auto auto auto';

    return (
      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: sc,
            gap: '10px',
          }}
        >
          {children}
        </div>
      </Row>
    );
  });

  onDragEnd = ({ oldIndex, newIndex }) => {
    let newTopics = arrayMove(this.state.topics, oldIndex, newIndex);
    if (newTopics && newTopics.length > 0) {
      this.setState(() => ({
        topics: newTopics,
        dragging: false,
      }));
      axios
        .post(prod() + '/api/cases/update/witness/topic-reorder', {
          witness: this.props.witness['_id'],
          case: this.props.case._id,
          newTopics: newTopics,
        })
        .then((use) => {})
        .catch((e) => console.log(e));
    }
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    this.setState({
      dragging: true,
    });
  };
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });
  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      axios
        .post(prod() + '/api/cases/getall/witness/topic', {
          case: this.props.case._id,
          witnesses: this.props.witness['_id'],
        })
        .then((use) => {
          this.setState({
            topics: use.data.topics,
            witness: this.props.witness,
            case: this.props.case,
          });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    });

  handleEClose = () => this.setState({ eShow: false });

  // update
  handleUShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
    this.setState({ uShow: true, uName: e.name, topic: e }, () =>
      this.editRef.current.focus()
    );
  };
  handleUClose = () => this.setState({ uShow: false, uName: '' });
  handleUSubmit = (data) => {
    data.preventDefault();

    const info = {
      case: this.props.case._id,
      witness: this.props.witness._id,
      sheetId: this.state.topic._id,
      sheet: 'topic',
      newName: this.state.uName,
    };

    axios
      .post(prod() + '/api/documents/update/location-name', info)
      .then((res) => {
        // console.log('RESPONSE', res.data);
      })
      .catch((e) => console.log(e));

    axios
      .post(prod() + '/api/cases/update/witness/topic/name', {
        witness: this.props.witness['_id'],
        case: this.props.case._id,
        topic: this.state.topic._id,
        name: this.state.uName,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.props.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].topics.length; j++) {
              if (
                use.data.case.witnesses[i].topics[j]['_id'] ==
                this.state.topic['_id']
              ) {
                this.setState({
                  uShow: false,
                  uName: '',
                  topics: use.data.case.witnesses[i].topics,
                });
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          user: 'Topic already exist',
        });
      });
  };

  handleDTClose = () => this.setState({ dTShow: false });
  handleDTShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();

    this.setState({ dTShow: true, deleteT: e });
  };
  handleDTSubmit = () => {
    this.deleteDocs(this.state.deleteT);
    this.deleteHighlights(this.state.topics, this.state.deleteT);
    axios
      .post(prod() + '/api/cases/delete/witness/topic', {
        case: this.props.case._id,
        witness: this.props.witness['_id'],
        topic: this.state.deleteT._id,
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/getall/witness/topic', {
            case: this.props.case._id,
            witnesses: this.props.witness['_id'],
          })
          .then((use) => {
            const [p, topics] = this.handleProgress(use.data.topics);
            this.setState({
              topics: topics,
              dTShow: false,
              progress: p,
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
  };

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }
  
  deleteHighlights(topics, deleted) {
    const rowIds = [];
    for (const topic of topics) {
      if (topic._id === deleted._id) {
        for (const row of topic.topic) {
          rowIds.push(row._id);
        }
      }
    }
    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: rowIds,
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })
        axios
          .post(prod() + '/api/file/highlights/documents/deleteAll', { rowIds })
          .then((res) => console.log(res.data))
          .catch(e => console.log(e));
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }
  componentDidMount() {
    logPageView();
    if (
      this.props.case == undefined ||
      this.props.case.client == undefined ||
      this.props.witness == undefined
    ) {
      this.props.changeSideMenu();
    } else {
      axios
        .post(prod() + '/api/cases/getall/witness/topic', {
          case: this.props.case._id,
          witnesses: this.props.witness['_id'],
        })
        .then((use) => {
          const [p, topics] = this.handleProgress(use.data.topics);

          this.setState({
            topics: topics,
            witness: this.props.witness,
            case: this.props.case,
            progress: p,
          });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }
  componentDidUpdate(props, old) {
    localStorage.setItem('topic', JSON.stringify(this.state));
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.witness &&
      prevProps.witness.topics.length !== this.props.witness.topics.length
    ) {
      const [p, topics] = this.handleProgress(prevProps.witness.topics);
      this.setState({
        dTShow: false,
        deleteT: '',
        topics: topics,
        progress: p,
      });
    }
  }

  onChange(e) {
    this.setState({
      names: {
        ...this.state.names,
        [e.target.name]: e.target.value
      }
    });
  }
  getTS(a) {
    localStorage.removeItem('q');
    this.props.getQ(a);
  }
  deleteTopic = () => {
    this.deleteDocs(this.state.deleted);
    axios
      .post(prod() + '/api/cases/delete/witness/topic', {
        case: this.props.case._id,
        witness: this.props.witness['_id'],
        topic: this.state.deleted._id,
      })
      .then((use) => {
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/getall/witness/topic', {
            case: this.props.case._id,
            witnesses: this.props.witness['_id'],
          })
          .then((use) => {
            const [p, topics] = this.handleProgress(use.data.topics);

            this.setState({
              topics: topics,
              dShow: false,
              progress: p,
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
  };
  handleSubmit = (data, noOfTopics) => {
    data.preventDefault();
    let witnessTopics = [];
    let enteredTopics = {};

    for (const topic of this.props.witness.topics) {
      witnessTopics.push(topic.name.toLowerCase());
    }

    let errors = {};
    for (let i = 1; i <= noOfTopics; i++) {
      const name = `name${i}`;
      const names = this.state.names;
      if (names[name]) {
        //checking to see if the user input duplicate names
        if (enteredTopics[names[name]]) {
          errors[`error${i}`] = 'Duplicate Topic, Please Remove One'
        } else {
          enteredTopics[names[name]] = true;
        }
        //checking to see if it already exists
        if (witnessTopics.includes(names[name].toLowerCase())) {
          errors[`error${i}`] = 'Topic Already Exists'
        }
      }
    };

    let errorExists = Object.keys(errors).length > 0;

    if (errorExists) {
      this.setState({ errors });
    } else {
      
      let enteredTopicsArr = Object.keys(enteredTopics);

      const topic = {
        names: enteredTopicsArr,
        witness: this.props.witness._id,
        case: this.props.case._id,
      };

      if (enteredTopicsArr.length !== 0 && topic.witness != undefined && topic.case != '') {
        axios
          .post(prod() + '/api/cases/create/witness/topics', topic)
          .then((use) => {
            console.log("updated case: ", use.data.case.witnesses);
            for (let a of use.data.case.witnesses) {
              if (a._id == this.props.witness._id) {
                this.props.updateWitness(use.data.case, a);
              }
            }
            axios
              .post(prod() + '/api/cases/getall/witness/topic', {
                case: this.props.case._id,
                witnesses: this.props.witness['_id'],
              })
              .then((use) => {
                const [p, topics] = this.handleProgress(use.data.topics);

                this.setState({
                  topics: topics,
                  show: false,
                  names: {},
                  addMoreClicked: 1,
                  errors: {},
                  removeLocations: [],
                  progress: p,
                  user: '',
                });
              })
              .catch((err) => {
                console.log('MSG in err', err);
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              user: 'Topic already exist',
            });
          });
      } else {
        this.setState({
          user: 'Please fill out all topic fields',
        });
      }
    }
  };
  handleClose = () => this.setState({ show: false, names: {}, user: '', errors: {}, addMoreClicked: 1, removeLocations: [], });
  handleShow = () =>
    this.setState({ show: true }, () => this.createRef.current.focus());
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, deleted: e });

  addMore = (clicked, remove) => {
    let forms = [];

    for (let i = 1; i <= clicked; i++) {
      if (!remove.includes(i)) {
        const form = (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
              {this.state.errors[`error${i}`] && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors[`error${i}`]}
                </div>
              )}
              <Form.Control
                type='text'
                placeholder='Enter Topic Name'
                value={this.state.names[`name${i}`] ? this.state.names[`name${i}`] : ''}
                onChange={this.onChange}
                name={`name${i}`}
                ref={this.createRef}
              />
            </div>
            <img
              style={{ width: '10%'}}
              onClick={() => this.removeOne(i)}
              className="logo-title logo-title-l" 
              src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg"
            ></img>
          </div>
        )
        forms.push(form);
      }
    }
    if (forms.length === 0) {
      this.setState({ 
        user: 'Error: At Lease One Field Must Exist',
        addMoreClicked: this.state.addMoreClicked + 1,
      })
    }

    return forms;
  }

  removeOne = (nameLocation) => { 
    let names = {...this.state.names};
    names[`name${nameLocation}`] = '';

    let removeLocations = [...this.state.removeLocations];
    removeLocations.push(nameLocation);
    this.setState({ removeLocations, names });
  };

  clone = (e, topic) => {
    e.preventDefault();
    //names should go like this - 'topic copy' -> 'topic copy(1)' -> 'topic copy(2)' and so on
    let name = topic.name + ' copy';
    let existingTopicNames = [];
    this.state.topics.forEach(t => {
      existingTopicNames.push(t.name);
    })

    let counter = 1;
    let nameLength = name.length;
    while(existingTopicNames.includes(name)) {
      name = name.slice(0,nameLength);
      name += `(${counter})`;
      counter++;
    }

    const data = {
      case: this.props.case._id,
      witness: this.props.witness._id,
      topics: topic,
      name: name,
    };

    axios
      .post(prod() + '/api/cases/create/witness/topic/clone', data)
      .then(res => {
        console.log("updated case: ", res.data.case.witnesses);
        const witness = res.data.case.witnesses.find(w => w._id === this.props.witness._id);
        const [p, topics] = this.handleProgress(witness.topics);
        this.setState({
          cloneConfirm: false,
          uShow: false,
          topics: topics,
        })
      })
      .catch(e => console.log(e));
  }

  render() {
    let num = 0;
    if (this.state.redirecting) {
      return <Redirect to='/' />;
    }

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    const firm = this.props.top ? 'add-btn-expanded' : 'add-btn';
    const firm2 = this.props.top ? 'wit-nav-expanded' : '';
    return (
      <div id='wit-dash'>
        <Container fluid>
          <div>
            <Row id='dash-add'>
              <Col md={5}>
                <div className='case-dash-container'>
                  {/* {this.state.witness.comment ? (
                    <img
                      onClick={(e) => this.handleImportant(e, false)}
                      className='logo-title-dash '
                      src={require('../../../pics/comment_submitted.svg')}
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src={require('../../../pics/comment.svg')}
                    ></img>
                  )} */}
                  {this.state.witness.topicImportant ? (
                    <img
                      className='logo-title-dash'
                      onClick={(e) =>
                        this.handleImportant(
                          e,

                          false
                        )
                      }
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                    ></img>
                  )}
                </div>
                <h1 id={firm2}>Cross-examination Questions </h1>
                <ProgressBar now={Math.round(this.state.progress)} />
              </Col>

              <Col md={7}>
                <div className={firm + ' add-btn-p add-btn-i1'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={firm + ' add-btn-p add-btn-i2'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={firm + ' add-btn-p add-btn-i3'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Add Topic
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleShow} className='add-case plus-icon'>
                          <img
                            className='logo-title plus-icon'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>

          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.eShow}
            onHide={this.handleEClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  eShow: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Export All Question Sheets</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <TopicsExport
                  witness={this.props.witness}
                  topics={this.state.topics}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleEClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.hShow}
            onHide={this.handleHClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  hShow: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>What Is A Topic Sheet</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='help'>
                  <p>
                    The cross-examination should have one overall goal or theme.
                    Within the general theme or goal, you will break the
                    cross-examination into individual topics. Each topic is a
                    group of questions that designed to achieve a goal that
                    supports the overall theory of your case. The topics will
                    vary on the nature of the case and the witness. They could
                    include issues of motive or bias, impeachment of the
                    witness, cutting off escape routes for a later impeachment
                    or any number of relevant topics. You will assign names for
                    each topic, create a list of the topics you intend to use,
                    and then create questions within the individual topics. The
                    questions for each topic should be one page or under. If the
                    topic is too long then you would break it into multiple
                    topics.
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link to='/settings/support'>
                  <Button variant='outline-secondary'>Help</Button>
                </Link>
                <div style={{ flex: 1 }}></div>
                <Button variant='secondary' onClick={this.handleHClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Form onSubmit={(e) => this.handleSubmit(e, this.state.addMoreClicked)}>
              <Modal.Header closeButton>
                <Modal.Title>Create Topic</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.user && (
                  <div className='invalid-feedback invalid-feedback-c'>
                    {this.state.user}
                  </div>
                )}
                <Form.Group controlId='caseName'>
                  <Form.Label>Topic Name</Form.Label>
                  {this.addMore(this.state.addMoreClicked, this.state.removeLocations)}
                </Form.Group>
                <Button 
                  variant='primary' 
                  style={{ marginRight: '10px' }} 
                  onClick={() => this.setState({ addMoreClicked: this.state.addMoreClicked + 1 }, () => this.createRef?.current?.focus())}
                >
                  Add More
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant='primary' type='submit'>
                  Add Topic
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          

          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.dTShow}
            onHide={this.handleDTClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Are you sure you want to delete this topic?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.handleDTClose}
              >
                No
              </Button>
              <Button variant='primary' onClick={this.handleDTSubmit}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.uShow}
            onHide={this.handleUClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Topic</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Topic Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Topic Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.editRef}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleUClose}>
                Close
              </Button>
              <Button variant='secondary' onClick={() => this.setState({ cloneConfirm: true, uShow: false })}>
                Clone
              </Button>
              <Button
                variant='primary'
                type='submit'
                onClick={this.handleUSubmit}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Clone Confirm Modal */}
          <Modal show={this.state.cloneConfirm} onHide={() => this.setState({ cloneConfirm: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Clone Topic</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to clone this Topic?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ cloneConfirm: false, uShow: true })}>
                Close
              </Button>
              <Button variant="primary" onClick={(e) => {
                this.clone(e, this.state.topic);
              }}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='cases cases-d'>
            <div className={this.state.dragging ? 'cases-dragging' : ''}></div>
            <this.SortableContainer
              onSortEnd={this.onDragEnd}
              onSortStart={this.onDragStart}
              forceFallback={true}
              axis='xy'
              distance={1}
              helperClass={'dragging'}
            >
              {this.state.topics &&
                this.state.topics.map((topic, index) => (
                  <this.SortableItem
                    key={`topic-${index}`}
                    index={index}
                    topic={topic}
                  />
                ))}
            </this.SortableContainer>
            {this.state.topics.length == 0 && (
              <Row id='dash-desc' className='dash-desc-d'>
                <Col>

                  <p className='dash-d dash-d-d'>
                    Press The Plus Icon To Add A Question Topic
                  </p>
                  <p className='dash-d dash-d-d dash-d-m'>
                    Press The Plus Icon To Add A Question Topic

                  </p>

                  <div className='dash-desc-d-d'>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Add Question Topic
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={this.handleShow}
                          className='add-case d plus-icon'
                        >
                          <img
                            className='logo-title plus-icon'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Witness);
