import React, { Component, Fragment, useState } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Form,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
  Modal,
} from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../../prod';
import ChecklistModal from './ChecklistModal';
import TextareaAutosize from 'react-textarea-autosize';
import Pulse from 'react-reveal/Pulse'; // Importing Bounce effect
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import '../../../../App.css';

export const logPageView = () => {
  window.analytics.page('Checklist');
};

const CreateTopic = ({
  name,
  witness,
  p_case,
  updateWitness,
  topicArray,
  addTopicToTopicArray,
}) => {
  const [clicked, setClicked] = useState(false);

  const handleSubmit = (data) => {
    data.preventDefault();

    if (!name) return;

    const topic = {
      name: name,
      witness: witness._id,
      case: p_case._id,
    };

    if (topic.name != '' && topic.witness != undefined && topic.case != '') {
      axios
        .post(prod() + '/api/cases/create/witness/topic', topic)
        .then((use) => {
          addTopicToTopicArray(name);
          for (let a of use.data.case.witnesses) {
            if (a._id == witness._id) {
              updateWitness(use.data.case, a);
            }
          }
          setClicked(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (clicked) {
    setTimeout(() => {
      setClicked(false);
    }, 2000);
  }

  return (
    <div>
      {clicked && (
        <Button className='s-item create-topic-btn'>
          Created
          <img
            style={{ width: '22px', height: '13px' }}
            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
          ></img>
        </Button>
      )}
      {!clicked && !topicArray.includes(name) && (
        <Button className='s-item create-topic-btn' onClick={handleSubmit}>
          Create Topic
        </Button>
      )}
    </div>
  );
};

class Checklist extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      redirect: false,
      checklist: {
        reliability: {
          status: '',
          bias: {
            financial: '',
            relationship: '',
            hostility: '',
          },
          impairment_alcohol_or_drugs: '',
          physical_deficiency: '',
          lack_knowledge_of_events_faulty_perception: {
            focus: '',
            witness_preoccupied: '',
            m_quantity_and_distance_not_reliable: '',
            desire_to_find_meaning: '',
            establish_inability_to_see: '',
            establish_inability_to_hear: '',
            impact_of_sudden_events: '',
            insignificance_of_event: '',
            ability_to_observe: '',
            expose_belief_from_habit: '',
          },
          reliability_of_memory: {
            forget_events: '',
            flawed: '',
            experience: '',
            collateral: '',
            influence: '',
          },
        },
        plausibility_and_consistency: {
          status: '',
          prior_inconsistent: {
            accuse: '',
            reliable: '',
            confront: '',
          },

          implausible: '',
          contradicted: '',
        },
        credibility: {
          status: '',
          record: '',
          evidence: '',
        },
      },
      hShow: false,
      show: false,

      bias: false,
      biasFriend: false,
      hostility: false,
      impairment: false,
      pyhsical: false,

      focus: false,
      wit: false,
      desire: false,
      see: false,
      hear: false,

      impact: false,
      insignificance: false,
      expose: false,
      reliability: false,

      reliabilityMem: false,
      reliabilityExp: false,
      reliabilityEst: false,
      reliabilityInflu: false,

      prior: false,
      version: false,
      contradicted: false,

      priorCriminal: false,
      evidence: false,

      //create topic
      topicCreateStatus: '',
      topicArray: [],
      showTopicCreatedModal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.saveItem = this.saveItem.bind(this);
    this.toggleStatus = this.toggleStatus.bind(this);
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);
    // export
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // ref
    this.handleClick = this.handleClick.bind(this);
    this.recordRef = React.createRef();
    this.evidenceRef = React.createRef();
    this.accuseRef = React.createRef();
    this.reliableRef = React.createRef();
    this.confrontRef = React.createRef();
    this.implausibleRef = React.createRef();
    this.contradictedRef = React.createRef();
    //
    this.financialRef = React.createRef();
    this.relationshipRef = React.createRef();
    this.hostilityRef = React.createRef();
    this.aRef = React.createRef();
    this.bRef = React.createRef();
    //
    this.focusRef = React.createRef();
    this.witness_preoccupiedRef = React.createRef();
    this.m_quantity_and_distance_not_reliableRef = React.createRef();
    //

    this.desire_to_find_meaningRef = React.createRef();
    this.establish_inability_to_seeRef = React.createRef();
    this.establish_inability_to_hearRef = React.createRef();
    this.impact_of_sudden_eventsRef = React.createRef();
    this.insignificance_of_eventRef = React.createRef();
    //
    this.ability_to_observeRef = React.createRef();
    this.expose_belief_from_habitRef = React.createRef();
    this.forget_eventsRef = React.createRef();

    this.flawedRef = React.createRef();
    this.experienceRef = React.createRef();
    this.collateralRef = React.createRef();
    this.influenceRef = React.createRef();

    //create topic
    this.addTopicToTopicArray = this.addTopicToTopicArray.bind(this);
  }

  handleBias = () => {
    this.setState({
      bias: !this.state.bias,
    });
  };

  handleBiasFriend = () => {
    this.setState({
      biasFriend: !this.state.biasFriend,
    });
  };
  handleHostility = () => {
    this.setState({
      hostility: !this.state.hostility,
    });
  };

  handleImpairment = () => {
    this.setState({
      impairment: !this.state.impairment,
    });
  };

  handlePyhsical = () => {
    this.setState({
      pyhsical: !this.state.pyhsical,
    });
  };

  handleFocus = () => {
    this.setState({
      focus: !this.state.focus,
    });
  };

  handleWitness = () => {
    this.setState({
      wit: !this.state.wit,
    });
  };

  handleDesire = () => {
    this.setState({
      desire: !this.state.desire,
    });
  };

  see = () => {
    this.setState({
      see: !this.state.see,
    });
  };

  hear = () => {
    this.setState({
      hear: !this.state.hear,
    });
  };
  impact = () => {
    this.setState({
      impact: !this.state.impact,
    });
  };
  insignificance = () => {
    this.setState({
      insignificance: !this.state.insignificance,
    });
  };
  expose = () => {
    this.setState({
      expose: !this.state.expose,
    });
  };

  reliability = () => {
    this.setState({
      reliability: !this.state.reliability,
    });
  };

  reliabilityMem = () => {
    this.setState({
      reliabilityMem: !this.state.reliabilityMem,
    });
  };
  reliabilityExp = () => {
    this.setState({
      reliabilityExp: !this.state.reliabilityExp,
    });
  };
  reliabilityEst = () => {
    this.setState({
      reliabilityEst: !this.state.reliabilityEst,
    });
  };
  reliabilityInflu = () => {
    this.setState({
      reliabilityInflu: !this.state.reliabilityInflu,
    });
  };

  prior = () => {
    this.setState({
      prior: !this.state.prior,
    });
  };
  version = () => {
    this.setState({
      version: !this.state.version,
    });
  };
  contradicted = () => {
    this.setState({
      contradicted: !this.state.contradicted,
    });
  };
  evidence = () => {
    this.setState({
      evidence: !this.state.evidence,
    });
  };

  priorCriminal = () => {
    this.setState({
      priorCriminal: !this.state.priorCriminal,
    });
  };

  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/checklist', {
          case: this.state.case._id,
          witness: this.state.witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          this.setState(
            {
              witness: user.data,
            },
            () => {
              this.props.updateWitness(this.state.case, user.data);
            }
          );
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };

  handleClick = (event) => {
    event.preventDefault();
    const name = event.target.getAttribute('name');
    const value = event.target.getAttribute('value');
    const key = event.target.id;
    const parent = event.target.attributes.getNamedItem('parent').value;
    const subParent = event.target.attributes.getNamedItem('subParent').value;

    if (subParent != '-1') {
      this.setState(
        ({ checklist }) => ({
          checklist: {
            ...checklist,
            [parent]: {
              ...checklist[parent],
              [subParent]: {
                ...checklist[parent][subParent],
                [name]: value,
              },
            },
          },
        }),
        (s) => {
          if (name == 'record') {
            this.recordRef.current._ref.focus();
          } else if (name == 'evidence') {
            this.evidenceRef.current._ref.focus();
          } else if (name == 'accuse') {
            this.accuseRef.current._ref.focus();
          } else if (name == 'reliable') {
            this.reliableRef.current._ref.focus();
          } else if (name == 'confront') {
            this.confrontRef.current._ref.focus();
          } else if (name == 'implausible') {
            this.implausibleRef.current._ref.focus();
          } else if (name == 'contradicted') {
            this.contradictedRef.current._ref.focus();
          } else if (name == 'financial') {
            this.financialRef.current._ref.focus();
          } else if (name == 'relationship') {
            this.relationshipRef.current._ref.focus();
          } else if (name == 'hostility') {
            this.hostilityRef.current._ref.focus();
          } else if (name == 'impairment_alcohol_or_drugs') {
            this.aRef.current._ref.focus();
          } else if (name == ' physical_deficiency') {
            this.bRef.current._ref.focus();
          } else if (name == 'focus') {
            this.focusRef.current._ref.focus();
          } else if (name == 'witness_preoccupied') {
            this.witness_preoccupiedRef.current._ref.focus();
          } else if (name == 'm_quantity_and_distance_not_reliable') {
            this.m_quantity_and_distance_not_reliableRef.current._ref.focus();
          } else if (name == 'desire_to_find_meaning') {
            this.desire_to_find_meaningRef.current._ref.focus();
          } else if (name == 'establish_inability_to_see') {
            this.establish_inability_to_seeRef.current._ref.focus();
          } else if (name == 'establish_inability_to_hear') {
            this.establish_inability_to_hearRef.current._ref.focus();
          } else if (name == 'impact_of_sudden_events') {
            this.impact_of_sudden_eventsRef.current._ref.focus();
          } else if (name == 'insignificance_of_event') {
            this.insignificance_of_eventRef.current._ref.focus();
          } else if (name == 'ability_to_observe') {
            this.ability_to_observeRef.current._ref.focus();
          } else if (name == 'expose_belief_from_habit') {
            this.expose_belief_from_habitRef.current._ref.focus();
          } else if (name == 'forget_events') {
            this.forget_eventsRef.current._ref.focus();
          } else if (name == 'flawed') {
            this.flawedRef.current._ref.focus();
          } else if (name == 'experience') {
            this.experienceRef.current._ref.focus();
          } else if (name == 'collateral') {
            this.collateralRef.current._ref.focus();
          } else if (name == 'influence') {
            this.influenceRef.current._ref.focus();
          }
        }
      );
    } else {
      this.setState(
        ({ checklist }) => ({
          checklist: {
            ...checklist,
            [parent]: {
              ...checklist[parent],
              [name]: value,
            },
          },
        }),
        () => {
          if (name == 'record') {
            this.recordRef.current._ref.focus();
          } else if (name == 'evidence') {
            this.evidenceRef.current._ref.focus();
          } else if (name == 'accuse') {
            this.accuseRef.current._ref.focus();
          } else if (name == 'reliable') {
            this.reliableRef.current._ref.focus();
          } else if (name == 'confront') {
            this.confrontRef.current._ref.focus();
          } else if (name == 'implausible') {
            this.implausibleRef.current._ref.focus();
          } else if (name == 'contradicted') {
            this.contradictedRef.current._ref.focus();
          } else if (name == 'financial') {
            this.financialRef.current._ref.focus();
          } else if (name == 'relationship') {
            this.relationshipRef.current._ref.focus();
          } else if (name == 'hostility') {
            this.hostilityRef.current._ref.focus();
          } else if (name == 'impairment_alcohol_or_drugs') {
            this.aRef.current._ref.focus();
          } else if (name == ' physical_deficiency') {
            this.bRef.current._ref.focus();
          } else if (name == 'focus') {
            this.focusRef.current._ref.focus();
          } else if (name == 'witness_preoccupied') {
            this.witness_preoccupiedRef.current._ref.focus();
          } else if (name == 'm_quantity_and_distance_not_reliable') {
            this.m_quantity_and_distance_not_reliableRef.current._ref.focus();
          } else if (name == 'desire_to_find_meaning') {
            this.desire_to_find_meaningRef.current._ref.focus();
          } else if (name == 'establish_inability_to_see') {
            this.establish_inability_to_seeRef.current._ref.focus();
          } else if (name == 'establish_inability_to_hear') {
            this.establish_inability_to_hearRef.current._ref.focus();
          } else if (name == 'impact_of_sudden_events') {
            this.impact_of_sudden_eventsRef.current._ref.focus();
          } else if (name == 'insignificance_of_event') {
            this.insignificance_of_eventRef.current._ref.focus();
          } else if (name == 'ability_to_observe') {
            this.ability_to_observeRef.current._ref.focus();
          } else if (name == 'expose_belief_from_habit') {
            this.expose_belief_from_habitRef.current._ref.focus();
          } else if (name == 'forget_events') {
            this.forget_eventsRef.current._ref.focus();
          } else if (name == 'flawed') {
            this.flawedRef.current._ref.focus();
          } else if (name == 'experience') {
            this.experienceRef.current._ref.focus();
          } else if (name == 'collateral') {
            this.collateralRef.current._ref.focus();
          } else if (name == 'influence') {
            this.influenceRef.current._ref.focus();
          }
        }
      );
    }
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleHShow = () => {
    this.setState({ hShow: true });
  };
  handleHClose = () => {
    this.setState({ hShow: false });
  };
  toggleStatus = (event) => {
    const name = event.target.attributes.getNamedItem('name')
      ? event.target.attributes.getNamedItem('name').value
      : undefined;
    if (name == undefined) {
      return false;
    }

    const value = event.target.value;

    axios
      .post(prod() + '/api/cases/update/witness/checklist/toggle', {
        name: name,
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
      })
      .then((use) => {
        const items = [];

        axios
          .post(prod() + '/api/cases/get/witness/checklist', {
            case: this.props.case['_id'],
            witness: this.props.witness['_id'],
          })
          .then((use) => {
            this.setState({ checklist: use.data.checklist });
            console.log('STATUS: ', use.data.checklist);
          })
          .catch((e) => {
            console.log('ERROR', e);
          });
      });
  };
  handleChange = (event) => {
    //val
    const key = event.target.id;
    const name = event.target.name;
    const value = event.target.value;
    const parent = event.target.attributes.getNamedItem('parent').value;
    const subParent = event.target.attributes.getNamedItem('subParent').value;

    if (subParent != '-1') {
      this.setState(({ checklist }) => ({
        checklist: {
          ...checklist,
          [parent]: {
            ...checklist[parent],
            [subParent]: {
              ...checklist[parent][subParent],
              [name]: value,
            },
          },
        },
      }));
    } else {
      this.setState(({ checklist }) => ({
        checklist: {
          ...checklist,
          [parent]: {
            ...checklist[parent],
            [name]: value,
          },
        },
      }));
    }
  };
  componentDidUpdate(props) {
    if (this.state.checklist != props.checklist) this.handleProgress();
  }
  componentDidMount() {
    logPageView();
    window.scrollTo(0, 0);

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      this.props.witness == undefined ||
      this.props.witness == null ||
      Object.keys(this.props.case).length === 0 ||
      Object.keys(this.props.witness).length === 0
    ) {
      this.setState({ redirect: true });
    } else {
      if (this.props.second != true) {
        this.props.getTab({
          case: this.props.case,
          witness: this.props.witness,
          topic: '',
          path: '/case/witness/checklist',
          name: 'Checklist',
          active: true,
        });
      }

      axios
        .post(prod() + '/api/cases/getall/witness/topic', {
          case: this.props.case['_id'],
          witnesses: this.props.witness['_id'],
        })
        .then((res) => {
          console.log('topics: ', res.data.topics);
          const topicArray = [];
          for (const topic of res.data.topics) {
            topicArray.push(topic.name);
          }
          console.log('topicArray: ', topicArray);
          this.setState({ topicArray });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });

      axios
        .post(prod() + '/api/cases/get/witness/checklist', {
          case: this.props.case['_id'],
          witness: this.props.witness['_id'],
        })
        .then((use) => {
          this.setState(
            {
              checklist: use.data.checklist,
              case: this.props.case,
              witness: this.props.witness,
            },
            () => {
              this.handleProgress();
            }
          );
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  saveItem() {
    axios
      .post(prod() + '/api/cases/update/witness/checklist', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        checklist: this.state.checklist,
      })
      .then((user) => {
        const items = [];
        console.log(user);
        for (let a of user.data.checklist.witnesses) {
          if (a._id == this.props.witness._id) {
            console.log(user.data.checklist, a);
            this.props.updateWitness(user.data.checklist, a);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          user: 'Focus Error',
        });
      });
  }
  handleProgress = () => {
    let p = 0;
    const n = this.state;

    if (
      n.checklist.reliability &&
      n.checklist.reliability.bias &&
      n.checklist.reliability.bias.financial != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.bias &&
      n.checklist.reliability.bias.relationship != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.bias &&
      n.checklist.reliability.bias.hostility != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.impairment_alcohol_or_drugs != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.physical_deficiency != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .focus != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .witness_preoccupied != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .m_quantity_and_distance_not_reliable != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .desire_to_find_meaning != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .establish_inability_to_see != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .establish_inability_to_hear != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .impact_of_sudden_events != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .insignificance_of_event != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .ability_to_observe != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
      n.checklist.reliability.lack_knowledge_of_events_faulty_perception
        .expose_belief_from_habit != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.reliability_of_memory &&
      n.checklist.reliability.reliability_of_memory.forget_events != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.reliability_of_memory &&
      n.checklist.reliability.reliability_of_memory.flawed != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.reliability_of_memory &&
      n.checklist.reliability.reliability_of_memory.experience != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.reliability_of_memory &&
      n.checklist.reliability.reliability_of_memory.collateral != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.reliability &&
      n.checklist.reliability.reliability_of_memory &&
      n.checklist.reliability.reliability_of_memory.influence != ''
    ) {
      p += 4.34782608696;
    }

    if (
      n.checklist.plausibility_and_consistency &&
      n.checklist.plausibility_and_consistency.prior_inconsistent &&
      n.checklist.plausibility_and_consistency.prior_inconsistent.accuse != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.plausibility_and_consistency &&
      n.checklist.plausibility_and_consistency.prior_inconsistent &&
      n.checklist.plausibility_and_consistency.prior_inconsistent.reliable != ''
    ) {
      p += 4.34782608696;
    }

    if (
      n.checklist.plausibility_and_consistency &&
      n.checklist.plausibility_and_consistency.prior_inconsistent &&
      n.checklist.plausibility_and_consistency.prior_inconsistent.confront != ''
    ) {
      p += 4.34782608696;
    }

    if (
      n.checklist.plausibility_and_consistency &&
      n.checklist.plausibility_and_consistency.implausible
    ) {
      p += 4.34782608696;
    }

    if (
      n.checklist.plausibility_and_consistency &&
      n.checklist.plausibility_and_consistency.contradicted
    ) {
      p += 4.34782608696;
    }

    if (
      n.checklist.credibility &&
      n.checklist.credibility.record &&
      n.checklist.credibility.record != ''
    ) {
      p += 4.34782608696;
    }
    if (
      n.checklist.credibility &&
      n.checklist.credibility.evidence &&
      n.checklist.credibility.evidence != ''
    ) {
      p += 4.34782608696;
    }
    if (p != this.state.progress) this.setState({ progress: p });
  };

  addTopicToTopicArray(topic) {
    this.setState({ topicArray: [...this.state.topicArray, topic] });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash'>
        <Container fluid id='foc'>
          <Row>
            <Col md={5}>
              <div className='case-dash-container'>
                {this.state.witness &&
                this.state.witness.checklist.important ? (
                  <img
                    className='logo-title-dash'
                    onClick={(e) =>
                      this.handleImportant(
                        e,

                        false
                      )
                    }
                    src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                  ></img>
                ) : (
                  <img
                    onClick={(e) => this.handleImportant(e, true)}
                    className='logo-title-dash card_important_comment'
                    src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                  ></img>
                )}
              </div>
              <h1>Cross-examination Checklist</h1>
              <ProgressBar now={Math.round(this.state.progress)} />
            </Col>
            <Col md={7}>
              <div className='add-btn add-btn-p add-btn-p2 topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <div className='add-btn add-btn-p add-btn-p2 topic-btn'>
                <Pulse>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Pulse>
              </div>
              <ChecklistModal
                show={this.state.show}
                handleClose={this.handleClose}
                checklist={this.state.checklist}
                hShow={this.state.hShow}
                handleHClose={this.handleHClose}
                bias={this.state.bias}
                handleBias={this.handleBias}
                biasFriend={this.state.biasFriend}
                handleBiasFriend={this.handleBiasFriend}
                hostility={this.state.hostility}
                handleHostility={this.handleHostility}
                impairment={this.state.impairment}
                handleImpairment={this.handleImpairmen}
                pyhsical={this.state.pyhsical}
                handlePyhsical={this.handlePyhsical}
                focus={this.state.focus}
                handleFocus={this.handleFocus}
                witness={this.state.wit}
                handleWitness={this.handleWitness}
                desire={this.state.desire}
                handleDesire={this.handleDesire}
                see={this.state.see}
                hideSee={this.see}
                hear={this.state.hear}
                hideHear={this.hear}
                impact={this.state.impact}
                hideImpact={this.impact}
                insignificance={this.state.insignificanc}
                hideInsignificance={this.insignificanc}
                expose={this.state.expose}
                hideExpose={this.expose}
                reliability={this.state.reliability}
                hideReliability={this.reliability}
                reliabilityMem={this.state.reliabilityMem}
                hideReliabilityMem={this.reliabilityMem}
                reliabilityExp={this.state.reliabilityExp}
                hideReliabilityExp={this.reliabilityExp}
                reliabilityEst={this.state.reliabilityEst}
                hideReliabilityEst={this.reliabilityEst}
                reliabilityInflu={this.state.reliabilityInflu}
                hideReliabilityInflu={this.reliabilityInflu}
                prior={this.state.prior}
                hidePrior={this.prior}
                version={this.state.version}
                hideVersion={this.version}
                contradicted={this.state.contradicted}
                hideContradicted={this.contradicted}
                evidence={this.state.evidence}
                hideEvidence={this.evidence}
                priorCriminal={this.state.priorCriminal}
                hidePriorCriminal={this.priorCriminal}
                darkMode={this.props.darkMode}
              />
            </Col>
          </Row>

          <div id='focus-form'>
            <Fade>
              <div className='d-block d-md-none '>
                <div className='to-center'>
                  <Row>
                    <Col xs={6}>
                      <h2 className='check-title'>Reliability Of Witness</h2>
                    </Col>
                    <Col xs={6}>
                      <p
                        name='reliability'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.reliability.status ===
                        'incomplete'
                          ? 'Mark Completed'
                          : 'Complete'}
                        {this.state.checklist.reliability.status ==
                          'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>

                <Row className='saved-input underline-t'>
                  <h4>Bias and Motive - Financial Interest</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.reliability.bias.financial}
                        onChange={this.handleChange}
                        name='financial'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                      />
                    </Form.Group>
                    <div className='suggestion'>
                      <Fragment>
                        <div className='s-items'>
                          <Button
                            name='weaknesses'
                            value='Employee'
                            onClick={this.handleClick}
                            className='s-item'
                          >
                            Employee
                          </Button>
                          <Button
                            name='weaknesses'
                            value='Civil Lawsuit'
                            className='s-item'
                            onClick={this.handleClick}
                          >
                            Civil Lawsuit
                          </Button>
                          <Button
                            name='weaknesses'
                            value='Payed By Other Party'
                            className='s-item'
                            onClick={this.handleClick}
                          >
                            Payed By Other Party
                          </Button>
                        </div>
                      </Fragment>
                    </div>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Bias and Motive - Friendship, Relative</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleTopic}
                    ></img>
                  </Row>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.bias.relationship
                        }
                        onChange={this.handleChange}
                        name='relationship'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Bias and Motive - Hostility</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.reliability.bias.hostility}
                        onChange={this.handleChange}
                        name='hostility'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Impairment Alcohol Or Drugs</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .impairment_alcohol_or_drugs
                        }
                        onChange={this.handleChange}
                        name='impairment_alcohol_or_drugs'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Physical Deficiency (Eyesight, hearing etc)</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.physical_deficiency
                        }
                        onChange={this.handleChange}
                        name='physical_deficiency'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Focus Of
                      Attention Is Not On Event
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception.focus
                        }
                        onChange={this.handleChange}
                        name='focus'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Witness
                      Preoccupied
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .witness_preoccupied
                        }
                        onChange={this.handleChange}
                        name='witness_preoccupied'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Measures
                      Of Quantity, And Distance Are Often Unreliable
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .m_quantity_and_distance_not_reliable
                        }
                        onChange={this.handleChange}
                        name='m_quantity_and_distance_not_reliable'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Desire
                      To Find Meaning
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .desire_to_find_meaning
                        }
                        onChange={this.handleChange}
                        name='desire_to_find_meaning'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To See
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .establish_inability_to_see
                        }
                        onChange={this.handleChange}
                        name='establish_inability_to_see'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To Hear
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .establish_inability_to_hear
                        }
                        onChange={this.handleChange}
                        name='establish_inability_to_hear'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Impact
                      Of Sudden Events
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .impact_of_sudden_events
                        }
                        onChange={this.handleChange}
                        name='impact_of_sudden_events'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Insignificance Of Event
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .insignificance_of_event
                        }
                        onChange={this.handleChange}
                        name='insignificance_of_event'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Ability
                      To Observe
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .ability_to_observe
                        }
                        onChange={this.handleChange}
                        name='ability_to_observe'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Expose
                      Belief From Habit
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .expose_belief_from_habit
                        }
                        onChange={this.handleChange}
                        name='expose_belief_from_habit'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='lack_knowledge_of_events_faulty_perception'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Reliability of Memory - Witnesses Forget Events Over Time,
                      And Subsequent Events Can Distort Memory
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .forget_events
                        }
                        onChange={this.handleChange}
                        name='forget_events'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Reliability of Memory - Memory Is Highly Flawed</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .flawed
                        }
                        onChange={this.handleChange}
                        name='flawed'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row></Row>

                  <h4>
                    Reliability of Memory - Experience, Social Values, Prejudice
                    And Emotions Can Affect The Way An Event Is Remembered
                  </h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .experience
                        }
                        onChange={this.handleChange}
                        name='experience'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                        style={{ marginTop: '10px'}}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>
                    Reliability of Memory - Establish Poor Memory-Cross On
                    Collateral Details
                  </h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .collateral
                        }
                        onChange={this.handleChange}
                        name='collateral'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Reliability of Memory - Influence Of Hearsay</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .influence
                        }
                        onChange={this.handleChange}
                        name='influence'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <div className='to-center'>
                    <Col xs={12}>
                      <h2 className='check-title'>
                        Plausibility and Consistency
                      </h2>
                    </Col>
                    <Col xs={12}>
                      <p
                        name='plausibility_and_consistency'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.plausibility_and_consistency
                          .status === 'incomplete'
                          ? 'Mark Completed'
                          : 'Complete'}
                        {this.state.checklist.plausibility_and_consistency
                          .status == 'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </div>
                </Row>

                <Row className='saved-input underline-t'>
                  <h4>
                    Prior Inconsistent Statements - Accuse Of False Statement At
                    Trial
                  </h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .prior_inconsistent.accuse
                        }
                        onChange={this.handleChange}
                        name='accuse'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='prior_inconsistent'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>
                    Prior Inconsistent Statements - Show The Circumstances Of
                    Prior Statement- Why More Reliable
                  </h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .prior_inconsistent.reliable
                        }
                        onChange={this.handleChange}
                        name='reliable'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='prior_inconsistent'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>
                    Prior Inconsistent Statements - Confront With Prior
                    Statement
                  </h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .prior_inconsistent.confront
                        }
                        onChange={this.handleChange}
                        name='confront'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='prior_inconsistent'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Version Is Implausible Or Improbable</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .implausible
                        }
                        onChange={this.handleChange}
                        name='implausible'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Contradicted By Other Evidence Or Witnesses</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .contradicted
                        }
                        onChange={this.handleChange}
                        name='contradicted'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <div className='to-center'>
                    <Col xs={12}>
                      <h2 className='check-title'>Credibility of Witness</h2>
                    </Col>
                    <Col xs={12}>
                      <p
                        name='credibility'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.credibility.status ===
                        'incomplete'
                          ? 'Mark Completed'
                          : 'Completed'}
                        {this.state.checklist.credibility.status ==
                          'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </div>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Prior Criminal Record</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.credibility.record}
                        onChange={this.handleChange}
                        name='record'
                        parent='credibility'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <h4>Evidence Of Misconduct Or Character</h4>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.credibility.evidence}
                        onChange={this.handleChange}
                        name='evidence'
                        parent='credibility'
                        onBlur={this.saveItem}
                        subParent='-1'
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              {/* DESK */}
              <div className='d-none d-md-block '>
                <Row>
                  <div className='to-center'>
                    <Col xs={12}>
                      <h2 className='check-title'>Reliability Of Witness</h2>
                    </Col>
                    <Col xs={12}>
                      <p
                        name='reliability'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.reliability.status ===
                        'incomplete'
                          ? 'Mark Completed'
                          : 'Complete'}
                        {this.state.checklist.reliability.status ==
                          'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </div>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Bias and Motive - Financial Interest</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBias}
                    ></img>
                  </Row>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.reliability.bias.financial}
                        onChange={this.handleChange}
                        name='financial'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                        ref={this.financialRef}
                      />

                      {this.state.checklist.reliability.bias.financial.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Employee'
                                onClick={this.handleClick}
                                className='s-item'
                                name='financial'
                                parent='reliability'
                                subParent='bias'
                              >
                                Employee
                              </Button>
                              <Button
                                value='Civil Lawsuit'
                                className='s-item'
                                onClick={this.handleClick}
                                name='financial'
                                parent='reliability'
                                subParent='bias'
                              >
                                Civil Lawsuit
                              </Button>
                              <Button
                                value='Payed By Other Party'
                                className='s-item'
                                onClick={this.handleClick}
                                name='financial'
                                parent='reliability'
                                subParent='bias'
                              >
                                Payed By Other Party
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.bias.financial.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.bias.financial.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Bias and Motive - Friendship, Relative</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleBiasFriend}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.bias.relationship
                        }
                        onChange={this.handleChange}
                        name='relationship'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                        ref={this.relationshipRef}
                      />

                      {this.state.checklist.reliability.bias.relationship.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Friend'
                                onClick={this.handleClick}
                                className='s-item'
                                name='relationship'
                                parent='reliability'
                                subParent='bias'
                              >
                                Friend
                              </Button>
                              <Button
                                value='Relative'
                                className='s-item'
                                onClick={this.handleClick}
                                name='relationship'
                                parent='reliability'
                                subParent='bias'
                              >
                                Relative
                              </Button>
                              <Button
                                value='Coworker'
                                className='s-item'
                                onClick={this.handleClick}
                                name='relationship'
                                parent='reliability'
                                subParent='bias'
                              >
                                Coworker
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.bias.relationship.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.bias.relationship.trim()}
                            className='create-topic-btn'
                            witness={this.props.witness}
                            p_case={this.props.case}
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Bias and Motive - Hostility</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleHostility}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.reliability.bias.hostility}
                        onChange={this.handleChange}
                        name='hostility'
                        parent='reliability'
                        subParent='bias'
                        onBlur={this.saveItem}
                        ref={this.hostilityRef}
                      />

                      {this.state.checklist.reliability.bias.hostility.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Fragment>
                                <Button
                                  value='Personal Dispute'
                                  onClick={this.handleClick}
                                  className='s-item'
                                  name='hostility'
                                  parent='reliability'
                                  subParent='bias'
                                >
                                  Personal Dispute
                                </Button>
                                <Button
                                  value='Victim'
                                  className='s-item'
                                  onClick={this.handleClick}
                                  name='hostility'
                                  parent='reliability'
                                  subParent='bias'
                                >
                                  Victim
                                </Button>
                              </Fragment>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.bias.hostility.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.bias.hostility.trim()}
                            className='create-topic-btn'
                            witness={this.props.witness}
                            p_case={this.props.case}
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Impairment Alcohol Or Drugs</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleImpairment}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .impairment_alcohol_or_drugs
                        }
                        onChange={this.handleChange}
                        name='impairment_alcohol_or_drugs'
                        parent='reliability'
                        subParent='-1'
                        onBlur={this.saveItem}
                        ref={this.aRef}
                      />

                      {this.state.checklist.reliability.impairment_alcohol_or_drugs.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Fragment>
                                <Button
                                  value='Alcohol'
                                  onClick={this.handleClick}
                                  className='s-item'
                                  name='impairment_alcohol_or_drugs'
                                  parent='reliability'
                                  subParent='-1'
                                  physical_deficiency
                                >
                                  Alcohol
                                </Button>
                                <Button
                                  value='Illegal Drugs'
                                  className='s-item'
                                  onClick={this.handleClick}
                                  name='impairment_alcohol_or_drugs'
                                  parent='reliability'
                                  subParent='-1'
                                >
                                  Illegal Drugs
                                </Button>
                                <Button
                                  value='Prescription Drugs'
                                  className='s-item'
                                  onClick={this.handleClick}
                                  name='impairment_alcohol_or_drugs'
                                  parent='reliability'
                                  subParent='-1'
                                >
                                  Prescription Drugs
                                </Button>
                              </Fragment>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.impairment_alcohol_or_drugs.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.impairment_alcohol_or_drugs.trim()}
                            witness={this.props.witness}
                            className='create-topic-btn'
                            p_case={this.props.case}
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Physical Deficiency (Eyesight, hearing etc)</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handlePyhsical}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.physical_deficiency
                        }
                        onChange={this.handleChange}
                        name='physical_deficiency'
                        parent='reliability'
                        subParent='-1'
                        onBlur={this.saveItem}
                        ref={this.bRef}
                      />

                      {this.state.checklist.reliability.physical_deficiency.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Fragment>
                                <Button
                                  value='Eyesight'
                                  onClick={this.handleClick}
                                  className='s-item'
                                  name='physical_deficiency'
                                  parent='reliability'
                                  subParent='-1'
                                >
                                  Eyesight
                                </Button>
                                <Button
                                  value='Hearing'
                                  className='s-item'
                                  onClick={this.handleClick}
                                  name='physical_deficiency'
                                  parent='reliability'
                                  subParent='-1'
                                >
                                  Hearing
                                </Button>
                              </Fragment>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.physical_deficiency.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.physical_deficiency.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Focus Of
                      Attention Is Not On Event
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleFocus}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception.focus
                        }
                        onChange={this.handleChange}
                        name='focus'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        onBlur={this.saveItem}
                        ref={this.focusRef}
                      />
                    </Form.Group>

                    {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.focus.trim() ==
                      '' &&
                      this.state.checklist.reliability.status ==
                        'incomplete' && (
                        <div className='suggestion'>
                          <div className='s-items'>
                            <Fragment>
                              <Button
                                value='Distractions'
                                onClick={this.handleClick}
                                className='s-item'
                                name='focus'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Distractions
                              </Button>
                              <Button
                                value='Unexpected Event'
                                className='s-item'
                                onClick={this.handleClick}
                                name='focus'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Unexpected Event
                              </Button>
                              <Button
                                value='Busy Or Occupied'
                                className='s-item'
                                onClick={this.handleClick}
                                name='focus'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Busy Or Occupied
                              </Button>
                            </Fragment>
                          </div>
                        </div>
                      )}
                    {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.focus.trim() !==
                      '' &&
                      this.state.checklist.reliability.status ==
                        'incomplete' && (
                        <CreateTopic
                          name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.focus.trim()}
                          witness={this.props.witness}
                          p_case={this.props.case}
                          className='create-topic-btn'
                          updateWitness={this.props.updateWitness}
                          topicArray={this.state.topicArray}
                          addTopicToTopicArray={this.addTopicToTopicArray}
                        />
                      )}
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Witness
                      Preoccupied
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleWitness}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .witness_preoccupied
                        }
                        onChange={this.handleChange}
                        name='witness_preoccupied'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        ref={this.witness_preoccupiedRef}
                        onBlur={this.saveItem}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.witness_preoccupied.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Fragment>
                                <Button
                                  value='Witness Speculation'
                                  onClick={this.handleClick}
                                  className='s-item'
                                  name='witness_preoccupied'
                                  parent='reliability'
                                  subParent='lack_knowledge_of_events_faulty_perception'
                                >
                                  Witness Speculation
                                </Button>
                                <Button
                                  value='Biased Towards One Conclusion'
                                  className='s-item'
                                  onClick={this.handleClick}
                                  name='witness_preoccupied'
                                  parent='reliability'
                                  subParent='lack_knowledge_of_events_faulty_perception'
                                >
                                  Biased Towards One Conclusion
                                </Button>
                              </Fragment>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.witness_preoccupied.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.witness_preoccupied.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Desire
                      To Find Meaning
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.handleDesire}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .desire_to_find_meaning
                        }
                        onChange={this.handleChange}
                        name='desire_to_find_meaning'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        onBlur={this.saveItem}
                        ref={this.desire_to_find_meaningRef}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.desire_to_find_meaning.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Witness Speculation'
                                onClick={this.handleClick}
                                className='s-item'
                                name='desire_to_find_meaning'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Witness Speculation
                              </Button>
                              <Button
                                value='Biased Towards One Conclusion'
                                className='s-item'
                                onClick={this.handleClick}
                                name='desire_to_find_meaning'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Biased Towards One Conclusion
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.desire_to_find_meaning.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.desire_to_find_meaning.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To See
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.see}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .establish_inability_to_see
                        }
                        onChange={this.handleChange}
                        name='establish_inability_to_see'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        onBlur={this.saveItem}
                        ref={this.establish_inability_to_seeRef}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_see.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Distance'
                                onClick={this.handleClick}
                                className='s-item'
                                name='establish_inability_to_see'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Distance
                              </Button>
                              <Button
                                value='Obstruction'
                                className='s-item'
                                onClick={this.handleClick}
                                name='establish_inability_to_see'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Obstruction
                              </Button>
                              <Button
                                value='Movements'
                                className='s-item'
                                onClick={this.handleClick}
                                name='establish_inability_to_see'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Movements
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_see.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_see.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To Hear
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.hear}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .establish_inability_to_hear
                        }
                        onChange={this.handleChange}
                        name='establish_inability_to_hear'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        onBlur={this.saveItem}
                        ref={this.establish_inability_to_hearRef}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_hear.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Distance'
                                onClick={this.handleClick}
                                className='s-item'
                                name='establish_inability_to_hear'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Distance
                              </Button>
                              <Button
                                value='Other Noises'
                                className='s-item'
                                onClick={this.handleClick}
                                name='establish_inability_to_hear'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Other Noises
                              </Button>
                              <Button
                                value='Obstruction'
                                className='s-item'
                                onClick={this.handleClick}
                                name='establish_inability_to_hear'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Obstruction
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_hear.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.establish_inability_to_hear.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Impact
                      Of Sudden Events
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.impact}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .impact_of_sudden_events
                        }
                        onChange={this.handleChange}
                        name='impact_of_sudden_events'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        ref={this.impact_of_sudden_eventsRef}
                        onBlur={this.saveItem}
                      />
                    </Form.Group>

                    {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.impact_of_sudden_events.trim() ==
                      '' &&
                      this.state.checklist.reliability.status ==
                        'incomplete' && (
                        <div className='suggestion'>
                          <div></div>
                          <div className='s-items'>
                            <Button
                              value='Unexpected'
                              onClick={this.handleClick}
                              className='s-item'
                              name='impact_of_sudden_events'
                              parent='reliability'
                              subParent='lack_knowledge_of_events_faulty_perception'
                            >
                              Unexpected
                            </Button>
                            <Button
                              value='Duration'
                              className='s-item'
                              onClick={this.handleClick}
                              name='impact_of_sudden_events'
                              parent='reliability'
                              subParent='lack_knowledge_of_events_faulty_perception'
                            >
                              Duration
                            </Button>
                            <Button
                              value='Surprise / Shock'
                              className='s-item'
                              onClick={this.handleClick}
                              name='impact_of_sudden_events'
                              parent='reliability'
                              subParent='lack_knowledge_of_events_faulty_perception'
                            >
                              Surprise / Shock
                            </Button>
                          </div>
                        </div>
                      )}
                    {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.impact_of_sudden_events.trim() !==
                      '' &&
                      this.state.checklist.reliability.status ===
                        'incomplete' && (
                        <CreateTopic
                          name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.impact_of_sudden_events.trim()}
                          witness={this.props.witness}
                          p_case={this.props.case}
                          className='create-topic-btn'
                          updateWitness={this.props.updateWitness}
                          topicArray={this.state.topicArray}
                          addTopicToTopicArray={this.addTopicToTopicArray}
                        />
                      )}
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Insignificance Of Event
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.insignificance}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .insignificance_of_event
                        }
                        onChange={this.handleChange}
                        name='insignificance_of_event'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        onBlur={this.saveItem}
                        ref={this.insignificance_of_eventRef}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.insignificance_of_event.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Passage Of Time'
                                onClick={this.handleClick}
                                className='s-item'
                                name='insignificance_of_event'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Passage Of Time
                              </Button>
                              <Button
                                value='Routine Event'
                                className='s-item'
                                onClick={this.handleClick}
                                name='insignificance_of_event'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Routine Event
                              </Button>
                              <Button
                                value='Unrecorded'
                                className='s-item'
                                onClick={this.handleClick}
                                name='insignificance_of_event'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Unrecorded
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.insignificance_of_event.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.insignificance_of_event.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Lack Of Knowledge Of Events / Faulty Perception - Expose
                      Belief From Habit
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.expose}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability
                            .lack_knowledge_of_events_faulty_perception
                            .expose_belief_from_habit
                        }
                        onChange={this.handleChange}
                        name='expose_belief_from_habit'
                        parent='reliability'
                        subParent='lack_knowledge_of_events_faulty_perception'
                        ref={this.expose_belief_from_habitRef}
                        onBlur={this.saveItem}
                      />

                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.expose_belief_from_habit.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Routine Event'
                                onClick={this.handleClick}
                                className='s-item'
                                name='expose_belief_from_habit'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Routine Event
                              </Button>
                              <Button
                                value='Passage Of Time'
                                className='s-item'
                                onClick={this.handleClick}
                                name='expose_belief_from_habit'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                Passage Of Time
                              </Button>
                              <Button
                                value=' No Present Memory'
                                className='s-item'
                                onClick={this.handleClick}
                                name='expose_belief_from_habit'
                                parent='reliability'
                                subParent='lack_knowledge_of_events_faulty_perception'
                              >
                                No Present Memory
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.expose_belief_from_habit.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.lack_knowledge_of_events_faulty_perception.expose_belief_from_habit.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Reliability of Memory - Witnesses Forget Events Over Time,
                      And Subsequent Events Can Distort Memory
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.reliability}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .forget_events
                        }
                        onChange={this.handleChange}
                        name='forget_events'
                        parent='reliability'
                        subParent='reliability_of_memory'
                        ref={this.forget_eventsRef}
                        onBlur={this.saveItem}
                        style={{ marginTop: '15px'}}
                      />

                      {this.state.checklist.reliability.reliability_of_memory.forget_events.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Passage of Time'
                                onClick={this.handleClick}
                                className='s-item'
                                name='forget_events'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Passage of Time
                              </Button>
                              <Button
                                value='Intervening Events'
                                className='s-item'
                                onClick={this.handleClick}
                                name='forget_events'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Intervening Events
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.reliability_of_memory.forget_events.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.reliability_of_memory.forget_events.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Reliability of Memory - Memory Is Highly Flawed</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.reliabilityMem}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .flawed
                        }
                        onChange={this.handleChange}
                        name='flawed'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                        ref={this.flawedRef}
                      />

                      {this.state.checklist.reliability.reliability_of_memory.flawed.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Passage Of Time'
                                onClick={this.handleClick}
                                className='s-item'
                                name='flawed'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Passage Of Time
                              </Button>
                              <Button
                                value='Intervening Events'
                                className='s-item'
                                onClick={this.handleClick}
                                name='flawed'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Intervening Events
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.reliability_of_memory.flawed.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.reliability_of_memory.flawed.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Reliability of Memory - Experience, Social Values,
                      Prejudice And Emotions Can Affect The Way An Event Is
                      Remembered
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.reliabilityExp}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .experience
                        }
                        onChange={this.handleChange}
                        name='experience'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                        ref={this.experienceRef}
                        style={{ marginTop: '15px'}}
                      />

                      {this.state.checklist.reliability.reliability_of_memory.experience.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Experience'
                                onClick={this.handleClick}
                                className='s-item'
                                name='experience'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Experience
                              </Button>
                              <Button
                                value='Values And Prejudice'
                                className='s-item'
                                onClick={this.handleClick}
                                name='experience'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Values And Prejudice
                              </Button>
                              <Button
                                value='Values And Prejudice'
                                className='s-item'
                                onClick={this.handleClick}
                                name='experience'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Emotion
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.reliability_of_memory.experience.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.reliability_of_memory.experience.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>
                      Reliability of Memory - Establish Poor Memory-Cross On
                      Collateral Details
                    </h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.reliabilityEst}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .collateral
                        }
                        onChange={this.handleChange}
                        name='collateral'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                        ref={this.collateralRef}
                      />

                      {this.state.checklist.reliability.reliability_of_memory.collateral.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Before Event'
                                onClick={this.handleClick}
                                className='s-item'
                                name='collateral'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Before Event
                              </Button>
                              <Button
                                value='After Event'
                                className='s-item'
                                onClick={this.handleClick}
                                name='collateral'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                After Event
                              </Button>
                              <Button
                                value='During Event'
                                className='s-item'
                                onClick={this.handleClick}
                                name='collateral'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                During Event
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.reliability.reliability_of_memory.collateral.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ===
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.reliability_of_memory.collateral.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Reliability of Memory - Influence Of Hearsay</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.reliabilityInflu}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.reliability.reliability_of_memory
                            .influence
                        }
                        onChange={this.handleChange}
                        name='influence'
                        parent='reliability'
                        onBlur={this.saveItem}
                        subParent='reliability_of_memory'
                        ref={this.influenceRef}
                      />

                      {this.state.checklist.reliability.reliability_of_memory.influence.trim() ==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Innocent Collusion'
                                onClick={this.handleClick}
                                className='s-item'
                                name='influence'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Innocent Collusion
                              </Button>
                              <Button
                                value='Diserbate Collusion'
                                className='s-item'
                                onClick={this.handleClick}
                                name='influence'
                                parent='reliability'
                                subParent='reliability_of_memory'
                              >
                                Diserbate Collusion
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.reliability.reliability_of_memory.influence.trim() !==
                        '' &&
                        this.state.checklist.reliability.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.reliability.reliability_of_memory.influence.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <div className='to-center'>
                    <Col xs={12}>
                      <h2 className='check-title'>
                        Plausibility and Consistency
                      </h2>
                    </Col>
                    <Col xs={12}>
                      <p
                        name='plausibility_and_consistency'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.plausibility_and_consistency
                          .status === 'incomplete'
                          ? 'Mark Completed'
                          : 'Complete'}
                        {this.state.checklist.plausibility_and_consistency
                          .status == 'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </div>
                </Row>{' '}
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Prior Inconsistent Statements</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.prior}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .prior_inconsistent.accuse
                        }
                        onChange={this.handleChange}
                        name='accuse'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='prior_inconsistent'
                        ref={this.accuseRef}
                      />

                      {this.state.checklist.plausibility_and_consistency.prior_inconsistent.accuse.trim() ==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Prior Written Statement'
                                onClick={this.handleClick}
                                className='s-item'
                                name='accuse'
                                parent='plausibility_and_consistency'
                                subParent='prior_inconsistent'
                              >
                                Prior Written Statement
                              </Button>
                              <Button
                                value='Prior Verbal Statement'
                                className='s-item'
                                onClick={this.handleClick}
                                name='accuse'
                                parent='plausibility_and_consistency'
                                subParent='prior_inconsistent'
                              >
                                Prior Verbal Statement
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.plausibility_and_consistency.prior_inconsistent.accuse.trim() !==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.plausibility_and_consistency.prior_inconsistent.accuse.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Version Is Implausible Or Improbable</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.version}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .implausible
                        }
                        onChange={this.handleChange}
                        name='implausible'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='-1'
                        ref={this.implausibleRef}
                      />

                      {this.state.checklist.plausibility_and_consistency.implausible.trim() ==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Version Is Impossible'
                                onClick={this.handleClick}
                                className='s-item'
                                name='implausible'
                                parent='plausibility_and_consistency'
                                subParent='-1'
                              >
                                Version Is Impossible
                              </Button>
                              <Button
                                value='Version Is Improbable'
                                className='s-item'
                                onClick={this.handleClick}
                                name='implausible'
                                parent='plausibility_and_consistency'
                                subParent='-1'
                              >
                                Version Is Improbable
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.plausibility_and_consistency.implausible.trim() !==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.plausibility_and_consistency.implausible.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Contradicted By Other Evidence Or Witnesses</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.contradicted}
                    ></img>
                  </Row>

                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={
                          this.state.checklist.plausibility_and_consistency
                            .contradicted
                        }
                        onChange={this.handleChange}
                        name='contradicted'
                        parent='plausibility_and_consistency'
                        onBlur={this.saveItem}
                        subParent='-1'
                        ref={this.contradictedRef}
                      />

                      {this.state.checklist.plausibility_and_consistency.contradicted.trim() ==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <div className='suggestion'>
                            <div className='s-items'>
                              <Button
                                value='Other Physical Evidence'
                                onClick={this.handleClick}
                                className='s-item'
                                name='contradicted'
                                parent='plausibility_and_consistency'
                                subParent='-1'
                              >
                                Other Physical Evidence
                              </Button>
                              <Button
                                value='Other Witness'
                                className='s-item'
                                onClick={this.handleClick}
                                name='contradicted'
                                parent='plausibility_and_consistency'
                                subParent='-1'
                              >
                                Other Witness
                              </Button>
                            </div>
                          </div>
                        )}
                      {this.state.checklist.plausibility_and_consistency.contradicted.trim() !==
                        '' &&
                        this.state.checklist.plausibility_and_consistency
                          .status == 'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.plausibility_and_consistency.contradicted.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <div className='to-center'>
                    <Col xs={12}>
                      <h2 className='check-title'>Credibility Of Witness</h2>
                    </Col>
                    <Col xs={12}>
                      <p
                        name='credibility'
                        className='checklist-status to-center-t'
                        onClick={this.toggleStatus}
                      >
                        {this.state.checklist.credibility.status ===
                        'incomplete'
                          ? 'Mark Completed'
                          : 'Completed'}
                        {this.state.checklist.credibility.status ==
                          'complete' && (
                          <img
                            className='logo-title checklist-check'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593878903/Aleri/verified_hndcww.svg'
                          ></img>
                        )}
                      </p>
                    </Col>
                  </div>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Prior Criminal Record</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.priorCriminal}
                    ></img>
                  </Row>
                  <Col md={12}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.credibility.record}
                        onChange={this.handleChange}
                        name='record'
                        parent='credibility'
                        onBlur={this.saveItem}
                        subParent='-1'
                        ref={this.recordRef}
                      />

                      {this.state.checklist.credibility.record.trim() == '' &&
                        this.state.checklist.credibility.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Criminal Record'
                                onClick={this.handleClick}
                                className='s-item'
                                name='record'
                                parent='credibility'
                                subParent='-1'
                              >
                                Criminal Record
                              </Button>
                              <Button
                                value='Outstanding Charges'
                                className='s-item'
                                onClick={this.handleClick}
                                name='record'
                                parent='credibility'
                                subParent='-1'
                              >
                                Outstanding Charges
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.credibility.record.trim() !== '' &&
                        this.state.checklist.credibility.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.credibility.record.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='saved-input underline-t'>
                  <Row>
                    <h4>Evidence Of Misconduct Or Character</h4>
                    <img
                      className='checkHelp'
                      alt='image2'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                      onClick={this.evidence}
                    ></img>
                  </Row>
                  <Col md={12} style={{ marginBottom: '70px' }}>
                    <Form.Group controlId='s'>
                      <TextareaAutosize
                        placeholder='Enter Notes Here'
                        value={this.state.checklist.credibility.evidence}
                        onChange={this.handleChange}
                        name='evidence'
                        parent='credibility'
                        onBlur={this.saveItem}
                        subParent='-1'
                        ref={this.evidenceRef}
                      />

                      {this.state.checklist.credibility.evidence.trim() == '' &&
                        this.state.checklist.credibility.status ==
                          'incomplete' && (
                          <div className='suggestion'>
                            <div></div>
                            <div className='s-items'>
                              <Button
                                value='Evidence Of Misconduct'
                                onClick={this.handleClick}
                                className='s-item'
                                name='evidence'
                                parent='credibility'
                                subParent='-1'
                              >
                                Evidence Of Misconduct
                              </Button>
                              <Button
                                value='General Reputation Of Dishonesty'
                                className='s-item'
                                onClick={this.handleClick}
                                name='evidence'
                                parent='credibility'
                                subParent='-1'
                              >
                                General Reputation Of Dishonesty
                              </Button>
                              <Button
                                value='General Reputation Of Violence'
                                className='s-item'
                                onClick={this.handleClick}
                                name='evidence'
                                parent='credibility'
                                subParent='-1'
                              >
                                General Reputation Of Violence
                              </Button>
                            </div>
                            <div></div>
                          </div>
                        )}
                      {this.state.checklist.credibility.evidence.trim() !==
                        '' &&
                        this.state.checklist.credibility.status ==
                          'incomplete' && (
                          <CreateTopic
                            name={this.state.checklist.credibility.evidence.trim()}
                            witness={this.props.witness}
                            p_case={this.props.case}
                            className='create-topic-btn'
                            updateWitness={this.props.updateWitness}
                            topicArray={this.state.topicArray}
                            addTopicToTopicArray={this.addTopicToTopicArray}
                          />
                        )}
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Fade>
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Checklist);
