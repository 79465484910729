import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../prod';
import PDFViewer from '../Shared/PDFViewer';
import ErrorScreen from './ErrorScreen';
class FileTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      link: '',
      statement: [],
      summary: false,
      fact: false,
      topic: false,

      errorScreen: false,
    };
    this.addHighlightToRow = this.addHighlightToRow.bind(this);
    this.addHighlightsToRows = this.addHighlightsToRows.bind(this);
    this.showErrorScreen = this.showErrorScreen.bind(this);
  }

  addHighlightToRow(highlight, data) {
    let statements = this.props.witness.statements;
    let row;
    let statementId;
    for (let i = 0; i < statements.length; i ++) {
      for (let j = 0; j < statements[i].statement.length; j ++) {
        if (statements[i].statement[j]._id === data.rowId) {
          row = statements[i].statement[j];
          statementId = statements[i]._id
        }
      }
    }
    const docId = this.props.match.params.docId ? this.props.match.params.docId : this.props.docId;

    // make row with reference and evidence values
    const newRow = {
      reference: `${data.linkName} - page ${highlight.position.pageNumber}`,
      evidence: highlight.content.text ? highlight.content.text : highlight.content.image.file,
    };
    // console.log("newrow: ", newRow)

    axios // get the latest rows info;
    .post(prod() + '/api/cases/get/witness/statement', {
      case: this.props.case._id,
      witness: this.props.witness['_id'],
      statement: statementId,
    })
    .then(res => {
      statements = res.data.statements; //latest rows info

      // check to see if the row which this document is located still exists
      const rows = res.data.statements.statement;
      const index = rows.findIndex(row => row._id === data.rowId);
      if (index === -1 || !rows[index].ref.document) {
        this.setState({ errorScreen: true });
      } else {
        let index;
        for (let i = 0; i < statements.statement.length; i ++) {
          if (statements.statement[i]._id === data.rowId) {
            index = i; //get the index from the latest rows info
          }
        }
  
        axios
          .post(prod() + '/api/documents/get', { document: docId })
          .then((res) => {
            const docInfo = res.data;
    
            axios //route that add one row below the row that has the document
              .post(prod() + '/api/cases/add/witness/statement/row', {
                witness: this.props.witness._id,
                case: this.props.case._id,
                statement: statementId,
                referenceLink: docInfo,
                row: newRow,
                rowIndex: index + 1,
              })
              .then((use) => {
                // console.log('Case after updating rows: ', use);
                for (let a of use.data.case.witnesses) {
                  if (a._id == this.props.witness['_id']) {
                    this.props.updateWitness(use.data.case, a);
                  }
                }
                const items = [];
    
                axios
                  .post(prod() + '/api/cases/get/witness/statement', {
                    case: this.props.case._id,
                    witness: this.props.witness['_id'],
                    statement: statementId,
                  })
                  .then((use) => {
                    // console.log('UPDATED ROWS', use);
                    let rowId;
                    if (use.data.statements.statement[index + 1] === undefined) {
                      this.setState({ redirect: true });
                    } else {
                      rowId = use.data.statements.statement[index + 1]._id;
                    }
  
                    //get the rowId of the newly created row
                    axios // save the highlight with the newly created row's id and pin
                      .post(
                        prod() + '/api/file/highlights/documents/addHighlights',
                        {
                          rowIds: [rowId],
                          highlights: [highlight],
                          link: data.linkLocation,
                        }
                      )
                      .then((res) => {});
                      });
                  })
                  .catch((e) => {
                    console.log('ERROR', e);
                  });
              });
      }
      
    });
  }

  addHighlightsToRows(highlights, data) {
    console.log("highlights: ", highlights)
    console.log("data: ", data);
    const docId = this.props.match.params.docId ? this.props.match.params.docId : this.props.docId;

    const newRows = [];
    for (const highlight of highlights) {
      newRows.push({
        reference: `${data.linkName} - page ${highlight.position.pageNumber}`,
        evidence: highlight.content.text ? highlight.content.text : highlight.content.image.file,
      });
    }
    const rowsLength = newRows.length;

    let statementId;
    let statements = this.props.witness.statements;
    for (let i = 0; i < statements.length; i ++) {
      for (let j = 0; j < statements[i].statement.length; j ++) {
        if (statements[i].statement[j]._id === data.rowId) {
          statementId = statements[i]._id
        }
      }
    }
    axios // get the latest rows info;
    .post(prod() + '/api/cases/get/witness/statement', {
      case: this.props.case._id,
      witness: this.props.witness['_id'],
      statement: statementId,
    })
    .then(res => {
      statements = res.data.statements; //latest rows info
      // check to see if the row which this document is located still exists
      const rows = res.data.statements.statement;
      const index = rows.findIndex(row => row._id === data.rowId);
      if (index === -1 || !rows[index].ref.document) {
        // if the row wasn't found, then redirect it to home
        this.setState({ errorScreen: true });
      } else {

        let index;
        for (let i = 0; i < statements.statement.length; i ++) {
          if (statements.statement[i]._id === data.rowId) {
            index = i; //get the index from the latest rows info
          }
        }
  
        if (docId && data.linkName && statementId) {
          axios
            .post(prod() + '/api/documents/get', { document: docId })
            .then((res) => {
              const docInfo = res.data;
              // console.log("doc info: ", res.data)
      
              axios //route that adds multiple rows at the same time
                .post(prod() + '/api/cases/add/witness/statement/rows', {
                  witness: this.props.witness._id,
                  case: this.props.case._id,
                  statement: statementId,
                  referenceLink: docInfo,
                  rows: newRows,
                  rowIndex: index + 1,
                  rowsLength: rowsLength,
                })
                .then((use) => {
                  // console.log("Case after updating rows: ", use);
                  for (let a of use.data.case.witnesses) {
                    if (a._id == this.props.witness['_id']) {
                      this.props.updateWitness(use.data.case, a);
                    }
                  }
                  axios
                    .post(prod() + '/api/cases/get/witness/statement', {
                      case: this.props.case._id,
                      witness: this.props.witness['_id'],
                      statement: statementId,
                    })
                    .then((use) => {
                      // console.log('UPDATED ROWS', use);
                      const statement = use.data.statements.statement;
                      const rowIds = [];
                      for (let i = index + 1; i <= index + rowsLength; i++) {
                        rowIds.push(statement[i]._id);
                      }
                      //get the rowId of the newly created row
                      axios
                        .post(
                          prod() + '/api/file/highlights/documents/addHighlights',
                          {
                            rowIds: rowIds.reverse(),
                            highlights,
                            link: data.linkLocation,
                          }
                        )
                        .then((res) => {});
                        });
                    })
                    .catch((e) => {
                      console.log('ERROR', e);
                    });
                });
        }
      }
    })

  }
  
  componentWillReceiveProps(newProps) {
    // console.log("componentwillreceiveprops new props: ", newProps)

    if (
      newProps.statement !== this.props.statement &&
      newProps.statement != ''
    ) {
      // console.log("new props statement changed")
      window.scrollTo(0, 0);
      const refId = newProps.match.params.refId
        ? newProps.match.params.refId
        : newProps.refId;
      const url = prod() + '/api/file/highlights/get-link';
      axios.post(url, { refId }).then((res) => {
        // console.log('LINK: ', res.data);
        this.setState({ link: res.data });
      });

      if (
        newProps.case == undefined ||
        newProps.case == null ||
        Object.keys(newProps.case).length === 0
      ) {
        this.setState({ redirect: true });
      } else {
        if (newProps.second != true) {
          newProps.getTab({
            case: newProps.case,
            witness: newProps.witness,
            file: newProps.match.params.filename
              ? newProps.match.params.filename
              : newProps.filename,
            topic: newProps.match.params.refId
              ? newProps.match.params.refId
              : newProps.refId,
            path: newProps.match.params.refId
              ? newProps.match.url
              : newProps.secondPath,
            name: 'File',
            active: true,
            docId: newProps.match.params.docId,
          });
        }
      }
    }
    
  }

  componentDidMount() {
    console.log('componentdidmount this.props', this.props);

    window.scrollTo(0, 0);
    const refId = this.props.match.params.refId
      ? this.props.match.params.refId
      : this.props.refId;
    const url = prod() + '/api/file/highlights/get-link';
    axios.post(url, { refId }).then((res) => {
      // console.log('LINK: ', res.data);
      this.setState({ link: res.data });
    });

    if (
      this.props.case == undefined ||
      this.props.case == null ||
      Object.keys(this.props.case).length === 0
    ) {
      this.setState({ redirect: true });
    } else {
      // see if the row with the document still exists (see if it was removed)
      let statementId;
      let factId;
      let topicId;
      let statements = this.props.witness.statements;
      let facts = this.props.witness.facts;
      let topics = this.props.witness.topics;
      for (let i = 0; i < statements.length; i ++) {
        for (let j = 0; j < statements[i].statement.length; j ++) {
          if (statements[i].statement[j]._id === refId) {
            statementId = statements[i]._id
          }
        }
      }
      for (let i = 0; i < facts.length; i ++) {
        for (let j = 0; j < facts[i].fact.length; j ++) {
          if (facts[i].fact[j]._id === refId) {
            factId = facts[i]._id
          }
        }
      }
      for (let i = 0; i < topics.length; i ++) {
        for (let j = 0; j < topics[i].topic.length; j ++) {
          if (topics[i].topic[j]._id === refId) {
            topicId = topics[i]._id
          }
        }
      }


      if (statementId) { //see if the statementId exists to see if the document was from summary page or topic page or fact page
        this.setState({ summary: true });

        axios // get the latest rows info;
          .post(prod() + '/api/cases/get/witness/statement', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            statement: statementId,
          })
          .then(res => {
            const rows = res.data.statements.statement;
            const index = rows.findIndex(row => row._id === refId);
  
            if (index === -1) {
              this.setState({ errorScreen: true });
  
            } else {  
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  file: this.props.match.params.filename
                    ? this.props.match.params.filename
                    : this.props.filename,
                  topic: this.props.match.params.refId
                    ? this.props.match.params.refId
                    : this.props.refId,
                  path: this.props.match.params.refId
                    ? this.props.match.url
                    : this.props.path,
                  name: 'File',
                  active: true,
                  docId: this.props.match.params.docId
                    ? this.props.match.params.docId
                    : this.props.docId,
                  index: this.props.match.params.index,
                });
              }
            }
          })
      } else if (factId) {

        this.setState({ fact: true });
        axios // get the latest rows info;
          .post(prod() + '/api/cases/get/witness/fact', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            fact: factId,
          })
          .then(res => {
            const rows = res.data.facts.fact;
            const index = rows.findIndex(row => row._id === refId);
  
            if (index === -1) {
              this.setState({ errorScreen: true });
  
            } else {  
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  file: this.props.match.params.filename
                    ? this.props.match.params.filename
                    : this.props.filename,
                  topic: this.props.match.params.refId
                    ? this.props.match.params.refId
                    : this.props.refId,
                  path: this.props.match.params.refId
                    ? this.props.match.url
                    : this.props.path,
                  name: 'File',
                  active: true,
                  docId: this.props.match.params.docId
                    ? this.props.match.params.docId
                    : this.props.docId,
                  index: this.props.match.params.index,
                });
              }
            }
          })
      } else if (topicId) {
        this.setState({ topic: true });
        axios // get the latest rows info;
          .post(prod() + '/api/cases/get/witness/topic', {
            case: this.props.case._id,
            witness: this.props.witness['_id'],
            topic: topicId,
          })
          .then(res => {
            const rows = res.data.topics.topic;
            const index = rows.findIndex(row => row._id === refId);
  
            if (index === -1) {
              this.setState({ errorScreen: true });
  
            } else {  
              if (this.props.second != true) {
                this.props.getTab({
                  case: this.props.case,
                  witness: this.props.witness,
                  file: this.props.match.params.filename
                    ? this.props.match.params.filename
                    : this.props.filename,
                  topic: this.props.match.params.refId
                    ? this.props.match.params.refId
                    : this.props.refId,
                  path: this.props.match.params.refId
                    ? this.props.match.url
                    : this.props.path,
                  name: 'File',
                  active: true,
                  docId: this.props.match.params.docId
                    ? this.props.match.params.docId
                    : this.props.docId,
                  index: this.props.match.params.index,
                });
              }
            }
          })
          .catch(e => console.log(e));
      } else {
        if (this.props.second != true) {
          this.props.getTab({
            case: this.props.case,
            witness: this.props.witness,
            file: this.props.match.params.filename
              ? this.props.match.params.filename
              : this.props.filename,
            topic: this.props.match.params.refId
              ? this.props.match.params.refId
              : this.props.refId,
            path: this.props.match.params.refId
              ? this.props.match.url
              : this.props.path,
            name: 'File',
            active: true,
            docId: this.props.match.params.docId
              ? this.props.match.params.docId
              : this.props.docId,
            index: this.props.match.params.index,
          });
        }
      }

    }

  }
  
  showErrorScreen() {
    this.setState({ errorScreen: true });
  }

  render() {
    console.log("tab info: " ,{
      case: this.props.case,
      witness: this.props.witness,
      file: this.props.match.params.filename
        ? this.props.match.params.filename
        : this.props.filename,
      topic: this.props.match.params.refId
        ? this.props.match.params.refId
        : this.props.refId,
      path: this.props.path,
      name: 'File',
      active: true,
    });
    // console.log('This.State.', this.state);
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }
    const firm2 = this.props.top ? 'wit-nav-expanded' : '';
    // console.log(firm2);
    // console.log('URL IN FILETAB: ', this.state.link);
    return (
      <div style={{ height: '100%', width: '100%' }}>
        {this.state.errorScreen ? 
        (
          <ErrorScreen message="Document doesn't exist. Please close the tab."/>
        ): (
          <PDFViewer
            refId={
              this.props.match.params.refId
                ? this.props.match.params.refId
                : this.props.refId
            }
            link={this.state.link}
            statement={this.props.statement}
            summary={this.state.summary}
            addHighlightToRow={this.addHighlightToRow}
            addHighlightsToRows={this.addHighlightsToRows}
            name={this.props.match.params.filename ? this.props.match.params.filename: this.props.filename}
            fact={this.state.fact}
            topic={this.state.topic}
            showErrorScreen={this.showErrorScreen}
          />
        )}
      </div>
    );
  }
}
export default withRouter(FileTab);
