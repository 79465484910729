import React from "react";

import { Button } from "react-bootstrap";

// pdf
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { saveAs } from "file-saver";
import { Document as DocxDocument, Packer } from "docx";

import { getCrossExaminationChecklist } from "../../../Shared/DocxExport";

const Pdf = (props) => {
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  const output = <div></div>;

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingTop: 15,
      paddingLeft: 15,
      paddingRight: 10,
      paddingBottom: 10,
      lineHeight: 1,
      flexDirection: "column",
    },
    section: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
    },
    subSection: {
      display: "flex",
      flexDirection: "column",
      flexBasis: "100%",
      flex: "1 0 50%",
    },
    title: {
      letterSpacing: 1,
      fontSize: 8,
      textAlign: "left",
      textTransform: "uppercase",
    },
    date: {
      letterSpacing: 1,
      fontSize: 5,
      textAlign: "right",
    },
    image: {
      width: 50,
      height: 66,
      marginLeft: "auto",
      marginRight: "auto",
    },
    logo: {
      letterSpacing: 2,
      fontSize: 10,
      textAlign: "right",
      textTransform: "uppercase",
      marginTop: 0,
      paddingTop: 0,
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      marginTop: 3,
    },
    tableRow: {
      margin: "auto",
      flexDirection: "row",
    },
    tableColName: {
      width: "30%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    tableColValue: {
      width: "70%",
      borderStyle: "solid",
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 0,
      textOverflow: "ellipsis",
    },
    tableCell: {
      margin: "auto",
      marginTop: 0,
      fontSize: 11,
      textAlign: "left",
      fontFamily: "Helvetica",
      fontWeight: 300,
      width: "100%",
      textOverflow: "ellipsis",
      padding: 5,
    },
    group: {
      width: "100%",
      margin: 10,
    },
    groupName: {
      fontFamily: "Helvetica",
      fontSize: 14,
      textAlign: "center",
    },
    groupStatus: {
      fontFamily: "Helvetica",
      fontSize: 14,
      textAlign: "center",
    },
  });

  const pdf = (
    <PDFDownloadLink
      className="bob-link"
      target="_blank"
      document={
        <Document>
          <Page size="A4" style={styles.page}>
            <View style={styles.section}>
              <View style={styles.subSection}>
                <Text style={styles.title}>Cross Examination Checklist</Text>
              </View>
              <View style={styles.subSection}>
                <Text style={styles.logo}>&copy; ALERI Inc. 2020</Text>
                <Text style={styles.date}>{today}</Text>
              </View>
            </View>
            <View style={styles.section}>
              <View style={styles.group}>
                <Text style={styles.groupName}>Reliability Of Witness</Text>
                <Text style={styles.groupStatus}>
                  {props.checklist.reliability.status}
                </Text>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Bias and Motive - Financial Interest
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.bias.financial}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Bias and Motive - Friendship, Relative
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.bias.relationship}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Bias and Motive - Hostility
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.bias.hostility}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Impairment Alcohol Or Drugs
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.impairment_alcohol_or_drugs}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Pyhsical Deficiency (Eyesight, hearing etc)
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.physical_deficiency}
                    </Text>
                  </View>
                </View>

                {/*  */}

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Focus Of
                      Attention Is Not On Event
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception.focus
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Witness
                      Preoccupied
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .witness_preoccupied
                      }
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Measures
                      Of Quantity, And Distance Are Often Unreliable
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .m_quantity_and_distance_not_reliable
                      }
                    </Text>
                  </View>
                </View> */}

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Desire
                      To Find Meaning
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .desire_to_find_meaning
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To See
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .establish_inability_to_see
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Establish Inability To Hear
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .establish_inability_to_hear
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Impact
                      Of Sudden Events
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .impact_of_sudden_events
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception -
                      Insignificance Of Event
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .insignificance_of_event
                      }
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Ability
                      To Observe
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .ability_to_observe
                      }
                    </Text>
                  </View>
                </View> */}

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Lack Of Knowledge Of Events / Faulty Perception - Expose
                      Belief From Habit
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability
                          .lack_knowledge_of_events_faulty_perception
                          .expose_belief_from_habit
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Reliability of Memory - Witnesses Forget Events Over Time,
                      And Subsequent Events Can Distort Memory
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability.reliability_of_memory
                          .forget_events
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Reliability of Memory - Memory Is Highly Flawed
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.reliability.reliability_of_memory.flawed}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Reliability of Memory - Experience, Social Values,
                      Prejudice And Emotions Can Affect The Way An Event Is
                      Remembered
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability.reliability_of_memory
                          .experience
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      {" "}
                      Reliability of Memory - Establish Poor Memory-Cross On
                      Collateral Details
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability.reliability_of_memory
                          .collateral
                      }
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Reliability of Memory - Influence Of Hearsay
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.reliability.reliability_of_memory
                          .influence
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.group}>
                <Text style={styles.groupName}>
                  Plausibility and Consistency
                </Text>
                <Text style={styles.groupStatus}>
                  {props.checklist.plausibility_and_consistency.status}
                </Text>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Prior Inconsistent Statements - Accuse Of False Statement
                      At Trial
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.plausibility_and_consistency
                          .prior_inconsistent.accuse
                      }
                    </Text>
                  </View>
                </View>

                {/* <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Prior Inconsistent Statements - Show The Circumstances Of
                      Prior Statement- Why More Reliable
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.plausibility_and_consistency
                          .prior_inconsistent.reliable
                      }
                    </Text>
                  </View>
                </View> */}
                {/* <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Prior Inconsistent Statements - Confront With Prior
                      Statement
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.plausibility_and_consistency
                          .prior_inconsistent.confront
                      }
                    </Text>
                  </View>
                </View> */}
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Version Is Implausible Or Improbable
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.plausibility_and_consistency.implausible}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Contradicted By Other Evidence Or Witnesses
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {
                        props.checklist.plausibility_and_consistency
                          .contradicted
                      }
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.group}>
                <Text style={styles.groupName}>Credibility Of Witness</Text>
                <Text style={styles.groupStatus}>
                  {props.checklist.credibility.status}
                </Text>
              </View>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>Prior Criminal Record</Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.credibility.record}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableColName}>
                    <Text style={styles.tableCell}>
                      Evidence Of Misconduct Or Character
                    </Text>
                  </View>
                  <View style={styles.tableColValue}>
                    <Text style={styles.tableCell}>
                      {props.checklist.credibility.evidence}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      }
      fileName="checklist.pdf"
    >
      {({ blob, url, loading, error }) =>
        loading ? (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Loading PDF To Export
          </Button>
        ) : (
          <Button className="btn action  btn-b btn btn-primary btn-block">
            Export To PDF
          </Button>
        )
      }
    </PDFDownloadLink>
  );

  return <>{pdf}</>;
};

const Word = (props) => {
  const generate = () => {
    const page = getCrossExaminationChecklist(props.checklist);

    const doc = new DocxDocument({
      sections: [page],
    })
    // Cross Examination Checklist
    getCrossExaminationChecklist(props.checklist);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `checklist.docx`);
    });
  };

  return (
    <Button
      className="btn action  btn-b btn btn-primary btn-block"
      onClick={generate}
    >
      Export To Word(docx)
    </Button>
  );
};

const ChecklistExport = (props) => {
  return (
    <>
      <Pdf {...props} />
      <Word {...props} />
    </>
  );
};

export default ChecklistExport;
