import {
  Document as DocxDocument,
  HeadingLevel,
  Header,
  SectionType,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  Media,
  Packer,
  Paragraph,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  AlignmentType,
  Table,
  TableCell,
  TableRow,
  WidthType,
  ImageRun,
} from 'docx';

import moment from 'moment';

export const createHeading = (text) =>
  new Paragraph({
    text: text,
    heading: HeadingLevel.HEADING_1,
    thematicBreak: true,
    alignment: AlignmentType.CENTER,
  });

export const createSubHeading = (text) =>
  new Paragraph({
    text: text,
    heading: HeadingLevel.HEADING_2,
  });

const oneValueRow = (heading, value) => 
  new TableRow({
    children: [
      new TableCell({
        size: 30,
        type: WidthType.PERCENTAGE,
        children: [new Paragraph(`${heading}`)],
      }),
      new TableCell({
        size: 70,
        type: WidthType.PERCENTAGE,
        children: [new Paragraph(`${value}`)],
      }),
    ],
  });

const getImageBlob = async (link) => {
  const response = await fetch(link)
  const blob = response.blob();
  return blob;
}


const oneImageRow = (heading, value) => {
  if (value) {
    const blob = getImageBlob(value);
    
    return new TableRow({
      children: [
        new TableCell({
          size: 30,
          type: WidthType.PERCENTAGE,
          children: [new Paragraph(`${heading}`)],
        }),
        new TableCell({
          size: 70,
          type: WidthType.PERCENTAGE,
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: blob,
                  transformation: {
                    width: 180,
                    height: 120
                  }
                })
              ]
            })
          ],
        }),
      ],
    });
  } else {
    return new TableRow({
      children: [
        new TableCell({
          size: 30,
          type: WidthType.PERCENTAGE,
          children: [new Paragraph(`${heading}`)],
        }),
        new TableCell({
          size: 70,
          type: WidthType.PERCENTAGE,
          children: [new Paragraph('')],
        }),
      ],
    });
  }

}


export const getTopics = (topics) => {
  const docArr = [];
  topics.forEach((item) => {
    const topicRows = item.topic.map(
      (t) =>
        new TableRow({
          children: [
            new TableCell({
              size: 33,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${t?.ref?.value}`)],
            }),
            new TableCell({
              size: 33,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${t?.disc?.value}`)],
            }),
            // new TableCell({
            //   size: 33,
            //   type: WidthType.PERCENTAGE,
            //   children: [new Paragraph(`${t?.notes?.value || ''}`)],
            // }),
          ],
        })
    );

    const topicContainer = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading(' Cross Examination Questions Topic'),
        createSubHeading(`${item.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 33,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('References')],
                }),
                new TableCell({
                  size: 33,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Questions')],
                }),
                // new TableCell({
                //   size: 33,
                //   type: WidthType.PERCENTAGE,
                //   children: [new Paragraph('Notes')],
                // }),
              ],
              tableHeader: true,
            }),
            ...topicRows,
          ],
        }),
      ],
    });
    docArr.push(topicContainer);
  });
  return docArr;
};

export const getFacts = (facts) => {
  const docArr = [];

  facts.forEach((item) => {
    const factRows = item.fact.map(
      (f) =>
        new TableRow({
          children: [
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.reference}`)],
            }),
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.evidence}`)],
            }),
          ],
        })
    );

    const factContainer = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Facts By Topic'),
        createSubHeading(`${item.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('References')],
                }),
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Evidence')],
                }),
              ],
              tableHeader: true,
            }),
            ...factRows,
          ],
        }),
      ],
    });
    docArr.push(factContainer);
  });
  return docArr;
};
export const getViewAllFacts = (facts) => {
  const docArr = [];

  facts.forEach((item) => {
    const factRows = item.fact.map(
      (f) =>
        new TableRow({
          children: [
            new TableCell({
              size: 25,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.reference}`)],
            }),
            new TableCell({
              size: 25,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.witnessName}`)],
            }),
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.evidence}`)],
            }),
          ],
        })
    );

    const factContainer = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Facts By Topic'),
        createSubHeading(`${item.tag}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 25,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Reference')],
                }),
                new TableCell({
                  size: 25,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Witness')],
                }),
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Evidence')],
                }),
              ],
              tableHeader: true,
            }),
            ...factRows,
          ],
        }),
      ],
    });
    docArr.push(factContainer);
  });
  return docArr;
};

export const getTimelines = (timelines) => {
  const docArr = [];

  timelines.forEach((item) => {
    const timelineRows = item.timeline.map(
      (t) =>
        new TableRow({
          children: [
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${moment(t.date, moment.ISO_8601).format('MMMM Do YYYY, h:mm a')}`)],
            }),
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${t.name}`)],
            }),
          ],
        })
    );

    const container = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Timeline By Witness'),
        createSubHeading(`${item.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Date')],
                }),
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Event')],
                }),
              ],
              tableHeader: true,
            }),
            ...timelineRows,
          ],
        }),
      ],
    });
    docArr.push(container);
  });
  return docArr;
};

export const getAllTimelines = (timelines) => {
  const docArr = [];
  timelines.forEach((item) => {
    const timelineRows = item.timeline.map(
      (t) =>
        new TableRow({
          children: [
            new TableCell({
              size: 25,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${moment(t.date, moment.ISO_8601).format('MMMM Do YYYY, h:mm a')}`)],
            }),
            new TableCell({
              size: 20,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${t.witnessName}`)],
            }),
            new TableCell({
              size: 45,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${t.name}`)],
            }),
          ],
        })
    );

    const container = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Timeline for All Witnesses'),
        // createSubHeading(`${item.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 25,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Date')],
                }),
                new TableCell({
                  size: 20,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Witness')],
                }),
                new TableCell({
                  size: 45,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Event')],
                }),
              ],
              tableHeader: true,
            }),
            ...timelineRows,
          ],
        }),
      ],
    });
    docArr.push(container);
  });
  return docArr;
};

export const getTags = (facts) => {
  const docArr = [];
  facts.forEach((item) => {
    const timelineRows = item.fact.fact.map(
      (f) =>
        new TableRow({
          children: [
            new TableCell({
              size: 25,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.reference}`)],
            }),
            new TableCell({
              size: 25,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${item.witness.name}`)],
            }),
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${f.evidence}`)],
            }),
          ],
        })
    );

    const container = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading(`Fact: ${item.fact.name}`),
        createSubHeading(`Witness: ${item.witness.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 25,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Reference')],
                }),
                new TableCell({
                  size: 25,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Witness')],
                }),
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Evidence')],
                }),
              ],
              tableHeader: true,
            }),
            ...timelineRows,
          ],
        }),
      ],
    });
    docArr.push(container);
  });
  return docArr;
};

export const getStatements = (statements) => {
  const docArr = [];
  statements.forEach((item) => {
    const statementRows = item.statement.map(
      (s) =>
        new TableRow({
          children: [
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${s.reference}`)],
            }),
            new TableCell({
              size: 50,
              type: WidthType.PERCENTAGE,
              children: [new Paragraph(`${s.evidence}`)],
            }),
          ],
        })
    );

    const statementContainer = ({
      headers: {
        default: new Header({
          children: [
            new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
            new Paragraph(`${new Date().toDateString()}`),
          ],
        }),
      },
      children: [
        createHeading('Statement Summaries'),
        createSubHeading(`${item.name}`),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          rows: [
            new TableRow({
              children: [
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('References')],
                }),
                new TableCell({
                  size: 50,
                  type: WidthType.PERCENTAGE,
                  children: [new Paragraph('Evidence')],
                }),
              ],
              tableHeader: true,
            }),
            ...statementRows,
          ],
        }),
      ],
    });
    docArr.push(statementContainer);
  });
  return docArr;
};

export const getPrepChart = (prepChart) => {
  const prepRows = [];
  prepChart.forEach((p) => {
    // Check which one is longer and use that length to loop
    const loopLength =
      p.statement.length > p.other_statement.length
        ? p.statement.length
        : p.other_statement.length;

    const statementCells = [];
    for (let s_index = 1; s_index < p.statement.length; s_index++) {
      statementCells.push(
        new TableCell({
          size: 25,
          type: WidthType.PERCENTAGE,
          children: [new Paragraph(`${p.statement[s_index].value}`)],
        })
      );
    }

    const otherStatementCells = [];
    for (let o_index = 1; o_index < p.other_statement.length; o_index++) {
      otherStatementCells.push(
        new TableCell({
          size: 25,
          type: WidthType.PERCENTAGE,
          children: [new Paragraph(`${p.other_statement[o_index].value}`)],
        })
      );
    }

    const EmptyTableCell = new TableCell({
      size: 25,
      type: WidthType.PERCENTAGE,
      children: [new Paragraph(``)],
    });

    const rows = [];
    for (let i = 0; i < loopLength - 1; i++) {
      rows.push(
        new TableRow({
          children: [
            statementCells[i] || EmptyTableCell,
            otherStatementCells[i] || EmptyTableCell,
          ],
        })
      );
    }

    prepRows.push(
      new TableRow({
        children: [
          new TableCell({
            size: 25,
            type: WidthType.PERCENTAGE,
            children: [new Paragraph(`${p.fact.value}`)],
            rowSpan: loopLength,
          }),
          new TableCell({
            size: 25,
            type: WidthType.PERCENTAGE,
            children: [new Paragraph(`${p.statement[0].value}`)],
          }),
          new TableCell({
            size: 25,
            type: WidthType.PERCENTAGE,
            children: [new Paragraph(`${p.trial_evidence.value}`)],
            rowSpan: loopLength,
          }),
          new TableCell({
            size: 25,
            type: WidthType.PERCENTAGE,
            children: [new Paragraph(`${p.other_statement[0].value}`)],
          }),
        ],
      }),
      ...rows
    );
  });
  const container = ({
    headers: {
      default: new Header({
        children: [
          new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
          new Paragraph(`${new Date().toDateString()}`),
        ],
      }),
    },
    children: [
      createHeading('Cross Examination Preparation Chart'),
      new Table({
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          new TableRow({
            children: [
              new TableCell({
                size: 25,
                type: WidthType.PERCENTAGE,
                children: [new Paragraph('Fact')],
              }),
              new TableCell({
                size: 25,
                type: WidthType.PERCENTAGE,
                children: [new Paragraph('Statements')],
              }),
              new TableCell({
                size: 25,
                type: WidthType.PERCENTAGE,
                children: [new Paragraph('Trial Evidence')],
              }),
              new TableCell({
                size: 25,
                type: WidthType.PERCENTAGE,
                children: [new Paragraph('Other Statements')],
              }),
            ],
            tableHeader: true,
          }),
          ...prepRows,
        ],
      }),
    ],
  });
  return container;
};

export const getCrossExaminationChecklist = (checklist) => {
  return ({
    headers: {
      default: new Header({
        children: [
          new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
          new Paragraph(`${new Date().toDateString()}`),
        ],
      }),
    },
    children: [
      createHeading('Cross Examination Checklist'),
      new Table({
        columnWidths: [50, 50],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          oneValueRow('Reliability Of Witness', checklist.reliability.status),
          oneValueRow(
            'Bias and Motive - Financial Interest',
            checklist.reliability.bias.financial
          ),
          oneValueRow(
            'Bias and Motive - Friendship, Relative',
            checklist.reliability.bias.relationship
          ),
          oneValueRow(
            'Bias and Motive - Hostility',
            checklist.reliability.bias.hostility
          ),
          oneValueRow(
            'Impairment Alcohol Or Drugs',
            checklist.reliability.impairment_alcohol_or_drugs
          ),
          oneValueRow(
            'Pyhsical Deficiency (Eyesight, hearing etc)',
            checklist.reliability.physical_deficiency
          ),
          oneValueRow(
            'Lack Of Knowledge Of Events / Faulty Perception - Focus Of Attention Is Not On Event',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .focus
          ),
          oneValueRow(
            ' Lack Of Knowledge Of Events / Faulty Perception - Witness Preoccupied',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .witness_preoccupied
          ),
          // oneValueRow(
          //   "Lack Of Knowledge Of Events / Faulty Perception - Measures Of Quantity, And Distance Are Often Unreliable",
          //   checklist.reliability.lack_knowledge_of_events_faulty_perception
          //     .m_quantity_and_distance_not_reliable
          // ),
          oneValueRow(
            '   Lack Of Knowledge Of Events / Faulty Perception - Desire To Find Meaning',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .desire_to_find_meaning
          ),
          oneValueRow(
            ' Lack Of Knowledge Of Events / Faulty Perception - Establish Inability To See',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .establish_inability_to_see
          ),
          oneValueRow(
            'Lack Of Knowledge Of Events / Faulty Perception - Establish Inability To Hear',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .establish_inability_to_hear
          ),
          oneValueRow(
            'Lack Of Knowledge Of Events / Faulty Perception - Impact Of Sudden Events',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .impact_of_sudden_events
          ),
          oneValueRow(
            ' Lack Of Knowledge Of Events / Faulty Perception - Insignificance Of Event',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .insignificance_of_event
          ),
          // oneValueRow(
          //   "Lack Of Knowledge Of Events / Faulty Perception - Ability To Observe",
          //   checklist.reliability.lack_knowledge_of_events_faulty_perception
          //     .ability_to_observe
          // ),
          oneValueRow(
            'Lack Of Knowledge Of Events / Faulty Perception - Expose Belief From Habit',
            checklist.reliability.lack_knowledge_of_events_faulty_perception
              .expose_belief_from_habit
          ),
          oneValueRow(
            'Reliability of Memory - Witnesses Forget Events Over Time, And Subsequent Events Can Distort Memory',
            checklist.reliability.reliability_of_memory.forget_events
          ),
          oneValueRow(
            'Reliability of Memory - Memory Is Highly Flawed',
            checklist.reliability.reliability_of_memory.flawed
          ),
          oneValueRow(
            'Reliability of Memory - Experience, Social Values, Prejudice And Emotions Can Affect The Way An Event Is Remembered',
            checklist.reliability.reliability_of_memory.experience
          ),
          oneValueRow(
            'Reliability of Memory - Establish Poor Memory-Cross On Collateral Details',
            checklist.reliability.reliability_of_memory.collateral
          ),
          oneValueRow(
            'Reliability of Memory - Influence Of Hearsay',
            checklist.reliability.reliability_of_memory.influence
          ),
          oneValueRow(
            'Plausibility and Consistency',
            checklist.plausibility_and_consistency.status
          ),
          oneValueRow(
            'Prior Inconsistent Statements - Accuse Of False Statement At Trial',
            checklist.plausibility_and_consistency.prior_inconsistent.accuse
          ),
          // oneValueRow(
          //   "Prior Inconsistent Statements - Show The Circumstances Of Prior Statement- Why More Reliable",
          //   checklist.plausibility_and_consistency.prior_inconsistent.reliable
          // ),
          // oneValueRow(
          //   "Prior Inconsistent Statements - Confront With Prior Statement",
          //   checklist.plausibility_and_consistency.prior_inconsistent.confront
          // ),
          oneValueRow(
            'Version Is Implausible Or Improbable',
            checklist.plausibility_and_consistency.implausible
          ),
          oneValueRow(
            'Contradicted By Other Evidence Or Witnesses',
            checklist.plausibility_and_consistency.contradicted
          ),
          oneValueRow('Credibility Of Witness', checklist.credibility.status),
          oneValueRow('Prior Criminal Record', checklist.credibility.record),
          oneValueRow(
            'Evidence Of Misconduct Or Character',
            checklist.credibility.evidence
          ),
        ],
      }),
    ],
  });
};

// Also focus
export const getCrossExamination = (focus) => {
  return ({
    headers: {
      default: new Header({
        children: [
          new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
          new Paragraph(`${new Date().toDateString()}`),
        ],
      }),
    },
    children: [
      createHeading('Focus Of Cross Examination'),
      new Table({
        columnWidths: [50, 50],
        width: {
          size: 100,
          type: WidthType.PERCENTAGE,
        },
        rows: [
          oneValueRow('Witness Name', focus.name.value),
          oneImageRow('Witness Photo', focus.photo.file),
          oneValueRow('Relationship To Case', focus.relationship.value),
          oneValueRow(
            "How They Advance Opponent's Case",
            focus.opposition_adv.value
          ),
          oneValueRow('Bias / Motivation', focus.bias.value),
          oneValueRow('Strengths', focus.strength.value),
          oneValueRow('Weaknesses', focus.weaknesses.value),
          oneValueRow('Facts To Bring Out', focus.fact.value),
          oneValueRow('Goals / Focus Of \nExamination', focus.goals.value),
          oneValueRow('Key Words', focus.keywords.value),
          oneValueRow('Objections', focus.objections.value),
          oneValueRow('Opening Question', focus.open.value),
          oneValueRow('Closing Question', focus.closing.value),
          oneValueRow(
            'Cross-Examination \nTechniques',
            focus.examination_tech.value
          ),
          oneValueRow('Documents', focus.documents.value),
          oneValueRow('Topics', focus.topics.value),
        ],
      }),
    ],
  });
};

export const getTranscript = (transcript, filename, isTranscriptString) => {
  const docArr = [];

  const rows = [];
  if (isTranscriptString) {
    rows.push(
      new Paragraph({
        text: transcript[0].sentence,
        size: 14,
      }),
    )
  } else {
    for (let i = 0; i < transcript.length - 1; i++) {
      rows.push(
        new Paragraph({
          text: transcript[i].timestamp,
          size: 14,
        }),
        new Paragraph({
          text: `Speaker ${transcript[i].speaker}`,
          size: 14,
        }),
        new Paragraph({
          text: transcript[i].sentence,
          size: 14,
        }),
      );
    }
  }
  const container = ({
    headers: {
      default: new Header({
        children: [
          new Paragraph(`Aleri Inc. ${new Date().getFullYear()}`),
          new Paragraph(`${new Date().toDateString()}`),
        ],
      }),
    },
    children: [
      createHeading('Transcript'),
      createSubHeading(`${filename}`),
      ...rows,
    ],
  });
  docArr.push(container);
  return docArr;
};
