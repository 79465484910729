import React from 'react';
import { Route, Switch } from 'react-router-dom';
//custom components
import Login from './Auth/Login';

import Registration from './Auth/Registration';
import RegisterUser from './Auth/FirmRegistrationSteps/RegisterUser';

import NotFound from './NotFound';

import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/RestPassword';

import Chrome from '../Chrome';

const Landing = (props) => {
  window.scrollTo(0, 0);
  return (
    <div id='Landing'>
      <Switch>
        {/* <Route exact path='/' render={() => <LandingInfo />} /> */}
        <Route exact path='/' render={() => <Login load={props.load} />} />
        <Route exact path='/login' render={() => <Login load={props.load} />} />

        <Route exact path='/registration' component={Registration} />
        <Route exact path='/registration/firm' component={Registration} />
        <Route exact path='/registration/user' component={RegisterUser} />

        <Route exact path='/forgotPassword' component={ForgotPassword} />
        <Route exact path='/reset/:token_id' component={ResetPassword} />

        <Route path='/chrome' component={Chrome} />
        <Route path='*' component={NotFound} />
      </Switch>

      {/* <Footer /> */}
    </div>
  );
};

export default Landing;
