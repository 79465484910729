import React, { Component } from 'react';
import { Container, Col, Row, Button, Modal, Form } from 'react-bootstrap';
export default class TopicModal extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
    };
  }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  componentWillReceiveProps(newProps) {
    if (newProps.linkErr !== this.props.linkErr) {
      this.setState({
        name: '',
      });
    }
    if (newProps.name != this.props.name) {
      this.setState({
        name: newProps.name,
      });
    }
  }
  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.createLink}
          onHide={() =>
            this.setState(
              {
                name: '',
              },
              () => {
                this.props.handleCreateClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.linkErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.linkErr}
                </div>
              )}
              <Form.Group controlId='linkName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The Topic'
                  value={this.state.name ? this.state.name : ''}
                  onChange={this.handleChange}
                  name='name'
                  ref={this.props.linkRef}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.handleSubmit({
                    name: this.state.name,
                  });
                  this.setState({
                    name: '',
                  });
                }}
                variant='primary'
              >
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showDelete}
          onHide={this.props.handleDeleteClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this document?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleDeleteClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showUpdate}
          onHide={() =>
            this.setState(
              {
                name: '',
              },
              () => {
                this.props.handleUpdateClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Update Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.updateErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.updateErr}
                </div>
              )}
              <Form.Group controlId='linkName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The Topic'
                  value={this.state.name ? this.state.name : ''}
                  onChange={this.handleChange}
                  name='name'
                  ref={this.props.linkRef}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.handleUpdate({
                    name: this.state.name,
                  });
                  this.setState({
                    name: '',
                  });
                }}
                variant='primary'
              >
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
