import axios from 'axios';
import prod from '../../../prod';

/* eslint import/no-webpack-loader-syntax: 0 */
import React, { Component } from 'react';

import PDFWorker from 'worker-loader!pdfjs-dist/lib/pdf.worker';
// import PDFWorker from 'worker-loader!react-pdf-highlighter/node_modules/pdfjs-dist/lib/pdf.worker';

import {
  PdfLoader,
  PdfHighlighter,
  Popup,
  AreaHighlight,
  setPdfWorker,
} from 'react-pdf-highlighter';

import Highlight from './Highlight';

import Tip from './Tip';
import Spinner from './Spinner';
import Sidebar from './Sidebar';

setPdfWorker(PDFWorker);

const parseIdFromHash = () =>
  document.location.hash.slice('#highlight-'.length);

const resetHash = () => {
  document.location.hash = '';
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className='Highlight__popup'>
      {comment.emoji} {comment.text}
    </div>
  ) : null;
class PDFViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: this.props.link,
      highlights: [],
      highlightsAddedToRows: false,
    };

    this.deleteHighlight = this.deleteHighlight.bind(this);
    this.pinHighlight = this.pinHighlight.bind(this);
    this.resetHighlights = this.resetHighlights.bind(this);
    this.getNextId = this.getNextId.bind(this);
    this.addHighlightsToRows = this.addHighlightsToRows.bind(this);
    this.addHighlightToRow = this.addHighlightToRow.bind(this);
    this.changeIcon = this.changeIcon.bind(this);
  }

  resetHighlights = () => {
    const url = prod() + '/api/file/highlights/delete-all';
    const { refId } = this.props;

    axios.post(url, { refId }).then((res) => {
      this.setState({
        highlights: [],
      });
    });
  };

  scrollViewerTo = (highlight) => {};

  scrollToHighlightFromHash = () => {
    const highlight = this.getHighlightById(parseIdFromHash());

    if (highlight) {
      this.scrollViewerTo(highlight);
    }
  };

  getNextId = () => String(Math.random()).slice(2);

  componentWillReceiveProps(newProps) {
    if (
      newProps.statement !== this.props.statement &&
      newProps.statement != ''
    ) {
      window.addEventListener(
        'hashchange',
        this.scrollToHighlightFromHash,
        false
      );
      // get highlights for this file
      const url = prod() + '/api/file/highlights/getall';
      const { refId } = newProps;

      if (refId) {
        axios
          .post(url, { refId })
          .then((res) => {
            if (res.data.highlights !== undefined) {
              this.setState({ highlights: res.data.highlights });

              //find pinned highlight and scoll to the pinned highlight
              const pinnedHighlight = res.data.highlights.find(
                (highlight) => highlight.pinned === true
              );
              if (pinnedHighlight) {
                document.location.hash = `highlight-${pinnedHighlight._id}`;
              }
              this.scrollViewerTo(pinnedHighlight);
            }
          })
          .catch((e) => console.log(e));
      }
    }
  }
  componentDidMount() {
    window.addEventListener(
      'hashchange',
      this.scrollToHighlightFromHash,
      false
    );
    const url = prod() + '/api/file/highlights/getall';
    const { refId, link } = this.props;

    if (refId) {
      axios
        .post(url, { refId, link })
        .then((res) => {
          if (res.data.highlights !== undefined) {
            this.setState({ highlights: res.data.highlights });

            //find pinned highlight and scoll to the pinned highlight
            const pinnedHighlight = res.data.highlights.find(
              (highlight) => highlight.pinned === true
            );
            if (pinnedHighlight) {
              document.location.hash = `highlight-${pinnedHighlight._id}`;
            }
            this.scrollViewerTo(pinnedHighlight);
          }
        })
        .catch((e) => console.log(e));
    }
  }

  getHighlightById(id) {
    const { highlights } = this.state;

    return highlights.find((highlight) => highlight._id === id);
  }

  //uploads image highlight to gcs
  uploadFile = (image) => {
    const base64Text = image.substring(22, image.length);
    const url = prod() + '/api/cases/upload/highlightImage';

    return axios
      .post(url, { imageData: base64Text })
      .then((res) => {
        console.log("highlight image uploaded: ", res.data.gcImageData)
        // const gcImageData = res.data.gcImageData;
        return res.data.gcImageData;
      })
      .catch(e => console.log(e));
  }

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }

  async addHighlight(highlight) {
    console.log("highight: ", highlight)
    const url = prod() + '/api/file/highlights/add';
    let data = {};

    // if it's an image, then upload it to google cloud first, get the gc_filename and link to save to the db
    if (highlight.content.image) {
      const gcImageData = await this.uploadFile(highlight.content.image);
      console.log("image data: ", gcImageData)
      data = {
        refId: this.props.refId,
        link: this.props.link,
        highlight: {
          ...highlight,
          content: { image: gcImageData },
          pinned: false,
        },
      };
    } else { //if the highlight is just a text
      data = {
        refId: this.props.refId,
        link: this.props.link,
        highlight: {
          ...highlight,
          pinned: false,
        },
      };
    }
    console.log("data: ", data)

    axios.post(url, data).then((res) => {
      console.log("found pdf? ", res.data)
      if (res.data.failed) {
        this.props.showErrorScreen();
      } else {
        this.setState({ highlights: res.data.updatedPDF.highlights });
      }
      //if the pdf is being previewed, this will trigger to be able to summarize the highlights
      // if (this.props.saveHighlights) {
      //   this.props.saveHighlights(res.data.updatedPDF.highlights);
      // }
    })
    .catch(e => console.log(e))
  }

  // ONLY for updating image highlight, not texts
  async updateHighlight(highlightId, position, content, image) {
    if (image.gc_filename) { //remove the image from google cloud
      this.deleteFile(image.gc_filename);
    }
    //upload the new image to gcs
    const gcImageData = await this.uploadFile(content.image);
    
    const data = {
      refId: this.props.refId,
      highlightId,
      position,
      content: { image: gcImageData },
    };
    axios //update the highlights db
      .post(prod() + '/api/file/highlights/update-highlight', data)
      .then((res) => {})
      .catch(e => console.log(e));
    
    this.setState({
      highlights: this.state.highlights.map((h) => {
        const {
          _id,
          position: originalPosition,
          content: originalContent,
          ...rest
        } = h;
        return _id === highlightId
          ? {
              _id,
              position: { ...originalPosition, ...position },
              content: { image: gcImageData },
              ...rest,
            }
          : h;
      }),
    });
  }

  deleteHighlight(highlightId, content) {
    const { refId } = this.props;
    const data = { refId, highlightId };
    const url = prod() + '/api/file/highlights/delete-highlight';
    axios
      .post(url, data) //remove the highlight from the db
      .then(() => {
        const updatedHighlights = this.state.highlights.filter(
          (highlight) => highlight._id !== highlightId
        ); // remove the highlight from the state

        this.setState({ highlights: updatedHighlights });
      });
    if (content.image?.gc_filename) { //if the highlight is an image highlight
      this.deleteFile(content.image.gc_filename); //remove image from gcs
    }
  }

  // pin or unpin highlight, pinned is either true or false
  pinHighlight(highlightId, pinned) {
    const { refId } = this.props;
    const data = { refId, highlightId, pinned };
    const url = prod() + '/api/file/highlights/pin-highlight';

    axios.post(url, data).then((res) => {
      //make a shallow copy of the highlights
      const highlights = [...this.state.highlights];
      //find the index of the highlight you're updating, and pin it
      const highlightIndex = this.state.highlights.findIndex(
        (h) => h._id === highlightId
      );
      const updatedHighlight = { ...highlights[highlightIndex], pinned };

      //find the index of the previously pinned highlight to unpin it
      const pinnedHighlightIndex = this.state.highlights.findIndex(
        (h) => h.pinned === true
      );
      const unpinnedHighlight = {
        ...highlights[pinnedHighlightIndex],
        pinned: false,
      };

      //insert updated highlight to the highlights copy
      highlights[highlightIndex] = updatedHighlight;
      highlights[pinnedHighlightIndex] = unpinnedHighlight;

      this.setState({ highlights });
    });
  }

  addHighlightsToRows() {
    const data = {
      linkLocation: this.props.link,
      linkName: this.props.name,
      rowId: this.props.refId,
      type: 'file',
    };
    this.setState({ highlightsAddedToRows: true });
    this.props.addHighlightsToRows(this.state.highlights, data);
  }

  addHighlightToRow(highlight) {
    console.log("highlight: ", highlight)
    const data = {
      linkLocation: this.props.link,
      linkName: this.props.name,
      rowId: this.props.refId,
      type: 'file',
    };

    this.props.addHighlightToRow(highlight, data);
  }

  changeIcon(id) {
    const highlight = this.state.highlights.find(
      (highlight) => highlight._id === id
    );
    const index = this.state.highlights.findIndex(
      (highlight) => highlight._id === id
    );
    const updatedHighlight = { ...highlight, added: true };
    const highlightDupe = [...this.state.highlights];
    highlightDupe[index] = updatedHighlight;
    this.setState({ highlights: highlightDupe });
  }

  render() {
    const { url, highlights } = this.state;
    console.log("highlightS: ", this.state.highlights)
    console.log("url: ", url)

    return (
      <div className='pdf-container'>
        <Sidebar
          highlights={highlights}
          resetHighlights={this.resetHighlights}
          toggleDocument={this.toggleDocument}
          deleteHighlight={this.deleteHighlight}
          pinHighlight={this.pinHighlight}
          addHighlightsToRows={this.addHighlightsToRows}
          addHighlightToRow={this.addHighlightToRow}
          preview={this.props.preview ? this.props.preview : false}
          darkMode={this.props.darkMode}
          changeIcon={this.changeIcon}
          summary={this.props.summary}
          highlightsAddedToRows={this.state.highlightsAddedToRows}
        />
        <div className='file-container'>
          <PdfLoader
            url={url ? url : this.props.link}
            beforeLoad={<Spinner />}
            // onLoadError={(error) => {
            //   console.log("Load error", error)
            // }}
            // onSourceSuccess={() => {
            //     console.log("Source success")
            //   }}
            // onSourceError={(error) => {
            //   console.error("Source error", error)
            // }}
            // onLoadSuccess={console.log("SUCESS!")}
          >
            {(pdfDocument) => (
              <PdfHighlighter
                pdfDocument={pdfDocument}
                enableAreaSelection={(event) => event.altKey}
                onScrollChange={resetHash}
                scrollRef={(scrollTo) => {
                  this.scrollViewerTo = scrollTo;

                  this.scrollToHighlightFromHash();
                }}
                onSelectionFinished={(
                  position,
                  content,
                  hideTipAndSelection,
                  transformSelection
                ) => (
                  <Tip
                    onOpen={transformSelection}
                    darkMode={this.props.darkMode}
                    onConfirm={(comment) => {
                      this.addHighlight({ content, position, comment });

                      hideTipAndSelection();
                    }}
                  />
                )}
                highlightTransform={(
                  highlight,
                  index,
                  setTip,
                  hideTip,
                  viewportToScaled,
                  screenshot,
                  isScrolledTo
                ) => {
                  const isTextHighlight = !Boolean(
                    highlight.content && highlight.content.image
                  );

                  const component = isTextHighlight ? (
                    <Highlight
                      isScrolledTo={isScrolledTo}
                      position={highlight.position}
                      comment={highlight.comment}
                    />
                  ) : (
                    <AreaHighlight
                      highlight={highlight}
                      onChange={(boundingRect) => {
                        this.updateHighlight(
                          highlight._id,
                          { boundingRect: viewportToScaled(boundingRect) },
                          { image: screenshot(boundingRect) },
                          highlight.content.image
                        );
                      }}
                    />
                  );

                  return (
                    <Popup
                      popupContent={<HighlightPopup {...highlight} />}
                      onMouseOver={(popupContent) =>
                        setTip(highlight, (highlight) => popupContent)
                      }
                      onMouseOut={hideTip}
                      key={index}
                      children={component}
                    />
                  );
                }}
                highlights={highlights}
              />
            )}
          </PdfLoader>
        </div>
      </div>
    );
  }
}

export default PDFViewer;
