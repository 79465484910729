import React, { Component, createRef } from 'react';
import {
  Container,
  Col,
  Row,
  Button,
  Modal,
  Form,
  RBGlyph,
} from 'react-bootstrap';
import axios from 'axios';
import prod from '../../../prod';
import { FaDropbox } from 'react-icons/fa';
import DropboxChooser from 'react-dropbox-chooser';

import './UploadDocument.css';

// import FilePreviewModalE from './FilePreviewModal_existingrow';
import FilePreviewModal from './FilePreviewModal';

export default class LinkDocument extends Component {
  constructor() {
    super();
    this.state = {
      linkName: '',
      linkLocation: 'https://',

      docType: 'link',

      fileName: '', //file name displayed on app
      fileLocation: '',
      fileErr: '',

      fileInfo: '',
      fileModal: false,
      uploadableFileTypes: ['application/pdf'],

      gcFileName: '', //file name on google cloud

      showCreateNewDoc: false,
      showAddExistingDoc: false,

      //
      dropbox: false,
    };

    this.uploadDoc = this.uploadDoc.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.openFileViewModal = this.openFileViewModal.bind(this);
    this.closeModals = this.closeModals.bind(this);
    this.updateDocs = this.updateDocs.bind(this);
    this.createHighlightsToRows = this.createHighlightsToRows.bind(this);
    this.fileRef = createRef();
  }
  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };
  componentDidMount() {
    // console.log('BEFORE DOCS Mount');
    axios
      .post(prod() + '/api/documents/getall', {
        case: this.props.case._id,
        witness: this.props.witness['_id'],
      })
      .then((a) => {
        this.setState({
          docs: a.data.documents,
        });
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }
  componentWillReceiveProps(newProps) {
    if (newProps.docs?.length !== this.props.docs?.length) {
      axios
        .post(prod() + '/api/documents/getall', {
          case: this.props.case._id,
          witness: this.props.witness['_id'],
        })
        .then((res) => {
          this.setState({
            docs: res.data.documents,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
    }
  }

  // when a user clicks on 'Create' button or the 'Use' button
  uploadDoc() {
    console.log('uploading file');
    if (this.props.refId) {
      if (this.state.docType === 'link') {
        this.props.addLink(
          {
            linkName:
              this.state.linkName != ''
                ? this.state.linkName
                : this.state.linkLocation,
            linkLocation: this.state.linkLocation,
            type: 'Link',
            refId: this.props.refId,
          })
      } else if (this.state.docType === 'file') {
        this.props.addLink(
          {
            linkName: this.state.fileName,
            linkLocation: this.state.fileLocation,
            type: 'file',
            refId: this.props.refId,
          }
        );
      }
    } else {
      if (this.state.docType === 'link') {
        this.props.createLink(
          {
            linkName:
              this.state.linkName != ''
                ? this.state.linkName
                : this.state.linkLocation,
            linkLocation: this.state.linkLocation,
            type: 'Link',
          })
      } else if (this.state.docType === 'file') {
        console.log("create link!")
        this.props.createLink(
          {
            linkName: this.state.fileName,
            linkLocation: this.state.fileLocation,
            type: 'file',
          }
        )
      }
    }
    this.setState({
      linkName: '',
      linkLocation: '',
      fileName: '',
      fileLocation: '',
    });

  }

  onFileChange(e) {
    // if the fileName already exists because the user entered the optional name, then don't change the filename to the actual file name
    if (this.state.fileName) {
      this.setState({ fileInfo: e.target.files[0] });
    } else {
      this.setState({
        fileInfo: e.target.files[0],
        fileName: e.target.files[0].name,
      });
    }
  }


  // when a user clicks on 'upload' button, it uploads to google doc and sends back the google cloud uploaded file info including the link
  uploadFile() {
    // console.log('UPLOADING FILE (TO GOOGLE CLOUD)');
    // const url = prod() + '/api/cases/update/witness/uploadFile';
    const url = prod() + '/api/cases/update/witness/uploadFile';
    const file = this.state.fileInfo;
    const data = new FormData();
    data.append('fileInfo', file);

    if (!file) {
      this.setState({ fileErr: 'Please choose a file' });
    } else if (file && !this.state.uploadableFileTypes.includes(file.type)) {
      this.setState({
        fileErr: 'File type is not supported. Supported file type: pdf',
      });
    } else if (file && this.state.uploadableFileTypes.includes(file.type)) {

      this.setState({ fileModal: true });

      axios({
        method: 'post',
        url: url,
        data: data,
        config: { headers: { 'Content-Type': 'multipart/form-data' } },
      }).then((res) => {
        console.log('uploaded file info from gc: ', res.data);
        this.setState({
          fileLocation: res.data.path,
          gcFileName: res.data.filename,
        });
      });
    }
  }

  openFileViewModal(open) {
    const file = this.state.fileInfo;
    if (file && this.state.uploadableFileTypes.includes(file.type)) {
      this.setState({ fileModal: open });
    }
  }

  closeModals() {
    this.setState({ 
      showCreateNewDoc: false, 
      showAddExistingDoc: false,
      fileName: '',
      linkName: '',
      fileModal: false,
    });
  }

  deleteFile() {
    const url = prod() + '/api/cases/update/witness/deleteFile';
    const filename = this.state.gcFileName;
    axios.post(url, { filename }).then(() => {
      this.setState({
        fileInfo: '',
        fileName: '',
        fileLocation: '',
        gcFileName: '',
      });
    });
  }

  updateDocs() {
    axios
        .post(prod() + '/api/documents/getall', {
          case: this.props.case._id,
          witness: this.props.witness['_id'],
        })
        .then((res) => {
          // console.log("UPDATED DOCUMENT LIST: ", res.data.documents);
          this.setState({
            docs: res.data.documents,
          });
        })
        .catch((e) => {
          console.log('ERROR', e);
        });
  }

  createHighlightsToRows(highlights, rowId) {
    const data = {
      linkName: this.state.fileName,
      linkLocation: this.state.fileLocation,
      type: 'file',
      rowId: rowId,
    }
    this.props.createHighlightsToRows(highlights, data);
  }

  handleDropboxSuccess = (files) => {
    //
    console.log("files: ", files)
    //"https://www.dropbox.com/s/ruixvjprdywhl8w/sha531_transcripts.pdf?dl=0"
    //change to http://dl.dropboxusercontent.com/s/{filename}
    const dropboxLink = files[0].link;
    const index = dropboxLink.indexOf("www.dropbox.com/s/") + 18;
    const filePath = dropboxLink.slice(index);
    // console.log(filePath)
    //link that is not blocked by CORS policy
    const updatedDropboxLink = "https://dl.dropboxusercontent.com/s/" + filePath;

    if (!files[0].name.includes('pdf')) {
      this.setState({ fileErr: 'You can only upload pdfs'})
    } else if (files.length > 1) {
      this.setState({ fileErr: 'You can only choose one file at a time', 
      fileName: files[0].name,
      fileLocation: updatedDropboxLink,
      dropbox: true, 
    });
    } else if (!files[0].name.includes('pdf') && files.length > 1) {
      this.setState({ fileErr: 'You can only upload pdfs and can only upload one file at a time'})
    } else {
      this.setState({ 
        fileName: files[0].name,
        fileLocation: updatedDropboxLink,
        dropbox: true,
        fileErr: '',
      });
    }
  }

  render() {
    // console.log("ROW ID OF THE CLICKED ROW: ", this.props.refId)
    let listItems = [];
    if (this.state.docs && this.state.docs.length > 0) {
      for (let a of this.state.docs) {
        listItems.push(
          <div
            className='listItem'
            onClick={() => {
              if (this.props.refId) {
                this.props.addExistingDocOnRow(a, this.props.refId);
                this.props.createPDF(this.props.refId, a.link)
              } else {
                this.props.addExistingDoc(a);
                this.props.saveLinkToCreatePDF(a.link);
              }
              this.setState({ showAddExistingDoc: false });
              this.props.openUploadDocModal(false);
            }}
          >
            <p>{a.name}</p>
            <p>{a.type === 'Link' ? a.link : 'Uploaded on Aleri'}</p>
          </div>
        );
      }
    } else {
      listItems = <p className='no_ref'>No Documents Referenced</p>;
    }
    // let listItemsExisting = [];
    // if (this.state.docs && this.state.docs.length > 0) {
    //   for (let a of this.state.docs) {
    //     listItemsExisting.push(
    //       <div
    //         className='listItem'
    //         onClick={() => this.props.addExistingLink(a)}
    //       >
    //         <p>{a.name}</p>
    //         <p>{a.link}</p>
    //       </div>
    //     );
    //   }
    // } else {
    //   listItemsExisting = <p className='no_ref'>No Documents Referenced</p>;
    // }
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={
            this.props.uploadDocModal
              ? this.props.uploadDocModal
              : this.props.uploadDocModalE
          }
          onHide={() => {
            this.setState({
              linkName: '',
              linkLocation: 'https://',
              fileName: '',
              fileInfo: {},
            });
            this.props.openUploadDocModal(false);
          }}
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach Link</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.props.linkErr && this.state.docType === 'link' && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.linkErr}
                </div>
              )}
              {this.state.fileErr && this.state.docType === 'file' && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.fileErr}
                </div>
              )}

              <div
                className='attach-link'
                onClick={() => {
                  this.state.showCreateNewDoc
                    ? this.setState({ showCreateNewDoc: false })
                    : this.setState({ showCreateNewDoc: true });
                }}
              >
                <h5 className='upload-doc_header'>Create New Document</h5>
                <img
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                ></img>
              </div>
              {this.state.showCreateNewDoc && (
                <>
                  {this.state.docType === 'link' && (
                    <Form.Group controlId='linkName'>
                      <Form.Label>Name(optional)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter The Name Of The File'
                        value={this.state.linkName ? this.state.linkName : ''}
                        onChange={this.handleChange}
                        name='linkName'
                        ref={this.props.linkRef}
                      />
                    </Form.Group>
                  )}
                  {this.state.docType === 'file' && (
                    <Form.Group controlId='fileName'>
                      <Form.Label>Name(optional)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter The Name Of The File'
                        value={this.state.fileName ? this.state.fileName : ''}
                        onChange={this.handleChange}
                        name='fileName'
                        ref={this.props.linkRef}
                      />
                    </Form.Group>
                  )}
                  <Form.Group controlId='linkLocation'>
                    <Form.Label>Location</Form.Label>
                    <div>
                      <span>Document Type:</span>
                      <span
                        className={
                          this.state.docType === 'link'
                            ? 'link document-type'
                            : 'document-type'
                        }
                        onClick={() => this.setState({ docType: 'link' })}
                      >
                        Link File
                      </span>
                      <span>|</span>
                      <span
                        className={
                          this.state.docType === 'file'
                            ? 'file document-type'
                            : 'document-type'
                        }
                        onClick={() => this.setState({ docType: 'file' })}
                      >
                        Upload File
                      </span>
                    </div>
                    {this.state.docType === 'link' && (
                      <Form.Control
                        type='text'
                        placeholder='Enter The URL Of The File'
                        value={
                          this.state.linkLocation ? this.state.linkLocation : ''
                        }
                        onChange={this.handleChange}
                        name='linkLocation'
                      />
                    )}
                    {this.state.docType === 'file' && (
                      <div>
                        <input
                          type='file'
                          name='fileInfo'
                          className='choose-file'
                          onChange={this.onFileChange}
                        />
                        <div className="dropbox">
                          <DropboxChooser 
                            appKey={'ui01g9o2ch2wmw7'}
                            success={this.handleDropboxSuccess}
                            cancel={() => console.log("cancel")}
                            multiselect={true}
                            extensions={['.pdf']} 
                          >
                            <div>
                              <FaDropbox size={25} className="dropbox-icon"/>       
                              <span style={{ marginLeft: '10px'}}>Dropbox</span>
                            </div>
                          </DropboxChooser>
                        </div>
                      </div>
                    )}
                  </Form.Group>
                </>
              )}
              <div
                className='attach-link'
                onClick={() => {
                  this.state.showAddExistingDoc
                    ? this.setState({ showAddExistingDoc: false })
                    : this.setState({ showAddExistingDoc: true });
                }}
              >
                <h5 className='upload-doc_header'>Add Existing Document</h5>
                <img
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                ></img>
              </div>
              {this.state.showAddExistingDoc && (
                <div className='documentList'>{listItems}</div>
              )}
            </Modal.Body>
            {this.state.showCreateNewDoc && (
              <>
                {this.state.docType === 'link' && (
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        this.uploadDoc();
                        this.props.openUploadDocModal(false);
                        this.closeModals();
                        this.setState({
                          linkName: '',
                          linkLocation: 'https://',
                        });
                      }}
                      variant='primary'
                    >
                      Create
                    </Button>
                  </Modal.Footer>
                )}
                {this.state.docType === 'file' && !this.state.dropbox && (
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        this.uploadFile(
                          this.state.fileInfo.name,
                          this.state.fileInfo.type
                        );
                      }}
                      variant='primary'
                    >
                      Preview
                    </Button>
                  </Modal.Footer>
                )}
                {this.state.docType === 'file' && this.state.dropbox && (
                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        this.uploadDoc();
                        this.props.openUploadDocModal(false);
                        this.props.saveLinkToCreatePDF(this.state.fileLocation)
                        this.setState({ dropbox: false });
                      }}
                      variant='primary'
                    >
                      Upload
                    </Button>
                  </Modal.Footer>
                )}
              </>
            )}
          </Form>
        </Modal>

        {/* When a file uploads, this modal pops up and previews the file*/}

        {/* <FilePreviewModalE
          link={this.state.fileLocation}
          fileModal={this.state.fileModal}
          openFileViewModal={this.openFileViewModal}
          name={this.state.fileInfo.name}
          fileRef={this.fileRef}
          deleteFile={this.deleteFile}
          uploadDoc={this.uploadDoc}
          refId={this.props.refId}
          value={this.props.value}
          handleCreateClose={this.props.handleCreateClose}
          openUploadDocModal={this.props.openUploadDocModal}
          closeModals={this.closeModals}
          darkMode={this.props.darkMode}
          createHighlightsToRows={this.createHighlightsToRows}
          darkMode={this.props.darkMode}
          summary={this.props.summary}
          saveHighlights={this.props.saveHighlights}
        /> */}
        <FilePreviewModal
          link={this.state.fileLocation}
          fileModal={this.state.fileModal}
          openFileViewModal={this.openFileViewModal}
          name={this.state.fileInfo.name}
          fileRef={this.fileRef}
          deleteFile={this.deleteFile}
          uploadDoc={this.uploadDoc}
          saveHighlights={this.props.saveHighlights}
          handleCreateClose={this.props.handleCreateClose}
          openUploadDocModal={this.props.openUploadDocModal}
          closeModals={this.closeModals}
          darkMode={this.props.darkMode}
          createHighlightsToRows={this.createHighlightsToRows}
          darkMode={this.props.darkMode}
          summary={this.props.summary}
          refId={this.props.refId}
        />

        {/* add */}
        {/* ??? what is this modal for -JJ */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.addLinkState}
          onHide={() =>
            this.setState(
              {
                linkName: '',
                linkLocation: '',
              },
              () => {
                this.props.handleAddClose();
              }
            )
          }
        >
          <Form>
            <Modal.Header closeButton>
              <Modal.Title>Attach Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.props.linkErr && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.props.linkErr}
                </div>
              )}
              <h5>Create New Document</h5>
              <Form.Group controlId='linkName'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The Name Of The File'
                  value={this.state.linkName ? this.state.linkName : ''}
                  onChange={this.handleChange}
                  name='linkName'
                  ref={this.props.addRef}
                />
              </Form.Group>
              <Form.Group controlId='linkLocation'>
                <Form.Label>Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter The URL Of The File'
                  value={this.state.linkLocation ? this.state.linkLocation : ''}
                  onChange={this.handleChange}
                  name='linkLocation'
                />
              </Form.Group>
              <h5>Add Existing Document</h5>
              {/* <div className='documentList'>{listItemsExisting}</div> */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => {
                  this.props.addLink({
                    linkName:
                      this.state.linkName != '' &&
                      this.state.linkName != undefined &&
                      this.state.linkName != null
                        ? this.state.linkName
                        : this.state.linkLocation,
                    linkLocation: this.state.linkLocation,
                  });
                }}
                variant='primary'
              >
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>


       {/* Document Delete Modal */}
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.props.showDelete}
          onHide={this.props.handleDeleteClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Delete</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to delete this document?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.props.handleDeleteClose}>
              No
            </Button>
            <Button variant='primary' onClick={this.props.handleDelete}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
