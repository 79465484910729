import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { sortableHandle } from 'react-sortable-hoc';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';

import FileViewModal from '../../Shared/FileViewModal';
import LinkDocument from '../../Shared/LinkDocument';
import AudioModal from '../../Shared/AudioModal';
import Highlightable from 'highlightable';
import '../../Shared/UploadDocument.css';

export default class SummaryRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      i: this.props.index,

      uploadDocModal: false,

      noHighlightModal: false,

      fileModal: false,
      filename: this.props.value.ref.document
        ? this.props.value.ref.document.link.slice(48)
        : '',
      
      imageFactTag: false,
      //audio modal
      audioModal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.openFileViewModal = this.openFileViewModal.bind(this);
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
    this.handleRefTextDrag = this.handleRefTextDrag.bind(this);
  }

  DragHandle = sortableHandle(() => <span className='drag'></span>);
  handleChange = (i, event) => {
    const key = i;
    const name = event.target.name;
    const value = event.target.value;
    // console.log(name, value, key);
    if (name === 'reference') {
      this.setState({
        value: {
          ...this.state.value,
          ref: {
            ...this.state.value['ref'],
            value: value,
          },
          reference: value,
        },
      });
    } else {
      this.setState({
        value: {
          ...this.state.value,
          [name]: value,
        },
      });
    }
  };

  componentWillReceiveProps(newProps) {
    if (
      this.props.value != newProps.value ||
      this.props.statement != newProps.statement
    ) {
      this.setState({
        value: newProps.value,
        i: newProps.value,
      });
    }
  }

  openFileViewModal(open) {
    this.setState({ fileModal: open });
  }

  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open });
  }

  handleRefTextDrag() {
    if (window.getSelection().toString() !== '') this.setState({ noHighlightModal: true })
  }
  openAudioModal = (open) => {
    this.setState({ audioModal: open });
  }

  render() {
    const { value, i } = this.state;
    // console.log("this.state: ", this.state)
    let ref = {};
    let row = {};
    // console.log('prop toggle timeline', this.props.toggleTimeline);
    if (value.ref && value.ref.document == undefined) {
      ref = (
        <>
          <Col
            md={4}
            className={value.ref.value == '' ? 'ref' : ' ref ref-end'}
          >
            <Form.Group controlId={`${i}a`}>
              {this.props.toggleHighlight ? (
                <TextareaAutosize
                  placeholder='Enter Reference'
                  value={value.reference}
                  onMouseUp={this.handleRefTextDrag}
                  onChange={(e) => this.handleChange(i, e)}
                  name='reference'
                  onBlur={() => this.props.saveItem(this.state.value)}
                  className={value.ref.value != '' ? '' : ' notes-n'}
                  ref={this.props.textInput1}
                />
              ) : (
              <TextareaAutosize
                placeholder='Enter Reference'
                value={value.reference}
                onChange={(e) => this.handleChange(i, e)}
                name='reference'
                onBlur={() => this.props.saveItem(this.state.value)}
                className={value.ref.value != '' ? '' : ' notes-n'}
                ref={this.props.textInput1}
              />
              )}
            </Form.Group>
            {value.ref.value == '' && (
              <Button
                onClick={() => this.openUploadDocModal(true)}
                className='btn upload-document'
                tabIndex={-1}
              >
                Upload Document
              </Button>
            )}
          </Col>
        </>
      );
    } else {
      ref = (
        <>
          <Col md={3} className='ref '>
            {value.ref.document.type === 'Link' && (
              <>
                <a
                  className='centre_link'
                  href={value.ref.document.link}
                  target='_black'
                >
                  {value.ref.document.name}
                </a>
              </>
            )}
            {value.ref.document.type === 'file' && (
              <p
                className='centre_link'
                onClick={() => this.openFileViewModal(true)}
              >
                {value.ref.value}
              </p>
            )}
            {value.ref.document.type === 'audio' && (
              <p
                className='centre_link'
                onClick={() => this.openAudioModal(true)}
              >
                {value.ref.value}
              </p>
            )}
          </Col>
          <Col md={1} className='ref-end'>
            <img
              className='ref-logo-title'
              src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
              onClick={() =>
                this.props.handleDocumentDeleteShow(this.state.value)
              }
            ></img>
          </Col>
        </>
      );
    }
    if (i == this.props.q - 1) {
      row = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-none d-md-block '>
            <Row key={i} className='saved-input'>
              {ref}
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                  {this.props.toggleHighlight || this.props.toggleTimeline ? (
                    <div className='highlight-class'>
                      <Highlightable
                        // ranges CANNOT be empty, needs to have a start and end of the highlight
                        // need to extract start and end index somehow after highlighting???
                        // ranges={[{start: 0, end: this.state.q[i].evidence.length - 1, text: this.state.q[i].evidence}]}
                        // ranges={[{start: this.state.startHighlight, end: this.state.endHighlight, text: this.state.q[i].evidence}]}
                        ranges={[]}
                        enabled={true}
                        onTextHighlighted={(e) => {
                          // this.props.selectionHandler(e, value);
                          if (this.props.toggleHighlight) {
                            this.props.selectionHandler(e, value.reference, value.evidence);
                          } else if (this.props.toggleTimeline) {
                            this.props.selectionTimelineHandler(
                              e,
                              value.reference
                            );
                          }
                        }}
                        id={`${i}`}
                        // onMouseOverHighlightedWord={
                        //   onMouseOverHighlightedWordCallback
                        // }
                        //#fdfd3e
                        highlightStyle={{
                          backgroundColor: '#ffcc80',
                          color: 'black',
                        }}
                        text={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                      />
                    </div>
                  ) : (
                    <>
                    {value.evidence.includes('storage.googleapis.com/highlight-images') ? (
                      <div 
                        className="img-container"
                        onMouseEnter={() => this.setState({ imageFactTag: true })}
                        onMouseLeave={() => this.setState({ imageFactTag: false })}
                      >
                        <img 
                          src={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                          alt="screenshot"
                        />
                        {this.state.imageFactTag && (
                          <Button
                            variant="secondary"
                            className="facttag-btn"
                            onClick={() => this.props.handleImageHighlightShow(value.evidence, value)}
                          >
                            Fact Tag
                          </Button>
                        )}
                      </div>
                    ) : (
                      <TextareaAutosize
                        placeholder='Enter Evidence Here'
                        value={value.evidence}
                        onChange={(e) => this.handleChange(i, e)}
                        name='evidence'
                        onBlur={() => this.props.saveItem(this.state.value)}
                        ref={this.props.textInput2}
                      />
                    )}
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={1}>
                {this.props.toggleHighlight ||
                this.props.toggleTimeline ? null : (
                  <>
                    <img
                      onClick={() => this.props.handleDShow(this.state.value)}
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                    ></img>
                    <this.DragHandle />
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      row = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-none d-md-block ' ref={this.props.divElement}>
            <Row key={i} className='saved-input'>
              {ref}
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                  {this.props.toggleHighlight || this.props.toggleTimeline ? (
                    <div className='highlight-class'>
                      <Highlightable
                        // ranges CANNOT be empty, needs to have a start and end of the highlight
                        // need to extract start and end index somehow after highlighting???
                        // ranges={[{start: 0, end: this.state.q[i].evidence.length - 1, text: this.state.q[i].evidence}]}
                        // ranges={[{start: this.state.startHighlight, end: this.state.endHighlight, text: this.state.q[i].evidence}]}
                        ranges={[]}
                        enabled={true}
                        onTextHighlighted={(e) =>
                          this.props.selectionHandler(e, value, null)
                        }
                        id={`${i}`}
                        //#fdfd3e
                        highlightStyle={{
                          backgroundColor: '#ffcc80',
                          color: 'black',
                        }}
                        text={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                      />
                    </div>
                  ) : (
                    <>
                    {value.evidence.includes('storage.googleapis.com/highlight-images') ? (
                      <div 
                        className="img-container"
                        onMouseEnter={() => this.setState({ imageFactTag: true })}
                        onMouseLeave={() => this.setState({ imageFactTag: false })}
                      >
                        <img 
                          src={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                          alt="screenshot"
                        />
                        {this.state.imageFactTag && (
                          <Button
                            variant="secondary"
                            className="facttag-btn"
                            onClick={() => this.props.handleImageHighlightShow(value.evidence, value)}
                          >
                            Fact Tag
                          </Button>
                        )}
                      </div>
                    ) : (
                      <TextareaAutosize
                        placeholder='Enter Evidence Here'
                        value={value.evidence}
                        onChange={(e) => this.handleChange(i, e)}
                        name='evidence'
                        onBlur={() => this.props.saveItem(this.state.value)}
                      />
                    )}
                    </>
                  )}
                </Form.Group>
              </Col>
              <Col md={1}>
                {this.props.toggleHighlight ||
                this.props.toggleTimeline ? null : (
                  <>
                    <img
                      onClick={() => this.props.handleDShow(this.state.value)}
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                    ></img>
                    <this.DragHandle />
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <>
        {row}
        {this.props.value.ref.document && (
          <>
            <FileViewModal
              refId={this.state.value._id}
              link={this.state.value.ref.document.link}
              value={this.state.value}
              name={this.state.value.ref.document.name}
              fileId={this.state.value.ref.document._id}
              fileModal={this.state.fileModal}
              openFileViewModal={this.openFileViewModal}
              addHighlightsToRows={this.props.addHighlightsToRows}
              addHighlightToRow={this.props.addHighlightToRow}
              darkMode={this.props.darkMode}
              summary={true}
            />
            {/* <AudioModal
              darkMode={this.props.darkMode}
              openAudioModal={this.openAudioModal}
              audioModal={this.state.audioModal}
              docId={this.state.value.ref.document._id}
              link={this.state.value.ref.document.link}
              name={this.state.value.ref.document.name}
            /> */}
          </>
        )}
        <LinkDocument
          uploadDocModal={this.state.uploadDocModal}
          openUploadDocModal={this.openUploadDocModal}
          linkRef={this.props.linkRef}
          addRef={this.props.addRef}
          createLink={this.props.createLink}
          handleCreateClose={this.props.handleCreateClose}
          linkErr={this.props.linkErr}
          darkMode={this.props.darkMode}
          witness={this.props.witness}
          case={this.props.case}
          addExistingDoc={this.props.addExistingDoc}
          addExistingLink={this.props.addExistingLink}
          addLink={this.props.addLink}
          addLinkState={this.props.addLinkState}
          handleAddClose={this.props.handleAddClose}
          showDelete={this.props.documentDeleteShow}
          handleDelete={this.props.handleDelete}
          handleDeleteClose={this.props.handleDeleteClose}
          refId={this.state.value._id}
          value={this.state.value}
          addExistingDocOnRow={this.props.addExistingDocOnRow}
          docs={this.props.docs}
          createHighlightsToRows={this.props.createHighlightsToRows}
          statement={this.props.statement}
          summary={true}
          saveHighlights={this.props.saveHighlights}
          createPDF={this.props.createPDF}
        />
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.noHighlightModal}
          onHide={() => this.setState({ noHighlightModal: false })}
        >
          <Modal.Header closeButton></Modal.Header>

          <Modal.Body>Only Evidence texts are highlightable.</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.setState({ noHighlightModal: false })}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
