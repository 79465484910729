import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';
import DashboardModal from '../Dashboard/DashboardModal';
import CaseModal from './CaseModal';

import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';
import Icons from '../Navigation/Icons';
import ToggleNav from '../Navigation/ToggleNav';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { isSafari } from 'react-device-detect';

export default class CaseDashboard extends Component {
  constructor() {
    super();
    this.state = {
      witnessListTodo: [],
      witnessListDoing: [],
      witnessListDone: [],
      case: {},
      show: false,
      //  create case
      wShow: false,
      // delete witness
      wDShow: false,
      // update witness

      wSShow: false,
      uWName: '',
      //
      err: '',
      //  update case
      uName: '',
      uClient: '',
      sShow: false,
      updating: '',
      bUpdate: {},
      //  delete case
      deleted: '',
      dShow: false,
      status: '',
      // export
      eShow: false,
      eCaseShow: false,
      witness: {},
      timelineByWitness: [],
      timelines: [],
    };
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
    this.handleECaseShow = this.handleECaseShow.bind(this);
    this.handleECaseClose = this.handleECaseClose.bind(this);
    this.getT = this.getT.bind(this);
    this.handleWShow = this.handleWShow.bind(this);
    this.handleWClose = this.handleWClose.bind(this);
    this.handleWDShow = this.handleWDShow.bind(this);
    this.handleWDClose = this.handleWDClose.bind(this);
    this.handleWSubmit = this.handleWSubmit.bind(this);
    this.deleteWitness = this.deleteWitness.bind(this);

    this.handleWSShow = this.handleWSShow.bind(this);
    this.handleWSClose = this.handleWSClose.bind(this);
    this.handleWSSubmit = this.handleWSSubmit.bind(this);

    //  update case
    this.handleSShow = this.handleSShow.bind(this);
    this.handleSClose = this.handleSClose.bind(this);
    this.handleSSubmit = this.handleSSubmit.bind(this);
    //  delete case
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteCase = this.deleteCase.bind(this);
    this.witnessHeadingRef = React.createRef();

    // go back page
    this.goBack = this.goBack.bind(this);

    this.editRef = React.createRef();
    this.createRef = React.createRef();
  }
  handleEShow = () => {
    this.setState({ eShow: true, wSShow: false }, () => {});
  };
  handleEClose = () => this.setState({ eShow: false });
  handleECaseShow = () => {
    this.setState({ eCaseShow: true, sShow: false }, () => {});
  };
  handleECaseClose = () => this.setState({ eCaseShow: false });

  handleImportant = (ev, cases, witness, important, index) => {
    // console.log("important: ", important)
    // console.log("witness: ", witness)
    ev.stopPropagation();
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/witness', {
          case: this.props.case._id,
          witness: witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          // console.log("mark witness important: ", user.data)
          if (witness.status == 'todo') {
            const data = [...this.state.witnessListTodo];
            data[index].important = user.data.important;
            this.setState({ data });
          } else if (witness.status == 'doing') {
            const data = [...this.state.witnessListDoing];
            data[index].important = user.data.important;
            this.setState({ data });
          } else {
            const data = [...this.state.witnessListDone];
            data[index].important = user.data.important;
            this.setState({ data });
          }
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };
  handleProgress = () => {
    let p = 0;
    let num = 0;
    let v = 0;
    let amount = 0;
    const witness = this.props.case.witnesses;

    if (witness && witness.length > 0) {
      witness.forEach((n) => {
        let empty = num;
        let prog = v;
        if (n.topics && n.topics.length > 0) {
          for (let i of n.topics) {
            if (i.topic.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.statements && n.statements.length > 0) {
          for (let i of n.statements) {
            if (i.statement.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.facts && n.facts.length > 0) {
          for (let i of n.facts) {
            if (i.fact.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.financial != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.relationship != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.hostility != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.impairment_alcohol_or_drugs != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.physical_deficiency != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .focus != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .witness_preoccupied != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .m_quantity_and_distance_not_reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .desire_to_find_meaning != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_see != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_hear != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .impact_of_sudden_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .insignificance_of_event != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .ability_to_observe != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .expose_belief_from_habit != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.forget_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.flawed != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.experience != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.collateral != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.influence != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent.accuse !=
            ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .confront != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.implausible
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.contradicted
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.record &&
          n.checklist.credibility.record != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.evidence &&
          n.checklist.credibility.evidence != ''
        ) {
          v += 1;
        }
        //
        if (n.focus.name && n.focus.name.value != '') {
          v += 1;
        }
        if (n.focus.relationship && n.focus.relationship.value != '') {
          v += 1;
        }
        if (n.focus.goals && n.focus.goals.value != '') {
          v += 1;
        }
        if (n.focus.open && n.focus.open.value != '') {
          v += 1;
        }
        if (n.focus.closing && n.focus.closing.value != '') {
          v += 1;
        }
        if (n.focus.bias && n.focus.bias.value != '') {
          v += 1;
        }
        if (n.focus.fact && n.focus.fact.value != '') {
          v += 1;
        }
        // if (n.focus.tops && n.focus.tops.length > 0) {
        if (1 == 1) {
          v += 1;
        }
        if (n.focus.docs && n.focus.docs.length > 0) {
          v += 1;
        }
        if (n.focus.keywords && n.focus.keywords.value != '') {
          v += 1;
        }
        if (n.focus.strength && n.focus.strength.value != '') {
          v += 1;
        }
        if (n.focus.weaknesses && n.focus.weaknesses.value != '') {
          v += 1;
        }
        if (n.focus.examination_tech && n.focus.examination_tech.value != '') {
          v += 1;
        }
        if (n.focus.objections && n.focus.objections.value != '') {
          v += 1;
        }
        if (n.focus.opposition_adv && n.focus.opposition_adv.value != '') {
          v += 1;
        }
        if (n.prepchart && n.prepchart.length > 0) {
          v += 1;
        }

        n.progress = (100 / (num - empty + 39)) * (v - prog);
      });
    } else {
      num = 1;
    }

    num += 38 * (witness ? witness.length : 0);

    amount = 100 / num;

    // console.log(amount);
    p = amount * v;
    // console.log(p);
    if (p != this.state.progress) this.setState({ progress: p });
  };

  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e, ev) => {
    ev.stopPropagation();
    this.setState({ dShow: true, deleted: e });
  };

  handleSSubmit = (s, data) => {
    data.preventDefault();
    const cases = {
      name: s.name,
      client: s.client,
      firm: this.state.firm,
      user: this.state.use,
      updating: this.state.updating,
    };
    if (
      this.state.bUpdate.client != cases.client ||
      this.state.bUpdate.name != cases.name
    ) {
      if (
        cases.name != '' &&
        cases.client != '' &&
        cases.name != undefined &&
        cases.client != undefined &&
        cases.firm != ''
      ) {
        axios
          .post(prod() + '/api/cases/update', {
            name: cases.name,
            client: cases.client,
            updating: this.state.updating,
          })
          .then((use) => {
            console.log("updated case: ", use.data)
            this.setState({ case: use.data })
            axios
              .get(prod() + '/api/users/current')
              .then((s) => {
                axios
                  .post(prod() + '/api/cases/getall', {
                    firm: s.data.firm,
                  })
                  .then((use) => {
                    this.setState({
                      sShow: false,
                      uClient: '',
                      uName: '',
                    });
                  })
                  .catch((err) => {
                    console.log('MSG in err', err);
                  });
              })
              .catch((err) => {
                console.log('MSG in err', err);
              });
          })
          .catch((e) =>
            this.setState({
              user: 'Could not find case',
            })
          );
      } else {
        this.setState({
          userU: 'Please fill out both case name and client name fields',
        });
      }
    } else {
      this.setState({
        dShow: false,
        sShow: false,
        updating: '',
      });
    }
  };
  deleteCase = () => {
    axios.post(prod() + '/api/documents/delete/case', {
      case: this.props.case._id,
    });
    axios
      .post(prod() + '/api/cases/delete', {
        id: this.state.deleted,
        firm: this.props.firm,
      })
      .then((use) => {
        axios
          .get(prod() + '/api/users/current')

          .then((use) => {
            this.props.changeLocation({
              loc: '/',
              case: {},
              caseN: '',
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      })
      .catch((e) => console.log(e));
  };
  //  case
  handleSClose = () =>
    this.setState({
      sShow: false,
      userU: '',
      uClient: '',
      uName: '',
    });

  handleSShow = (e, ev) => {
    ev.stopPropagation();

    axios
      .post(prod() + '/api/cases/get', { case: e })
      .then((use) => {
        this.setState(
          {
            sShow: true,
            updating: e,
            uClient: use.data.client,
            uName: use.data.name,
            bUpdate: {
              client: use.data.client,
              name: use.data.name,
            },
          },
          () => this.witnessHeadingRef.current.focus()
        );
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  };
  //

  componentWillReceiveProps(newProps) {
    // console.log(newProps.case);

    if (newProps.case != this.props.case) {
      this.orderWitnessesByStatus(newProps);
    }
  }

  orderWitnessesByStatus = (props) => {
    let todo = [],
      doing = [],
      done = [];
    if (props.case && props.case.witnesses) {
      props.case.witnesses.map((witness) => {
        switch (witness.status) {
          case 'todo':
            todo.push(witness);
            break;
          case 'doing':
            doing.push(witness);
            break;
          case 'done':
            done.push(witness);
            break;
        }
        // Add sorting to order index here
        todo = todo.sort((a, b) => a.orderIndex - b.orderIndex);
        doing = doing.sort((a, b) => a.orderIndex - b.orderIndex);
        done = done.sort((a, b) => a.orderIndex - b.orderIndex);

        this.setState(
          {
            case: props.case,
            witnessListTodo: todo,
            witnessListDoing: doing,
            witnessListDone: done,
          },
          () => {}
        );
      });
      // Add sorting to order index here
      todo = todo.sort((a, b) => a.orderIndex - b.orderIndex);
      doing = doing.sort((a, b) => a.orderIndex - b.orderIndex);
      done = done.sort((a, b) => a.orderIndex - b.orderIndex);

      this.setState(
        {
          case: props.case,
          witnessListTodo: todo,
          witnessListDoing: doing,
          witnessListDone: done,
        },
        () => {
          this.handleProgress();
        }
      );
    }
  };

  transformEvents = (events) => {
    const witnessMap = this.getWitnessMap();
    return events.reduce((prev, event) => {
      const individualEvents = event.events.map((e) => ({
        ...e,
        witnessId: event.witnessId,
        witnessName: witnessMap[event.witnessId],
      }));
      return [...prev, ...individualEvents];
    }, []);
  };

  getWitnessMap = () => {
    return (
      this.props?.case?.witnesses?.reduce(
        (prev, witness) => ({ ...prev, [witness._id]: witness.name }),
        {}
      ) || {}
    );
  };

  sortEvents = (events) => events.sort((a, b) => a.date.localeCompare(b.date));

  componentDidMount() {
    if (this.props.case != undefined && Object.keys(this.props.case) != 0) {
      this.orderWitnessesByStatus(this.props);
      this.props.updateCase();

      //getting timeline by witness to pass it to case export
      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            const response = res?.data?.events || [];
            const sortedEvents = this.sortEvents(response);
            eventsByWitness.push({
              [witness.name]: sortedEvents,
            });
          })
          .catch((e) => console.log("no timeline events yet"));
      }

      //getting timelines to pass it to case export
      axios
        .get(prod() + '/api/cases/timeline/all', {
          params: {
            id: this.props.case._id,
          },
        })
        .then((res) => {
          const response = this.transformEvents(res?.data?.events || []);
          const sortedEvents = this.sortEvents(response);

          this.setState({
            timelineByWitness: eventsByWitness,
            timelines: sortedEvents,
          });
        })
        .catch((e) => console.log("no timeline events yet"));
    } else {
      window.location.replace('/');
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.case !== this.props.case) {
      this.setState({
        case: this.props.case,
      });
      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            eventsByWitness.push({
              [witness.name]: res.data.events,
            });
          })
          .catch(e => console.log("no timeline events yet"))
      }
      this.setState({ timelineByWitness: eventsByWitness });

      //getting timelines to pass it to case export
      axios
        .get(prod() + '/api/cases/timeline/all', {
          params: {
            id: this.props.case._id,
          },
        })
        .then((res) => {
          const response = this.transformEvents(res?.data?.events || []);
          const sortedEvents = this.sortEvents(response);
          this.setState({
            timelines: sortedEvents,
          });
        })
        .catch((e) => console.log("no timeline events yet"));
    }
  }

  handleWSSubmit(s, e) {
    console.log("called")
    e.preventDefault();

    if (s.name != '' && s.name != undefined) {
      if (s.name != this.state.old) {
        axios
          .post(prod() + '/api/cases/update/witness', {
            name: s.name,
            witness: this.state.updating,
            case: this.props.case._id,
          })
          .then((user) => {
            this.setState({
              wSShow: false,
              errors: undefined,
              old: s.name,
            });
            this.props.updateCase();
          })
          .catch((err) => {
            this.setState({
              errors: 'Could Not Update Witness',
            });
          });
      } else {
        this.setState({
          errors: 'New name is the same as old.',
        });
      }
    } else {
      this.setState({
        errors: 'Please Enter The Witness Name.',
      });
    }
  }
  handleWSubmit(enteredWitnesses, e) {
    e.preventDefault();
    const witnesses = {
      names: enteredWitnesses,
      user: this.state.use,
      case: this.state.case._id,
      orderIndex: this.state.witnessListTodo.length,
    };

    axios
      .post(prod() + '/api/cases/create/witnesses', witnesses)
      .then((use) => {
        this.setState(
          {
            wShow: false,
          },
          () => {
            // console.log(use.data);
            this.props.updateCase();
          }
        );
      })
      .catch((err) => {
        this.setState({
          user: 'Case already exist',
        });
      });
  }
  handleWSClose = () => this.setState({ wSShow: false });
  handleWSShow = (e, ev) => {
    ev.stopPropagation();
    this.setState(
      {
        wSShow: true,
        uWName: e.name,
        updating: e['_id'],
        old: e['name'],
        witness: e,
      },
      () => this.editRef.current.focus()
    );
  };

  handleWClose = () => this.setState({ wShow: false });
  handleWShow = () =>
    this.setState({ wShow: true }, () => this.createRef?.current?.focus());

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }
  
  deleteHighlights(rowIds) {
    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: rowIds,
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })
        axios
          .post(prod() + '/api/file/highlights/documents/deleteAll', { rowIds })
          .then((res) => console.log(res.data))
          .catch(e => console.log(e));
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }

  deleteWitness(a) {
    this.deleteDocs(this.state.deleted);
    this.deleteTimelines(this.state.deleted);
    //delete highlights and remove image highlights from gcs
    let rowIds = [];
    const witness = this.props.case.witnesses.find(w => w._id === this.state.deleted)
    witness.statements.forEach(s => {
      s.statement.forEach(row => {
        if(row.ref.document) rowIds.push(row._id);
      });
    });
    witness.facts.forEach(f => {
      f.fact.forEach(row => {
        if(row.ref.document) rowIds.push(row._id);
      });
    });
    witness.topics.forEach(t => {
      t.topic.forEach(row => {
        if(row.ref.document) rowIds.push(row._id);
      });
    });
    console.log("rowIds: ", rowIds)
    this.deleteHighlights(rowIds)

    axios.post(prod() + '/api/documents/delete/witness', {
      case: this.props.case._id,
      witness: this.state.deleted,
    });
    axios
      .post(prod() + '/api/cases/delete/witness', {
        case: this.state.case._id,
        witness: this.state.deleted,
        item: this.state.item,
      })
      .then((use) => {
        this.setState({
          wDShow: false,
        });
        this.props.updateCase();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  deleteTimelines = (deletedWitnessId) => {
    axios
      .post(prod() + '/api/cases/timeline/deleteAll', {
        caseId: this.props.case._id,
        witnessId: deletedWitnessId,
      })
      .then(res => {
        console.log(res.data);
      })
      .catch(e => console.log(e));
  }

  deleteDocs = (deletedWitnessId) => {
    axios
      .post(prod() + '/api/documents/getall', {
        case: this.props.case._id,
        witness: deletedWitnessId,
      })
      .then((a) => {
        // console.log("documents: ", a)
        const documents = a.data.documents;
        for (const doc of documents) {
          if (doc.type === 'file') {
            //GC storage bucket names are different for prod and development
            const link = doc.link;
            const indexOfTerm = link.indexOf('storage.googleapis.com');
            const slicedLink = link.slice(indexOfTerm);
            const gcFileName = slicedLink.slice(23);

            axios.post(prod() + '/api/cases/gcs/deleteFile', {
              filename: gcFileName,
            });
          }
        }
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleWDShow = (e, item, ev) => {
    ev.stopPropagation();

    this.setState({ wDShow: true, deleted: e, item: item });
  };
  handleWDClose = () => {
    this.setState({ wDShow: false });
  };
  getT(a, i) {
    localStorage.removeItem('witness');
    localStorage.removeItem('topic');
    localStorage.removeItem('q');
    localStorage.removeItem('case');

    this.props.changeLocationW({
      loc: '/case/witness/topics',
      witness: a,
      caseN: i,
      case: this.props.case,
    });
  }

  onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      let listName = source.droppableId;
      const newList = this.reorder(
        this.state[listName],
        source.index,
        destination.index,
        source,
        destination
      );
      this.setState({ [listName]: newList });
    } else {
      let sourceList = source.droppableId;
      let destinationList = destination.droppableId;
      let status = '';
      switch (destination.droppableId) {
        case 'witnessListTodo':
          status = 'todo';
          break;
        case 'witnessListDoing':
          status = 'doing';
          break;
        default:
          status = 'done';
      }
      this.move(sourceList, destinationList, source, destination);
      const witnessId = this.state[sourceList][source.index]._id;
      axios
        .post(prod() + '/api/cases/witness/updateStatus', {
          caseId: this.state.case._id,
          status: status,
          witnessId,
        })
        .then((res) => {
          // console.log("after changing status: ", res.data.witnesses);
          const witnessObj = res.data.witnesses.find(w => w._id === witnessId);
          if (status == 'todo') {
            const index = this.state.witnessListTodo.findIndex(w => w._id === witnessId);
            const data = [...this.state.witnessListTodo];
            data.splice(index, 1, witnessObj);
            this.setState({ witnessListTodo: data });
          } else if (status == 'doing') {
            const index = this.state.witnessListDoing.findIndex(w => w._id === witnessId);
            const data = [...this.state.witnessListDoing];
            data.splice(index, 1, witnessObj);
            this.setState({ witnessListDoing: data });
          } else {
            const index = this.state.witnessListDone.findIndex(w => w._id === witnessId);
            const data = [...this.state.witnessListDone];
            data.splice(index, 1, witnessObj);
            this.setState({ witnessListDone: data });
          }
        })
        .catch((error) => {
          console.log('Error while changing status');
          console.log(error);
          return;
        });
    }
  };

  reorder = (list, startIndex, endIndex, source, destination) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    // Update the index in the backend
    axios
      .post(prod() + '/api/cases/witness/updateOrderIndex', {
        caseId: this.state.case._id,
        orderIndex: endIndex,
        status: result[endIndex].status,
        witnessId: result[endIndex]._id,
        newStatus: destination.droppableId,
        oldStatus: source.droppableId,
        oldIndex: endIndex,
        newIndex: startIndex,
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(this.state[source]);
    const destClone = Array.from(this.state[destination]);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    this.setState({
      [source]: sourceClone,
      [destination]: destClone,
    });

    axios
      .post(prod() + '/api/cases/witness/updateOrderIndex', {
        caseId: this.state.case._id,
        orderIndex: droppableDestination.index,
        status: removed.status,
        witnessId: removed._id,
        newStatus: droppableSource.droppableId,
        oldStatus: droppableDestination.droppableId,
        oldIndex: droppableDestination.index,
        newIndex: droppableSource.index,
      })
      .then((response) => {
        console.log("here")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  grid = 8;

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: '8px 4px',
    paddingTop: '0',
    margin: `0 0 ${this.grid}px 0`,

    background: this.props.darkMode
      ? isDragging
        ? '#202224'
        : '#424242'
      : isDragging
      ? 'rgb(234, 239, 244)'
      : 'white',

    ...draggableStyle,
  });

  getListStyle = (isDraggingOver) => ({
    background: this.props.darkMode
      ? isDraggingOver
        ? '#202224 !important'
        : '#202224 !important'
      : isDraggingOver
      ? '#f9f9f9 !important'
      : '#f9f9f9 !important',
    padding: this.grid,
    minHeight: '90px',
  });

  getIndividualWitness = (witness, i) => {
    return (
      <div className='w'>
        <div className='case-btn-side case-btn-side-w'>
          <div className='w-home-2'>
            <ButtonToolbar className='side-nav-t-overide'>
              <Button className='add-case'>
                <img
                  onClick={(e) => this.handleWDShow(witness['_id'], witness, e)}
                  className='logo-title logo-title-2'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                ></img>{' '}
              </Button>
            </ButtonToolbar>
          </div>
          <div className='w-home-2'>
            <ButtonToolbar className='side-nav-t-overide'>
              <Button className='add-case'>
                <img
                  onClick={(e) => this.handleWSShow(witness, e)}
                  className='logo-title'
                  src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                ></img>
              </Button>
            </ButtonToolbar>
          </div>
          {/* {witness.comment ? (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <Button
                  className='add-case'
                  onClick={(e) =>
                    this.handleImportant(e, this.state.case, witness, false, i)
                  }
                >
                  <img
                    className='logo-title '
                    src={require('../../../pics/app/filled_comment_grey.svg')}
                  ></img>
                </Button>
              </ButtonToolbar>
            </div>
          ) : (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <Button
                  className=' add-case'
                  onClick={(e) =>
                    this.handleImportant(e, this.state.case, witness, true, i)
                  }
                >
                  <img
                    className='logo-title card_important_comment'
                    src={require('../../../pics/app/comment_grey.svg')}
                  ></img>
                </Button>
              </ButtonToolbar>
            </div>
          )} */}
          {witness.important ? (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Unmark as Important
                    </Tooltip>
                  }
                >
                  <Button
                    className=' add-case'
                    onClick={(e) =>
                      this.handleImportant(
                        e,
                        this.state.case,
                        witness,
                        false,
                        i
                      )
                    }
                  >
                    <img
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642356/Aleri/app/filled_star_grey_fzinfp.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          ) : (
            <div className='  w-home-2'>
              <ButtonToolbar className=' side-nav-t-overide'>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Mark as Important
                    </Tooltip>
                  }
                >
                  <Button
                    className=' add-case'
                    onClick={(e) =>
                      this.handleImportant(e, this.state.case, witness, true, i)
                    }
                  >
                    <img
                      className='logo-title card_important_comment'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642356/Aleri/app/star_grey_ixp4e2.svg'
                    ></img>
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          )}

          <ProgressBar now={Math.round(witness.progress)} />
        </div>
        {/* <br /> */}
        <div className='case-btn-side-t'>
          <p className='parent-p'>{witness.name}</p>
          <p className='parent-p-d'>{witness.client}</p>
          {/* <div className='case-type'>
            <p>Criminal</p>
          </div> */}
        </div>
      </div>
    );
  };


  getDnDWitness = () => {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className='case-drag-single-list'>
          <h4>To Do</h4>
          {isSafari ? (
            <Droppable 
              droppableId='witnessListTodo'
              renderClone={(provided, snapshot, rubric) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <p style={{ textAlign: 'center'}}>Drag And Drop The Witness To The Correct List</p>
                </div>
              )}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListTodo.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ): (
            <Droppable 
              droppableId='witnessListTodo'
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListTodo.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
        <div className='case-drag-single-list'>
          <h4>Doing</h4>
          {isSafari? (
            <Droppable 
              droppableId='witnessListDoing'
              renderClone={(provided, snapshot, rubric) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <p style={{ textAlign: 'center'}}>Drag And Drop The Witness To The Correct List</p>
                </div>
              )}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListDoing.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ): (
            <Droppable 
              droppableId='witnessListDoing'
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListDoing.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </div>
        <div className='case-drag-single-list'>
          <h4>Done</h4>
          {isSafari ? (
            <Droppable 
              droppableId='witnessListDone'
              renderClone={(provided, snapshot, rubric) => (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                >
                  <p style={{ textAlign: 'center'}}>Drag And Drop The Witness To The Correct List</p>
                </div>
              )}
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListDone.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ): (
            <Droppable 
              droppableId='witnessListDone'
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={this.getListStyle(snapshot.isDraggingOver)}
                >
                  {this.state.witnessListDone.map((item, index) => (
                    <Draggable
                      key={item._id}
                      draggableId={item._id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={this.getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className='parent-side-nav unfocus'
                          onMouseDown={(e) => e.currentTarget.focus()}
                          onClick={() => this.getT(item, index)}
                        >
                          {this.getIndividualWitness(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

          )}
        </div>
      </DragDropContext>
    );
  };

  goBack() {
    if (this.props?.history?.location.pathname === '/case') {
      this.props.goHome();
    } else {
      this.props.goCase();
    }
  }

  render() {
    return (
      <>
        <Icons
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          changeLocation={this.props.changeLocation}
          getWitness={this.props.getWitness}
          cases={this.props.cases}
          setNavExpanded={this.props.setNavExpanded}
          goInfo={this.props.goInfo}
          goSettings={this.props.goSettings}
          goActivity={this.props.goActivity}
          goHome={this.props.goHome}
          loadOut={this.props.loadOut}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.props.goAudioTranscription}
        />

        <div className='expanded'>
          <div id='side-nav-open'>
            <div
              className='side-nav-open-title side-nav-open-title-w'
              ref={this.witnessHeadingRef}
            >
              <Row>
                <Col md={12}>
                  <Fade>
                    <h5 id='case'>
                      {this.props.case && this.props.case.witnesses
                        ? this.props.case.witnesses.length
                        : 0}{' '}
                      {this.props.case && this.props.case.witnesses
                        ? this.props.case.witnesses == 1
                          ? 'Witness'
                          : 'Witnesses'
                        : 'Witnesses'}
                    </h5>
                  </Fade>
                </Col>
              </Row>

              {/* <br /> */}
              <div>
                <Fade>
                  <div className='w-buttons'>
                    <Row>
                      <Col md={4}>
                        {' '}
                        <div className='add-btn-plus add-btn-plus1'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Case Dashboard
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={this.goBack}
                                  className='add-case'
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/left-arrow_w86mdw.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus2'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Settings
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className='add-case'
                                  onClick={(e) =>
                                    this.handleSShow(this.state.case['_id'], e)
                                  }
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus3'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={(e) =>
                                    this.handleDShow(this.state.case['_id'], e)
                                  }
                                  className='add-case '
                                >
                                  <img
                                    className='logo-title logo-title-l'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                                  ></img>
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 10 }}>
                        <ProgressBar now={this.state.progress} />
                      </Col>
                    </Row>
                  </div>
                </Fade>

                <Fade>
                  <h6 className='w-name'>
                    <span>Case: </span>
                    {this.state.case ? this.state.case.name : ''}
                  </h6>
                  <h6 className='w-name'>
                    <span>Client:</span>{' '}
                    {this.state.case ? this.state.case.client : ''}
                  </h6>
                </Fade>
              </div>
            </div>
            <Fade>
              <Button
                onClick={this.handleWShow}
                className='add-case-btn '
                block
              >
                Add Witness
              </Button>
            </Fade>
            <Fade>{this.getDnDWitness()}</Fade>

            <DashboardModal
              show={this.state.show}
              handleClose={this.handleClose}
              handleSubmit={this.handleSubmit}
              dShow={this.state.dShow}
              handleDClose={this.handleDClose}
              handleDSubmit={this.handleDSubmit}
              sShow={this.state.sShow}
              handleSClose={this.handleSClose}
              handleSSubmit={this.handleSSubmit}
              user={this.state.user}
              err={this.state.err}
              uName={this.state.uName}
              uClient={this.state.uClient}
              deleteCase={this.deleteCase}
              darkMode={this.props.darkMode}
              handleEShow={this.handleECaseShow}
              handleEClose={this.handleECaseClose}
              eShow={this.state.eCaseShow}
              case={this.state.case}
              edit={this.witnessHeadingRef}
              //export
              witness={this.state.witness}
              timelineByWitness={this.state.timelineByWitness}
              timelines={this.state.timelines}
            />
            {/* Not the Case export in Case Dashboard */}
            <CaseModal
              wShow={this.state.wShow}
              wSShow={this.state.wSShow}
              handleWClose={this.handleWClose}
              handleWSubmit={this.handleWSubmit}
              wDShow={this.state.wDShow}
              handleWDClose={this.handleWDClose}
              handleWDSubmit={this.handleWDSubmit}
              sWShow={this.state.sWShow}
              handleWSClose={this.handleWSClose}
              handleWSSubmit={this.handleWSSubmit}
              user={this.state.user}
              err={this.state.err}
              uName={this.state.uName}
              uClient={this.state.uClient}
              deleteWitness={this.deleteWitness}
              //  update wit
              uWName={this.state.uWName}
              create={this.createRef}
              edit={this.editRef}
              darkMode={this.props.darkMode}
              handleEShow={this.handleEShow}
              handleEClose={this.handleEClose}
              eShow={this.state.eShow}
              //export
              witness={this.state.witness}
              case={this.state.case}
              timelineByWitness={this.state.timelineByWitness}
              timelines={this.state.timelines}
            />
          </div>
        </div>
        <ToggleNav setNavExpanded={this.props.setNavExpanded} />
      </>
    );
  }
}
