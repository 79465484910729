//components
import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import store from '../store';
import CommunityLogin from './Unauthenticated/Auth/CommunityLogin';
//auth
import jwt_decode from 'jwt-decode';
import setAuthToken from '../utils/setAuthToken';
import { setCurrentUser, logoutUser } from '../actions/authActions';
import { sessionExpire } from './Shared/sessionExpire';
import { trialEnd } from './Shared/trialEnd';
import moment from 'moment';

//assets
// import logo from './../logo.svg';
import './../App.css';
import '../styles/suggestion.css';
import '../styles/dark.css';
// import '../styles/drift.css';
import '../styles/search.css';
import '../styles/documentUploading.css';
import '../styles/important.css';
import '../styles/landing.css';
import '../styles/footer.css';
import '../styles/landing-form.css';
import '../styles/progress.css';
import '../styles/app-landing.css';
import '../styles/reference.css';
//custom components
import Navigation from './Navigation';
import Layout from './Authenticated/Layout';
import Landing from './Unauthenticated/Landing';

// Loading
import Loading from '../Components/Shared/loading';
import LoggingOut from '../Components/Shared/LoggingOut';

// history
import history from '../utils/history';
import axios from 'axios';
import prod from '../prod';

import { isMobile } from 'react-device-detect';

import SupportedBrowsers from './Chrome';

if (
  localStorage.jwtToken !== 'undefined' &&
  localStorage.jwtToken !== undefined &&
  localStorage.jwtToken !== ''
) {
  if (window.location.hostname == 'aleri.ca') {
    // ReactGA.initialize('UA-160714583-1');
    // ReactGA.pageview('auth' + window.location.pathname.toString());
  } else {
  }
  //set auth token
  setAuthToken(localStorage.jwtToken);
  //decode token
  const decoded = jwt_decode(localStorage.jwtToken);
  // set isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  //check for expired token
  const currentTime = Date.now() / 1000;
  //logout user

  if (decoded.exp < currentTime) {
    //clear all state
    store.dispatch(logoutUser);
    //redirect user to home

    window.location.href = '/';
    localStorage.clear();
  }
}
if (window.location.hostname == 'app.aleri.ca') {
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];

        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.13.1';
      analytics.load('ZNCuz4R5JzYqaOLDIELirdua9Gp4M9JV');
      // analytics.page();
    }
} else {
  // dev

  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.');
    else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          var t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (var e = 0; e < analytics.methods.length; e++) {
        var key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        var t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src =
          'https://cdn.segment.com/analytics.js/v1/' +
          key +
          '/analytics.min.js';
        var n = document.getElementsByTagName('script')[0];

        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = '4.13.1';
      analytics.load('ljWuOBhYT6XrEVtZtQiHxneTutHQVNur');
      // analytics.page();
    }
}
class App extends Component {
  constructor() {
    super();
    this.state = {
      auth:
        localStorage.jwtToken !== 'undefined' &&
        localStorage.jwtToken !== undefined &&
        localStorage.jwtToken !== ''
          ? true
          : false,
      color: 'black',
      open: true,
      clicked: 0,
      clicked2: 0,
      witness: {},
      loading: false,
      change: false,
      topic: {},
      split: false,
      dark: false,
      loc: '/',
      case: {},
      cases: [],
      witness: {},
      caseN: undefined,
      witnessN: undefined,
      fact: '',
      statement: '',

      openSessionExpireModal: false,
      trialEndModal: false,
    };
    this.openSideMenu = this.openSideMenu.bind(this);
    this.clicked = this.clicked.bind(this);
    this.clicked2 = this.clicked2.bind(this);
    this.getWitness = this.getWitness.bind(this);
    this.getTopic = this.getTopic.bind(this);
    this.load = this.load.bind(this);
    this.unload = this.unload.bind(this);
    this.loadOut = this.loadOut.bind(this);
    this.unloadOut = this.unloadOut.bind(this);
    this.changeSideMenu = this.changeSideMenu.bind(this);
    this.getSingleTopic = this.getSingleTopic.bind(this);
    this.getSingleFact = this.getSingleFact.bind(this);
    this.getSingleSummary = this.getSingleSummary.bind(this);
    this.getDoc = this.getDoc.bind(this);
    this.split = this.split.bind(this);
    this.toggleSplit = this.toggleSplit.bind(this);
    this.updateFromFocus = this.updateFromFocus.bind(this);
    this.changeLocation = this.changeLocation.bind(this);
    this.updateCase = this.updateCase.bind(this);
    // this.openModal = this.openModal.bind(this);
  }
  updateCase = (cases) => {
    this.setState(
      {
        case: cases,
        clicked: (this.state.clicked += 1),
        clicked2: (this.state.clicked2 += 1),
      },
      () => {
        // console.log('updatedState', this.state.case);
      }
    );
  };
  handleTopics = (item) => {
    this.setState(({ witness }) => ({
      witness: {
        ...witness,
        topics: item,
      },
    }));
  };
  changeLocation = (e, cases, witness, topic) => {
    this.setState({
      loc: e,
      case: cases,
      witness: witness,
      clicked2: this.state.clicked2,
      topic: topic,
    });
  };
  updateFromFocus(cases, witness) {
    this.setState({
      case: cases,
      witness: witness,
    });
  }
  split(a) {
    this.setState({
      split: a,
    });
  }
  toggleSplit() {
    this.setState({
      split: !this.state.split,
    });
  }
  getDoc(item) {
    // console.log("get doc item: ", item)

    if (item.path === '/case') {
      history.push('/case');

      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case',
      });
    } else if (item.path == '/case/witness/topics') {
      history.push('/case/witness/topics');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/witness/topics',
      });
    } else if (item.path === '/case/witness/topic') {
      history.push('/case/witness/topic');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/witness/topic',
      });
    } else if (item.path === '/case/witness/prep') {
      history.push('/case/witness/prep');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
      });
    } else if (item.path === '/case/witness/focus') {
      history.push('/case/witness/focus');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
      });
    } else if (item.path === '/case/witness/checklist') {
      history.push('/case/witness/checklist');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        topic: item.topicId,
        clicked2: this.state.clicked2,
      });
    }
    //Information strategy
    else if (item.path === '/information/impeachment') {
      this.setState(
        {
          case: item.caseId,
          witness: item.witnessId,
          topic: item.topicId,
          clicked2: this.state.clicked2,
          loc: '/information',
        },
        () => {
          history.push('/information/impeachment');
        }
      );
    } else if (item.path === '/information/framing') {
      this.setState(
        {
          case: item.caseId,
          witness: item.witnessId,
          topic: item.topicId,
          clicked2: this.state.clicked2,
          loc: '/information',
        },
        () => {
          history.push('/information/framing');
        }
      );
    } else if (item.path === '/information/exposing') {
      this.setState(
        {
          case: item.caseId,
          witness: item.witnessId,
          topic: item.topicId,
          clicked2: this.state.clicked2,
          loc: '/information',
        },
        () => {
          history.push('/information/exposing');
        }
      );
    } else if (item.path === '/information/credibility') {
      this.setState(
        {
          case: item.caseId,
          witness: item.witnessId,
          topic: item.topicId,
          clicked2: this.state.clicked2,
          loc: '/information',
        },
        () => {
          history.push('/information/credibility');
        }
      );
    } else if (item.path === '/case/witness/fact') {
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        fact: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/witness/fact',
      });
      // console.log("Get doc get fact called!!")
      // console.log("NEW FACT STATE: ", this.state.fact)
      history.push('/case/witness/fact');
    } else if (item.path === '/case/witness/summary') {
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        statement: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/witness/summary',
      });
      history.push('/case/witness/summary');
    } else if (item.path === '/case/witness/timeline') {
      history.push('/case/witness/timeline');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        statement: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/witness/timeline',
      });
    } else if (item.path === '/case/timeline') {
      history.push('/case/timeline');
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        statement: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/timeline',
      });
    } else if (
      item.path ===
      `/case/witness/file/${item.topicId}/${item.docId}/${item.topic}`
    ) {
      history.push(
        `/case/witness/file/${item.topicId}/${item.docId}/${item.topic}`
      );
      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        statement: item.topicId,
        clicked2: this.state.clicked2,
        loc: `/case/witness/file/${item.topicId}/${item.docId}/${item.topic}`,
      });
    } else if (item.path === '/case/tags') {
      history.push('/case/tags');

      this.setState({
        case: item.caseId,
        witness: item.witnessId,
        statement: item.topicId,
        clicked2: this.state.clicked2,
        loc: '/case/tags',
      });
    }
  }
  getSingleTopic(a) {
    this.setState({
      case: this.state.case,
      witness: this.state.witness,
      topic: a,
      loc: '/case/witness/topic',
    });
  }
  getSingleSummary(a) {
    this.setState({
      case: this.state.case,
      witness: this.state.witness,
      statement: a,
      loc: '/case/witness/summary',
    });
  }
  getSingleFact(a, witness) {
    // console.log("getsinglefact: a, witness : ", a, witness)
    this.setState({
      case: this.state.case,
      witness: witness ? witness : this.state.witness,
      fact: a,
      loc: '/case/witness/fact',
    });
  }
  getWitness(a, b) {
    if (a !== undefined || a != {} || a != null) {
      this.setState({ case: a, loc: b.loc, caseN: b.caseN });
    }
  }

  updateWitness = (cases, witness) => {
    // console.log(cases, witness);
    this.setState({
      clicked: (this.state.clicked += 1),
      clicked2: (this.state.clicked2 += 1),
      witness: witness,
      case: cases,
    });
  };
  changeSideMenu() {
    window.location = '/';
  }
  getTopic(a) {
    if (a !== undefined || a != {} || a != null) {
      this.setState({ witness: a });
    }
  }
  load() {
    this.setState(
      {
        loading: true,
      },
      () => {
        setTimeout(() => history.push('/'), 2500);
      }
    );
  }
  unload() {
    if (this.state.loading == true) {
      this.setState({ loading: false }, () => window.location.reload());
    }
  }
  loadOut() {
    localStorage.clear();

    this.setState(
      {
        loadingOut: true,
        auth: false,
      },
      () => {
        setTimeout(() => history.push('/'), 2500);
        this.unloadOut();
      }
    );
  }
  unloadOut() {
    if (this.state.loadingOut === true) {
      setTimeout(() => {
        this.setState({ loadingOut: false });
      }, 1500);
    }
  }

  openSideMenu() {
    if (this.state.open === true) {
      this.setState({ open: false });
    } else {
      this.setState({ open: true });
    }
  }

  clicked() {
    this.setState({
      clicked: this.state.clicked + 1,
    });
  }
  clicked2() {
    this.setState({
      clicked2: this.state.clicked2 + 1,
    });
  }

  async componentDidMount() {
    console.log('App component did mount');
    window.scrollTo(0, 0);
    if (
      localStorage.jwtToken !== 'undefined' &&
      localStorage.jwtToken !== undefined &&
      localStorage.jwtToken !== ''
    ) {
      try {
        const res = await axios.get(prod() + '/api/users/current');

        trialEnd(res.data.firm).then((res) => {
          if (res) {
            this.setState({ trialEndModal: true });
          }
        });
        console.log('trial end return', trialEnd(res.data.firm));

        this.setState(
          {
            dark: res.data.darkMode,
            currentUser: res.data,
          },
          (e) => console.log(this.state.currentUser)
        );
      } catch (error) {
        console.log('Error while Dark Mode', error);
      }

      // check if dark and set if true
    } else {
      if (this.state.dark === true) {
        this.setState({
          dark: false,
        });
      }
    }
  }

  componentWillUpdate(newProps) {
    console.log('App component will update');
    //session expire code
    if (
      localStorage.jwtToken !== 'undefined' &&
      localStorage.jwtToken !== undefined &&
      localStorage.jwtToken !== '' &&
      this.state.openSessionExpireModal === false
    ) {
      //set auth token
      setAuthToken(localStorage.jwtToken);
      //decode token
      const decoded = jwt_decode(localStorage.jwtToken);
      // set isAuthenticated
      store.dispatch(setCurrentUser(decoded));
      //check for expired token
      const currentTime = Date.now() / 1000;
      //logout user
      if (decoded.exp < currentTime) {
        this.setState({ openSessionExpireModal: true });
      }
    }

    if (
      localStorage.jwtToken !== 'undefined' &&
      localStorage.jwtToken !== undefined &&
      localStorage.jwtToken !== '' &&
      this.state.trialEndModal === false
    ) {
      axios.get(prod() + '/api/users/current').then((res) => {
        trialEnd(res.data.firm).then((response) => {
          if (response) {
            this.setState({ trialEndModal: true });
          }
        });
      });
    }
    //internet connection code
    // window.addEventListener('offline', () => alert('No Internet Connection Available'))
    // if(!navigator.onLine){
    //   alert('No Internet Connection Available');
    //  }

    if (
      localStorage.jwtToken !== 'undefined' &&
      localStorage.jwtToken !== undefined &&
      localStorage.jwtToken !== '' &&
      this.state.auth === false
    ) {
      this.setState(
        {
          auth: true,
        },
        async () => {
          try {
            const res = await axios.get(prod() + '/api/users/current');

            this.setState({
              dark: res.data.darkMode,
            });
          } catch (error) {
            console.log('Error while Dark Mode', error);
          }
        }
      );
    } else if (
      localStorage.jwtToken === 'undefined' ||
      localStorage.jwtToken === undefined ||
      localStorage.jwtToken === ''
    ) {
      if (this.state.dark == true) {
        this.setState(
          {
            dark: false,
          }
          // () => console.log('called', this.state)
        );
      }
    }
  }

  setDarkModeCallback = (value, others) => {
    this.setState(
      {
        dark: value,
      },
      async () => {
        try {
          const res = await axios.post(prod() + '/api/users/update', {
            first_name: others.first_name,
            last_name: others.last_name,
            email: others.email,
            oldEmail: others.oldEmail,
            number: others.number,
            darkMode: value,
            _id: others._id,
          });
        } catch (error) {
          console.log('Dark Mode Error', error);
        }
      }
    );
  };

  render() {
    // console.log(window.location.pathname);
    if (isMobile && this.state.auth === true) {
      return <SupportedBrowsers />;
    }
    const background =
      this.state.auth === true &&
      window.location.pathname != '/docs' &&
      window.location.pathname != '/season'
        ? ''
        : 'white';
    const dark =
      this.state.dark &&
      window.location.pathname != '/docs' &&
      window.location.pathname != '/season'
        ? 'dark'
        : '';

    let page = <></>;
    if (window.location.pathname == '/community/login') {
      page = (
        <CommunityLogin currentUser={this.state.currentUser} load={this.load} />
      );
    } else {
      page =
        this.state.auth === true &&
        window.location.pathname != '/docs' &&
        window.location.pathname != '/season' ? (
          <Layout
            clicked={this.clicked}
            clicked2={this.state.clicked2}
            open={this.state.open}
            toggleSideMenu={this.openSideMenu}
            getWitness={this.getWitness}
            getTopic={this.getTopic}
            getSingleTopic={this.getSingleTopic}
            getSingleFact={this.getSingleFact}
            getSingleSummary={this.getSingleSummary}
            case={this.state.case}
            witness={this.state.witness}
            topic={this.state.topic}
            fact={this.state.fact}
            unload={this.unload}
            loadOut={this.loadOut}
            changeSideMenu={this.changeSideMenu}
            getDoc={this.getDoc}
            split={this.split}
            splitState={this.state.split}
            toggleSplit={this.toggleSplit}
            setDarkMode={this.setDarkModeCallback}
            darkMode={this.state.dark}
            updateFromFocus={this.updateFromFocus}
            statement={this.state.statement}
            updateWitness={this.updateWitness}
            currentUser={this.state.currentUser}
            handleTopics={this.handleTopics}
            updateCase={this.updateCase}
          />
        ) : (
          <Landing load={this.load} unloadOut={this.unloadOut} />
        );
    }

    return (
      <div id={dark}>
        <Provider store={store}>
          <Router history={history}>
            <div className='App'>
              {this.state.loading && <Loading />}
              {this.state.loadingOut && <LoggingOut />}
              <div id={background}>
                <Navigation
                  clicked2={this.clicked2}
                  clicked={this.state.clicked}
                  clickedB={this.clicked}
                  toggleSideMenu={this.openSideMenu}
                  getWitness={this.getWitness}
                  getTopic={this.getTopic}
                  getT={this.getT}
                  change={this.state.change}
                  changeSideMenu={this.changeSideMenu}
                  loadOut={this.loadOut}
                  getSingleTopic={this.getSingleTopic}
                  getSingleFact={this.getSingleFact}
                  getSingleSummary={this.getSingleSummary}
                  topic={this.state.topic}
                  fact={this.state.fact}
                  darkMode={this.state.dark}
                  open={this.state.open}
                  getDoc={this.getDoc}
                  changeLocation={this.changeLocation}
                  loc={this.state.loc}
                  cases={this.state.cases}
                  case={this.state.case}
                  witness={this.state.witness}
                  topic={this.state.topic}
                  caseN={this.state.caseN}
                  witnessN={this.state}
                  updateFromFocus={this.updateFromFocus}
                  statement={this.state.statement}
                  authentication={this.state.auth}
                  updateWitness={this.updateWitness}
                  currentUser={this.state.currentUser}
                />
                {/* <Drift appId='vh5bt7y2xkng' className='drift' /> */}
                {page}
              </div>
            </div>
          </Router>
        </Provider>

        {/* Session Timeout Modal */}
        <Modal
          dialogClassName={this.state.dark ? 'dark-modal' : ''}
          show={this.state.openSessionExpireModal}
        >
          <Modal.Header></Modal.Header>

          <Modal.Body>Session has expired. Please log in again.</Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => sessionExpire()}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Trial end Modal */}
        <Modal
          dialogClassName={this.state.dark ? 'dark-modal' : ''}
          show={this.state.trialEndModal}
        >
          <Modal.Header></Modal.Header>

          <Modal.Body>
            Membership has ended. Please choose a plan to continue using Aleri.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => {
                window.location.href = '/settings/payment';
                this.setState({ trialEndModal: false });
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default App;
