import React, { Component } from 'react';
import {
  Button,
  Row,
  Col,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ListGroup,
  ProgressBar,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../prod';
import CaseModal from './../Case/CaseModal';
import Fade from 'react-reveal/Fade';
import Slide from 'react-reveal/Slide';

import Icons from './../Navigation/Icons';
import ToggleNav from '../Navigation/ToggleNav';
import WitnessModules from './WitModules.js';
import WitModules from './WitModules.js';
export default class WitnessDashboard extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      case: {},
      witness: {},
      //  create case
      wShow: false,
      // delete witness
      wDShow: false,
      // update witness
      errors: '',

      wSShow: false,
      uWName: '',
      hShow: false,
      question: false,
      preparation: false,
      fact: false,
      summary: false,
      checklist: false,
      focus: false,
      eShow: false,
      timeline: false,

      //export
      timelineByWitness: [],
    };
    this.handleWShow = this.handleWShow.bind(this);
    this.handleWClose = this.handleWClose.bind(this);
    this.handleWDShow = this.handleWDShow.bind(this);
    this.handleWDClose = this.handleWDClose.bind(this);
    this.handleWSubmit = this.handleWSubmit.bind(this);
    this.deleteWitness = this.deleteWitness.bind(this);

    this.handleWSShow = this.handleWSShow.bind(this);
    this.handleWSClose = this.handleWSClose.bind(this);
    this.handleWSSubmit = this.handleWSSubmit.bind(this);
    this.getT = this.getT.bind(this);

    this.editRef = React.createRef();
    //
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);
  }
  handleEShow = () => {
    this.setState({ eShow: true, wSShow: false }, () => {});
  };
  handleEClose = () => this.setState({ eShow: false });
  handleProgress = () => {
    let p = 0;
    const n = this.state.witness;
    let num = 0;
    let v = 0;
    let amount = 0;
    if (n.topics && n.topics.length > 0) {
      for (let i of n.topics) {
        if (i.topic.length > 0) v += 1;
        num += 1;
      }
    } else {
      num += 1;
    }
    if (n.statements && n.statements.length > 0) {
      for (let i of n.statements) {
        if (i.statement.length > 0) v += 1;
        num += 1;
      }
    } else {
      num += 1;
    }
    if (n.facts && n.facts.length > 0) {
      for (let i of n.facts) {
        if (i.fact.length > 0) v += 1;
        num += 1;
      }
    } else {
      num += 1;
    }
    if (n.checklist) {
      if (
        n.checklist.reliability &&
        n.checklist.reliability.bias &&
        n.checklist.reliability.bias.financial != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.bias &&
        n.checklist.reliability.bias.relationship != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.bias &&
        n.checklist.reliability.bias.hostility != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.impairment_alcohol_or_drugs != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.physical_deficiency != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .focus != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .witness_preoccupied != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .m_quantity_and_distance_not_reliable != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .desire_to_find_meaning != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .establish_inability_to_see != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .establish_inability_to_hear != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .impact_of_sudden_events != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .insignificance_of_event != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .ability_to_observe != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
        n.checklist.reliability.lack_knowledge_of_events_faulty_perception
          .expose_belief_from_habit != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.reliability_of_memory &&
        n.checklist.reliability.reliability_of_memory.forget_events != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.reliability_of_memory &&
        n.checklist.reliability.reliability_of_memory.flawed != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.reliability_of_memory &&
        n.checklist.reliability.reliability_of_memory.experience != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.reliability_of_memory &&
        n.checklist.reliability.reliability_of_memory.collateral != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.reliability &&
        n.checklist.reliability.reliability_of_memory &&
        n.checklist.reliability.reliability_of_memory.influence != ''
      ) {
        v += 1;
      }

      if (
        n.checklist.plausibility_and_consistency &&
        n.checklist.plausibility_and_consistency.prior_inconsistent &&
        n.checklist.plausibility_and_consistency.prior_inconsistent.accuse != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.plausibility_and_consistency &&
        n.checklist.plausibility_and_consistency.prior_inconsistent &&
        n.checklist.plausibility_and_consistency.prior_inconsistent.reliable !=
          ''
      ) {
        v += 1;
      }

      if (
        n.checklist.plausibility_and_consistency &&
        n.checklist.plausibility_and_consistency.prior_inconsistent &&
        n.checklist.plausibility_and_consistency.prior_inconsistent.confront !=
          ''
      ) {
        v += 1;
      }

      if (
        n.checklist.plausibility_and_consistency &&
        n.checklist.plausibility_and_consistency.implausible
      ) {
        v += 1;
      }

      if (
        n.checklist.plausibility_and_consistency &&
        n.checklist.plausibility_and_consistency.contradicted
      ) {
        v += 1;
      }

      if (
        n.checklist.credibility &&
        n.checklist.credibility.record &&
        n.checklist.credibility.record != ''
      ) {
        v += 1;
      }
      if (
        n.checklist.credibility &&
        n.checklist.credibility.evidence &&
        n.checklist.credibility.evidence != ''
      ) {
        v += 1;
      }

      //
      if (n.focus.name && n.focus.name.value != '') {
        v += 1;
      }
      if (n.focus.relationship && n.focus.relationship.value != '') {
        v += 1;
      }
      if (n.focus.goals && n.focus.goals.value != '') {
        v += 1;
      }
      if (n.focus.open && n.focus.open.value != '') {
        v += 1;
      }
      if (n.focus.closing && n.focus.closing.value != '') {
        v += 1;
      }
      if (n.focus.bias && n.focus.bias.value != '') {
        v += 1;
      }
      if (n.focus.fact && n.focus.fact.value != '') {
        v += 1;
      }
      // if (n.focus.tops && n.focus.tops.length > 0) {
      if (1 == 1) {
        v += 1;
      }
      if (n.focus.docs && n.focus.docs.length > 0) {
        v += 1;
      }
      if (n.focus.keywords && n.focus.keywords.value != '') {
        v += 1;
      }
      if (n.focus.strength && n.focus.strength.value != '') {
        v += 1;
      }
      if (n.focus.weaknesses && n.focus.weaknesses.value != '') {
        v += 1;
      }
      if (n.focus.examination_tech && n.focus.examination_tech.value != '') {
        v += 1;
      }
      if (n.focus.objections && n.focus.objections.value != '') {
        v += 1;
      }
      if (n.focus.opposition_adv && n.focus.opposition_adv.value != '') {
        v += 1;
      }
      if (n.prepchart && n.prepchart.length > 0) {
        v += 1;
      }
    }

    num += 38;

    amount = 100 / num;

    p = amount * v;

    if (p != this.state.progress) this.setState({ progress: p });
  };
  question = (ev) => {
    this.setState({
      question: !this.state.question,
    });
  };
  preparation = (ev) => {
    this.setState({
      preparation: !this.state.preparation,
    });
  };
  focus = (ev) => {
    this.setState({
      focus: !this.state.focus,
    });
  };
  fact = (ev) => {
    this.setState({
      fact: !this.state.fact,
    });
  };

  summary = (ev) => {
    this.setState({
      summary: !this.state.summary,
    });
  };

  checklist = (ev) => {
    this.setState({
      checklist: !this.state.checklist,
    });
  };

  timeline = (ev) => {
    this.setState({
      timeline: !this.state.timeline,
    });
  };

  getT() {
    //  this.props.getT(a);
    this.props.setNavExpanded();
  }

  transformEvents = (events) => {
    const witnessMap = this.getWitnessMap();
    return events.reduce((prev, event) => {
      const individualEvents = event.events.map((e) => ({
        ...e,
        witnessId: event.witnessId,
        witnessName: witnessMap[event.witnessId],
      }));
      return [...prev, ...individualEvents];
    }, []);
  };

  getWitnessMap = () => {
    return (
      this.props?.case?.witnesses?.reduce(
        (prev, witness) => ({ ...prev, [witness._id]: witness.name }),
        {}
      ) || {}
    );
  };

  sortEvents = (events) => events.sort((a, b) => a.date.localeCompare(b.date));

  componentDidMount() {
    if (this.props.case != undefined && Object.keys(this.props.case) != 0) {
      this.setState(
        {
          case: this.props.case,
          witness: this.props.witness,
        },
        () => {
          if (this.state.case != undefined) {
            this.handleProgress();
          }
        }
      );
      //getting timeline by witness to pass it to case export
      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            const response = res?.data?.events || [];
            const sortedEvents = this.sortEvents(response);
            eventsByWitness.push({
              [witness.name]: sortedEvents,
            });
          });
      }
      this.setState({ timelineByWitness: eventsByWitness });
    } else {
      window.location.replace('/');
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.case !== this.props.case) {
      this.setState(
        {
          case: this.props.case,
          witness: this.props.witness,
        },
        () => {
          if (this.state.case != undefined) {
            this.handleProgress();
          }
        }
      );
    

      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            eventsByWitness.push({
              [witness.name]: res.data.events,
            });
          });
      }
      this.setState({ timelineByWitness: eventsByWitness });
     
    }
  }

  componentWillReceiveProps(news) {
    if (this.props.witness != news.witness) {
      this.setState(
        {
          case: news.case,
          witness: news.witness,
        },
        () => {
          if (this.state.case != undefined) {
            this.handleProgress();
          }
        }
      );
    }
  }

  handleWSSubmit(s, e) {
    e.preventDefault();
    console.log("called 2")
    console.log("name: ", s)
    console.log("this.state.old name", this.state.old)

    if (s.name != '' && s.name != undefined) {
      if (s.name != this.state.old) {
        axios
          .post(prod() + '/api/cases/update/witness', {
            name: s.name,
            witness: { _id: this.state.updating },
            case: this.props.case._id,
          })

          .then((user) => {
            this.setState(
              {
                wSShow: false,
                errors: undefined,
                old: s.name,
              },
              () => {
                for (let a of user.data.name.witnesses) {
                  if (a._id == this.props.witness._id) {
                    // this.props.updateCase();
                    this.props.updateWitness(user.data.name, a);
                  }
                }
              }
            );
          })
          .catch((err) => {
            this.setState({
              errors: 'Could Not Update Witness',
            });
          });
      } else {
        this.setState({
          errors: 'New name is the same as old.',
        });
      }
    } else {
      this.setState({
        errors: 'Please Enter The Witness Name.',
      });
    }
  }
  handleWSubmit(i, e) {
    e.preventDefault();
    const witnesses = {
      name: i.name,
      // user: this.state.use,
      case: this.state.case._id,
    };
    if (
      witnesses.name != '' &&
      witnesses.name != undefined &&
      witnesses.case != {}
    ) {
      axios
        .post(prod() + '/api/cases/create/witness', witnesses)
        .then((use) => {
          this.setState({
            wShow: false,
          });
          this.props.updateCase();
        })
        .catch((err) => {
          this.setState({
            user: 'Case already exist',
          });
        });
    } else {
      this.setState({
        user: 'Please fill out witness name field',
      });
    }
  }
  handleWSClose = () => this.setState({ wSShow: false });
  handleWSShow = () => {
    this.setState(
      {
        wSShow: true,
        uWName: this.state.witness.name,
        updating: this.state.witness['_id'],
        old: this.state.witness['name'],
      },
      () => this.editRef.current.focus()
    );
  };

  handleWClose = () => this.setState({ wShow: false });
  handleWShow = () => this.setState({ wShow: true });
  deleteWitness(a) {
    axios.post(prod() + '/api/documents/delete/witness', {
      case: this.props.case._id,
      witness: this.props.witness['_id'],
    });
    axios
      .post(prod() + '/api/cases/delete/witness', {
        case: this.props.case._id,
        witness: this.props.witness['_id'],
        item: this.props.witness,
      })
      .then((use) => {
        for (let item of this.props.cases) {
          this.props.changeLocation({
            loc: '/case',
            witness: undefined,
            witnessN: '',
            case: use.data.case,
          });
          break;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleWDShow = (e, ev) => {
    ev.stopPropagation();

    this.setState({ wDShow: true, deleted: e });
  };
  handleWDClose = () => {
    this.setState({ wDShow: false });
  };
  render() {
    return (
      <>
        <Icons
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          changeLocation={this.props.changeLocation}
          getWitness={this.props.getWitness}
          cases={this.props.cases}
          setNavExpanded={this.props.setNavExpanded}
          goInfo={this.props.goInfo}
          goHome={this.props.goHome}
          goSettings={this.props.goSettings}
          loadOut={this.props.loadOut}
          goActivity={this.props.goActivity}
          darkMode={this.props.darkMode}
          getDoc={this.props.getDoc}
          goAudioTranscription={this.props.goAudioTranscription}
        />
        <div className='expanded'>
          <div id='side-nav-open' className='side-nav-open-w'>
            <div className='side-nav-open-title side-nav-open-title-w'>
              <Row>
                <Col md={12}>
                  <Fade>
                    <h5 id='case'>Sections </h5>
                  </Fade>
                </Col>
              </Row>

              <div>
                <Fade>
                  <div className='w-buttons'>
                    <Row>
                      <Col md={4}>
                        {' '}
                        <div className='add-btn-plus add-btn-plus1'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Case Dashboard
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={this.props.goCase}
                                  className='add-case'
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/left-arrow_w86mdw.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus2'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Settings
                                  </Tooltip>
                                }
                              >
                                <Button
                                  className='add-case'
                                  onClick={(e) =>
                                    this.handleWSShow(this.state.case['_id'], e)
                                  }
                                >
                                  <img
                                    className='logo-title'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div className='add-btn-plus add-btn-plus3'>
                          <div>
                            <ButtonToolbar>
                              <OverlayTrigger
                                key='top'
                                placement='top'
                                overlay={
                                  <Tooltip
                                    id={
                                      this.props.darkMode
                                        ? 'dark-tooltip-add'
                                        : 'tooltip-add'
                                    }
                                  >
                                    Delete
                                  </Tooltip>
                                }
                              >
                                <Button
                                  onClick={(e) =>
                                    this.handleWDShow(this.state.case['_id'], e)
                                  }
                                  className='add-case add-btn-plus add-btn-plus2'
                                >
                                  <img
                                    className='logo-title logo-title-l'
                                    src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                                  ></img>{' '}
                                </Button>
                              </OverlayTrigger>
                            </ButtonToolbar>
                          </div>
                        </div>
                      </Col>
                      <Col sm={{ size: 10 }}>
                        <ProgressBar now={this.state.progress} />
                      </Col>
                    </Row>
                  </div>
                </Fade>

                <Fade>
                  <h6 className='w-name'>
                    <span>Case: </span>
                    {this.state.case && this.state.case.name
                      ? this.state.case.name
                      : ''}
                  </h6>
                  <h6 className='w-name'>
                    <span>Client:</span>{' '}
                    {this.state.case && this.state.case.client
                      ? this.state.case.client
                      : ''}
                  </h6>
                  <h6 className='w-name'>
                    <span>Witness:</span>{' '}
                    {this.state.witness && this.state.witness.name
                      ? this.state.witness.name
                      : ''}
                  </h6>
                </Fade>
              </div>
              <Fade>
                <div>
                  {' '}
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link to='/case/witness/focus' className='cases-items'>
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642371/Aleri/target_lesomq.svg'></img>
                              <p className='wit-title wit-title-z'>Focus</p>
                              <span className='step-by-step'>Step 1.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.focus(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  {/*  */}
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/checklist'
                          className='cases-items'
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642363/Aleri/notepad_ouiolb.svg'></img>
                              <p className='wit-title wit-title-z'>Checklist</p>
                              <span className='step-by-step'>Step 2.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.checklist(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/summaries'
                          className='cases-items'
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593878901/Aleri/evidence_lol8la.svg'></img>
                              <p className='wit-title wit-title-z'>Summaries</p>
                              <span className='step-by-step'>Step 3.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.summary(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  {/*  */}
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link to='/case/witness/facts' className='cases-items'>
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/footprint_hafmha.svg'></img>
                              <p className='wit-title wit-title-z'>Facts</p>
                              <span className='step-by-step'>Step 4.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.fact(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link to='/case/witness/prep' className='cases-items'>
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593878907/Aleri/ruler-and-pencil-cross_urquuu.svg'></img>
                              <p className='wit-title wit-title-z'>
                                Preparation
                              </p>
                              <span className='step-by-step'>Step 5.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.preparation(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link to='/case/witness/topics' className='cases-items'>
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/question_1_nvpf9m.svg'></img>
                              <p className='wit-title wit-title-z'>Questions</p>
                              <span className='step-by-step'>Step 6.</span>
                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.question(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <hr className='divider'></hr>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/timeline'
                          className='cases-items'
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1615996857/Aleri/app/timeline_iipgrq.svg'></img>
                              <p className='wit-title wit-title-z'>Timeline</p>
                              <span className='step-by-step'>Ongoing.</span>

                              <img
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'
                                onClick={(e) => this.timeline(e)}
                              ></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                </div>
              </Fade>
            </div>
          </div>

          <div id='side-nav-open-mobile'>
            <Slide bottom>
              <div className='side-nav-open-title side-nav-open-title-w'>
                <Fade>
                  <h3>Sections</h3>
                </Fade>

                <br />
                <div>
                  <Fade>
                    <div className='w-buttons'>
                      <div className='add-btn-plus add-btn-plus3'>
                        <div>
                          <ButtonToolbar>
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip
                                  id={
                                    this.props.darkMode
                                      ? 'dark-tooltip-add'
                                      : 'tooltip-add'
                                  }
                                >
                                  Delete
                                </Tooltip>
                              }
                            >
                              <Button
                                onClick={(e) =>
                                  this.handleWDShow(this.state.case['_id'], e)
                                }
                                className='add-case'
                              >
                                <img
                                  className='logo-title logo-title-l'
                                  src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                                ></img>{' '}
                              </Button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </div>
                      </div>

                      <div className='add-btn-plus add-btn-plus2'>
                        <div>
                          <ButtonToolbar>
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip
                                  id={
                                    this.props.darkMode
                                      ? 'dark-tooltip-add'
                                      : 'tooltip-add'
                                  }
                                >
                                  Settings
                                </Tooltip>
                              }
                            >
                              <Button
                                className='add-case'
                                onClick={(e) =>
                                  this.handleWSShow(this.state.case['_id'], e)
                                }
                              >
                                <img
                                  className='logo-title'
                                  src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                                ></img>{' '}
                              </Button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </div>
                      </div>
                      <div className='add-btn-plus add-btn-plus1'>
                        <div>
                          <ButtonToolbar>
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip
                                  id={
                                    this.props.darkMode
                                      ? 'dark-tooltip-add'
                                      : 'tooltip-add'
                                  }
                                >
                                  Case Dashboard
                                </Tooltip>
                              }
                            >
                              <Button
                                onClick={this.props.goCase}
                                className='add-case'
                              >
                                <img
                                  className='logo-title'
                                  src='https://res.cloudinary.com/aleri/image/upload/v1593642360/Aleri/left-arrow_w86mdw.svg'
                                ></img>{' '}
                              </Button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        </div>
                      </div>
                    </div>
                  </Fade>

                  <Fade>
                    <h6 className='w-name'>
                      <span>Case: </span>
                      {this.state.case && this.state.case.name
                        ? this.state.case.name
                        : ''}
                    </h6>
                    <h6 className='w-name'>
                      <span>Client:</span>{' '}
                      {this.state.case && this.state.case.client
                        ? this.state.case.client
                        : ''}
                    </h6>
                    <h6 className='w-name'>
                      <span>Witness:</span>
                      {this.state.witness && this.state.witness.name
                        ? this.state.witness.name
                        : ''}
                    </h6>
                  </Fade>
                </div>
              </div>
              <Fade>
                <div>
                  {' '}
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/topics'
                          onClick={this.props.setNavExpanded}
                          className='cases-items'
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642368/Aleri/question_uhcg2h.svg'></img>
                              <p className='wit-title wit-title-z'>Questions</p>

                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/focus'
                          className='cases-items'
                          onClick={this.props.setNavExpanded}
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/focus-sheet_ota5bv.svg'></img>
                              <p className='wit-title wit-title-z'> Focus</p>

                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/prep'
                          onClick={this.props.setNavExpanded}
                          className='cases-items'
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/graph_egopgd.svg'></img>
                              <p className='wit-title wit-title-z'>
                                {' '}
                                Prep Chart
                              </p>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                  <Col md={12}>
                    <Fade>
                      <div>
                        <Link
                          to='/case/witness/checklist'
                          className='cases-items'
                          onClick={this.props.setNavExpanded}
                        >
                          <ListGroup.Item className='parent-side-nav untouch'>
                            <div className='icon-img'>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642361/Aleri/checklist_pdse32.svg'></img>
                              <p className='wit-title wit-title-z'>
                                {' '}
                                Checklist
                              </p>
                              <img src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/information_kydyh7.svg'></img>
                            </div>
                          </ListGroup.Item>
                        </Link>
                      </div>
                    </Fade>
                  </Col>
                </div>
              </Fade>
            </Slide>
          </div>
        </div>
        <CaseModal
          wShow={this.state.wShow}
          wSShow={this.state.wSShow}
          handleWClose={this.handleWClose}
          handleWSubmit={this.handleWSubmit}
          wDShow={this.state.wDShow}
          handleWDClose={this.handleWDClose}
          handleWDSubmit={this.handleWDSubmit}
          sWShow={this.state.sWShow}
          handleWSClose={this.handleWSClose}
          handleWSSubmit={this.handleWSSubmit}
          user={this.state.user}
          err={this.state.errors}
          uName={this.state.uName}
          uClient={this.state.uClient}
          deleteWitness={this.deleteWitness}
          //  update wit
          uWName={this.state.uWName}
          edit={this.editRef}
          darkMode={this.props.darkMode}
          handleEShow={this.handleEShow}
          handleEClose={this.handleEClose}
          eShow={this.state.eShow}
          witness={this.state.witness}
          case={this.state.case}
          timelineByWitness={this.state.timelineByWitness}
        />
        <WitModules
          darkMode={this.props.darkMode}
          setFocus={this.focus}
          focus={this.state.focus}
          // eShow={this.state.eShow}
          handleEClose={this.handleEClose}
          witness={this.state.witness}
          case={this.state.case}
          hShow={this.state.hShow}
          handleHClose={this.handleHClose}
          question={this.state.question}
          setQuestion={this.question}
          preparation={this.state.preparation}
          setPreparation={this.preparation}
          fact={this.state.fact}
          setFact={this.fact}
          checklist={this.state.checklist}
          setChecklist={this.checklist}
          summary={this.state.summary}
          setSummary={this.summary}
          timeline={this.state.timeline}
          setTimeline={this.timeline}
        />
        <ToggleNav setNavExpanded={this.props.setNavExpanded} />
      </>
    );
  }
}
