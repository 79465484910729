import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import PDFViewer from './PDFViewer';

class FileViewModal extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <Modal
        show={this.props.fileModal}
        onHide={() => this.props.openFileViewModal(false)}
        id='file-modal'
        dialogClassName={
          this.props.darkMode ? 'dark-modal file-preview' : 'file-preview'
        }
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header className='file-modal_header' closeButton>
          <Modal.Title id='example-custom-modal-styling-title'>
            {this.props.value.ref.document.name}
          </Modal.Title>

          {/*path to '/case/witness/file/:refId/:docId/:filename' */}
          <Link
            to={{
              pathname: `/case/witness/file/${this.props.value._id}/${this.props.value.ref.document._id}/${this.props.value.ref.document.name}`,
              query: { 
                refId: this.props.refId, 
                link: this.props.link 
              },
            }}
            onClick={() => this.props.openFileViewModal(false)}
          >
            Open as Page
          </Link>
        </Modal.Header>

        <Modal.Body>
          <PDFViewer 
            refId={this.props.refId} 
            link={this.props.link} 
            name={this.props.value.ref.document.name}
            addHighlightToRow={this.props.addHighlightToRow}
            addHighlightsToRows={this.props.addHighlightsToRows}
            darkMode={this.props.darkMode}
            summary={this.props.summary}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default FileViewModal;
