import React, { Component } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';

import prod from '../../../../prod';

import axios from 'axios';
import '../../../../Tags.css';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../styles/timeline.css';
import DatePicker from 'react-datepicker';

export default class SummaryTimelineModal extends Component {
  constructor() {
    super();
    this.state = {
      // loading: true,
      // cases: [],
      // show: false,
      // dShow: false,
      // firm: "",
      // use: "",
      // client: "",
      // name: "",
      // user: "",
      // uName: "",
      // summaryName: "",
      // selectFact: "",
      // facts: "",
      // witness: "",
      // case: "",
      // newFactName: "",
      // activeSelectedFact: "",
      show: true,
      startDate: new Date(),
      // New Timeline Point
      // Name
      newEventName: '',
      // Date
      startDate: new Date(),
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleClose = this.handleClose.bind(this);
    this.tref = React.createRef();
  }

  componentDidMount() {
    this.setState(
      {
        newEventName: this.props.highlightedText,
        show: true,
      },
      () => {
        this.tref.current.focus();
      }
    );
  }
  componentDidUpdate(prevProps) {
    // if (prevProps.uWName !== this.props.uWName) {
    //   this.setState({
    //     uName: this.props.uWName,
    //   });
    // }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const event = {
      name: this.state.newEventName,
      date: this.state.startDate,
      witnessId: this.props.witness._id,
      caseId: this.props.case._id,
    };

    if (
      event.name !== '' &&
      event.witnessId !== undefined &&
      event.caseId !== ''
    ) {
      axios
        .post(prod() + '/api/cases/create/witness/timeline', event)
        .then((_) => {
          this.props.handleTimelineClose();
          this.setState({
            show: false,
            newEventName: '',
            startDate: new Date(),
          });
        })
        .catch((err) => {
          this.setState({
            user: 'Error',
          });
        });
    } else {
      this.setState({
        user: 'Please fill out all event fields',
      });
    }
  };
  // handleClose = () => this.setState({ show: false, });
  // onChange for New Event name
  onChange(e) {
    this.setState({ newEventName: e.target.value });
  }
  handleClose = () => this.setState({ show: false });

  render() {
    return (
      <>
        <Modal
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
          show={this.state.show ? this.state.show : this.props.show}
          onHide={() => {
            this.setState({ newFactName: '' });
            this.props.handleTimelineClose();
          }}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create New Timeline Point</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Event Description</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Event'
                  value={this.state.newEventName ? this.state.newEventName : ''}
                  onChange={(e) => this.onChange(e)}
                  name='name'
                  ref={this.tref}
                />
                <div className='select-date'>
                  <Form.Label>Date</Form.Label>
                  <div>
                    <DatePicker
                      className='date-picker'
                      selected={this.state.startDate}
                      onChange={(date) => this.setState({ startDate: date })}
                      showTimeSelect
                      dateFormat='MMMM d, yyyy h:mm aa'
                      value={this.state.startDate}
                      placeholderText='MM/DD/YYYY'
                    />
                  </div>
                </div>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleClose}>
                Close
              </Button>
              <Button variant='primary' type='submit'>
                Add Time Point
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
