import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default class DashboardComment extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.state = {
      case: {
        comments: [
          {
            user: 'David Paul',
            content: 'Here is a test comment',
            created: '1 Day Ago',
          },
          {},
        ],
      },
      newComment: '',
    };
  }
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Modal
        show={this.props.commentModal}
        onHide={() => {
          this.props.handleCommentModalClose();
        }}
        dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id='comment_list'>{this.state.case.comments}</div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              this.props.handleCommentModalClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
