import React, { Component } from 'react';

import {
  Form,
  Button,
  Col,
  Row,
  Container,
  Tooltip,
  OverlayTrigger,
  ButtonToolbar,
  ProgressBar,
} from 'react-bootstrap';
import CaseModal from './CaseModal';
import axios from 'axios';
import { Link, Redirect, withRouter } from 'react-router-dom';
import history from '../../../utils/history';
import prod from '../../../prod';
import { cpus } from 'os';

import Pulse from 'react-reveal/Pulse';


import '../../../styles/Case.css';

export const logPageView = () => {
  window.analytics.page('Case');
};
class Case extends Component {
  constructor() {
    super();
    this.state = {
      case: {},
      hShow: false,
      // export
      eShow: false,
      timelineByWitness: [],
      timelines: [],

      noWitness: true,
      showNoWitnessModalF: false,
      showNoWitnessModalT: false,
    };
    this.handleHClose = this.handleHClose.bind(this);
    this.handleHShow = this.handleHShow.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    // fact tags
    this.handleFactTags = this.handleFactTags.bind(this);

    //timeline
    this.handleTimeline = this.handleTimeline.bind(this);

    //export
    this.transformEvents = this.transformEvents.bind(this);
    this.getWitnessMap = this.getWitnessMap.bind(this);

    this.openNoWitnessModalF = this.openNoWitnessModalF.bind(this);
    this.openNoWitnessModalT = this.openNoWitnessModalT.bind(this);
  }

  // export
  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      // axios
      //   .post(prod() + '/api/cases/get/witness/fact', {
      //     case: this.props.case,
      //     witness: this.props.witness['_id'],
      //     fact: this.props.fact,
      //   })
      //   .then((use) => {
      //     const items = [];
      //     for (let topi of use.data.facts.fact) {
      //       items.push({
      //         reference: topi.reference,
      //         evidence: topi.evidence,
      //         _id: topi._id,
      //       });
      //     }
      //     this.setState({
      //       q: items,
      //     });
      //   })
      //   .catch((e) => {
      //     console.log('ERROR', e);
      //   });
    });
  handleEClose = () => this.setState({ eShow: false });

  // help

  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });

  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/case', {
          cases: this.state.case,
          important: important,
          user: s,
        })
        .then((user) => {
          this.setState({
            case: user.data,
          });
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };

  handleProgress = () => {
    let p = 0;
    let num = 0;
    let v = 0;
    let amount = 0;
    const witness = this.props.case.witnesses;

    if (witness && witness.length > 0) {
      witness.forEach((n) => {
        if (n.topics && n.topics.length > 0) {
          for (let i of n.topics) {
            if (i.topic.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.statements && n.statements.length > 0) {
          for (let i of n.statements) {
            if (i.statement.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (n.facts && n.facts.length > 0) {
          for (let i of n.facts) {
            if (i.fact.length > 0) v += 1;
            num += 1;
          }
        } else {
          num += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.financial != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.relationship != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.bias &&
          n.checklist.reliability.bias.hostility != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.impairment_alcohol_or_drugs != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.physical_deficiency != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .focus != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .witness_preoccupied != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .m_quantity_and_distance_not_reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .desire_to_find_meaning != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_see != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .establish_inability_to_hear != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .impact_of_sudden_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .insignificance_of_event != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .ability_to_observe != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception &&
          n.checklist.reliability.lack_knowledge_of_events_faulty_perception
            .expose_belief_from_habit != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.forget_events != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.flawed != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.experience != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.collateral != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.reliability &&
          n.checklist.reliability.reliability_of_memory &&
          n.checklist.reliability.reliability_of_memory.influence != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent.accuse !=
            ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .reliable != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.prior_inconsistent &&
          n.checklist.plausibility_and_consistency.prior_inconsistent
            .confront != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.implausible
        ) {
          v += 1;
        }
        if (
          n.checklist.plausibility_and_consistency &&
          n.checklist.plausibility_and_consistency.contradicted
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.record &&
          n.checklist.credibility.record != ''
        ) {
          v += 1;
        }
        if (
          n.checklist.credibility &&
          n.checklist.credibility.evidence &&
          n.checklist.credibility.evidence != ''
        ) {
          v += 1;
        }
        //
        if (n.focus.name && n.focus.name.value != '') {
          v += 1;
        }
        if (n.focus.relationship && n.focus.relationship.value != '') {
          v += 1;
        }
        if (n.focus.goals && n.focus.goals.value != '') {
          v += 1;
        }
        if (n.focus.open && n.focus.open.value != '') {
          v += 1;
        }
        if (n.focus.closing && n.focus.closing.value != '') {
          v += 1;
        }
        if (n.focus.bias && n.focus.bias.value != '') {
          v += 1;
        }
        if (n.focus.fact && n.focus.fact.value != '') {
          v += 1;
        }
        if (n.focus.tops && n.focus.tops.length > 0) {
          v += 1;
        }
        if (n.focus.docs && n.focus.docs.length > 0) {
          v += 1;
        }
        if (n.focus.keywords && n.focus.keywords.value != '') {
          v += 1;
        }
        if (n.focus.strength && n.focus.strength.value != '') {
          v += 1;
        }
        if (n.focus.weaknesses && n.focus.weaknesses.value != '') {
          v += 1;
        }
        if (n.focus.examination_tech && n.focus.examination_tech.value != '') {
          v += 1;
        }
        if (n.focus.objections && n.focus.objections.value != '') {
          v += 1;
        }
        if (n.focus.opposition_adv && n.focus.opposition_adv.value != '') {
          v += 1;
        }
        if (n.prepchart && n.prepchart.length > 0) {
          v += 1;
        }
      });
    } else {
      num = 1;
    }

    num += 39 * (witness ? witness.length : 0);

    amount = 100 / num;

    p = amount * v;

    if (p != this.state.progress)
      this.setState({ progress: p, case: this.props.case });
  };

  componentDidUpdate() {
    localStorage.setItem('witness', JSON.stringify(this.state));
  }
  transformEvents = (events) => {
    const witnessMap = this.getWitnessMap();
    return events.reduce((prev, event) => {
      const individualEvents = event.events.map((e) => ({
        ...e,
        witnessId: event.witnessId,
        witnessName: witnessMap[event.witnessId],
      }));
      return [...prev, ...individualEvents];
    }, []);
  };

  getWitnessMap = () => {
    return (
      this.props?.case?.witnesses?.reduce(
        (prev, witness) => ({ ...prev, [witness._id]: witness.name }),
        {}
      ) || {}
    );
  };

  // fetchTimelinePerWitness(case) {
  //   //getting timeline by witness to pass it to case export
  //   const eventsByWitness = [];
  //   for (const witness of case.witnesses) {
  //     axios
  //       .get(prod() + '/api/cases/timeline/witness', {
  //         params: {
  //           caseId: case._id,
  //           witnessId: witness._id,
  //         },
  //       })
  //       .then((res) => {
  //         const response = res?.data?.events || [];
  //         const sortedEvents = this.sortEvents(response);
  //         eventsByWitness.push({
  //           [witness.name]: sortedEvents,
  //         })
  //       })
  //     }
  //     this.setState({ timelineByWitness: eventsByWitness})
  // };

  // fetchTimelines(case) {
  //   //getting timelines to pass it to case export
  //   axios
  //   .get(prod() + '/api/cases/timeline/all', {
  //     params: {
  //       id: case._id,
  //     },
  //   })
  //   .then((res) => {
  //     const response = this.transformEvents(res?.data?.events || []);
  //     const sortedEvents = this.sortEvents(response);
  //     this.setState({
  //       timelines: sortedEvents,
  //     });
  //   })
  //   .catch(e => console.log(e))
  // };

  sortEvents = (events) => events.sort((a, b) => a.date.localeCompare(b.date));

  componentDidMount() {
    logPageView();
    this.handleProgress();
    if (this.props.case != undefined && Object.keys(this.props.case) != 0) {
      if (this.props.case.witnesses.length > 0) {
        this.setState({ noWitness: false });
      }
      //getting timeline by witness to pass it to case export
      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            const response = res?.data?.events || [];
            const sortedEvents = this.sortEvents(response);
            eventsByWitness.push({
              [witness.name]: sortedEvents,
            });
          });
      }
      this.setState({ timelineByWitness: eventsByWitness });

      //getting timelines to pass it to case export
      axios
        .get(prod() + '/api/cases/timeline/all', {
          params: {
            id: this.props.case._id,
          },
        })
        .then((res) => {
          const response = this.transformEvents(res?.data?.events || []);
          const sortedEvents = this.sortEvents(response);
          this.setState({
            timelines: sortedEvents,
          });
        })
        .catch((e) => console.log(e));
    } else {
      window.location.replace('/');
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.case != this.props.case) {
      axios
        .post(prod() + '/api/cases/get', { case: this.props.case._id })
        .then(res => {
          if (res.data.witnesses.length > 0) {
            this.setState({ noWitness: false });
          } else {
            this.setState({ noWitness: true });
          }
        })
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.case !== this.props.case) {
      this.setState({
        case: this.props.case,
      });
      const eventsByWitness = [];
      for (const witness of this.props.case.witnesses) {
        axios
          .get(prod() + '/api/cases/timeline/witness', {
            params: {
              caseId: this.props.case._id,
              witnessId: witness._id,
            },
          })
          .then((res) => {
            eventsByWitness.push({
              [witness.name]: res.data.events,
            });
          });

      }
      this.setState({ timelineByWitness: eventsByWitness });

      //getting timelines to pass it to case export
      axios
        .get(prod() + '/api/cases/timeline/all', {
          params: {
            id: this.props.case._id,
          },
        })
        .then((res) => {
          const response = this.transformEvents(res?.data?.events || []);
          const sortedEvents = this.sortEvents(response);
          this.setState({
            timelines: sortedEvents,
          });
        })
        .catch((e) => console.log(e));
    }
  }

  handleFactTags() {
    window.scrollTo(0, 0);
    history.push('/case/tags');
  }

  handleTimeline() {
    window.scrollTo(0, 0);
    history.push('/case/timeline');
  }

  openNoWitnessModalF(open) {
    this.setState({ showNoWitnessModalF: open })
  }

  openNoWitnessModalT(open) {
    this.setState({ showNoWitnessModalT: open })
  }

  render() {
    const caseList = [];
    if (this.state.redirect) {
      return <Redirect to='/' />;
    }

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;

    let firm = <div></div>;
    if (this.props.splitState == true && this.props.open == true) {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded dash-d-split'
        : 'dash-d dash-d-firm';
    } else if (this.props.splitState == true && this.props.open == false) {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded dash-d-split-closed'
        : 'dash-d dash-d-firm';
    } else {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded'
        : 'dash-d dash-d-firm';
    }
    return (
      <>
        <div id="wit-dash">
          <Container fluid className="container">
            <div>
              <Row id='dash-add'>
                <Col md={5}>
                  <div className='case-dash-container'>
                    {/* {this.state.case.comment ? (
                      <img
                        onClick={(e) => this.handleImportant(e, false)}
                        className='logo-title-dash '
                        src={require('../../../pics/comment_submitted.svg')}
                      ></img>
                    ) : (
                      <img
                        onClick={(e) => this.handleImportant(e, true)}
                        className='logo-title-dash card_important_comment'
                        src={require('../../../pics/comment.svg')}
                      ></img>
                    )} */}
                    {this.state.case.important ? (
                      <img
                        className='logo-title-dash'
                        onClick={(e) =>
                          this.handleImportant(
                            e,

                            false
                          )
                        }
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                      ></img>
                    ) : (
                      <img
                        onClick={(e) => this.handleImportant(e, true)}
                        className='logo-title-dash card_important_comment'
                        src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                      ></img>
                    )}
                  </div>
                  <h1>Case Dashboard</h1>

                  <ProgressBar now={Math.round(this.state.progress)} />
                </Col>

                <Col md={7}>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Help
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleHShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                  <div className='add-btn add-btn-p-top topic-btn'>
                    <Pulse>
                      <div>
                        <ButtonToolbar>
                          <OverlayTrigger
                            key='bottom'
                            placement='bottom'
                            overlay={
                              <Tooltip
                                id={
                                  this.props.darkMode
                                    ? 'dark-tooltip-add'
                                    : 'tooltip-add'
                                }
                              >
                                Export
                              </Tooltip>
                            }
                          >
                            <Button
                              onClick={this.handleEShow}
                              className='add-case'
                            >
                              <img
                                className='logo-title'
                                src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                              ></img>{' '}
                            </Button>
                          </OverlayTrigger>
                        </ButtonToolbar>
                      </div>
                    </Pulse>
                  </div>
                </Col>
              </Row>
            </div>
            <div id="widgets" className="widgets">
              {this.state.noWitness ? (
                <>
                  <div
                    onClick={() => this.openNoWitnessModalF(true)}
                    className={this.props.darkMode == true ? "widget-item-dark" : "widget-item"}
                  >
                    <p>Fact Tags</p>
                  </div>
                  <div
                    onClick={() => this.openNoWitnessModalT(true)}
                    className={this.props.darkMode == true ? "widget-item-dark" : "widget-item"}
                  >
                    <p>Timeline</p>
                  </div>
                </>
              ): (
                <>
                  <div
                    onClick={this.handleFactTags}
                    className={this.props.darkMode == true ? "widget-item-dark" : "widget-item"}
                  >
                    <p>Fact Tags</p>
                  </div>
                  <div
                    onClick={this.handleTimeline}
                    className={this.props.darkMode == true ? "widget-item-dark" : "widget-item"}
                  >
                    <p>Timeline</p>
                  </div>
                </>
              )}
            </div>
          </Container>
          {/* Case Modal in Case Dashboard */}
          <CaseModal
            darkMode={this.props.darkMode}
            handleHClose={this.handleHClose}
            hShow={this.state.hShow}
            handleEClose={this.handleEClose}
            eCaseShow={this.state.eShow}
            case={this.state.case}
            timelineByWitness={this.state.timelineByWitness}
            timelines={this.state.timelines}
            showNoWitnessModalT={this.state.showNoWitnessModalT}
            showNoWitnessModalF={this.state.showNoWitnessModalF}
            openNoWitnessModalT={this.openNoWitnessModalT}
            openNoWitnessModalF={this.openNoWitnessModalF}
          />
        </div>
      </>
    );
  }
}
export default withRouter(Case);
