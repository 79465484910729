import React, { Component } from 'react';

import { Container } from 'react-bootstrap';
export default class Impeachment extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.second != true) {
      this.props.getTab({
        case: '',
        witness: '',
        topic: '',
        path: '/information/impeachment',
        name: 'Impeachment',
        active: true,
      });
    }
  }
  render() {
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash' className='wit-dash-w'>
        <Container fluid>
          <div className='info_and_tech'>
            <h2>Coming Soon</h2>
            {/* <h2>Impeachment Strategies</h2>
            <h1>Impeachment Using a Prior Inconsistent Statement</h1>
            <h6>
              Prior to determining if you proceed with impeachment you should:
            </h6>
            <ol>
              <li>
                Determine if the prior statement is more helpful to your case,
                as you won't necessarily want to impeach the witness if the
                current version is more favourable to your case. However, if the
                witness frequently gives different versions of the events, you
                may want to impeach, even if the new version is more favourable,
                in order to highlight a pattern of changing versions.
              </li>
              <li>
                Determine How Significant the Inconsistency is- you will not
                want to impeach a witness on trivial inconsistencies.
              </li>
              <li>
                Ensure that you can prove the inconsistency. If the witness
                denies the statement, then you will have to be in a position to
                prove it. If it is prior testimony then you will require the
                transcript. If it's a document/statement to another witness,
                you'll have to be in a position to prove the document. Or, in
                the case of a verbal statement, to be in a position to call the
                witness who heard the verbal statement.
              </li>
            </ol>
            <h6>The impeachment itself should proceed as follows:</h6>
            <ol>
              <li>
                <h6>
                  Recommit the Witness to The Current Version of Their Testimony
                </h6>
                <p>
                  You should very briefly remind the witness of their current
                  version that they testified to in Court.
                </p>
              </li>
              <li>
                <h6>Lose off Any Excuses Inconsistencies</h6>
                Using the technique, referred to above (laying foundations and
                cutting off escape routes), you may want to <em>
                  anticipate
                </em>{' '}
                any excuses for the inconsistency and ask questions that will
                make it difficult to rely on the excuses. Part of closing off
                excuses and escape routes will involve bringing out the
                circumstances of the earlier statement.
                <ul>
                  If the earlier statement is more favourable to your side, then
                  you would bring out:
                  <li>
                    That the earlier statement was made closer to the incident
                  </li>
                  <li>
                    The emphasis on how the details were fresh in their mind
                  </li>
                  <li>
                    The fact that witness was able to focus and think carefully
                  </li>
                  <li>Highlight if it was made under oath</li>
                  <li>
                    That they recognize what they were saying was important
                  </li>
                  <li>They were attempting to tell the truth</li>
                  <li>If the statement was made in an official capacity.</li>
                  <li>
                    <h6>Establish a Motive</h6>
                    If there is a motive to testify differently and deliberately
                    lie the you will want to establish the motive.
                  </li>
                  <li>
                    <h6>Confront the Witness</h6>
                    You should then confront the witness using their{' '}
                    <em>exact</em> words. You should not give the witness an
                    opportunity to explain the inconsistency at that point as
                    they are not likely to agree with your suggested version.
                  </li>
                  <li>
                    <h6>Prove the Statement</h6>
                    If the witness denies the statement, you will have to prove
                    the statement in your case, which brings us to our next
                    point.
                  </li>
                </ul>
              </li>
            </ol>
            <h1>Impeachment by Omission</h1>
            <h6>
              If a witness fails to make a statement, in circumstances where it
              would be expected that would have made a statement, then they may
              be impeached based on the omission. The impeachment by omission
              involves establishing:
            </h6>
            <ul>
              <li>
                That the witness would have understood the need to have made the
                statement and to have been as detailed and complete as they
                were;
              </li>
              <li>
                The forum of the statement (hearing, report, document) must have
                been an appropriate place to make the statement
              </li>
              <li>
                The matter that was omitted was known to the witness and was
                important.{' '}
              </li>
            </ul>
            <h1>Impeachment by Inconsistencies Between Witnesses</h1>
            <p>
              One effective manner of undermining the opponent’s case is to
              highlight differences between the evidence of their witnesses.
              Prior to proceeding technique, it should be determined if you are
              simply challenging the opponent’s witnesses generally or if you
              attempting to support one of the two different versions in the
              opponent’s case. In the latter case you would focus you attack
              more on the witness whose version you are trying to discredit. The
              technique would involve bringing out the conflicting versions when
              you cross-examine the witnesses, but you would not confront them
              with the other witness’s evidence and allow them to explain the
              difference. It would be dangerous to allow a hostile witness to
              explain an inconsistency.
            </p>
            <h1>Asking Highly Detailed Questions to Expose False Evidence</h1>
            <p>
              If you suspect that a witness or witness is giving false evidence,
              then you may want to cross-examine at length on some of the
              peripheral details relating to the case. strategy is particularly
              useful where there are multiple witnesses who are suspected of
              fabricating a version of the events. The theory being that it's
              easy to fabricate a general version of the events, but it is not
              as common to create all the minute peripheral details of a false
              version of the events.
            </p>
            <h1>Impeachment by Inconsistencies with Physical Evidence</h1>
            <p>
              Physical evidence could include things like photographs of the
              scene or a videotape of an incident. If there is physical evidence
              that is almost irrefutable then it can be a powerful form of
              impeachment if a witness testifies in a manner that is
              inconsistent with the proven physical evidence. You would bring
              out, <em>in detail</em>, the inconsistency with the physical
              evidence.
            </p> */}
          </div>
        </Container>
      </div>
    );
  }
}
