import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import TextareaAutosize from 'react-textarea-autosize';

export class NewRow extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <>
        <div className='d-none d-md-block'>
          <Row className='unsaved-input'>
            <Col md={4} className='ref'>
              <Form.Group controlId='formBasicEmail'>
                <TextareaAutosize
                  placeholder='Enter Reference Here'
                  onBlur={() => this.props.addItem(1)}
                  value={this.props.col1Value}
                  onChange={this.props.handleUpdate}
                  name={this.props.col1Name}
                  ref={this.props.textInput3}
                />
              </Form.Group>
              <Button
                onClick={() => this.props.openUploadDocModal(true)}
                className='btn upload-document'
                tabIndex={-1}
              >
                Upload Document
              </Button>
            </Col>
            <Col md={8}>
              <Form.Group controlId='formBasicEmail'>
                <TextareaAutosize
                  placeholder={this.props.col2Placeholder}
                  onBlur={() => this.props.addItem(2)}
                  value={this.props.col2Value}
                  onChange={this.props.handleUpdate}
                  name={this.props.col2Name}
                />
              </Form.Group>
            </Col>
          </Row>
        </div>
      </>
    )
  }
}

export default NewRow;
