import React, { Component } from 'react';
import { BiUser } from 'react-icons/bi'

import {
  Col,
  Form,
  Button,
  Container,
  Row,
  ListGroup,
  Modal,
  Spinner,
} from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';
const divContainer = {
  justifyContent: 'space-between',
  padding: '0 0',
  margin: '0 auto',
  textAlign: 'left',
};
export const logPageView = () => {
  window.analytics.page('Member Settings');
};
export default class Members extends Component {
  constructor() {
    super();
    this.state = {
      firm: '',
      number: '',
      first_name: '',
      last_name: '',
      errors: '',
      success: '',
      user: {},
      old: {},
      plan: '',
      photo: {},

      payment: {},
      members: [],
      show: false,
      dShow: false,
      admin: false,

      new_first_name: '',
      new_last_name: '',

      new_email: '',
      new_password: '',
      user_err: '',
      users: 0,
      to_delete: null,
      user_d__err: '',
      user_t__err: '',
      tShow: false,
      to_toggle: null,

      disabled: false,
      loading: false,
      api_err: false,

      invitationStatus: [],
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleNShow = this.handleNShow.bind(this);
    this.handleNClose = this.handleNClose.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.sendInvitation = this.sendInvitation.bind(this);
    this.onChangeP = this.onChangeP.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.handleTShow = this.handleTShow.bind(this);
    this.handleTClose = this.handleTClose.bind(this);
    this.createRef = React.createRef();
  }
  handleNClose = () =>
    this.setState({
      show: false,
      new_first_name: '',
      new_last_name: '',
      new_email: '',
      user_err: '',
    });
  handleNShow = (e) =>
    this.setState({ show: true, admin: false }, () => {
      this.createRef.current.focus();
    });
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, to_delete: e });
  handleTClose = () => this.setState({ tShow: false, to_toggle: null });
  handleTShow = (e) => this.setState({ tShow: true, to_toggle: e });
  onChangeP = () => {
    this.setState({ admin: !this.state.admin });
  };

  componentDidMount() {
    logPageView();
    axios
      .get(prod() + '/api/users/current')
      .then((use) => {
        axios
          .post(prod() + '/api/firms/get', { firm: use.data.firm })
          .then((user) => {
            // console.log('USER', user.data.subscriptionId);
            axios
              .post(prod() + '/api/users/getall', { firm: use.data.firm })
              .then((a) => {
                let plan;
                if (user.data.subscriptionId == 0) {
                  plan = 3;

                  this.setState({
                    first_name: user.data['first_name'],
                    last_name: user.data['last_name'],
                    old: user.data,
                    number: user.data['number'],
                    plan: plan,
                    payment: user.data['payment'],
                    members: a.data['members'],
                    firm: use.data.firm,
                    users: a.data['members'].length,
                    user: use.data,
                    invitationStatus: a.data['members'],
                    photo: use.data['photo'],
                  });
                } else {
                  axios
                    .post(prod() + '/api/stripe/subscription/get', {
                      subscriptionId: user.data.subscriptionId,
                    })
                    .then((z) => {
                      if (z.data.subscription.plan.id === 'basic') {
                        plan = 1;
                      } else if (z.data.subscription.plan.id === 'pro') {
                        plan = 2;
                      } else {
                        plan = 3;
                      }

                      this.setState({
                        first_name: user.data['first_name'],
                        last_name: user.data['last_name'],
                        old: user.data,
                        number: user.data['number'],
                        plan: plan,
                        payment: user.data['payment'],
                        members: a.data['members'],
                        firm: use.data.firm,
                        users: a.data['members'].length,
                        user: use.data,
                        invitationStatus: a.data['members'],
                        photo: a.data['photo'],
                      });
                    })
                    .catch((err) => {
                      console.log('MMEMEMME', err);
                    });
                }
              })

              .catch((err) => {
                console.log('MMEMEMME', err);
              });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  }

  toggleAdmin() {
    if (
      this.state.user._id != this.state.members[this.state.to_toggle]['_id']
    ) {
      if (this.state.user.admin != 'false') {
        axios
          .post(prod() + '/api/users/admin', {
            email: this.state.new_email,
            admin: this.state.admin,
            firm: this.state.firm,
            user: this.state.members[this.state.to_toggle]['_id'],
            user_admin:
              this.state.members[this.state.to_toggle]['admin'] === 'false'
                ? 'true'
                : 'false',
          })
          .then((use) => {
            axios.get(prod() + '/api/users/current').then((s) => {
              axios
                .post(prod() + '/api/users/getall', { firm: this.state.firm })
                .then((a) => {
                  this.setState({
                    members: a.data['members'],
                    to_toggle: null,
                    user_t__err: '',

                    tShow: false,
                  });
                })
                .catch((err) => {
                  console.log('MMEMEMME', err);
                });
            });
            this.setState({ user_err: undefined });
          })
          .catch((err) => {
            this.setState({
              user_err: 'Case already exist',
            });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        this.setState({
          user_t__err: 'Only Admins Can Edit User Permissions',
        });
      }
    } else {
      this.setState({
        user_t__err: 'Can Not Change Permissions Of Your Account',
      });
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  deleteUser = async () => {
    if (this.state.user.admin != 'false') {
      axios
        .post(prod() + '/api/users/delete', {
          email: this.state.new_email,
          admin: this.state.admin,
          firm: this.state.firm,

          user: this.state.members[this.state.to_delete]['_id'],
          userInfo: this.state.user,
        })
        .then((use) => {
          axios.get(prod() + '/api/users/current').then((s) => {
            console.log('success', s);

            axios
              .post(prod() + '/api/users/getall', { firm: this.state.firm })
              .then((a) => {
                this.setState({
                  members: a.data['members'],
                  to_delete: null,

                  user_d__err: '',

                  dShow: false,
                  users: this.state.users.length - 1,
                });
              })
              .catch((err) => {
                console.log('MMEMEMME', err);
              });
          });

          this.setState({ user_err: undefined });
        })
        .catch((err) => {
          this.setState({
            user_err: 'Case already exist',
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.setState({ user_d__err: 'Only Admins Can Delete Users' });
    }
  };

  onSubmit(e) {
    e.preventDefault();
    if (
      this.state.first_name != '' &&
      this.state.first_name != undefined &&
      this.state.last_name != '' &&
      this.state.last_name != undefined
    ) {
      if (
        this.state.first_name != this.state.old.first_name ||
        this.state.last_name != this.state.old.last_name ||
        this.state.number != this.state.old.number
      ) {
        axios
          .post(prod() + '/api/firms/update', {
            name: this.state.name,
            number: this.state.number,
            oldFirm: this.state.old.name,
          })
          .then((user) => {
            this.setState({
              success: 'Updated Firm Information!',
              errors: undefined,
            });
          })
          .catch((err) => {
            this.setState({
              errors: 'Could Not Update Firm Information',
              success: undefined,
            });
          });
      }
    } else {
      this.setState({ errors: 'Firm name can not be blank.' });
    }
  }

  sendInvitation = (e) => {
    e.preventDefault();

    if (this.state.user.admin != 'false') {
      if (this.state.plan === 1) {
        this.setState({
          user_err: 'Please Upgrade Plan To Add User',
        });
      } else if (this.state.plan === 2 && this.state.users >= 3) {
        this.setState({
          user_err: 'Please Upgrade Plan To Add User',
        });
      } else if (this.state.plan === 3 && this.state.users >= 10) {
        this.setState({
          user_err: 'Please Upgrade Plan To Add User',
        });
      } else {
        this.setState(
          {
            disabled: true,
            loading: true,
            success: 'Creating User...',
          },
          () => {
            if (
              this.state.new_first_name != '' &&
              this.state.new_first_name != undefined &&
              this.state.new_last_name != '' &&
              this.state.new_last_name != undefined &&
              this.state.new_email != '' &&
              this.state.new_email != undefined
            ) {
              axios
                .post(prod() + '/api/sendEmail/sendInvitation', {
                  currentUser:
                    this.state.user.first_name +
                    ' ' +
                    this.state.user.last_name,
                  first_name: this.state.new_first_name,
                  last_name: this.state.new_last_name,
                  email: this.state.new_email,
                  admin: this.state.admin,
                  firm: this.state.firm,
                })
                .then((use) => {
                  console.log('Updated Firm after adding a new user', JSON.stringify(use))
                  axios
                    .post(prod() + '/api/users/getall', {
                      firm: this.state.firm,
                    })

                    .then((a) => {
                      console.log('success', a);
                      this.setState({
                        members: a.data['members'],
                        show: false,
                        new_first_name: '',
                        new_last_name: '',
                        new_email: '',
                        user_err: '',
                        disabled: false,
                        loading: false,
                        api_err: false,
                        success: '',
                      });
                    })
                    .catch((err) => {
                      console.log('MMEMEMME', err);
                    });
                  console.log('after');
                })
                .catch((err) => {
                  console.log('Error from Api while updating user count', err);
                  this.setState({
                    disabled: false,
                    loading: false,
                    success: '',
                    api_err: true
                  });
                });
            } else {
              console.log('err');
            }
          }
        );
      }
    }
  };

  render() {
    // console.log('invitationstat', this.state.invitationStatus);
    console.log("this.state: ", this.state)

    var month = new Array();

    month[0] = 'January';
    month[1] = 'February';
    month[2] = 'March';
    month[3] = 'April';
    month[4] = 'May';
    month[5] = 'June';
    month[6] = 'July';
    month[7] = 'August';
    month[8] = 'September';
    month[9] = 'October';
    month[10] = 'November';
    month[11] = 'December';

    let plan;
    if (this.state.plan === '1') {
      plan = 'Basic';
    } else if (this.state.plan === '2') {
      plan = 'Pro';
    } else if (this.state.plan === '3') {
      plan = 'Business';
    } else {
      plan = 'Enterprise';
    }

    let memberList = [];

    for (let i = 0; i < this.state.members.length; i++) {
      console.log(
        'Mmbers of I: ',
        this.state.user.id,
        this.state.members[i]['_id']
      );
      console.log(this.state.user.id === this.state.members[i]['_id']);

      memberList.push(
        <ListGroup.Item className='list-underline'>
          <div key={i} style={divContainer}>
            <Row>
              <Col sm={3}>
                <p className='' style={{ color: '#0275d8', fontWeight: '600' }}>
                  {' '}
                  {this.state.members[i].first_name +
                    ' ' +
                    this.state.members[i].last_name}
                </p>
              </Col>
              <Col sm={3}>
                {this.state.members[i].photo?.file ? (
                  <img
                    src={this.state.members[i].photo?.file}
                    alt='user-photo'
                    className='witness-img'
                    style={{ height: '60px' }}
                  />
                ): (
                  <BiUser size={50}/>
                )}
              </Col>
              <Col sm={3}>
                <p className=''>
                  Status:{' '}
                  {this.state.members[i].invitationStatus
                    ? this.state.members[i].invitationStatus
                    : 'Accepted'}
                </p>
              </Col>
              <Col sm={2}>
                {this.state.user.admin === 'true' &&
                this.state.user.id !== this.state.members[i]['_id'] ? (
                  <p className='' onClick={() => this.handleTShow(i)}>
                    {this.state.members[i].admin === 'true' ? (
                      <span className='lab badge  badge-pill badge-secondary admin'>
                        Admin
                      </span>
                    ) : (
                      <>
                        {this.state.user.admin === 'true' ? (
                          <span className='lab_u admin '>Make Admin</span>
                        ) : (
                          <p></p>
                        )}
                      </>
                    )}
                  </p>
                ) : (
                  <p className=''>
                    {this.state.members[i].admin === 'true' ? (
                      <span className='lab badge  badge-pill badge-secondary nt-admin'>
                        Admin
                      </span>
                    ) : (
                      <>
                        {this.state.user.admin === 'true' ? (
                          <span className='lab_u admin'>Make Admin</span>
                        ) : (
                          <p></p>
                        )}
                      </>
                    )}
                  </p>
                )}
              </Col>

              {this.state.user.admin === 'true' &&
              this.state.user.id !== this.state.members[i]['_id'] ? (
                <Col sm={1}>
                  {/* remove */}
                  <Button
                    className='trash '
                    onClick={() => this.handleDShow(i)}
                  >
                    {' '}
                    <img src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/trash_umvko6.svg'></img>
                  </Button>
                </Col>
              ) : (
                <Col sm={1}>
                  <Button disabled={true} className='trash '>
                    {' '}
                    <img src='https://res.cloudinary.com/aleri/image/upload/v1593822541/Aleri/trash_umvko6.svg'></img>
                  </Button>
                </Col>
              )}
            </Row>
          </div>
        </ListGroup.Item>
      );
    }
    let admin = '';
    admin =
      this.state.to_toggle != null
        ? this.state.members[this.state.to_toggle].admin === false
          ? 'Are you sure you want to make this user a Admin'
          : 'Are you sure you want to remove Admin permissions from this User'
        : '';
    if (this.state.to_toggle != null) {
      if (this.state.members[this.state.to_toggle]['admin'] === 'false') {
        admin = 'Are You Sure You Want To Make This User a Admin';
      } else {
        admin =
          'Are You Sure You Want To Remove Admin Permissions From This User';
      }
    }

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div>
        <Container>
          <div id="user-settings">
            <Form onSubmit={this.onSubmit}>
              <h2>Members</h2>
              <Modal
                dialogClassName={this.props.darkMode ? "dark-modal" : ""}
                show={this.state.dShow}
                onHide={this.handleDClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Delete</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>Are you sure you want to delete this user?</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={this.handleDClose}
                  >
                    No
                  </Button>

                  <Button variant="primary" onClick={this.deleteUser}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                dialogClassName={this.props.darkMode ? "dark-modal" : ""}
                show={this.state.tShow}
                onHide={this.handleTClose}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Permissions</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p style={{ paddingLeft: "10px" }}>{admin}</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    type="submit"
                    variant="secondary"
                    onClick={this.handleTClose}
                  >
                    No
                  </Button>

                  <Button variant="primary" onClick={this.toggleAdmin}>
                    Yes
                  </Button>
                </Modal.Footer>
              </Modal>
              <Modal
                dialogClassName={this.props.darkMode ? "dark-modal" : ""}
                show={this.state.show}
                onHide={this.handleNClose}
              >
                <Form onSubmit={this.sendInvitation}>
                  <Modal.Header closeButton>
                    <Modal.Title>Create User</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    {this.state.loading && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Spinner animation="border" />
                      </div>
                    )}
                    {!this.state.loading && this.state.api_err && (
                      <div className="invalid-feedback invalid-feedback-c">
                        Something went wrong. Please try again or contact support.
                      </div>
                    )}
                    {!this.state.loading && (
                      <>
                        {this.state.user_err && (
                          <div className="invalid-feedback invalid-feedback-c">
                            {this.state.user_err}
                          </div>
                        )}
                        {this.state.success && (
                          <div className="valid-feedback valid-feedback-c">
                            {this.state.success}
                          </div>
                        )}
                        <Form.Group controlId="caseName">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Members First Name"
                            value={this.state.new_first_name}
                            onChange={this.onChange}
                            name="new_first_name"
                            required
                            ref={this.createRef}
                          />
                        </Form.Group>
                        <Form.Group controlId="caseName">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Members Last Name"
                            value={this.state.new_last_name}
                            onChange={this.onChange}
                            name="new_last_name"
                            required
                          />
                        </Form.Group>
                        <Form.Group controlId="clientName">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter Members Email"
                            value={this.state.new_email}
                            onChange={this.onChange}
                            name="new_email"
                            required
                          />
                        </Form.Group>

                        <Form.Group id="private" controlId="formBasicCheckbox">
                          <Form.Check
                            style={{ paddingLeft: "10px" }}
                            type="checkbox"
                            label="Admin"
                            name="admin"
                            onChange={this.onChangeP}
                            value={this.state.admin}
                          ></Form.Check>
                        </Form.Group>
                      </>
                    )}
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      type="submit"
                      variant="secondary"
                      onClick={this.handleNClose}
                      disabled={this.state.disabled || this.state.loading}
                    >
                      No
                    </Button>

                    <Button
                      disabled={this.state.loading}
                      variant="primary"
                      type="submit"
                    >
                      Yes
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>

              {/* add */}
              {this.state.user.admin === "true" ? (
                <Button
                  onClick={this.handleNShow}
                  className="action action-b new top-g-f switch-btn btn btn-dark"
                >
                  Add User
                </Button>
              ) : (
                <Button
                  className="action action-b new top-g-f switch-btn btn  btn-dark"
                  disabled={true}
                >
                  Add User
                </Button>
              )}

              <ListGroup className="user-list">{memberList}</ListGroup>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
