import React, { Component } from 'react';
import { Form, Button, Container, Modal } from 'react-bootstrap';

import ReactPhoneInput from 'react-phone-input-2';
import axios from 'axios';
import prod from '../../../prod';

export const logPageView = () => {
  window.analytics.page('Firm Settings');
};
export default class Settings extends Component {
  constructor() {
    super();
    this.state = {
      firm: '',
      number: '',
      name: '',
      errors: '',
      success: '',
      user: {},
      old: {},
      plan: '',
      payment: {},
      members: [],
      show: false,
      dShow: false,
      admin: false,
      new_name: '',
      new_email: '',
      new_password: '',
      user_err: '',
      users: 0,
      to_delete: null,
      user_d__err: '',
      user_t__err: '',
      tShow: false,
      to_toggle: null,
      oldState: null,
      showNameModal: true,
    };
    this.onChange = this.onChange.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.onChangeP = this.onChangeP.bind(this);
  }

  handleDClose = () => this.setState({ dShow: false });
  onChangeP = () => this.setState({ admin: !this.state.admin });
  componentDidMount() {
    logPageView();
    axios
      .get(prod() + '/api/users/current')

      .then((use) => {
        console.log(use);
        axios
          .post(prod() + '/api/firms/get', { firm: use.data.firm })
          .then((user) => {
            console.log(user.data);
            axios
              .post(prod() + '/api/users/getall', { firm: use.data.firm })
              .then((a) => {
                this.setState({
                  name: user.data['displayName']
                    ? user.data['displayName']
                    : user.data['name'],

                  old: user.data,
                  number: user.data['number'],

                  address: user.data['address'],
                  postalCode: user.data['postalCode'],
                  country: user.data['country'],
                  province: user.data['province'],
                  city: user.data['city'],
                  age: user.data['age'],
                  litigation: user.data['litigation'],

                  plan: user.data['plan'],
                  payment: user.data['payment'],
                  members: a.data['members'],
                  firm: use.data.firm,

                  users: a.data['members'].length,

                  user: use.data,
                });
              })
              .catch((err) => {
                console.log('MMEMEMME', err);
              });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      })
      .catch((err) => {
        console.log('MSG in err', err);
      });
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleOnChange = (value) => {
    this.setState({ number: value }, () => {
      console.log(this.state.number);
    });
  };
  onSubmit() {
    if (
      this.state.name &&
      this.state.name.trim() !== '' &&
      this.state.name !== undefined
    ) {
      if (
        this.state.name.trim() !== this.state.old.displayName.trim() ||
        this.state.number.trim() != this.state.old.number.trim() ||
        this.state.address.trim() != this.state.old.address.trim() ||
        this.state.postalCode.trim() != this.state.old.postalCode.trim() ||
        this.state.province.trim() != this.state.old.province.trim() ||
        this.state.city.trim() != this.state.old.city.trim() ||
        this.state.age.trim() != this.state.old.age.trim() ||
        this.state.litigation.trim() != this.state.old.litigation.trim()
      ) {
        let newFirmName = this.state.name.toLowerCase();
        if (this.state.name.trim() !== this.state.old.displayName) {
          // check
          axios
            .post(prod() + '/api/firms/check', {
              name: newFirmName,
            })
            .then((use) => {
              if (use.data.err == false) {
                axios
                  .post(prod() + '/api/firms/update', {
                    name: newFirmName.trim(),
                    displayName: this.state.name.trim(),

                    number: this.state.number.trim(),
                    address: this.state.address.trim(),
                    postalCode: this.state.postalCode.trim(),
                    country: this.state.country.trim(),
                    province: this.state.province.trim(),
                    city: this.state.city.trim(),
                    age: this.state.age.trim(),
                    litigation: this.state.litigation.trim(),
                    oldFirm: this.state.old.name.trim(),
                  })
                  .then((user) => {
                    this.setState({
                      success: 'Updated Firm Information!',
                      errors: undefined,
                      showNameModal: false,
                      dShow: false,
                    });
                    setTimeout(() => {
                      this.setState({
                        success: '',
                      });
                    }, 2000);
                  })
                  .catch((err) => {
                    this.setState({
                      errors: 'Could Not Update Firm Information',
                      success: undefined,
                    });
                  });
              } else {
                return this.setState({
                  errors: 'Firm Name Already Taken',
                });
              }
            });
        } else {
          axios
            .post(prod() + '/api/firms/update', {
              name: newFirmName.trim(),
              displayName: this.state.name.trim(),

              number: this.state.number.trim(),
              address: this.state.address.trim(),
              postalCode: this.state.postalCode.trim(),
              country: this.state.country.trim(),
              province: this.state.province.trim(),
              city: this.state.city.trim(),
              age: this.state.age.trim(),
              litigation: this.state.litigation.trim(),
              oldFirm: this.state.old.name.trim(),
            })
            .then((user) => {
              this.setState({
                success: 'Updated Firm Information!',
                errors: undefined,
                showNameModal: false,
                dShow: false,
              });
              setTimeout(() => {
                this.setState({
                  success: '',
                });
              }, 2000);
            })
            .catch((err) => {
              this.setState({
                errors: 'Could Not Update Firm Information',
                success: undefined,
              });
            });
        }
      }
    } else {
      this.setState({ errors: 'Firm name can not be blank.' });
      alert('Firm name can not be blank.');
    }
  }

  render() {
    const isEnabled =
      this.state.name.length > 0 &&
      // this.state.number.length > 0 &&
      // this.state.address.length > 0 &&
      // this.state.postalCode.length > 0 &&
      // this.state.country.length > 0 &&
      // this.state.province.length > 0 &&
      // this.state.city.length > 0 &&
      // this.state.age.length > 0 &&
      // this.state.litigation.length > 0 &&
      (this.state.name.toLowerCase().trim() !== this.state.old.name.trim() ||
        this.state.number.trim() !== this.state.old.number.trim() ||
        this.state.address.trim() !== this.state.old.address.trim() ||
        this.state.postalCode.trim() !== this.state.old.postalCode.trim() ||
        this.state.province.trim() !== this.state.old.province.trim() ||
        this.state.city.trim() !== this.state.old.city.trim() ||
        this.state.age.trim() !== this.state.old.age.trim() ||
        this.state.litigation.trim() !== this.state.old.litigation.trim() ||
        this.state.country.trim() != this.state.old.country.trim());

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    const disabled = this.state.user.admin == 'true' ? false : true;

    return (
      <div>
        <Container fluid>
          <div id='user-settings' className='container'>
            <Modal
              dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
              show={this.state.dShow}
              onHide={this.handleDClose}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Are your sure you want to make these changes?
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  type='submit'
                  variant='secondary'
                  onClick={this.handleDClose}
                >
                  No
                </Button>
                <Button variant='primary' onClick={this.onSubmit}>
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>

            <h2>Firm Settings</h2>

            <Button
              disabled={!isEnabled}
              onClick={this.onSubmit}
              className='action action-b new btn-dark'
            >
              Save
            </Button>

            <Form className='user-form'>
              {this.state.errors && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors}
                </div>
              )}
              {this.state.success && (
                <div className='valid-feedback valid-feedback-c'>
                  {this.state.success}
                </div>
              )}
              <Form.Group controlId='formBasicEmails'>
                <Form.Label>Firm Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  value={this.state.name}
                  onChange={this.onChange}
                  name='name'
                  disabled={disabled}
                />
              </Form.Group>

              <ReactPhoneInput
                inputExtraProps={{
                  name: 'number',
                  required: true,
                  autoFocus: true,
                }}
                defaultCountry={'ca'}
                value={this.state.number}
                onChange={this.handleOnChange}
                disabled={disabled}
              />
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  value={this.state.address}
                  onChange={this.onChange}
                  name='address'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Postal Code'
                  value={this.state.postalCode}
                  onChange={this.onChange}
                  name='postalCode'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Country'
                  value={this.state.country}
                  onChange={this.onChange}
                  name='country'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Province</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Province'
                  value={this.state.province}
                  onChange={this.onChange}
                  name='province'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>City</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter City'
                  value={this.state.city}
                  onChange={this.onChange}
                  name='city'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Age'
                  value={this.state.age}
                  onChange={this.onChange}
                  name='age'
                  disabled={disabled}
                />
              </Form.Group>
              <Form.Group controlId='formBasicEmailz'>
                <Form.Label>Practice Area</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Practice Area'
                  value={this.state.litigation}
                  onChange={this.onChange}
                  name='litigation'
                  disabled={disabled}
                />
              </Form.Group>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}
