import React, { Component } from 'react';

import { Container } from 'react-bootstrap';
export default class Impeachment extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);

    if (this.props.second != true) {
      this.props.getTab({
        case: '',
        witness: '',
        topic: '',
        path: '/information/impeachment',
        name: 'Impeachment',
        active: true,
      });
    }
  }
  render() {
    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    return (
      <div id='wit-dash' className='wit-dash-w'>
        <Container fluid>
          <div className='info_and_tech'>
            {/* <h2>General Credibility</h2>
            <h1>
              Highlight the Anti-Social Nature of the Conduct of the Witness
            </h1>
            <p>
              In this technique you would establish that the conduct of the
              witness is dishonest, unfair or otherwise contrary to accepted
              values of society. The conduct could also be contrary to the
              values of the organization or profession that the witness belongs
              to. You would want to establish and highlight the fact that the
              witness either does not accept values of society, or that they
              have clearly breached those values.
            </p>
            <h1>Bias</h1>
            <p>
              Bias can be established by proving some form of close relationship
              such as friendship, family relationship or a financial interest.
              The bias will not likely be admitted but can be demonstrated by
              bringing out the factual circumstances demonstrating the close
              connection.
            </p>
            <h1> Plausibility </h1>
            <p>
              Plausibility deals with the likelihood that something would occur
              as described by the witness. If the witness testifies in a manner
              that is contrary to what one would expect then the witness can be
              challenged on the basis of plausibility. Cross-examination
              questions can also be put to the witness challenging the witness
              to reject a proposition that is logical and supports your case. In{' '}
              <em>McCarthy on Cross-Examination</em> the example is given of
              suggesting to a prosecution cooperating witness, or “snitch”, that
              he will do anything to get out of jail including lying. If a
              witness of bad character denies that he would lie to get out of
              jail then it raises a plausibility issue.
            </p> */}
            <h2>Coming Soon</h2>
          </div>
        </Container>
      </div>
    );
  }
}
