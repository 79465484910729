import React, { Component } from 'react';

export default class ToggleNav extends Component {
  render() {
    return (
      <>
        <div className='comp-auth-nav-toggle'>
          <div onClick={this.props.setNavExpanded} className='czhaTb'></div>
          <div className='blur-back'></div>
        </div>
        <div className='mobile-auth-nav-toggle'>
          <div onClick={this.props.setNavExpanded} className='czhaTb'>
            <div className='mobile-upper-nav'>
              <img
                className='land-logo'
                src='https://res.cloudinary.com/aleri/image/upload/v1593642374/Aleri/tod_lzzdaf.svg'
              ></img>
              <div className='title-mobile'>
                <h2>ALERI</h2>
              </div>
            </div>
            <div className='ham'>
              <img src='https://res.cloudinary.com/aleri/image/upload/v1593642362/Aleri/menu_arjwr3.svg'></img>
            </div>
          </div>
        </div>
      </>
    );
  }
}
