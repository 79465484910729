import React, { Component } from 'react';
import axios from 'axios';
import prod from '../../../prod';
import Login from './Login';
export default class CommunityLogin extends Component {
  constructor() {
    super();
    this.state = {};
    this.goToPeerBoard = this.goToPeerBoard.bind(this);
    this.bundle = this.bundle.bind(this);
  }
  // comunity
  componentWillReceiveProps(newProps) {
    this.bundle(newProps.currentUser);
  }
  bundle = (currentUser) => {
    console.log(currentUser);
    if (currentUser && currentUser.email) {
      console.log(currentUser);
      axios
        .post(prod() + '/api/community/login', {
          id: currentUser.id,
          email: currentUser.email,
          name: currentUser.name,
          // firm: firm,
        })
        .then((r) => {
          this.goToPeerBoard(r.data);
        })

        .catch((err) => {
          console.log('Could not login to community', err);
        });
    }
  };
  goToPeerBoard = (token) => {
    window.location.replace(
      'https://community.aleri.ca/api/v2/forum/login/bearer/' + token
    );
  };

  render() {
    window.scrollTo(0, 0);
    return (
      <div id='Landing'>
        <Login
          load={this.props.load}
          bundle={this.bundle}
          currentUser={this.props.currentUser}
          community='yes'
        />
      </div>
    );
  }
}
