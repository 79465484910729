import React, { Component } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { sortableHandle } from 'react-sortable-hoc';
import { Form, Col, Row, Modal, Button } from 'react-bootstrap';

import FileViewModal from '../../Shared/FileViewModal';
import LinkDocument from '../../Shared/LinkDocument';
import AudioModal from '../../Shared/AudioModal';
import VideoModal from '../../Shared/VideoModal';
import '../../Shared/UploadDocument.css';
import Highlightable from 'highlightable';

export default class FactRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      i: this.props.index,

      uploadDocModal: false,

      fileModal: false,
      filename: this.props.value.ref.document
        ? this.props.value.ref.document.link.slice(48)
        : '',
      
      //audio modal
      audioModal: false,
      //video modal
      videoModal: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.openFileViewModal = this.openFileViewModal.bind(this);
    this.openUploadDocModal = this.openUploadDocModal.bind(this);
  }

  DragHandle = sortableHandle(() => <span className='drag'></span>);
  handleChange = (i, event) => {
    const key = i;
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, value, key);
    if (name === 'reference') {
      this.setState({
        value: {
          ...this.state.value,
          ref: {
            ...this.state.value['ref'],
            value: value,
          },
          reference: value,
        },
      });
    } else {
      this.setState({
        value: {
          ...this.state.value,
          [name]: value,
        },
      });
    }
  };

  componentWillReceiveProps(newProps) {
    // console.log('Changed 2: ', this.props.value, this.props.fact);
    if (
      this.props.value != newProps.value ||
      this.props.fact != newProps.fact
    ) {
      this.setState({
        value: newProps.value,
        i: newProps.value,
      });
    }
  }

  openFileViewModal(open) {
    this.setState({ fileModal: open });
  }
  openUploadDocModal(open) {
    this.setState({ uploadDocModal: open });
  }
  openAudioModal = (open) => {
    this.setState({ audioModal: open });
  }
  openVideoModal = (open) => {
    this.setState({ videoModal: open });
  }

  render() {
    const { value, i } = this.state;
    let ref = {};
    let row = {};
    if (value.ref && value.ref.document == undefined) {
      ref = (
        <>
          <Col
            md={4}
            className={value.reference == '' ? 'ref' : ' ref ref-end'}
          >
            <Form.Group controlId={i}>
              <TextareaAutosize
                placeholder='Enter Reference'
                value={this.state.value.reference}
                onChange={(e) => this.handleChange(i, e)}
                name='reference'
                onBlur={() => this.props.saveItem(this.state.value)}
                className={this.state.value.ref.value != '' ? '' : ' notes-n'}
                ref={this.props.textInput1}
              />
            </Form.Group>
            {value.reference == '' && (
              <Button
                onClick={() => this.openUploadDocModal(true)}
                className='btn upload-document'
                tabIndex={-1}
              >
                Upload Document
              </Button>
            )}
          </Col>
        </>
      );
    } else {
      ref = (
        <>
          <Col md={3} className='ref '>
            {value.ref.document.type === 'Link' && (
              <a
                className='centre_link'
                href={value.ref.document.link}
                target='_black'
              >
                {value.ref.document.name}
              </a>
            )}
            {value.ref.document.type === 'file' && (
              <p
                className='centre_link'
                onClick={() => this.openFileViewModal(true)}
              >
                {value.ref.value}
              </p>
            )}
            {value.ref.document.type === 'audio' && (
              <p
                className='centre_link'
                onClick={() => this.openAudioModal(true)}
              >
                {value.ref.value}
              </p>
            )}
            {value.ref.document.type === 'video' && (
              <p
                className='centre_link'
                onClick={() => this.openVideoModal(true)}
              >
                {value.ref.value}
              </p>
            )}
          </Col>
          <Col md={1} className='ref-end'>
            <img
              className='ref-logo-title'
              src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
              onClick={() =>
                this.props.handleDocumentDeleteShow(this.state.value)
              }
            ></img>
          </Col>
        </>
      );
    }
    if (i == this.props.q - 1) {
      row = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-none d-md-block '>
            <Row key={i} className='saved-input'>
              {ref}
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                {this.props.toggleTimeline ? (
                  <div className='highlight-class'>
                    <Highlightable
                    // ranges CANNOT be empty, needs to have a start and end of the highlight
                    // need to extract start and end index somehow after highlighting???
                    // ranges={[{start: 0, end: this.state.q[i].evidence.length - 1, text: this.state.q[i].evidence}]}
                    // ranges={[{start: this.state.startHighlight, end: this.state.endHighlight, text: this.state.q[i].evidence}]}
                    ranges={[]}
                    enabled={true}
                    onTextHighlighted={(e) => {
                      if (this.props.toggleHighlight) {
                        this.props.selectionHandler(e, value.reference);
                      } else if (this.props.toggleTimeline) {
                        this.props.selectionTimelineHandler(e, value.reference);
                      }
                    }}
                    id={`${i}`}
                    // onMouseOverHighlightedWord={
                    //   onMouseOverHighlightedWordCallback
                    // }
                    //#fdfd3e
                    highlightStyle={{
                      backgroundColor: '#ffcc80',
                      color: 'black',
                    }}
                    text={value.evidence}
                    />
                    </div>
                  
                ) : (
                  <>
                  {value.evidence.includes('storage.googleapis.com/highlight-images') ? (
                    <div className="img-container">
                      <img 
                        src={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                        alt="screenshot"
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                      placeholder='Enter Evidence'
                      value={value.evidence}
                      onChange={(e) => this.handleChange(i, e)}
                      name='evidence'
                      onBlur={() => this.props.saveItem(this.state.value)}
                      className={value.evidence != '' ? '' : ' notes-n'}
                      ref={this.props.textInput2}
                    />
                  )}
                  </>
                )}
                </Form.Group>
              </Col>
              <Col md={1}>
                {this.props.toggleTimeline ? null : (
                  <>
                    <img
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                      onClick={() => this.props.handleDShow(this.state.value)}
                    ></img>
                    <this.DragHandle />
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      row = (
        <div
          className={
            this.props.darkMode == true ? 'underline-t dark' : 'underline-t '
          }
        >
          <div className='d-none d-md-block ' ref={this.divElement}>
            <Row key={i} className='saved-input'>
              {ref}
              <Col md={7} className='notes'>
                <Form.Group controlId={i}>
                  {this.props.toggleTimeline ? (
                    <div className='highlight-class'>
                    <Highlightable
                      ranges={[]}
                      enabled={true}
                      onTextHighlighted={(e) => {
                        if (this.props.toggleHighlight) {
                          this.props.selectionHandler(e, value.reference);
                        } else if (this.props.toggleTimeline) {
                          this.props.selectionTimelineHandler(e, value.reference);
                        }
                      }}
                      id={`${i}`}
                      highlightStyle={{
                        backgroundColor: '#ffcc80',
                        color: 'black',
                      }}
                      text={value.evidence}
                    />
                  </div>
                ) : (
                <>
                  {value.evidence.includes('storage.googleapis.com/highlight-images') ? (
                    <div className="img-container">
                      <img 
                        src={value.evidence.replace('aleri-app-bucket', 'aleri-prod-app-bucket')}
                        alt="screenshot"
                      />
                    </div>
                  ) : (
                    <TextareaAutosize
                    placeholder='Enter Evidence'
                    value={value.evidence}
                    onChange={(e) => this.handleChange(i, e)}
                    name='evidence'
                    onBlur={() => this.props.saveItem(this.state.value)}
                  />
                  )}
                </>
                )}
                </Form.Group>
              </Col>
              <Col md={1}>
                {this.props.toggleTimeline ? null : (
                  <>
                    <img
                      className='logo-title'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg'
                      onClick={() => this.props.handleDShow(this.state.value)}
                    ></img>
                    <this.DragHandle />
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <>
        {row}
        {this.props.value.ref.document && (
          <>
            <FileViewModal
              refId={this.state.value._id}
              link={this.state.value.ref.document.link}
              value={this.state.value}
              name={this.state.value.ref.document.name}
              fileId={this.state.value.ref.document._id}
              fileModal={this.state.fileModal}
              openFileViewModal={this.openFileViewModal}
              darkMode={this.props.darkMode}
            />
            {/* <AudioModal
              darkMode={this.props.darkMode}
              openAudioModal={this.openAudioModal}
              audioModal={this.state.audioModal}
              docId={this.state.value.ref.document._id}
              link={this.state.value.ref.document.link}
              name={this.state.value.ref.document.name}
              refId={this.state.value._id}
              value={this.state.value}
              openFileViewModal={this.openFileViewModal}
            /> */}
            {/* <VideoModal
              darkMode={this.props.darkMode}
              openVideoModal={this.openVideoModal}
              videoModal={this.state.videoModal}
              docId={this.state.value.ref.document._id}
              link={this.state.value.ref.document.link}
              name={this.state.value.ref.document.name}
            /> */}
          </>
        )}
        <LinkDocument
          uploadDocModal={this.state.uploadDocModal}
          openUploadDocModal={this.openUploadDocModal}
          linkRef={this.props.linkRef}
          addRef={this.props.addRef}
          createLink={this.props.createLink}
          handleCreateClose={this.props.handleCreateClose}
          linkErr={this.props.linkErr}
          darkMode={this.props.darkMode}
          witness={this.props.witness}
          case={this.props.case}
          addExistingDoc={this.props.addExistingDoc}
          addExistingLink={this.props.addExistingLink}
          addLink={this.props.addLink}
          addLinkState={this.props.addLinkState}
          handleAddClose={this.props.handleAddClose}
          showDelete={this.props.documentDeleteShow}
          handleDelete={this.props.handleDelete}
          handleDeleteClose={this.props.handleDeleteClose}
          refId={this.state.value._id}
          value={this.state.value}
          addExistingDocOnRow={this.props.addExistingDocOnRow}
          docs={this.props.docs}
          createPDF={this.props.createPDF}
        />
      </>
    );
  }
}
