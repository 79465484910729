import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import prod from '../prod';

export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post(prod() + '/api/users/register', userData)
    .then((res) => {
      history.push('/login');
    })
    .catch((err) => {
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
  loginUser({ email: userData.email, password: userData.password });
};

export const registerFirm = (userData, history) => (dispatch) => {
  axios
    .post(prod() + '/api/firms/create', userData)
    .then((res) => {
      console.log('success -> ', res);
      history.push('/login');
    })
    .catch((err) => {
      console.log('fail -> ', err);
      dispatch({ type: GET_ERRORS, payload: err.response.data });
    });
  loginUser({ email: userData.email, password: userData.password });
};

export const loginUser = (userData) => (dispatch) => {
  console.log('prod = ', prod());
  console.log('userdata, ', userData);
  axios
    .post(prod() + '/api/users/login', userData)
    .then((res) => {
      console.log('REDUX: loginUser response', res);
      console.log(
        res.data.traits.userId,
        res.data.traits.traits,
        res.data.traits
      );
      window.analytics.identify(res.data.traits.userId, res.data.traits.traits);
      //console.log('User Email',res.data.email);
      const token = res.data.token;
      // console.log(token);
      //set token
      localStorage.setItem('jwtToken', token);
      //set token header
      setAuthToken(token);
      // decode token
      const decoded = jwt_decode(token);
      //set current user
      dispatch(setCurrentUser(token));
    })
    .catch((err) => dispatch({ type: GET_ERRORS, payload: err.response.data }));
};
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const logoutUser = () => (dispatch) => {
  console.log('REDUX:Logout User called');
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(setCurrentUser({}));
};
