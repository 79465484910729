import React, { Component } from 'react';

import {
  Col,
  Row,
  Container,
  Button,
  Form,
  Modal,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  ProgressBar,
} from 'react-bootstrap';

import { Link, Redirect, withRouter } from 'react-router-dom';
import axios from 'axios';
import prod from '../../../../prod';

import Fade from 'react-reveal/Fade';

import FactsExport from './FactsExport';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { TableRowHeightAttributes } from 'docx';

export const logPageView = () => {
  window.analytics.page('Fact List');
};
class Facts extends Component {
  constructor() {
    super();
    this.state = {
      progress: 0,
      facts: [],
      redirecting: false,
      dShow: false,
      show: false,
      deleted: '',
      case: '',
      witness: '',
      witnessName: '',
      name: '',
      uName: '',
      use: '',
      reference: '',
      evidence: '',
      // delete Fact
      deleteT: {},
      dTShow: false,

      // update
      uShow: false,
      // help
      hShow: false,
      // export
      eShow: false,
      dragging: false,

      //add more
      addMoreClicked: 1,
      names : {},
      errors: {},
      removeLocations: [],

      //clone
      cloneConfirm: false,
    };
    this.getFS = this.getFS.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDShow = this.handleDShow.bind(this);
    this.handleDClose = this.handleDClose.bind(this);
    this.deleteFact = this.deleteFact.bind(this);
    this.onChange = this.onChange.bind(this);
    // delete fact
    this.handleDFShow = this.handleDFShow.bind(this);
    this.handleDFClose = this.handleDFClose.bind(this);
    this.handleDFSubmit = this.handleDFSubmit.bind(this);

    // update fact
    this.handleUShow = this.handleUShow.bind(this);
    this.handleUClose = this.handleUClose.bind(this);
    this.handleUSubmit = this.handleUSubmit.bind(this);

    // help
    this.handleHShow = this.handleHShow.bind(this);
    this.handleHClose = this.handleHClose.bind(this);

    // export
    this.handleEShow = this.handleEShow.bind(this);
    this.handleEClose = this.handleEClose.bind(this);

    //
    this.removeOne = this.removeOne.bind(this);

    //clone
    this.clone = this.clone.bind(this);

    this.createRef = React.createRef();
    this.editRef = React.createRef();
  }
  handleImportant = (ev, important) => {
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/important/facts', {
          case: this.state.case._id,
          witness: this.state.witness,
          important: important,
          user: s.data.id,
        })
        .then((user) => {
          this.setState(
            {
              witness: user.data,
            },
            () => {
              this.props.updateWitness(this.state.case, user.data);
            }
          );
        })

        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  };
  handleProgress = () => {
    let p = 0;
    const n = this.state;
    let num = 0;
    let v = 0;
    let amount = 0;
    for (let i of n.facts) {
      if (i.fact.length > 0) {
        v += 1;
        i.progress = 100;
      } else {
        i.progress = 0;
      }
      num += 1;
    }
    num += 1;
    amount = 100 / num;
    if (n.facts && n.facts.length > 0) {
      p += amount;
    }
    p += amount * v;
    if (p != this.state.progress)
      this.setState({ progress: p, facts: n.facts });
  };
  SortableItem = sortableElement(({ fact, index }) => {
    return (
      <div key={index}>
        <div
          className={
            this.props.darkMode == true
              ? 'parent-side-nav parent-side-nav-card dark-parent-side-nav-card'
              : 'parent-side-nav parent-side-nav-card'
          }
          helperClass='dragging'
        >
          <div
            className='cases-items cases-items-s'
            onClick={() => this.getFS(fact)}
          >
            <div
              className={
                this.props.darkMode == true
                  ? 'inside-card inside-card-dark'
                  : 'inside-card'
              }
            >
              <ProgressBar now={Math.round(fact.progress)} />
              <div className='inside-btn'>
                {/* INSIDE BTN */}

                {/* INSIDE BTN */}
                {/* {fact.important ? (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportant(
                          e,
                          this.state.case,
                          fact,
                          false,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s'
                        src={require('../../../../pics/app/filled_star_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportant(
                          e,
                          this.state.case,
                          fact,
                          true,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s card_important_comment'
                        src={require('../../../../pics/app/star_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                )} */}
                {/* {fact.comment ? (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportant(
                          e,
                          this.state.case,
                          fact,
                          false,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s '
                        src={require('../../../../pics/app/filled_comment_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                ) : (
                  <ButtonToolbar className='side-nav-t'>
                    <Button
                      className='side-bt'
                      onClick={(e) =>
                        this.handleImportant(
                          e,
                          this.state.case,
                          fact,
                          true,
                          index
                        )
                      }
                    >
                      <img
                        className='logo-s card_important_comment'
                        src={require('../../../../pics/app/comment_grey.svg')}
                      ></img>
                    </Button>
                  </ButtonToolbar>
                )} */}
                <ButtonToolbar className='side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleUShow(fact, e)}
                      className='logo-settings'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822539/Aleri/settings-g_lylgdu.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
                <ButtonToolbar className=' side-nav-t'>
                  <Button className='side-bt'>
                    <img
                      onClick={(e) => this.handleDFShow(fact, e)}
                      className='logo-s'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/bin_qpxnvg.svg'
                    ></img>
                  </Button>
                </ButtonToolbar>
              </div>
              <p>{fact.name}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  SortableContainer = sortableContainer(({ children }) => {
    const sc =
      this.props.splitState === true ? 'auto auto' : 'auto auto auto auto';

    return (
      <Row>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: sc,
            gap: '10px',
          }}
        >
          {children}
        </div>
      </Row>
    );
  });

  onDragEnd = ({ oldIndex, newIndex }) => {
    let newFacts = arrayMove(this.state.facts, oldIndex, newIndex);
    if (newFacts && newFacts.length > 0) {
      this.setState(() => ({
        facts: newFacts,
        dragging: false,
      }));
      axios
        .post(prod() + '/api/cases/update/witness/fact-reorder', {
          witness: this.props.witness['_id'],
          case: this.props.case['_id'],
          newFacts: newFacts,
        })
        .then((use) => {})
        .catch((e) => console.log(e));
    }
  };
  onDragStart = ({ oldIndex, newIndex }) => {
    this.setState({
      dragging: true,
    });
  };
  handleHShow = () => this.setState({ hShow: true });
  handleHClose = () => this.setState({ hShow: false });
  handleEShow = () =>
    this.setState({ eShow: true }, () => {
      axios
        .post(prod() + '/api/cases/getall/witness/fact', {
          case: this.props.case['_id'],
          witnesses: this.props.witness['_id'],
        })
        .then((use) => {
          this.setState({
            facts: use.data.facts,
            witness: this.props.witness,
            case: this.props.case,
          });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    });

  handleEClose = () => this.setState({ eShow: false });

  // update
  handleUShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();

    this.setState({ uShow: true, uName: e.name, fact: e }, () =>
      this.editRef.current.focus()
    );
  };
  handleUClose = () => this.setState({ uShow: false, uName: '' });
  handleUSubmit = (data) => {
    data.preventDefault();
    // console.log('this.state.fact', this.state.fact)
    // console.log('this.state.uName', this.state.uName)

    const info = {
      case: this.props.case._id,
      witness: this.props.witness._id,
      sheetId: this.state.fact._id,
      sheet: 'fact',
      newName: this.state.uName,
    };

    axios
      .post(prod() + '/api/documents/update/location-name', info)
      .then((res) => {
        // console.log("RESPONSE", res.data);
      })
      .catch((e) => console.log(e));

    axios
      .post(prod() + '/api/cases/update/witness/fact/name', {
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
        fact: this.state.fact['_id'],
        name: this.state.uName,
      })
      .then((use) => {
        let witness = null;
        for (let i = 0; i < use.data.case.witnesses.length; i++) {
          if (use.data.case.witnesses[i]['_id'] == this.props.witness['_id']) {
            for (let j = 0; j < use.data.case.witnesses[i].facts.length; j++) {
              if (
                use.data.case.witnesses[i].facts[j]['_id'] ==
                this.state.fact['_id']
              ) {
                this.setState({
                  uShow: false,
                  uName: '',
                  facts: use.data.case.witnesses[i].facts,
                });
                break;
              }
            }
          }
        }
      })
      .catch((err) => {
        this.setState({
          user: 'Fact already exist',
        });
      });
    // }
  };

  handleDFClose = () => this.setState({ dTShow: false });
  handleDFShow = (e, ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();

    this.setState({ dTShow: true, deleteT: e });
  };
  handleDFSubmit = () => {
    this.deleteDocs(this.state.deleteT);
    this.deleteHighlights(this.state.facts, this.state.deleteT);
    axios
      .post(prod() + '/api/cases/delete/witness/fact', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
        fact: this.state.deleteT['_id'],
      })
      .then((use) => {
        axios
          .post(prod() + '/api/cases/getall/witness/fact', {
            case: this.props.case['_id'],
            witnesses: this.props.witness['_id'],
          })
          .then((use) => {
            this.setState({
              facts: use.data.facts,
              dTShow: false,
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
  };

  deleteDocs = (fact) => {
    axios
      .post(prod() + '/api/documents/getall', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
      })
      .then((a) => {
        let docs = a.data.documents;
        for (let i = 0; i < docs.length; i++) {
          let sub = 0;
          let count = 0;
          for (let j = 0; j < docs[i].locations.length; j++) {
            if (
              docs[i].locations[j].fact &&
              docs[i].locations[j].fact.toString() == fact._id.toString()
            ) {
              docs[i].locations.splice(j, 1);

              axios
                .post(prod() + '/api/documents/update/loc', {
                  case: this.props.case['_id'],
                  witness: this.props.witness['_id'],
                  document: docs[i]._id,
                  loc: docs[i].locations,
                })
                .then((use) => {})
                .catch((e) => {
                  console.log('Invalid Input, Please Try Again', e);
                });
            } else {
              sub++;
            }
          }
        }
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  };

  //deletes image highlight from gcs
  deleteFile = (filename) => {
    axios
      .post(prod() + '/api/cases/gcs/deleteFile', { filename })
      .then(res => console.log("deleted file"))
      .catch(e => console.log(e));
  }

  deleteHighlights(facts, deleted) {
    const rowIds = [];
    for (const fact of facts) {
      if (fact._id === deleted._id) {
        for (const row of fact.fact) {
          rowIds.push(row._id);
        }
      }
    }
    // console.log("row ids: ", rowIds);
    axios //get the array of image highlights' gcs names
      .post(prod() + '/api/file/highlights/documents/getImages', {
        rowIds: rowIds,
      })
      .then(res => {
        console.log("images: ", res.data);
        const images = res.data;
        //delete them from gcs
        images.forEach(image => {
          this.deleteFile(image);
        })
        axios
          .post(prod() + '/api/file/highlights/documents/deleteAll', { rowIds })
          .then((res) => console.log(res.data))
          .catch(e => console.log(e));
      })
      .catch((e) => {
        console.log('ERROR', e);
      });
  }
  componentDidMount() {
    logPageView();
    if (
      this.props.case == undefined ||
      this.props.case.client == undefined ||
      this.props.witness == undefined
    ) {
      this.props.changeSideMenu();
    } else {
      axios
        .post(prod() + '/api/cases/getall/witness/fact', {
          case: this.props.case['_id'],
          witnesses: this.props.witness['_id'],
        })
        .then((use) => {
          this.setState(
            {
              facts: use.data.facts,
              witness: this.props.witness,
              case: this.props.case,
            },
            () => {
              this.handleProgress();
            }
          );
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }
  componentDidUpdate() {
    localStorage.setItem('fact', JSON.stringify(this.state));
    this.handleProgress();
  }
  componentWillReceiveProps(prevProps) {
    if (
      prevProps.witness &&
      prevProps.witness.facts.length !== this.props.witness.facts.length
    ) {
      this.setState(
        {
          dTShow: false,
          deleteT: '',
          facts: prevProps.witness.facts,
        },
        () => {
          this.handleProgress();
        }
      );
    }
  }

  onChange(e) {
    if (e.target.name === 'uName') {
      this.setState({ [e.target.name]: e.target.value })
    } else {
      this.setState({
        names: {
          ...this.state.names,
          [e.target.name]: e.target.value
        }
      });
    }
  }

  getFS(a) {
    localStorage.removeItem('q');
    this.props.getFact(a);
  }
  deleteFact = () => {
    axios
      .post(prod() + '/api/cases/delete/witness/fact', {
        case: this.props.case['_id'],
        witness: this.props.witness['_id'],
        fact: this.state.deleted['_id'],
      })
      .then((use) => {
        // console.log(use);
        for (let a of use.data.case.witnesses) {
          if (a._id == this.props.witness['_id']) {
            this.props.updateWitness(use.data.case, a);
          }
        }
        axios
          .post(prod() + '/api/cases/getall/witness/fact', {
            case: this.props.case['_id'],
            witnesses: this.props.witness['_id'],
          })
          .then((use) => {
            this.setState({
              facts: use.data.facts,
              dShow: false,
            });
          })
          .catch((err) => {
            console.log('MSG in err', err);
          });
      });
  };
  handleSubmit = (data, noOfFacts) => {
    data.preventDefault();
    let witnessFacts = [];
    let enteredFacts = {};

    for (const fact of this.props.witness.facts) {
      witnessFacts.push(fact.name.toLowerCase());
    };

    let errors = {};
    
    for (let i = 1; i <= noOfFacts; i++) {
      const name = `name${i}`;
      const names = this.state.names;
      if (names[name]) {
        //checking to see if the user input duplicate names
        if (enteredFacts[names[name]]) {
          errors[`error${i}`] = 'Duplicate Fact, Please Remove One'
        } else {
          enteredFacts[names[name]] = true;
        }

        //checking to see if it already exists
        if (witnessFacts.includes(names[name].toLowerCase())) {
          errors[`error${i}`] = 'Fact Already Exists'
        }
      }
    };

    let errorExists = Object.keys(errors).length > 0;

    if (errorExists) {
      this.setState({ errors });
    } else {

      let enteredFactsArr = Object.keys(enteredFacts);

      const fact = {
        names: enteredFactsArr,
        witness: this.props.witness['_id'],
        case: this.props.case['_id'],
      };

      if (enteredFactsArr.length !== 0 && fact.witness != undefined && fact.case != '') {
        axios
          .post(prod() + '/api/cases/create/witness/facts', fact)
          .then((use) => {
            console.log(use);
            for (let a of use.data.case.witnesses) {
              if (a._id == this.props.witness['_id']) {
                this.props.updateWitness(use.data.case, a);
              }
            }
            axios
              .post(prod() + '/api/cases/getall/witness/fact', {
                case: this.props.case['_id'],
                witnesses: this.props.witness['_id'],
              })
              .then((use) => {
                this.setState({
                  facts: use.data.facts,
                  show: false,
                  names: {},
                  addMoreClicked: 1,
                  errors: {},
                  removeLocations: [],
                  user: '',
                });
              })
              .catch((err) => {
                console.log('MSG in err', err);
              });
          })
          .catch((err) => {
            console.log(err);
            this.setState({
              user: 'Fact already exist',
            });
          });
      } else {
        this.setState({
          user: 'Please fill out all fact fields',
        });
      }
    }
  };
  handleClose = () => this.setState({ show: false, names: {}, user: '', errors: {}, addMoreClicked: 1, removeLocations: [], });
  handleShow = () =>
    this.setState({ show: true }, () => this.createRef?.current?.focus());
  handleDClose = () => this.setState({ dShow: false });
  handleDShow = (e) => this.setState({ dShow: true, deleted: e });

  addMore = (clicked, remove) => {
    let forms = [];

    for (let i = 1; i <= clicked; i++) {
      if (!remove.includes(i)) {
        const form = (
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '90%' }}>
              {this.state.errors[`error${i}`] && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.errors[`error${i}`]}
                </div>
              )}
              <Form.Control
                type='text'
                placeholder='Enter Fact Name'
                value={this.state.names[`name${i}`] ? this.state.names[`name${i}`] : ''}
                onChange={this.onChange}
                name={`name${i}`}
                ref={this.createRef}
              />
            </div>
            <img
              style={{ width: '10%'}}
              onClick={() => this.removeOne(i)}
              className="logo-title logo-title-l" 
              src="https://res.cloudinary.com/aleri/image/upload/v1593822535/Aleri/cancel_bghnqi.svg"
            ></img>
          </div>
        )
        forms.push(form);
      }
    }
    if (forms.length === 0) {
      this.setState({ 
        user: 'Error: At Lease One Field Must Exist',
        addMoreClicked: this.state.addMoreClicked + 1,
      })
    }

    return forms;
  }

  removeOne = (nameLocation) => { 
    let names = {...this.state.names};
    names[`name${nameLocation}`] = '';

    let removeLocations = [...this.state.removeLocations];
    removeLocations.push(nameLocation);
    this.setState({ removeLocations, names });
  };

  clone = (e, fact) => {
    e.preventDefault();
    console.log("fact: ", fact)
    //names should go like this - 'fact copy' -> 'fact copy(1)' -> 'fact copy(2)' and so on
    let name = fact.name + ' copy';
    let existingFactNames = [];
    this.state.facts.forEach(f => {
      existingFactNames.push(f.name);
    })
    console.log("existing fact namaes: ", existingFactNames);
    let counter = 1;
    let nameLength = name.length;
    while(existingFactNames.includes(name)) {
      name = name.slice(0,nameLength);
      name += `(${counter})`;
      counter++;
    }

    const data = {
      case: this.props.case._id,
      witness: this.props.witness._id,
      facts: fact,
      name: name,
    };

    axios
      .post(prod() + '/api/cases/create/witness/fact/clone', data)
      .then(res => {
        console.log("updated case: ", res.data.case.witnesses);
        const witness = res.data.case.witnesses.find(w => w._id === this.props.witness._id);
        this.setState({
          cloneConfirm: false,
          uShow: false,
          facts: witness.facts,
        })
      })
      .catch(e => console.log(e));
  }

  render() {
    let num = 0;
    if (this.state.redirecting) {
      return <Redirect to='/' />;
    }

    const sideMenu = this.props.open ? 4 : 1;
    const display = this.props.open ? 8 : 11;
    const firm = this.props.top ? 'add-btn-expanded' : 'add-btn';
    const firm2 = this.props.top ? 'wit-nav-expanded' : '';
    return (
      <div id='wit-dash'>
        <Container fluid>
          <div>
            <Row id='dash-add'>
              <Col md={5}>
                <div className='case-dash-container'>
                  {/* {this.state.witness.comment ? (
                    <img
                      onClick={(e) => this.handleImportant(e, false)}
                      className='logo-title-dash '
                      src={require('../../../../pics/comment_submitted.svg')}
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src={require('../../../../pics/comment.svg')}
                    ></img>
                  )}*/}
                  {this.state.witness.factImportant ? (
                    <img
                      className='logo-title-dash'
                      onClick={(e) =>
                        this.handleImportant(
                          e,

                          false
                        )
                      }
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642365/Aleri/favourite_submitted_ye0nad.svg'
                    ></img>
                  ) : (
                    <img
                      onClick={(e) => this.handleImportant(e, true)}
                      className='logo-title-dash card_important_comment'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642367/Aleri/favourite_cwtltk.svg'
                    ></img>
                  )}
                </div>
                <h1 id={firm2}>Statement Facts By Topics </h1>
                <ProgressBar now={Math.round(this.state.progress)} />
              </Col>

              <Col md={7}>
                <div className={firm + ' add-btn-p add-btn-i1'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Help
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleHShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642372/Aleri/help_vghyvc.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={firm + ' add-btn-p add-btn-i2'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Export
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleEShow} className='add-case'>
                          <img
                            className='logo-title log'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593822537/Aleri/export_w64d13.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
                <div className={firm + ' add-btn-p add-btn-i3'}>
                  <div>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Add Fact
                          </Tooltip>
                        }
                      >
                        <Button onClick={this.handleShow} className='add-case plus-icon'>
                          <img
                            className='logo-title plus-icon'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
              <Col md={2}></Col>
            </Row>
          </div>

          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.eShow}
            onHide={this.handleEClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  eShow: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Export All Fact Sheets</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FactsExport
                  witness={this.props.witness}
                  facts={this.state.facts}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleEClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.hShow}
            onHide={this.handleHClose}
          >
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                this.setState({
                  hShow: false,
                });
              }}
            >
              <Modal.Header closeButton>
                <Modal.Title>What Are Statement Facts By Topics</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='help'>
                  <p>
                    When you are preparing your questions, it is useful to have
                    the facts, from pre-trial statements, separated by topic.
                    This will allow you to prepare questions more easily as you
                    will have the pre-trial statement facts separate by topic
                    when you prepare questions on each topic. Each question
                    topic can have a corresponding document that lists the
                    relevant facts for that topic. The “Facts by Topic” document
                    can then be put on a split screen on the computer as you are
                    drafting questions. This gives a summary of the relevant
                    portion. You can then go directly to the pre-trial statement
                    or testimony on split screen, next to the questions, instead
                    of the summary if you want the exact wording to the
                    pre-trial statement. These statements can be exported and
                    printed as PDF versions
                  </p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Link to='/settings/support'>
                  <Button variant='outline-secondary'>Help</Button>
                </Link>
                <div style={{ flex: 1 }}></div>
                <Button variant='secondary' onClick={this.handleHClose}>
                  Done
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Form onSubmit={(e) => this.handleSubmit(e, this.state.addMoreClicked)}>
              <Modal.Header closeButton>
                <Modal.Title>Create Fact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* {this.state.user && (
                  <div className='invalid-feedback invalid-feedback-c'>
                    {this.state.user}
                  </div>
                )} */}
                <Form.Group controlId='caseName'>
                  <Form.Label>Fact Name</Form.Label>
                  {/* <Form.Control
                    type='text'
                    placeholder='Enter Fact Name'
                    value={this.state.names.name ? this.state.names.name : ''}
                    onChange={this.onChange}
                    name='name'
                    ref={this.createRef}
                  /> */}
                  {this.addMore(this.state.addMoreClicked, this.state.removeLocations)}
                </Form.Group>
                <Button 
                  variant='primary' 
                  style={{ marginRight: '10px' }} 
                  onClick={() => this.setState({ addMoreClicked: this.state.addMoreClicked + 1 }, () => this.createRef?.current?.focus())}
                >
                  Add More
                </Button>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant='primary' type='submit'>
                  Add Fact
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.dTShow}
            onHide={this.handleDFClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Are you sure you want to delete this fact?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                type='submit'
                variant='secondary'
                onClick={this.handleDFClose}
              >
                No
              </Button>
              <Button variant='primary' onClick={this.handleDFSubmit}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
            show={this.state.uShow}
            onHide={this.handleUClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Fact</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {this.state.user && (
                <div className='invalid-feedback invalid-feedback-c'>
                  {this.state.user}
                </div>
              )}
              <Form.Group controlId='caseName'>
                <Form.Label>Fact Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Fact Name'
                  value={this.state.uName}
                  onChange={this.onChange}
                  name='uName'
                  ref={this.editRef}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={this.handleUClose}>
                Close
              </Button>
              <Button variant='secondary' onClick={() => this.setState({ cloneConfirm: true, uShow: false })}>
                Clone
              </Button>
              <Button
                variant='primary'
                type='submit'
                onClick={this.handleUSubmit}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
          {/* Clone Confirm Modal */}
          <Modal show={this.state.cloneConfirm} onHide={() => this.setState({ cloneConfirm: false })}>
            <Modal.Header closeButton>
              <Modal.Title>Clone Fact</Modal.Title>
            </Modal.Header>
            <Modal.Body>Do you want to clone this Fact?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.setState({ cloneConfirm: false, uShow: true })}>
                Close
              </Button>
              <Button variant="primary" onClick={(e) => {
                this.clone(e, this.state.fact);
              }}>
                Yes
              </Button>
            </Modal.Footer>
          </Modal>

          <div className='cases cases-d'>
            <div className={this.state.dragging ? 'cases-dragging' : ''}></div>
            <this.SortableContainer
              onSortEnd={this.onDragEnd}
              onSortStart={this.onDragStart}
              forceFallback={true}
              axis='xy'
              distance={1}
              helperClass={'dragging'}
            >
              {this.state.facts &&
                this.state.facts.map((fact, index) => (
                  <this.SortableItem
                    key={`fact-${index}`}
                    index={index}
                    fact={fact}
                  />
                ))}
            </this.SortableContainer>
            {this.state.facts.length == 0 && (
              <Row id='dash-desc' className='dash-desc-d'>
                <Col>
                  <p className=' dash-d-d uncap'>
                    Press The Plus Icon To Add A Fact
                  </p>
                  <p className=' dash-d-d dash-d-m uncap'>
                    press The Plus Icon To Add A Fact
                  </p>

                  <div className='dash-desc-d-d'>
                    <ButtonToolbar>
                      <OverlayTrigger
                        key='bottom'
                        placement='bottom'
                        overlay={
                          <Tooltip
                            id={
                              this.props.darkMode
                                ? 'dark-tooltip-add'
                                : 'tooltip-add'
                            }
                          >
                            Add Fact
                          </Tooltip>
                        }
                      >
                        <Button
                          onClick={this.handleShow}
                          className='add-case d plus-icon'
                        >
                          <img
                            className='logo-title plus-icon'
                            src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                          ></img>{' '}
                        </Button>
                      </OverlayTrigger>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    );
  }
}
export default withRouter(Facts);
