import React, { Component } from 'react';
import {
  Button,
  Col,
  Row,
  Container,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip,
  Modal,
  Spinner,
} from 'react-bootstrap';

import axios from 'axios';
import prod from '../../../prod';
import Pulse from 'react-reveal/Pulse';
import DashboardModal from './DashboardModal';
// import GettingStarted from './GettingStarted';

export const logPageView = () => {
  window.analytics.page('Dashboard');
};

// export default class RenderDashboard extends Component {
//   constructor() {
//     super();
//     this.state = {
//       // checklistComplete: true,
//       // checklist: {},
//       // loading: true,
//     };
//     this.updateChecklist = this.updateChecklist.bind(this);
//   }

// componentDidMount() {
// console.log('props', this.props);
// Make api call to confirm if check list has been completed or not
// axios.get(prod() + '/api/users/current').then((user) => {
//   // console.log("user id", user.data.id)
//   axios
//     .post(prod() + '/api/users/checklist/get', {
//       userId: user.data.id,
//     })
//     .then((user) => {
//       this.setState({
//         checklistComplete: false,
//         checklist: user.data.checklist,
//         loading: false,
//       });
//     })
//     .catch((err) => {
//       // if error happens, just show them the dashboard
//       this.setState({ checklistComplete: true, loading: false });
//     });

// };

// axios
//   .get(prod() + "/api/users/checklist/status")
//   .then((response) => {
//     const { data } = response;
//     console.log('data', data);
//     this.setState({
//       checklistComplete: data.checklistComplete,
//       checklist: data.checklist,
//       loading: false,
//     });
//     console.log('api call success', response);
//     console.log('props', this.props);
//   })
//   .catch((err) => {
//     // if error happens, just show them the dashboard
//     this.setState({ checklistComplete: true, loading: false });
//   });
// }

// updateChecklist(updated) {
//   this.setState({ checklist: updated });
// }

// render() {
//   // if (this.state.loading) {
//     // return <Spinner style={{ marginTop: '100px' }} animation='border' />;
//   // }

//   // if (!this.state.checklistComplete) {
//   //   return (
//   //     <GettingStarted
//   //       checklist={this.state.checklist}
//   //       darkMode={this.props.darkMode}
//   //       updateChecklist={this.updateChecklist}
//   //     />
//   //   );
//   // }

//   return <Dashboard {...this.props} />;
// }

export default class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      itemClicked: null,
      cases: [],
      show: false,
      firm: '',
      user: '',
      use: {},
      plan: '',
      pdfClicked: false,
      loggedInUser: '',
      currentUser: '',
      season: false,
    };
    this.onMenuClick = this.onMenuClick.bind(this);
    this.getW = this.getW.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.createRef = React.createRef();
  }

  handleShow = () =>
    this.setState({ show: true }, () => this.createRef.current.focus());
  handleClose = () =>
    this.setState({
      show: false,
      err: '',
      user: '',
      name: '',
      client: '',
    });

  handleSubmit = (info, data) => {
    data.preventDefault();
    var numberOfExistingPreTrialCases = this.state.cases.filter(
      (caseObj) => caseObj.status === 'PRE_TRIAL'
    ).length;

    const cases = {
      name: info.name,
      client: info.client,
      firm: this.state.firm,
      user: this.state.use,
      currentUser: this.state.currentUser,
      orderIndex:
        numberOfExistingPreTrialCases == 0
          ? 0
          : ++numberOfExistingPreTrialCases,
    };

    if (
      cases.name !== '' &&
      cases.client !== '' &&
      cases.name !== undefined &&
      cases.client !== undefined &&
      cases.firm !== ''
    ) {
      if (
        (this.state.plan === '1' || this.state.plan === '4') &&
        this.state.cases.length >= 1
      ) {
        this.setState({
          err: 'You are allowed to add 1 case. Upgrade to higher plan to add more cases.',
        });
      } else if (
        (this.state.plan === '2' || this.state.plan === '5') &&
        this.state.cases.length >= 100
      ) {
        this.setState({
          err: 'You are allowed to add 100 case. Upgrade to higher plan to add more cases.',
        });
      } else {
        console.log('creating cases');
        axios
          .post(prod() + '/api/cases/create', cases)
          .then((use) => {
            console.log('cases created');
            axios.get(prod() + '/api/users/current').then((s) => {
              axios
                .post(prod() + '/api/cases/getall', {
                  firm: s.data.firm,
                })

                .then((user) => {
                  this.setState({
                    cases: user.data.cases,
                    firm: s.data.firm,
                    use: use.data.id,
                    show: false,
                    private: this.state.private,
                    user: '',
                  });
                  this.props.clicked();
                })
                .catch((err) => {
                  console.log('MSG in err', err);
                });
            });
          })
          .catch((err) => {
            console.log('There was an error ', err);
            this.setState({
              user: 'Case already exist',
            });
          });
      }
    } else {
      this.setState({
        user: 'Please fill out both file number and client name fields',
      });
    }
  };

  onMenuClick(e) {
    this.setState({ itemClicked: parseInt(e.target.value) });
  }
  openSeason = () => {
    this.setState({
      season: true,
    });
  };
  componentDidMount() {
    logPageView();
    axios.get(prod() + '/api/users/current').then((s) => {
      axios
        .post(prod() + '/api/firms/get', { firm: s.data.firm })
        .then((use) => {
          axios
            .post(prod() + '/api/cases/getall', {
              firm: s.data.firm,
            })
            .then((user) => {
              console.log(s.data);
              this.setState(
                {
                  cases: user.data.cases,
                  firm: s.data.firm,
                  loggedInUser: s.data.first_name,
                  use: use.data.id,

                  plan: use.data.plan,
                  currentUser: s.data.id,
                  firmName: use.data.displayName
                    ? use.data.displayName
                    : use.data.name,
                },
                () => {
                  this.props.unload();
                }
              );
            })
            .catch((err) => {
              console.log('MSG in err', err);
            });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.clicked2 !== this.props.clicked2) {
      axios
        .post(prod() + '/api/cases/getall', {
          firm: this.state.firm,
        })
        .then((user) => {
          this.setState({
            cases: user.data.cases,
          });
        })
        .catch((err) => {
          console.log('MSG in err', err);
        });
    }
  }

  getW(a) {
    localStorage.removeItem('case');
    this.props.getWitness(a);
  }

  render() {
    let caseList = [];
    //Bhushan:PDF

    const items = this.props.open ? 'cases cases-open' : 'cases';
    let firm = <div></div>;
    if (this.props.splitState == true && this.props.open == true) {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded dash-d-split'
        : 'dash-d dash-d-firm';
    } else if (this.props.splitState === true && this.props.open === false) {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded dash-d-split-closed'
        : 'dash-d dash-d-firm';
    } else {
      firm = this.props.top
        ? 'dash-d dash-d-firm-expanded'
        : 'dash-d dash-d-firm';
    }

    return (
      <Container>
        {/* <Modal
          show={this.state.season}
          onHide={() => this.setState({ season: false })}
          dialogClassName={this.props.darkMode ? 'dark-modal' : ''}
        >
          <Modal.Header closeButton>
            <Modal.Title>Season 1</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Aleri Season 1 is here!</h5>
            <p>
              Season 1 marks the first set of new features to be added to Aleri.
            </p>
            <p>
              New features including: marking as important, document linking,
              and progress bars for every document have been added.
            </p>
            <p>Stay tuned for more Season 1 features to come.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='secondary'
              onClick={() => this.setState({ season: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        <DashboardModal
          show={this.state.show}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
          user={this.state.user}
          create={this.createRef}
          darkMode={this.props.darkMode}
          cases={this.state.cases}
        />

        <Row id='dash-desc'>
          <p className={firm} id='dash-d-main'>
            {this.state.firmName}
            <br />
            <strong>
              <a
                className='season'
                target='_blank'
                href='https://help.aleri.ca/en/collections/2747781-getting-started-with-aleri'
              >
                Getting Started ↗
              </a>
            </strong>
          </p>

          <Col md={12}>
            <img
              className='landing-logo'
              src={
                this.props.darkMode
                  ? 'https://res.cloudinary.com/aleri/image/upload/v1593642358/Aleri/app/darkWheat_sywnpz.svg'
                  : 'https://res.cloudinary.com/aleri/image/upload/v1593642356/Aleri/app/wheat_nx4dky.svg'
              }
            ></img>{' '}
            <Pulse>
              <p className='dash-t'>
                Welcome{', '}
                <span className='loggedInUser'>{this.state.loggedInUser}</span>
              </p>
            </Pulse>
            <Pulse>
              <p className='dash-d'>
                Press The Plus Icon Below To Create A New Case
              </p>
              <p className='dash-d uncap dash-d-m'>
                Press the icon with the 3 lines in the top right corner to see
                cases
              </p>
            </Pulse>
          </Col>
        </Row>

        <Row id='dash-add'>
          <Col>
            <div>
              <ButtonToolbar>
                <OverlayTrigger
                  key='bottom'
                  placement='bottom'
                  overlay={
                    <Tooltip
                      id={
                        this.props.darkMode ? 'dark-tooltip-add' : 'tooltip-add'
                      }
                    >
                      Add Case
                    </Tooltip>
                  }
                >
                  <Button
                    onClick={this.handleShow}
                    className='add-case d plus-icon'
                  >
                    <img
                      className='logo-title plus-icon'
                      src='https://res.cloudinary.com/aleri/image/upload/v1593642366/Aleri/plus_zn8w6g.svg'
                    ></img>{' '}
                  </Button>
                </OverlayTrigger>
              </ButtonToolbar>
            </div>
          </Col>

          <p className=' dash-d-logo' style={{ margin: '20px' }}>
            <div className='dash-foot'>
              <h5>
                <strong>Explore resources</strong>
              </h5>
              <h5>
                <strong>
                  <a
                    target='_blank'
                    href='https://help.aleri.ca/en/collections/2738203-what-is-aleri'
                  >
                    Help Center ↗
                  </a>
                </strong>
              </h5>
              <p>Advice and answers from the Aleri team</p>

              <h5>
                <strong>
                  <a
                    target='_blank'
                    href='https://help.aleri.ca/en/articles/4848247-watch-a-demo-of-aleri'
                  >
                    Demo Video ↗
                  </a>
                </strong>
              </h5>
              <p>Video demonstration of Aleri</p>
            </div>
          </p>
        </Row>
      </Container>
    );
  }
}
